import { DataLoadingState } from '@zi-common/interface/ngrx-state';
import { defaultLoadingState } from '@zi-core/ngrx/config/ngrx-loading-states.config';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface EmailerVersionState extends DataLoadingState {
  version: string;
}

export const initialEmailerVersionState: EmailerVersionState = {
  version: null,
  ...defaultLoadingState,
};

export const emailerVersionFeatureSelector = createFeatureSelector<EmailerVersionState>('emailerVersion');

export const getEmailerVersionSelector = createSelector(emailerVersionFeatureSelector, (state: EmailerVersionState) => state.version);

export const getEmailerVersionLoading = createSelector(emailerVersionFeatureSelector, (state: EmailerVersionState) => state.loading);
