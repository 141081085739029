import { initialAdaLiveAgentState, AdaLiveAgentState } from '@zi-pages/ada-live-chat/state/ada-live-agent.state';
import { Action, createReducer, on } from '@ngrx/store';
import {
  AdaLiveAgentDisconnectAction,
  AdaLiveAgentDisconnectSuccessAction,
  AdaLiveAgentInitFailureAction,
  AdaLiveAgentInitSuccessAction,
  StartAdaLiveAgentInitAction,
} from '@zi-pages/ada-live-chat/action/ada-live-agent.action';
import { defaultLoadingState, failureLoadedState, loadingState, successLoadedState } from '@zi-core/ngrx/config/ngrx-loading-states.config';

const adaLiveAgentReducer = createReducer(
  { ...initialAdaLiveAgentState },
  on(StartAdaLiveAgentInitAction, (state: AdaLiveAgentState) => {
    return {
      ...state,
      ...loadingState,
    };
  }),

  on(AdaLiveAgentInitSuccessAction, (state: AdaLiveAgentState) => {
    return {
      isInitialized: true,
      ...successLoadedState,
    };
  }),

  on(AdaLiveAgentInitFailureAction, (state: AdaLiveAgentState, { error }) => {
    return {
      isInitialized: false,
      ...failureLoadedState,
      error: {
        hasError: true,
        error,
      },
    };
  }),

  on(AdaLiveAgentDisconnectAction, (state: AdaLiveAgentState) => {
    return {
      ...state,
      ...loadingState,
      error: {
        hasError: false,
        error: null,
      },
    };
  }),

  on(AdaLiveAgentDisconnectSuccessAction, (state: AdaLiveAgentState) => {
    return {
      isInitialized: false,
      ...defaultLoadingState,
      error: {
        hasError: false,
        error: null,
      },
    };
  }),
);

export function AdaLiveAgentReducer(state: AdaLiveAgentState, action: Action): AdaLiveAgentState {
  return adaLiveAgentReducer(state, action);
}
