import { environment } from '@env/environment';
import { ZicLogoMenuItem } from '@zoominfo/component-library/components/icon-button-menu/menu-item.model';
import { Permissions } from '@zi-core/ngxPermissions/permissions';

export type ZicWaffleMenuItem = ZicLogoMenuItem & {
  index?: number;
};

export const salesMenuItem: ZicWaffleMenuItem = {
  index: 0,
  logo: 'zoominfo_24',
  logoBackground: 'linear-gradient(134.89deg, #EB3E3F 1.14%, #9B1578 120.71%)',
  label: 'Sales',
  url: environment?.zoominfo_app,
};

export const recruiterMenuItem: ZicWaffleMenuItem = {
  index: 1,
  logo: 'zoominfo_24',
  logoBackground: 'linear-gradient(288.49deg, #F49B62 3.05%, #F23980 72.67%)',
  label: 'Talent',
  url: environment?.recruiter_url,
  permissions: [Permissions.LD_WAFFLE_RECRUITER], // TODO: Uncomment after testing css
};

export const marketingMenuItem: ZicWaffleMenuItem = {
  index: 2,
  logo: 'zoominfo_24',
  logoBackground: 'linear-gradient(319.57deg, #10CAD0 6.19%, #0061FF 100%)',
  label: 'Marketing',
  url: environment?.marketingos_app,
};
const iconsUrl = environment.icons_url;

export const engageMenuItem: ZicWaffleMenuItem = {
  index: 3,
  imageSrc: `${iconsUrl}/engage_logo.svg`,
  logoBackground: 'linear-gradient(274.24deg, #F0247D 0.84%, #9F1679 104.44%)',
  label: 'Engage',
  url: '#',
};

export const adminMenuItem: ZicWaffleMenuItem = {
  index: 4,
  label: 'Admin',
  logoBackground: 'linear-gradient(134.89deg, #EB3E3F 1.14%, #9B1578 120.71%)',
  logo: 'zoominfo_24',
  url: environment?.admin_url,
};

export class UmsToken {
  ver: number;
  jti: string;
  iss: string;
  aud: string;
  iat: number;
  exp: number;
  cid: string;
  uid: string;
  scp: string[];
  auth_time: number;
  ziUsername: string;
  sub: string;
  firstName: string;
  lastName: string;
  ziSessionType: number;
  ziGroupId: number;
  ziUserId: number;
  ziTenantId: number;
  email: string;
  ziMongoUserId: string;
  ziPlatforms: string[];
}
