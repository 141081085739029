import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '@env/environment';
import { ProvisionMultiFactorAuthRequest } from 'src/app/core/http-model/request/provision-multi-factor-auth.request.model';
import { ProvisionMultiFactorAuthResponse } from 'src/app/core/http-model/response/provision-multi-factor-auth.response.model';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import _ from 'lodash';
import { HolidayAttribute, Profile } from '@zi-core/data-model/profile.model';
import { LoggerServiceToken } from '@zi-core/config/logger-service.config';
import { ILoggerService } from '@zi-core/interface/logger.service.interface';
import { FeatureFlagService } from '@app/feature-flag/feature-flag.service';
import { DataSerializationService } from '@zi-common/service/data-serialization.service';
import { Flag } from '@app/feature-flag/types/flag.enum';
import { StringEncodingMethod } from '@zi-core/enums/string-encoding-method.enum';
import { SpacesResponse } from '@zi-core/http-model/response/spaces.response.model';
import { ApiEncodedPayload } from '@zi-core/http-model/request/api-encoded-payload.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private readonly postUpdateProfileRequestUrl = `${environment.backend_url}/v1/account/profile`;

  private readonly postEncodedUpdateProfileRequestUrl = `${environment.backend_url}/v2/encoded/account/profile`;

  constructor(
    private httpClient: HttpClient,
    @Inject(LoggerServiceToken) private loggerService: ILoggerService,
    private readonly featureFlagService: FeatureFlagService,
    private readonly dataSerializationService: DataSerializationService,
  ) {}

  changePassword(oldPassword: string, newPassword: string) {
    return this.httpClient.post(`${environment.backend_url}/v1/password/change`, { Old: oldPassword, New: newPassword });
  }

  getProfileData() {
    return this.httpClient.get(`${environment.backend_url}/v2/token/refresh?type=0`).pipe(
      map((res) => {
        const holidays = (_.get(res, 'profile.attributes.holidaysV2') || [])?.map(
          (h) => new Object({ displayName: this.formatHolidayDisplayName(h.displayName), name: h.name, isEnabled: h.isEnabled }),
        );
        _.set(res, 'profile.attributes.holidaysV2', holidays);
        return res;
      }),
    );
  }

  getHolidays(cultureName: string) {
    return this.httpClient.get(`${environment.backend_url}/v1/account/holidays?CultureName=${cultureName}`).pipe(
      map((res) => {
        const holidays: string[] = (_.get(res, 'holidays') || []).map((h) => this.formatHolidayDisplayName(h));
        return { holidays };
      }),
    );
  }

  getUserHolidays(): Observable<{ holidays: HolidayAttribute[] }> {
    return this.httpClient.get(`${environment.backend_url}/v1/account/holidaysv2`).pipe(
      map((res) => {
        const holidays = (_.get(res, 'holidays') || []).map((h) => ({
          displayName: this.formatHolidayDisplayName(h.displayName),
          name: h.name,
          isEnabled: h.isEnabled,
        }));
        return { holidays: holidays };
      }),
    );
  }

  setProfileData(profile: Profile) {
    return this.featureFlagService.observe<Flag.ENABLE_API_ENCODING_FOR_WAF>(Flag.ENABLE_API_ENCODING_FOR_WAF).pipe(
      take(1),
      switchMap((apiEncodingEnabled: boolean) => {
        const body = { Profile: profile };
        if (apiEncodingEnabled && profile?.attributes?.signature) {
          body.Profile.attributes.signature = this.dataSerializationService.getEncodedString(body.Profile.attributes.signature, StringEncodingMethod.Base64);
          return this.httpClient.post<SpacesResponse>(this.postEncodedUpdateProfileRequestUrl, {
            data: body,
            encoding: StringEncodingMethod.Base64,
          } as ApiEncodedPayload);
        }
        return this.httpClient.post<SpacesResponse>(this.postUpdateProfileRequestUrl, body);
      }),
    );
  }

  provisionMfa(resource: ProvisionMultiFactorAuthRequest): Observable<ProvisionMultiFactorAuthResponse> {
    return this.httpClient.post<ProvisionMultiFactorAuthResponse>(`${environment.backend_url}/v1/account/mfa/provision`, resource);
  }

  /**
   * get profile data from backend and in case of error return null
   */
  getProfile(): Observable<Profile> {
    return this.httpClient
      .get(`${environment.backend_url}/v1/account/profile`, {
        withCredentials: true,
      })
      .pipe(
        map((res: any) => res?.userProfile),
        catchError((err) => {
          this.loggerService.error('Failed to fetch profile data');
          return of(null);
        }),
      );
  }

  private formatHolidayDisplayName(holiday: string) {
    return holiday.replace(/([a-z])([A-Z])/g, '$1 $2').trim();
  }
}
