import { CallStatusEnum } from '@app/caller/interface/call-status-enum';
import { DialerSelection } from '@app/core/enums/dialer.enum';
import { PhoneTypesEnum } from '@app/caller/interface/phone-types-enum';

export interface CallInfo {
  callId: string;
  prospectCallId: string;
  taskId?: number;
  contactInfo: ContactCallInfo;
  callStatus: CallStatusEnum;
  isCallLoggerActionRequired: boolean;
  isProspectCallActive: boolean;
  voicemailId?: string;
  // Recording Info needs to live here in case we need to reload state on reload for bridge.
  // Object will be saved in session storage.
  recordingInfo: CallRecordingInfo;
  dialerType: DialerSelection;
  isIncoming: boolean;
  isAutoDial?: boolean;
}

export const initialCallInfo: CallInfo = {
  callId: null,
  prospectCallId: null,
  contactInfo: { name: null, id: null, email: null, phoneNumberDialed: null },
  callStatus: null,
  isCallLoggerActionRequired: false,
  taskId: null,
  isProspectCallActive: false,
  voicemailId: null,
  recordingInfo: { isRecordingOn: false, isRecordingCancelledForCurrentCall: false },
  dialerType: null,
  isIncoming: false,
};

export interface ContactCallInfo {
  // As of VOIP, these are optional
  name?: string;
  id?: number;
  email?: string;
  phoneNumberDialed: string;
  originalNumber?: string;
  zoomId?: number;
  imageUrl?: string;
  phoneType?: PhoneTypesEnum; // Used for indicate type of phone number
}

export interface CallRecordingInfo {
  isRecordingOn: boolean;
  isRecordingCancelledForCurrentCall: boolean;
}
