import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as _ from 'lodash';
import { OnBoardingSteps } from '@zi-pages/on-boarding/on-boarding.config';
import { OnBoardingService } from '@zi-pages/on-boarding/services/on-boarding.service';
import {
  SetOnBoardingAction,
  SetOnBoardingFailedAction,
  SetOnBoardingSuccessAction,
  UpdateUnfinishedStepsAction,
  UpdateUnfinishedStepsFailedAction,
  UpdateUnfinishedStepsSuccessAction,
} from '@zi-pages/on-boarding/ngrx/action/on-boarding.action';

@Injectable()
export class OnBoardingEffect {
  constructor(private action$: Actions, private onBoardingService: OnBoardingService) {}

  setOnBoarding$ = createEffect(() => {
    return this.action$.pipe(
      ofType(SetOnBoardingAction),
      mergeMap(() =>
        this.onBoardingService.getOnBoarding().pipe(
          map((res) => {
            const sortUnfinishedStep = this.onBoardingService.sort(res.value.unfinishedSteps);
            // get the first unfinished step
            const first = _.first(sortUnfinishedStep);
            const currStep = OnBoardingSteps.findIndex((step) => step.key === first);
            return SetOnBoardingSuccessAction({ currentStep: currStep, unfinishedSteps: sortUnfinishedStep });
          }),
          catchError(() => {
            return of(SetOnBoardingFailedAction());
          }),
        ),
      ),
    );
  });

  updateUnfinishedSteps$ = createEffect(() => {
    return this.action$.pipe(
      ofType(UpdateUnfinishedStepsAction),
      mergeMap(({ currentStep, unfinishedSteps }) =>
        this.onBoardingService.updateUnfinishedSteps(unfinishedSteps).pipe(
          map((res) => {
            const list = _.get(res, 'value.unfinishedSteps');
            return UpdateUnfinishedStepsSuccessAction({ currentStep, unfinishedSteps: list });
          }),
          catchError(() => {
            return of(UpdateUnfinishedStepsFailedAction());
          }),
        ),
      ),
    );
  });
}
