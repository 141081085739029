import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ApplicationState } from '@app/reducers';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { ErrorDialogComponent } from '@zi-common/component/error-dialog/error-dialog.component';
import { variableMap } from '@zi-common/component/froala-editor-v2/froala-editor.config';
import { OrgMemberService } from '@zi-common/service/org-member/org-member.service';
import { Contact } from '@zi-core/data-model/contact.model';
import { FollowUpTask, InvitationsCreateRequest } from '@zi-core/http-model/request/invitations.request.model';
import { InvitationsResponse } from '@zi-core/http-model/response/invitations.response.model';
import { getProfile } from '@zi-core/ngrx/state/auth.state';
import { EmailConfig, EmailDraftStateModel } from '@zi-pages/email-v2/model/email.model';
import { EmailStorageService } from '@zi-pages/email-v2/services/email-storage.service';
import * as _ from 'lodash';
import { Observable, of, throwError } from 'rxjs';
import { first, map, switchMap, take, tap } from 'rxjs/operators';
import { EngageModeV2Service } from '@zi-pages/engage-mode-v2/service/engage-mode-v2.service';
import { getExtensionMode } from '@app/extensionMode.config';
import { TemplateServiceService } from '@zi-common/service/templates-data/template-service.service';
import { AnalyticsService } from '@app/core/service/analytics.service';
import { MergeVariableRegexPattern } from '@zi-common/service/email-preview-merge-variables/merge-variables.config';
import { MessageType } from '@app/common/model/message/message-type';
import { Message } from '@app/common/model/message/message.model';
import { NotyService } from '@app/common/service/noty/noty.service';
import { AnalyticsSection, EmailInvokedPage } from '@app/core/enums/engage-email';
import { emailSignatureValidator, extractReplyEmail, hasOnlyWhitespaceOrImgExistsValidator } from '@zi-pages/email-v2/helpers/email-reply.helper';
import { Invitation } from '@zi-core/data-model/invitation.model';
import { SafeHtmlPipe } from '@zi-common/pipe/safe-value/safe-html.pipe';
import { Profile } from '@zi-core/data-model/profile.model';
import { EmailReplyAnalyticModel } from '@zi-core/data-model/analytics/email-reply-event.model';
import { AnalyticsEvent } from '@zi-core/enums/analytics-event.enum';
import { PdmApplication } from '@zi-core/enums/pdm-application.enum';
import { SUBJECT_MAX_LENGTH } from '../component/email/email.config';
import { encodeHtmlEntities } from '@zi-pages/email-v2/helpers/html-entities/encode-html-entities.helper';
import { FeatureFlagService } from '@app/feature-flag/feature-flag.service';
import { Flag } from '@app/feature-flag/types/flag.enum';
import { StringEncodingMethod } from '@zi-core/enums/string-encoding-method.enum';
import { ApiEncodedPayload } from '@zi-core/http-model/request/api-encoded-payload.model';
import { DataSerializationService } from '@zi-common/service/data-serialization.service';

/**
 *  Email Service
 */

@Injectable()
export class EmailModeService {
  EmailDraftLimit = 5;
  emailFormArray = new FormArray([]);
  allMergedVariablesList: string[] = Object.keys(variableMap);
  initialDraftHeight: any = true;
  private readonly postCreateInvitationUrl = `${environment.backend_url}/v1/invitations/create`;
  private readonly postEncodedCreateInvitationUrl = `${environment.backend_url}/v2/encoded/invitations/create`;
  private readonly postCreateInvitationTestUrl = `${environment.backend_url}/v1/invitations/sendtest`;
  private readonly postEncodedCreateInvitationTestUrl = `${environment.backend_url}/v2/encoded/invitations/sendtest`;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private httpClient: HttpClient,
    private fb: FormBuilder,
    private orgMemberService: OrgMemberService,
    private appStore: Store<ApplicationState>,
    private emailStorageService: EmailStorageService,
    private engageModeService: EngageModeV2Service,
    private templateService: TemplateServiceService,
    private dialog: MatDialog,
    private analyticsService: AnalyticsService,
    private notyService: NotyService,
    private safeHtml: SafeHtmlPipe,
    private engageModeV2Service: EngageModeV2Service,
    private readonly featureFlagService: FeatureFlagService,
    private readonly dataSerializationService: DataSerializationService,
  ) {
    /**
     * If engage mode is false, then only we save to session storage
     */
    this.emailFormArray.valueChanges.subscribe((formValue) => {
      if (!this.engageModeService.getEngageModeState()) {
        this.emailStorageService.storeEmailCreateObjectsInSessionStorage(formValue);
      }
    });
  }

  /**
   * Get the form with contact id
   */
  getEmailFormWithContactId(contactId: number): any {
    let foundForm: any;
    this.emailFormArray.controls.forEach((form, index) => {
      if (form.get('draftState').get('contactId').value === contactId) {
        foundForm = {
          form: form.get('emailForm'),
          index,
        };
      }
    });
    return foundForm;
  }

  /**
   * Get minimized drafts values
   */
  getMinimizedDraftValues() {
    return this.getEmailFormArray().controls.filter((elt: any) => _.get(elt.value, 'draftState.minimized'));
  }

  /**
   * if maximized drafts state is available
   */
  isDraftMaximised() {
    return this.getEmailFormArray().controls.some((elt) => !_.get(elt.value, 'draftState.minimized'));
  }

  /**
   * Get the whole form array
   */
  getEmailFormArray(): FormArray {
    return this.emailFormArray;
  }

  /**
   * Get the Form group from Array at Index
   */
  getEmailFormAtIndex(index): FormGroup {
    return this.emailFormArray.at(index) as FormGroup;
  }

  /**
   * Get the Draft Form from Array at Index
   */
  getDraftStateFormGroup(index): FormGroup {
    return this.emailFormArray.at(index).get('draftState') as FormGroup;
  }

  /**
   * Get the Draft State Value at Index
   */
  getDraftState(index): EmailDraftStateModel {
    if (this.emailFormArray && this.emailFormArray.at(index)) {
      return this.emailFormArray.at(index).get('draftState').value;
    } else {
      return {} as EmailDraftStateModel;
    }
  }

  /**
   * Get the title
   */
  getEmailFormTitle(index): EmailDraftStateModel {
    const name = this.emailFormArray.at(index).get('emailForm').value;
    return _.get(name, 'to[0].name', 'Email');
  }

  /**
   * Should be called by components to init a Email
   * Initializes a new Email template
   */
  initializeNewEmail(contacts: Contact[], config: EmailConfig = {}, analyticsSection?: AnalyticsSection) {
    this.checkForMaximumDraftsAndExtractSignature()
      .pipe(first())
      .subscribe((signature) => this.createNewEmailDraft(contacts, this.addSignatureToMessageHtmlTemplate(null, signature), config));
    const props: EmailReplyAnalyticModel = {
      section: analyticsSection,
      replyingTo: '', // Since this is a fresh new email replyingTo is explicitly set to null & isReply to false
      isReply: false,
    };

    this.analyticsService.send(AnalyticsEvent.OPENED_EMAIL_EDITOR, props);
  }

  checkForMaximumDraftsAndExtractSignature(): Observable<string> {
    return this.appStore.select(getProfile).pipe(
      take(1),
      switchMap((profile: Profile) => {
        // if draft check for 5 limit
        if (!this.engageModeService.getEngageModeState() && this.emailFormArray.length === this.EmailDraftLimit) {
          this.dialog.open(ErrorDialogComponent, {
            width: '500px',
            data: {
              title: 'Maximum 5 drafts',
              content: 'There’s a maximum for 5 drafts. To draft another email\n' + 'please send or discard one of the drafts.',
              secondaryBoltonDisabled: true,
            },
          });
          return throwError(null);
        } else {
          return of(profile?.attributes?.signature);
        }
      }),
    );
  }

  initializeEmailReply(contacts: Contact[], email: Invitation, invokedPage: EmailInvokedPage, analyticsSection?: AnalyticsSection) {
    this.checkForMaximumDraftsAndExtractSignature()
      .pipe(
        switchMap((signature) => {
          return this.appStore.select(getProfile).pipe(
            tap((profile: Profile) => {
              const currentUserEmail = profile?.email;
              const subject = `RE: ${email.subject}`;
              const message = extractReplyEmail(email, currentUserEmail, this.locale, this.safeHtml);
              const emailReplyInvitationId = email.id;
              this.createEmailReplyDraft(contacts, subject, this.addSignatureToMessageHtmlTemplate(message, signature), invokedPage, emailReplyInvitationId);
            }),
          );
        }),
        first(),
      )
      .subscribe();
    const props: EmailReplyAnalyticModel = {
      section: analyticsSection,
      replyingTo: email.id.toString(),
      isReply: true,
    };
    this.analyticsService.send(AnalyticsEvent.OPENED_EMAIL_EDITOR, props);
  }

  addSignatureToMessageHtmlTemplate(message: string, signature: string) {
    return signature
      ? `
      <div>
        <div><br></div>
        <div><br></div>
        <footer id="zi-email-signature" class="fr-deletable" contenteditable="false">${signature}</footer>
        <div><br></div>
        ${message ?? ''}
        <div><br></div>
      </div>
    `
      : `<div><br></div> ${message ?? ''}`;
  }

  /**
   * internal function to create form
   * Create New Email Draft Form Group and pushes into array
   */
  private createNewEmailDraft(contacts: Contact[], message: string, config: EmailConfig) {
    const emailMode = this.fb.group({
      emailForm: this.fb.group({
        isGroup: new FormControl(false),
        isReply: new FormControl(false),
        to: new FormControl(contacts ? contacts : [], Validators.required),
        from: new FormControl(''),
        cc: new FormControl([]),
        bcc: new FormControl([]),
        isTrackOpen: new FormControl(true),
        showSendOnBehalfOf: new FormControl(true),
        templateId: new FormControl(config.templateId ? config.templateId : 0),
        tileIds: new FormControl([]),
        sendEmail: new FormControl(true),
        subject: new FormControl('', [Validators.required, Validators.maxLength(SUBJECT_MAX_LENGTH)]),
        message: new FormControl(message, [Validators.required, emailSignatureValidator(), hasOnlyWhitespaceOrImgExistsValidator()]),
        isRawMessage: new FormControl(false),
        sendAt: new FormControl(0),
        attachments: new FormControl([]),
        promoCode: new FormControl(null),
        spaceId: new FormControl(0),
        isRecipientTimezone: new FormControl(false),
        spaceTags: new FormControl([]),
        startAt: new FormControl(null),
        salesWorkflowId: new FormControl(null),
        isEmailFromPreviousTaskAttached: new FormControl(false),
      }),
      draftState: this.fb.group({
        minimized: new FormControl(false),
        taskId: new FormControl(config.taskId ? config.taskId : null),
        templateId: new FormControl(config.templateId ? config.templateId : 0),
        contactId: new FormControl(_.get(contacts, '0.id', 0)),
        isExpanded: new FormControl(550),
        isBulkAction: new FormControl(!!config.isBulkAction),
        emailInvokedPage: new FormControl(config.emailInvokedPage),
      }),
    });

    // minimize other forms while adding new
    this.emailFormArray.controls.forEach((form, index) => {
      form.get('draftState').get('minimized').setValue(true);
    });

    /**
     * For template edit/new/duplicate we have to set the subject and message here only
     * as the Email component does not update the subject and message if template Id is same
     * this was done to handle the Engage mode, we load the app email component again and again while iterating over the contact array
     * so can not rewrite the subject/message every time Check email.component.html line 168
     * that is why in Save and Send option on Template we should set the subject and email first time in service.
     */
    if (config.templateInput) {
      emailMode.get('emailForm').get('templateId').setValue(_.get(config.templateInput, 'id', 0));
      emailMode.get('emailForm').get('subject').setValue(_.get(config.templateInput, 'subject', ''));
      emailMode.get('emailForm').get('message').setValue(_.get(config.templateInput, 'attributes.message', ''));
    }

    this.emailFormArray.push(emailMode);
  }

  private createEmailReplyDraft(contacts: Contact[], subject: string, message: string, invokedPage: EmailInvokedPage, emailReplyId: number) {
    const emailMode = this.fb.group({
      emailForm: this.fb.group({
        isGroup: new FormControl(false),
        isReply: new FormControl(true),
        to: new FormControl(contacts, Validators.required),
        from: new FormControl(''),
        cc: new FormControl([]),
        bcc: new FormControl([]),
        isTrackOpen: new FormControl(true),
        showSendOnBehalfOf: new FormControl(false),
        templateId: new FormControl(0),
        tileIds: new FormControl([]),
        sendEmail: new FormControl(true),
        subject: new FormControl(subject, [Validators.required, , Validators.maxLength(SUBJECT_MAX_LENGTH)]),
        message: new FormControl(message, Validators.required),
        isRawMessage: new FormControl(false),
        sendAt: new FormControl(0),
        attachments: new FormControl([]),
        promoCode: new FormControl(null),
        spaceId: new FormControl(0),
        isRecipientTimezone: new FormControl(false),
        spaceTags: new FormControl([]),
        startAt: new FormControl(null),
        salesWorkflowId: new FormControl(null),
        replyingToEmail: new FormControl(emailReplyId),
        isEmailFromPreviousTaskAttached: new FormControl(false),
      }),
      draftState: this.fb.group({
        minimized: new FormControl(false),
        taskId: new FormControl(null),
        templateId: new FormControl(0),
        contactId: new FormControl(_.get(contacts, '0.id', 0)),
        isExpanded: new FormControl(550),
        isBulkAction: new FormControl(false),
        emailInvokedPage: new FormControl(invokedPage),
      }),
    });

    // minimize other forms while adding new
    this.emailFormArray.controls.forEach((form, index) => {
      form.get('draftState').get('minimized').setValue(true);
    });

    this.emailFormArray.push(emailMode);
  }

  addFollowUpTaskControl(task: FollowUpTask, index: number) {
    const tempForm = this.emailFormArray.at(index) as FormGroup;
    const emailForm = tempForm.get('emailForm') as FormGroup;
    emailForm.addControl(
      'followUpTask',
      new FormGroup({
        dueAt: new FormControl(task ? task.dueAt : ''),
        enabled: new FormControl(task ? task.enabled : true),
        note: new FormControl(task ? task.note : ''),
        priority: new FormControl(task ? task.priority : ''),
        type: new FormControl(task ? task.type : ''),
      }),
    );
  }

  patchFollowUpTaskControl(task: FollowUpTask, index: number) {
    const tempForm = this.emailFormArray.at(index) as FormGroup;
    const followUpTask = tempForm.get('emailForm').get('followUpTask') as FormGroup;
    followUpTask.patchValue(task);
  }

  putControl(name: string, value: any, index: number) {
    const tempForm = (this.emailFormArray.at(index) as FormGroup).get('emailForm') as FormGroup;
    if (tempForm.get(name)) {
      tempForm.get(name).patchValue(value);
    } else {
      tempForm.addControl(name, new FormControl(value));
    }
  }

  clearAllEmailTemplates() {
    this.clearFormArray();
    this.emailStorageService.clearEmailTemplateSessionStorage();
  }

  /**
   * Call this on page refresh when draft mode is enabled
   */
  loadEmailDraftsFromSessionStorage() {
    if (getExtensionMode()) {
      return;
    }
    // clear the array
    this.clearFormArray();
    const templateEntities = this.emailStorageService.loadEmailCreateFromSessionStorage();
    // if value in local storage populate the form array
    if (templateEntities) {
      templateEntities.forEach((emailTemplate) => {
        this.reCreateEmailDraft(emailTemplate);
      });
    }
  }

  /**
   * load from session storage and re initialize the form Array
   */
  reCreateEmailDraft(emailObject) {
    const emailMode = this.fb.group({
      // EmailForm should be consistent with the backend specs
      emailForm: this.fb.group({
        isGroup: new FormControl(_.get(emailObject, 'emailForm.isGroup', false)),
        isReply: new FormControl(_.get(emailObject, 'emailForm.isReply', false)),
        to: new FormControl(_.get(emailObject, 'emailForm.to', []), Validators.required),
        from: new FormControl(_.get(emailObject, 'emailForm.from', '')),
        cc: new FormControl(_.get(emailObject, 'emailForm.cc', [])),
        bcc: new FormControl(_.get(emailObject, 'emailForm.bcc', [])),
        isTrackOpen: new FormControl(_.get(emailObject, 'emailForm.isTrackOpen', true)),
        showSendOnBehalfOf: new FormControl(_.get(emailObject, 'emailForm.showSendOnBehalfOf', true)),
        templateId: new FormControl(_.get(emailObject, 'emailForm.templateId', 0)),
        tileIds: new FormControl(_.get(emailObject, 'emailForm.tileIds', [])),
        sendEmail: new FormControl(_.get(emailObject, 'emailForm.sendEmail', true)),
        subject: new FormControl(_.get(emailObject, 'emailForm.subject', ''), [Validators.required, Validators.maxLength(SUBJECT_MAX_LENGTH)]),
        message: new FormControl(_.get(emailObject, 'emailForm.message', ''), Validators.required),
        isRawMessage: new FormControl(_.get(emailObject, 'emailForm.isRawMessage', false)),
        sendAt: new FormControl(_.get(emailObject, 'emailForm.sendAt', 0)),
        attachments: new FormControl(_.get(emailObject, 'emailForm.attachments', [])),
        promoCode: new FormControl(null),
        spaceId: new FormControl(_.get(emailObject, 'emailForm.spaceId', 0)),
        spaceTags: new FormControl(_.get(emailObject, 'emailForm.spaceTags', [])),
        isRecipientTimezone: new FormControl(_.get(emailObject, 'emailForm.isRecipientTimezone', false)),
        startAt: new FormControl(_.get(emailObject, 'emailForm.startAt', null)),
        salesWorkflowId: new FormControl(_.get(emailObject, 'emailForm.salesWorkflowId', null)),
        replyingToEmail: new FormControl(_.get(emailObject, 'emailForm.replyingToEmail', 0)),
        isEmailFromPreviousTaskAttached: new FormControl(_.get(emailObject, 'emailForm.isEmailFromPreviousTaskAttached', false)),
      }),
      draftState: this.fb.group({
        minimized: new FormControl(_.get(emailObject, 'draftState.minimized', false)),
        taskId: new FormControl(_.get(emailObject, 'draftState.taskId', null)),
        templateId: new FormControl(_.get(emailObject, 'draftState.templateId', 0)),
        contactId: new FormControl(_.get(emailObject, 'draftState.contactId', 0)),
        isExpanded: new FormControl(_.get(emailObject, 'draftState.isExpanded', 550)),
      }),
    });
    if (_.get(emailObject, 'emailForm.followUpTask')) {
      const emailForm = emailMode.get('emailForm') as FormGroup;
      emailForm.addControl(
        'followUpTask',
        new FormGroup({
          dueAt: new FormControl(_.get(emailObject, 'followUpTask.task.dueAt', '')),
          enabled: new FormControl(_.get(emailObject, 'followUpTask.task.enabled', true)),
          note: new FormControl(_.get(emailObject, 'followUpTask.task.note', '')),
          priority: new FormControl(_.get(emailObject, 'followUpTask.task.priority', '')),
          type: new FormControl(_.get(emailObject, 'followUpTask.task.type', '')),
        }),
      );
    }
    this.emailFormArray.push(emailMode);
  }

  expand(index: number, height: number) {
    const tempForm = this.emailFormArray.at(index) as FormGroup;
    const draftState = tempForm.get('draftState') as FormGroup;
    draftState.get('isExpanded').setValue(height);
  }

  minimizeState(index: number) {
    const tempForm = this.emailFormArray.at(index) as FormGroup;
    const draftState = tempForm.get('draftState') as FormGroup;
    draftState.get('minimized').setValue(true);
  }

  maximizeState(indexMax: number) {
    const tempForm = this.emailFormArray.at(indexMax) as FormGroup;
    const draftState = tempForm.get('draftState') as FormGroup;
    draftState.get('minimized').setValue(false);
    this.emailFormArray.controls.forEach((form, index) => {
      if (index !== indexMax) {
        form.get('draftState').get('minimized').setValue(true);
      }
    });
  }

  removeEmailDraftAfterSend(index) {
    this.emailFormArray.removeAt(index);
  }

  removeEmailDraft(index) {
    if (!this.engageModeService.getEngageModeState()) {
      this.dialog
        .open(ErrorDialogComponent, {
          width: '500px',
          data: {
            title: 'Discard draft',
            content: 'Are you sure you want to discard the email draft?',
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result) {
            this.emailFormArray.removeAt(index);
          }
        });
    }
  }

  checkForMergedVariablesUsed(emailSubject, emailContent) {
    const mergedVariablesInUse = [];
    this.allMergedVariablesList.forEach((mergeVariable: string) => {
      if (mergeVariable !== '{MySignature}' && (emailContent.includes(mergeVariable) || emailSubject.includes(mergeVariable))) {
        mergedVariablesInUse.push(mergeVariable);
      }
    });
    // if any custom field is matched push to merged fields
    const regEx = new RegExp(MergeVariableRegexPattern.customMergeVariablesWithBrackets);
    const emailSubjectCustomFields = emailSubject.match(regEx);
    const emailMessageCustomFields = emailContent.match(regEx);
    if (emailSubjectCustomFields) {
      mergedVariablesInUse.push(...emailSubjectCustomFields);
    }
    if (emailMessageCustomFields) {
      mergedVariablesInUse.push(...emailMessageCustomFields);
    }
    return mergedVariablesInUse.length > 0;
  }

  handleGroupWithMergeVariablesError() {
    this.dialog.open(ErrorDialogComponent, {
      width: '400px',
      data: {
        content: 'Group emails cannot contain variables',
        title: 'Notice',
        secondaryBoltonDisabled: true,
      },
    });
  }

  /**
   *
   * @param emailData
   * create invitation request
   */
  sendEmail(emailData: InvitationsCreateRequest): Observable<InvitationsResponse> {
    const req = {
      ...emailData,
      pdmApplication: getExtensionMode() ? PdmApplication.EngageExtension : PdmApplication.Engage,
      subject: encodeHtmlEntities(emailData.subject),
    };
    return this.featureFlagService.observe<Flag.ENABLE_API_ENCODING_FOR_WAF>(Flag.ENABLE_API_ENCODING_FOR_WAF).pipe(
      take(1),
      switchMap((apiEncodingEnabled: boolean) => {
        if (apiEncodingEnabled) {
          req.message = this.dataSerializationService.getEncodedString(req.message, StringEncodingMethod.Base64);
          return this.httpClient.post<InvitationsResponse>(this.postEncodedCreateInvitationUrl, {
            data: req,
            encoding: StringEncodingMethod.Base64,
          } as ApiEncodedPayload);
        }
        return this.httpClient.post<InvitationsResponse>(this.postCreateInvitationUrl, req);
      }),
      map((res: InvitationsResponse) => {
        this.analyticsService.sentEmailEvent(emailData, this.engageModeService.getEngageModeState() ? 'Engage' : 'Standalone');
        return res;
      }),
    );
  }

  /**
   *
   * create invitation request to yourself
   */
  sendYourselfEmail(emailData: any): Observable<InvitationsResponse> {
    return this.featureFlagService.observe<Flag.ENABLE_API_ENCODING_FOR_WAF>(Flag.ENABLE_API_ENCODING_FOR_WAF).pipe(
      take(1),
      switchMap((apiEncodingEnabled: boolean) => {
        if (apiEncodingEnabled) {
          emailData.message = this.dataSerializationService.getEncodedString(emailData.message, StringEncodingMethod.Base64);
          return this.httpClient.post<InvitationsResponse>(this.postEncodedCreateInvitationTestUrl, {
            data: emailData,
            encoding: StringEncodingMethod.Base64,
          } as ApiEncodedPayload);
        }
        return this.httpClient.post<InvitationsResponse>(this.postCreateInvitationTestUrl, emailData);
      }),
    );
  }

  clearFormArray() {
    this.emailFormArray.clear();
  }

  email_yourself(emailTemplateValue) {
    this.analyticsService.emailerIconClicked('SendToMyself');
    this.sendYourselfEmail(emailTemplateValue).subscribe(
      (resp) => {
        this.notyService.postMessage(new Message(MessageType.SUCCESS, 'Email sent to your mailbox'));
      },
      (err) => {
        const errorMessage = _.get(err, 'error.ResponseStatus.Message', 'Error sending email. Please try again later.');
        this.notyService.postMessage(new Message(MessageType.ERROR, errorMessage));
      },
    );
  }

  getEmailInvokedPage(index): string {
    if (this.engageModeV2Service.getEngageModeState()) {
      return EmailInvokedPage.ENGAGE;
    }
    const draft = this.getDraftState(index);
    return _.get(draft, 'emailInvokedPage');
  }

  resolveAttachments(templateTiles) {
    let allTemplateTiles = templateTiles;
    allTemplateTiles = allTemplateTiles.filter((tile, i) => {
      return (
        i ===
        allTemplateTiles.findIndex((item) => {
          const url1 = item.attributes.url || item.attributes.pictureUrl;
          const url2 = tile.attributes.url || tile.attributes.pictureUrl;
          return url1 === url2;
        })
      );
    });

    let emailAttachments: any[] = [];
    emailAttachments = allTemplateTiles.map((elt) => elt.attributes);
    return emailAttachments;
  }
}
