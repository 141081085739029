import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UniversityUser } from '@zi-core/data-model/profile.model';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
export interface UniversityTokenResponse {
  token: string;
}

@Injectable()
export class ZoominfoUniversityService {
  universityUrl = environment.engage_backend_url + '/university/getJwtToken';
  universityLink = environment.university;
  constructor(private httpClient: HttpClient) {}

  getUniversityJWT(profile: UniversityUser): Observable<string> {
    return this.httpClient.post<UniversityTokenResponse>(this.universityUrl, profile).pipe(map((res) => _.get(res, 'token', '')));
  }

  getUniversityNavigation(user) {
    this.getUniversityJWT(user).subscribe(
      (token) => {
        const link = this.universityLink + token;
        window.open(link, '_blank');
      },
      (error) => {
        window.open(this.universityLink, '_blank');
      },
    );
  }
}
