export enum ContactSource {
  Other = 0,
  Tellwise = 10,
  Gmail = 20,
  Rainking = 30,
  DiscoverOrg = 40,
  Salesforce = 50,
  Dynamics = 60,
  Spreadsheet = 70,
  Hubspot = 80,
  ZoomInfo = 90,
  Dozi = 100,
}
