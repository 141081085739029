import { environment } from '@env/environment';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BackendInterceptorService } from '@zi-core/interceptor/backend-interceptor.service';
import { DoziInterceptorService } from '@zi-core/interceptor/dozi-interceptor.service';
import { ImportInterceptorService } from '@zi-core/interceptor/import-interceptor.service';
import { SmsMessengerInterceptorService } from '@zi-core/interceptor/sms-messenger-interceptor.service';
import { WorkflowInterceptorService } from '@zi-core/interceptor/workflow-interceptor.service';
import { REFRESH_TOKEN_CONFIG_TOKEN, RefreshTokenInterceptor } from '@zoominfo/refresh-token-interceptor-ng12/dist/refresh-token-interceptor-ng12';

export const interceptors = [
  { provide: REFRESH_TOKEN_CONFIG_TOKEN, useValue: { env: environment.name } },
  { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: BackendInterceptorService, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: DoziInterceptorService, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ImportInterceptorService, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: SmsMessengerInterceptorService, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: WorkflowInterceptorService, multi: true },
];
