import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { ApplicationState } from '@app/reducers';
import { Store } from '@ngrx/store';
import { getValidAuthToken } from '@zi-core/ngrx/state/auth.state';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  constructor(private store: Store<ApplicationState>) {}

  /**
   * Check if the user is authenticated
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    return this.store.select(getValidAuthToken).pipe(
      take(1), // take only one to end the observable
      map((authToken) => {
        if (!authToken || !moment(new Date(authToken.expiresAt)).isAfter(moment())) {
          return true;
        } else {
          return false;
        }
      }),
    );
  }
}
