import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';
import { StringEncodingMethod } from '@zi-core/enums/string-encoding-method.enum';

@Injectable({
  providedIn: 'root',
})
export class DataSerializationService {
  /**
   * Return a encoded string from a input
   * @param data
   * @param encoding
   */
  public getEncodedString(data: any, encoding: StringEncodingMethod): string {
    if (!data) {
      return data;
    }

    let dataString;
    if (typeof data === 'string') {
      dataString = data;
    } else {
      dataString = JSON.stringify(data);
    }

    if (encoding === StringEncodingMethod.Base64) {
      return this.encodeData(dataString, 'base64');
    }

    throw new Error(`Encoding is not supported for encoding method:${encoding}`);
  }

  /**
   * Return a decoded object
   * @param data
   * @param encoding
   */
  public getDecodedString(data: string, encoding: StringEncodingMethod): string {
    if (!data) {
      return data;
    }

    if (encoding === StringEncodingMethod.Base64) {
      return this.decodeData(data, 'base64');
    }

    throw new Error(`Decoding is not supported for encoding method:${encoding}`);
  }

  /**
   * Get encoded data based on BufferEncoding type
   * note: btoa is deprecated since node v15.13.0. use Buffer.from
   * @param data
   * @param encoding
   */
  private encodeData(data: string, encoding: BufferEncoding): string {
    return Buffer.from(data).toString(encoding);
  }

  /**
   * Get decoded data from encoded string input
   * note: atob is deprecated since node v15.13.0. use Buffer.from
   * @param data
   * @param encoding
   */
  private decodeData(data: string, encoding: BufferEncoding): string {
    return Buffer.from(data, encoding).toString();
  }
}
