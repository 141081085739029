import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { SmsService } from '@zi-pages/sms/service/sms.service';

@Component({
  selector: 'app-engage-settings-dialog',
  templateUrl: './engage-settings-dialog.component.html',
  styleUrls: ['./engage-settings-dialog.component.scss'],
})
export class EngageSettingsDialogComponent implements OnInit {
  public title: string;
  primaryButtonText = 'Save';
  secondaryButtonText = 'Cancel';
  smsDisabledTooltip;
  returnValueFromXIcon = false;
  contact;
  checkBoxOptions;
  public subHeader: string;
  public isSaveButtonEnabled: boolean;
  isSmsRegistered$: Observable<boolean>;

  constructor(private dialogRef: MatDialogRef<EngageSettingsDialogComponent>, private smsService: SmsService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.isSaveButtonEnabled = false;
  }

  ngOnInit() {
    this.contact = this.data && this.data.contact ? this.data.contact : null;
    this.smsDisabledTooltip = this.contact?.optOutSms ? 'To resubscribe, customers are required \n to initiate an opt-in message' : '';
    this.isSmsRegistered$ = this.smsService.getIsSmsRegistered();

    this.primaryButtonText = _.get(this.data, 'primaryButtonText', 'Save');
    this.secondaryButtonText = _.get(this.data, 'secondaryButtonText', 'Cancel');
    this.checkBoxOptions = _.get(this.data, 'checkBoxOptions', []);
    this.subHeader = _.get(this.data, 'subHeader') || 'Unsubscribe this contact from:';
    this.title = _.get(this.data, 'title') || 'Subscription Settings';
  }

  onOptionToggle(option) {
    this.enableSaveButton();
    if (option.disabled) {
      return;
    }
    const type = option.type;
    const updatedOption = this.checkBoxOptions.find((opt) => opt.type === type);
    updatedOption.checked = !updatedOption.checked;
    this.checkBoxOptions = _.cloneDeep(this.checkBoxOptions);
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  onSend() {
    this.dialogRef.close(this.checkBoxOptions);
  }

  private enableSaveButton() {
    if (!this.isSaveButtonEnabled) {
      this.isSaveButtonEnabled = true;
    }
  }
}
