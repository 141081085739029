import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Organization } from '@zi-core/http-model/response/organization.model';
import { select, Store } from '@ngrx/store';
import { ApplicationState } from '@app/reducers';
import { filter, take, map } from 'rxjs/operators';
import { getValidAuthToken } from '@zi-core/ngrx/state/auth.state';
import { environment } from '@env/environment';
import { AuthenticatedToken } from '@zi-core/http-model/response/authenticated-token.response.model';
import { IntegrationNamesMap } from '@app/core/enums/integration-names-map.enum';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private orgId: number;

  constructor(private httpClient: HttpClient, private store: Store<ApplicationState>) {
    this.init();
  }

  init() {
    this.store
      .pipe(
        select(getValidAuthToken),
        filter((authenticatedToken: AuthenticatedToken) => !!authenticatedToken),
        take(1),
      )
      .subscribe((authToken) => {
        this.orgId = authToken.orgId;
      });
  }

  getOrganization(): Observable<{ organization: Organization }> {
    return this.httpClient.get<{ organization: Organization }>(`${environment.backend_url}/v1/org?OrgId=${this.orgId}`).pipe(
      map((res) => {
        let attributes = res.organization.attributes;
        attributes[IntegrationNamesMap.UnieSalesforce] = attributes[IntegrationNamesMap.Salesforce];

        res.organization = {
          ...res.organization,
          attributes: attributes,
        };

        return res;
      }),
    );
  }

  setAllowEnableAndCancelRecording(allowEnable = false, allowCancel = false, allowTwoParty = false) {
    const params = {
      orgId: this.orgId,
      allowRecordPhoneCalls: allowEnable,
      allowCancelRecordPhoneCalls: allowCancel,
      allowRecordPhoneCallsLegalConsent: allowTwoParty,
    };
    return this.httpClient.post<{ organization: Organization }>(`${environment.backend_url}/v1/org/enable_recording`, params);
  }
}
