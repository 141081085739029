import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpEvent, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { HttpInterceptor } from '@angular/common/http';
import { IdProviderType } from '../enums/id-povider-type.enum';
import { LoadConnectedServices } from '@zi-core/ngrx/action/auth.action';
import { Store } from '@ngrx/store';
import { ApplicationState } from '@app/reducers';
import { IntegrationNamesMap } from '../enums/integration-names-map.enum';
import { ProfileService } from '@app/common/service/profile/profile-service';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from '@zi-common/component/error-dialog/error-dialog.component';
import { ProviderResponse } from '@zi-core/http-model/response/crm.model';
import { FlagSet } from '@app/feature-flag/interfaces/flag-set.interface';
import { Flag } from '@app/feature-flag/types/flag.enum';
import { FeatureFlagService } from '@app/feature-flag/feature-flag.service';

@Injectable()
export class IntegrationsInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req);
  }
}

@Injectable()
export class IntegrationService implements OnDestroy {
  startConnectionUrl = `${environment.backend_url}/v1/oauth/start`;
  getConnectionsUrl = `${environment.backend_url}/v1/connected/services`;
  providerConnectionUrl = `${environment.backend_url}/v1/connected/providers`;
  crmAccountUrl = `${environment.backend_url}/v1/org/crmaccount`;

  unieSalesforceAuthFlag$: Observable<FlagSet[Flag.UNIE_SALESFORCE_AUTHENTICATION]>;
  isUnieSalesforceAuth: boolean;
  allSubscriptions: Subscription[] = [];

  constructor(
    private httpClient: HttpClient,
    private store: Store<ApplicationState>,
    private profileService: ProfileService,
    private dialog: MatDialog,
    private featureFlagService: FeatureFlagService,
  ) {
    this.unieSalesforceAuthFlag$ = this.featureFlagService.observe<Flag.UNIE_SALESFORCE_AUTHENTICATION>(Flag.UNIE_SALESFORCE_AUTHENTICATION);

    this.allSubscriptions.push(
      this.unieSalesforceAuthFlag$.subscribe((res) => {
        this.isUnieSalesforceAuth = res;
      }),
    );
  }

  ngOnDestroy(): void {
    this.allSubscriptions.forEach((sub) => sub.unsubscribe());
  }

  connect(idp: IdProviderType, orgId: number, callbackUrl: string, resource?: string): Observable<any> {
    if (this.isUnieSalesforceAuth && idp === IdProviderType.Salesforce) {
      idp = IdProviderType.UnieSalesforce;
    }

    let url = `${this.startConnectionUrl}?idp=${idp.toString()}&orgId=${orgId.toString()}&url=${encodeURIComponent(callbackUrl)}`;
    if (resource) {
      url = `${url}&resource=${resource}`;
    }
    return this.httpClient.get<any>(url).pipe(tap(() => this.store.dispatch(LoadConnectedServices())));
  }

  disconnect(idp: IdProviderType): Observable<any> {
    const url = `${this.providerConnectionUrl}?IdProviderType=${idp.toString()}`;
    return this.httpClient.delete<any>(url).pipe(tap(() => this.store.dispatch(LoadConnectedServices())));
  }

  getConnectedServices() {
    return this.httpClient.get<any>(this.getConnectionsUrl);
  }

  checkConnection(idp: IdProviderType, checkConnection: boolean): Observable<ProviderResponse> {
    if (this.isUnieSalesforceAuth && idp === IdProviderType.Salesforce) {
      idp = IdProviderType.UnieSalesforce;
    }
    const url = `${this.providerConnectionUrl}?IdProviderType=${idp.toString()}&checkConnection=${checkConnection}`;
    return this.httpClient.get<ProviderResponse>(url);
  }

  goToPage(url: string) {
    window.location.href = url;
  }

  toggleTriggersCrmAccount(accountId) {
    return this.httpClient.post(this.crmAccountUrl, { CrmServiceAccountId: accountId });
  }

  getTriggersCrmAccount() {
    return this.httpClient.get(this.crmAccountUrl);
  }

  checkIfOrgEnabledIntegration(integrationName: IntegrationNamesMap): Observable<boolean> {
    return this.profileService.getProfileData().pipe(
      map((res) => {
        const organizationsAttr = _.get(res, 'authenticatedToken.organizations[0].attributes');
        const contactIntegrationValue = _.get(organizationsAttr, `${integrationName}.contactsIntegration`);
        return contactIntegrationValue === 4; // value 4 -> enabled : value 0 -> disabled
      }),
    );
  }

  notConnectedToCRMDialog(crmName: IntegrationNamesMap) {
    return this.dialog
      .open(ErrorDialogComponent, {
        width: '450px',
        data: {
          title: 'Notice',
          content: `You are not connected to ${_.capitalize(crmName)}.\n Click here to connect to ${_.capitalize(crmName)} in your Engage Account Settings.`,
          secondaryBoltonDisabled: false,
          primaryButtonText: 'To Settings',
          secondaryButtonText: 'Cancel',
        },
      })
      .afterClosed();
  }
}
