export enum CompanyAccountDetailsTab {
  ACTIVITIES = 'activities',
  REPS = 'reps',
  SALESFLOWS = 'salesflows',
  NOTES = 'notes',
}

export enum ActivitiesUiFilterKeys {
  activityTypes = 'activityTypes',
}

export enum CompanyAccountActivitiesType {
  ALL = 1,
  EMAILS = 2,
  CALLS = 3,
  SALESFLOWS = 4,
}

export enum CompanyAccountDetailKey {
  ACCOUNT = 'account',
  ACTIVITIES = 'activities',
  WEBSITE_VISITS = 'websiteVisits',
  HEALTH_SCORE = 'healthScore',
  OPPORTUNITIES = 'opportunities',
  ENGAGEMENT_SUMMARY = 'engagementSummary',
  TASKS = 'tasks',
}
