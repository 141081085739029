import { Inject, Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LoggerServiceToken } from '@zi-core/config/logger-service.config';
import { ILoggerService } from '@zi-core/interface/logger.service.interface';

@Pipe({
  name: 'safeValue',
})
export class SafeValuePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer, @Inject(LoggerServiceToken) private loggerService: ILoggerService) {}

  /**
   * Used to prevent XSS by sanitizing values to be safe to use in the different DOM contexts.
   * @param value The specified value for santization.
   * @param type The type of value that would need to be sanitied (html | style | script | url | resourceUrl).
   */
  transform(value: string, type: string): string {
    try {
      if (!value) {
        return '';
      }
      switch (type) {
        case 'html':
          return this.sanitizer.sanitize(SecurityContext.HTML, value);
        case 'style':
          return this.sanitizer.sanitize(SecurityContext.STYLE, value);
        case 'script':
          return this.sanitizer.sanitize(SecurityContext.SCRIPT, value);
        case 'url':
          return this.sanitizer.sanitize(SecurityContext.URL, value);
        case 'resourceUrl':
          return this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, value);
        default:
          return this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, value);
      }
    } catch (error) {
      this.loggerService.error(error);
      return '';
    }
  }
}
