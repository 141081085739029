import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilitiesService } from '@app/common/service/utilities-service/utilities-service';
import { AnalyticsSectionType } from '@app/core/enums/analytics.enum';
import { IntegrationNamesMap } from '@app/core/enums/integration-names-map.enum';
import { ResourceType } from '@app/core/enums/resource-type.enum';
import { UpdateCompanyAccountDetailContactAction } from '@app/pages/company-accounts/ngrx/action/company-account-detail-contacts-entity.action';
import { CompanyAccountDetailContactEntityState } from '@app/pages/company-accounts/ngrx/state/company-account-detail-contact-entity.state';
import { ApplicationState } from '@app/reducers';
import { Store } from '@ngrx/store';
import { DropdownOption } from '@zi-common/component/select-overlay/select-overlay.config';
import { validationMessages } from '@zi-common/model/forms/validation-messages';
import { MessageType } from '@zi-common/model/message/message-type';
import { Message } from '@zi-common/model/message/message.model';
import { ContactCreateConfigVariable, ContactCreateMode } from '@zi-common/pages-component/contact-create/contact-create-config';
import { CrmService } from '@zi-common/service/crm/crm.service';
import { NotyService } from '@zi-common/service/noty/noty.service';
import { Contact, ContactCustomFields } from '@zi-core/data-model/contact.model';
import { CrmEntitiesNames, CrmEntityType } from '@zi-core/enums/crm-entity-type.enum';
import { getConfigurationCRM } from '@zi-core/ngrx/state/auth.state';
import { AddContactAction, UpdateContactAction } from '@zi-pages/contact/ngrx/action/contact-entity.action';
import * as _ from 'lodash';
import { combineLatest, of, Subscription } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-contact-create',
  templateUrl: './contact-create.component.html',
  styleUrls: ['./contact-create.component.scss'],
})
export class ContactCreateComponent implements OnInit, OnDestroy {
  contactCreateConfigVariable = ContactCreateConfigVariable;
  contactCreateMode = ContactCreateMode;
  selectedFields = [];
  mode: ContactCreateMode;
  contact: Contact;
  connectedCrm: IntegrationNamesMap;
  formGroup: FormGroup;
  validationMessages = validationMessages;
  subscriptions: Subscription[] = [];
  isCompanyFieldAllowed = false;
  pageSource: ResourceType;
  customFields: DropdownOption[] = [];
  customFieldsArray: FormArray = new FormArray([]);
  callSid: string;
  phoneNumberDialed: string;
  activePhoneCall: boolean;
  customDefaultValues = [];
  isFromSms: boolean;
  isOptOutSms: boolean;

  constructor(
    public dialogRef: MatDialogRef<ContactCreateComponent>,
    private store: Store<ApplicationState>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private crmService: CrmService,
    private notyService: NotyService,
    private utilitiesService: UtilitiesService,
    private companyAccountDetailContactEntityStore: Store<CompanyAccountDetailContactEntityState>,
  ) {
    this.mode = this.data && this.data.mode ? this.data.mode : ContactCreateMode.create;
    this.contact = this.data && this.data.contact ? this.data.contact : null;
    this.connectedCrm = _.get(this.data, 'connectedCrm');
    this.pageSource = this.data.pageSource || ResourceType.Contact;
    this.contactCreateConfigVariable.showMoreCommunicationFlag = false;
    this.contactCreateConfigVariable.showMoreCompanyFlag = false;
    this.callSid = this.data && this.data.callSid ? this.data.callSid : null;
    this.phoneNumberDialed = this.data && this.data.phoneNumberDialed ? this.data.phoneNumberDialed : '';
    this.activePhoneCall = this.data && this.data.activePhoneCall ? this.data.activePhoneCall : false;
    this.isFromSms = this.data && this.data.isFromSms;
    this.isOptOutSms = this.data && this.data.isOptOutSms;
  }

  ngOnInit() {
    if (this.mode === ContactCreateMode.create) {
      this.initNewForm();
    } else {
      this.initForm();
    }
    // Add the XLS injection validator to all form controls.
    for (const key in this.formGroup.controls) {
      const control = this.formGroup.get(key);

      if (key.toLocaleLowerCase().includes('phone')) {
        control.addValidators(this.utilitiesService.phoneInputValidator);
      } else if (key.toLocaleLowerCase().includes('url')) {
        control.addValidators(this.utilitiesService.urlInputValidator);
        control.addValidators(this.utilitiesService.urlPlusInputValidator);
      } else {
        control.addValidators(this.utilitiesService.xlsInjectionInputValidator);
      }
    }

    this.store
      .select(getConfigurationCRM)
      .pipe(
        take(1),
        map((setting) => {
          this.isCompanyFieldAllowed = _.get(setting, 'newProspectCreateEntityType') === CrmEntitiesNames[CrmEntityType.Lead];
          if (this.isCompanyFieldAllowed) {
            this.formGroup.get('company').setValidators(Validators.required);
          }
          return _.get(setting, 'newProspectCreateEntityType');
        }),
        switchMap((entity) => {
          const crmEntity = entity === CrmEntitiesNames[CrmEntityType.Contact] ? CrmEntityType.Contact : CrmEntityType.Lead;
          return combineLatest(
            this.crmService.getCRMEntityFieldMapConfigV2(crmEntity).pipe(
              map((crmConfig) => crmConfig.crmFields),
              catchError(() => {
                return of([]);
              }),
            ),
            this.crmService.getCustomFieldsNamesBasedOnCrmEntity(CrmEntityType.Contact).pipe(
              catchError(() => {
                this.notyService.postMessage(new Message(MessageType.ERROR, `Failed to load custom fields`));
                return of([]);
              }),
            ),
          );
        }),
      )
      .subscribe(([crmConfig, customFields]) => {
        this.customDefaultValues = crmConfig.filter((elt) => elt.isCustomField);
        customFields.forEach((elt) => {
          const customObject = {
            contactId: this.contact ? this.contact.id : 0,
            fieldName: elt.fieldName,
            value: this.contact && this.contact.customFields ? this.searchContactOrDefault(elt.fieldName) : this.searchDefaultValue(elt.fieldName),
          };
          this.customFieldsArray.push(this.createCustomField(customObject));
        });
      });
  }

  // Search if contact has the value for custom fields - else search the default - else ''
  searchContactOrDefault(searchVal) {
    const foundC = this.contact.customFields.find((c) => c.fieldName === searchVal);
    return foundC ? foundC.value : this.searchDefaultValue(searchVal);
  }

  searchDefaultValue(searchVal) {
    const defaultVal = this.customDefaultValues.find((e) => e.tellwiseFieldName === searchVal);
    return defaultVal && defaultVal.crmFieldDefaultValue ? defaultVal.crmFieldDefaultValue : '';
  }

  /**
   * Add custom field object to form array
   */
  createCustomField(customObject: ContactCustomFields): FormGroup {
    const cf = this.formBuilder.group({
      contactId: new FormControl(customObject.contactId ? customObject.contactId : 0),
      fieldName: new FormControl(customObject && customObject.fieldName ? customObject.fieldName : '', [Validators.required, this.duplicateFn]),
      value: new FormControl(customObject && customObject.value ? customObject.value : '', [this.utilitiesService.xlsInjectionInputValidator]),
    });
    if (
      this.mode === ContactCreateMode.update &&
      this.contact &&
      !this.crmService.isBiDirectionalSyncFileMappingEnabled(_.get(customObject, 'fieldName', ''), this.contact)
    ) {
      cf.disable();
    }
    return cf;
  }

  initNewForm() {
    this.formGroup = this.formBuilder.group({
      // contact fields
      firstName: [
        this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.firstName) : '',
        [Validators.required, this.utilitiesService.newNameValidator],
      ],
      lastName: [
        this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.lastName) : '',
        [Validators.required, this.utilitiesService.newNameValidator],
      ],
      email: [this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.email) : '', [Validators.required, Validators.email]],
      jobTitle: [this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.jobTitle) : ''],
      department: [this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.department) : ''],
      salutation: [this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.salutation) : ''],
      description: [this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.description) : ''],
      // communication fields
      phone: [
        this.phoneNumberDialed && !this.isFromSms ? this.phoneNumberDialed : this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.phone) : '',
      ],
      mobilePhone: [this.isFromSms ? this.phoneNumberDialed : this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.mobilePhone) : ''],
      homePhone: [this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.homePhone) : ''],
      assistantPhone: [this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.assistantPhone) : ''],
      hqPhone: [this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.hqPhone) : ''],
      otherPhone: [this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.otherPhone) : ''],
      // company fields
      company: [this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.company) : ''],
      companyPageUrl: [this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.companyPageUrl) : ''],
      industry: [this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.industry) : ''],
      annualRevenue: [this.contact ? this.contact.annualRevenue : ''],
      numberOfEmployees: [this.contact ? this.contact.numberOfEmployees : ''],
      companyLinkedInUrl: [this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.companyLinkedInUrl) : ''],
      companyTwitterUrl: [this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.companyTwitterUrl) : ''],
      // address fields
      country: [this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.country) : ''],
      state: [this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.state) : ''],
      city: [this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.city) : ''],
      street: [this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.street) : ''],
      zip: [this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.zip) : ''],
      // social media fields
      linkedInUrl: [this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.linkedInUrl) : ''],
      twitterUrl: [this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.twitterUrl) : ''],
      // misc
      doNotEmail: [this.contact ? this.contact.doNotEmail : false],
      doNotCall: [this.contact ? this.contact.doNotCall : false],
      optOutSms: [this.contact ? this.contact.optOutSms : this.isFromSms ? this.isOptOutSms : false],
    });
  }

  initForm() {
    const isContactSynced = _.get(this.contact, 'externalId');
    this.formGroup = this.formBuilder.group({
      // contact fields
      firstName: [
        {
          value: this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.firstName) : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('firstName', this.contact),
        },
        [Validators.required, this.utilitiesService.newNameValidator],
      ],
      lastName: [
        {
          value: this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.lastName) : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('lastName', this.contact),
        },
        [Validators.required, this.utilitiesService.newNameValidator],
      ],
      email: [
        {
          value: this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.email) : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('email', this.contact),
        },
        [Validators.required, Validators.email],
      ],
      jobTitle: [
        {
          value: this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.jobTitle) : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('jobTitle', this.contact),
        },
      ],
      department: [
        {
          value: this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.department) : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('department', this.contact),
        },
      ],
      salutation: [
        {
          value: this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.salutation) : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('salutation', this.contact),
        },
      ],
      description: [
        {
          value: this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.description) : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('description', this.contact),
        },
      ],
      // communication fields
      phone: [
        {
          value: this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.phone) : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('phone', this.contact),
        },
      ],
      mobilePhone: [
        {
          value: this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.mobilePhone) : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('mobilePhone', this.contact),
        },
      ],
      homePhone: [
        {
          value: this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.homePhone) : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('homePhone', this.contact),
        },
      ],
      assistantPhone: [
        {
          value: this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.assistantPhone) : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('assistantPhone', this.contact),
        },
      ],
      hqPhone: [
        {
          value: this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.hqPhone) : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('hqPhone', this.contact),
        },
      ],
      otherPhone: [
        {
          value: this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.otherPhone) : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('otherPhone', this.contact),
        },
      ],
      // company fields
      // When it's ready to reflect this change in PROD, change it to disabled: true.
      company: [
        {
          value: this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.company) : '',
          disabled: true,
        },
      ],
      companyPageUrl: [
        {
          value: this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.companyPageUrl) : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('companyPageUrl', this.contact),
        },
      ],
      industry: [
        {
          value: this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.industry) : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('industry', this.contact),
        },
      ],
      annualRevenue: [
        {
          value: this.contact ? this.contact.annualRevenue : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('annualRevenue', this.contact),
        },
      ],
      numberOfEmployees: [
        {
          value: this.contact ? this.contact.numberOfEmployees : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('numberOfEmployees', this.contact),
        },
      ],
      companyLinkedInUrl: [
        {
          value: this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.companyLinkedInUrl) : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('companyLinkedInUrl', this.contact),
        },
      ],
      companyTwitterUrl: [
        {
          value: this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.companyTwitterUrl) : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('companyTwitterUrl', this.contact),
        },
      ],
      // address fields
      country: [
        {
          value: this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.country) : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('country', this.contact),
        },
      ],
      state: [
        {
          value: this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.state) : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('state', this.contact),
        },
      ],
      city: [
        {
          value: this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.city) : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('city', this.contact),
        },
      ],
      street: [
        {
          value: this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.street) : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('street', this.contact),
        },
      ],
      zip: [
        {
          value: this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.zip) : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('zip', this.contact),
        },
      ],
      // social media fields
      linkedInUrl: [
        {
          value: this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.linkedInUrl) : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('linkedInUrl', this.contact),
        },
      ],
      twitterUrl: [
        {
          value: this.contact ? this.utilitiesService.removeWhiteSpaces(this.contact.twitterUrl) : '',
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('twitterUrl', this.contact),
        },
      ],
      // misc
      doNotEmail: [
        {
          value: this.contact ? this.contact.doNotEmail : false,
          disabled:
            !this.crmService.isBiDirectionalSyncFileMappingEnabled('doNotEmail', this.contact) ||
            (this.connectedCrm === IntegrationNamesMap.Hubspot && this.contact.doNotEmail && isContactSynced),
        },
      ],
      doNotCall: [
        {
          value: this.contact ? this.contact.doNotCall : false,
          disabled: !this.crmService.isBiDirectionalSyncFileMappingEnabled('doNotCall', this.contact),
        },
      ],
    });
  }

  toggleAdditionalCommunicationFields() {
    this.contactCreateConfigVariable.showMoreCommunicationFlag = !this.contactCreateConfigVariable.showMoreCommunicationFlag;
  }

  toggleAdditionalCompanyFields() {
    this.contactCreateConfigVariable.showMoreCompanyFlag = !this.contactCreateConfigVariable.showMoreCompanyFlag;
  }

  close() {
    this.dialogRef.close();
  }

  submit() {
    const submitVal = this.formGroup.value as Contact;
    const customValues = this.customFieldsArray.controls.filter((fb) => fb.valid).map((fg) => fg.value);
    let updatedContact: Contact = { ...this.contact, ...submitVal };
    updatedContact = { ...updatedContact, customFields: [...customValues] };

    if (this.mode === ContactCreateMode.create) {
      if (this.activePhoneCall) {
        this.store.dispatch(
          AddContactAction({
            payload: updatedContact,
            callSid: this.callSid,
            isFromSms: false,
            analyticsData: { section: AnalyticsSectionType.SideNav },
          }),
        );
      } else {
        this.store.dispatch(
          AddContactAction({
            payload: updatedContact,
            isFromSms: this.isFromSms,
            analyticsData: { section: AnalyticsSectionType.SideNav },
          }),
        );
      }
    } else if (this.mode === ContactCreateMode.update) {
      switch (this.pageSource) {
        case ResourceType.CompanyAccountContact: {
          this.companyAccountDetailContactEntityStore.dispatch(UpdateCompanyAccountDetailContactAction({ payload: updatedContact, oldContact: this.contact }));
          break;
        }
        default: {
          this.store.dispatch(UpdateContactAction({ payload: updatedContact, oldContact: this.contact }));
        }
      }
    }

    this.dialogRef.close();
  }

  duplicateFn(control: AbstractControl): ValidationErrors | null {
    if (control && control.parent && control.parent.parent) {
      const newValue = control ? control.value : '';
      const valuesArr: FormArray = control.parent.parent as FormArray;
      let idx = -1;
      if (newValue !== '') {
        idx = valuesArr.value.findIndex((ele) => ele.fieldName === newValue);
      }
      if (idx !== -1) {
        return { duplicate: true };
      }
    }
    return null;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
