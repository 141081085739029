import { Invitation } from '@zi-core/data-model/invitation.model';
import _, { isEmpty } from 'lodash';
import { formatDate } from '@angular/common';
import { InvitationEmailReply } from '@zi-core/data-model/invitation-email-reply.model';
import { SafeHtmlPipe } from '@zi-common/pipe/safe-value/safe-html.pipe';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const extractReplyEmail = (email: Invitation, currentUserEmail: string, locale: string, safeHtml: SafeHtmlPipe) => {
  const emailReplies = email.emailReplies || [];
  const sortedEmailReplies = _.sortBy(emailReplies, 'createdAt');
  return `<div style="${REPLY_STYLE}">
                  ${extractHeader(email, sortedEmailReplies, currentUserEmail, locale)}
                  ${extractEmailBody(email, sortedEmailReplies, safeHtml)}
            </div>`;
};

const extractEmailBody = (email: Invitation, emailReplies: Array<InvitationEmailReply>, safeHtml: SafeHtmlPipe) => {
  const emailBody = isEmpty(emailReplies) ? email.body : emailReplies[0].messageBody;
  const sanitizedEmailBody = safeHtml.transform(emailBody, false);
  return `<div style = "${EMAIL_BODY_STYLE}">${sanitizedEmailBody}</div>`;
};

const buildEmailHeaderSubjectHtmlTemplate = (email: Invitation, emailReplies: Array<InvitationEmailReply>) => {
  const subject = isEmpty(emailReplies) ? email.subject : emailReplies[0].messageSubject;
  return !_.isEmpty(subject)
    ? `<div style="${EMAIL_HEADER_ELEMENT_STYLE}">
            ${subject}
          </div>`
    : '';
};

const buildEmailHeaderDateHtmlTemplate = (isReply: boolean, date: Date | string, locale: string) => {
  return `<div style="${EMAIL_HEADER_ELEMENT_STYLE}">
            ${isReply ? 'Replied' : 'Sent'}: ${formatDate(date, 'MMM dd, hh:mm a', locale)}
          </div>`;
};

const extractHeader = (email: Invitation, emailReplies: Array<InvitationEmailReply>, currentUserEmail: string, locale: string) => {
  const createdDate = isEmpty(email.emailReplies) ? email.createdAt : emailReplies[0].createdAt;
  const isReply = isEmpty(emailReplies) ? false : emailReplies[0].fromEmail?.trim() !== currentUserEmail.trim();
  return `<div style="${EMAIL_HEADER_STYLE}">
              ${buildEmailHeaderDateHtmlTemplate(isReply, createdDate, locale)}
              ${buildEmailHeaderSubjectHtmlTemplate(email, emailReplies)}
          </div>`;
};

const REPLY_STYLE = `padding-top: 10px;`;
const EMAIL_BODY_STYLE = `padding-bottom: 12px;
  font-size: 12px;
  color: #1A1D3F;`;

const EMAIL_HEADER_STYLE = `
  padding-bottom: 5px;`;

const EMAIL_HEADER_ELEMENT_STYLE = `
  padding-bottom: 5px; font-size: 12px; color: #1A1D3F; font-weight: bold`;

export const emailSignatureValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const val: Document = new DOMParser().parseFromString(control.value, 'text/html');
      const footerTag = val.getElementById('zi-email-signature') as HTMLElement;
      const imagesInValue = new DOMParser().parseFromString(control.value, 'text/html').body?.querySelectorAll('img');
      const imagesInFooterTag = footerTag?.querySelectorAll('img');
      // Detect if body is contains images does not related to signature
      if (imagesInValue?.length > 0 && imagesInValue?.length !== imagesInFooterTag?.length) {
        return null;
      }
      if (footerTag) {
        return val?.body?.innerText?.trim()?.length - footerTag?.innerText?.trim()?.length <= 0 ? { bodyIsEmpty: true } : null;
      } else {
        return val?.body?.innerText?.trim()?.length === 0 ? { bodyIsEmpty: true } : null;
      }
    }
    return null;
  };
};

export const hasOnlyWhitespaceOrImgExistsValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      const isImageAttachedToBody = !!new DOMParser().parseFromString(control.value, 'text/html').body?.querySelector('img');
      const textNotWhiteSpaceExists = !!new DOMParser().parseFromString(control.value, 'text/html').body?.innerText.trim();
      return isImageAttachedToBody || textNotWhiteSpaceExists ? null : { hasOnlyWhiteSpace: true };
    }

    return null;
  };
};
