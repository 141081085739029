import { Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { EngageVersionStringTransformService } from '@zi-common/service/engage-version-string-transform/engage-version-string-transform.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-dotten-text',
  templateUrl: './dotten-text.component.html',
  styleUrls: ['./dotten-text.component.scss'],
})
export class DottenTextComponent implements OnInit, OnChanges {
  @Input() text: string;
  @Input() position = 'above';
  @Input() hasTooltip = false;
  @Input() tooltipClass = '';
  @Input() isLink = false;
  @Input() disabled = false;
  @Input() elementRef: ElementRef;
  @Input() customTooltipText = '';
  @Input() enableAppVerTranslation = false;

  public needTooltip: boolean;
  private textValueModified$: BehaviorSubject<boolean>;

  @ViewChild('someInput') someInput: ElementRef;

  @HostListener('mouseenter') showTooltip() {
    if (this.someInput.nativeElement.clientWidth < this.someInput.nativeElement.scrollWidth || this.customTooltipText?.length) {
      this.needTooltip = true;
    }
  }

  @HostListener('mouseleave') hideTooltip() {
    this.needTooltip = false;
  }

  constructor(private stringTransformService: EngageVersionStringTransformService) {
    this.textValueModified$ = new BehaviorSubject<boolean>(false);
    this.needTooltip = false;
  }

  public ngOnInit() {
    this.transformTextValue();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!_.isEqual(_.get(changes, 'text.currentValue'), _.get(changes, 'text.previousValue'))) {
      this.textValueModified$.next(true);
      this.setTextAsString();
    }
  }

  /**
   * Private functions
   */

  private setTextAsString() {
    if (_.isArray(this.text)) {
      this.text = this.text.join(', ');
    }
  }

  private transformTextValue() {
    combineLatest([this.stringTransformService.userRole$, this.textValueModified$])
      .pipe(
        untilDestroyed(this),
        filter(() => !!this.enableAppVerTranslation),
      )
      .subscribe(([userRole, textValueModified]) => {
        this.text = this.stringTransformService.getTransformedText(userRole, this.text);
      });
  }
}
