import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { EngageVersionEnum } from '@zi-core/enums/engage-version.enum';
import { EngageVersionStringModel, TextTransEngageVerRegexMapModel, TextTransformationMapModel } from '@zi-common/interface/user-licenses.model';

@Injectable({
  providedIn: 'root',
})
export class EngageVersionStringTransformConfig {
  /**
   * Engage base version is always sales.
   * Note: This should be a private variable and should to be accessed using the getter method only
   * post ordering of the ds.
   */
  private readonly textTransformationMap: TextTransformationMapModel = {
    [EngageVersionEnum.RECRUITER]: [
      { key: 'Salesflow', value: 'Talentflow' },
      { key: 'salesflow', value: 'talentflow' },
      { key: 'Prospect', value: 'Candidate' },
      { key: 'prospect', value: 'candidate' },
      { key: 'hot prospect', value: 'prospective candidate' },
      { key: 'SalesWorkflow', value: 'TalentWorkflow' },
      { key: 'salesWorkflow', value: 'talentWorkflow' },
    ],
  };

  public textTransEngageVerRegexMap: TextTransEngageVerRegexMapModel = {};

  constructor() {
    this.orderTextTransformMap();
  }

  /**
   * Returns the k/v list of string mapping objects for the specific roleVersion.
   * @param roleVersion - Exclusive user role/engage version
   */
  public getTextTransformationList(roleVersion: EngageVersionEnum): EngageVersionStringModel[] {
    return this.textTransformationMap[roleVersion];
  }

  /**
   * Function performing ordering of textTransformationMap also creating regex matcher object list for all EngageVersionEnum keys.
   * Ordering is needed to efficiently find and replace strings.
   */
  private orderTextTransformMap() {
    Object.keys(this.textTransformationMap).forEach((key) => {
      const keyValue: EngageVersionStringModel[] = this.textTransformationMap[key];
      this.textTransformationMap[key] = _.orderBy(keyValue, [(o) => _.get(o, 'key.length')], 'desc');
      this.textTransEngageVerRegexMap[key] = new RegExp(this.textTransformationMap[key].map((obj) => obj.key).join('|'), 'g');
    });
  }
}
