export enum DialerSetupStep {
  ENABLE = 'enable',
  TYPE = 'type',
  VOIP = 'voip',
  BRIDGE = 'bridge',
}

export enum BridgeDialerVerificationStep {
  UNVERIFIED = 'phoneInput',
  ON_VERIFYING = 'verifyCode',
  VERIFY_SUCCESS = 'success',
  VERIFIED = 'verifiedPhone',
}

export enum DialerSelection {
  VOIP = 'voip',
  BRIDGE = 'bridge',
  NONE = 'noDialer',
}

export enum VoicemailType {
  GREETING = 'Greeting',
  DROP = 'Drop',
}
