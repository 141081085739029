import { Injectable } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { ClearFilterOptions } from '@app/common/component/list-checkbox-v2/list-checkbox-config';
import { ElementRefDirective } from '@app/common/directive/element-ref/element-ref.directive';
import { FailedDeliveryRadioOptions, NonDateFields, RadioOptions } from '@app/common/pages-component/engage-email-filter-panel/engage-email-filter-config';
import * as _ from 'lodash';
import { fromEvent, Observable, Subject } from 'rxjs';
import { SafeHtmlPipe } from '@zi-common/pipe/safe-value/safe-html.pipe';
import { UmsToken } from '@zi-common/service/ums-waffle-menu/ums-waffle-navbar.config';
import { TemplateCategory } from '@zi-common/interface/template-category.enum';
import { doziAccessTokenCookieName } from '@app/login/login.config';
import * as moment from 'moment/moment';
import { CookieService } from 'ngx-cookie-service';
import { Moment } from 'moment';

export enum NextContactDirection {
  UP = 'ArrowUp',
  DOWN = 'ArrowDown',
}

@Injectable({ providedIn: 'root' })
export class UtilitiesService {
  documentClickedTarget: Subject<HTMLElement> = new Subject<HTMLElement>();
  buttonClicked: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  windowResize: Observable<Event> = fromEvent(window, 'resize');

  constructor(private safeHtmlPipe: SafeHtmlPipe, private cookieService: CookieService) {}
  stripHtml(html, sanitizingHtml = true) {
    if (!html) {
      return '';
    }

    // Create a new div element
    const temporalDivElement = document.createElement('div');
    // If sanitizingHtml is true, sanitize the html string to prevent XSS
    if (sanitizingHtml) {
      html = this.safeHtmlPipe.transform(html, false);
    }
    temporalDivElement.innerHTML = html;
    return temporalDivElement.textContent || temporalDivElement.innerText || '';
  }

  getElementOffset(element) {
    const de = document.documentElement;
    const box = element.getBoundingClientRect();
    const top = box.top + window.pageYOffset - de.clientTop;
    const left = box.left + window.pageXOffset - de.clientLeft;
    return { top, left };
  }

  isElementOutOfBoundBottom(element: ElementRefDirective) {
    return element.getElementOffsetBottom() > window.innerHeight;
  }

  checkDropdownOverflow(element, dropdownClassName) {
    const pageHeight = _.get(document, 'documentElement.offsetHeight') * 0.9;
    const boxPos = this.getElementOffset(element).top;
    const dropdownEl = element.getElementsByClassName(dropdownClassName)[0];
    const boxHeight = _.get(dropdownEl, 'clientHeight');
    return pageHeight - boxPos < boxHeight;
  }

  scrollToNextElementAndGetIndexOfTheNextElement(
    nextContactDirection: NextContactDirection | string,
    containerNativeElement: HTMLElement,
    currentElementIndex: number,
    elementRowsArray: ElementRefDirective[],
  ): number {
    let index = -1;
    const currentElementRow = elementRowsArray[currentElementIndex];
    switch (nextContactDirection) {
      case NextContactDirection.UP:
        containerNativeElement.scrollTop += this.getSizeToScroll(containerNativeElement, currentElementRow, elementRowsArray[currentElementIndex - 1]);
        index = Math.max(0, currentElementIndex - 1);
        break;
      case NextContactDirection.DOWN:
        containerNativeElement.scrollTop += this.getSizeToScroll(containerNativeElement, currentElementRow, elementRowsArray[currentElementIndex + 1]);
        index = Math.min(elementRowsArray.length - 1, currentElementIndex + 1);
        break;
    }
    return index;
  }

  removeWhiteSpaces(text: string) {
    if (!text) {
      return '';
    }
    text = text.replace(/(\\r\\n|\\n|\\r)/gm, ' ');
    text.trim();
    return text;
  }

  isUpOrDownKey(key: string): boolean {
    return key === NextContactDirection.UP || key === NextContactDirection.DOWN;
  }

  private getSizeToScroll(containerNativeElement: HTMLElement, currentElementRow: ElementRefDirective, nextElementRow: ElementRefDirective): number {
    let scrollPosition = 0;

    if (!nextElementRow) {
      nextElementRow = currentElementRow;
    }

    if (nextElementRow) {
      const containerOffsetTop = containerNativeElement.getBoundingClientRect().top;
      const containerOffsetBottom = containerOffsetTop + containerNativeElement.offsetHeight;
      const nextElementRowOffsetTop = nextElementRow.getAbsoluteOffsetTop();
      const nextElementRowHeight = nextElementRow.getElementFullHeight();
      const nextElementRowOffsetBottom = nextElementRowOffsetTop + nextElementRowHeight;
      let difference = 0;

      // If the next contact is hidden by the lower border of the container
      difference = nextElementRowOffsetBottom - containerOffsetBottom;
      scrollPosition += Math.max(0, difference);

      // If the next contact is hidden by the upper border of the container
      difference = containerOffsetTop - nextElementRowOffsetTop;
      scrollPosition -= Math.max(0, difference);
    }

    return scrollPosition;
  }

  public adjustDataForEngagementFilters(filtersAsArray) {
    const emailedFilters = filtersAsArray.filter((r) => {
      return r.categoryKey === 'SentDate';
    });

    let dateValue = "'1970-01-01T00:00:00Z', ";

    if (emailedFilters.length) {
      // get the date from Last Emailed
      dateValue = emailedFilters[0].value.substring(1, emailedFilters[0].value.length - 1);
    }

    const otherFilters = filtersAsArray.filter((r) => {
      return r.categoryKey === 'EmailActivity';
    });
    otherFilters.map((r) => {
      // these keys are not date fields.
      if (!(r.key in NonDateFields)) {
        r.value = r.value.replace('placeholder,', dateValue);
      }
    });

    const callFilters = filtersAsArray.filter((r) => {
      return r.categoryKey !== 'EmailActivity' && r.categoryKey !== 'SentDate';
    });

    // Last emailed and Email activity cannot be in the same chip
    if (otherFilters.length) {
      return [...otherFilters, ...callFilters];
    }

    return [...emailedFilters, ...callFilters];
  }

  public checkForDuplicateRequests(arr): boolean {
    if (arr && arr.length) {
      const idx = arr.findIndex((x) => x.displayName === ClearFilterOptions.Opened);
      if (idx > -1) {
        arr.splice(idx, 1);
        // cancel the duplicate request.
        if (arr.findIndex((x) => x.displayName in RadioOptions) < 0 || arr.length == 0) {
          return false;
        }
      }
      let indexFailedDelivery = arr.findIndex((x) => x.displayName === ClearFilterOptions.FailedDelivery);
      const numOfFailedDeliveryRadioOptions = arr.filter((x) => x.displayName in FailedDeliveryRadioOptions).length;
      while (indexFailedDelivery > -1) {
        arr.splice(indexFailedDelivery, 1);
        if (!arr.length) {
          return false;
        }
        indexFailedDelivery = arr.findIndex((x) => x.displayName === ClearFilterOptions.FailedDelivery);
      }
      if (arr.filter((x) => x.displayName in FailedDeliveryRadioOptions).length !== numOfFailedDeliveryRadioOptions) {
        return false;
      }
    }
    return true;
  }

  /**
   * Fn checks if clicked element is within the target element or not.
   * @param targetEl - Parent element
   * @param clickedEl - Element currently clicked
   */
  public isChildNodeClicked(targetEl: HTMLElement, clickedEl: HTMLElement): boolean {
    let node = clickedEl;
    while (node !== null) {
      if (node === targetEl) {
        return true;
      } else {
        node = node.parentElement;
      }
    }
    return false;
  }

  public newNameValidator(fc: FormControl) {
    return !(fc?.value?.replace(/^\s+|\s+$/gm, '') === '') ? null : { 'new-Name-field-error': true };
  }

  public urlInputValidator(fc: FormControl): ValidationErrors | null {
    if (typeof fc?.value === 'string' || fc?.value instanceof String) {
      const res = fc?.value?.includes('=');

      return !res ? null : { 'url-equal-field-error': true };
    }
    return null;
  }

  public urlPlusInputValidator(fc: FormControl): ValidationErrors | null {
    if (typeof fc?.value === 'string' || fc?.value instanceof String) {
      const res = ['+'].some((char) => fc?.value?.startsWith(char));

      return !res ? null : { 'url-plus-field-error': true };
    }
    return null;
  }

  /**
   * Custom validator of specific charaters that may expose security vulnerabilities when injected to spreadsheets.
   * @param fc The form control to validate.
   */
  public xlsInjectionInputValidator(fc: FormControl): { 'xls-injection-field-error': boolean } | null {
    if (typeof fc?.value === 'string' || fc?.value instanceof String) {
      // See: https://discoverorg.atlassian.net/browse/IS-535

      const res = ['+', '-'].some((char) => fc?.value?.startsWith(char)) || fc?.value?.includes('=');

      // Return null if valid or the xls error if invalid.
      return !res ? null : { 'xls-injection-field-error': true };
    }
    return null;
  }

  public phoneInputValidator(fc: FormControl): ValidationErrors | null {
    if (typeof fc?.value === 'string' || fc?.value instanceof String) {
      const res = ['-'].some((char) => fc?.value?.startsWith(char)) || fc?.value?.includes('=');

      return !res ? null : { 'equal-phone-error': true };
    }
    return null;
  }

  decodeJWT(token: string): UmsToken {
    if (!token) {
      return null;
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`;
        })
        .join(''),
    );
    return JSON.parse(jsonPayload);
  }

  isZiAccessTokenValid(): boolean {
    const ziAccessTokenExpirationEpochTime = this.decodeJWT(this.cookieService.get(doziAccessTokenCookieName))?.exp;
    const ziAccessTokenExpiration = ziAccessTokenExpirationEpochTime ? moment.unix(ziAccessTokenExpirationEpochTime) : null;
    return ziAccessTokenExpiration && ziAccessTokenExpiration.diff(moment.now()) > 0;
  }

  fetchZiAccessTokenExpiry(): Moment | null {
    const ziAccessTokenExpirationEpochTime = this.decodeJWT(this.cookieService.get(doziAccessTokenCookieName))?.exp;
    return ziAccessTokenExpirationEpochTime ? moment.unix(ziAccessTokenExpirationEpochTime) : null;
  }

  getTemplateCategoryByScope(scope: number): TemplateCategory {
    switch (scope) {
      case 1:
        return TemplateCategory.MyTemplates;
      case 2:
        return TemplateCategory.MyTeam;
      case 3:
        return TemplateCategory.AllOrg;
    }
  }

  /**
   * Sanitizes HTML content potentially containing unsafe element - <script> tag
   * @param htmlContent
   */
  sanitizeHtml(htmlContent: string): string {
    if (!htmlContent || !this.containsHtmlBase64DataURL(htmlContent)) {
      return htmlContent;
    }

    return this.sanitizePotentiallyUnsafeHtml(htmlContent);
  }

  /**
   * Checks if the input string contains a base64 encoded HTML data URL.
   * @param input - The input string to check
   */
  containsHtmlBase64DataURL(input: string): boolean {
    const regex = /data:(?:text\/html)?(?:;base64)?,/gi;
    return regex.test(input);
  }

  /**
   * Sanitizes HTML content potentially containing unsafe element - <script> tag
   * This function ensures the content is safe for rendering to prevent Cross-Site Scripting (XSS) attacks.
   * @param htmlContent - The HTML content to be sanitized
   */
  sanitizePotentiallyUnsafeHtml(htmlContent: string): string {
    const match = htmlContent.match(/base64,(.+?)"/);
    if (match && match[1]) {
      const decodedText = atob(match[1]);
      if (decodedText.match(/<script>/)) {
        return ''; // Returning an empty string to prevent potential script execution
      }
    }

    return htmlContent;
  }
}
