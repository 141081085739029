import { Action, createReducer, on } from '@ngrx/store';
import {
  ClearSignatureAction,
  GetSignatureAction,
  GetSignatureFailureAction,
  GetSignatureSuccessAction,
} from '@zi-pages/on-boarding/ngrx/action/on-boarding-signature.action';
import { initialOnBoardingSignatureEntity, OnBoardingSignatureEntityState } from '@zi-pages/on-boarding/ngrx/state/on-boarding-signature.state';

const OnBoardingSignatureReducer = createReducer(
  initialOnBoardingSignatureEntity,

  on(GetSignatureAction, (state: OnBoardingSignatureEntityState, { name, email }) => {
    return { ...state, loading: true, loaded: false };
  }),
  on(GetSignatureSuccessAction, (state: OnBoardingSignatureEntityState, { signature }) => {
    return { ...state, loaded: true, loading: false, emailSignature: signature };
  }),
  on(GetSignatureFailureAction, (state: OnBoardingSignatureEntityState) => {
    return { ...state, loaded: true, loading: false };
  }),
  on(ClearSignatureAction, (state: OnBoardingSignatureEntityState) => {
    return { ...initialOnBoardingSignatureEntity };
  }),
);

export function OnBoardingSignatureEntityReducer(state: OnBoardingSignatureEntityState, action: Action): OnBoardingSignatureEntityState {
  return OnBoardingSignatureReducer(state, action);
}
