export class Tab {
  constructor(
    public displayName: string,
    public value: any,
    public isLocked?: boolean,
    public isDisabled?: boolean,
    public isSelected?: boolean,
    public entitiesCount?: number,
    public icon?: string,
    public notificationCount?: number,
    public displayNotificationCount?: boolean,
    public automationId?: string,
  ) {}
}
