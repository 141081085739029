import { EntityDialerService } from '@app/caller/service/entity-dialer.service';
import { Inject, Injectable } from '@angular/core';
import { CallInfo, CallRecordingInfo, ContactCallInfo, initialCallInfo } from '@app/caller/interface/call-info';
import { PhoneTypesEnum } from '@app/caller/interface/phone-types-enum';
import { BehaviorSubject, combineLatest, interval, Observable, of, Subscription } from 'rxjs';
import { CallStatusEnum } from '@app/caller/interface/call-status-enum';
import { Message } from '@zi-common/model/message/message.model';
import { MessageType } from '@zi-common/model/message/message-type';
import { DialerResponse } from '@zi-core/http-model/response/dialer.response.model';
import { catchError, distinctUntilChanged, filter, finalize, map, mergeMap, pairwise, switchMap, take, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { getValidAuthToken } from '@zi-core/ngrx/state/auth.state';
import { Contact } from '@zi-core/data-model/contact.model';
import { NotyService } from '@zi-common/service/noty/noty.service';
import { ContactInternalDataService } from '@zi-pages/contact/service/contact-internal-data.service';
import { OrganizationService } from '@zi-common/service/organization/organization.service';
import { IntegrationService } from '@zi-core/service/integration.service';
import { PhoneVerifierService } from '@zi-core/service/phone-verifier.service';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { ApplicationState } from '@app/reducers';
import { AnalyticsService } from '@zi-core/service/analytics.service';
import { MatDialog } from '@angular/material/dialog';
import { EngageModeV2Service } from '@zi-pages/engage-mode-v2/service/engage-mode-v2.service';
import { DialerUtilService } from '@app/caller/service/dialer-util.service';
import { DialerSelection } from '@app/core/enums/dialer.enum';
import { getManualLogCallDialogOpen } from '@app/caller/state/call-log.state';
import { DialerErrorDetails, DialerErrorTypes } from '../constant/dialer-errors.enum';
import { getExtensionMode } from '@app/extensionMode.config';
import { AnalyticsApplication } from '@app/core/enums/analytics.enum';
import { DialerAnalyticsService } from '@app/caller/service/dialer-analytics.service';
import { LoggerServiceToken } from '@zi-core/config/logger-service.config';
import { ILoggerService } from '@zi-core/interface/logger.service.interface';

interface BridgeCallerSessionItem {
  callId: string;
  taskId?: number;
  recordingInfo: CallRecordingInfo;
}

@Injectable()
export class BridgeDialerService extends EntityDialerService {
  private callStatusPollSubscription: Subscription;
  private STATUS_INTERVAL_CHECK_TIME = 3000;
  private callerSessionKey = 'engageCallerKey';
  private verifyOpen$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private waitingForConnectionResponse$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private application = AnalyticsApplication.Engage;

  constructor(
    protected _appStore: Store<ApplicationState>,
    protected _httpClient: HttpClient,
    protected analyticsService: AnalyticsService,
    protected contactInternalDataService: ContactInternalDataService,
    protected notyService: NotyService,
    protected dialerUtilService: DialerUtilService,
    private dialog: MatDialog,
    private engageModeV2Service: EngageModeV2Service,
    private organizationService: OrganizationService,
    private integrationService: IntegrationService,
    private phoneVerifierService: PhoneVerifierService,
    private dialerAnalyticsService: DialerAnalyticsService,
    @Inject(LoggerServiceToken) protected loggerService: ILoggerService,
  ) {
    super(_httpClient, _appStore, analyticsService, contactInternalDataService, notyService, dialerUtilService, loggerService);
    this.application = getExtensionMode() ? AnalyticsApplication.EngageExtension : AnalyticsApplication.Engage;
  }

  public startNewCallWithContact$(contactInfo: ContactCallInfo, phoneType: PhoneTypesEnum, taskId?: number): Observable<any> {
    contactInfo.originalNumber = contactInfo.phoneNumberDialed;
    contactInfo.phoneType = phoneType; // set phone type to contactInfo
    contactInfo.phoneNumberDialed = this.dialerUtilService.retrievePhoneNumber(contactInfo.phoneNumberDialed);
    const existingCallInfo = this.getCallInfo();
    if (existingCallInfo.callStatus === CallStatusEnum.callingRep || existingCallInfo.callStatus === CallStatusEnum.waitingRepApproval) {
      this.notyService.postMessage(
        new Message(MessageType.ERROR, 'To call a number, please establish the call session first and make ' + 'sure no other call is active.'),
      );
      return of(() => {});
    } else if (existingCallInfo.callStatus != null && existingCallInfo.callStatus !== CallStatusEnum.inSession) {
      this.dialerAnalyticsService.sendDialerErrorAnalyticsEvent(DialerErrorDetails.ONGOING_CALL);
      this.notyService.postMessage(
        new Message(MessageType.ERROR, 'To call another number, please complete your current call' + ' and fill out/cancel the call log.'),
      );
      return of(() => {});
    } else if (existingCallInfo.isCallLoggerActionRequired || this.isManualLogDialogOpen()) {
      this.dialerAnalyticsService.sendDialerErrorAnalyticsEvent(DialerErrorDetails.WAITING_FOR_LOG);
      this.notyService.postMessage(new Message(MessageType.ERROR, 'To call another number, please complete/cancel the call log.'));
      return of(() => {});
    } else if (!existingCallInfo || existingCallInfo.callStatus == null || existingCallInfo.callId == null) {
      return this.startNewCallSession$(contactInfo, phoneType, taskId);
    } else {
      return this.startNewProspectCallInSession$(contactInfo, taskId);
    }
  }

  public startNewCallWithContactStandalone$(contactInfo: ContactCallInfo, phoneType: PhoneTypesEnum, taskId?: number): Observable<any> {
    return this.isCallerNumberVerified().pipe(
      mergeMap((isVerified) => {
        if (isVerified) {
          return this.startNewCallWithContact$(
            { name: contactInfo.name, id: contactInfo.id, phoneNumberDialed: contactInfo.phoneNumberDialed },
            phoneType,
            taskId,
          );
        } else {
          return of(this.emitOpenVerifyPanel());
        }
      }),
    );
  }

  public cleanUpCallerSession() {
    this.endCallSession$().pipe(take(1)).subscribe();
    this.modifyCallInfo({ ...initialCallInfo });
    sessionStorage.removeItem(this.callerSessionKey);
  }

  public endCallSession$(): Observable<any> {
    const callInfo = this.getCallInfo();
    const callId = _.get(callInfo, 'callId');
    const prospectCallId = _.get(callInfo, 'prospectCallId', null);
    const callStatus = _.get(callInfo, 'callStatus', null);
    return of(() => {}).pipe(
      mergeMap(() => {
        return this._httpClient.delete(`${this.dialerUtilService.dialerDomain}/v1/dial?id=${callId}`).pipe(
          finalize(() => {
            const isEngageMode = this.engageModeV2Service.getEngageModeState();
            if (isEngageMode) {
              this.cancelLogCall(false);
            } else {
              callInfo.prospectCallId = prospectCallId;
              if ((callStatus === CallStatusEnum.connectingToProspect || callStatus === CallStatusEnum.callWithProspectStarted) && prospectCallId) {
                callInfo.isCallLoggerActionRequired = true;
                if (callInfo.isProspectCallActive) {
                  callInfo.isProspectCallActive = false;
                }
                this.modifyCallInfo(callInfo);
              }
              this.cleanUpCallSession();
            }
          }),
        );
      }),
    );
  }

  public openVerifyPanel$() {
    return this.verifyOpen$.asObservable();
  }

  public emitOpenVerifyPanel() {
    this.setOpenVerifyPanel(true);
  }

  public setOpenVerifyPanel(openVerifyPanel: boolean) {
    this.verifyOpen$.next(openVerifyPanel);
  }

  public isCallerNumberVerified(): Observable<boolean> {
    if (this.getCallInfo().callStatus == null) {
      return this._appStore.select(getValidAuthToken).pipe(
        take(1),
        mergeMap((authToken) => {
          return this.phoneVerifierService.getCallerIds(authToken.userId);
        }),
        map((res) => {
          const isVerified = _.has(res, 'phone');
          return isVerified;
        }),
      );
    } else {
      return of(true);
    }
  }

  public cleanUpCallSession(): void {
    const callInfo = this.getCallInfo();
    callInfo.callId = null;
    callInfo.callStatus = null;
    callInfo.recordingInfo = { ...initialCallInfo.recordingInfo };
    if (this.callStatusPollSubscription) {
      this.callStatusPollSubscription.unsubscribe();
    }
    this.modifyCallInfo(callInfo);
  }

  public endProspectCall$(): Observable<any> {
    const callId = _.get(this.getCallInfo(), 'callId');
    return this.checkCallStatusPoll(callId).pipe(
      switchMap(() => {
        return this.endProspectCall(callId);
      }),
      catchError(() => {
        return this.endProspectCall(callId);
      }),
    );
  }

  public isSessionStarted$(): Observable<any> {
    return this.getCallStatus$().pipe(
      distinctUntilChanged(),
      map((status) => status != null),
      distinctUntilChanged(),
      filter((res) => res),
    );
  }

  public isSessionEnded$(): Observable<any> {
    return this.getCallStatus$().pipe(
      distinctUntilChanged(),
      filter((status) => status == null),
    );
  }

  public prospectCallLegActive$(): Observable<CallInfo> {
    return combineLatest([this.waitingForConnectionResponse$, this.getCallStatus$()]).pipe(
      map(([waitingForConnectionResponse, status]) => {
        const statusInProgress = status === CallStatusEnum.connectingToProspect || status === CallStatusEnum.callWithProspectStarted;
        if (waitingForConnectionResponse) {
          return true;
        } else if (statusInProgress) {
          return true;
        } else {
          return false;
        }
      }),
      distinctUntilChanged(),
      filter((res) => res),
      map(() => {
        return this.getCallInfo();
      }),
    );
  }

  public whenProspectCallEnds$(): Observable<CallInfo> {
    // return this.prospectCallEnded$.asObservable();
    return this.getCallStatus$().pipe(
      distinctUntilChanged(),
      pairwise(),
      map(([previousValue, currentValue]) => {
        if (
          (previousValue === CallStatusEnum.connectingToProspect || previousValue === CallStatusEnum.callWithProspectStarted) &&
          currentValue !== CallStatusEnum.connectingToProspect &&
          currentValue !== CallStatusEnum.callWithProspectStarted
        ) {
          // normal call end scenario
          return true;
        } else if (previousValue === CallStatusEnum.waitingRepApproval && currentValue === CallStatusEnum.inSession) {
          // invalid number on session start
          return true;
        } else if ((previousValue === CallStatusEnum.callingRep || previousValue === CallStatusEnum.waitingRepApproval) && currentValue === null) {
          // user exited session before answering or approving
          return true;
        } else {
          return false;
        }
      }),
      distinctUntilChanged(),
      filter((res) => res),
      map(() => {
        return this.getCallInfo();
      }),
    );
  }

  // leave a voicemail out of existing recordings and end call
  // Lifted straight from DialerService
  // TODO: Delete from either here or dialer.service.ts when decision is made what to do with it.
  public leaveVoicemail$(voiceMailId: string): Observable<any> {
    if (this.getCallInfo().callId) {
      return this._httpClient
        .post(`${this.dialerUtilService.dialerDomain}/v1/dial/update`, {
          Id: this.getCallInfo().callId,
          LeaveVoicemail: true,
          VoicemailId: voiceMailId,
        })
        .pipe(
          tap((res: { session: DialerResponse }) => {
            const curCallInfo = this.getCallInfo();
            curCallInfo.voicemailId = voiceMailId;
            this.modifyCallInfo(curCallInfo);
          }),
        );
    } else {
      return of(() => {});
    }
  }

  public checkAndInitializeFromSessionStorage$(): Observable<any> {
    const item = sessionStorage.getItem(this.callerSessionKey);
    sessionStorage.removeItem(this.callerSessionKey);
    if (item) {
      const callerSessionItem: BridgeCallerSessionItem = _.cloneDeep(JSON.parse(item));
      if (callerSessionItem.callId) {
        return this.checkCallStatus(callerSessionItem.callId).pipe(
          mergeMap((resp: DialerResponse) => {
            if (resp && resp.status) {
              return this.contactInternalDataService.getContactById(resp.contactId).pipe(
                tap((contact: Contact) => {
                  const callInfoToUse = _.cloneDeep(initialCallInfo);
                  callInfoToUse.callStatus = resp.status;
                  callInfoToUse.callId = callerSessionItem.callId;
                  if (callInfoToUse.callStatus === CallStatusEnum.connectingToProspect || callInfoToUse.callStatus === CallStatusEnum.callWithProspectStarted) {
                  }
                  callInfoToUse.contactInfo = {
                    id: resp.contactId,
                    phoneNumberDialed: resp.prospectPhone,
                    name: contact.name,
                  };
                  callInfoToUse.recordingInfo = callerSessionItem.recordingInfo;
                  if (resp.prospectCallId) {
                    callInfoToUse.prospectCallId = resp.prospectCallId;
                  }

                  if (callInfoToUse.callStatus === CallStatusEnum.callWithProspectStarted || callInfoToUse.callStatus === CallStatusEnum.connectingToProspect) {
                    if (callInfoToUse.prospectCallId) {
                      callInfoToUse.isCallLoggerActionRequired = true;
                    }
                    callInfoToUse.isProspectCallActive = true;
                  } else {
                    // should only reset to false only if service level isCallLoggerActionRequired is not present.
                    // in case we come from GCE to Web App we need the existing state of isCallLoggerActionRequired
                    callInfoToUse.isCallLoggerActionRequired = this.callInfo$.value.isCallLoggerActionRequired || false;
                    callInfoToUse.isProspectCallActive = false;
                  }

                  if (resp.voicemailId) {
                    callInfoToUse.voicemailId = resp.voicemailId.toString();
                  }

                  if (callerSessionItem.taskId) {
                    callInfoToUse.taskId = callerSessionItem.taskId;
                  }

                  this.modifyCallInfo(callInfoToUse);
                  this.startPollingSubscription(callInfoToUse.callId);
                }),
              );
            } else {
              // dont need to do anything, just leave it as the initial
              return of(() => {});
            }
          }),
        );
      } else {
        return of(() => {});
      }
    } else {
      return of(() => {});
    }
  }

  public resetProspectCallInfo(callInfo: CallInfo) {
    const newCallInfo = _.cloneDeep(initialCallInfo);
    newCallInfo.callId = callInfo.callId;
    newCallInfo.callStatus = callInfo.callStatus;
    newCallInfo.isCallLoggerActionRequired = false;

    this.modifyCallInfo(newCallInfo);
  }

  private startNewCallSession$(contactInfo: ContactCallInfo, phoneType: PhoneTypesEnum, taskId?: number): Observable<any> {
    this.waitingForConnectionResponse$.next(true);

    const callInfo: CallInfo = {
      contactInfo,
      callId: null,
      prospectCallId: null,
      callStatus: null,
      isCallLoggerActionRequired: false,
      isProspectCallActive: false,
      recordingInfo: { ...initialCallInfo.recordingInfo },
      taskId,
      dialerType: DialerSelection.BRIDGE,
      isIncoming: false,
    };

    return this._httpClient
      .post(`${this.dialerUtilService.dialerDomain}/v1/dial`, {
        Phone: contactInfo.phoneNumberDialed,
        PhoneType: phoneType,
        ContactId: contactInfo.id,
        Cases: [],
        ContactTaskId: taskId ?? null,
        Application: this.application,
      })
      .pipe(
        // response 204 (no content) is inactive call - res will be undefined
        tap((res: { session: DialerResponse }) => {
          this.waitingForConnectionResponse$.next(false);
          const callId = _.get(res, 'session.id', null);
          callInfo.callId = callId;
          callInfo.callStatus = _.get(res, 'session.status', null);
          this.modifyCallInfo(callInfo);
          this.analyticsService.sendCalledContactEvent(callInfo);
          if (callId) {
            this.startPollingSubscription(callId);
          }
        }),
        catchError((error) => {
          this.dialerAnalyticsService.sendDialerErrorAnalyticsEvent(error, DialerErrorTypes.TW_Sharpy);
          this.waitingForConnectionResponse$.next(false);
          return error;
        }),
      );
  }

  private startNewProspectCallInSession$(contactInfo: ContactCallInfo, taskId?: number): Observable<any> {
    this.waitingForConnectionResponse$.next(true);

    const curCallInfo = this.getCallInfo();
    const callId = _.get(curCallInfo, 'callId');
    const callInfo: CallInfo = {
      contactInfo,
      callId: _.get(this.getCallInfo(), 'callId'),
      prospectCallId: null,
      callStatus: _.get(this.getCallInfo(), 'callStatus'),
      isCallLoggerActionRequired: false,
      recordingInfo: { ...initialCallInfo.recordingInfo },
      isProspectCallActive: false,
      taskId,
      dialerType: DialerSelection.BRIDGE,
      isIncoming: false,
    };
    // Make sure callInfo is up to date.
    this.modifyCallInfo(callInfo);

    return this._httpClient
      .post(`${this.dialerUtilService.dialerDomain}/v1/dial/update`, {
        Id: callId,
        Phone: contactInfo.phoneNumberDialed,
        ContactId: contactInfo.id,
        Cases: [],
        ContactTaskId: taskId ?? null,
      })
      .pipe(
        tap((res: { session: DialerResponse }) => {
          this.waitingForConnectionResponse$.next(false);
          callInfo.callStatus = _.get(res, 'session.status', null);
          callInfo.isProspectCallActive = true;
          this.modifyCallInfo(callInfo);
          this.analyticsService.sendCalledContactEvent(callInfo);
        }),
        catchError((error) => {
          this.dialerAnalyticsService.sendDialerErrorAnalyticsEvent(error, DialerErrorTypes.TW_Sharpy);
          this.waitingForConnectionResponse$.next(false);
          return error;
        }),
      );
  }

  private checkCallStatus(callId: string): Observable<DialerResponse> {
    return this._httpClient.get(`${this.dialerUtilService.dialerDomain}/v1/dial?id=${callId}`).pipe(
      map((res: { session: DialerResponse }) => {
        return res ? res.session : null;
      }),
    );
  }

  private isManualLogDialogOpen(): boolean {
    let isOpen: boolean;
    this._appStore.select(getManualLogCallDialogOpen).subscribe((isDialogOpen) => (isOpen = isDialogOpen));
    return isOpen;
  }

  private startPollingSubscription(callId: string): void {
    // Make sure there's only one.
    if (this.callStatusPollSubscription) {
      this.callStatusPollSubscription.unsubscribe();
    }
    this.callStatusPollSubscription = interval(this.STATUS_INTERVAL_CHECK_TIME).subscribe(() => {
      this.checkCallStatusPoll(callId).subscribe((callStatus) => {
        if (!callStatus) {
          this.cleanUpCallSession();
        }
      });
    });
  }

  private checkCallStatusPoll(callId: string): Observable<CallStatusEnum> {
    return this.checkCallStatus(callId).pipe(
      map((res: DialerResponse) => {
        const callInfo = this.getCallInfo();
        callInfo.callStatus = _.get(res, 'status', null);
        const prospectCallId = _.get(res, 'prospectCallId', null);

        if (callInfo.callStatus === CallStatusEnum.connectingToProspect || callInfo.callStatus === CallStatusEnum.callWithProspectStarted) {
          if (prospectCallId) {
            callInfo.prospectCallId = prospectCallId;
            callInfo.isCallLoggerActionRequired = true;
          }

          callInfo.isProspectCallActive = true;
        } else {
          callInfo.isProspectCallActive = false;
        }
        this.modifyCallInfo(callInfo);
        return callInfo.callStatus;
      }),
    );
  }

  protected modifyCallInfo(callInfo: CallInfo): void {
    if (callInfo.callStatus == null) {
      // Session is ended, we're not saving the info.
      sessionStorage.removeItem(this.callerSessionKey);
    } else {
      // Only save callId and taskId (if it exists) in sessionStorage
      const objToSave: { callId: string; taskId?: number; recordingInfo: CallRecordingInfo } = {
        callId: callInfo.callId,
        recordingInfo: { ...callInfo.recordingInfo },
      };
      if (callInfo.taskId) {
        objToSave.taskId = callInfo.taskId;
      }
      sessionStorage.setItem(this.callerSessionKey, JSON.stringify(objToSave));
    }
    this.callInfo$.next(callInfo);
  }

  private endProspectCall(callId: string): Observable<any> {
    return this._httpClient
      .post(`${this.dialerUtilService.dialerDomain}/v1/dial/update`, {
        Id: callId,
        HangUp: true,
      })
      .pipe(
        tap((res: { session: DialerResponse }) => {
          const callInfo = this.getCallInfo();
          callInfo.prospectCallId = _.get(res, 'session.prospectCallId');
          callInfo.callStatus = _.get(res, 'session.status');
          callInfo.isProspectCallActive = false;
          callInfo.recordingInfo = { ...initialCallInfo.recordingInfo };
          if (callInfo.prospectCallId) {
            callInfo.isCallLoggerActionRequired = true;
          } else {
            callInfo.isCallLoggerActionRequired = false;
          }
          this.modifyCallInfo(callInfo);
        }),
      );
  }
}
