import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Injector,
  Renderer2,
  TemplateRef,
  Type,
  ViewContainerRef,
  ViewRef,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';

export class ContentRef {
  constructor(public nodes: any[], public viewRef?: ViewRef) {}
}

/**
 * The service responsible for opening and closing popover instances around the application. Each popover instance
 * should have its own instance of the service.
 *
 * This service can be created using the {@link PopoverServiceFactory} which can be injected into your components and
 * directives.
 *
 * @see PopoverServiceFactory
 */
export class PopoverService<T> {
  private contentRef: ContentRef;
  private popoverRef: ComponentRef<T>;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private componentType: Type<T>,
    private renderer: Renderer2,
    private injector: Injector,
    private componentFactoryResolver: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
  ) {}

  /**
   * Open the popover instance registered with this service.
   *
   * @param string | TemplateRef<any> content - The popover content. This can be a simple text string or a {@link TemplateRef}
   *        to represent the content inside of the popover.
   * @param context - An optional value that will be injected into the popover instance and can be accessed using let-* bindings
   * @param Injector customInjector - A custom injector that will inject values into the created popover component instance.
   *        If a custom injector is not provided, the default application injector will be utilized.
   * @return ComponentRef<T> - The reference to the rendered popover component.
   */
  open(content: string | TemplateRef<any>, context?: any, customInjector?: Injector): ComponentRef<T> {
    if (!this.popoverRef) {
      this.contentRef = this.getContentViewRef(content, context);
      this.popoverRef = this.viewContainerRef.createComponent(
        this.componentFactoryResolver.resolveComponentFactory(this.componentType),
        0,
        customInjector || this.injector,
        this.contentRef.nodes,
      );
    }

    return this.popoverRef;
  }

  /**
   * Closes the open popover instance (if open). This will remove the component from the host {@link ViewContainerRef}
   * and it will detach the content view reference if a {@link TemplateRef} was provided for the popover content.
   */
  close(): void {
    if (this.popoverRef) {
      const popoverIndex = this.viewContainerRef.indexOf(this.popoverRef.hostView);
      this.viewContainerRef.remove(popoverIndex);
      this.popoverRef = null;

      if (this.contentRef && this.contentRef.viewRef) {
        this.applicationRef.detachView(this.contentRef.viewRef);
        this.contentRef.viewRef.destroy();
      }
      this.contentRef = null;
    }
  }

  listenToTriggers(nativeElement: HTMLElement, triggers: string, isOpenedFn: () => boolean, openFn: () => void, closeFn: () => void): Subscription {
    return this.observeTriggers(nativeElement, triggers, isOpenedFn).subscribe((open) => {
      open ? openFn() : closeFn();
    });
  }

  private getContentViewRef(content: string | TemplateRef<any>, context?: any): ContentRef {
    if (content instanceof TemplateRef) {
      const viewRef = content.createEmbeddedView(context);
      this.applicationRef.attachView(viewRef);
      return new ContentRef([viewRef.rootNodes], viewRef);
    } else {
      const textNode = this.renderer.createText(`${content}`);
      return new ContentRef([textNode]);
    }
  }

  private observeTriggers(nativeElement: HTMLElement, triggers: string, isOpenedFn: () => boolean) {
    return new Observable<boolean>((subscriber) => {
      const listeners = [];
      const openFn = () => subscriber.next(true);
      const closeFn = () => subscriber.next(false);
      const toggleFn = () => subscriber.next(!isOpenedFn());

      const splitTriggers = triggers.split(':');
      if (splitTriggers.length === 1) {
        listeners.push(this.renderer.listen(nativeElement, splitTriggers[0], toggleFn));
      } else {
        listeners.push(this.renderer.listen(nativeElement, splitTriggers[0], openFn), this.renderer.listen(nativeElement, splitTriggers[1], closeFn));
      }
    });
  }
}

@Injectable()
export class PopoverServiceFactory {
  constructor(private injector: Injector, private componentFactoryResolver: ComponentFactoryResolver, private applicationRef: ApplicationRef) {}

  /**
   * Creates a new instance of the {@link PopoverService} for use in a popover directive. This service handles opening
   * and closing the popover with the correct content, and registering/deregistering the component with the running
   * application.
   *
   * Only one should be created per instance of a popover directive.
   *
   * @param ViewContainerRef viewContainerRef - The directive {@link ViewContainerRef} instance;
   * @param Type componentType: A reference to the popover component type
   * @return PopoverService<T> The popover service instance.
   */
  create<T>(viewContainerRef: ViewContainerRef, componentType: Type<T>, renderer: Renderer2): PopoverService<T> {
    return new PopoverService<T>(viewContainerRef, componentType, renderer, this.injector, this.componentFactoryResolver, this.applicationRef);
  }
}
