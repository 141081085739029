import { EventEmitter, Inject, Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '@app/reducers';
import { AnalyticsService } from '@zi-core/service/analytics.service';
import { CallStatusEnum } from '@app/caller/interface/call-status-enum';
import { CallInfo, ContactCallInfo, initialCallInfo } from '@app/caller/interface/call-info';
import { Connection } from 'twilio-client';
import { VoipDeviceService } from '@app/caller/service/voip-device.service';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { PhoneTypesEnum } from '@app/caller/interface/phone-types-enum';
import { Message } from '@zi-common/model/message/message.model';
import { MessageType } from '@zi-common/model/message/message-type';
import { NotyService } from '@zi-common/service/noty/noty.service';
import { VoipCallEvent } from '@app/caller/interface/voip-call-event';
import { DialerUtilService } from '@app/caller/service/dialer-util.service';
import { distinctUntilChanged, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { getValidAuthToken } from '@zi-core/ngrx/state/auth.state';
import { PhoneVerifierService } from '@zi-core/service/phone-verifier.service';
import { DialerSelection } from '@zi-core/enums/dialer.enum';
import { AuthenticatedToken } from '@zi-core/http-model/response/authenticated-token.response.model';
import { TwilioCallerId } from '@zi-core/http-model/response/phone-verifier.response.model';
import { ContactInternalDataService } from '@zi-pages/contact/service/contact-internal-data.service';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import * as uuid from 'uuid';
import { extSizeSelector } from '@app/extension/ngrx/state/extension.state';
import { ExtensionSize } from '@app/extension/constants/extension-size.enum';
import { getExtensionMode } from '@app/extensionMode.config';
import { ExtensionIframeMessagingService } from '@app/extension/service/extension-iframe-messaging.service';
import { ExtGoToTwentyPctAction } from '@app/extension/ngrx/action/extension.action';
import { EngageModeV2Service } from '@app/pages/engage-mode-v2/service/engage-mode-v2.service';
import { environment } from '@env/environment';
import { SearchByFilterLeafsRequest } from '@app/core/http-model/request/engage-filter.request.model';
import { SearchResponse } from '@app/core/http-model/response/contact-search.response.model';
import { getManualLogCallDialogOpen } from '@app/caller/state/call-log.state';
import { DialerErrorDetails } from '../constant/dialer-errors.enum';
import { DialerAnalyticsService } from '@app/caller/service/dialer-analytics.service';
import { LoggerServiceToken } from '@zi-core/config/logger-service.config';
import { ILoggerService } from '@zi-core/interface/logger.service.interface';

@Injectable()
export class VoipDialerService implements OnDestroy {
  /** List of subscriptions used by this service */
  private subscriptionList: Subscription[] = [];
  /** A Map between Call UUIDs and VoipCallEvent Objects that represent an individual VOIP Call (incoming/outgoing) */
  private callEvents$ = new BehaviorSubject<Map<string, VoipCallEvent>>(new Map());
  /** The maximum amount of open call events allowed. */
  private MAX_CALL_EVENTS = 2;
  /** An event emitter that will control the opening/closing of the no phone number panel. */
  private noPhoneNumberPanelOpen$: EventEmitter<boolean> = new EventEmitter<boolean>();
  private waitForReadySub = new Subscription();
  private incomingCallPending$ = new BehaviorSubject<boolean>(false);
  private isEngageModeActive = false;

  constructor(
    private _appStore: Store<ApplicationState>,
    private _httpClient: HttpClient,
    private analyticsService: AnalyticsService,
    private contactInternalDataService: ContactInternalDataService,
    private voipDeviceService: VoipDeviceService,
    private dialerUtilService: DialerUtilService,
    private engageModeV2Service: EngageModeV2Service,
    private phoneVerifierService: PhoneVerifierService,
    private extensionIframeMessagingService: ExtensionIframeMessagingService,
    private notyService: NotyService,
    private dialerAnalyticsService: DialerAnalyticsService,
    @Inject(LoggerServiceToken) protected loggerService: ILoggerService,
  ) {}

  /**
   * Starts a prospect call using VOIP Dialer
   *
   * @param contactInfo - The Contact being dialed.
   * @param phoneType - The type of phone number used.
   * @param userId - The ID of the user dialing
   * @param taskId - The task ID associated with the call.
   */
  public startProspectCall(contactInfo: ContactCallInfo, phoneType: PhoneTypesEnum, userId: number, taskId?: number): void {
    contactInfo.phoneType = phoneType; // set phone type to contactInfo
    contactInfo.originalNumber = contactInfo.phoneNumberDialed;
    contactInfo.phoneNumberDialed = this.dialerUtilService.retrievePhoneNumber(contactInfo.phoneNumberDialed);
    if (this.checkIfSesionActive()) {
      this.dialerAnalyticsService.sendDialerErrorAnalyticsEvent(DialerErrorDetails.ONGOING_CALL);
      this.notyService.postMessage(
        new Message(MessageType.ERROR, 'To call another number, please complete your current call' + ' and fill out/cancel the call log.'),
      );
    } else if (this.isWaitingForLog() || this.isManualLogDialogOpen()) {
      this.voipDeviceService.unsetAudioInput();
      this.dialerAnalyticsService.sendDialerErrorAnalyticsEvent(DialerErrorDetails.WAITING_FOR_LOG);
      this.notyService.postMessage(new Message(MessageType.ERROR, 'To call another number, please complete/cancel the call log.'));
    } else {
      this.waitForReadySub.unsubscribe();
      const taskIdStr = taskId ? taskId.toString() : null;
      this.waitForReadySub = this.voipDeviceService
        .connectToTwilio(contactInfo.phoneNumberDialed, _.get(contactInfo, 'id', 0).toString(), userId.toString(), taskIdStr)
        .pipe(take(1))
        .subscribe((connection) => {
          {
            const callInfo: CallInfo = {
              callId: uuid.v4(),
              contactInfo,
              prospectCallId: null,
              callStatus: null,
              isCallLoggerActionRequired: false,
              recordingInfo: { ...initialCallInfo.recordingInfo },
              isProspectCallActive: false,
              voicemailId: null,
              taskId,
              dialerType: DialerSelection.VOIP,
              isIncoming: false,
            };

            this.analyticsService.sendCalledContactEvent(callInfo);
            return this.createCallEvent(connection, false, contactInfo, taskId);
          }
        });
    }
  }

  /**
   * Starts a call using standalone VOIP dialer.
   *
   * @param contactInfo - The Contact being dialed.
   * @param phoneType - The type of phone number used.
   * @param userId - The ID of the user dialing
   * @param taskId - The task ID associated with the call.
   */
  public startProspectCallWithStandalone(contactInfo: ContactCallInfo, phoneType: PhoneTypesEnum, userId: number, taskId?: number) {
    this.isPhoneNumberExist()
      .pipe(
        take(1),
        switchMap((isPhoneNumberExists) => {
          return this.voipDeviceService.initializeDevices().pipe(map(() => isPhoneNumberExists));
        }),
      )
      .subscribe(
        (isPhoneNumberExists) => {
          if (isPhoneNumberExists) {
            this.startProspectCall(contactInfo, phoneType, userId, taskId);
          } else {
            this.dialerAnalyticsService.sendDialerErrorAnalyticsEvent(DialerErrorDetails.PHONENUM_DOES_NOT_EXIST);
            this.emitNoPhoneNumberPanelOpen(true);
          }
        },
        (err) => {
          this.dialerAnalyticsService.sendDialerErrorAnalyticsEvent(DialerErrorDetails.PHONENUM_DOES_NOT_EXIST);
          this.notyService.postMessage(new Message(MessageType.ERROR, 'Error starting phone call with prospect. Please reload and try again. '));
        },
      );
  }

  /**
   * An Observable that returns if a VOIP number exists or not.
   */
  public isPhoneNumberExist(): Observable<boolean> {
    return this._appStore.select(getValidAuthToken).pipe(
      take(1),
      mergeMap((authToken: AuthenticatedToken) => {
        return this.phoneVerifierService.getCallerIds(authToken.userId, [DialerSelection.VOIP]);
      }),
      map((res: TwilioCallerId) => {
        return _.has(res, 'phone');
      }),
    );
  }

  /**
   * Clear existing calls for Engage
   */
  public clearCallEventsForEngage() {
    this.callEvents$.next(new Map());
  }

  public getIncomingCallPending() {
    return this.incomingCallPending$.value;
  }

  /**
   * An Observable that listens on changes to the Call Events and returns an array of Call Events
   * currently in the system.
   */
  public getCallEvents$(): Observable<VoipCallEvent[]> {
    return this.callEvents$.asObservable().pipe(
      map((callEventMap: Map<string, VoipCallEvent>) => {
        return Array.from(callEventMap.values()) || [];
      }),
    );
  }

  /**
   * Returns default call log info for engage mode if the call log event does not exist
   * need this to initialize engage mode with auto dialer
   */
  public getCallInfo$(): Observable<CallInfo> {
    return this.getCallEvents$().pipe(
      mergeMap((callEvents) => {
        if (callEvents.length > 0) {
          return callEvents[0].getCallInfo$();
        } else {
          return of(_.cloneDeep(initialCallInfo));
        }
      }),
    );
  }

  /**
   * Returns if there is an active VOIP Call.
   */
  public checkIfSesionActive(): boolean {
    return !_.isEmpty(this.getActiveCall());
  }

  /**
   * Returns if there is an open call panel or not.
   */
  public hasOpenCallPanel(): boolean {
    return this.getCallEvents().length > 0;
  }

  /**
   * Emit an event indicating if the No VOIP Number Panel should open or not.
   *
   * @param toOpen - Whether the panel should open or not.
   */
  public emitNoPhoneNumberPanelOpen(toOpen: boolean): void {
    this.noPhoneNumberPanelOpen$.emit(toOpen);
  }

  /**
   * Retrieve an Observable<boolean> that will fire a value indicating if the
   * No VOIP Number Panel should open or not.
   */
  public getNoPhoneNumberPanelOpen$(): Observable<boolean> {
    return this.noPhoneNumberPanelOpen$.asObservable();
  }

  /**
   * An external call to clean up subscriptions (For example: to be used with logout).
   */
  public cleanUp(): void {
    this.subscriptionList.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  /**
   * Returns the Call UUID -> VoipCallEvent Map maintained by this service.
   */
  private getCallEventsMap(): Map<string, VoipCallEvent> {
    return new Map(this.callEvents$.value);
  }

  /**
   * Returns the VOIP call events currently existing in the system.
   */
  private getCallEvents(): VoipCallEvent[] {
    return Array.from(this.getCallEventsMap().values());
  }

  /**
   * Returns the active VOIP Call Event
   */
  private getActiveCall(): VoipCallEvent {
    return this.getCallEvents().find((callEvent: VoipCallEvent) => {
      return callEvent.getCallInfo().isProspectCallActive;
    });
  }

  /**
   * Returns if there is a call event that is not an active call, but is still
   * awaiting call log input.
   */
  public isWaitingForLog(): boolean {
    return (
      this.getCallEvents().filter((callEvent) => {
        return callEvent.getCallInfo().isCallLoggerActionRequired;
      }).length > 0
    );
  }

  public leaveVoicemail$(voiceMailId: string): Observable<any> {
    const activeCall = this.getActiveCall();
    if (activeCall.getCallInfo().callId) {
      return this._httpClient
        .post(`${this.dialerUtilService.dialerDomain}/v1/dial/update`, {
          CallSid: this.getActiveCall().getCallInfo().prospectCallId,
          LeaveVoicemail: true,
          VoicemailId: voiceMailId,
          callerIdType: DialerSelection.VOIP,
        })
        .pipe(
          tap(() => {
            activeCall.setVoicemailId(voiceMailId);
          }),
        );
    } else {
      return of(() => {});
    }
  }

  /**
   * Creates a VOIP Call Event and stores it in the map.
   *
   * @param connection - The Twilio connection being represented by this Call Event.
   * @param isIncoming - Is the call incoming or not?
   * @param contactInfo - The info of the contact being called/calling
   * @param taskId - The task related to this call (optional).
   */
  private createCallEvent(connection: Connection, isIncoming: boolean, contactInfo: ContactCallInfo, taskId?: number): void {
    const callInfo: CallInfo = {
      callId: uuid.v4(),
      contactInfo,
      prospectCallId: null,
      callStatus: isIncoming ? CallStatusEnum.incomingCall : null,
      isCallLoggerActionRequired: false,
      recordingInfo: { ...initialCallInfo.recordingInfo },
      isProspectCallActive: false,
      voicemailId: null,
      taskId,
      dialerType: DialerSelection.VOIP,
      isIncoming,
    };

    const callEvts = this.getCallEventsMap();
    const callEvent = new VoipCallEvent(
      this._httpClient,
      this._appStore,
      this.analyticsService,
      this.contactInternalDataService,
      this.notyService,
      this.dialerUtilService,
      this,
      connection,
      callInfo,
      this.loggerService,
    );
    this.registerCallEventHandlers(callEvent, isIncoming);

    callEvts.set(callInfo.callId, callEvent);
    this.callEvents$.next(callEvts);

    if (contactInfo.id == null || contactInfo.id == 0) {
      const phone = isIncoming ? _.get(connection, 'parameters.From') : connection.customParameters.get('phoneNumber');
      const phoneE164 = this.dialerUtilService.formatPhoneNumberToE164(phone);
      const resource: SearchByFilterLeafsRequest = this.dialerUtilService.getContactsByPhoneNumberRequest(phoneE164);
      this._httpClient
        .post(`${environment.backend_url}/v1/filters`, resource)
        .pipe(take(1))
        .subscribe((res: SearchResponse) => {
          if (!!res) {
            if (res.contacts.length > 0) {
              const contact = res.contacts[0];
              contactInfo.id = res.contacts[0].id;
              contactInfo.name = res.contacts[0].name;
              callEvent.updateCallContact(contact).pipe(take(1)).subscribe();
            }
          }
        });
    }
  }

  /**
   * Registers the necessary handlers needed for the service to clean up after a call is completed.
   *
   * @param callEvent - The call event that is having handlers registered.
   * @param isIncoming - Is it an incoming call or not?
   */
  private registerCallEventHandlers(callEvent: VoipCallEvent, isIncoming: boolean): void {
    callEvent
      .whenProspectCallEnds$()
      .pipe(take(1))
      .subscribe((callInfo: CallInfo) => {
        this.incomingCallPending$.next(false);
        if (!callInfo.isCallLoggerActionRequired) {
          this.removeCallEvent(callInfo.callId);
        }
      });

    callEvent
      .whenLogCallCompleted()
      .pipe(take(1))
      .subscribe((evt: CallInfo) => {
        this.removeCallEvent(evt.callId);
      });

    callEvent
      .whenIncomingCallAccepted$()
      .pipe(take(1), distinctUntilChanged())
      .subscribe((evt: CallInfo) => {
        this.incomingCallPending$.next(false);
      });

    if (isIncoming) {
      callEvent
        .whenIncomingCallAccepted$()
        .pipe(take(1))
        .subscribe((callInfo: CallInfo) => {
          const activeCall = this.getActiveCall();
          this.incomingCallPending$.next(false);
          if (!_.isEmpty(activeCall)) {
            activeCall.endProspectCall();
          }
        });

      callEvent
        .whenIncomingCallRejected$()
        .pipe(take(1))
        .subscribe((callInfo: CallInfo) => {
          this.removeCallEvent(callInfo.callId);
          this.incomingCallPending$.next(false);
        });
    }
  }

  /**
   * Removes a call event from the map. Indicates that the call either ended or was rejected.
   *
   * @param callId - The uuid of the call impacted.
   */
  private removeCallEvent(callId: string): void {
    const callEvts = this.getCallEventsMap();
    callEvts.delete(callId);
    this.callEvents$.next(callEvts);

    if (!this.hasOpenCallPanel()) {
      this.voipDeviceService.notifyAllCallEventsEnded();
    }
  }

  /**
   * Handle an incoming call into Engage.
   *
   * @param incomingConnection - The incoming Twilio Connection.
   */
  public handleIncomingCall(incomingConnection: Connection): void {
    this.engageModeV2Service.isEngageMode$.pipe(take(1)).subscribe((isEngageMode) => {
      this.isEngageModeActive = isEngageMode;
    });

    // Reject call if would be above max number of call events.
    if (this.getCallEvents().length >= this.MAX_CALL_EVENTS || this.isEngageModeActive) {
      incomingConnection.reject();
    } else {
      this.incomingCallPending$.next(true);
      const contactInfo = {
        id: null,
        name: 'Unknown',
        email: null,
        phoneNumberDialed: _.get(incomingConnection, 'parameters.From'),
        originalNumber: _.get(incomingConnection, 'parameters.From'),
      };
      contactInfo.phoneNumberDialed = this.dialerUtilService.retrievePhoneNumber(contactInfo.phoneNumberDialed);
      const callInfo: CallInfo = {
        callId: uuid.v4(),
        contactInfo,
        prospectCallId: null,
        callStatus: CallStatusEnum.incomingCall,
        isCallLoggerActionRequired: false,
        recordingInfo: { ...initialCallInfo.recordingInfo },
        isProspectCallActive: false,
        voicemailId: null,
        dialerType: DialerSelection.VOIP,
        isIncoming: true,
      };
      this.analyticsService.sendCalledContactEvent(callInfo);
      if (getExtensionMode()) {
        this._appStore
          .select(extSizeSelector)
          .pipe(take(1))
          .subscribe((extSize: ExtensionSize) => {
            if (extSize === ExtensionSize.MINIMIZED) {
              this._appStore.dispatch(ExtGoToTwentyPctAction({ sizeComingFrom: extSize }));
            }
          });
      }

      this.createCallEvent(incomingConnection, true, contactInfo);
    }
  }

  public updateCallContact(callSid: string, contactId: number): Observable<object> {
    return this._httpClient.put(`${environment.backend_url}/v1/phonecalls`, { callSid, contactId });
  }

  private isManualLogDialogOpen(): boolean {
    let isOpen: boolean;
    this._appStore.select(getManualLogCallDialogOpen).subscribe((isDialogOpen) => (isOpen = isDialogOpen));
    return isOpen;
  }

  ngOnDestroy(): void {
    this.cleanUp();
  }
}
