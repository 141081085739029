import { Injectable } from '@angular/core';
import { ApplicationState } from '@app/reducers';
import { Store } from '@ngrx/store';
import { EngageVersionStringModel } from '@zi-common/interface/user-licenses.model';
import { EngageVersionStringTransformConfig } from '@zi-common/service/engage-version-string-transform/engage-version-string-transform.config';
import { EngageVersionEnum } from '@zi-core/enums/engage-version.enum';
import { getExclusiveUserRole, isUserLicensesLoaded } from '@zi-core/ngrx/state/auth.state';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EngageVersionStringTransformService {
  public userRole$: BehaviorSubject<EngageVersionEnum>;
  constructor(private store: Store<ApplicationState>, private config: EngageVersionStringTransformConfig) {
    this.userRole$ = new BehaviorSubject<EngageVersionEnum>(EngageVersionEnum.SALES);
    // App root level listener for user role change
    this.getExclusiveUserRole$().subscribe((role: EngageVersionEnum) => {
      this.userRole$.next(role);
    });
  }

  getUserRoleCurrentValue() {
    return this.userRole$.value;
  }

  /**
   * Returns the text corresponding to the engage version
   * @param roleVersion - User exclusive role / engage version
   * @param originalText - string text base value, i.e sales/DOZI
   */
  public getTransformedText(roleVersion: EngageVersionEnum, originalText: string): string {
    if (!roleVersion || !originalText) {
      return originalText;
    }
    switch (roleVersion) {
      case EngageVersionEnum.SALES:
        return originalText;
      default:
        return this.getUserText(roleVersion, originalText);
    }
  }

  /**
   * Keep this method private as there is already a listener at app root.
   * User userRole$ to listen to role change of user.
   */
  private getExclusiveUserRole$(): Observable<EngageVersionEnum> {
    return this.store.select(isUserLicensesLoaded).pipe(
      filter((loaded) => !!loaded),
      switchMap(() => this.store.select(getExclusiveUserRole)),
    );
  }

  private getUserText(roleVersion: EngageVersionEnum, originalText: string): string {
    const configList: EngageVersionStringModel[] = this.config.getTextTransformationList(roleVersion);
    const versionRegex: RegExp = this.config.textTransEngageVerRegexMap[roleVersion];

    return originalText.replace(versionRegex, (matched) => {
      return configList.find((configObj: EngageVersionStringModel) => configObj.key === matched).value;
    });
  }
}
