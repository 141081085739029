export const successLoadedState = {
  loading: false,
  loaded: true,
  error: null,
};

export const failureLoadedState = {
  loading: false,
  loaded: true,
};

export const loadingState = {
  loading: true,
  loaded: false,
  error: null,
};

export const defaultLoadingState = {
  loading: false,
  loaded: false,
  error: null,
};
