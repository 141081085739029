import { LogoutAction } from '@app/core/ngrx/action/auth.action';
import * as auth from '@app/core/ngrx/reducer/auth.reducer';
import * as engageFilter from '@app/core/ngrx/reducer/engage-filter.reducer';
import * as extension from '@app/extension/ngrx/reducer/extension.reducer';
import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { AuthenticatedTokenState, initialAuthenticatedTokenState } from '@zi-core/ngrx/state/auth.state';
import { EngageFilterState, initialEngageFilterState } from '@app/core/ngrx/state/engage-filter.state';
import { initialOnBoardingSignatureEntity, OnBoardingSignatureEntityState } from '@zi-pages/on-boarding/ngrx/state/on-boarding-signature.state';
import { localStorageSync } from 'ngrx-store-localstorage';
import { ExtensionState, initialExtensionState } from '@app/extension/ngrx/state/extension.state';
import { initialOnBoardingEntity, OnBoardingEntityState } from '@zi-pages/on-boarding/ngrx/state/on-boarding.state';
import { OnBoardingEntityReducer } from '@zi-pages/on-boarding/ngrx/reducer/on-boarding.reducer';
import { initialLabelsState, LabelsState } from '@zi-core/ngrx/state/labels.state';
import { LabelsReducer } from '@zi-core/ngrx/reducer/labels.reducer';
import { OnBoardingSignatureEntityReducer } from '@zi-pages/on-boarding/ngrx/reducer/on-boarding-signature.reducer';
import { contactListImportReducer } from '@zi-pages/contact/ngrx/reducer/import-contact-list.reducer';
import { ImportListsState, InitialContactListImportEmptyState } from '@zi-pages/contact/ngrx/state/import-contact-list.state';
import { SfLiveAgentState } from '@zi-pages/sf-live-agent/ngrx/state/sf-live-agent.state';
import { SfLiveAgentReducer } from '@zi-pages/sf-live-agent/ngrx/reducer/sf-live-agent.reducer';
import { DialerConfigState, initialDialerConfigState } from '@app/pages/account/ngrx/state/dialer-config.state';
import { dialerConfigReducer } from '@app/pages/account/ngrx/reducer/dialer-config.reducer';
import { AdaLiveAgentState } from '@zi-pages/ada-live-chat/state/ada-live-agent.state';
import { AdaLiveAgentReducer } from '@zi-pages/ada-live-chat/reducer/ada-live-agent.reducer';
import { EmailerVersionReducer } from '@app/pages/admin/ngrx/reducer/emailer-version.reducer';
import { EmailerVersionState, initialEmailerVersionState } from '@zi-pages/admin/ngrx/state/emailer-version.state';
import { SmsMessageReducer } from '@zi-pages/sms/ngrx/reducer/sms-message.reducer';
import { initialSmsMessageState, SmsMessageState } from '@zi-pages/sms/ngrx/state/sms-message.state';
import { GmailPluginState, initialGmailPluginState } from '@app/gmail-plugin/ngrx/state/gmail-plugin.state';
import { GmailPluginReducer } from '@app/gmail-plugin/ngrx/reducer/gmail-plugin.reducer';

/**
 * Application state in the store
 */
export interface ApplicationState {
  auth: AuthenticatedTokenState;
  filter: EngageFilterState;
  extension: ExtensionState;
  gmailPlugin: GmailPluginState;
  onBoarding: OnBoardingEntityState;
  onBoardingSignature: OnBoardingSignatureEntityState;
  labels: LabelsState;
  importContactList: ImportListsState;
  sfLiveAgentStatus: SfLiveAgentState;
  adaLiveAgentStatus: AdaLiveAgentState;
  dialerConfig: DialerConfigState;
  emailerVersion: EmailerVersionState;
  smsMessage: SmsMessageState;
}

/**
 * reducer for the application
 */
export const reducers: ActionReducerMap<ApplicationState> = {
  auth: auth.AuthReducer,
  filter: engageFilter.EngageFilterReducer,
  extension: extension.ExtensionReducer,
  gmailPlugin: GmailPluginReducer,
  onBoarding: OnBoardingEntityReducer,
  onBoardingSignature: OnBoardingSignatureEntityReducer,
  labels: LabelsReducer,
  importContactList: contactListImportReducer,
  sfLiveAgentStatus: SfLiveAgentReducer,
  adaLiveAgentStatus: AdaLiveAgentReducer,
  dialerConfig: dialerConfigReducer,
  emailerVersion: EmailerVersionReducer,
  smsMessage: SmsMessageReducer,
};

/**
 * metaReducer to clear application state on logouts
 */
export function clearState(reducer: ActionReducer<ApplicationState>): ActionReducer<ApplicationState> {
  return (state: ApplicationState, action: Action): ApplicationState => {
    if (action.type === LogoutAction.type) {
      state = {
        auth: { ...initialAuthenticatedTokenState, rememberMeData: state.auth.rememberMeData },
        filter: { ...initialEngageFilterState },
        extension: { ...initialExtensionState, size: state.extension.size },
        gmailPlugin: { ...initialGmailPluginState },
        onBoarding: { ...initialOnBoardingEntity },
        onBoardingSignature: { ...initialOnBoardingSignatureEntity },
        labels: { ...initialLabelsState },
        importContactList: { ...InitialContactListImportEmptyState },
        sfLiveAgentStatus: { ...state.sfLiveAgentStatus },
        adaLiveAgentStatus: { ...state.adaLiveAgentStatus },
        dialerConfig: { ...initialDialerConfigState },
        emailerVersion: { ...initialEmailerVersionState },
        smsMessage: { ...initialSmsMessageState },
      };
    }
    return reducer(state, action);
  };
}

/**
 * metaReducer to sync application state with local storage
 */
export const KEYS_TO_PERSIST_LOCAL_STORE = [];

export function localStorageSyncReducer(reducer: ActionReducer<ApplicationState>): ActionReducer<ApplicationState> {
  return localStorageSync({
    keys: KEYS_TO_PERSIST_LOCAL_STORE,
    rehydrate: true,
  })(reducer);
}

export const KEYS_TO_PERSIST_SESSION_STORE = ['feed'];

export function sessionStorageSyncReducer(reducer: ActionReducer<ApplicationState>): ActionReducer<ApplicationState> {
  return localStorageSync({
    keys: KEYS_TO_PERSIST_SESSION_STORE,
    rehydrate: true,
    storage: sessionStorage,
  })(reducer);
}

export const metaReducers: MetaReducer<ApplicationState>[] = [clearState, localStorageSyncReducer, sessionStorageSyncReducer];
