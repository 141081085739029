import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ApplicationState } from '@app/reducers';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { Template } from '@app/core/data-model/template.model';
import { ScopeNumber } from '@app/core/enums/engage-filter.enum';
import { UpdateContentAction } from '@app/pages/content/ngrx/action/content-entity.action';

@Component({
  selector: 'app-salesflow-email-template-dialog',
  templateUrl: './salesflow-email-template-dialog.component.html',
  styleUrls: ['./salesflow-email-template-dialog.component.scss'],
})
export class SalesflowEmailTemplateDialogComponent implements OnInit, OnDestroy {
  allSubscriptions: Subscription[] = [];
  emailTemplates: Template[] = [];

  constructor(
    private dialogRef: MatDialogRef<SalesflowEmailTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private appStore: Store<ApplicationState>,
  ) {
    this.emailTemplates = _.get(this.data, 'emailTemplates', []);
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    if (this.allSubscriptions) {
      this.allSubscriptions.forEach((sub) => sub.unsubscribe());
    }
  }

  updateTemplates() {
    if (_.get(this.emailTemplates, 'length', 0) === 0) {
      this.dialogRef.close('Empty');
    }

    this.emailTemplates.forEach((t) => {
      const template: Template = { ...t, ...{ scope: ScopeNumber.Organization } };
      this.appStore.dispatch(UpdateContentAction({ payload: template }));
    });
    this.dialogRef.close('Success');
  }

  onClose() {
    this.dialogRef.close('Closed');
  }
}
