import { AppliedQuickFilterEvent } from '@zi-core/data-model/analytics/filter-events.model';
import { DownloadedCsvContactsEvent } from '@zi-core/data-model/analytics/downloaded-csv.model';
import { IntegrationIconClickedEvent } from '@zi-core/data-model/analytics/integrations-events.model';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Inject, Injectable } from '@angular/core';
import { UrlService } from '@app/core/service/url.service';
import { AnalyticsApplication, AnalyticsSectionType } from '../enums/analytics.enum';
import { contactForm, TREE_DATA } from '@app/common/pages-component/contact-create/contact-create-config';
import { FilterEntity, FilterSort, FilterSortOrder, Scope, ScopeNumber } from '@zi-core/enums/engage-filter.enum';
import { CalledContact, ConversationContact, SelectedDialerTypeEvent } from '../data-model/analytics/dialer-events.model';
import { TaskInternalDataService } from '@app/pages/task/service/task-internal-data.service';
import { CallInfo } from '@app/caller/interface/call-info';
import { ContactInternalDataService } from '@app/pages/contact/service/contact-internal-data.service';
import { forkJoin, of } from 'rxjs';
import { Task } from '../data-model/task.model';
import { SalesWorkflowTemplateStep } from '../http-model/response/sales-workflow-template.response.model';
import { VerifiedPhoneNumberForCalls } from '../data-model/analytics/verify-phone.model';
import { Router, RoutesRecognized } from '@angular/router';
import { SentEmail } from '../data-model/analytics/sent-email.model';
import { InvitationsCreateRequest } from '../http-model/request/invitations.request.model';
import { Contact } from '../data-model/contact.model';
import { ClickedContactUsLock, ClickedLearnMoreLock, ReplacedContact } from '../data-model/analytics/dozi-events.model';
import {
  AddedSalesFlowStepEvent,
  AddedToSalesflowEvent,
  CreatedSalesflowEvent,
  EditedSalesflowEvent,
  EditedSalesflowStepEvent,
  ViewedSalesflowTabsEvent,
} from '../data-model/analytics/salesflow-events.model';
import { UnsubscribedContactEvent, UnsubscribeLinkEvent, ViewedContactCardEvent } from '../data-model/analytics/contact-events.model';
import { map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AuthenticatedTokenState } from '@zi-core/ngrx/state/auth.state';
import { ApplicationState } from '@app/reducers';
import { TaskEvent } from '@zi-core/data-model/analytics/task-event.model';
import { AddedVoicemailRecordingEvent, DeletedVoicemailRecordingEvent } from '@zi-core/data-model/analytics/voicemail-events.model';
import { UpdatedPhoneNumberStatus } from '../data-model/analytics/phone-status-event.model';
import { AnalyticsConfigService } from '@zi-core/service/analytics-config.service';
import { AnalyticsEvent } from '@zi-core/enums/analytics-event.enum';
import { AnalyticsBaseService } from '@zi-core/service/analytics.base.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { variableMap } from '@app/common/component/froala-editor-v2/froala-editor.config';
import { getExtensionMode } from '@app/extensionMode.config';
import { EmailerIconClicked } from '../data-model/analytics/emailer-icon-clicked';
import { extSizeSelector } from '@app/extension/ngrx/state/extension.state';
import { ExtensionSize } from '@app/extension/constants/extension-size.enum';
import { SortingEvent } from '@zi-core/data-model/analytics/sorting.event';
import { MultisortOption } from '@zi-core/data-model/multisort-option.model';
import { LoggerServiceToken } from '@zi-core/config/logger-service.config';
import { ILoggerService } from '@zi-core/interface/logger.service.interface';

@UntilDestroy()
@Injectable()
export class AnalyticsService extends AnalyticsBaseService {
  constructor(
    private contactService: ContactInternalDataService,
    private taskService: TaskInternalDataService,
    private router: Router,
    protected urlService: UrlService,
    protected store: Store<ApplicationState>,
    public config: AnalyticsConfigService,
    @Inject(LoggerServiceToken) protected loggerService: ILoggerService,
  ) {
    super(urlService, store, loggerService);
    this.routerAmplitudeEvents();
  }

  /**
   * Initialize amplitude post multiple actions
   */
  public initAmplitude() {
    this.authState$.pipe(take(1)).subscribe((authToken: AuthenticatedTokenState) => {
      this.setProperties(authToken.authenticatedToken, authToken.profile);
      this.loginEvent(authToken?.isOrgAdmin);
    });
  }

  /**
   * send a ClickedViewAction analytic event
   * @param actionType the action that was performed on custom views
   */
  public clickedViewActionEvent(actionType: string): void {
    const eventProps = {
      actionType,
    };
    this.send(AnalyticsEvent.CLICKED_VIEW_ACTION, eventProps);
  }

  public sortActionEvent(sortBy: FilterSort, sortOrder: FilterSortOrder): void {
    const value: SortingEvent[] = [{ sortBy, sortOrder }];
    this.send(AnalyticsEvent.SELECTED_VIEW_SORT_DROPDOWN, { value });
  }

  public multisortActionEvent(multisortOptions: MultisortOption[]): void {
    const value: SortingEvent[] = multisortOptions.map((x) => {
      return {
        sortBy: x.sortBy,
        sortOrder: x.sortOrder === 0 ? FilterSortOrder.Ascending : FilterSortOrder.Descending,
      };
    });
    this.send(AnalyticsEvent.SELECTED_VIEW_SORT_DROPDOWN, { value });
  }

  public markedTaskAsCompleteEvent(taskType: string, section: AnalyticsSectionType) {
    const eventProps = {
      taskType,
      section,
    };
    this.send(AnalyticsEvent.MARK_TASK_AS_COMPLETE, eventProps);
  }

  public openedToolEvent(fromUrl: string) {
    const eventProps = {
      tool: this.getCurrentPageSourceName(),
    };
    this.send(AnalyticsEvent.OPENED_PAGE, eventProps, fromUrl);
  }

  /**
   * send a NotificationClicked event
   * @param notificationID the notification id
   * @param taskID  the task id
   * @param type  the notification type
   */
  public notificationClickedEvent(notificationID: string, taskID: string, type: string): void {
    const eventProps = {
      notificationID,
      taskID,
      type,
    };
    this.send(AnalyticsEvent.NOTIFICATION_CLICKED, eventProps);
  }

  public createdTaskEvent(userId, task, section, contacts) {
    const eventProps = this.buildTaskEventsProps(task, section, userId, contacts);
    this.send(AnalyticsEvent.CREATED_TASK, eventProps);
  }

  public editTaskEvent(userId, task, section) {
    const eventProps = this.buildTaskEventsProps(task, section, userId);
    this.send(AnalyticsEvent.EDITED_TASK, eventProps);
  }

  public emailTemplateEvents(eventName, template, tiles, alsoSendEmail, variablesUsed, type?) {
    const eventProps: any = {
      enabledOpenTracking: template.isTrackOpen,
      sharingSetting: template.scope === ScopeNumber.Owner ? 'Private' : 'Shared',
      sendOptions: '',
      tagged: template.tags.length > 0,
      addedAttachment: tiles.length > 0,
      addedUnsubscribeLink: template.attributes.message.indexOf('Unsubscribe') !== -1,
      variablesUsed,
      selectedSaveOption: alsoSendEmail === true ? 'Save&Send' : 'Save',
    };
    if (type) {
      eventProps.type = type;
    }
    this.send(eventName, eventProps);
  }

  public createdContactEvent(eventProps) {
    this.send(AnalyticsEvent.CREATED_CONTACT, eventProps);
  }

  public importedContactsEvent(eventProps) {
    this.send(AnalyticsEvent.IMPORTED_CONTACTS, eventProps);
  }

  public calledConversationOccuredEvent(event: ConversationContact) {
    this.send(AnalyticsEvent.CONVERSATION_OCCURED, event);
  }

  public downloadedContactsCsv(eventProps: DownloadedCsvContactsEvent) {
    this.send('DownloadedCSV', eventProps);
  }

  public sentEmail(event: SentEmail) {
    this.send('SentEmail', event);
  }

  public replacedContactEvent(event: ReplacedContact) {
    this.send('ReplacedContact', event);
  }

  public clickedLearnMoreLock(event: ClickedLearnMoreLock) {
    this.send('ClickedLearnMoreLock', event);
  }

  public clickedContactUsLock(event: ClickedContactUsLock) {
    this.send('ClickedContactUsLock', event);
  }

  public createdSalesflowEvent(eventProps: CreatedSalesflowEvent) {
    this.send('CreatedSalesflow', eventProps);
  }

  public editedSalesflowEvent(eventProps: EditedSalesflowEvent) {
    this.send('EditedSalesflow', eventProps);
  }

  public addedSalesFlowStepEvent(eventProps: AddedSalesFlowStepEvent) {
    this.send('AddedSalesFlowStep', eventProps);
  }

  public editedSalesflowStepEvent(eventProps: EditedSalesflowStepEvent) {
    this.send('EditedSalesflowStep', eventProps);
  }

  public addedToSalesflowEvent(eventProps: AddedToSalesflowEvent) {
    this.send('AddedToSalesflow', eventProps);
  }

  public viewedSalesflowTabsEvent(eventProps: ViewedSalesflowTabsEvent) {
    this.send('ViewedSalesflowTabs', eventProps);
  }

  public appliedQuickFilter(eventProps: AppliedQuickFilterEvent) {
    this.send('AppliedQuickFilters', eventProps);
  }

  public clickedIntegrationsIcon(eventProps: IntegrationIconClickedEvent) {
    this.send('IntegrationIconsClicked', eventProps);
  }

  public unsubscribeLink(event: UnsubscribeLinkEvent) {
    this.send('UnsubscribeLink', event);
  }

  public unsubscribedContact(event: UnsubscribedContactEvent) {
    this.send('UnsubscribedContact', event);
  }

  /**
   * send a new emailerIconClicked event to analytic
   * @param {string} icon
   * @returns {void}
   */
  public emailerIconClicked(icon: string) {
    const isExtensionMode = getExtensionMode();
    if (isExtensionMode) {
      this.store
        .select(extSizeSelector)
        .pipe(take(1), map(this.mapExtensionSize))
        .subscribe((extensionSize: string) => {
          this.sendAnalyticEventEmailerIconClickedByApplication(icon, isExtensionMode, extensionSize);
        });
    } else {
      this.sendAnalyticEventEmailerIconClickedByApplication(icon, isExtensionMode, '');
    }
  }

  public mapExtensionSize(extensionSize: ExtensionSize) {
    if (extensionSize === ExtensionSize.TWENTY_PCT) {
      return '20';
    } else if (extensionSize === ExtensionSize.FIFTY_PCT) {
      return '50';
    } else if (extensionSize === ExtensionSize.NINETY_PCT) {
      return '90';
    } else {
      return null;
    }
  }

  private sendAnalyticEventEmailerIconClickedByApplication(icon: string, isExtensionMode: boolean, extensionSize: string) {
    const eventProperties: EmailerIconClicked = {
      section: '',
      icon: icon,
      PDM_PRODUCT_CATEGORY: 'Engage',
      PDM_PRODUCT_TYPE: 'Engage',
      PDM_PLATFORM: 'Engage',
      PDM_APPLICATION: isExtensionMode ? 'ChromeExtension' : 'Web',
      PDM_APPLICATION_ATTRIBUTE: extensionSize,
    };

    this.send(AnalyticsEvent.EMAILER_ICON_CLICKED, eventProperties);
  }

  public viewedContactCard(event: ViewedContactCardEvent) {
    this.send('ViewedContactCard', event);
  }

  public sendSelectedDialerTypeEvent(event: SelectedDialerTypeEvent) {
    this.send('SelectedDialerType', event);
  }

  public sendDeletedPhoneNumberEvent() {
    this.send('DeletedPhoneNumber', {});
  }

  public sendAddedVoicemailRecordingEvent(event: AddedVoicemailRecordingEvent) {
    this.send('AddedVoicemailRecording', event);
  }

  public sendDeletedVoicemailRecordingEvent(event: DeletedVoicemailRecordingEvent) {
    this.send('DeletedVoicemailRecording', event);
  }

  /**
   * send a new UpdatedPhoneNumberStatus event to analytic
   * @param {UpdatedPhoneNumberStatus} event
   * @returns {void}
   */
  public updatedPhoneNumberStatusEvent(event: UpdatedPhoneNumberStatus): void {
    this.send('UpdatedPhoneNumberStatus', event);
  }

  /**
   * recieves a new phone number status and creates a new event of type UpdatedPhoneNumberStatus
   * @param {string} status The new phone number status
   * @returns {void}
   */
  public sendUpdatedPhoneNumberStatusEvent(status: string): void {
    const req = new UpdatedPhoneNumberStatus({ status });
    this.updatedPhoneNumberStatusEvent(req);
  }

  /**
   * send a new event of type NewSavedWorkflow
   */
  public sendNewSavedWorkflow(): void {
    this.send('NewSavedWorkflow', {});
  }

  /**
   * send a new event of type TriggerAddedToWorkflow
   * @param trigger the trigger type that has been added
   */
  public sendTriggerAddedToWorkflow(trigger: string): void {
    this.send('TriggerAddedToWorkflow', { trigger: trigger });
  }

  /**
   * send a new event of type ActionAddedToWorkflow
   * @param action the action type that has benn added
   */
  public sendActionAddedToWorkflow(action: string): void {
    this.send('ActionAddedToWorkflow', { action: action });
  }

  /**
   * send a new event of type EditExistingWorkflow
   */
  public sendEditExistingWorkflow(): void {
    this.send('EditExistingWorkflow', {});
  }

  public sentEmailEvent(event: InvitationsCreateRequest, analyticsSection: string = 'Standalone') {
    const variablesIncluded = [];
    Object.entries(variableMap).forEach(([k, v]) => {
      if (event.message.includes(k)) {
        variablesIncluded.push(v);
      }
    });
    const totalRecipientCount: number = (_.get(event, 'to.length') || 0) + (_.get(event, 'cc.length') || 0) + (_.get(event, 'bcc.length') || 0);
    const res = new SentEmail({
      section: analyticsSection,
      numberOfRecipients: totalRecipientCount,
      sendOptions: this.getEmailSource(totalRecipientCount, _.get(event, 'isGroup', false)),
      addedTemplate: _.get(event, 'templateId', 0) > 0,
      templateCategory: _.get(event, 'templateCategory'),
      addedAttachment: _.get(event, 'attachments.length', 0) > 0,
      addedUnsubscribeLink: this.hasUnsubscribeLink(_.get(event, 'message', '')),
      enabledOpenTracking: _.get(event, 'isTrackOpen', false),
      setDeliveryTime: _.get(event, 'sendAt', 0) > 0,
      distanceFromCurrentDate: this.getDeliveryEmailDate(_.get(event, 'sendAt')),
      setFollowUpTask: _.has(event, 'followUpTask'),
      partOfSalesflow: false,
      salesflowStep: '',
      fromEmail: event?.from?.displayName?.toString(),
      fromUserId: _.get(event, 'senderId'),
      replyingTo: _.get(event, 'replyingToEmail')?.toString() || '',
      isReply: _.get(event, 'isReply') || false,
      variablesIncluded,
    });
    this.sentEmail(res);
  }

  public sendVerifiedPhoneNumberForCalls(phoneExtension: string, verificationProcessStatus: string) {
    const req = new VerifiedPhoneNumberForCalls({
      hasExtension: !!phoneExtension,
      selectedSaveOption: verificationProcessStatus,
    });
    this.send(AnalyticsEvent.VERIFIED_PHONE_NUMBER_FOR_CALLS, req);
  }

  public sendCalledContactEvent(event: CallInfo, analyticsSection: string = 'EngageCall', component: string = '') {
    const contactId: number = _.get(event, 'contactInfo.id', 0);
    const isAutoDial: boolean = _.get(event, 'isAutoDial', false);

    this.send(
      AnalyticsEvent.CALLED_CONTACT,
      new CalledContact({
        component,
        isAutoDial,
        contactMail: _.get(event, 'contactInfo.email', ''),
        dialerType: _.get(event, 'dialerType', ''),
        phoneNumber: _.get(event, 'contactInfo.phoneNumberDialed', ''),
        partOfSalesflow: false,
        salesflowStep: '',
        callId: _.get(event, 'prospectCallId', '0'),
        contactId: contactId?.toString(),
        zoomPersonId: (_.get(event, 'contactInfo.zoomId', '0') || '0')?.toString(),
        section: analyticsSection,
      }),
      null,
    );
  }

  public sendCallerEvent(event: CallInfo, analyticsSection: string = 'EngageCall', component: string = '') {
    const contactId: number = _.get(event, 'contactInfo.id', 0);
    const phoneNumber: string = _.get(event, 'contactInfo.phoneNumberDialed', '');
    const taskId: number = _.get(event, 'taskId', 0);
    const dialerType: string = _.get(event, 'dialerType', '');
    const contact$ = contactId ? this.contactService.getContactById(contactId) : of(null);
    const tasks$ = this.taskService.getFilterResults({
      chips: [],
      scope: Scope.Organization,
      entity: FilterEntity.Task,
    });

    forkJoin([contact$, tasks$])
      .pipe(take(1))
      .subscribe(([contact, filters]) => {
        const tasks = _.get(filters, 'tasks', []) as Task[];

        if (!tasks) {
          this.failedToSendEvent(
            `Analytics: Error no tasks found for ${contactId}. This event will not be logged`,
            null,
            null,
            AnalyticsEvent.CONVERSATION_OCCURED,
            {},
          );
          return;
        }

        const foundCallTask = tasks.find((x) => x.id === taskId);
        const steps = _.get(foundCallTask, 'salesWorkflowTemplate.steps', null);
        const callStepId = _.get(foundCallTask, 'salesWorkflowTemplateStepId', -999);

        let foundStep: SalesWorkflowTemplateStep;
        if (steps) {
          foundStep = steps.find((step: SalesWorkflowTemplateStep) => step.id === callStepId);
        }
      });
  }

  public createContactAnalyticsProps(actionPayload: Contact, section) {
    const fields = this.computeFieldsFilled(actionPayload);
    return {
      section,
      tag: false,
      fieldsFilled: fields,
      categories: this.computeCategories(fields),
      contactMail: _.get(actionPayload, 'email', ''),
    };
  }

  public importContactsAnalyticsProps(analyticsData) {
    const fields = Object.keys(analyticsData.mapping);
    return {
      section: analyticsData.section,
      tagged: analyticsData.tagged,
      fileSize: analyticsData.fileSize,
      numberOfContacts: analyticsData.numberOfContacts,
      fieldsFilled: fields,
      categories: this.computeCategories(fields),
      numberOfMappedFields: fields.length,
    };
  }

  public createdFollowUpTaskEvent(followUpTask, section) {
    const eventProps = this.buildTaskEventsProps(followUpTask, section);
    this.send(AnalyticsEvent.CREATED_FOLLOW_UP_TASK, eventProps);
  }

  public onboardAnalytics(eventName: string, eventProps: any) {
    this.send(eventName, eventProps);
  }

  public taggedContacts(contactIds, pageSource, action) {
    const taggedContact = {
      section: pageSource,
      contactIDs: contactIds,
      action,
    };
    this.send(AnalyticsEvent.TAGGED_CONTACT, taggedContact);
  }

  public getContactsFilterAnalytics(filters) {
    const companies = _.get(filters, 'companies', []).map((filter) => filter.value);
    const numOfEmployees = _.get(filters, 'numbers_of_employees', [{ value: 'null' }]).map((filter) => this.minAndMaxString(filter))[0];
    const annualRevenue = _.get(filters, 'annual_revenues', [{ value: 'null' }]).map((filter) => this.minAndMaxString(filter))[0];
    const departments = _.get(filters, 'departments', []).map((filter) => filter.value);
    const industries = _.get(filters, 'industries', []).map((filter) => filter.value);
    const jobTitles = _.get(filters, 'job_titles', []).map((filter) => filter.value);
    const countries = _.get(filters, 'countries', []).map((filter) => filter.value);
    const states = _.get(filters, 'state_or_provinces', []).map((filter) => filter.value);
    const cities = _.get(filters, 'cities', []).map((filter) => filter.value);

    return {
      companies,
      numOfEmployees,
      annualRevenue,
      departments,
      industries,
      jobTitles,
      countries,
      states,
      cities,
    };
  }

  public getEngagementFilterAnalytics(filters) {
    const lastEngagedArray = _.get(filters, 'active_at', []);
    const lastEngaged = !lastEngagedArray.length ? 0 : lastEngagedArray.map((filter) => this.daysInPast(filter))[0];
    const lastEmailedArray = _.get(filters, 'SentDate', []);
    const lastEmailed = !lastEmailedArray.length ? 0 : lastEmailedArray.map((filter) => this.daysInPast(filter))[0];
    const lastCalledArray = _.get(filters, 'CalledAt', []);
    const lastCalled = !lastCalledArray.length ? 0 : lastCalledArray.map((filter) => this.daysInPast(filter))[0];
    const engagementCallResult = _.get(filters, 'LastCallResult', []).map((filter) => filter.value);
    const engagementCallSentiment = _.get(filters, 'LastCallSentiment', []).map((filter) => filter.value);
    const engagementCallDuration = _.get(filters, 'LastConversationDuration', []).map((filter) => filter.value);
    const engagementEmailActivity = _.get(filters, 'EmailActivity', []).map((filter) => filter.value);

    return {
      lastEngaged,
      lastEmailed,
      lastCalled,
      engagementCallResult,
      engagementCallSentiment,
      engagementCallDuration,
      engagementEmailActivity,
    };
  }

  public sendApplicationReloadRequestEvent(reason?: string) {
    const props = { reason: reason || null };
    this.send(AnalyticsEvent.APPLICATION_RELOAD_REQUEST, props);
  }

  /**
   * Emit failed amplitude event during a unlikely scenario when sending amplitude event
   * @param error - Error object
   * @param failedEventName - AnalyticsEvent failed to send
   * @param failedEventProperties - properties object
   * @param source - Page source
   */
  public failedToSendEngageEvent(error: any, failedEventName: AnalyticsEvent, failedEventProperties: any, source = '') {
    try {
      const application = getExtensionMode() ? AnalyticsApplication.EngageExtension : AnalyticsApplication.Engage;
      const propsSource = source || this.getCurrentPageSourceName();
      const errorProps = {
        application,
        failedEvent: failedEventName,
        errorDetails: error.toString(),
        eventProperties: failedEventProperties,
      };
      this.send(AnalyticsEvent.FAILED_TO_SEND_EVENT, errorProps, propsSource);
    } catch (err: any) {
      this.loggerService.error(err);
    }
  }

  /**
   * Private functions
   */

  /**
   * Returns email delivery date in days from today
   */
  private getDeliveryEmailDate(deliveryEpoch: any): string {
    const zeroString = '0';
    if (deliveryEpoch === 0) {
      return 'null'; // Empty for email being sent at at time of creation
    }
    const deliveryDate = moment(deliveryEpoch);
    const isSameDayDelivery = deliveryDate.isSame(new Date(), 'day');
    if (isSameDayDelivery) {
      return zeroString;
    }
    const todayDate = moment(new Date());
    return deliveryDate.diff(todayDate, 'days').toString();
  }

  /**
   * Since the unsubscribe link can be named anything, we check on specific keywords
   */
  private hasUnsubscribeLink(emailMessage: string): boolean {
    return emailMessage.toLowerCase().includes('unsubscribe');
  }

  private getEmailSource(recipientCount: number, isGroupedEmail: boolean): string {
    if (isGroupedEmail) {
      return 'GroupEmail';
    } else if (recipientCount === 1) {
      return 'Manual';
    } else {
      return 'Campaign';
    }
  }

  private buildTaskEventsProps(task, section, userId?, contacts?) {
    const analyticProps: TaskEvent = {
      taskType: task.type,
      priority: task.priority,
      distanceFromCurrentDate: moment(task.dueAt).diff(new Date(), 'days'),
      includedNote: !!task.note,
      section: section || '',
      amount: contacts ? contacts.length : 1,
      ...(userId ? { assignTo: userId.toString() } : false),
    };
    return analyticProps;
  }

  private computeFieldsFilled(payload) {
    return Object.keys(payload).filter((element) => payload[element] !== '');
  }

  private computeCategories(fields) {
    const titles = contactForm.filter((form) => fields.includes(form.formControl)).map((form) => form.title);
    return Object.keys(TREE_DATA).filter((categoryTitle) => _.intersection(titles, TREE_DATA[categoryTitle]).length > 0);
  }

  private loginEvent(isAdmin: boolean) {
    const loggedInProps = {
      application: 'Engage',
      Admin: isAdmin,
      LoginTo: 'Engage',
      LoginType: 'Credentials', // Just as default we don't really know the login type
      Used2FA: false,
    };
    this.send(AnalyticsEvent.LOGGED_IN, loggedInProps, 'noSource');
  }

  private routerAmplitudeEvents() {
    this.router.events.pipe(untilDestroyed(this)).subscribe((path: any) => {
      if (path instanceof RoutesRecognized) {
        this.send('OpenedTool', { tool: path.url });
      }
    });
  }

  private daysInPast({ value }): number {
    const today = new Date();
    const earliestDateString = value.match(/^\['(.*?)',/)[1];
    const date = new Date(earliestDateString);
    const timeInMs = today.getTime() - date.getTime();
    return Math.floor(timeInMs / (1000 * 60 * 60 * 24));
  }

  private minAndMaxString({ value }): string {
    if (value === 'null') {
      return value;
    }
    const min = value.match(/^\[(.*?),/)[1];
    const max = value.match(/, (.*?)[}\]]$/)[1];
    return `Min:${min} To Max:${max}`;
  }
}
