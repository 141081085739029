import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { OnBoardingService } from '@zi-pages/on-boarding/services/on-boarding.service';
import * as _ from 'lodash';
import { OnBoardingEntityState } from '@zi-pages/on-boarding/ngrx/state/on-boarding.state';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { ApplicationState } from '@app/reducers';
import { getProfile } from '@zi-core/ngrx/state/auth.state';
import { Profile } from '@zi-core/data-model/profile.model';
import * as moment from 'moment';
import { getExtensionMode } from '@app/extensionMode.config';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { NotyService } from '@app/common/service/noty/noty.service';
import { Message } from '@app/common/model/message/message.model';
import { MessageType } from '@app/common/model/message/message-type';
import { LoginService } from '@app/login/services/login.service';

@Injectable({
  providedIn: 'root',
})
export class OnBoardingGuard implements CanActivate {
  defaultCreationDate = '2020/05/10';
  statusUrl: string;
  skipOnboarding = false;
  private HIDE_HEADER_PARAM = 'hideHeaders';

  constructor(
    private onBoardingStore: Store<OnBoardingEntityState>,
    private loginService: LoginService,
    private router: Router,
    private notyService: NotyService,
    private appStore: Store<ApplicationState>,
    private onBoardingService: OnBoardingService,
  ) {
    this.statusUrl = 'https://status.zoominfo.com';
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    return this.appStore.select(getProfile).pipe(
      take(1),
      switchMap((userProfile: Profile) => {
        // get creation date -> check if bigger then 15-May-2020
        if (moment(new Date(userProfile.createdAt)).isAfter(moment(new Date(this.defaultCreationDate))) || _.get(userProfile, 'loginCount', 0) < 2) {
          return this.onBoardingService.getOnBoarding().pipe(
            map((res) => {
              this.skipOnboarding = next.queryParams[this.HIDE_HEADER_PARAM] && next.queryParams[this.HIDE_HEADER_PARAM] === 'true';
              if (res && _.get(res, 'value.unfinishedSteps.length', 0) > 0 && !this.skipOnboarding) {
                const required = this.onBoardingService.getMappedSteps(res.value.unfinishedSteps).find((step) => step.required);
                if (required) {
                  if (getExtensionMode()) {
                    this.router.navigate(['/ext-setup']);
                    return true;
                  }

                  this.router.navigate(['/on-boarding/setup']);
                  return false;
                }
                return true;
              } else {
                return true;
              }
            }),
            catchError((error: HttpErrorResponse) => {
              switch (error?.status) {
                case HttpStatusCode.BadRequest:
                  this.notyService.postMessage(new Message(MessageType.ERROR, 'Cannot fetch onboarding details. Please try again.'));
                  break;
                case HttpStatusCode.Unauthorized:
                case HttpStatusCode.PaymentRequired:
                case HttpStatusCode.Forbidden:
                case HttpStatusCode.NotFound:
                  this.loginService.generalLogout();
                  break;
                case HttpStatusCode.NotAcceptable:
                  return this.initialOnBoarding();
                default:
                  window.location.href = this.statusUrl;
                  break;
              }
              return of(false);
            }),
          );
        }
        return of(true);
      }),
    );
  }

  initialOnBoarding(): Observable<boolean> {
    const onBoardingObj = { unfinishedSteps: this.onBoardingService.getAllSteps() };
    return this.onBoardingService.upsertOnBoarding(onBoardingObj).pipe(
      map((res) => {
        if (getExtensionMode()) {
          this.router.navigate(['/ext-setup']);
          return true;
        }

        this.router.navigate(['/on-boarding']);
        return false;
      }),
    );
  }
}
