import { Contact } from '@app/core/data-model/contact.model';
import { ContactDto } from '@zi-core/http-model/response/contacts.response.model';
import { createAction, props } from '@ngrx/store';
import { FilterQueryNode } from '@app/core/data-model/filter-node.model';
import { FilterSortOrder, FilterSort, Scope } from '@app/core/enums/engage-filter.enum';
import { ContactSource } from '@zi-core/enums/contact-source.enum';
import { EngagePage } from '@app/common/model/engage-page.enum';
import { ContactPhoneStatus } from '@app/core/enums/contact-phone-status.enum';
import { PhoneTypesEnum } from '@app/caller/interface/phone-types-enum';
import { BulkTagChanges } from '@app/common/pages-component/add-remove-tag-dialog/model/bulk-tag-changes';
import { SubscriptionSetting } from '@app/core/http-model/request/subscription-setting-request.model';
import { CrmEntityType } from '@app/core/enums/crm-entity-type.enum';
import { SalesWorkflowTemplate } from '@app/core/http-model/response/sales-workflow-template.response.model';

export const ResetContactEntitiesAction = createAction('[Contact] entity/reset');

// add contact
export const AddContactAction = createAction('[Contact] entity/add', props<{ payload: Contact; callSid?: string; analyticsData: any; isFromSms?: boolean }>());

// add contact success
export const AddContactSuccessAction = createAction(
  '[Contact] entity/add/success',
  props<{ payload: Contact; callSid?: string; analytics: any; isFromSms?: boolean }>(),
);

// add contact success
export const AddContactFailureAction = createAction('[Contact] entity/add/failure', props<{ payload?: Contact; error: string; errorCode?: string }>());

export const UpdateContactAction = createAction(
  '[Contact] entity/update',
  props<{ payload: Contact; updateEngageSettings?: boolean; updateType?: string; oldContact?: Contact }>(),
);

// update contact success
export const UpdateContactSuccessAction = createAction('[Contact] entity/update/success', props<{ payload: Contact }>());

// update contact failure
export const UpdateContactFailureAction = createAction('[Contact] entity/update/failure', props<{ payload?: Contact; error: string; errorCode?: string }>());

// update contact status action
export const UpdateContactEntityStatusAction = createAction('[Contact] update status', props<{ status: string; contactId: number }>());

// update contact status success
export const UpdateContactEntityStatusSuccessAction = createAction(
  '[Contact] update status success',
  props<{ status: string; contactId: number; contactType: CrmEntityType; externalId: string }>(),
);

// update contact status failure
export const UpdateContactEntityStatusFailureAction = createAction(
  '[Contact] update status failure',
  props<{ status: string; contactId: number; error: any }>(),
);

// update contact phone status action
export const UpdateContactEntityPhoneStatusAction = createAction(
  '[Contact] update phone status',
  props<{ phoneType: PhoneTypesEnum; contactPhoneNewStatus: ContactPhoneStatus; contactId: number }>(),
);

// update contact phone status success
export const UpdateContactEntityPhoneStatusSuccessAction = createAction(
  '[Contact] update phone status success',
  props<{ phoneType: PhoneTypesEnum; contactPhoneNewStatus: ContactPhoneStatus; contactId: number }>(),
);

// update contact phone status failure
export const UpdateContactEntityPhoneStatusFailureAction = createAction(
  '[Contact] update phone status failure',
  props<{ phoneType: PhoneTypesEnum; contactPhoneNewStatus: ContactPhoneStatus; contactId: number; error: any }>(),
);

// update contact status action in bulk
export const UpdateBulkContactEntityStatusAction = createAction('[Contact] bulk update status', props<{ status: string; contactIds: Array<number> }>());

// update contact status success
export const UpdateBulkContactEntityStatusSuccessAction = createAction(
  '[Contact] bulk update status success',
  props<{ status: string; contacts: Array<Contact> }>(),
);

// update contact status failure
export const UpdateBulkContactEntityStatusFailureAction = createAction(
  '[Contact] bulk update status failure',
  props<{ status: string; contactIds: Array<number>; error: any }>(),
);

// get contacts by ids
export const GetContactsByIdsAction = createAction('[Contact] entity/get/contacts', props<{ contactIds: Array<number>; requestId: number }>());

// get contacts by ids success
export const GetContactsByIdsSuccessAction = createAction('[Contact] entity/get/contacts/success', props<{ payload: ContactDto[]; requestId: number }>());

// get contacts by ids failure
export const GetContactsByIdsFailureAction = createAction(
  '[Contact] entity/get/contacts/failure',
  props<{ payload?: Contact; error: string; errorCode?: string }>(),
);

// get contact
export const GetContactAction = createAction('[Contact] entity/get/contact', props<{ id: number; displayError?: boolean }>());

// get contact success
export const GetContactSuccessAction = createAction('[Contact] entity/get/contact/success', props<{ contact: Contact }>());

// get contacts by ids failure
export const GetContactFailureAction = createAction('[Contact] entity/get/contact/failure', props<{ error: any }>());

// unsubscribe contact
export const UnsubscribeContact = createAction('[Contact detail] detail/unsubscribe', props<{ contact: Contact }>());

// unsubscribe contact
export const UnsubscribeContactSuccess = createAction('[Contact detail] detail/unsubscribe/success', props<{ contact: Contact }>());

// unsubscribe contact
export const UnsubscribeContactFailure = createAction('[Contact detail] detail/unsubscribe/failure', props<{ error: string }>());

export const BulkUnsubscribeContactsAction = createAction(
  '[Contact] entities/bulkUnsubscribe',
  props<{ contactIds: number[]; subscriptionSetting: SubscriptionSetting }>(),
);

export const BulkUnsubscribeContactsSuccessAction = createAction(
  '[Contact] entities/bulkUnsubscribe/success',
  props<{ contactIds: number[]; subscriptionSetting: SubscriptionSetting }>(),
);

export const BulkUnsubscribeContactsFailureAction = createAction('[Contact] entities/bulkUnsubscribe/failure', props<{ error: string }>());

// load contacts
// TODO autogenerate requestId
export const LoadContactAction = createAction('[Contact] entity/load', props<{ payload: string; requestId: number; page?: number; count?: number }>());

// load contacts success
export const LoadContactSuccessAction = createAction(
  '[Contact] entity/load/success',
  props<{ contacts: Array<Contact>; requestId: number; page?: number; count?: number; found?: number; isProcessingInCloudSearch?: boolean }>(),
);

// set contacts to store
export const SetContactsAction = createAction('[Contact] entity/contacts/set', props<{ contacts: Array<Contact> }>());

// load contacts failure
export const LoadContactFailureAction = createAction('[Contact] entity/load/failure', props<{ error: string }>());

export const LoadFilteredContactAction = createAction(
  '[Contact] entity/filtered/load',
  props<{
    requestId: number;
    page?: number;
    count?: number;
    scope: Scope;
    sortOrder?: FilterSortOrder;
    sortField?: FilterSort;
    nodes: FilterQueryNode[];
    search: string;
  }>(),
);

export const LoadSearchContactAction = createAction(
  '[Contact] entity/search/load',
  props<{
    requestId: number;
    page?: number;
    count?: number;
    scope: Scope;
    sortOrder?: FilterSortOrder;
    sortField?: FilterSort;
    search: string;
  }>(),
);

// delete contacts
export const DeleteContactsAction = createAction('[Contact] entity/delete', props<{ contactIds: Array<number> }>());

// delete contacts success
export const DeleteContactsSuccessAction = createAction('[Contact] entity/delete/success');

// delete contacts failure
export const DeleteContactsFailureAction = createAction('[Contact] entity/delete/error', props<{ error: any; errorCode?: string }>());

// bulk tag contacts
export const BulkTagContactsActionV2 = createAction('[Contact] entity/tag/v2', props<{ changes: BulkTagChanges; resourceType: string; pageSource: string }>());

export const BulkTagContactsSuccessActionV2 = createAction('[Contact] entity/tag/v2/success', props<{ bulkTagChanges: BulkTagChanges }>());

// bulk tag contacts failure
export const BulkTagContactsFailureAction = createAction('[Contact] entity/tag/failure', props<{ error: any; errorCode?: string }>());

// bulk add contacts to workflow
export const BulkAddContactsToWorkflowAction = createAction(
  '[Contact] entity/workflow',
  props<{
    contacts: Array<Contact>;
    workflowTemplate: SalesWorkflowTemplate;
    sendAt: Date | string;
    senderId: number;
    analyticSection: string;
    engagePage?: EngagePage;
    isOwnerAccordingToData?: boolean;
  }>(),
);

// bulk add contacts to workflow success
export const BulkAddContactsToWorkflowSuccessAction = createAction(
  '[Contact] entity/workflow/success',
  props<{ contacts: Contact[]; workflowTemplateId: number; engagePage?: EngagePage }>(),
);

// bulk add contacts to workflow failure
export const BulkAddContactsToWorkflowFailureAction = createAction('[Contact] entity/workflow/failure', props<{ error: any; errorCode?: string }>());

// bulk add contacts
export const BulkAddContactsAction = createAction(
  '[Contact] entity/add/bulk',
  props<{ contacts: Array<Contact>; labelIds: Array<number>; source: ContactSource; analyticsData: any }>(),
);

// bulk add contacts success
export const BulkAddContactsSuccessAction = createAction(
  '[Contact] entity/add/bulk/success',
  props<{ contacts: Array<Contact>; tagIds: Array<number>; analytics: any }>(),
);

// bulk add contacts failure
export const BulkAddContactsFailureAction = createAction('[Contact] entity/add/bulk/failure', props<{ error: any; errorCode?: string }>());

// download contacts
export const DownloadCsvContactsAction = createAction(
  '[Contact] entity/download',
  props<{ contacts: Array<Contact>; file?: string; skipFiltering?: boolean }>(),
);

// update contact status action in bulk
export const UpdateBulkContactEntityOwnerAction = createAction(
  '[Contact] bulk update owner',
  props<{ ownerId: number; contactIds: Array<number>; showNoty?: boolean; ownerName?: string }>(),
);

export const UpdateBulkContactEntityOwnerSuccessAction = createAction(
  '[Contact] bulk update owner success',
  props<{ ownerId: number; contactIds: Array<number> }>(),
);

export const UpdateBulkContactEntityOwnerFailureAction = createAction(
  '[Contact] bulk update owner failure',
  props<{ ownerId: number; contactIds: Array<number>; error: any }>(),
);

export const BulkUpdateContactsAction = createAction('[Contact] entity/update/bulk', props<{ contacts: Contact[] }>());

export const ContactEntityNoOpAction = createAction('[Contact] No Op');
