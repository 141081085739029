import { Injectable } from '@angular/core';
import { InvitationsCreateRequest } from '@zi-core/http-model/request/invitations.request.model';

@Injectable()
export class EmailStorageService {
  emailTemplates = 'emailTemplates';

  constructor() {}

  storeEmailCreateObjectsInSessionStorage(templates: Array<InvitationsCreateRequest>) {
    sessionStorage.setItem(this.emailTemplates, JSON.stringify(templates));
  }

  loadEmailCreateFromSessionStorage(): Array<InvitationsCreateRequest> {
    if (sessionStorage.getItem(this.emailTemplates)) {
      return [...JSON.parse(sessionStorage.getItem(this.emailTemplates))];
    }
    return null;
  }

  clearEmailTemplateSessionStorage() {
    sessionStorage.removeItem(this.emailTemplates);
  }
}
