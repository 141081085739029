<app-dialog-template class="dozi-features-header" hasCloseIcon="true" (close)="onClose()">
  <app-dialog-content>
    <div class="contact-us-container">
      <div class="centered">
        <div class="content">
          <p>Thank you! We will reach out to you soon.</p>
          <p>You can also call us at 866-904-9666.</p>
        </div>
        <div>
          <img class="image-success-src" [src]="contactUsSrc" />
        </div>
        <div>
          <div>
            <app-primary-btn class="ok-contact-us-btn" [text]="'Ok'" (clickButton)="onClose()"> </app-primary-btn>
          </div>
        </div>
      </div>
    </div>
  </app-dialog-content>
</app-dialog-template>
