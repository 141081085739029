import { DialerSelection } from '@app/core/enums/dialer.enum';
import { createAction, props } from '@ngrx/store';

export const GetSelectedDialerConfigAction = createAction('[DialerConfig] Get Selected Dialer Configuration');

export const SaveSelectedDialerConfigAction = createAction(
  '[DialerConfig] Save Selected Dialer Configuration',
  props<{ dialerSelection: DialerSelection | null }>(),
);

export const SaveSelectedDialerConfigErrorAction = createAction('[DialerConfig] Save Selected Dialer Configuration Error');

export const AddDialerPhoneNumber = createAction(
  '[DialerConfig] Add Number Configuration',
  props<{ twilioCallerId: any; dialerSelection: DialerSelection | null }>(),
);

export const DeleteDialerPhoneNumber = createAction('[DialerConfig] Delete Number Configuration', props<{ dialerSelection: DialerSelection | null }>());

export const ResetDialerPhoneNumber = createAction('[DialerConfig] Reset Number Configuration', props<{ dialerSelection: DialerSelection | null }>());
