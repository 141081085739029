export enum ResourceType {
  None = 0,
  Account = 1,
  OrgMembers = 2,
  OrgSubscription = 3,
  UserOrgMemberships = 4,
  EmailProviderConfig = 5,
  UserOrgSubscriptions = 6,
  OrgTeams = 7,
  Site = 11,
  Organization = 12,
  Profile = 13,
  Contact = 14,
  Space = 15,
  ExternalProvider = 16,
  CompanyAccount = 17,
  CompanyAccountContact = 18,
  BasicSite = 101,
  BasicOrganization = 102,
  BasicProfile = 103,
  BasicContact = 104,
  BasicSpace = 105,
  EmailTemplate = 106,
  SalesflowTemplate = 107,
}

export enum LabelResourceType {
  Contact = 'Contact',
  EmailTemplate = 'EmailTemplate',
  SalesflowTemplate = 'SalesWorkflowTemplate',
  Content = 'Content', // Used for shared tags
  CompanyAccount = 'CompanyAccount',
}

export enum StatusPickListResourceType {
  Contact = 'Contact',
  CompanyAccount = 'CompanyAccount',
}
