<div #inputSearchDropdown class="search-input-container" (click)="searchValue.updateValueAndValidity()">
  <div class="input-field-container" [automationId]="'company-account-search-input-field-container'">
    <ng-container *ngIf="selectedOption">
      <app-company-account-image class="search-dropdown-selected-account-image" [companyAccount]="selectedOption?.value"> </app-company-account-image>
    </ng-container>
    <ng-container *ngIf="!selectedOption">
      <i class="icon-search icon" [automationId]="'company-account-search-input-search-icon'"></i>
    </ng-container>
    <input
      #inputField
      [automationId]="'company-account-search-input-field'"
      type="text"
      class="input-field"
      [formControl]="searchValue"
      [placeholder]="placeholder"
      (keyup)="onKeyUp()"
    />
    <i
      class="icon-x icon"
      [ngClass]="{'clear-icon-disabled': isClearDisabled}"
      *ngIf="searchValue?.value"
      (click)="clear()"
      [automationId]="'company-account-search-input-x-icon'"
    ></i>
  </div>

  <div *ngIf="($dropdownOptions | async)?.length > 0" class="results-panel show-results" [automationId]="'company-account-search-results-panel'">
    <ng-container *ngFor="let option of $dropdownOptions | async">
      <!--      listen to 'mousedown' instead of 'click' because it fires before 'blur', and 'click' fires after 'blur'!-->
      <div
        class="single-result"
        [automationId]="'account-company-search-result-'+option?.value?.name"
        [ngClass]="{'disabled': linkedCompanyAccountIdToDisable === option?.value?.id}"
        (mousedown)="clickOption(option)"
        [matTooltip]="disabledToolTipText"
        matTooltipPosition="above"
        matTooltipClass="engage-tooltip"
        [matTooltipDisabled]="linkedCompanyAccountIdToDisable !== option?.value?.id"
      >
        <app-company-account-image
          class="search-dropdown-account-image"
          [automationId]="'search-dropdown-account-image'"
          [companyAccount]="option?.value"
        ></app-company-account-image>
        <app-dotten-text
          class="company-account-search-dropdown-result"
          [automationId]="'company-account-search-dropdown-result'"
          [hasTooltip]="true"
          [text]="option?.value?.name"
        ></app-dotten-text>
        <div>{{option?.value?.numberOfContacts || 0}} Contact{{option?.value?.numberOfContacts === 1 ? '' : 's'}}</div>
        <app-status-indicator
          class="company-account-search-dropdown-status"
          [automationId]="'company-account-search-dropdown-status'"
          [statusColor]="companyAccountStatusColors[option?.value?.id] || ''"
          [statusText]="option?.value?.status || defaultPickListVal"
        >
        </app-status-indicator>
      </div>
    </ng-container>
  </div>

  <div *ngIf="noResultsFound" class="no-results">No Results Found</div>
</div>
