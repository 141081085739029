export enum Flag {
  ROLES_AND_PERMISSONS = 'engage-5-pex-160-integrations-roles-and-permissions',
  SENDING_EMAILS_ON_BEHALF_OF = 'engage9-ze-13484-emails-sendingEmailsOnBehalfOf',
  REPORTING_CALL_ANALYTICS = 'engage-1-ze-2494-reporting-call-analytics',
  ACTIVE_IN_SALESFLOW = 'engage9-ze-14559-filters-activeInSalesflow',
  UMS_INTEGRATION = 'engage-2-ze-14994-okta_token_validation',
  NEVERBOUNCE_INTEGRATION_PHASE_1 = 'engage-2-ze-11237-neverbounce_integration_phase_1',
  ACCOUNT_ORG_SYNC = 'engage3-ze-15089-account-org-sync-buttons',
  CUSTOM_PROFILES = 'engage5-ze-15954-createEditCustomProfiles',
  OFFER_SMS = 'engage4-ze-13867-sms',
  ENABLE_SMS = 'engage4-ze-13867-sms-enableSMS',
  ENABLE_LOCAL_PRESENCE = 'engage4-ze-12903-localPresence',
  ENABLE_LOCAL_PRESENCE_ENTITLEMENT = 'engage4-ze-12903-localPresence-ENTITLEMENT',
  API_MONITORING_PAGE = 'engage3-ze-15326-salesforce-apiMonitoring',
  EMAIL_REPLIES_FROM_ENGAGE = 'engage9-ze-13058-emails-emailRepliesFromEngage',
  ONBOARDING_SKIP_EMAIL = 'engage-2-ze-16666-onboarding_skip_email_step',
  SALESFLOW_VISIBILITY_PHASE1 = 'engage3-ze-16235-salesflow-visibilityPhase1',
  IMPORT_ENABLE_ORGSYNC_RESOLUTION = 'engage3-ze-10233-import-orgsyncContactImportTypes',
  CRM_CONTACT_OWNER_MAPPING = 'engage9-ze-13060-import-contactOwnerFieldMapping',
  USER_MAPPING_PAGE = 'engage3-ze-18057-crmsettings-usermapping',
  IMPORT_CONTACTS_PERMISSIONS = 'engage-2-ze-14101-profiles_import_contacts_permissions',
  SALESFLOW_AND_EMAIL_OBO_CONTACT_OWNER = 'engage9-ze-16371-salesflows-salesflowEmailsOnBehalfOfOwner',
  BLOCKLISTING_OF_DOMAINS_AND_EMAILS_OUTBOUND = 'engage9-ze-17422-email-blocklistingOfDomainsAndEmailsOutbound',
  RECRUITER_OOTB_TEMPLATES = 'engage3-ze-17842-recruiter-ootbTemplates',
  CONTACT_PROFILE_BACKEND_CLEANUP = 'engage3-ze-18423-profile-cleanBackendCalls',
  SALESFLOW_BASED_CONTACT_FILTER = 'engage9-ze-16372-filter-salesflowBasedContactsFilter',
  UNIFIED_VOICE_FEED = 'engage4-ze-18932-unified_voice_feed',
  NEW_FILTERING_UX_DISCOVERY_AND_ENHANCEMENTS = 'engage9-ze-16778-filter-filteringUXDiscoveryAndEnhancements',
  TRUSTED_CALLING = 'engage4-ze-18878-dialer-trustedCalling',
  SELECT_RANGE_CONTACTS = 'engage3-ze-16713-contacts-selectRange',
  UNIE_SALESFORCE_AUTHENTICATION = 'engx-engage-3-ZE-20506-use-unie-connector',
  DOZI_ADMIN_PORTAL_INTEGRATIONS_ENGAGE = 'engage3-ZE-20431-AdminPortalIntegrationsEngage-EngageSideFlag',
  ADMIN_INTEGRATIONS_HIDE = 'engage3-ZE-22217-AdminIntegrations-Hide',
  ENABLE_DIALER_SERVICE_REQUESTS = 'engage4-ze-24040-enable-dialer-service-requests',
  TASK_NOTIFICATION_TRIGGERED_INDIVIDUAL = 'engage8-ze-25411-task-notification-triggered-individual',
  SEARCH_SALESFLOW_CONTACTS_TAB = 'engage1-ze-25535-search-salesflow-contacts-tab',
  ORG_MANAGEMENT_SALESFLOW_QUOTA = 'engagekenobi-ze26156-salesflows-quota',
  SALESFLOW_STEP_AB_TEMPLATE_ORG_INSIGHTS = 'engage-kenobi-ze-27916-display-ab-templates-org-insights',
  FROALA_USABILITY_IMPROVEMENTS = 'engage-yoda-ze-28401-email-froalaUsabilityImprovements',
  SALESFLOW_INSIGHTS_BY_IDS_FROM_INSIGHTS_TABLE = 'engage-kenobi-ze-27491-salesflow-insights-by-ids-from-insights-table',
  ENABLE_API_ENCODING_FOR_WAF = 'engage-council-ZE-28248-api-encoding-for-waf',
  DASHBOARD_API_PERF_IMPROVEMENTS = 'engage-kenobi-ze-29367-dashboard-api-perf-improvements',
  ADA_CHAT_BOT_BUTTON = 'engage-yoda-ze-30496-ada-chat-bot',
  PREVENT_XSS_EMAIL_TEMPLATES_DISPLAY = 'engage-yoda-ze-31828-prevent-xss-email-templates-display',
  ENGAGE_IN_HOUSE_IFRAME_ANALYTICS = 'engage-kenobi-ze-31105-in-house-analytics',
  EMAIL_FROALA_FONT_CHANGES_ON_SPELL_CHECK = 'engage-yoda-ze-32036-email-froalaFontChangesOnSpellCheck',
}
