import { createAction, props } from '@ngrx/store';
import { Task } from '@app/core/data-model/task.model';
import { Contact } from '@app/core/data-model/contact.model';
import { TaskStatus } from '@zi-core/enums/task-status.enum';
import { FilterSort, FilterSortOrder, Scope } from '@zi-core/enums/engage-filter.enum';
import { FilterQueryNode } from '@zi-core/data-model/filter-node.model';
import { EngagePage } from '@app/common/model/engage-page.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { MultisortFilter } from '@zi-core/data-model/multisort-filter.model';

export const LoadTaskAction = createAction('[Task] task/load', props<{ userId: number; requestId: number; found?: number }>());

// load task success
export const LoadTaskSuccessAction = createAction(
  '[Task] task/load/success',
  props<{ tasks: Array<Task>; requestId: number; found?: number; statistics?: any }>(),
);

// load task failure
export const LoadTaskFailureAction = createAction('[Task] task/load/failure', props<{ error: object }>());

export const AddNewTaskAction = createAction(
  '[Task] task/new',
  props<{
    userId: number;
    contacts: Contact[];
    task: any;
    section?: string;
    isFollowUpTask: boolean;
    isBulkAction?: boolean;
    accountId?: number;
    engagePage?: EngagePage;
  }>(),
);

export const AddNewTaskSuccessAction = createAction('[Task] task/new/success', props<{ tasks: Task[]; contacts: Contact[]; engagePage?: EngagePage }>());

export const EditTaskAction = createAction('[Task] task/edit', props<{ userId: number; task: Task; section?: string }>());

export const EditTaskSuccessAction = createAction('[Task] task/edit/success', props<{ tasks: Task[] }>());

export const EditTaskFailureAction = createAction('[Task] task/edit/failure', props<{ error: HttpErrorResponse }>());

// remove tasks from entities
export const RemoveTasksFromTaskEntities = createAction('[Task] Remove tasks', props<{ tasks: Array<number> }>());

// update task status
export const UpdateTasksStatus = createAction('[Task] Tasks Update Status', props<{ tasks: Array<number>; status: TaskStatus }>());

export const LoadFilteredTasksAction = createAction(
  '[Task] entity/filtered/load',
  props<{
    requestId: number;
    page?: number;
    count?: number;
    scope: Scope;
    search?: string;
    sortOrder?: FilterSortOrder;
    sortField?: FilterSort;
    multisort?: MultisortFilter[];
    nodes: FilterQueryNode[];
  }>(),
);

export const LoadTaskStatisticsAction = createAction('[Task] Load Task Statistics', props<{ requestId: number; scope: Scope; nodes: FilterQueryNode[] }>());

export const LoadTaskStatisticsSuccessAction = createAction('[Task] Load Task Statistics Success', props<{ requestId: number; statistics: any }>());

export const UpdateContactTasks = createAction('[Task] entity/update/contact', props<{ contacts: Contact[] }>());

// update task data
export const UpdateTasksData = createAction('[Task] entity/update/task', props<{ tasks: Task[] }>());

export const ResetTasksEntity = createAction('[Task] entity/load/ResetTasksEntity');
