import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Tile } from '@zi-core/data-model/tile.model';
import { SpacesResponse } from '@zi-core/http-model/response/spaces.response.model';
import { Observable } from 'rxjs';
import { map, switchMap, tap, filter, take, mergeMap } from 'rxjs/operators';
import { getUserLicenses, getValidAuthToken } from '@app/core/ngrx/state/auth.state';
import { ContentEntityState } from '../ngrx/state/content-entity.state';
import { AuthenticatedToken } from '@app/core/http-model/response/authenticated-token.response.model';
import { contentEntitySelector } from '../ngrx/state/content.state';
import * as _ from 'lodash';
import { LoadContentAction } from '../ngrx/action/content-entity.action';
import { ApplicationState } from '@app/reducers';
import { Store } from '@ngrx/store';
import { SearchQueryAnalyticsModel } from '@zi-core/data-model/analytics/search-query-analytics.model';
import { FeatureFlagService } from '@app/feature-flag/feature-flag.service';
import { DataSerializationService } from '@zi-common/service/data-serialization.service';
import { Flag } from '@app/feature-flag/types/flag.enum';
import { StringEncodingMethod } from '@zi-core/enums/string-encoding-method.enum';
import { ApiEncodedPayload } from '@zi-core/http-model/request/api-encoded-payload.model';
import { UtilitiesService } from '@zi-common/service/utilities-service/utilities-service';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  private readonly postCreateTemplatesUrl = `${environment.backend_url}/v1/templates/create`;
  private readonly postUpdateTemplatesUrl = `${environment.backend_url}/v1/templates/update`;
  private readonly postDuplicateLibraryTemplate = `${environment.backend_url}/v1/librarytemplate/duplicate`;

  private readonly postEncodedUpdateTemplatesUrl = `${environment.backend_url}/v2/encoded/templates/update`;
  private readonly postEncodedCreateTemplatesUrl = `${environment.backend_url}/v2/encoded/templates/create`;
  private readonly postEncodedDuplicateLibraryTemplate = `${environment.backend_url}/v2/encoded/librarytemplate/duplicate`;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly store: Store<ApplicationState>,
    private readonly featureFlagService: FeatureFlagService,
    private readonly dataSerializationService: DataSerializationService,
    private readonly utilitiesService: UtilitiesService,
  ) {}

  getWithQuery(queryParams: string): Observable<SpacesResponse> {
    return this.httpClient.get<SpacesResponse>(`${environment.backend_url}/v1/templates?${queryParams}`).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  getTemplatesWithoutBody(): Observable<SpacesResponse> {
    return this.httpClient.get<SpacesResponse>(`${environment.backend_url}/v2/templates`);
  }

  getCannotDeleteTemplatesIds(): Observable<number[]> {
    return this.httpClient.get<number[]>(`${environment.backend_url}/v1/templates/cannotDelete`);
  }

  add(entity: any): Observable<SpacesResponse> {
    return this.featureFlagService.observe<Flag.ENABLE_API_ENCODING_FOR_WAF>(Flag.ENABLE_API_ENCODING_FOR_WAF).pipe(
      take(1),
      switchMap((apiEncodingEnabled: boolean) => {
        if (apiEncodingEnabled && entity?.Template?.attributes?.message) {
          entity.Template.attributes.message = this.dataSerializationService.getEncodedString(entity.Template.attributes.message, StringEncodingMethod.Base64);
          return this.httpClient.post<SpacesResponse>(this.postEncodedCreateTemplatesUrl, {
            data: entity,
            encoding: StringEncodingMethod.Base64,
          } as ApiEncodedPayload);
        }
        return this.httpClient.post<SpacesResponse>(this.postCreateTemplatesUrl, entity);
      }),
    );
  }

  get(id: number): Observable<SpacesResponse> {
    return this.featureFlagService.observe<Flag.PREVENT_XSS_EMAIL_TEMPLATES_DISPLAY>(Flag.PREVENT_XSS_EMAIL_TEMPLATES_DISPLAY).pipe(
      take(1),
      switchMap((preventXSSEmailTemplatesDisplayEnabled: boolean) => {
        return this.httpClient.get<SpacesResponse>(`${environment.backend_url}/v1/templates?templateId=${id}`).pipe(
          tap((res: SpacesResponse) => {
            // Sanitizes HTML content potentially containing unsafe element
            if (preventXSSEmailTemplatesDisplayEnabled) {
              res.templates[0].attributes.message = this.utilitiesService.sanitizeHtml(res.templates[0].attributes.message);
            }
          }),
        );
      }),
    );
  }

  getReadyToUseTemplates(): Observable<SpacesResponse> {
    return this.store.select(getUserLicenses).pipe(
      take(1),
      mergeMap((license) => {
        const recruiterLicense = license.licenses?.recruiter ? 1 : 0;
        const params = {
          isRecruiterLicense: recruiterLicense,
        };
        return this.httpClient.get<SpacesResponse>(`${environment.backend_url}/v1/librarytemplates`, { params });
      }),
    );
  }

  duplicateReadyToUseTemplate(OOTBTemplate: any, tiles?: Tile[]): Observable<any> {
    return this.featureFlagService.observe<Flag.ENABLE_API_ENCODING_FOR_WAF>(Flag.ENABLE_API_ENCODING_FOR_WAF).pipe(
      take(1),
      switchMap((apiEncodingEnabled: boolean) => {
        const body = {
          templateLibraryId: OOTBTemplate.template.id,
          template: OOTBTemplate.template,
          tiles: OOTBTemplate.tiles ? OOTBTemplate.tiles : [],
        };
        if (apiEncodingEnabled && OOTBTemplate?.template?.attributes?.message) {
          body.template.attributes.message = this.dataSerializationService.getEncodedString(body.template.attributes.message, StringEncodingMethod.Base64);
          return this.httpClient.post<SpacesResponse>(this.postEncodedDuplicateLibraryTemplate, {
            data: body,
            encoding: StringEncodingMethod.Base64,
          } as ApiEncodedPayload);
        }
        return this.httpClient.post<SpacesResponse>(this.postDuplicateLibraryTemplate, body);
      }),
    );
  }

  delete(id: number): Observable<number | string> {
    return this.httpClient.delete<SpacesResponse>(`${environment.backend_url}/v1/templates?templateId=${id}`).pipe(
      map((res) => {
        if (res !== null && res.templates !== null) {
          return 0;
        }
      }),
    );
  }

  bulkDelete(ids: number[]): Observable<number[]> {
    return this.httpClient.delete<number[]>(`${environment.backend_url}/v1/templates/delete`, { body: { TemplatesIds: ids } });
  }

  update(entity: any): Observable<SpacesResponse> {
    return this.featureFlagService.observe<Flag.ENABLE_API_ENCODING_FOR_WAF>(Flag.ENABLE_API_ENCODING_FOR_WAF).pipe(
      take(1),
      switchMap((apiEncodingEnabled: boolean) => {
        if (apiEncodingEnabled && entity?.Template?.attributes?.message) {
          entity.Template.attributes.message = this.dataSerializationService.getEncodedString(entity.Template.attributes.message, StringEncodingMethod.Base64);
          return this.httpClient.post<SpacesResponse>(this.postEncodedUpdateTemplatesUrl, {
            data: entity,
            encoding: StringEncodingMethod.Base64,
          } as ApiEncodedPayload);
        }
        return this.httpClient.post<SpacesResponse>(this.postUpdateTemplatesUrl, entity);
      }),
    );
  }

  bulkTagContents(resourceIds: Array<number>, labelId: string, resourceType: string) {
    return this.httpClient.post(`${environment.backend_url}/v1/labels/set`, { resourceIds, labelId, resourceType }).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  getContentEntityState(): Observable<ContentEntityState> {
    return this.store.select(getValidAuthToken).pipe(
      switchMap((authToken: AuthenticatedToken) => {
        return this.store.select(contentEntitySelector).pipe(
          tap((contentEntityState: ContentEntityState) => {
            const error = _.get(contentEntityState, 'error');
            if (error) {
              // eslint-disable-next-line @typescript-eslint/no-throw-literal
              throw error;
            }

            if (!_.get(contentEntityState, 'loaded') && !_.get(contentEntityState, 'loading')) {
              this.store.dispatch(
                LoadContentAction({
                  orgId: authToken?.orgId,
                  requestId: new Date().getTime(),
                  userId: authToken?.userId,
                }),
              );
            }
          }),
          filter((contentEntityState: ContentEntityState) => _.get(contentEntityState, 'loaded') && !_.get(contentEntityState, 'loading')),
        );
      }),
    );
  }

  getSearchAnalyticsFromContent(filters, paginatedResult): SearchQueryAnalyticsModel {
    const defaultEventProperties = new SearchQueryAnalyticsModel();

    const salesflows = _.get(filters, 'query.salesflow', []).map((currFilter) => currFilter.displayName);
    const tags = _.get(filters, 'query.tags', []).map((currFilter) => currFilter.value);
    const salesflowsStatus = salesflows.length ? 'true' : 'false';

    const emailTemplateDates = _.get(filters, 'memFilter.date_field', []).map((currFilter) => currFilter.displayName)[0];
    const emailTemplateDatePeriod = _.get(filters, 'memFilter.date_range', [{ displayName: 'null' }]).map((currFilter) => currFilter.displayName)[0];
    const emailTemplateOwner = _.get(filters, 'memFilter.owner', []).map((currFilter) => currFilter.displayName);

    const eventProperties = {
      ...defaultEventProperties,
      object: 'Template',
      amount: paginatedResult,
      tags,
      salesflows,
      salesflowsStatus,
      emailTemplateDates,
      emailTemplateDatePeriod,
      emailTemplateOwner,
    };
    return eventProperties;
  }
}
