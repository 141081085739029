import { Inject, Injectable } from '@angular/core';
import { EmailSignatureResponse } from '@zi-core/http-model/response/email-signature.response.model';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { OnBoardingSteps } from '../on-boarding.config';
import { UserConfigurationService } from '../../../core/service/user-configuration.service';
import { UserConfigurationKey } from '../../../core/enums/user-configuration.enum';
import { OnBoardingEntityState, getUnfinishedStepsSelector } from '../ngrx/state/on-boarding.state';
import { UpdateUnfinishedStepsAction } from '../ngrx/action/on-boarding.action';
import { Store } from '@ngrx/store';
import { LoggerServiceToken } from '@zi-core/config/logger-service.config';
import { ILoggerService } from '@zi-core/interface/logger.service.interface';

@Injectable({
  providedIn: 'root',
})
export class OnBoardingService {
  constructor(
    private httpClient: HttpClient,
    private userConfigurationService: UserConfigurationService,
    private onBoardingStore: Store<OnBoardingEntityState>,
    @Inject(LoggerServiceToken) private loggerService: ILoggerService,
  ) {}

  getAllSteps() {
    return OnBoardingSteps.map((elm) => elm.key);
  }

  getMappedSteps(stepsKeys: string[]) {
    return stepsKeys.map((stepKey) => {
      return OnBoardingSteps.find((step) => step.key === stepKey);
    });
  }

  sort(unfinishedSteps) {
    const arr = [];
    OnBoardingSteps.forEach((item) => {
      if (unfinishedSteps.includes(item.key)) {
        arr.push(item.key);
      }
    });
    return arr;
  }

  getOnBoarding() {
    return this.userConfigurationService.getUserConfigByKey(UserConfigurationKey.ON_BOARDING).pipe(
      map((res) => res),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }),
    );
  }

  getDialerConfig() {
    return this.userConfigurationService.getUserConfigByKey(UserConfigurationKey.DIALER_CONFIG).pipe(
      map((res) => res),
      catchError((error: any) => {
        return throwError(error);
      }),
    );
  }

  getSignatureConfig() {
    return this.userConfigurationService.getUserConfigByKey(UserConfigurationKey.SIGNATURE).pipe(
      map((res) => res),
      catchError((error: any) => {
        return throwError(error);
      }),
    );
  }

  upsertOnBoarding(onBoardingObject) {
    return this.httpClient
      .post(`${environment.backend_url}/v1/userconfiguration`, {
        Key: 'onBoarding',
        Value: onBoardingObject,
      })
      .pipe(map((res) => res));
  }

  upsertDialerConfig(dialerConfigObject) {
    return this.httpClient
      .post(`${environment.backend_url}/v1/userconfiguration`, {
        Key: UserConfigurationKey.DIALER_CONFIG,
        Value: dialerConfigObject,
      })
      .pipe(map((res) => res));
  }

  updateSignatureConfig(signatureValue) {
    return this.httpClient
      .post(`${environment.backend_url}/v1/userconfiguration`, {
        Key: UserConfigurationKey.SIGNATURE,
        Value: { signature: signatureValue },
      })
      .pipe(map((res) => res));
  }

  updateUnfinishedSteps(unfinishedSteps) {
    const onBoardingObj = { unfinishedSteps: unfinishedSteps };
    return this.upsertOnBoarding(onBoardingObj);
  }

  addToUnFinishedSteps(currentStep) {
    let unFinishedSteps;
    this.onBoardingStore.select(getUnfinishedStepsSelector).subscribe((unFinishedStepsArray) => {
      unFinishedSteps = unFinishedStepsArray;
    });
    if (!unFinishedSteps.includes(currentStep)) {
      const newUnfinishedList = unFinishedSteps.filter((step) => step);
      newUnfinishedList.push(currentStep);
      const currentStepIndex = OnBoardingSteps.findIndex((item) => item.key === currentStep);
      this.onBoardingStore.dispatch(UpdateUnfinishedStepsAction({ currentStep: currentStepIndex, unfinishedSteps: newUnfinishedList }));
    } else return;
  }

  getEmailSignature(name: string, email: string): Observable<EmailSignatureResponse> {
    return this.httpClient.get<EmailSignatureResponse>(`${environment.backend_url}/v1/account/emailsignature?name=${name}&email=${email}`).pipe(
      map((res) => res),
      catchError((error) => {
        this.loggerService.error('Error retrieving signature from sent emails', error);
        return of({ emailSignature: '' });
      }),
    );
  }
}
