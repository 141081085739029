import { Injectable } from '@angular/core';
import { FeatureFlagService } from '@app/feature-flag/feature-flag.service';
import { Flag } from '@app/feature-flag/types/flag.enum';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { CustomerProfileDto } from '@zi-pages/admin/pages/org-management/sms-registration/sms-registration-form.dto';

@Injectable()
export class TrustedCallingService {
  private isTrustedCallingVisible$: Observable<boolean>;
  private isTrustedCallingRegistered$: Observable<boolean>;

  constructor(private _httpClient: HttpClient, private featureFlagService: FeatureFlagService) {
    this.isTrustedCallingVisible$ = this.featureFlagService.observe<Flag.TRUSTED_CALLING>(Flag.TRUSTED_CALLING);
    this.isTrustedCallingRegistered$ = this.isTrustedCallingVisible$.pipe(
      switchMap((isTrustedCallingVisible) => {
        return of(true);
      }),
    );
  }

  public getIsTrustedCallingVisible(): Observable<boolean> {
    return this.isTrustedCallingVisible$;
  }

  public registerForTrustedCallingService(customerProfile: CustomerProfileDto): Observable<string> {
    return this._httpClient.post<any>(`${environment.backend_url}/v1/trusted_calling`, { customerProfile }).pipe(
      map((res) => {
        return res.sid;
      }),
    );
  }

  public getTrustedCallingRegistrationStatus(): Observable<any> {
    return this._httpClient.get<any>(`${environment.backend_url}/v1/trusted_calling/status`, {}).pipe(
      map((res) => {
        return res;
      }),
    );
  }
}
