import { PipeTransform, Injectable } from '@angular/core';
import { NeverbounceEmailJob, NeverbounceEmailStatus } from '@app/core/data-model/neverbounce-email-status.model';

@Injectable()
export class NeverbounceJobStatusPipe implements PipeTransform {
  transform(nbJobs: NeverbounceEmailJob[], ...args: any[]): NeverbounceEmailStatus {
    let nbStatus: NeverbounceEmailStatus = {
      Valid: 0,
      Unverifiable: 0,
      Unknown: 0,
      Disposable: 0,
      Invalid: 0,
    };

    nbJobs.forEach((nbJob) => {
      nbStatus.Valid += nbJob?.totalValid;
      nbStatus.Unverifiable += nbJob?.totalCatchAll;
      nbStatus.Unknown += nbJob?.totalUnknown;
      nbStatus.Disposable += nbJob?.totalDisposable;
      nbStatus.Invalid += nbJob?.totalInvalid;
    });
    return nbStatus;
  }
}
