import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, UrlSerializer, UrlTree } from '@angular/router';
import { PhoneNumberInfo } from '@app/caller/interface/phone-number-info';
import { setContactDetailsInitialTab } from '@app/pages/contact/ngrx/action/contact-detail-entity.action';
import { ContactFilterState } from '@app/pages/contact/ngrx/state/contact-filter.state';
import { Store } from '@ngrx/store';
import { ErrorDialogComponent } from '@zi-common/component/error-dialog/error-dialog.component';
import { MessageType } from '@zi-common/model/message/message-type';
import { Message } from '@zi-common/model/message/message.model';
import { NotyService } from '@zi-common/service/noty/noty.service';
import { Contact } from '@zi-core/data-model/contact.model';
import { LoginRedirectParams } from '@zi-core/data-model/login-redirect-params.model';
import { Task } from '@zi-core/data-model/task.model';
import { SalesWorkflowTemplate, SalesWorkflowTemplateStep } from '@zi-core/http-model/response/sales-workflow-template.response.model';
import { UrlService } from '@zi-core/service/url.service';
import { ContentService } from '@zi-pages/content/service/content.service';
import { EmailModeService } from '@zi-pages/email-v2/services/email-mode.service';
import { EngageActionTypeV2 } from '@zi-pages/engage-mode-v2/engage-mode-v2.config';
import * as _ from 'lodash';
import { empty, forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { ContactDetailsTab } from '../data-model/contact-details.model';
import { ExtSetProfilePageContactAction } from '@app/extension/ngrx/action/extension.action';
import { BridgeDialerService } from '@app/caller/service/bridge-dialer.service';
import { DialerUtilService } from '@app/caller/service/dialer-util.service';
import { VoipDialerService } from '@app/caller/service/voip-dialer.service';
import { ApplicationState } from '@app/reducers';
import { DialerSelectionSelector } from '@zi-pages/account/ngrx/selector/dialer-config.selector';
import { DialerSelection } from '@zi-core/enums/dialer.enum';
import { getValidAuthToken, isVoipEnabledSelector } from '@zi-core/ngrx/state/auth.state';
import { getExtensionMode } from '@app/extensionMode.config';
import { environment } from '@env/environment';
import { EXTENSION_NAVIGATION_URLS, ExtensionNavigationPageNames } from '@app/extension/constants/redirection-page-names.constant';
import { VoipVoicemailService } from '@app/caller/service/voip-voicemail.service';
import { EmailInvokedPage, AnalyticsSection } from '@zi-core/enums/engage-email';
import { Invitation } from '@zi-core/data-model/invitation.model';
import { blocklistSettingsSelector } from '@app/pages/admin/ngrx/state/blocklist.state';
import { DialerErrorDetails } from '@app/caller/constant/dialer-errors.enum';
import { DialerAnalyticsService } from '@app/caller/service/dialer-analytics.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(
    private _router: Router,
    private urlService: UrlService,
    private _urlSerializer: UrlSerializer,
    protected store: Store<ContactFilterState>,
    private appStore: Store<ApplicationState>,
    private bridgeDialerService: BridgeDialerService,
    private voipDialerService: VoipDialerService,
    private dialerUtilService: DialerUtilService,
    private emailService: EmailModeService,
    private dialog: MatDialog,
    private contentService: ContentService,
    private voipVoicemailService: VoipVoicemailService,
    private notyService: NotyService,
    private dialerAnalyticsService: DialerAnalyticsService,
  ) {}

  private appUrl = '/app';
  private homePage = '/home-page';
  private loginUrl = '/login';
  private engageV2Url = '/app/engageV2';
  private engageUrl = this.engageV2Url;
  private extensionUrl = '/engageext';
  private provisionMfaUrl = '/provisionMfa';
  private verifyOtpUrl = '/verifyOtp';

  navigateToLogin(urlToNavTo?: string, options?: any) {
    let internalWantedRoute;
    if (!urlToNavTo) {
      internalWantedRoute = this._router.url;
    } else {
      internalWantedRoute = urlToNavTo;
    }
    const urlTree: UrlTree = this._urlSerializer.parse(internalWantedRoute);
    internalWantedRoute = internalWantedRoute.split('?')[0];
    const queryParams = urlTree.queryParams;

    const loginQueryParams: LoginRedirectParams = {
      redirectRoute: internalWantedRoute,
      redirectQueryParams: queryParams,
    };
    const encodedQueryParams = this.urlService.encodeToBase64(loginQueryParams);
    this._router.navigate([this.getLoginUrl()], {
      skipLocationChange: _.get(options, 'skipLocationChange', true),
      queryParams: { redirect: encodedQueryParams },
    });
  }

  getCurrentInternalRoute() {
    const urlWithoutHost = window.location.href.split('/#');
    urlWithoutHost.splice(0, 1);
    return decodeURIComponent(urlWithoutHost.join('/#')); // decode the url in case it is encoded by angular router
  }

  getAppUrl(): string {
    return this.appUrl;
  }

  getProvisionMfaUrl(): string {
    return this.provisionMfaUrl;
  }

  getVerifyOtpUrl(): string {
    return this.verifyOtpUrl;
  }

  getEngageUrl(): string {
    return this.engageUrl;
  }

  getEngageV2Url(): string {
    return this.engageV2Url;
  }

  navigateToContactById(contactId: number) {
    this._router.navigateByUrl(this.appUrl + '/contacts?id=' + contactId);
  }

  navigateToContactProfile(contactId: number, activeTab?: ContactDetailsTab) {
    if (activeTab) {
      this.store.dispatch(setContactDetailsInitialTab({ tab: activeTab }));
    }
    this._router.navigateByUrl(this.appUrl + '/contacts/v2/' + contactId);
  }

  navigateContactProfileForExtension(contact, mode) {
    this.store.dispatch(ExtSetProfilePageContactAction({ parsedContacts: [contact], page: mode }));
    this._router.navigate(['/engageext/ext-cont/profile'], { queryParams: { mode } });
  }

  navigateToContacts() {
    this._router.navigateByUrl(this.appUrl + '/contacts/v2');
  }

  navigateToTasks() {
    this._router.navigateByUrl(this.appUrl + '/actions/tasks/v2');
  }

  navigateToTasksHighlightTask(taskId: number) {
    this._router.navigate([this.appUrl + '/actions/tasks/v2'], { queryParams: { taskId } });
  }

  navigateToOverdueTask() {
    this._router.navigate([this.appUrl + '/actions/tasks/v2'], { queryParams: { status: 'Overdue' } });
  }

  navigateToCompanyAccountListViewPage() {
    this._router.navigateByUrl(this.appUrl + '/accounts');
  }

  navigateToCompanyAccountProfile(companyAccountId: number) {
    this._router.navigateByUrl(`${this.appUrl}/accounts/${companyAccountId}`);
  }

  getLoginUrl(): string {
    return this.loginUrl;
  }

  getNavigationIdForSingleContact(contactId: number) {
    return `/#/${this.appUrl}/contacts/v2/${contactId}`;
  }

  getNavigationIdForCompanyAccount(companyAccountId: number) {
    return `/#/${this.appUrl}/accounts/${companyAccountId}`;
  }
  navigateToImportedContacts(query) {
    this._router.navigateByUrl(this.appUrl + '/contacts/v2', { skipLocationChange: true }).then((isDone) => {
      if (isDone) {
        this._router.navigateByUrl(this.appUrl + `/contacts/v2?query=${query}`);
      }
    });
  }

  navigateToSettings() {
    if (getExtensionMode()) {
      window.open(`${environment.app_domain}/#/${EXTENSION_NAVIGATION_URLS[ExtensionNavigationPageNames.INTEGRATION][0]}`, '_blank');
    } else {
      this._router.navigateByUrl(`${this.appUrl}/user-name/my-account/v2/settings`);
    }
  }

  navigateToCallHistory() {
    if (getExtensionMode()) {
      window.open(`${environment.app_domain}/#${EXTENSION_NAVIGATION_URLS[ExtensionNavigationPageNames.ANALYTICS][0]}`, '_blank');
    } else {
      this._router.navigateByUrl(`${this.appUrl}/analytics/v2/call-logs`);
    }
  }

  navigateToSalesflow(salesflowId: number) {
    if (salesflowId) {
      this._router.navigate([`${this.appUrl}/content/salesflow/v2/template/${salesflowId}`]);
    }
  }

  public navigateToHomePage() {
    this._router.navigate([`${this.appUrl}${this.homePage}`]);
  }

  navigateToDoziLogin(returnPath: string = this._router.url) {
    if (getExtensionMode()) {
      window.location.replace(`${environment.zoominfo_login_url}/?isReachout=true&isEngageExt=true`);
    } else {
      window.location.replace(`${environment.zoominfo_login_url}/?redirect=${window.location.origin}/${returnPath}`);
    }
  }

  goToStandaloneModeForContact(
    contact: Contact,
    actionType: EngageActionTypeV2,
    navigateToProfile = true,
    phoneNumberInfo: PhoneNumberInfo = null,
    taskId?: number,
    emailReply?: Invitation,
    analyticsSection?: AnalyticsSection,
    templateId?,
  ) {
    this.appStore
      .select(blocklistSettingsSelector(_.get(contact, 'email')))
      .pipe(take(1))
      .subscribe((blockListSettings) => {
        if (actionType === EngageActionTypeV2.Email) {
          if (contact.doNotEmail) {
            this.openUnsubscribedErrorDialog('Emails');
            return;
          }
          if (_.get(blockListSettings, 'blockEmail')) {
            this.openBlocklistedErrorDialog('Emails');
            return;
          }
          if (templateId && templateId > 0) {
            this.contentService
              .get(templateId)
              .pipe(
                map((response) => {
                  if (navigateToProfile) {
                    this.navigateToContactProfile(contact.id);
                  }
                  this.emailService.initializeNewEmail([contact], {
                    taskId,
                    templateId,
                    templateInput: _.get(response, 'templates[0]'),
                  });
                }),
                catchError(() => {
                  this.notyService.postMessage(new Message(MessageType.ERROR, `There was an error retrieving template details. Please try again later.`));
                  return empty();
                }),
              )
              .subscribe();
          } else {
            if (navigateToProfile) {
              this.navigateToContactProfile(contact.id);
            }
            this.emailService.initializeNewEmail([contact], {
              taskId,
            });
          }
        } else if (actionType === EngageActionTypeV2.Phone) {
          if (_.get(contact, 'doNotCall')) {
            this.dialerAnalyticsService.sendDialerErrorAnalyticsEvent(DialerErrorDetails.UNSUBSCRIBED_FROM_CALLS);
            this.openUnsubscribedErrorDialog('Calls');
            return;
          }
          if (_.get(blockListSettings, 'blockCall')) {
            this.dialerAnalyticsService.sendDialerErrorAnalyticsEvent(DialerErrorDetails.BLOCK_LISTED_CONTACT);
            this.openBlocklistedErrorDialog('Calls');
            return;
          }
          if (navigateToProfile) {
            this.navigateToContactProfile(_.get(contact, 'id', 0));
          }
          this.startCall(contact, phoneNumberInfo, taskId);
        } else if (actionType === EngageActionTypeV2.EmailReply) {
          this.composeStandAloneReplyForContact(contact, emailReply, true, AnalyticsSection.Action_Menu);
        }
      });
  }

  composeStandAloneReplyForContact(contact: Contact, email: Invitation, navigateToProfile = true, analyticsSection?: AnalyticsSection) {
    if (contact.doNotEmail) {
      this.openUnsubscribedErrorDialog('Emails');
      return;
    }
    if (navigateToProfile) {
      this.navigateToContactProfile(_.get(contact, 'id', 0), ContactDetailsTab.Emails);
    }
    this.emailService.initializeEmailReply([contact], email, EmailInvokedPage.STANDALONE, analyticsSection);
  }

  goToStandAloneModeForTask(task: Task, panelToOpen?: EngageActionTypeV2, phoneNumberInfo?: PhoneNumberInfo) {
    const contactData = _.get(task, 'contact');
    const templateId = this.getEmailTemplateIdFromTask(task);
    const actionType = panelToOpen ? panelToOpen : _.get(task, 'type');
    if (actionType === EngageActionTypeV2.Email) {
      if (contactData.doNotEmail) {
        this.openUnsubscribedErrorDialog('Emails');
        return;
      }
      if (templateId && templateId > 0) {
        this.contentService
          .get(templateId)
          .pipe(
            map((response) => {
              this.navigateToContactProfile(contactData.id);
              this.emailService.initializeNewEmail([contactData], {
                taskId: task.id,
                templateId,
                templateInput: _.get(response, 'templates[0]'),
              });
            }),
            catchError(() => {
              this.notyService.postMessage(new Message(MessageType.ERROR, `There was an error retrieving template details. Please try again later.`));
              return empty();
            }),
          )
          .subscribe();
      } else {
        this.navigateToContactProfile(contactData.id);
        this.emailService.initializeNewEmail([contactData], {
          taskId: task.id,
        });
      }
    } else if (actionType === EngageActionTypeV2.Phone || actionType === 'Call') {
      if (contactData.doNotCall) {
        this.openUnsubscribedErrorDialog('Calls');
        return;
      }
      this.navigateToContactProfile(contactData.id);
      this.startCall(contactData, phoneNumberInfo, task.id);
    }
  }

  startCall(contact: Contact, phoneNumberInfo: PhoneNumberInfo, taskId?: number) {
    let phoneInfo: PhoneNumberInfo = phoneNumberInfo;
    if (!phoneInfo) {
      phoneInfo = this.dialerUtilService.pickFirstNumberToUse(contact);
    }

    const dialerSelectionSelector$ = this.appStore.select(DialerSelectionSelector).pipe(
      take(1),
      map((dialerSelection) => dialerSelection),
    );
    const voipEnabledSelector$ = this.appStore.select(isVoipEnabledSelector).pipe(
      take(1),
      map((isVoipEnabled) => isVoipEnabled),
    );

    forkJoin([dialerSelectionSelector$, voipEnabledSelector$])
      .pipe(
        mergeMap(([dialerSelection, isVoipEnabled]) => {
          if (this.dialerUtilService.checkIsVoip(dialerSelection, isVoipEnabled)) {
            return this.appStore.select(getValidAuthToken).pipe(
              take(1),
              map((authToken) => {
                this.voipDialerService.startProspectCallWithStandalone(
                  {
                    id: _.get(contact, 'id', 0),
                    name: _.get(contact, 'name', null),
                    email: _.get(contact, 'email', null),
                    zoomId: _.get(contact, 'zoomId', null),
                    phoneNumberDialed: phoneInfo.number,
                  },
                  phoneInfo.type,
                  authToken.userId,
                  taskId,
                );
              }),
            );
          } else if (this.dialerUtilService.checkIsBridge(dialerSelection, isVoipEnabled)) {
            return this.bridgeDialerService
              .startNewCallWithContactStandalone$(
                {
                  id: contact.id,
                  name: contact.name,
                  phoneNumberDialed: phoneInfo.number,
                },
                phoneInfo.type,
                taskId,
              )
              .pipe(take(1));
          } else if (dialerSelection === DialerSelection.NONE) {
            this.dialerAnalyticsService.sendDialerErrorAnalyticsEvent(DialerErrorDetails.DIALER_SELECTION_ERROR);
            this.dialerUtilService.emitOpenNoDialerSelectionPanel(true);
            return of({});
          } else {
            return of({});
          }
        }),
      )
      .subscribe();
  }

  getEmailTemplateIdFromTask(task: Task): number {
    let emailTemplateId: number;
    const salesWorkflowTemplate: SalesWorkflowTemplate = _.get(task, 'salesWorkflowTemplate', null);
    if (task.salesWorkflowId && salesWorkflowTemplate) {
      const steps = _.get(salesWorkflowTemplate, 'steps', []);
      const index: number = steps.findIndex((step: SalesWorkflowTemplateStep) => step.id === task.salesWorkflowTemplateStepId);
      emailTemplateId = _.get(steps, `[${index}].messageTemplateId`);
    }
    return emailTemplateId;
  }

  checkCallStatus(action) {
    const status =
      this.bridgeDialerService.checkIfActiveCallOrSession() || this.voipDialerService.hasOpenCallPanel() || this.voipVoicemailService.isVoicemailRecActive();
    if (status) {
      this.displayCallInProgressErrorDialog(action);
    }
    return status;
  }

  displayCallInProgressErrorDialog(action) {
    return this.dialog.open(ErrorDialogComponent, {
      width: '400px',
      data: {
        title: 'Call in progress',
        content: `Please end your call session before ${action}`,
        secondaryBoltonDisabled: true,
        primaryButtonText: 'OK',
      },
    });
  }

  openUnsubscribedErrorDialog(action: string) {
    this.dialog.open(ErrorDialogComponent, {
      width: '400px',
      data: {
        title: 'Contact is Unsubscribed',
        content: `Contact has unsubscribed from receiving ${action}`,
        secondaryBoltonDisabled: true,
        primaryButtonText: 'OK',
      },
    });
  }

  openBlocklistedErrorDialog(action: string) {
    this.dialog.open(ErrorDialogComponent, {
      width: '400px',
      data: {
        title: 'Alert',
        content: `Selected contact is associated to a blocklist`,
        secondaryBoltonDisabled: true,
        primaryButtonText: 'OK',
      },
    });
  }
}
