<form>
  <app-dialog-template [dialogTitle]="dialogTitle" (close)="close()" [automationId]="'app-dialog-template'">
    <app-dialog-content [automationId]="'app-dialog-content'">
      <div class="preview-email-loading-container" *ngIf="isLoading" [automationId]="'preview-email-loading-container'">
        <app-loader></app-loader>
      </div>
      <div class="preview-content" *ngIf="!isLoading" [automationId]="'preview-content'">
        <div *ngIf="template; else injectedTemplate">
          <div *ngIf="contactsName" class="recipient" [automationId]="'recipient'">To: {{contactsName}}</div>
          <div class="preview-subject" [automationId]="'preview-subject'">{{template?.subject}}</div>
          <div class="preview-message" [innerHTML]="templateContent | safeHtml" [automationId]="'preview-message'"></div>
        </div>
        <ng-template #injectedTemplate>
          <div *ngIf="contactsName" class="recipient" [automationId]="'recipient'">To: {{contactsName}}</div>
          <div class="preview-subject" [automationId]="'preview-subject'">{{dialogSubject}}</div>
          <div class="preview-message" [innerHTML]="templatebody | safeHtml" [automationId]="'preview-message'"></div>
        </ng-template>
      </div>
      <div *ngIf="attachments.length > 0">
        <div class="preview-separator-only"></div>
        <app-attachments-container
          [attachments]="attachments"
          [adjustAttachmentAlignment]="true"
          [automationId]="'app-attachments-container'"
        ></app-attachments-container>
      </div>
    </app-dialog-content>
    <app-dialog-footer>
      <app-secondary-btn [automationId]="'closeButtonText'" [text]="'Close'" (clickButton)="close()"></app-secondary-btn>
    </app-dialog-footer>
  </app-dialog-template>
</form>
