export enum ImportsPageModules {
  CONTACTS = 'contacts',
  ACCOUNTS = 'accounts',
}

export enum ImportsPageTabIds {
  CONTACTS = 0,
  ACCOUNTS = 1,
}

export enum ImportsPageRouteLinks {
  CONTACTS = 'contacts',
  ACCOUNTS = 'accounts',
}

export const ZI_COMPANY_LIST = 'SalesOS Company List';
export const ZI_SAVED_SEARCH = 'SalesOS Saved Search';
export const ZI_LIST = 'SalesOS List';
export const EXCEL_FILE = 'Excel File';
export const SF_ACCOUNTS_LIST = 'Salesforce Account List';
export const SF_LIST = 'Salesforce List';
export const DYN_ACCOUNTS_LIST = 'Dynamics Account List';
export const DYN_LIST = 'Dynamics List';
export const OPT_OUT = 'Opt Out ';
export const MOST_ENGAGED_PERSONA = 'Most Engaged';

export enum ImportSortOrder {
  ASC = 0,
  DESC = 1,
}

export enum ImportSortField {
  DATE = 'DATE',
  NAME = 'NAME',
  NUMBER_OF_CONTACTS = 'NUMBER_OF_CONTACTS',
  NUMBER_OF_ACCOUNTS = 'NUMBER_OF_ACCOUNTS',
  IMPORT_TYPE = 'IMPORT_TYPE',
  IMPORT_OWNER = 'IMPORT_OWNER',
}

export enum ImportPages {
  CONTACTS = 'import-history-contacts',
  ACCOUNTS = 'import-history-accounts',
}
