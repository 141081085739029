import { CompanyAccountDetailKey } from '@zi-core/enums/company-account.enum';
import {
  CompanyAccountActivitiesSummary,
  CompanyAccountMeetingsSummary,
  CompanyAccountOpportunitiesSummary,
} from '@zi-core/http-model/response/company-accounts.response.model';
import { EventCustomModel } from '@zi-core/data-model/dozi-integration/event-custom.model';
import { DataLoadingState } from '@zi-common/interface/ngrx-state';
import { CompanyAccount } from '@zi-core/data-model/account.model';
import { Opportunity } from '@zi-core/data-model/opportunity.model';
import { Task } from '@app/core/data-model/task.model';

export interface CompanyAccountDetailsEntitiesModel {
  [id: string]: CompanyAccountDetails;
}

/**
 * Model mapping company account detail to its data
 */
export interface CompanyAccountDetails {
  [CompanyAccountDetailKey.ACTIVITIES]: CompanyAccountActivitiesState;
  [CompanyAccountDetailKey.WEBSITE_VISITS]: CompanyAccountDetailsWebsiteVisits;
  [CompanyAccountDetailKey.HEALTH_SCORE]: CompanyAccountDetailsHealthScore;
  [CompanyAccountDetailKey.OPPORTUNITIES]: CompanyAccountDetailsOpportunities;
  [CompanyAccountDetailKey.ACCOUNT]: CompanyAccountDetailsAccount;
  [CompanyAccountDetailKey.ENGAGEMENT_SUMMARY]: CompanyAccountActivitiesEngagementSummaryState;
  [CompanyAccountDetailKey.TASKS]: CompanyAccountDetailUpcomingTaskState;
}

export interface CompanyAccountDetailsAccount extends DataLoadingState {
  account: CompanyAccount;
}

export interface CompanyAccountDetailsWebsiteVisits extends DataLoadingState {
  websiteVisits: number;
}

export interface CompanyAccountDetailsHealthScore extends DataLoadingState {
  healthScore: number;
}

export interface CompanyAccountDetailsOpportunities extends DataLoadingState {
  opportunities: Array<Opportunity>;
}

export interface CompanyAccountActivitiesState extends DataLoadingState {
  entities: Array<EventCustomModel>;
  paginationCookie: string;
  uiFilter: CompanyAccountDetailsUiFilter;
}

export interface CompanyAccountDetailsUiFilter {
  // any type to support all kind of filter arrays
  [filterType: string]: Array<any>;
}

export interface CompanyAccountDetailsUiFilterActionPayload {
  detailKey: CompanyAccountDetailKey;
  filters: CompanyAccountDetailsUiFilter;
  companyAccountId: number;
}

export interface CompanyAccountActivitiesEngagementSummaryState extends DataLoadingState {
  activitiesSummary: CompanyAccountActivitiesSummary;
  meetingsSummary: CompanyAccountMeetingsSummary;
  opportunitiesSummary: CompanyAccountOpportunitiesSummary;
}

export enum CompanyAccountFilter {
  CompanyAccountId = 'company_account_id',
}

export interface CompanyAccountDetailUpcomingTaskState extends DataLoadingState {
  tasks: Task[];
}
