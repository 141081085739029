<button
  class="secondary-btn"
  (click)="onClick($event)"
  [type]="buttonType"
  [disabled]="disabled"
  [ngClass]="{ 'disabled': disabled, 'withIcon': iconSrc, 'flex-elements':isRightButtonEnabled }"
>
  <i *ngIf="iconSrc" [class]="'icon ' + iconSrc"></i>
  <span>{{text}}</span>
  <i *ngIf="isRightButtonEnabled" class="icon-arrow_right"></i>
</button>
