import { createAction, props } from '@ngrx/store';

export const StartSfLiveAgentInitAction = createAction('[SfLiveAgent] Start initialization');

export const SfLiveAgentInitSuccessAction = createAction('[SfLiveAgent] Initialization successfully completed');

export const SfLiveAgentInitFailureAction = createAction('[SfLiveAgent] Initialization unsuccessful', props<{ error: any }>());

export const SfLiveAgentDisconnectAction = createAction('[SfLiveAgent] Start Disconnect');

export const SfLiveAgentDisconnectSuccessAction = createAction('[SfLiveAgent] Disconnected');
