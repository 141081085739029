import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { DoziScoopsResponse } from '@zi-core/http-model/response/dozi-scoops.response.model';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DoziConnectionResponse } from '../data-model/dozi-integration/dozi-connect-response.model';
import { DoziJWTResponse } from '../data-model/dozi-integration/dozi-jwt-response.model';
import { DoziUser } from '../data-model/dozi-integration/dozi-user.model';
import { MfaRequest } from '../data-model/dozi-integration/mfa-request.model';
import { MfaResendRequest } from '../data-model/dozi-integration/mfa-resend-request.model';
import { MfaResponse } from '../data-model/dozi-integration/mfa-response.model';
import { User } from '../data-model/user.model';
import { DoziTrialResponse } from '../data-model/dozi-integration/dozi-trial-response.model';
import { ContactUsResponse } from '../data-model/dozi-integration/dozi-contact-us-response.model';
import { ContactUsRequest } from '../data-model/dozi-integration/dozi-contact-us-request.model';
import { DoziFeature } from '../data-model/dozi-integration/enums/dozi-feature.model';
import { ContactUsLocation } from '../data-model/dozi-integration/enums/contact-us-location.model';
import * as _ from 'lodash';
import { PersonExportRequest } from '../data-model/dozi-integration/person-export-request.model';
import { PersonExportAndCreditResponse } from '../data-model/dozi-integration/person-export-response.model';
import { WebsiteDomainResponse } from '@zi-core/http-model/response/website-domain-response.model';
import { UserLicensesResponseModel } from '@zi-core/data-model/dozi-integration/response/user-licenses.response.model';
import { UserLicensesRequestModel } from '@zi-core/data-model/dozi-integration/request/user-licenses.request.model';
import { EngageProduct } from '@zi-core/enums/engage-product.enum';

@Injectable({
  providedIn: 'root',
})
export class DoziIntegrationService {
  readonly doziApiUrl = `${environment.engage_backend_url}`;

  readonly OAUTH_PROVIDER = {
    GOOGLE: 'google',
    OFFICE365: 'azure',
  };

  constructor(private httpClient: HttpClient) {}

  loginSSO(): Observable<MfaResponse> {
    return this.httpClient.post<MfaResponse>(`${this.doziApiUrl}/auth/loginSSO`, {});
  }

  login(user: User): Observable<DoziJWTResponse> {
    return this.httpClient.post<DoziJWTResponse>(`${this.doziApiUrl}/auth/login`, user).pipe(map((res: DoziJWTResponse) => new DoziJWTResponse(res)));
  }

  verifyMfa(req: MfaRequest): Observable<MfaResponse> {
    return this.httpClient.post<MfaResponse>(`${this.doziApiUrl}/auth/verifyMfa`, req);
  }

  resendMfa(req: MfaResendRequest): Observable<any> {
    return this.httpClient.post<MfaResponse>(`${this.doziApiUrl}/auth/resendMfa`, req);
  }

  getUser(): Observable<DoziUser> {
    return this.httpClient.get<DoziUser>(`${this.doziApiUrl}/user`).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        return throwError(error.data);
      }),
    );
  }

  checkIfConnectedService(): Observable<DoziConnectionResponse> {
    return this.httpClient.get<DoziConnectionResponse>(`${this.doziApiUrl}/auth/connected`);
  }

  disconnectService(): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.doziApiUrl}/auth/disconnect`, null);
  }

  retrieveScoops(companyId: number): Observable<DoziScoopsResponse> {
    return this.httpClient.get<DoziScoopsResponse>(`${this.doziApiUrl}/data/scoop/${companyId}`);
  }

  retrieveOAuthVerification(providerId: string, redirectUrl: string): Observable<any> {
    if (!redirectUrl) {
      redirectUrl = window.location.href;
    }

    return this.httpClient.get(`${this.doziApiUrl}/auth/oauth2/${this.OAUTH_PROVIDER[providerId]}/redirectUrl?url=${encodeURIComponent(redirectUrl)}`);
  }

  checkTrialStatus(): Observable<DoziTrialResponse> {
    return this.httpClient.post<DoziTrialResponse>(`${this.doziApiUrl}/trial/apply`, null);
  }

  sendContactUs(req: ContactUsRequest): Observable<ContactUsResponse> {
    return this.httpClient.post<ContactUsResponse>(`${this.doziApiUrl}/contact-us`, req);
  }

  getCredits() {
    return this.httpClient.get(`${this.doziApiUrl}/user/credits`).pipe(
      map((res) => {
        return res;
      }),
      catchError((error) => {
        throw error.data;
      }),
    );
  }

  export(doziContactId: number): Observable<PersonExportAndCreditResponse> {
    return this.httpClient.post<PersonExportAndCreditResponse>(`${this.doziApiUrl}/data/person/export/${doziContactId}`, null).pipe(
      map((res) => {
        return this.toCamelCase(res);
      }),
      catchError((error) => {
        throw error.error;
      }),
    );
  }

  exportBulk(req: PersonExportRequest): Observable<PersonExportAndCreditResponse> {
    return this.httpClient.post<PersonExportAndCreditResponse>(`${this.doziApiUrl}/data/person/export`, req).pipe(
      map((res) => {
        return this.toCamelCase(res);
      }),
      catchError((error) => {
        throw error.error;
      }),
    );
  }

  sendContactusRequest(req: ContactUsRequest): Observable<ContactUsResponse> {
    return this.sendContactUs(req).pipe(
      map(
        (res) => {
          return res;
        },
        (error) => {},
      ),
    );
  }

  goToPage(url: string) {
    window.location.href = url;
  }

  /**
   * For mapping to backend model
   */
  getContactUsSource(doziFeature: DoziFeature): ContactUsLocation {
    switch (doziFeature) {
      case DoziFeature.orgCharts:
        return ContactUsLocation.ORG_CHART;
      case DoziFeature.news:
        return ContactUsLocation.NEWS;
      case DoziFeature.scoops:
        return ContactUsLocation.SCOOP;
      case DoziFeature.scoopsNews:
        return ContactUsLocation.NEWS_AND_SCOOP;
      case DoziFeature.technologies:
        return ContactUsLocation.TECH;
      case DoziFeature.attributes:
        return ContactUsLocation.COMPANY_ATTRIBUTES;
      case DoziFeature.technologiesAttributes:
        return ContactUsLocation.TECH_ATTRIBUTE;
      case DoziFeature.replace:
        return ContactUsLocation.REPLACE;
      default:
        return ContactUsLocation.NONE;
    }
  }

  /**
   * convert from pascal case to camelcase
   */
  toCamelCase(json: any): any {
    return this.convertCase(json, (key: string) => _.camelCase(key));
  }

  /**
   * generic method to convert from camelcase to pascal and vice versa
   */
  convertCase(json: any, keyMappingFn: (key: string) => string) {
    if (!_.isPlainObject(json) && !_.isArray(json)) {
      return json;
    }
    // if array loop through and convert
    if (_.isArray(json)) {
      return _.map(json, (item) => this.convertCase(item, keyMappingFn));
    }
    // if object
    return _.transform(
      json,
      (result, value, key) => {
        result[keyMappingFn(key.toString())] = this.convertCase(value, keyMappingFn);
      },
      {},
    );
  }

  GetWebsiteDomainList(): Observable<WebsiteDomainResponse> {
    return this.httpClient.get<WebsiteDomainResponse>(`${this.doziApiUrl}/websights/zoominfo-websights/websites`);
  }

  /**
   * Get user licenses based on zoomCustSalesforceId
   */
  getUserLicenses(payload: UserLicensesRequestModel): Observable<UserLicensesResponseModel> {
    const params = new HttpParams().set('zoomCustSalesforceId', _.get(payload, 'zoomCustSalesforceId'));
    return this.httpClient.get<UserLicensesResponseModel>(`${this.doziApiUrl}/auth/user/recruiter-licenses`, { params });
  }

  /**
   * Update user licenses in dozi-connector (for demo org only)
   */
  updateUserLicenses(product: EngageProduct): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.doziApiUrl}/auth/product-switcher?product=${product}`, {});
  }
}
