import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SetManualLogCallInfo } from '@app/caller/actions/call-log.actions';
import { BridgeDialerService } from '@app/caller/service/bridge-dialer.service';
import { VoipDialerService } from '@app/caller/service/voip-dialer.service';
import { getManualLogCallDialogOpen } from '@app/caller/state/call-log.state';
import { EngageSettingsDialogComponent } from '@app/common/component/engage-settings-dialog/engage-settings-dialog.component';
import { CheckboxOptions, DotsOption } from '@app/common/interface/dots-option';
import { ContactCreateMode } from '@app/common/pages-component/contact-create/contact-create-config';
import { ContactCreateComponent } from '@app/common/pages-component/contact-create/contact-create.component';
import { SystemName } from '@app/common/pages-component/upload-contacts/file-mapping-dialog/file-mapping.config';
import { SafeValuePipe } from '@app/common/pipe/safe-value/safe-value.pipe';
import { NotyService } from '@app/common/service/noty/noty.service';
import { EngageVersionEnum } from '@app/core/enums/engage-version.enum';
import { ListSource } from '@app/core/enums/import-list.enum';
import { ResourceType } from '@app/core/enums/resource-type.enum';
import { UrlService } from '@app/core/service/url.service';
import { UpdateCompanyAccountDetailContactAction } from '@app/pages/company-accounts/ngrx/action/company-account-detail-contacts-entity.action';
import { CompanyAccountDetailContactEntityState } from '@app/pages/company-accounts/ngrx/state/company-account-detail-contact-entity.state';
import { GetContactAction, UpdateContactAction } from '@app/pages/contact/ngrx/action/contact-entity.action';
import { ContactEntityState } from '@app/pages/contact/ngrx/state/contact-entity.state';
import { ApplicationState } from '@app/reducers';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { ErrorDialogComponent } from '@zi-common/component/error-dialog/error-dialog.component';
import { ContactMoreOptionName } from '@zi-common/model/contact-options.enum';
import { MessageType } from '@zi-common/model/message/message-type';
import { Message } from '@zi-common/model/message/message.model';
import { ContactAssociateToAccountDialogComponent } from '@zi-common/pages-component/contact-associate-to-account-dialog/contact-associate-to-account-dialog.component';
import { CompanyService } from '@zi-common/service/company-service/company.service';
import { CompanyAccountContact } from '@zi-core/data-model/company-account-contact.model';
import { Contact } from '@zi-core/data-model/contact.model';
import { IntegrationNamesMap } from '@zi-core/enums/integration-names-map.enum';
import { ContactCrmInfo, ContactDto } from '@zi-core/http-model/response/contacts.response.model';
import { getExclusiveUserRole, isConnectedToService } from '@zi-core/ngrx/state/auth.state';
import { IntegrationService } from '@zi-core/service/integration.service';
import { NavigationService } from '@zi-core/service/navigation.service';
import { ContactInternalDataService } from '@zi-pages/contact/service/contact-internal-data.service';
import { EngageActionTypeV2 } from '@zi-pages/engage-mode-v2/engage-mode-v2.config';
import * as _ from 'lodash';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { DateManagementService } from '../date-management/date-management.service';
import { getExtensionMode } from '@app/extensionMode.config';
import { throwError } from 'rxjs';
import { SharpyErrorTypesEnum } from '@app/common/model/sharpy-error-types.enum';

export interface ViewInRecruiterObj {
  companyId: number | null;
  orgChart: {
    isTreeView: boolean;
    filters: any;
  };
}

@Injectable()
export class ContactsServiceService {
  DEFAULT_TWITTER_URL = 'https://twitter.com/';
  DEFAULT_LINKEDIN_URL = 'https://www.linkedin.com/';
  private recruiter_app = environment.recruiter_app;
  private recruiter_app_search = `${this.recruiter_app}/candidates`;
  engageIndicatorOptions: CheckboxOptions[] = [
    {
      checked: false,
      type: SystemName.doNotEmail,
      displayText: 'Emails',
      disabled: false,
    },
    {
      checked: false,
      type: SystemName.doNotCall,
      displayText: 'Calls',
      disabled: false,
    },
    {
      checked: false,
      type: SystemName.doNotSms,
      displayText: 'SMS',
      disabled: false,
    },
  ];

  constructor(
    private contactInternalDataService: ContactInternalDataService,

    private dialog: MatDialog,
    private dateManagement: DateManagementService,
    private integrationService: IntegrationService,
    private store: Store<ApplicationState>,
    private navigationService: NavigationService,
    private contactEntityStore: Store<ContactEntityState>,
    private companyService: CompanyService,
    private companyAccountDetailContactEntityStore: Store<CompanyAccountDetailContactEntityState>,
    private entityDialerService: BridgeDialerService,
    private voipDialerService: VoipDialerService,
    private notyService: NotyService,
    private urlService: UrlService,
    private safeValue: SafeValuePipe,
  ) {}

  openLinkedInSearch(contact: Contact) {
    this.contactInternalDataService
      .getContactById(contact.id)
      .pipe(
        map((contactData) => {
          this.openLinkedByContact(contactData);
        }),
        catchError((error) => {
          let errorMsg = 'System Error';
          if (error?.status === 403 && error?.error?.ResponseStatus?.ErrorCode === SharpyErrorTypesEnum.FORBIDDEN) {
            errorMsg = error?.error?.ResponseStatus.Message;
          }
          this.notyService.postMessage(new Message(MessageType.ERROR, `Cannot search contact in LinkedIn: ${errorMsg}`));
          return throwError(error);
        }),
      )
      .subscribe();
  }

  openCRMRecord(contact: Contact, crmName: IntegrationNamesMap, contactSynced: boolean) {
    this.store
      .select(isConnectedToService(crmName))
      .pipe(
        take(1),
        switchMap((isConnectedToCRM) => {
          if (isConnectedToCRM) {
            if (contactSynced) {
              return this.contactInternalDataService.getContactById(contact.id).pipe(
                catchError((error) => {
                  if (error?.status === 403 && error?.error?.ResponseStatus.ErrorCode === SharpyErrorTypesEnum.FORBIDDEN) {
                    const errorMessage = `There was an error getting the CRM Record of the selected contact: ${error?.error?.ResponseStatus.Message}.`;
                    this.notyService.postMessage(new Message(MessageType.ERROR, errorMessage));
                  }
                  return throwError(error);
                }),
              );
            }
          } else {
            this.notConnectedToCRMDialog(crmName);
          }
        }),
        map((contactData) => {
          const searchString = _.get(contactData.crmInfo as ContactCrmInfo, 'entityUrl', '');
          window.open(searchString, '_blank');
        }),
      )
      .subscribe();
  }

  notConnectedToCRMDialog(crmName: IntegrationNamesMap) {
    this.integrationService.notConnectedToCRMDialog(crmName).subscribe((toSettings) => {
      if (toSettings) {
        this.navigationService.navigateToSettings();
      }
    });
  }

  openZoominfoProfile(contact: Contact) {
    this.store
      .select(getExclusiveUserRole)
      .pipe(take(1))
      .subscribe((userRole: EngageVersionEnum) => {
        const zoominfoProfileId = _.get(contact, 'zoomId');
        switch (userRole) {
          case EngageVersionEnum.RECRUITER:
            const recruiterRedirectUrl = zoominfoProfileId ? `${this.recruiter_app_search}/${zoominfoProfileId}/experience` : this.recruiter_app_search;
            window.open(recruiterRedirectUrl, '_blank');
            break;
          default:
            const zoominfoRedirectUrl = zoominfoProfileId
              ? `${environment.zoominfo_app}/#/apps/profile/person/${zoominfoProfileId}`
              : environment.zoominfo_app_search;
            window.open(zoominfoRedirectUrl, '_blank');
            break;
        }
      });
  }

  openZoominfoCompany(contact: Contact) {
    this.store
      .select(getExclusiveUserRole)
      .pipe(take(1))
      .subscribe((userRole: EngageVersionEnum) => {
        const zoominfoCompanyId = _.get(contact, 'zoomCompanyId');
        switch (userRole) {
          case EngageVersionEnum.RECRUITER:
            if (zoominfoCompanyId) {
              const recruiterObj: ViewInRecruiterObj = { companyId: zoominfoCompanyId, orgChart: { isTreeView: false, filters: {} } };
              const recruiterObjToBase64 = this.urlService.encodeToBase64(recruiterObj);
              const companyUrl = `${environment.recruiter_app}/company/${zoominfoCompanyId}/orgChart?company=${recruiterObjToBase64}`;
              window.open(companyUrl, '_blank');
            } else {
              const recruiterWebUrl = `${this.recruiter_app_search}`;
              window.open(recruiterWebUrl, '_blank');
            }
            break;
          default:
            if (zoominfoCompanyId) {
              const companyUrl = `${environment.zoominfo_app}/#/apps/profile/company/${zoominfoCompanyId}`;
              window.open(companyUrl, '_blank');
            } else {
              const zoomWebUrl = environment.zoominfo_app_search;
              window.open(zoomWebUrl, '_blank');
            }
            break;
        }
      });
  }

  allContactsUnsubscribed(contacts: ContactDto[], actionType?: EngageActionTypeV2) {
    if (contacts.length === 0) {
      return false;
    }
    const action = actionType || EngageActionTypeV2.Email;
    const contactsUnsubscribed =
      action === EngageActionTypeV2.Email ? contacts.filter((contact) => contact.doNotEmail) : contacts.filter((contact) => contact.doNotCall);
    const areAllUnsubscribed = contactsUnsubscribed.length === contacts.length;
    if (areAllUnsubscribed) {
      this.dialog.open(ErrorDialogComponent, {
        width: getExtensionMode() ? '300px' : '400px',
        data: {
          title: 'Alert',
          content:
            (contactsUnsubscribed.length > 1 ? 'These contacts have' : 'This contact has') +
            ' unsubscribed from receiving' +
            (actionType === EngageActionTypeV2.Email ? ' Emails' : ' Calls'),
          secondaryBoltonDisabled: true,
        },
      });
    }
    return areAllUnsubscribed;
  }

  allEngageModeContactUnsubscribed(contacts: Array<Contact>, actionType: EngageActionTypeV2) {
    const totalContactsCount = contacts.length;
    if (totalContactsCount === 0) {
      return false;
    }

    const unsubscribedContactsCount =
      actionType === EngageActionTypeV2.Email
        ? contacts.filter((contact) => contact.doNotEmail).length
        : contacts.filter((contact) => contact.doNotCall).length;

    if (unsubscribedContactsCount === totalContactsCount) {
      this.dialog.open(ErrorDialogComponent, {
        width: '400px',
        data: {
          title: 'Alert',
          content:
            (unsubscribedContactsCount > 1 ? 'These contacts have' : 'This contact has') +
            ' unsubscribed from receiving' +
            (actionType === EngageActionTypeV2.Email ? ' Emails' : ' Calls'),
          secondaryBoltonDisabled: true,
        },
      });
      return true;
    }
    return false;
  }
  openLinkedByContactFromImport(contact, source) {
    let parametersStr = '';
    if (source === ListSource.DOZI) {
      const fullName = _.get(contact, 'name', '');
      const company = _.get(contact, 'companyName', '');
      parametersStr = `${fullName} ${company}`;
    }
    if (source === ListSource.SALESFORCE) {
      const fullName = _.get(contact, 'Name', '');
      const company = _.get(contact, 'Company', '');
      parametersStr = `${fullName} ${company}`;
    }
    let searchString = _.get(contact, 'linkedInUrl', `https://www.linkedin.com/search/results/all/?keywords=${parametersStr}`);
    if (!/^https?:\/\//i.test(searchString)) {
      searchString = 'https://' + searchString;
    }
    window.open(searchString, '_blank');
  }
  openCRMRecordByContact(contact: Contact) {
    const searchString = _.get(contact.crmInfo as ContactCrmInfo, 'entityUrl', '');
    window.open(searchString, '_blank');
  }

  openLinkedByContact(contact: Contact, searchWithin = true, searchFromReplace = false) {
    if (searchWithin) {
      const firstName = _.get(contact, 'firstName', '');
      const lastName = _.get(contact, 'lastName', '');
      const company = searchFromReplace ? _.get(contact, 'company.name', '') : _.get(contact, 'company', '');
      const parametersStr = `${firstName} ${lastName} ${company}`;

      let linkedInUrl = _.get(contact, 'linkedInUrl');
      if (linkedInUrl) {
        // Convert to safe value.
        linkedInUrl = this.safeValue.transform(linkedInUrl, 'url');
      } else {
        linkedInUrl = `https://www.linkedin.com/search/results/all/?keywords=${parametersStr}`;
      }
      let searchString = linkedInUrl;
      if (!/^https?:\/\//i.test(searchString)) {
        searchString = 'https://' + searchString;
      }
      window.open(searchString, '_blank');
    } else {
      window.open(this.DEFAULT_LINKEDIN_URL, '_blank');
    }
  }

  openTwitterByContact(contact: Contact) {
    let twitterUrl = _.get(contact, 'twitterUrl');
    if (twitterUrl) {
      // Convert to safe value.
      twitterUrl = this.safeValue.transform(twitterUrl, 'url');
    }
    const searchString = twitterUrl ? twitterUrl : this.DEFAULT_TWITTER_URL;
    window.open(searchString, '_blank');
  }

  openLinkedInCompanyByContact(contact: Contact, searchWithin = true) {
    if (searchWithin) {
      const companyName = _.get(contact, 'company', '');

      let companyLinkedInUrl = _.get(contact, 'companyLinkedInUrl');

      if (companyLinkedInUrl) {
        // Convert to safe value.
        companyLinkedInUrl = this.safeValue.transform(companyLinkedInUrl, 'url');
      } else {
        companyLinkedInUrl = `https://www.linkedin.com/search/results/all/?keywords=${companyName}`;
      }
      let searchString = companyLinkedInUrl;
      if (!/^https?:\/\//i.test(searchString)) {
        searchString = 'https://' + searchString;
      }
      window.open(searchString, '_blank', 'noopener noreferrer');
    } else {
      window.open(this.DEFAULT_LINKEDIN_URL, '_blank', 'noopener noreferrer');
    }
  }

  openTwitterCompanyByContact(contact: Contact) {
    let companyTwitterUrl = _.get(contact, 'companyTwitterUrl');

    if (companyTwitterUrl) {
      // Convert to safe value.
      companyTwitterUrl = this.safeValue.transform(companyTwitterUrl, 'url');
    }
    const searchString = companyTwitterUrl ? companyTwitterUrl : this.DEFAULT_TWITTER_URL;
    window.open(searchString, '_blank');
  }

  openCompanyWebsite(contact: Contact) {
    let searchString = _.get(contact, 'companyPageUrl');

    if (searchString) {
      // Convert to safe value.
      searchString = this.safeValue.transform(searchString, 'url');
    }

    if (!/^https?:\/\//i.test(searchString)) {
      searchString = 'https://' + searchString;
    }
    window.open(searchString, '_blank');
  }

  getLastDateOfEngement(contact, longText?) {
    let lastEmailedAt = _.get(contact, 'lastEmailedAt');
    let lastCalledAt = _.get(contact, 'lastCalledAt');
    let lastTextedAt = _.get(contact, 'lastTextedAt');
    let lastDate;
    let lastTouchLong: string;
    let lastTouch;
    let touchType;

    if (this.isLastTouchedEmpty(lastEmailedAt, lastCalledAt, lastTextedAt, lastTouch, longText)) {
      return;
    }

    const lastTouchDates = [
      { title: 'Email', date: lastEmailedAt },
      { title: 'Call', date: lastCalledAt },
      { title: 'SMS', date: lastTextedAt },
    ].sort((dateOne, dateTwo) => {
      if (dateOne.date && dateTwo.date) {
        var d1 = this.dateManagement.strDateToMoment(dateOne.date);
        var d2 = this.dateManagement.strDateToMoment(dateTwo.date);
        return d1.isAfter(d2) ? 1 : -1;
      }
      return dateOne.date ? 1 : -1;
    });

    lastDate = this.dateManagement.strDateToMoment(lastTouchDates[2].date);
    touchType = 'Last Touch (by ' + lastTouchDates[2].title + ')';

    const diffFromNow = this.dateManagement.getDifferenceFromNow(lastDate);
    lastTouchLong = diffFromNow.long;

    if (!longText) {
      lastTouch = diffFromNow.short;
    } else {
      lastTouch = 'LT: ' + diffFromNow.short + ' Ago';
    }

    return { lastDate, touchType };
  }

  isLastTouchedEmpty(lastEmailedAt, lastCalledAt, lastTextedAt, lastTouch, longText) {
    if (!lastEmailedAt && !lastCalledAt && !lastTextedAt) {
      lastTouch = longText ? 'LT: -' : '-';
      return true;
    } else {
      return false;
    }
  }

  onDotsButtonAction(
    event: DotsOption,
    profile: Contact,
    engageIndicatorOptions: any = this.engageIndicatorOptions,
    connectedCrm: IntegrationNamesMap,
    resourceType: ResourceType = ResourceType.Contact,
  ) {
    const actionName = event.displayName;
    switch (actionName) {
      case ContactMoreOptionName.Edit:
        this.dialog
          .open(ContactCreateComponent, {
            width: '580px',
            height: '617px',
            data: {
              contact: profile,
              mode: ContactCreateMode.update,
              connectedCrm,
              pageSource: resourceType,
            },
          })
          .afterClosed()
          .subscribe(() => {});
        break;
      case ContactMoreOptionName.AssociateToAccount:
        this.companyService
          .getCompanyAccountMembershipsByContactIds([profile.id])
          .pipe(
            take(1),
            mergeMap((res: Array<CompanyAccountContact>) => {
              const contact = _.cloneDeep(profile);
              contact.companyAccountId = _.get(res, '[0].accountId', null);
              return this.dialog
                .open(ContactAssociateToAccountDialogComponent, {
                  width: '600px',
                  height: '383px',
                  data: {
                    contacts: [contact],
                    cta: 'ContactsProfilePage',
                  },
                })
                .afterClosed();
            }),
          )
          .subscribe((saved) => {
            if (saved) {
              // this is for refreshing the data on the profile page
              this.contactEntityStore.dispatch(GetContactAction({ id: profile.id }));
            }
          });
        break;
      case ContactMoreOptionName.SubscriptionSettings:
        const doNotEmail = engageIndicatorOptions.find((item) => _.get(item, 'type') === SystemName.doNotEmail);
        const doNotCall = engageIndicatorOptions.find((item) => _.get(item, 'type') === SystemName.doNotCall);
        const contactIsSynced = _.get(profile, 'externalId');
        if (connectedCrm === IntegrationNamesMap.Hubspot) {
          if (_.get(doNotEmail, 'checked') && contactIsSynced) {
            doNotEmail.disabled = true;
          }
        } else {
          doNotEmail.disabled = false;
          doNotCall.disabled = false;
        }
        this.dialog
          .open(EngageSettingsDialogComponent, {
            width: '400px',
            data: {
              contact: profile,
              checkBoxOptions: engageIndicatorOptions,
              subHeader: 'Unsubscribe this contact from:',
            },
          })
          .afterClosed()
          .subscribe((updatedOptions) => {
            if (updatedOptions) {
              this.engageIndicatorOptions = updatedOptions;
              this.updateContactEngageSettings(updatedOptions, profile, resourceType);
            }
          });
        break;
      case ContactMoreOptionName.LogACall:
        if (this.isCallOrLogDialogOpen()) {
          this.notyService.postMessage(new Message(MessageType.ERROR, `To log another call, please complete/cancel the call log in progress`));
        } else {
          this.store.dispatch(
            SetManualLogCallInfo({
              isDialogOpen: true,
              contactId: profile.id,
            }),
          );
        }
    }
  }

  updateContactEngageSettings(engageIndicatorOptions: any[], contact: Contact, pageSource: ResourceType) {
    const doNotEmail = engageIndicatorOptions.find((item) => item.type === SystemName.doNotEmail);
    const doNotCall = engageIndicatorOptions.find((item) => item.type === SystemName.doNotCall);
    const doNotSms = engageIndicatorOptions.find((item) => item.type === SystemName.doNotSms);
    const updateContact: Contact = { ...contact };
    const oldContact: Contact = { ...contact };

    const updateEngageSettings = updateContact.doNotCall !== doNotCall || (updateContact.doNotEmail !== doNotEmail && updateContact.doNotSms !== doNotSms);
    updateContact.doNotCall = _.get(doNotCall, 'checked');
    updateContact.doNotEmail = _.get(doNotEmail, 'checked');
    updateContact.doNotSms = _.get(doNotSms, 'checked', false);

    switch (pageSource) {
      case ResourceType.CompanyAccountContact: {
        this.companyAccountDetailContactEntityStore.dispatch(UpdateCompanyAccountDetailContactAction({ payload: updateContact, updateEngageSettings }));
        break;
      }
      default: {
        this.contactEntityStore.dispatch(UpdateContactAction({ payload: updateContact, updateEngageSettings, oldContact }));
      }
    }
  }

  isCallOrLogDialogOpen(): boolean {
    let isManualLogDialogOpen = false;
    const voipCallOrLogActive = this.voipDialerService.isWaitingForLog();
    const bridgeCallActive = !this.entityDialerService.isAbleToMakeNewCall();
    this.store.select(getManualLogCallDialogOpen).subscribe((isDialogOpen) => (isManualLogDialogOpen = isDialogOpen));
    return isManualLogDialogOpen || voipCallOrLogActive || bridgeCallActive;
  }
}
