import { Injectable } from '@angular/core';
import { EXTENSION_NAVIGATION_URLS, ExtensionNavigationPageNames } from '@app/extension/constants/redirection-page-names.constant';
import * as _ from 'lodash';
import { ExtGoToNinetyPctAction } from '@app/extension/ngrx/action/extension.action';
import { EmailModeService } from '@zi-pages/email-v2/services/email-mode.service';
import { Store } from '@ngrx/store';
import { ApplicationState } from '@app/reducers';
import { PhoneNumberInfo } from '@app/caller/interface/phone-number-info';
import { Task } from '@zi-core/data-model/task.model';
import { EngageActionTypeV2 } from '@zi-pages/engage-mode-v2/engage-mode-v2.config';
import { NavigationService } from '@zi-core/service/navigation.service';
import { take } from 'rxjs/operators';
import { BridgeDialerService } from '@app/caller/service/bridge-dialer.service';
import { DialerUtilService } from '@app/caller/service/dialer-util.service';
import { forkJoin } from 'rxjs';
import { DialerSelectionSelector } from '@zi-pages/account/ngrx/selector/dialer-config.selector';
import { getValidAuthToken, isVoipEnabledSelector } from '@zi-core/ngrx/state/auth.state';
import { VoipDialerService } from '@app/caller/service/voip-dialer.service';
import { Contact } from '@zi-core/data-model/contact.model';
import { ContactCallInfo } from '@app/caller/interface/call-info';
import { NotyService } from '@zi-common/service/noty/noty.service';
import { Message } from '@zi-common/model/message/message.model';
import { MessageType } from '@zi-common/model/message/message-type';
import { environment } from '@env/environment';
import { Invitation } from '@zi-core/data-model/invitation.model';
import { EmailInvokedPage } from '@zi-core/enums/engage-email';
import { ContactInternalDataService } from '@app/pages/contact/service/contact-internal-data.service';
import { SharpyErrorTypesEnum } from '@app/common/model/sharpy-error-types.enum';

@Injectable({
  providedIn: 'root',
})
export class ExtensionRedirectionService {
  constructor(
    private bridgeDialerService: BridgeDialerService,
    private voipDialerService: VoipDialerService,
    private dialerUtilService: DialerUtilService,
    private appStore: Store<ApplicationState>,
    private navigationService: NavigationService,
    private emailService: EmailModeService,
    private notyService: NotyService,
    private contactInternalDataService: ContactInternalDataService,
  ) {}

  public getRedirectParams(pageName, params?): { url: string; queryParams: object } {
    let url = '';
    let queryParams = {};
    switch (pageName) {
      case ExtensionNavigationPageNames.ACCOUNT_SETTINGS:
        url = EXTENSION_NAVIGATION_URLS[pageName][0];
        break;
      case ExtensionNavigationPageNames.ENGAGE:
        url = EXTENSION_NAVIGATION_URLS[pageName][0];
        break;
      case ExtensionNavigationPageNames.INTEGRATION:
        url = EXTENSION_NAVIGATION_URLS[pageName][0];
        if (params.platform) {
          queryParams = { connect: params.platform };
        }
        break;
      case ExtensionNavigationPageNames.ACCOUNT:
        url = EXTENSION_NAVIGATION_URLS[pageName][0];
        break;
      case ExtensionNavigationPageNames.ADMIN:
        url = EXTENSION_NAVIGATION_URLS[pageName][0];
        break;
      case ExtensionNavigationPageNames.BULK_EMAIL:
        url = EXTENSION_NAVIGATION_URLS[pageName][0];
        if (params.selectedContactIds) {
          queryParams = { selectedContactIds: params.selectedContactIds };
        }
        break;
    }
    return { url, queryParams };
  }

  emailSendAction(contact): void {
    this.goToContactAndExtendScreenToNinetyPct(contact);
    this.emailService.initializeNewEmail([contact]);
  }

  emailReplySendAction(contact, email: Invitation): void {
    this.goToContactAndExtendScreenToNinetyPct(contact);
    this.emailService.initializeEmailReply([contact], email, EmailInvokedPage.STANDALONE);
  }

  goToContactAndExtendScreenToNinetyPct(contact) {
    const contactId = _.get(contact, 'id', 0);
    const url = `app/contacts/v2/${contactId}`;
    this.emailService.clearFormArray();
    this.appStore.dispatch(ExtGoToNinetyPctAction({ redirectUrl: url }));
  }

  callActionStart(contact: Contact, phoneNumberInfo?: PhoneNumberInfo): void {
    forkJoin([this.appStore.select(DialerSelectionSelector).pipe(take(1)), this.appStore.select(isVoipEnabledSelector).pipe(take(1))]).subscribe(
      ([dialerSelection, isVoipEnabled]) => {
        let phoneInfoToUse: PhoneNumberInfo;
        if (!phoneNumberInfo || _.isEmpty(phoneNumberInfo)) {
          phoneInfoToUse = this.dialerUtilService.pickFirstNumberToUse(contact);
        } else {
          phoneInfoToUse = phoneNumberInfo;
        }
        if (this.dialerUtilService.checkIsVoip(dialerSelection, isVoipEnabled)) {
          this.makeVoipCall(contact, phoneInfoToUse);
        } else if (this.dialerUtilService.checkIsBridge(dialerSelection, isVoipEnabled)) {
          this.makeBridgeCall(contact, phoneInfoToUse);
        } else {
          this.postDialerErrorMessage();
        }
      },
    );
  }

  goToStandAloneModeForTask(task: Task, panelToOpen?: EngageActionTypeV2, additionalInfo?): void {
    const contactData: Contact = _.get(task, 'contact');
    const url = `app/contacts/v2/${contactData.id}`;
    const templateId = this.navigationService.getEmailTemplateIdFromTask(task);
    const actionType = panelToOpen ? panelToOpen : _.get(task, 'type');
    if (actionType === EngageActionTypeV2.Email) {
      this.emailService.clearFormArray();
      this.appStore.dispatch(ExtGoToNinetyPctAction({ redirectUrl: url }));
      this.emailService.initializeNewEmail([contactData], { taskId: task.id, templateId });
    } else if (actionType === EngageActionTypeV2.Phone || actionType === 'Call') {
      forkJoin([this.appStore.select(DialerSelectionSelector).pipe(take(1)), this.appStore.select(isVoipEnabledSelector).pipe(take(1))]).subscribe(
        ([dialerSelection, isVoipEnabled]) => {
          let phoneInfoToUse: PhoneNumberInfo;
          phoneInfoToUse = additionalInfo as PhoneNumberInfo;
          if (!phoneInfoToUse || _.isEmpty(phoneInfoToUse)) {
            phoneInfoToUse = this.dialerUtilService.pickFirstNumberToUse(contactData);
          }

          if (this.dialerUtilService.checkIsVoip(dialerSelection, isVoipEnabled)) {
            this.makeVoipCall(contactData, phoneInfoToUse, task.id);
          } else if (this.dialerUtilService.checkIsBridge(dialerSelection, isVoipEnabled)) {
            this.navigationService.startCall(contactData, phoneInfoToUse, task.id);
          } else {
            this.postDialerErrorMessage();
          }
        },
      );
    }
  }

  goToContactProfile(contactId: number): void {
    this.contactInternalDataService.getContactById(contactId).subscribe(
      (contact) => {
        const url = `app/contacts/v2/${contactId}`;
        this.appStore.dispatch(ExtGoToNinetyPctAction({ redirectUrl: url }));
      },
      (error) => {
        if (error?.status === 403 && error?.error?.ResponseStatus?.ErrorCode === SharpyErrorTypesEnum.FORBIDDEN) {
          this.notyService.postMessage(new Message(MessageType.ERROR, `Access Denied. You do not have permission to view this Contact`));
        }
      },
    );
  }

  goToCompanyAccountProfile(accountId: number): void {
    const url = `app/accounts/${accountId}`;
    this.appStore.dispatch(ExtGoToNinetyPctAction({ redirectUrl: url }));
  }

  goToViewContacts(query: string): void {
    const url = `${environment.app_domain}/#/app/contacts/v2?query=${query}`;
    window.open(url, '_blank');
  }

  private makeBridgeCall(contact: Contact, phoneInfo: PhoneNumberInfo): void {
    const contactId = _.get(contact, 'id', 0);
    this.bridgeDialerService
      .startNewCallWithContactStandalone$({ name: contact.name, id: contactId, phoneNumberDialed: phoneInfo.number }, phoneInfo.type)
      .pipe(take(1))
      .subscribe();
  }

  private makeVoipCall(contact: Contact, phoneInfo: PhoneNumberInfo, taskId?: number): void {
    const contactCallInfo: ContactCallInfo = {
      name: _.get(contact, 'name', 'Unknown'),
      id: _.get(contact, 'id', 0),
      email: _.get(contact, 'email', null),
      phoneNumberDialed: phoneInfo.number,
      zoomId: _.get(contact, 'zoomId', 0),
    };
    this.appStore
      .select(getValidAuthToken)
      .pipe(take(1))
      .subscribe((authToken) => {
        this.voipDialerService.startProspectCallWithStandalone(contactCallInfo, phoneInfo.type, authToken.userId, taskId);
      });
  }

  private postDialerErrorMessage() {
    const settingsURL = `${environment.app_domain}/#/${EXTENSION_NAVIGATION_URLS[ExtensionNavigationPageNames.INTEGRATION][0]}`;
    this.notyService.postMessage(
      new Message(MessageType.ERROR, `Dialer is not setup, click <a href="${settingsURL}" target="_blank">here</a> to setup your dialer`),
    );
  }
}
