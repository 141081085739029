import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IndicationKey } from '@app/common/component/indications/indications.config';
import { CheckboxOptions, DotsOption } from '@app/common/interface/dots-option';
import { ContactActionsNames } from '@app/common/model/actions/contactActionNames';
import { SelectComponentObject } from '@app/common/model/dropdown/select.model';
import { Contact } from '@app/core/data-model/contact.model';
import { CrmIntegration, crmIntegrationResponseInterface, CrmIntegrations } from '@app/core/data-model/integration.model';
import { SObjectType } from '@app/core/data-model/sobject.model';
import { IdProviderType } from '@app/core/enums/id-povider-type.enum';
import { IntegrationNamesMap } from '@app/core/enums/integration-names-map.enum';
import { SalesforceEntityType } from '@app/core/enums/salesforce-entity-type.enum';
import { CrmIdProviderType, Organization } from '@app/core/http-model/response/organization.model';
import { LoadConfigurationCRM } from '@app/core/ngrx/action/auth.action';
import { connectedServicesList, getAllCrmConnectionStatusMap, getConfigurationCRM, getOrg, isConnectedToService } from '@app/core/ngrx/state/auth.state';
import { IntegrationService } from '@app/core/service/integration.service';
import { ApplicationState } from '@app/reducers';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import { ContactMoreOptionName } from '@zi-common/model/contact-options.enum';
import { MessageType } from '@zi-common/model/message/message-type';
import { Message } from '@zi-common/model/message/message.model';
import { SystemName } from '@zi-common/pages-component/upload-contacts/file-mapping-dialog/file-mapping.config';
import { NotyService } from '@zi-common/service/noty/noty.service';
import { OrganizationService } from '@zi-common/service/organization/organization.service';
import { CrmEntitiesNames, CrmEntityType, CrmSyncType } from '@zi-core/enums/crm-entity-type.enum';
import { CreateCustomFieldsRequest, CRMCustomFieldDeleteRequest, CRMCustomFieldRequest } from '@zi-core/http-model/request/crm.request.model';
import {
  APILimitResponseModel,
  ConfigurationCRM,
  CrmEntityConfigModel,
  CRMEntityFieldMapConfig,
  CRMUser,
  CrmUserMappingGetResModel,
  CrmUsersListGetResModel,
  CustomFieldsModel,
  DescribeCRM,
  LeadOrContactField,
  ResetCRMEntityMapPayload,
  UserMappingPostModel,
  UserMappingPostResponseModel,
} from '@zi-core/http-model/response/crm.model';
import { HubspotIndustryResponse } from '@zi-core/http-model/response/hubspot-industry.response';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { LoggerServiceToken } from '@zi-core/config/logger-service.config';
import { ILoggerService } from '@zi-core/interface/logger.service.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class CrmService {
  private isContactEditable$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private isOrgSyncEnabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isContactCreationEnabledForCrm$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private contactCrmFields: LeadOrContactField[] = [];
  private leadsCrmFields: LeadOrContactField[] = [];
  private readonly crmPlatformList: string[] = [
    IntegrationNamesMap.Salesforce,
    IntegrationNamesMap.Dynamics,
    IntegrationNamesMap.UnieSalesforce,
    IntegrationNamesMap.Hubspot,
  ];

  constructor(
    private httpClient: HttpClient,
    private organizationService: OrganizationService,
    private store: Store<ApplicationState>,
    private integrationService: IntegrationService,
    private readonly notyService: NotyService,
    @Inject(LoggerServiceToken) private readonly loggerService: ILoggerService,
  ) {}

  getCrmNameByIdProvider(idProvider: IdProviderType) {
    switch (idProvider) {
      case IdProviderType.Hubspot:
        return IntegrationNamesMap.Hubspot;
      case IdProviderType.Dynamics:
        return IntegrationNamesMap.Dynamics;
      case IdProviderType.Salesforce:
        return IntegrationNamesMap.Salesforce;
      case IdProviderType.UnieSalesforce:
        return IntegrationNamesMap.UnieSalesforce;
      default:
        return null;
    }
  }

  public isConnectedToCRM(): Observable<boolean> {
    return this.store.pipe(
      select(connectedServicesList),
      distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)),
      map((connectedServiceList: IntegrationNamesMap[]) => connectedServiceList.some((service) => this.crmPlatformList.includes(service))),
    );
  }

  public getOrgConnectedCrmName(orgCrmIntegrations: CrmIntegration): IntegrationNamesMap {
    return _.get(
      Object.keys(orgCrmIntegrations || {}).filter((key) => {
        return orgCrmIntegrations[key];
      }),
      '[0]',
    );
  }

  public getOrgCrmIntegrations(): Observable<CrmIntegration> {
    return this.loadCrmServices().pipe(
      map((crmIntegrations: crmIntegrationResponseInterface) => _.get(crmIntegrations, 'orgCrmIntegrations', CrmIntegrations)),
    );
  }

  getDescribeCRM(): Observable<DescribeCRM> {
    return this.httpClient.get<DescribeCRM>(`${environment.backend_url}/v1/crm/describe`);
  }

  getConfigurationCRM(): Observable<ConfigurationCRM> {
    return this.httpClient.get<ConfigurationCRM>(`${environment.backend_url}/v1/crm/configuration`);
  }

  checkIfUserIsConnectedToSameOrgCrm(): Observable<CrmIdProviderType> {
    const observablesToWaitOn: Observable<any>[] = [
      this.store.select(isConnectedToService(IntegrationNamesMap.Salesforce)),
      this.store.select(isConnectedToService(IntegrationNamesMap.UnieSalesforce)),
      this.store.select(isConnectedToService(IntegrationNamesMap.Hubspot)),
      this.store.select(isConnectedToService(IntegrationNamesMap.Dynamics)),
      this.store.select(getOrg),
    ];

    return combineLatest(observablesToWaitOn).pipe(
      map(([userConnectedToSalesforce, userConnectedToUnieSalesforce, userConnectedToHubspot, userConnectedToDynamics, org]) => {
        const crmIdProviderType = _.get(org, 'crmIdProviderType');
        const orgCrmIntegrations = {
          [IntegrationNamesMap.Hubspot]: crmIdProviderType === CrmIdProviderType.Hubspot,
          [IntegrationNamesMap.Dynamics]: crmIdProviderType === CrmIdProviderType.Dynamics,
          [IntegrationNamesMap.Salesforce]: crmIdProviderType === CrmIdProviderType.Salesforce,
          [IntegrationNamesMap.UnieSalesforce]: crmIdProviderType === CrmIdProviderType.UnieSalesforce,
        };
        const userCrmIntegrations = {
          [IntegrationNamesMap.Salesforce]: userConnectedToSalesforce,
          [IntegrationNamesMap.UnieSalesforce]: userConnectedToUnieSalesforce,
          [IntegrationNamesMap.Hubspot]: userConnectedToHubspot,
          [IntegrationNamesMap.Dynamics]: userConnectedToDynamics,
        };
        const userAndOrgConnectedToHubspot = orgCrmIntegrations[IntegrationNamesMap.Hubspot] && userCrmIntegrations[IntegrationNamesMap.Hubspot];
        const userAndOrgConnectedToDynamics = orgCrmIntegrations[IntegrationNamesMap.Dynamics] && userCrmIntegrations[IntegrationNamesMap.Dynamics];
        const userAndOrgConnectedToSalesforce = orgCrmIntegrations[IntegrationNamesMap.Salesforce] && userCrmIntegrations[IntegrationNamesMap.Salesforce];
        const userAndOrgConnectedToUnieSalesforce =
          orgCrmIntegrations[IntegrationNamesMap.UnieSalesforce] && userCrmIntegrations[IntegrationNamesMap.UnieSalesforce];

        if (userAndOrgConnectedToHubspot) {
          return CrmIdProviderType.Hubspot;
        } else if (userAndOrgConnectedToSalesforce) {
          return CrmIdProviderType.Salesforce;
        } else if (userAndOrgConnectedToUnieSalesforce) {
          return CrmIdProviderType.UnieSalesforce;
        } else if (userAndOrgConnectedToDynamics) {
          return CrmIdProviderType.Dynamics;
        } else {
          return CrmIdProviderType.None;
        }
      }),
    );
  }

  loadCrmServices(): Observable<crmIntegrationResponseInterface> {
    const allCrmConnectionStatusMap$ = this.store.pipe(select(getAllCrmConnectionStatusMap), distinctUntilChanged(_.isEqual));
    // ignore the first null value from state from org
    const getOrg$ = this.store.pipe(
      select(getOrg),
      filter((org) => !!org),
      distinctUntilChanged(_.isEqual),
    );

    return combineLatest([allCrmConnectionStatusMap$, getOrg$]).pipe(
      // ensure that org is not null (auth state is initialized) before moving on
      filter(([allCrmConnectionStatusMap, org]) => !!org),
      map(([allCrmConnectionStatusMap, org]) => {
        const crmIdProviderType = _.get(org, 'crmIdProviderType');
        const orgCrmIntegrations = {
          [IntegrationNamesMap.Hubspot]: crmIdProviderType === CrmIdProviderType.Hubspot,
          [IntegrationNamesMap.Dynamics]: crmIdProviderType === CrmIdProviderType.Dynamics,
          [IntegrationNamesMap.Salesforce]: crmIdProviderType === CrmIdProviderType.Salesforce,
          [IntegrationNamesMap.UnieSalesforce]: crmIdProviderType === CrmIdProviderType.UnieSalesforce,
        };
        const userCrmIntegrations = {
          [IntegrationNamesMap.Salesforce]: allCrmConnectionStatusMap[IntegrationNamesMap.Salesforce],
          [IntegrationNamesMap.UnieSalesforce]: allCrmConnectionStatusMap[IntegrationNamesMap.UnieSalesforce],
          [IntegrationNamesMap.Hubspot]: allCrmConnectionStatusMap[IntegrationNamesMap.Hubspot],
          [IntegrationNamesMap.Dynamics]: allCrmConnectionStatusMap[IntegrationNamesMap.Dynamics],
        };
        return {
          orgCrmIntegrations,
          userCrmIntegrations,
        };
      }),
    );
  }

  getUserCRM(): Observable<{ crmUser: CRMUser }> {
    return this.httpClient.get<{ crmUser: CRMUser }>(`${environment.backend_url}/v1/crm/user`);
  }

  postConfiguration(newConfiguration: ConfigurationCRM): Observable<ConfigurationCRM> {
    return this.httpClient.post<ConfigurationCRM>(`${environment.backend_url}/v1/crm/configuration`, newConfiguration).pipe(
      map((res) => res),
      tap(() => this.store.dispatch(LoadConfigurationCRM())),
    );
  }

  applyBiDirectionalSyncRules() {
    return this.httpClient.put(`${environment.import_service_url}/import/bisync/bulk`, {});
  }

  initCrmFields() {
    return forkJoin(this.getCRMEntityFieldMapConfigV2(CrmEntityType.Contact), this.getCRMEntityFieldMapConfigV2(CrmEntityType.Lead)).pipe(
      map(([config1, config2]) => {
        this.contactCrmFields = _.get(config1, 'crmFields');
        this.leadsCrmFields = _.get(config2, 'crmFields');
      }),
    );
  }

  /**
   * Determines if contact fields are editable
   *  - If (OrgSync Enabled) then contact will be editable and each field mapping will be checked
   * else if (Bi Directional Sync is Enabled) then also contact is editable
   * - If crm is not connected then contact is  editable
   *
   * @param org - optional param, if exist initial org$ observable with this value; otherwise getOrganization from organizationService
   * @returns true if OrgSync Or Bi Directional Sync ; othewise false
   */
  isOrgSyncOrBiDirectionalSyncApplicable(orgParam: Organization = null): Observable<boolean> {
    const crmEnabledInOrg$ = this.loadCrmServices().pipe(
      distinctUntilChanged(_.isEqual),
      mergeMap((crmServices) => {
        const orgConnectedCrm: IntegrationNamesMap = this.getOrgConnectedCrmName(crmServices.orgCrmIntegrations);
        return this.integrationService.checkIfOrgEnabledIntegration(orgConnectedCrm).pipe(map((isEnabled) => [isEnabled, orgConnectedCrm]));
      }),
      distinctUntilChanged(_.isEqual),
    );
    this.loadEntityCrmFieldsForOrgSyncBiDiSync();

    const getConfigurationCRM$ = this.store.pipe(select(getConfigurationCRM), distinctUntilChanged(_.isEqual));
    const getConnectedServices$ = this.store.pipe(select(connectedServicesList), distinctUntilChanged(_.isEqual));
    const org$ = orgParam ? of({ organization: orgParam }) : this.organizationService.getOrganization();

    return combineLatest([crmEnabledInOrg$, getConfigurationCRM$, getConnectedServices$, org$]).pipe(
      map(([[isCrmEnabledForOrg, orgConnectedCrm], crmConfig, connectedServices, org]) => {
        this.isOrgSyncEnabled$.next(_.get(org, 'organization.isOrgSyncEnabled', false));
        let isContactEditable = false;
        const isCRMEnabled = isCrmEnabledForOrg && connectedServices.some((s) => s === orgConnectedCrm);
        if (!isCRMEnabled) {
          this.isContactEditable$.next(true);
          this.isContactCreationEnabledForCrm$.next(false);
          return true;
        }
        /* First check if Org Sync for Org is Enabled
         * if true - contact Editable is send true
         * else check for bi-directional */
        if (this.isOrgSyncEnabled$.value) {
          isContactEditable = _.get(org, 'organization.isOrgSyncEnabled', false) && isCRMEnabled;
        } else {
          isContactEditable = _.get(crmConfig, 'updateEnabled', false) && isCRMEnabled;
        }
        this.isContactEditable$.next(isContactEditable);

        // Determines whether a user is connected to CRM and has enabled contact/lead sync creation
        const isContactCreationEnabledForCRM =
          isCRMEnabled &&
          connectedServices.some((s) => s === orgConnectedCrm) &&
          (_.get(crmConfig, 'newProspectCreateEntityType') === SObjectType.CONTACT || _.get(crmConfig, 'newProspectCreateEntityType') === SObjectType.LEAD);
        this.isContactCreationEnabledForCrm$.next(isContactCreationEnabledForCRM);

        return this.isContactEditable$.value;
      }),
    );
  }

  private loadEntityCrmFieldsForOrgSyncBiDiSync() {
    this.isConnectedToCRM()
      .pipe(
        untilDestroyed(this),
        filter((isConnected) => isConnected),
        take(1),
      )
      .subscribe((isConnected) => {
        if (isConnected) {
          // ZE-29822 - Load CRM fields only if CRM is connected
          // TODO - can we store crm fields in store?
          this.loadContactCrmFields();
          this.loadLeadCrmFields();
        }
      });
  }

  isContactCreationEnabledForCrm(): boolean {
    return this.isContactCreationEnabledForCrm$.value;
  }

  /**
   * * Determines whether bi-directional rules apply based on certain scenarios
   * @param contact - contact to check if external id is present then bi-directional rule applies
   * @returns true editable; otherwise false
   */

  getIsBiDirectionalSyncApplicable(contact: Contact): boolean {
    return this.isContactEditable$.value || !!!_.get(contact, 'externalId') || _.get(contact, 'idProvider') === IdProviderType.Hubspot;
  }

  /**
   * * Determines whether bi-directional rules apply based on certain scenarios
   * @param contact - contact to check if external id is present then bi-directional rule applies
   * @returns true editable; otherwise false
   */
  getIsBiDirectionalSyncApplicableObservable(contact: Contact): Observable<boolean> {
    return this.isContactEditable$.pipe(
      map((isEditable) => isEditable || !!!_.get(contact, 'externalId') || _.get(contact, 'idProvider') === IdProviderType.Hubspot),
    );
  }

  checkIfFieldIsMapped(formField: string, contact: Contact): boolean {
    let found: LeadOrContactField;
    if (contact?.type === CrmEntityType.Lead) {
      found = this.leadsCrmFields?.find((fields) => fields?.tellwiseFieldName?.toLowerCase() === formField?.toLowerCase());
    } else {
      found = this.contactCrmFields?.find((fields) => fields?.tellwiseFieldName?.toLowerCase() === formField?.toLowerCase());
    }
    return !!found;
  }

  getBiDrectionalStatus(): boolean {
    return this.isContactEditable$.value;
  }

  /**
   * * Determines whether bi-directional rules apply based on certain scenarios for each contact field
   * @returns true if field mappings are editable; othewise false
   * @param formField
   * @param contact
   */

  isBiDirectionalSyncFileMappingEnabled(formField: string, contact: Contact): boolean {
    // If Crm is not connected Crm Fields will be empty
    if (this.isOrgSyncEnabled$.value) {
      // if contact is not synced return true
      if (contact && !contact.externalId) {
        return true;
      }
      let found: LeadOrContactField;
      if (contact?.type === CrmEntityType.Lead) {
        found = this.leadsCrmFields.find((fields) => fields.tellwiseFieldName.toLowerCase() === formField.toLowerCase());
      } else {
        found = this.contactCrmFields.find((fields) => fields.tellwiseFieldName.toLowerCase() === formField.toLowerCase());
      }
      return found ? found.crmSyncDirectionType === CrmSyncType.BiDirectionalSync : false;
    } else {
      return this.getIsBiDirectionalSyncApplicable(contact);
    }
  }

  /**
   * * Determines whether bi-directional rules apply based on certain scenarios for each contact field
   * @returns true if field mappings are editable; othewise false
   * @param formField
   * @param contact
   */
  isBiDirectionalSyncFileMappingEnabledObservable(formField: string, contact: Contact): Observable<boolean> {
    // If Crm is not connected Crm Fields will be empty
    return combineLatest([this.isOrgSyncEnabled$, this.isContactEditable$]).pipe(
      map(([isOrgsyncEnabled, isContactEditable]) => {
        if (isOrgsyncEnabled) {
          // if contact is not synced return true
          if (contact && !contact.externalId) {
            return true;
          }
          let found: LeadOrContactField;
          if (this.getCrmEntityType(contact)) {
            found = this.leadsCrmFields.find((fields) => fields.tellwiseFieldName.toLowerCase() === formField.toLowerCase());
          } else {
            found = this.contactCrmFields.find((fields) => fields.tellwiseFieldName.toLowerCase() === formField.toLowerCase());
          }
          return found?.crmSyncDirectionType === CrmSyncType.BiDirectionalSync;
        } else {
          return isContactEditable || !!!_.get(contact, 'externalId') || _.get(contact, 'idProvider') === IdProviderType.Hubspot;
        }
      }),
    );
  }

  getCrmEntityType(contact: Contact): CrmEntityType {
    const provider: IdProviderType = _.get(contact, 'idProvider');
    const contactExternalId = _.get(contact, 'externalId');
    if (!provider || !contactExternalId) {
      return CrmEntityType.None;
    }
    if (contact.type) {
      return contact.type;
    }
    switch (provider) {
      case IdProviderType.Salesforce:
      case IdProviderType.UnieSalesforce:
        if (contactExternalId.startsWith(SalesforceEntityType.Contact)) {
          return CrmEntityType.Contact;
        }
        if (contactExternalId.startsWith(SalesforceEntityType.Lead)) {
          return CrmEntityType.Lead;
        }
    }
    return CrmEntityType.None;
  }

  updateBulkOptionsForBiSyncRules(options: SelectComponentObject[], contacts: Contact[], isOrgSync?: boolean): SelectComponentObject[] {
    const isDisabled = contacts.some((c) => !this.getIsBiDirectionalSyncApplicable(c));
    const isStatusDisabled = contacts.some((c) => !this.isBiDirectionalSyncFileMappingEnabled('status', c));
    const isOrgSyncChangeOwnerDisabled = contacts.some((c) => !this.isBiDirectionalSyncFileMappingEnabled('primaryOwnerExternalId', c));
    options.forEach((o) => {
      if (o.displayName === ContactActionsNames.Delete) {
        o.isClickable = !isDisabled;
      } else if (o.displayName === ContactActionsNames.ChangeStatus) {
        o.isClickable = !isStatusDisabled;
      } else if (o.displayName === ContactActionsNames.ChangeOwner) {
        o.isClickable = isOrgSync ? !isOrgSyncChangeOwnerDisabled : !isStatusDisabled;
      }
    });

    return options;
  }

  /**
   * Brings back the edit options based on the bi-directional rules settings.
   * To use this, caller should first subscribe to isOrgSyncOrBiDirectionalSyncApplicable() to initialize.
   * @returns DotsOption setting
   * @param options
   * @param contact
   * @param connectedCrm
   */
  updateContactEditForBiSyncRules(options: DotsOption[], contact: Contact, connectedCrm: IntegrationNamesMap): DotsOption[] {
    const isContactSynced = _.get(contact, 'externalId');

    options.forEach((o) => {
      if (o.displayName === ContactMoreOptionName.Edit && connectedCrm === IntegrationNamesMap.Hubspot && isContactSynced) {
        o.disabled = true;
        return;
      }
      if (o.displayName === ContactMoreOptionName.Edit || o.displayName === ContactMoreOptionName.SubscriptionSettings) {
        o.disabled = this.isOptionDisabledByBiSyncRules(contact, connectedCrm);
      }
    });
    return options;
  }

  public isOptionDisabledByBiSyncRules(contact: Contact, connectedCrmName: IntegrationNamesMap) {
    return (
      (connectedCrmName === IntegrationNamesMap.Salesforce ||
        connectedCrmName === IntegrationNamesMap.UnieSalesforce ||
        connectedCrmName === IntegrationNamesMap.Dynamics) &&
      !this.getIsBiDirectionalSyncApplicable(contact)
    );
  }

  public isOptionDisabledByBiSyncRulesObservable(contact: Contact, connectedCrmName: IntegrationNamesMap) {
    return this.getIsBiDirectionalSyncApplicableObservable(contact).pipe(
      map(
        (isApplicable) =>
          (connectedCrmName === IntegrationNamesMap.Salesforce ||
            connectedCrmName === IntegrationNamesMap.UnieSalesforce ||
            connectedCrmName === IntegrationNamesMap.Dynamics) &&
          !isApplicable,
      ),
    );
  }

  updateEngageSettingOptions(options: CheckboxOptions[], contact: Contact, connectedCrm: IntegrationNamesMap): CheckboxOptions[] {
    options.forEach((o) => {
      if (o.type === SystemName.doNotEmail) {
        if (connectedCrm === IntegrationNamesMap.Salesforce || connectedCrm === IntegrationNamesMap.UnieSalesforce) {
          o.disabled = !this.isBiDirectionalSyncFileMappingEnabled(SystemName.doNotEmail, contact);
        } else if (connectedCrm === IntegrationNamesMap.Hubspot) {
          o.disabled = _.get(o, 'checked') && !!_.get(contact, 'externalId');
        } else {
          o.disabled = false;
        }
      } else if (o.type === SystemName.doNotCall) {
        o.disabled =
          (connectedCrm === IntegrationNamesMap.Salesforce || connectedCrm === IntegrationNamesMap.UnieSalesforce) &&
          !this.isBiDirectionalSyncFileMappingEnabled(SystemName.doNotCall, contact);
      }
    });
    return options;
  }

  /**
   * Get crm-tellwise field mapping for a particular entity.
   * @param entityType - CrmEntityType, type of crm entity ex: 'Account'
   */
  public getCRMEntityFieldMapConfigV2(entityType: CrmEntityType): Observable<CRMEntityFieldMapConfig> {
    return this.httpClient.get<CRMEntityFieldMapConfig>(`${environment.backend_url}/v2/crm/configuration?entityType=${CrmEntitiesNames[entityType]}`);
  }

  /**
   * Save v2 entity configuration.
   * @param data - CrmEntityConfigModel
   */
  public saveEntityConfigV2(data: CrmEntityConfigModel): Observable<CRMEntityFieldMapConfig> {
    return this.httpClient.post<CRMEntityFieldMapConfig>(
      `${environment.backend_url}/v2/crm/configuration?entityType=${CrmEntitiesNames[data.entity]}`,
      data.config,
    );
  }

  /**
   * Reset v2 crm-entity configuration
   * @param payload - ResetCRMEntityMapPayload, data for request
   */
  public resetCRMEntityMapping(payload: ResetCRMEntityMapPayload): Observable<CRMEntityFieldMapConfig> {
    return this.httpClient.post<CRMEntityFieldMapConfig>(`${environment.backend_url}/v2/crm/configuration/restore`, {
      entityType: CrmEntitiesNames[payload.entityType],
      shouldDeleteCustomFields: payload.shouldDeleteCustomFields,
    });
  }

  /**
   * Save v2 - custom fields for crm entity configuration.
   * @param data - CRMCustomFieldRequest
   */
  public saveEntityCustomFieldsConfigV2(data: CRMCustomFieldRequest): Observable<CRMEntityFieldMapConfig> {
    return this.httpClient.post<CRMEntityFieldMapConfig>(`${environment.backend_url}/v2/crm/configuration/customfields`, data);
  }

  /**
   * Delete v2 - entity custom field crm mapping.
   * @param data - CRMCustomFieldDeleteRequest
   */
  public deleteEntityCustomFieldsConfigV2(data: CRMCustomFieldDeleteRequest): Observable<CRMEntityFieldMapConfig> {
    const params = new HttpParams().set('Id', String(data.id)).set('EntityType', String(data.entityType));
    return this.httpClient.delete<CRMEntityFieldMapConfig>(`${environment.backend_url}/v2/crm/configuration/customfields`, { params });
  }

  /**
   * get custom field verification for delete action
   * @param crmEntity
   * @param fieldName
   */
  public getDeleteVerificationForCustomField(crmEntity: CrmEntityType, fieldName: string): Observable<boolean> {
    return this.httpClient.get<boolean>(
      `${environment.backend_url}/v1/customfields/delete/verify?crmEntity=${CrmEntitiesNames[crmEntity]}&fieldName=${fieldName}`,
    );
  }

  /**
   * get custom field names based on crmEntity
   * @param crmEntity
   */
  public getCustomFieldsNamesBasedOnCrmEntity(crmEntity: CrmEntityType): Observable<CustomFieldsModel[]> {
    return this.httpClient
      .get<CustomFieldsModel[]>(`${environment.backend_url}/v1/customfields?crmEntity=${CrmEntitiesNames[crmEntity]}`)
      .pipe(map((customM) => customM.sort((a, b) => a.fieldName.toLowerCase().localeCompare(b.fieldName.toLowerCase()))));
  }

  /**
   * create custom field names based on crm entity type
   * @param data
   */
  public createCustomFieldNamesForCrmEntity(data: CreateCustomFieldsRequest): Observable<CustomFieldsModel[]> {
    return this.httpClient.post<CustomFieldsModel[]>(`${environment.backend_url}/v1/customfields`, data);
  }

  /**
   * create custom field names based on crm entity type
   * @param data
   */
  public deleteCustomFieldNamesForCrmEntity(customFieldId: number): Observable<CustomFieldsModel> {
    const params = new HttpParams().set('CustomFieldId', String(customFieldId));
    return this.httpClient.delete<CustomFieldsModel>(`${environment.backend_url}/v1/customfields`, { params });
  }

  /**
   * check contact's crm entity type
   * @param contact
   * @param userCrmIntegrations
   */
  public getContactCRMEntityType(contact: Contact, userCrmIntegrations: CrmIntegration): SalesforceEntityType {
    let contactIdProvider = _.get(contact, 'idProvider');
    const contactExternalId = _.get(contact, 'externalId');

    if (contactExternalId) {
      switch (contactIdProvider) {
        case IdProviderType.Salesforce:
          if (userCrmIntegrations[IndicationKey.SALESFORCE]) {
            if (contactExternalId.startsWith(SalesforceEntityType.Contact)) {
              return SalesforceEntityType.Contact;
            }
            if (contactExternalId.startsWith(SalesforceEntityType.Lead)) {
              return SalesforceEntityType.Lead;
            }
          }
          break;
        case IdProviderType.UnieSalesforce:
          if (userCrmIntegrations[IndicationKey.UNIESALESFORCE]) {
            if (contactExternalId.startsWith(SalesforceEntityType.Contact)) {
              return SalesforceEntityType.Contact;
            }
            if (contactExternalId.startsWith(SalesforceEntityType.Lead)) {
              return SalesforceEntityType.Lead;
            }
          }
          break;
      }
    }
  }

  /**
   * get user mappings
   */
  getUserMappings(crmEntity: CrmEntityType, isCrmUserMappingFeatureFlag: boolean): Observable<CrmUserMappingGetResModel> {
    if (isCrmUserMappingFeatureFlag) {
      return this.httpClient.get<CrmUserMappingGetResModel>(`${environment.backend_url}/v2/crm/userMapping?CrmEntityType=${CrmEntitiesNames[crmEntity]}`);
    } else {
      return this.httpClient.get<CrmUserMappingGetResModel>(`${environment.backend_url}/v1/crm/userMapping?CrmEntityType=${CrmEntitiesNames[crmEntity]}`);
    }
  }

  /**
   * get list of all users in salesforce
   * @param searchTerm
   * @returns
   */
  getCrmUsers(searchTerm: string): Observable<CrmUsersListGetResModel[]> {
    return this.httpClient.get<CrmUsersListGetResModel[]>(`${environment.backend_url}/v1/crm/users?SearchTerm=${searchTerm}`);
  }

  /**
   * Save user mapping
   * @param data - UserMappingPostModel
   */
  saveUserMapping(data: UserMappingPostModel): Observable<UserMappingPostResponseModel> {
    return this.httpClient.post<UserMappingPostResponseModel>(`${environment.backend_url}/v1/crm/usermapping`, data);
  }

  getAPIUsage(): Observable<APILimitResponseModel> {
    return this.httpClient.get<APILimitResponseModel>(`${environment.backend_url}/v1/crm/limit`);
  }

  getHubspotIndustries(): Observable<HubspotIndustryResponse> {
    return this.httpClient.get<HubspotIndustryResponse>(`${environment.backend_url}/v1/crm/industrymapping`);
  }

  checkHubspotCrmIntegration$(): Observable<boolean> {
    return this.loadCrmServices().pipe(
      map((crmServices) => {
        return !!crmServices?.orgCrmIntegrations?.hubspot;
      }),
    );
  }

  getHubspotIndustryValues$(): Observable<HubspotIndustryResponse> {
    return this.getHubspotIndustries().pipe(
      catchError(() => {
        this.notyService.postMessage(new Message(MessageType.ERROR, 'Error retrieving Hubspot Industries.'));
        return of({ industryMappings: [] });
      }),
    );
  }

  /**
   * load latest Crm Settings for Contact entity
   * @private
   */
  private loadContactCrmFields() {
    this.getCRMEntityFieldMapConfigV2(CrmEntityType.Contact)
      .pipe(take(1))
      .subscribe(
        (config: CRMEntityFieldMapConfig) => {
          this.contactCrmFields = _.get(config, 'crmFields') || [];
        },
        catchError((err) => {
          this.loggerService.log('Error loading Contact CRM Fields', err);
          return of(null);
        }),
      );
  }

  /**
   * load latest Crm Settings for Lead entity
   * @private
   */
  private loadLeadCrmFields() {
    this.getCRMEntityFieldMapConfigV2(CrmEntityType.Lead)
      .pipe(take(1))
      .subscribe(
        (config: CRMEntityFieldMapConfig) => {
          this.leadsCrmFields = _.get(config, 'crmFields') || [];
        },
        catchError((err) => {
          this.loggerService.log('Error loading Lead CRM Fields', err);
          return of(null);
        }),
      );
  }
}
