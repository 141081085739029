import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface Technology {
  tag: number;
  categoryParent: string;
  category: string;
  vendor: string;
  product: string;
  attribute: string;
}

export interface Attribute {
  count: number;
  description: string;
  displayLabel: string;
  group: string;
  isCategorized: boolean;
  parentCategory: string;
  priority: number;
  searchString: string;
  value: string;
}

export interface TechnologiesAndAttributesResponse {
  companyID: number;
  companyHashtags: {
    hashtag: Attribute[];
  };
  techAttributes: {
    techAttribute: Technology[];
  };
}

@Injectable({
  providedIn: 'root',
})
export class TechnologiesAndAttributesService {
  constructor(private httpClient: HttpClient) {}

  getTechnologiesAndAttributes(companyId: number): Observable<TechnologiesAndAttributesResponse> {
    if (companyId > 0) {
      return this.httpClient.get<TechnologiesAndAttributesResponse>(`${environment.engage_backend_url}/data/company/${companyId}/techattributes`);
    }
  }
}
