export enum TaskEmptyStateStatus {
  NONE,
  CHART,
  AllTasks,
}

export enum TaskType {
  AutomaticEmail = 'AutomaticEmail',
  Email = 'Email',
  Call = 'Call',
  Other = 'Other',
  Completed = 'Completed',
}

export enum TaskFilterOptionDisplayName {
  All = 'All Types',
  Emails = 'Emails',
  Calls = 'Calls',
  Other = 'Other',
}
