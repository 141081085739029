import { Profile } from '@zi-core/data-model/profile.model';
import { UserOrganization } from '@zi-core/data-model/user-organization.model';

export interface OrgMemberResponse {
  profiles: Profile[];
  orgMembers: UserOrganization[];
}

export interface Organization {
  id: number;
  siteId: string;
  ownerId: number;
  title: string;
  logoUrl: string;
  createdAt: Date | string;
  modifiedAt: Date | string;
  attributes: { [name: string]: OrgAttribute };
  status: OrganizationStatus;
  billPeriod: BillPeriod;
  balance: number;
  balanceAt: Date | string | null;
  quota: Quota;
  quotaAsString: string;
  serviceEndsAt: Date | string | null;
  recurringAmount: number | null;
  recurringToken: string;
  recurringAt: Date | string | null;
  allowRecordPhoneCalls: boolean;
  allowCancelRecordPhoneCalls: boolean;
  crmServiceAccountId: number | null;
  OrganizationAttributes: any;
  isOrgSyncEnabled: boolean;
  crmIdProviderType: CrmIdProviderType;
  supportPin: string | null;
  allowRecordPhoneCallsLegalConsent: boolean;
}

export interface OrgAttribute {
  contactsIntegration: number;
  dialerIntegration: number;
  emailIntegration: number;
  tenantName: string;
}

export interface OrgMemberRequestParams {
  orgId: number;
  userId: number;
  privileges: number;
  sendOnBehalf: boolean;
  sendEmail?: boolean;
  roleId?: number;
}

export interface OrgMemberUpdateRoleParams {
  userId: number;
  roleId: number;
}

export enum USER_PRIVILEGE {
  Contributor = 8,
  Administrator = 3871,
}
export const VALID_ENGLISH_LETTERS_WITH_SPACE = "^[a-zA-Zs0-9! @*%^#$&+_()\\-`.+,/']*$";

export enum DEFAULT_PROFILES {
  ADMIN = 1,
  CONTENT_MANAGER = 2,
  SALES_MANAGER = 3,
  BASIC = 4,
}

export const USER_ROLES = {
  8: 'Contributor',
  3871: 'Administrator',
};

export interface OrgDialerAccount {
  id: number;
  orgId: number;
  isSharedAccount: boolean;
  localPresenceFallbackUserId: number;
  createdAt: Date | string;
  modifiedAt: Date | string;
  primaryPhoneNumber: string;
  incomingNumbers: OrgIncomingNumber[];
}

export interface OrgIncomingNumber {
  id: number;
  orgId: number;
  phoneNumber: string;
  isPrimary: boolean;
  createdAt: Date | string;
}

export interface CallerIds {
  id: number;
  userId: number;
  orgId: number;
  phone: string;
  externalId: string;
  accountSid: string;
  modifiedAt: Date | string;
}

export interface Quota {
  plan: string;
  messagesPerDay: number;
  monthlyDialerMinutesPerUser: number;
  numberOfLocalPresenceNumbers: number;
  MonthlyOrgDialerMinutes: number;
  contacts: number;
  seats: number;
  features: SubscriptionFeatures;
  DailyWorkflowEnterLimit: number | null;
  salesWorkflowInProgressLimit: number | null;
  salesWorkflowWaitingRoomLimit: number | null;
  DoziRollingContactLimit: number | null;
  DoziRollingContactPeriod: number | null;
}

export enum BillPeriod {
  Custom = 0,
  Trial = 14,
  Monthly = 30,
  Annually = 365,
}

export enum CrmIdProviderType {
  None,
  Salesforce,
  Dynamics,
  Hubspot,
  UnieSalesforce,
  UnieDynamics,
  UnieHubSpot,
}

export enum OrganizationStatus {
  Trial = 0,
  Recurring = 1,
  ManualBilling = 2,
  Cancelled = 3,
  Disabled = 4,
}

export enum SubscriptionFeatures {
  None = 0,
  Salesforce = 0x000001,
  Gmail = 0x000002,
  VoiceDialer = 0x000004,
  Dynamics = 0x000008,
  LocalPresence = 0x000010,
  Office365Mail = 0x00020,
  ExchangeServer = 0x00040,
  Hubspot = 0x00080,
  ZoomInfo = 0x00100,
}
export interface MonthlyBillHistoryRecord {
  id: number;
  orgId: number;
  month: number;
  year: number;
  organizationFeePlan: OrganizationFeePlan;
  TwilioFeePlan: TwilioFeePlan;
  additionalMinutesUsed: number;
  prePaidMinutesAvailable: number;
  prePaidMinutesUsed: number;
  includedMinutesUsed: number;
  recordingMinutesUsed: number;
  inboundMinutesUsed: number;
  outboundMinutesUsed: number;
  numberOfPhones: number;
}

export interface OrganizationFeePlan {
  orgId: number;
  numberOfMonthlyFreeMinutes: number;
  costPerPhoneNumberInCents: number;
  costPerMinuteInHundredthsOfCents: number;
  costPerRecordedMinuteInHundredthsOfCents: number;
}
export interface TwilioFeePlan {
  costPerInboundMinuteInHundredthsOfCents: number;
  costPerOutboundMinuteInHundredthsOfCents: number;
  costPerRecordedMinuteInHundredthsOfCents: number;
  costPerPhoneInHundredthsOfCents: number;
}

export interface OrganizationMinutesTransaction {
  id: number;
  orgId: number;
  createdAt: Date | string;
  transactionDate: Date | string;
  prePaidBalance: number;
  includedBalance: number;
  additionalBalance: number;
  minutesTransacted: number;
  minutesCredited?: number;
  minutesDebited?: number;
  balanceCode: BalanceCode;
  transactionCode: TransactionCode;
  comment: string;
  userId: number;
}
export enum BalanceCode {
  Additional = 0,
  PrePaid = 1,
  Included = 2,
}
export enum TransactionCode {
  Daily = 1,
  Admin = 2,
  IncludedRefresh = 3,
  BillPayment = 4,
}

export interface NewUserRequest {
  email: string;
  name: string;
  password: string;
  sendEmail: boolean;
  roleId: number;
}
