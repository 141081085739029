import { Component, OnDestroy, OnInit } from '@angular/core';
import { ExtLoadingEndAction } from '@app/extension/ngrx/action/extension.action';
import { Store } from '@ngrx/store';
import { ApplicationState } from '@app/reducers';
import { environment } from '@env/environment';
import { OnBoardingService } from '@zi-pages/on-boarding/services/on-boarding.service';
import { interval } from 'rxjs';
import { Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-onboarding-setup',
  templateUrl: './extension-onboarding-setup.component.html',
  styleUrls: ['./extension-onboarding-setup.component.scss'],
})
export class ExtensionOnboardingSetupComponent implements OnInit, OnDestroy {
  CHECK_TIME = 10000;
  subscriptionOnboard;
  defaultImg = `${environment.icons_url}/ebr2022_complete_setup.png`;
  whiteLogoImg = `${environment.icons_url}/zoominfo-white-logo.svg`;
  title = 'Complete Setup';
  subTitle =
    'We noticed, you have not completed your \n' +
    'user account setup. To make sure you can \n' +
    'take advantage of all of Engage’s features, \n' +
    'click here to finish your setup.';
  constructor(private store: Store<ApplicationState>, private router: Router, private onBoardingService: OnBoardingService) {}

  ngOnInit() {
    this.store.dispatch(ExtLoadingEndAction());
    this.subscriptionOnboard = interval(this.CHECK_TIME)
      .pipe(
        switchMap(() =>
          this.onBoardingService.getOnBoarding().pipe(
            map((res) => {
              const required = this.onBoardingService.getMappedSteps(res.value.unfinishedSteps).find((step) => step.required);
              if (!required) {
                this.router.navigate(['/engageext']);
              }
            }),
          ),
        ),
      )
      .subscribe();
  }

  onClick() {
    window.open(`${environment.app_domain}/#/on-boarding/setup`, '_blank');
  }

  ngOnDestroy(): void {
    this.subscriptionOnboard.unsubscribe();
  }
}
