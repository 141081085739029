import { CommonModule as AngularCommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { EngageBtnV2Component } from '@zi-common/component/engage-btn-v2/engage-btn-v2.component';
import { EngageModeV2ExitDialogComponent } from '@zi-common/component/engage-mode-v2-exit-dialog/engage-mode-v2-exit-dialog.component';
import { LastTouchComponent } from '@zi-common/component/last-touch/last-touch.component';
import { StatusIndicatorComponent } from '@zi-common/component/status-indicator/status-indicator.component';
import { TabsV2Component } from '@zi-common/component/tabs-v2/tabs-v2.component';
import { ContactProfileSocialComponent } from '@zi-common/pages-component/contact-profile-social/contact-profile-social.component';
import { UpdateTagsButtonComponent } from './component/update-tags-button/update-tags-button.component';
import { FileMappingComponent } from './component/file-mapping/file-mapping.component';
import { ProgressBarComponent } from './component/progress-bar/progress-bar.component';
import { BreadcrumbComponent } from './component/breadcrumb/breadcrumb.component';
import { ContactScopeComponent } from '@zi-common/component/contact-scope/contact-scope.component';
import { ContentScopeComponent } from '@zi-common/component/content-scope/content-scope.component';
import { PrimaryBtnComponent } from '@zi-common/component/primary-btn/primary-btn.component';
import { PriorityLabelComponent } from '@zi-common/component/priority-label/priority-label.component';
import { CheckboxComponent } from './component/checkbox/checkbox.component';
import { DropdownComponent } from './component/dropdown/dropdown.component';
import { DottenTextComponent } from './component/dotten-text/dotten-text.component';
import { DatePicker2Component } from './component/date-picker-2/date-picker-2.component';
import { SecondaryBtnComponent } from './component/secondary-btn/secondary-btn.component';
import { ToggleButtonComponent } from './component/toggle-button/toggle-button.component';
import { PaginationComponent } from './component/pagination/pagination.component';
import { ChipsComponent } from './component/chips/chips.component';
import { DotsButtonComponent } from './component/dots-button/dots-button.component';
import { PopoverWindowComponent } from '@zi-common/component/popover/popover-window/popover-window.component';
import { PopoverDirective } from '@zi-common/component/popover/popover.directive';
import { PopoverServiceFactory } from '@zi-common/component/popover/popover.service';
import { DialogTemplateComponent } from './component/dialog-template/dialog-template.component';
import { DialogContentComponent } from './component/dialog-template/dialog-content/dialog-content.component';
import { DialogFooterComponent } from './component/dialog-template/dialog-footer/dialog-footer.component';
import { ToggleWithoutTextComponent } from './component/toggle-without-text/toggle-without-text.component';
import { DatePickerComponent } from './component/date-picker/date-picker.component';
import { EmptyStateComponent } from './component/empty-state/empty-state.component';
import { ComponentsListComponent } from './components-list/components-list.component';
import { DummyComponent } from './component/dialog-template/dummy/dummy.component';
import { ComponentWrapperComponent } from './components-list/component-wrapper/component-wrapper.component';
import { SelectComponent } from './component/select/select.component';
import { SortDropdownComponent } from './component/sort-dropdown/sort-dropdown.component';
import { FilterDropdownComponent } from './component/filter-dropdown/filter-dropdown.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FormSelectComponent } from './component/FormComponents/form-select/form-select.component';
import { TimePickerComponent } from './component/time-picker/time-picker.component';
import { ClickStopPropagationDirective } from '@zi-common/directive/click-stop-propagation/click-stop-propagation.directive';
import { FileBrowseBoxComponent } from './component/file-browse-box/file-browse-box.component';
import { TreeCheckboxComponent } from './component/tree-checkbox/tree-checkbox.component';
import { ListCheckboxComponent } from './component/list-checkbox/list-checkbox.component';
import { MultipleToggleButtonComponent } from './component/multiple-toggle-button/multiple-toggle-button.component';
import { SelectCheckboxComponent } from './component/select-checkbox/select-checkbox.component';
import { AutocompleteWithChipsComponent } from '@zi-common/component/autocomplete-with-chips/autocomplete-with-chips.component';
import { AutoCompleteService } from '@zi-common/service/autocomplete/autocomplete.service';
import { TemplateDropdownComponent } from './component/template-dropdown/template-dropdown.component';
import { InputSearchDropdownComponent } from './component/input-search-dropdown/input-search-dropdown.component';
import { SelectMultipleOptionsComponent } from './component/select-multiple-options/select-multiple-options.component';
import { EditInputComponent } from './component/editable-input/edit-input.component';
import { EmailConnectValidationDialogComponent } from './component/email-connect-validation-dialog/email-connect-validation-dialog.component';
import { ChipsManagerComponent } from './component/chips-manager/chips-manager.component';
import { SignatureEditorDialogComponent } from '@zi-common/component/signature-editor-dialog/signature-editor-dialog.component';
import { LabelChipComponent } from '@zi-common/component/label-chip/label-chip.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { ValidationErrorsComponent } from './component/validation-errors/validation-errors.component';
import { UnifiedVoicemails } from '@app/pages/account/components/unified-voicemail/unified-voicemails.component';

const materialModules = [
  MatTooltipModule,
  MatDialogModule,
  MatCheckboxModule,
  MatMenuModule,
  MatRadioModule,
  MatFormFieldModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatIconModule,
  MatInputModule,
  MatTreeModule,
  MatButtonModule,
  MatListModule,
  MatButtonToggleModule,
  MatSelectModule,
];
import { StepperComponent } from '@zi-common/component/stepper/stepper.component';
import { CategoriesFilterComponent } from './component/categories-filter/categories-filter.component';
import { MappingRowComponent } from './component/file-mapping/mapping-row/mapping-row.component';
import { StepsHostComponent } from './component/steps-host/steps-host.component';
import { StepHostDirective } from './component/steps-host/directive/step-host.directive';
import { BrowseBoxDragAndDropContentComponent } from './component/file-browse-box/content/drag-and-drop/drag-and-drop.component';
import { BrowseBoxDeleteSelectedContentComponent } from './component/file-browse-box/content/delete-selected/delete-selected.component';
import { RouterModule } from '@angular/router';
import { MenuDropdownComponent } from './component/menu-dropdown/menu-dropdown.component';
import { LoaderComponent } from './component/loader/loader.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { DateRangePickerComponent } from './component/date-range-picker/date-range-picker.component';
import { RowLoaderComponent } from './component/row-loader/row-loader.component';
import { SetDeliveryTimeComponent } from '@zi-common/component/set-delivery-time/set-delivery-time.component';
import { FroalaEditorService } from '@zi-common/service/froala-editor/froala-editor.service';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { StatsPanelComponent } from './component/stats-panel/stats-panel.component';
import { AddNoteComponent } from '@zi-common/component/add-note/add-note.component';
import { RadioButtonsComponent } from './component/radio-buttons/radio-buttons.component';
import { AudioPlayerComponent } from './component/audio-player/audio-player.component';
import { ChartContainerComponent } from './component/chart-container/chart-container.component';
import { ToggleIconButtonComponent } from './component/toggle-icon-button/toggle-icon-button.component';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';
import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/code_view.min.js';
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/js/plugins/fullscreen.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/url.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/inline_class.min.js';
import 'froala-editor/js/plugins/inline_style.min.js';
import 'froala-editor/js/plugins/word_paste.min.js';
import { ExpandableInputComponent } from './component/expandable-input/expandable-input.component';
import { CustomTooltipComponent } from '@zi-common/component/custom-tooltip/custom-tooltip.component';
import { CustomTooltipDirective } from '@zi-common/directive/customTooltip/customToottip.directive';
import { ErrorDialogComponent } from './component/error-dialog/error-dialog.component';
import { IconButtonComponent } from './component/icon-button/icon-button.component';
import { ContactsServiceService } from '@zi-common/service/contacts-service/contacts-service.service';
import { ElementRefDirective } from './directive/element-ref/element-ref.directive';
import { EmailPreviewComponent } from './pages-component/email-preview/email-preview.component';
import { EmailService } from '@app/pages/engage-mode-v2/service/email.service'; // TODO: Remove EmailService from here, only temporary
import { PrimaryBtnDropdownComponent } from './component/primary-btn-dropdown/primary-btn-dropdown.component';
import { DotsButtonDropdownComponent } from './component/dots-button/dots-button-dropdown/dots-button-dropdown.component';
import { RowsTableComponent } from './component/rows-table/rows-table.component';
import { EmailSignatureComponent } from './component/email-signature/email-signature.component';
import { BasicContainerComponent } from './component/basic-container/basic-container.component';
import { DoubleLayerContainerComponent } from './component/double-layer-container/double-layer-container.component';
import { BasicExpansionPanelComponent } from './component/basic-expansion-panel/basic-expansion-panel.component';
import { MonthNamePipe } from './pipe/month-name/month-name.pipe';
import { OnBoardingIndicatorComponent } from '@zi-common/component/on-boarding-indicator/on-boarding-indicator.component';
import { RangeDatePickerComponent } from './component/range-date-picker/range-date-picker.component';
import { DateRangePickerV2Component } from './component/date-range-picker-v2/date-range-picker-v2.component';
import { ListCheckboxV2Component } from './component/list-checkbox-v2/list-checkbox-v2.component';
import { IndicationsComponent } from './component/indications/indications.component';
import { DragDropListComponent } from '@zi-common/component/drag-drop-list/drag-drop-list.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AttachmentChipComponent } from './component/attachments-container/attachment-chip/attachment-chip.component';
import { AttachmentsContainerComponent } from './component/attachments-container/attachments-container.component';
import { FileDownloaderService } from '@zi-common/service/file-downloader-service/file-downloader.service';

import { ContactImageComponent } from './component/contact-image/contact-image.component';
import { CompanyImageComponent } from './component/company-image/company-image.component';
import { FilterExpandableContentComponent } from './component/filter-expandable-content/filter-expandable-content.component';
import { FilterNumberRangePickerComponent } from './component/filter-number-range-picker/filter-number-range-picker.component';
import { FroalaSignatureComponent } from './component/froala-signature/froala-signature.component';
import { EngageSettingsDialogComponent } from './component/engage-settings-dialog/engage-settings-dialog.component';
import { StringToDatePipe } from './pipe/string-to-date/string-to-date.pipe';
import { DateCardComponent } from './component/date-card/date-card.component';
import { AddInputComponent } from './component/add-input/add-input.component';
import { OverlayTooltipComponent } from './component/overlay-tooltip/overlay-tooltip.component';
import { FroalaEditorV2Component } from './component/froala-editor-v2/froala-editor-v2.component';
import { DurationPipe } from './pipe/duration/duration.pipe';
import { ButtonsGroupWithDatePickerComponent } from './component/buttons-group-with-datepicker/buttons-group-with-datepicker.component';
import { SectionSortDropdownComponent } from '@zi-common/component/section-sort-dropdown/section-sort-dropdown.component';
import { EditTextareaComponent } from './component/edit-textarea/edit-textarea.component';
import { FilterDropdownV2Component } from './component/filter-dropdown-v2/filter-dropdown-v2.component';
import { DotsButtonDropdownOptionsComponent } from './component/dots-button/dots-button-dropdown-options/dots-button-dropdown-options.component';
import { DoziSetupComponent } from '@app/pages/on-boarding/pages/on-boarding-setup/pages/dozi-setup/dozi-setup.component';
import { DoziIntegrationDialogComponent } from './pages-component/dozi-integration-dialog/dozi-integration-dialog.component';
import { AttachmentsContainerV2Component } from './component/attachments-container-v2/attachments-container-v2.component';
import { ContactEngagementComponent } from '@zi-common/component/contact-engagement/contact-engagement.component';
import { ConfidenceScoreComponent } from './component/confidence-score/confidence-score.component';
import { ScoopsService } from './service/scoops/scoops.service';
import { TimeHelperService } from './service/time-helper.service';
import { DoziRolesErrorComponent } from './component/dozi-roles-error/dozi-roles-error.component';
import { SelectOverlayComponent } from './component/select-overlay/select-overlay.component';
import { SelectOverlayOptionsComponent } from './component/select-overlay/select-overlay-options/select-overlay-options.component';
import { NewsService } from './service/news/news.service';
import { TechnologiesAndAttributesService } from './service/technologies-and-attributes/technologies-and-attributes.service';
import { ExpandableChipsContainerComponent } from './component/expandable-chips-container/expandable-chips-container.component';
import { BasicInputComponent } from './component/basic-input/basic-input.component';
import { VerifyDialerComponent } from '@app/caller/component/verify-dialer/verify-dialer.component';
import { RowLoaderFlexComponent } from './component/row-loader/row-loader-flex/row-loader-flex.component';
import { StatsColumnComponent } from './component/stats-column/stats-column.component';
import { ToggleButtonV2Component } from './component/toggle-button-v2/toggle-button-v2.component';
import { DoziAccountDialogComponent } from './pages-component/dozi-account-dialog/dozi-account-dialog.component';
import { LockedZoominfoDialogComponent } from './component/locked-zoominfo-dialog/locked-zoominfo-dialog.component';
import { DoziLoginWrapperComponent } from './pages-component/dozi-login-wrapper/dozi-login-wrapper.component';
import { StatsIndicatorComponent } from './component/stats-indicator/stats-indicator.component';
import { CallButtonComponent } from '@zi-common/component/call-button/call-button.component';
import { CallNumberService } from '@zi-common/service/call-number/call-number.service';
import { CallDropdownComponent } from '@zi-common/component/call-dropdown/call-dropdown.component';
import { DoziFeaturesDialogComponent } from './pages-component/dozi-features-dialog/dozi-features-dialog.component';
import { DoziContactUsDialogComponent } from './pages-component/dozi-contact-us-dialog/dozi-contact-us-dialog.component';
import { SelectMultipleOptionsWithSearchComponent } from './component/select-multiple-options-with-search/select-multiple-options-with-search.component';
import { SelectMultipleOptionsWithSearchAndChipsComponent } from './component/select-multiple-options-with-search-chips/select-multiple-options-with-search-chips.component';
import { UnderscoreReplacePipe } from '@zi-common/pipe/stringManipulation/underscore-replace.pipe';
import { ZoomAutomationModule } from '@app/zoom-automation/zoom-automation.module';
import { PrimaryIconButtonComponent } from './component/primary-icon-button/primary-icon-button.component';
import { ChipsKeyListComponent } from './component/chips-key-list/chips-key-list.component';
import { SalesflowEmailTemplateService } from '@zi-pages/salesflow/services/salesflow-email-template.service';
import { RangeToNumberPipe } from './pipe/stringManipulation/range-to-number.pipe';
import { CustomNamePipe } from './pipe/stringManipulation/custom-name.pipe';
import { MultipleSelectChipsSearchComponent } from './component/multiple-select-chips-search/multiple-select-chips-search.component';
import { ChangeContactStatusDialogComponent } from '@app/common/pages-component/change-contact-status-dialog/change-contact-status-dialog.component';
import { ImportWizardComponent } from './component/import-wizard/import-wizard/import-wizard.component';
import { ImportWizardStepComponent } from './component/import-wizard/import-wizard-step/import-wizard-step.component';
import { ConfirmEngagePhoneDialogComponent } from './component/contact-replace-dialog/contact-replace-import/confirm-engage-phone-dialog/confirm-engage-phone-dialog.component';
import { DottenTextCharLimitComponent } from './component/dotten-text-char-limit/dotten-text-char-limit.component';
import { ToggleIconWithoutBackgroundComponent } from '@zi-common/component/toggle-icon-without-background/toggle-icon-without-background.component';
import { SelectOptionFilterPipe } from '@zi-common/pipe/select-option-filter/select-option-filter.pipe';
import { ExpandableSearchComponent } from '@zi-common/component/expandable-search/expandable-search.component';
import { CompanyAccountsIntegrationService } from '@zi-common/service/company-accounts-integration/company-accounts-integration.service';
import { SideNoteBoxComponent } from '@zi-common/component/side-note-box/side-note-box.component';
import { CircleNameInitialsComponent } from '@zi-common/component/circle-name-initials/circle-name-initials.component';
import { OrderNotesPipe } from '@zi-common/pipe/order-notes.pipe';
import { NewNoteDialogComponent } from '@zi-common/component/new-note-dialog/new-note-dialog.component';
import { DropdownV2Component } from './component/dropdown-v2/dropdown-v2.component';
import { CrmSetupComponent } from '@app/pages/on-boarding/pages/on-boarding-setup/pages/crm-setup/crm-setup.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ShowMoreLessComponent } from './component/show-more-less/show-more-less.component';
import { OnBoardingStepComponent } from '@zi-pages/on-boarding/pages/on-boarding-setup/pages/on-boarding-step/on-boarding-step.component';
import { CompanyAccountImageComponent } from './component/company-account-image/company-account-image.component';
import { AbbreviateNumberPipe } from './pipe/abbreviate-number/abbreviate-number.pipe';
import { SeparateAllNumbersPipe } from './pipe/separate-all-numbers/separate-all-numbers.pipe';
import { LocationDropdownComponent } from './component/location-dropdown/location-dropdown.component';
import { ShowMoreTextComponent } from '@zi-common/component/show-more-text/show-more-text.component';
import { PossessiveNamePipe } from './pipe/possessive-name/possessive-name.pipe';
import { TextFieldModule } from '@angular/cdk/text-field';
import { BackButtonBreadcrumbComponent } from './component/back-button-breadcrumb/back-button-breadcrumb.component';
import { ToggleButtonV3Component } from '@app/common/component/toggle-button-v3/toggle-button-v3.component';
import { RoundNumberPipe } from './pipe/round-number/round-number.pipe';
import { IntegrationDisconnectDialogComponent } from '@app/pages/account/components/integration-disconnect-dialog/integration-disconnect-dialog.component';
import { ProperTitlecasePipe } from './pipe/proper-titlecase/proper-titlecase.pipe';
import { NoVoipNumberPanelComponent } from '@app/caller/component/no-voip-number-panel/no-voip-number-panel.component';
import { VoipChooseNumberDialogComponent } from '@zi-pages/account/components/voip-choose-number-dialog/voip-choose-number-dialog.component';
import { LottieModule } from 'ngx-lottie';
import { TextAreaAutosizeDirective } from '@zi-common/directive/text-area-autosize.directive';
import { EngageVersionStringTransformDirective } from '@zi-common/directive/engage-version-string-transform/engage-version-string-transform.directive';
import { EngageVersionStringTransformPipe } from '@zi-common/pipe/engage-version-string-transform/engage-version-string-transform.pipe';
import { VoipSettingsComponent } from '@zi-pages/account/components/voip-settings/voip-settings.component';
import { AudioDeviceSettingsComponent } from '@zi-pages/account/components/audio-device-settings/audio-device-settings.component';
import { DialerVoicemailRowV2Component } from '@zi-pages/account/components/dialer-voicemail-row-v2/dialer-voicemail-row-v2.component';
import { ToggleButtonV4Component } from './component/toggle-button-v4/toggle-button-v4.component';
import { ExpandableTextComponent } from './component/expandable-text/expandable-text.component';
import { OrderByPipe } from './pipe/order-by/order-by.pipe';
import { VerticalScrollBoxDirective } from './directive/scroll-box/vertical-scroll-box.directive';
import { TableHeaderComponent } from './component/table-header/table-header.component';
import { TimePickerAutoCompleteComponent } from './component/time-picker-auto-complete/time-picker-auto-complete.component';
import { SetDeliveryTimeV2Component } from '@zi-common/component/set-delivery-time-v2/set-delivery-time-v2.component';
import { SidebarMenuComponent } from './component/sidebar-menu/sidebar-menu.component';
import { NumberRangeComponent } from './component/number-range/number-range.component';
import { DropdownDetailComponent } from './component/dropdown-detail/dropdown-detail.component';
import { DropdownNumericComponent } from './component/dropdown-numeric/dropdown-numeric.component';
import { MergeVariableDialogComponent } from './component/merge-variable-dialog/merge-variable-dialog.component';
import { FilterContactTeamComponent } from './component/filter-contact-team/filter-contact-team.component';
import { EmailTemplateStatisticsComponent } from './component/email-template-statistics/email-template-statistics.component';
import { DynamicsOrgDialogComponent } from '../pages/account/components/integration-dialogs/dynamics/dynamics-org-dialog/dynamics-org-dialog';
import { DeliveryTimeSelectorComponent } from '@zi-common/component/delivery-time-selector/delivery-time-selector.component';
import { WizardLandingPageComponent } from './component/wizard-landing-page/wizard-landing-page.component';
import { PhoneStatusComponent } from './component/phone-status/phone-status.component';
import { SelectEditOverlayOptionsComponent } from './component/select-edit-overlay/select-overlay-options/select-overlay-options.component';
import { SelectEditOverlayComponent } from './component/select-edit-overlay/select-edit-overlay.component';
import { CalendarLinkComponent } from './component/calendar-link/calendar-link.component';
import { AddressValidationDialogComponent } from '@app/pages/account/components/address-validation-dialog/address-validation-dialog.component';
import { InsightIndicatorsComponent } from '@zi-common/component/insight-indicators/insight-indicators.component';
import { SafeValuePipe } from './pipe/safe-value/safe-value.pipe';
import { RoundPercentagePipe } from '@zi-common/pipe/round-percentage.pipe';
import { IsEmptyNumPipe } from '@zi-common/pipe/is-empty-num.pipe';
import { SalesflowPreviewDropdownComponent } from '@zi-common/component/salesflow-preview-dropdown/salesflow-preview-dropdown.component';
import { SalesflowPreviewDropdownRowComponent } from '@zi-common/component/salesflow-preview-dropdown/salesflow-preview-dropdown-row/salesflow-preview-dropdown-row.component';
import { SalesflowStepsDiagramFlowComponent } from '@zi-common/component/salesflow-steps-diagram-flow/salesflow-steps-diagram-flow.component';
import { ContactTagsComponent } from '@zi-common/component/tags/contact-tags.component';
import { EmailPreviewContainerComponent } from '@zi-common/component/email-preview-container/email-preview-container.component';
import { TabsComponent } from '@zi-common/component/tabs/tabs.component';
import { SafeHtmlPipe } from '@zi-common/pipe/safe-value/safe-html.pipe';
import { ContactSalesforceSyncOverlayComponent } from '@zi-common/component/contact-salesforce-sync-overlay/contact-salesforce-sync-overlay.component';
import { ToggleButtonV5Component } from './component/toggle-button-v5/toggle-button-v5.component';
import { SafeValueTrustedPipe } from './pipe/safe-value/safe-value-trusted.pipe';
import { InputValidationTriggerDirective } from '@zi-common/directive/input-validation-trigger/input-validation-trigger.directive';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { EmptyStateHorizontalComponent } from './component/empty-state-horizontal/empty-state-horizontal.component';
import { PaginationCountComponent } from './component/pagination-count/pagination-count.component';
import { ResultsTableComponent } from '@zi-common/component/results-table/results-table.component';
import { InfoLoaderComponent } from '@zi-common/component/info-loader/info-loader.component';
import { HeaderSelectorComponent } from '@zi-common/component/header-selector/header-selector.component';
import { RefreshNeverBounceDialogComponent } from '@app/pages/admin/pages/org-management/neverbounce/refresh-dialog/refresh-dialog.component';
import { NeverbounceResultsComponent } from './pages-component/neverbounce-results/neverbounce-results.component';
import { DonutChartComponent } from './pages-component/charts/donut-chart/donut-chart.component';
import { ZiTableModule } from '@zoominfo/component-library/components/table';
import { TimePickerModule } from '@zoominfo/component-library/components/time-picker';
import { SelectMultipleOptionsNoDropdownComponent } from '@zi-common/component/select-multiple-options-no-dropdown/select-multiple-options-no-dropdown.component';
import { AutoInitialFocusDirective } from './directive/auto-initial-focus/auto-initial-focus.directive';
import { DoziOktaIntegrationDialogComponent } from '@zi-common/pages-component/dozi-okta-integration-dialog/dozi-okta-integration-dialog.component';
import { SelectDropdownContentComponent } from '@zi-common/component/select/component/select-dropdown-content.component';
import { SelectMultiSortComponent } from './component/select-multi-sort/select-multi-sort.component';
import { MultisortAvailableOptionsPipe } from './pipe/multisort-available-options/multisort-available-options.pipe';
import { DateTimeZonePipe } from '@zi-common/pipe/timezone-name-to-offset/date-timezone.pipe';
import { CastTypePipe } from '@zi-common/pipe/cast-type.pipe';
import { DecodeHtmlEntitiesPipe } from '@zi-common/pipe/unescape/decode-html-entities.pipe';
import { AddToSalesflowBtnComponent } from './component/add-to-salesflow-btn/add-to-salesflow-btn.component';

@NgModule({
  declarations: [
    UnifiedVoicemails,
    BasicInputComponent,
    IntegrationDisconnectDialogComponent,
    MonthNamePipe,
    StringToDatePipe,
    DateTimeZonePipe,
    DateRangePickerV2Component,
    RangeDatePickerComponent,
    BasicExpansionPanelComponent,
    DoubleLayerContainerComponent,
    BasicContainerComponent,
    RowsTableComponent,
    ExpandableInputComponent,
    StatsPanelComponent,
    UpdateTagsButtonComponent,
    BrowseBoxDeleteSelectedContentComponent,
    BrowseBoxDragAndDropContentComponent,
    StepsHostComponent,
    StepHostDirective,
    FileMappingComponent,
    MappingRowComponent,
    ProgressBarComponent,
    FileBrowseBoxComponent,
    BreadcrumbComponent,
    ContactScopeComponent,
    ContentScopeComponent,
    CheckboxComponent,
    DropdownComponent,
    ToggleButtonComponent,
    ToggleButtonV2Component,
    ToggleWithoutTextComponent,
    PriorityLabelComponent,
    DottenTextComponent,
    DatePicker2Component,
    SecondaryBtnComponent,
    PrimaryBtnComponent,
    AddNoteComponent,
    AddInputComponent,
    PaginationComponent,
    ChipsComponent,
    ChipsManagerComponent,
    ExpandableChipsContainerComponent,
    DotsButtonComponent,
    PopoverWindowComponent,
    NeverbounceResultsComponent,
    PopoverDirective,
    DonutChartComponent,
    ElementRefDirective,
    DialogTemplateComponent,
    DialogContentComponent,
    DialogFooterComponent,
    DatePickerComponent,
    CustomTooltipComponent,
    EmptyStateComponent,
    ComponentsListComponent,
    DummyComponent,
    ErrorDialogComponent,
    EngageSettingsDialogComponent,
    ComponentWrapperComponent,
    SelectComponent,
    SelectDropdownContentComponent,
    SortDropdownComponent,
    FilterDropdownComponent,
    FilterDropdownV2Component,
    TreeCheckboxComponent,
    FormSelectComponent,
    TimePickerComponent,
    AutocompleteWithChipsComponent,
    ClickStopPropagationDirective,
    CustomTooltipDirective,
    EngageVersionStringTransformDirective,
    ListCheckboxComponent,
    MultipleToggleButtonComponent,
    SelectCheckboxComponent,
    StepperComponent,
    EngageBtnV2Component,
    EngageModeV2ExitDialogComponent,
    TemplateDropdownComponent,
    InputSearchDropdownComponent,
    CategoriesFilterComponent,
    SelectMultipleOptionsComponent,
    SelectMultipleOptionsNoDropdownComponent,
    EditInputComponent,
    EditTextareaComponent,
    MenuDropdownComponent,
    LoaderComponent,
    DateRangePickerComponent,
    RowLoaderComponent,
    EmailPreviewComponent,
    SetDeliveryTimeComponent,
    SetDeliveryTimeV2Component,
    DeliveryTimeSelectorComponent,
    RadioButtonsComponent,
    AudioPlayerComponent,
    ChartContainerComponent,
    ToggleIconButtonComponent,
    DotsButtonDropdownComponent,
    IconButtonComponent,
    ContactProfileSocialComponent,
    PrimaryBtnDropdownComponent,
    EmailSignatureComponent,
    OnBoardingIndicatorComponent,
    EmailConnectValidationDialogComponent,
    ListCheckboxV2Component,
    LocationDropdownComponent,
    ContactEngagementComponent,
    IndicationsComponent,
    DragDropListComponent,
    AttachmentChipComponent,
    AttachmentsContainerComponent,
    AttachmentsContainerV2Component,
    ContactImageComponent,
    CompanyImageComponent,
    FilterExpandableContentComponent,
    FilterNumberRangePickerComponent,
    FroalaSignatureComponent,
    SignatureEditorDialogComponent,
    LabelChipComponent,
    DateCardComponent,
    ButtonsGroupWithDatePickerComponent,
    OverlayTooltipComponent,
    SectionSortDropdownComponent,
    DurationPipe,
    DoziRolesErrorComponent,
    DotsButtonDropdownOptionsComponent,
    ConfidenceScoreComponent,
    FroalaEditorV2Component,
    CrmSetupComponent,
    DoziSetupComponent,
    DoziIntegrationDialogComponent,
    DoziOktaIntegrationDialogComponent,
    SelectOverlayComponent,
    SelectOverlayOptionsComponent,
    SelectEditOverlayComponent,
    SelectEditOverlayOptionsComponent,
    VerifyDialerComponent,
    NoVoipNumberPanelComponent,
    RowLoaderFlexComponent,
    StatsColumnComponent,
    StatsIndicatorComponent,
    DoziAccountDialogComponent,
    LockedZoominfoDialogComponent,
    DoziFeaturesDialogComponent,
    DoziLoginWrapperComponent,
    CallButtonComponent,
    CallDropdownComponent,
    DoziContactUsDialogComponent,
    ChipsKeyListComponent,
    SelectMultipleOptionsWithSearchComponent,
    SelectMultipleOptionsWithSearchAndChipsComponent,
    ShowMoreTextComponent,
    CalendarLinkComponent,
    AddressValidationDialogComponent,
    EmailPreviewContainerComponent,
    TabsComponent,
    TabsV2Component,
    // pipes
    SafeValuePipe,
    SafeHtmlPipe,
    SafeValueTrustedPipe,
    UnderscoreReplacePipe,
    RangeToNumberPipe,
    CustomNamePipe,
    SelectOptionFilterPipe,
    OrderNotesPipe,
    DecodeHtmlEntitiesPipe,
    MultipleSelectChipsSearchComponent,
    ChangeContactStatusDialogComponent,
    PrimaryIconButtonComponent,
    ConfirmEngagePhoneDialogComponent,
    ImportWizardComponent,
    ImportWizardStepComponent,
    DottenTextCharLimitComponent,
    ToggleIconWithoutBackgroundComponent,
    ExpandableSearchComponent,
    SideNoteBoxComponent,
    CircleNameInitialsComponent,
    DropdownV2Component,
    CircleNameInitialsComponent,
    NewNoteDialogComponent,
    PossessiveNamePipe,
    ShowMoreLessComponent,
    CompanyAccountImageComponent,
    AbbreviateNumberPipe,
    SeparateAllNumbersPipe,
    StatusIndicatorComponent,
    OnBoardingStepComponent,
    LastTouchComponent,
    BackButtonBreadcrumbComponent,
    ToggleButtonV3Component,
    ToggleButtonV4Component,
    ToggleButtonV5Component,
    RoundNumberPipe,
    RoundPercentagePipe,
    IsEmptyNumPipe,
    ProperTitlecasePipe,
    VoipChooseNumberDialogComponent,
    TextAreaAutosizeDirective,
    RefreshNeverBounceDialogComponent,
    EngageVersionStringTransformPipe,
    VoipSettingsComponent,
    AudioDeviceSettingsComponent,
    DialerVoicemailRowV2Component,
    ExpandableTextComponent,
    VerticalScrollBoxDirective,
    OrderByPipe,
    TableHeaderComponent,
    TimePickerAutoCompleteComponent,
    NumberRangeComponent,
    SidebarMenuComponent,
    FilterContactTeamComponent,
    DropdownDetailComponent,
    DropdownNumericComponent,
    EmailTemplateStatisticsComponent,
    MergeVariableDialogComponent,
    DynamicsOrgDialogComponent,
    WizardLandingPageComponent,
    PhoneStatusComponent,
    InsightIndicatorsComponent,
    SalesflowPreviewDropdownComponent,
    SalesflowPreviewDropdownRowComponent,
    SalesflowStepsDiagramFlowComponent,
    ContactTagsComponent,
    ValidationErrorsComponent,
    ContactSalesforceSyncOverlayComponent,
    ResultsTableComponent,
    InfoLoaderComponent,
    HeaderSelectorComponent,
    EmptyStateHorizontalComponent,
    PaginationCountComponent,
    InputValidationTriggerDirective,
    AutoInitialFocusDirective,
    SelectMultiSortComponent,
    MultisortAvailableOptionsPipe,
    CastTypePipe,
    AddToSalesflowBtnComponent,
  ],
  imports: [
    RouterModule,
    AngularCommonModule,
    ScrollingModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    FormsModule,
    MatDialogModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatIconModule,
    // Material library
    ...materialModules,
    NgxDaterangepickerMd.forRoot(),
    MatSliderModule,
    DragDropModule,
    ZoomAutomationModule,
    NgxSliderModule,
    TextFieldModule,
    LottieModule,
    TimePickerModule,
    ZiTableModule,
  ],
  exports: [
    UnifiedVoicemails,
    BasicInputComponent,
    MonthNamePipe,
    StringToDatePipe,
    DateTimeZonePipe,
    OrderByPipe,
    DateRangePickerV2Component,
    RangeDatePickerComponent,
    BasicExpansionPanelComponent,
    DoubleLayerContainerComponent,
    BasicContainerComponent,
    RowsTableComponent,
    ExpandableInputComponent,
    StatsPanelComponent,
    UpdateTagsButtonComponent,
    LocationDropdownComponent,
    BrowseBoxDeleteSelectedContentComponent,
    BrowseBoxDragAndDropContentComponent,
    FileMappingComponent,
    ProgressBarComponent,
    FileBrowseBoxComponent,
    BreadcrumbComponent,
    ContactScopeComponent,
    ContentScopeComponent,
    ToggleButtonComponent,
    ToggleButtonV2Component,
    ToggleWithoutTextComponent,
    CheckboxComponent,
    DropdownComponent,
    PriorityLabelComponent,
    DottenTextComponent,
    DatePicker2Component,
    SecondaryBtnComponent,
    PrimaryBtnComponent,
    AddNoteComponent,
    AddInputComponent,
    PaginationComponent,
    ChipsComponent,
    ChipsManagerComponent,
    ExpandableChipsContainerComponent,
    DotsButtonComponent,
    LoaderComponent,
    PopoverWindowComponent,
    PopoverDirective,
    ElementRefDirective,
    NgxSliderModule,
    DialogTemplateComponent,
    DialogContentComponent,
    DialogFooterComponent,
    DatePickerComponent,
    CustomTooltipComponent,
    EmptyStateComponent,
    SelectComponent,
    SelectDropdownContentComponent,
    SortDropdownComponent,
    FilterDropdownComponent,
    FilterDropdownV2Component,
    TreeCheckboxComponent,
    FormSelectComponent,
    TimePickerComponent,
    MatCheckboxModule,
    MatSelectModule,
    MatMenuModule,
    MatRadioModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    AutocompleteWithChipsComponent,
    MatTabsModule,
    MatStepperModule,
    ClickStopPropagationDirective,
    CustomTooltipDirective,
    EngageVersionStringTransformDirective,
    ListCheckboxComponent,
    MultipleToggleButtonComponent,
    SelectCheckboxComponent,
    EngageBtnV2Component,
    EngageModeV2ExitDialogComponent,
    TemplateDropdownComponent,
    StepperComponent,
    CategoriesFilterComponent,
    StepsHostComponent,
    StepperComponent,
    StepperComponent,
    SelectMultipleOptionsComponent,
    SelectMultipleOptionsNoDropdownComponent,
    EditInputComponent,
    EditTextareaComponent,
    MenuDropdownComponent,
    InputSearchDropdownComponent,
    DateRangePickerComponent,
    RowLoaderComponent,
    EmailPreviewComponent,
    SetDeliveryTimeComponent,
    SetDeliveryTimeV2Component,
    DeliveryTimeSelectorComponent,
    MatInputModule,
    RadioButtonsComponent,
    MatInputModule,
    AudioPlayerComponent,
    ChartContainerComponent,
    MatInputModule,
    ToggleIconButtonComponent,
    IconButtonComponent,
    PrimaryBtnDropdownComponent,
    DotsButtonDropdownComponent,
    ContactProfileSocialComponent,
    EmailSignatureComponent,
    ListCheckboxV2Component,
    ContactEngagementComponent,
    IndicationsComponent,
    DragDropListComponent,
    AttachmentsContainerComponent,
    AttachmentsContainerV2Component,
    ContactImageComponent,
    CompanyImageComponent,
    FilterExpandableContentComponent,
    FilterNumberRangePickerComponent,
    FroalaSignatureComponent,
    SignatureEditorDialogComponent,
    DateCardComponent,
    LabelChipComponent,
    OverlayTooltipComponent,
    ButtonsGroupWithDatePickerComponent,
    SectionSortDropdownComponent,
    DurationPipe,
    ConfidenceScoreComponent,
    FroalaEditorV2Component,
    DoziSetupComponent,
    CrmSetupComponent,
    DoziIntegrationDialogComponent,
    DoziOktaIntegrationDialogComponent,
    SelectOverlayOptionsComponent,
    SelectOverlayComponent,
    SelectEditOverlayOptionsComponent,
    SelectEditOverlayComponent,
    VerifyDialerComponent,
    NoVoipNumberPanelComponent,
    RowLoaderFlexComponent,
    StatsColumnComponent,
    StatsIndicatorComponent,
    DoziAccountDialogComponent,
    LockedZoominfoDialogComponent,
    DoziFeaturesDialogComponent,
    DoziLoginWrapperComponent,
    CallButtonComponent,
    CallDropdownComponent,
    ChipsKeyListComponent,
    DoziContactUsDialogComponent,
    DoziRolesErrorComponent,
    ShowMoreTextComponent,
    VoipChooseNumberDialogComponent,
    ExpandableTextComponent,
    CalendarLinkComponent,
    AddressValidationDialogComponent,
    EmailPreviewContainerComponent,
    TabsComponent,
    TabsV2Component,
    PaginationCountComponent,
    // pipes
    SafeValuePipe,
    SafeHtmlPipe,
    SafeValueTrustedPipe,
    SelectOptionFilterPipe,
    OrderNotesPipe,
    SelectMultipleOptionsWithSearchComponent,
    SelectMultipleOptionsWithSearchAndChipsComponent,
    MultipleSelectChipsSearchComponent,
    PrimaryIconButtonComponent,
    ImportWizardComponent,
    ImportWizardStepComponent,
    DottenTextCharLimitComponent,
    ToggleIconWithoutBackgroundComponent,
    ExpandableSearchComponent,
    SideNoteBoxComponent,
    CircleNameInitialsComponent,
    DropdownV2Component,
    PossessiveNamePipe,
    OnBoardingStepComponent,
    AbbreviateNumberPipe,
    SeparateAllNumbersPipe,
    CompanyAccountImageComponent,
    ShowMoreLessComponent,
    StatusIndicatorComponent,
    LastTouchComponent,
    BackButtonBreadcrumbComponent,
    ToggleButtonV3Component,
    ToggleButtonV4Component,
    ToggleButtonV5Component,
    ProperTitlecasePipe,
    RoundNumberPipe,
    RoundPercentagePipe,
    IsEmptyNumPipe,
    TextAreaAutosizeDirective,
    EngageVersionStringTransformPipe,
    DecodeHtmlEntitiesPipe,
    VoipSettingsComponent,
    AudioDeviceSettingsComponent,
    DialerVoicemailRowV2Component,
    TableHeaderComponent,
    VerticalScrollBoxDirective,
    TimePickerAutoCompleteComponent,
    SidebarMenuComponent,
    FilterContactTeamComponent,
    DropdownDetailComponent,
    NumberRangeComponent,
    EmailTemplateStatisticsComponent,
    WizardLandingPageComponent,
    PhoneStatusComponent,
    InsightIndicatorsComponent,
    SalesflowPreviewDropdownComponent,
    SalesflowPreviewDropdownRowComponent,
    SalesflowStepsDiagramFlowComponent,
    ContactTagsComponent,
    ValidationErrorsComponent,
    ContactSalesforceSyncOverlayComponent,
    EmptyStateHorizontalComponent,
    InputValidationTriggerDirective,
    ResultsTableComponent,
    InfoLoaderComponent,
    HeaderSelectorComponent,
    DonutChartComponent,
    NeverbounceResultsComponent,
    AutoInitialFocusDirective,
    SelectMultiSortComponent,
    CastTypePipe,
    AddToSalesflowBtnComponent,
  ],
  providers: [
    ScoopsService,
    PopoverServiceFactory,
    AutoCompleteService,
    FroalaEditorService,
    ContactsServiceService,
    EmailService,
    FileDownloaderService,
    NewsService,
    TechnologiesAndAttributesService,
    CallNumberService,
    TimeHelperService,
    SalesflowEmailTemplateService,
    CompanyAccountsIntegrationService,
    PossessiveNamePipe,
    SafeValuePipe,
    SafeHtmlPipe,
    SafeValueTrustedPipe,
  ], // TODO: Remove EmailService from here, only temporary
  entryComponents: [
    IntegrationDisconnectDialogComponent,
    PopoverWindowComponent,
    DummyComponent,
    ErrorDialogComponent,
    EngageSettingsDialogComponent,
    EmailPreviewComponent,
    CustomTooltipComponent,
    DotsButtonDropdownComponent,
    EmailConnectValidationDialogComponent,
    SignatureEditorDialogComponent,
    EngageModeV2ExitDialogComponent,
    DoziIntegrationDialogComponent,
    DoziOktaIntegrationDialogComponent,
    SelectOverlayOptionsComponent,
    SelectEditOverlayOptionsComponent,
    VerifyDialerComponent,
    NoVoipNumberPanelComponent,
    DoziAccountDialogComponent,
    LockedZoominfoDialogComponent,
    DoziFeaturesDialogComponent,
    DoziContactUsDialogComponent,
    ChangeContactStatusDialogComponent,
    ConfirmEngagePhoneDialogComponent,
    NewNoteDialogComponent,
    ConfirmEngagePhoneDialogComponent,
    VoipChooseNumberDialogComponent,
    RefreshNeverBounceDialogComponent,
    MergeVariableDialogComponent,
    DynamicsOrgDialogComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CommonModule {}
