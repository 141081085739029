<app-dialog-template class="dozi-header" hasCloseIcon="true" [hasGoBackIcon]="false" (close)="onCloseDialog()">
  <app-dialog-content class="dozi-content">
    <div class="input dozi-title">{{doziTitle}}</div>

    <div class="sign-in-data">Signing in with user <span class="sign-in-data-username">{{doziUsername}}</span></div>

    <div>
      <app-primary-btn class="primary-button dozi-button" text="Sign In" (clickButton)="oktaLogin()"> </app-primary-btn>
    </div>

    <div class="error-message" *ngIf="errorMessage">{{errorMessage}}</div>

    <div class="cred-disclaimer-btn">
      <div class="input-verification">
        <div class="no-dozi-account-info">Don't have a {{engageVersionText}}OS account?</div>
      </div>
      <div class="input-verification">
        <div class="get-dozi-account">
          <div (click)="checkTrial()">Get free access to {{engageVersionText}}OS</div>
        </div>
      </div>
    </div>
  </app-dialog-content>
</app-dialog-template>
