import {
  ClearMultiFactorAuthTokenAction,
  ClearUserZoominfoDetailsAction,
  GetUserWebsiteDomainListAction,
  GetUserWebsiteDomainListFailureAction,
  GetUserWebsiteDomainListSuccessAction,
  GetUserZoominfoDetailsSuccessAction,
  LoadConfigurationCRMSuccess,
  LoadConnectedServicesSuccess,
  LoadOrgConnectedSuccess,
  LoadTwilioClientTokenAction,
  LoadTwilioClientTokenFailureAction,
  LoadTwilioClientTokenSuccessAction,
  LoginSuccessAction,
  LogoutAction,
  ProvisionMultiFactorAuthAction,
  RefreshTokenAction,
  RefreshTokenSuccessAction,
  ResetRememberMeData,
  SetAuthenticatedToken,
  SetRulesEngineToken,
  SetUserLicensesFailureAction,
  SetUserLicensesSuccessAction,
  UpdateOrgSalesflowQuotaAction,
  UpdateProfile,
  UpdateRecruiterUserLicenseAction,
  VerifyOneTimePasswordAction,
} from '@zi-core/ngrx/action/auth.action';
import { Action, createReducer, on } from '@ngrx/store';
import { AuthenticatedTokenState, initialAuthenticatedTokenState } from '@zi-core/ngrx/state/auth.state';
import { failureLoadedState, loadingState, successLoadedState } from '@zi-core/ngrx/config/ngrx-loading-states.config';
import { Organization } from '@app/core/http-model/response/organization.model';

const authReducer = createReducer(
  initialAuthenticatedTokenState,
  // todo: Update later to store only required data from the authenticated token in the store
  on(LoginSuccessAction, (state: AuthenticatedTokenState, { authToken, email, toRemember, profile }) => {
    let isOrgAdmin;
    if (authToken != null) {
      isOrgAdmin = authToken.organizations.filter((org) => org.id === authToken.orgId && org.isOrgAdmin).length > 0;
    }

    return {
      ...state,
      authenticatedToken: authToken,
      isOrgAdmin,
      rememberMeData: {
        username: email,
        needToRemember: toRemember,
      },
      profile,
    };
  }),
  on(LogoutAction, (state: AuthenticatedTokenState) => {
    return { ...initialAuthenticatedTokenState, rememberMeData: state.rememberMeData };
  }),
  on(SetAuthenticatedToken, (state: AuthenticatedTokenState, { payload }) => {
    return { ...state, authenticatedToken: payload };
  }),
  on(SetRulesEngineToken, (state: AuthenticatedTokenState, { payload }) => {
    return { ...state, rulesEngineToken: payload };
  }),
  on(ResetRememberMeData, (state: AuthenticatedTokenState) => {
    return { ...initialAuthenticatedTokenState, rememberMeData: null };
  }),
  on(RefreshTokenAction, (state: AuthenticatedTokenState) => {
    return { ...state };
  }),
  on(RefreshTokenSuccessAction, (state: AuthenticatedTokenState, { authenticatedToken }) => {
    return { ...state, authenticatedToken };
  }),
  on(UpdateProfile, (state: AuthenticatedTokenState, { profile }) => {
    return { ...state, profile };
  }),
  on(GetUserZoominfoDetailsSuccessAction, (state: AuthenticatedTokenState, { zoomUser }) => {
    return { ...state, zoomConnected: true, zoomUser };
  }),
  on(ClearUserZoominfoDetailsAction, (state: AuthenticatedTokenState) => {
    return { ...state, zoomConnected: false, zoomUser: null };
  }),
  on(LoadConnectedServicesSuccess, (state: AuthenticatedTokenState, { connectedServices }) => {
    return { ...state, connectedServices };
  }),
  on(LoadOrgConnectedSuccess, (state: AuthenticatedTokenState, { org }) => {
    return { ...state, org };
  }),
  on(LoadConfigurationCRMSuccess, (state: AuthenticatedTokenState, { configurationCRM }) => {
    return { ...state, configurationCRM };
  }),
  on(ProvisionMultiFactorAuthAction, (state: AuthenticatedTokenState, { multiFactorAuthJwt }) => {
    return { ...state, multiFactorAuthJwt };
  }),
  on(VerifyOneTimePasswordAction, (state: AuthenticatedTokenState, { multiFactorAuthJwt }) => {
    return { ...state, multiFactorAuthJwt };
  }),
  on(ClearMultiFactorAuthTokenAction, (state: AuthenticatedTokenState) => {
    return { ...state, multiFactorAuthJwt: null };
  }),
  on(GetUserWebsiteDomainListAction, (state: AuthenticatedTokenState) => {
    return { ...state, websiteDomains: [], websiteDomainsLoaded: false, websiteDomainsLoading: true };
  }),
  on(GetUserWebsiteDomainListSuccessAction, (state: AuthenticatedTokenState, { websiteDomains }) => {
    return { ...state, websiteDomains, websiteDomainsLoaded: true, websiteDomainsLoading: false };
  }),
  on(GetUserWebsiteDomainListFailureAction, (state: AuthenticatedTokenState) => {
    return { ...state, websiteDomains: [], websiteDomainsLoaded: true, websiteDomainsLoading: false };
  }),
  on(LoadTwilioClientTokenAction, (state: AuthenticatedTokenState) => {
    return {
      ...state,
      twilioClientToken: {
        token: undefined,
        ...loadingState,
      },
    };
  }),

  on(LoadTwilioClientTokenSuccessAction, (state: AuthenticatedTokenState, { token }) => {
    return {
      ...state,
      twilioClientToken: {
        token,
        ...successLoadedState,
      },
    };
  }),

  on(LoadTwilioClientTokenFailureAction, (state: AuthenticatedTokenState, { error }) => {
    return {
      ...state,
      twilioClientToken: {
        token: undefined,
        ...failureLoadedState,
        error,
      },
    };
  }),

  on(SetUserLicensesSuccessAction, (state: AuthenticatedTokenState, { licenses }) => {
    return {
      ...state,
      userLicenses: {
        licenses,
        ...successLoadedState,
      },
    };
  }),

  on(SetUserLicensesFailureAction, (state: AuthenticatedTokenState, { error }) => {
    return {
      ...state,
      userLicenses: {
        licenses: null,
        ...failureLoadedState,
        error,
      },
    };
  }),

  on(UpdateRecruiterUserLicenseAction, (state: AuthenticatedTokenState, { hasRecruiterLicense }) => {
    return {
      ...state,
      userLicenses: {
        ...state.userLicenses,
        licenses: {
          ...state.userLicenses.licenses,
          recruiter: hasRecruiterLicense,
        },
      },
    };
  }),

  on(UpdateOrgSalesflowQuotaAction, (state: AuthenticatedTokenState, { salesflowQuotaConfig }) => {
    if (state?.org?.quota) {
      const updatedOrg: Organization = {
        ...state.org,
        quota: {
          ...state.org.quota,
          salesWorkflowInProgressLimit: salesflowQuotaConfig.inProgress,
          salesWorkflowWaitingRoomLimit: salesflowQuotaConfig.waitingRoom,
        },
      };

      return {
        ...state,
        org: updatedOrg,
      };
    }

    return { ...state };
  }),
);

export function AuthReducer(state: AuthenticatedTokenState, action: Action) {
  return authReducer(state, action);
}
