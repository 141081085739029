import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  SfLiveAgentDisconnectAction,
  SfLiveAgentDisconnectSuccessAction,
  SfLiveAgentInitFailureAction,
  SfLiveAgentInitSuccessAction,
  StartSfLiveAgentInitAction,
} from '@zi-pages/sf-live-agent/ngrx/action/sf-live-agent.action';
import { catchError, map, mergeMap, take } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { getProfile } from '@zi-core/ngrx/state/auth.state';
import * as _ from 'lodash';
import { OrganizationService } from '@zi-common/service/organization/organization.service';
import { ApplicationState } from '@app/reducers';
import { Store } from '@ngrx/store';
import { environment } from '@env/environment';
import { LoggerServiceToken } from '@zi-core/config/logger-service.config';
import { ILoggerService } from '@zi-core/interface/logger.service.interface';

@Injectable()
export class SfLiveAgentEffect {
  constructor(
    private action$: Actions,
    private orgService: OrganizationService,
    private appStore: Store<ApplicationState>,
    @Inject(LoggerServiceToken) private loggerService: ILoggerService,
  ) {}

  startSfLiveAgentInit$ = createEffect(() => {
    return this.action$.pipe(
      ofType(StartSfLiveAgentInitAction),
      mergeMap(() => {
        return forkJoin([
          this.appStore.select(getProfile).pipe(
            take(1),
            map((profile) => profile),
          ),
          this.orgService.getOrganization(),
        ]);
      }),
      map(([profile, orgInfo]) => {
        const clientName = _.get(orgInfo, 'organization.title');
        const userName = _.get(profile, 'email');
        const accountName = _.get(profile, 'company');
        const clientPhone = _.get(profile, 'attributes.phone');
        // @ts-ignore
        const sfLiveAgent = window.liveagent;
        sfLiveAgent.setName(clientName);
        sfLiveAgent.addCustomDetail('Client Name', clientName);
        sfLiveAgent.addCustomDetail('Username', userName);
        if (!_.isEmpty(accountName)) {
          sfLiveAgent.addCustomDetail('Account Name', accountName);
        }
        if (!_.isEmpty(clientPhone)) {
          sfLiveAgent.addCustomDetail('Client Phone', clientPhone);
        }

        const input = (accountName ? accountName + ' - ' : '') + clientName + ' - Engage Support';
        sfLiveAgent.addCustomDetail('Case Subject', `${input}`);
        sfLiveAgent.addCustomDetail('Case Status', 'New');
        sfLiveAgent
          .findOrCreate('Case')
          .map('Subject', 'Case Subject', true, false, true)
          .map('Status', 'Case Status', false, false, true)
          .saveToTranscript('CaseId');

        sfLiveAgent.findOrCreate('Contact').map('Email', 'Username', true, true, false).saveToTranscript('ContactId').linkToEntity('Case', 'ContactId');

        sfLiveAgent.init('https://d.la4-c4-ph2.salesforceliveagent.com/chat', '5723p0000008QBO', environment.sf_live_chat_org_id);
      }),
      map(() => {
        return SfLiveAgentInitSuccessAction();
      }),
      catchError((err) => {
        this.loggerService.error('Error initializing SF Live Agent :: ', err);
        return of(SfLiveAgentInitFailureAction(err));
      }),
    );
  });

  disconnectSfLiveAgent$ = createEffect(() => {
    return this.action$.pipe(
      ofType(SfLiveAgentDisconnectAction),
      map(() => {
        // @ts-ignore
        const sfLiveAgent = window.liveagent;
        sfLiveAgent.disconnect();
        return SfLiveAgentDisconnectSuccessAction();
      }),
    );
  });
}
