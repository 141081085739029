export enum SObjectType {
  ACCOUNT = 'Account',
  CONTACT = 'Contact',
  LEAD = 'Lead',
}

export interface SObjectTypeFormField {
  value: SObjectType;
  required: boolean;
}

export enum SObjectFieldType {
  STRING = 'string',
  TEXTAREA = 'textarea',
  PHONE = 'phone',
  EMAIL = 'email',
  URL = 'url',
  ID = 'id',
  REFERENCE = 'reference',

  DOUBLE = 'double',
  CURENCY = 'currency',
  PERCENT = 'percent',
  INT = 'int',

  BOOLEAN = 'boolean',

  PICKLIST = 'picklist',

  DATE = 'date',
  DATE_TIME = 'datetime',

  TIME = 'time',
}

export interface SObject {
  name: SObjectType;
  label: string;
  listOfFields: SObjectField[];
}

export interface SObjectField {
  name: string;
  label: string;
  type: SObjectFieldType;
  pickListValues?: PicklistValue[] | null;
}

export interface PicklistValue {
  value: string;
  label: string;
  default: boolean;
}

export interface SObjectFieldResponse {
  listOfSObjects: SObject[];
  numberOfSObjects: number;
}
