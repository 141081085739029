import { DecimalPipe, LocationStrategy, TitleCasePipe } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule, EVENT_MANAGER_PLUGINS } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AreaCodesService } from '@app/caller/service/area-codes.service';
import { BridgeDialerService } from '@app/caller/service/bridge-dialer.service';
import { DialerUtilService } from '@app/caller/service/dialer-util.service';
import { TwilioTokenRefreshService } from '@app/caller/service/twilio-token-refresh.service';
import { VoipDialerService } from '@app/caller/service/voip-dialer.service';
import { AuthEffects } from '@app/core/ngrx/effect/auth.effects';
import { EngageLocationStrategy } from '@app/engage-location-strategy';
import { ExtensionOnboardingSetupComponent } from '@app/extension-onboarding-setup/extension-onboarding-setup.component';
import { ExtensionEffects } from '@app/extension/ngrx/effect/extension.effects';
import { ExtensionIframeMessagingService } from '@app/extension/service/extension-iframe-messaging.service';
import { ExtensionMatcherService } from '@app/extension/service/extension-matcher.service';
import { ExtensionRedirectionService } from '@app/extension/service/extension-redirection.service';
import { FeatureFlagService } from '@app/feature-flag/feature-flag.service';
import { ZoomAutomationModule } from '@app/zoom-automation/zoom-automation.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { CommonModule as ZiCommonModule } from '@zi-common/common.module';
import { DIALOG_TEMPLATE_CONFIG } from '@zi-common/component/dialog-template/dialog-template.config';
import { EventCapturePlugin } from '@zi-common/event/event-capture';
import { EmailerVersionService } from '@zi-core/service/emailer-version.service';
import { GlobalErrorHandler } from '@zi-core/service/global-error-handler/global-error-handler';
import { IntegrationService } from '@zi-core/service/integration.service';
import { OwnerColorService } from '@zi-core/service/owner-color.service';
import { PhoneVerifierService } from '@zi-core/service/phone-verifier.service';
import { SeoService } from '@zi-core/service/seo.service';
import { UrlService } from '@zi-core/service/url.service';
import { ZoominfoUniversityService } from '@zi-core/service/zoominf-university.service';
import { AdaLiveAgentEffect } from '@zi-pages/ada-live-chat/effect/ada-live-agent.effect';
import { EmailerVersionEffect } from '@zi-pages/admin/ngrx/effect/emailer-version.effect';
import { ContactDetailInternalDataService } from '@zi-pages/contact/service/contact-detail-internal-data.service';
import { ContactInternalDataService } from '@zi-pages/contact/service/contact-internal-data.service';
import { EmailModeService } from '@zi-pages/email-v2/services/email-mode.service';
import { EmailStorageService } from '@zi-pages/email-v2/services/email-storage.service';
import { EngageModeCallerStorageV2Service } from '@zi-pages/engage-mode-v2/service/engage-mode-caller-storage-v2.service';
import { EngageModeNavigationV2Service } from '@zi-pages/engage-mode-v2/service/engage-mode-navigation-v2.service';
import { OnBoardingEffect } from '@zi-pages/on-boarding/ngrx/effect/on-boarding.effect';
import { SfLiveAgentEffect } from '@zi-pages/sf-live-agent/ngrx/effect/sf-live-agent.effect';
import { SmsMessageEffect } from '@zi-pages/sms/ngrx/effect/sms-message.effect';
import { RetrieveConversationToDtoPipe } from '@zi-pages/sms/pipe/retrieve-conversation-to-dto.pipe';
import { RetrieveUserConversationsToDtoPipe } from '@zi-pages/sms/pipe/retrieve-user-conversations-to-dto.pipe';
import { SmsService } from '@zi-pages/sms/service/sms.service';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { LottieModule } from 'ngx-lottie';
import { NgxPermissionsModule } from 'ngx-permissions';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppEffects } from './app.effects';
import { extModules } from './build-specifics/index';
import { CountryCodesService } from './caller/service/country-code.service';
import { EngageGlobalLoaderComponent } from './common/component/engage-global-loader/engage-global-loader.component';
import { NotyComponent } from './common/component/noty/noty.component';
import { NeverbounceJobStatusPipe } from './common/pages-component/neverbounce-report-dialog/neverbounce-job-status.pipe';
import { OAuthMessageService } from './common/service/oauth-message/oauthMessage.service';
import { PermissionsService } from './core/ngxPermissions/permissions.service';
import { AnalyticsService } from './core/service/analytics.service';
import { DialerConfigEffect } from './pages/account/ngrx/effect/dialer-config.effect';
import { CompanyAccountDetailInternalDataService } from './pages/company-accounts/service/company-account-detail.service';
import { TellwiseContactErrorPipe } from './pages/contact/pipe/tellwise-contact-error.pipe';
import { EngageSupportService } from './pages/engage-support/services/engage-support.service';
import { TaskInternalDataService } from './pages/task/service/task-internal-data.service';
import { metaReducers, reducers } from './reducers';
import { ProfilesEffect } from './pages/admin/ngrx/effect/profiles.effect';
import { LoginService } from '@app/login/services/login.service';
import { combineLatest } from 'rxjs';
import { TrustedCallingService } from '@zi-pages/trusted-calling/service/trusted-calling.service';
import { OutlookExtensionComponent } from './outlook-extension/outlook-extension.component';
import { DialerAnalyticsService } from './caller/service/dialer-analytics.service';
import { interceptors } from '@zi-core/interceptor/interceptors';
import { LoggerServiceProviderToken } from '@zi-core/config/logger-service.config';

export function playerFactory() {
  return import('lottie-web');
}

/**
 * Init FeatureFlagService & LoginService and call to ready event
 * This function used to
 * 1. initial the FeatureFlagService
 * 2. perform sign in using UMS ziaccesstoken cookie
 * before the app, called by APP_INITIALIZER injection token
 * @param featureFlagService - DI of FeatureFlagService
 * @param loginService - DI of LoginService
 */
export function appInit(featureFlagService: FeatureFlagService, loginService: LoginService): () => Promise<void> {
  return () =>
    new Promise<void>((resolve, reject) => {
      combineLatest([featureFlagService.getReadyObs(), loginService.getReadyObs()]).subscribe(([res1, res2]) => {
        resolve();
      });
    });
}

@NgModule({
  declarations: [AppComponent, NotyComponent, EngageGlobalLoaderComponent, ExtensionOnboardingSetupComponent, OutlookExtensionComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ZiCommonModule,
    EffectsModule.forRoot([
      AuthEffects,
      ProfilesEffect,
      AppEffects,
      ExtensionEffects,
      OnBoardingEffect,
      SfLiveAgentEffect,
      DialerConfigEffect,
      AdaLiveAgentEffect,
      EmailerVersionEffect,
      SmsMessageEffect,
    ]),
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: false,
      },
    }),
    // StoreDevtoolsModule.instrument({name: 'Engage', maxAge: 25, logOnly: environment.production}),
    // devtool debugging
    extModules,
    BrowserAnimationsModule,
    MatToolbarModule,
    NgxPermissionsModule.forRoot(),
    MDBBootstrapModule.forRoot(),
    ZoomAutomationModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  providers: [
    ContactInternalDataService,
    ContactDetailInternalDataService,
    AnalyticsService,
    HttpClient,
    ExtensionRedirectionService,
    ExtensionIframeMessagingService,
    ExtensionMatcherService,
    IntegrationService,
    UrlService,
    PermissionsService,
    OAuthMessageService,
    DecimalPipe,
    EmailModeService,
    EmailStorageService,
    OwnerColorService,
    EngageModeNavigationV2Service,
    AreaCodesService,
    CountryCodesService,
    EngageModeCallerStorageV2Service,
    PhoneVerifierService,
    TaskInternalDataService,
    CompanyAccountDetailInternalDataService,
    SeoService,
    TitleCasePipe,
    TellwiseContactErrorPipe,
    NeverbounceJobStatusPipe,
    EngageSupportService,
    BridgeDialerService,
    VoipDialerService,
    DialerUtilService,
    TwilioTokenRefreshService,
    ZoominfoUniversityService,
    EmailerVersionService,
    SmsService,
    TrustedCallingService,
    RetrieveConversationToDtoPipe,
    RetrieveUserConversationsToDtoPipe,
    DialerAnalyticsService,
    // This is a custom location strategy that inherits from HashLocationStrategy
    {
      provide: LocationStrategy,
      useClass: EngageLocationStrategy,
    },
    interceptors,
    {
      provide: EVENT_MANAGER_PLUGINS,
      useClass: EventCapturePlugin,
      multi: true,
    },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: DIALOG_TEMPLATE_CONFIG },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [FeatureFlagService, LoginService],
    },
    LoggerServiceProviderToken,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
