import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VerifyCallerIdResponse, UserTask, TwilioCallerId } from '@app/core/http-model/response/phone-verifier.response.model';
import { AddDialerPhoneNumber, DeleteDialerPhoneNumber } from '@app/pages/account/ngrx/action/dialer-config.action';
import { DialerConfigState, getDialerBridge, getDialerVoip } from '@app/pages/account/ngrx/state/dialer-config.state';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { DialerSelection } from '../enums/dialer.enum';
import { VoicemailResponse } from '../http-model/response/dialer.response.model';

/**
 *  Phone Verifier Service
 */
@Injectable()
export class PhoneVerifierService {
  constructor(private httpClient: HttpClient, private dialerConfigStore: Store<DialerConfigState>) {}

  getCallerIds(userId: number, dialerType: DialerSelection[] = [DialerSelection.BRIDGE]): Observable<TwilioCallerId | TwilioCallerId[]> {
    const storedPhones = this.retrievePhoneFromState(dialerType);
    if (storedPhones) {
      const returnPhones = [];
      _.forEach(storedPhones, (dialerPhone) => {
        if (dialerPhone.phone !== '') {
          returnPhones.push(dialerPhone);
        }
      });
      if (returnPhones.length !== 0) {
        return of(dialerType.length > 1 ? returnPhones : returnPhones[0]);
      }
    }
    return this.httpClient.get(`${environment.backend_url}/v1/account/${userId}/caller_ids`).pipe(
      map((res: { callerIds: TwilioCallerId[] }) => {
        if (res?.callerIds.length === 0) {
          this.dialerConfigStore.dispatch(
            AddDialerPhoneNumber({ twilioCallerId: this.emptyTwilioCallerId(DialerSelection.BRIDGE), dialerSelection: DialerSelection.BRIDGE }),
          );
          this.dialerConfigStore.dispatch(
            AddDialerPhoneNumber({ twilioCallerId: this.emptyTwilioCallerId(DialerSelection.VOIP), dialerSelection: DialerSelection.VOIP }),
          );
        }
        if (res?.callerIds.length === 1) {
          if (this.changeCallerIdTypeToEnumType(res?.callerIds[0].type) === DialerSelection.BRIDGE) {
            this.dialerConfigStore.dispatch(
              AddDialerPhoneNumber({ twilioCallerId: this.emptyTwilioCallerId(DialerSelection.VOIP), dialerSelection: DialerSelection.VOIP }),
            );
          } else if (this.changeCallerIdTypeToEnumType(res?.callerIds[0].type) === DialerSelection.VOIP) {
            this.dialerConfigStore.dispatch(
              AddDialerPhoneNumber({ twilioCallerId: this.emptyTwilioCallerId(DialerSelection.BRIDGE), dialerSelection: DialerSelection.BRIDGE }),
            );
          }
        }
        _.forEach(res?.callerIds, (callerId) => {
          callerId.type = this.changeCallerIdTypeToEnumType(callerId.type);
          this.dialerConfigStore.dispatch(AddDialerPhoneNumber({ twilioCallerId: callerId, dialerSelection: callerId.type }));
        });
        return dialerType.length > 1 ? _.get(res, 'callerIds', null) : _.find(_.get(res, 'callerIds'), (callerId) => callerId.type === dialerType[0]) || null;
      }),
    );
  }

  emptyTwilioCallerId(dialerType: DialerSelection) {
    return { id: '', userId: '', orgId: '', phone: '', phoneExt: '', externalId: '', accountSid: '', modifiedAt: '', type: dialerType };
  }

  private retrievePhoneFromState(dialerType: DialerSelection[]) {
    let callerIds = [];
    _.forEach(dialerType, (dialer) => {
      if (dialer === DialerSelection.VOIP) {
        this.dialerConfigStore
          .select(getDialerVoip)
          .pipe(take(1))
          .subscribe((voip) => {
            callerIds.push(voip);
          });
      }
      if (dialer === DialerSelection.BRIDGE) {
        this.dialerConfigStore
          .select(getDialerBridge)
          .pipe(take(1))
          .subscribe((bridge) => {
            callerIds.push(bridge);
          });
      }
    });
    if (callerIds.includes(null)) {
      return null;
    }
    return callerIds;
  }

  private changeCallerIdTypeToEnumType(callerIdType) {
    switch (callerIdType) {
      case 1:
        return DialerSelection.BRIDGE;
      case 2:
        return DialerSelection.VOIP;
    }
  }

  // start a new phone verification
  verify(userId: string, fullPhoneNumber: string, extension: string = null): Observable<VerifyCallerIdResponse> {
    return this.httpClient
      .post(`${environment.backend_url}/v1/account/${userId}/caller_ids`, {
        Phone: fullPhoneNumber,
        Extension: extension,
      })
      .pipe(map((res: VerifyCallerIdResponse) => res));
  }

  // start a new voicemail recording
  record(userId: string, title: string, callerIdType?: string, isGreeting?: boolean): Observable<any> {
    const reqBody = {
      CallerType: callerIdType,
      IsGreeting: isGreeting,
    };
    if (title) {
      _.set(reqBody, 'Title', title);
    }
    return this.httpClient.post(`${environment.backend_url}/v1/account/${userId}/voicemail`, reqBody).pipe(map((res: UserTask) => res));
  }

  // check to see the verification status
  checkTaskStatus(taskId: number): Observable<UserTask> {
    return this.httpClient
      .get(`${environment.backend_url}/v1/tasks?TaskId=${taskId}`)
      .pipe(map((res: { tasks: UserTask[] }) => (res && res.tasks && res.tasks.length ? res.tasks[0] : null)));
  }

  deletePhoneNumber(userId: string, phoneId: string, dialerSelection: DialerSelection) {
    this.dialerConfigStore.dispatch(DeleteDialerPhoneNumber({ dialerSelection: dialerSelection }));
    return this.httpClient.delete(`${environment.backend_url}/v1/account/${userId}/caller_ids?Id=${phoneId}`);
  }

  getVoicemail(userId: number, orgId: number): Observable<VoicemailResponse[]> {
    return this.httpClient.get(`${environment.backend_url}/v1/account/${userId}/voicemail?OrgId=${orgId}`).pipe(map((res: VoicemailResponse[]) => res || []));
  }

  updateVoicemailTitle(userId: number, voicemailId: number, title: string): Observable<VoicemailResponse> {
    return this.httpClient
      .put(`${environment.backend_url}/v1/account/${userId}/voicemail`, {
        userId,
        voicemailId,
        title,
      })
      .pipe(map((res: VoicemailResponse) => res));
  }

  deleteVoicemail(userId: number, voicemailId: number, isGreeting?: boolean) {
    return this.httpClient.delete(`${environment.backend_url}/v1/account/${userId}/voicemail?Id=${voicemailId}&IsGreeting=${isGreeting}`);
  }
}
