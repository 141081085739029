import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { SpacesResponse } from '@zi-core/http-model/response/spaces.response.model';

@Injectable({
  providedIn: 'root',
})
export class FroalaEditorService {
  constructor(private httpClient: HttpClient) {}
  uploadFile(queryParams: string, entity: any) {
    return this.httpClient.post(`${environment.backend_url}/v1/upload?${queryParams}`, entity, { reportProgress: true, observe: 'events' });
  }

  uploadImage(entity: any): Observable<any> {
    return this.httpClient.post<string>(`${environment.backend_url}/v1/images/upload`, entity).pipe(
      map((res) => {
        return res.link;
      }),
    );
  }

  createTile(queryParams: string, entity: any): Observable<any> {
    return this.httpClient.post<SpacesResponse>(`${environment.backend_url}/v1/tiles/create`, this.tileResponseObject(entity)).pipe(
      map((res) => {
        return res.library[0];
      }),
    );
  }

  b64toBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab]);
  }

  tileResponseObject(fileResponse: any) {
    return {
      Tile: {
        Type: fileResponse.type,
        Title: fileResponse.title,
        ImageUrl: fileResponse.url,
        Attributes: {
          FileName: fileResponse.title,
          Size: fileResponse.size,
          PictureUrl: fileResponse.url,
        },
        Status: 1,
        Scope: 11,
        TemplateId: 0,
        SpaceId: 0,
      },
    };
  }
}
