import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { get } from 'lodash';

@Injectable()
export class EmailerVersionService {
  constructor(private _httpClient: HttpClient) {}

  public getEmailerVersion(): Observable<string> {
    return this._httpClient.get(`${environment.backend_url}/v1/emaileradmin/emailerversion`).pipe(
      map((resp: string) => {
        return get(resp, 'version');
      }),
    );
  }
}
