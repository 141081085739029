import { Component, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { getExtensionMode, isGmailExtension } from '@app/extensionMode.config';
import { ApplicationState } from '@app/reducers';
import { Store } from '@ngrx/store';
import { extSizeSelector } from '@app/extension/ngrx/state/extension.state';
import { take } from 'rxjs/operators';
import { ExtensionSize } from '@app/extension/constants/extension-size.enum';

@Component({
  selector: 'app-engage-global-loader',
  templateUrl: './engage-global-loader.component.html',
  styleUrls: ['./engage-global-loader.component.scss'],
})
export class EngageGlobalLoaderComponent implements OnInit {
  loaderSource: string = `${environment.icons_url}/New-Zoominfo-logo.gif`;
  useSmallerLoader = false;
  isGmailExt = false;

  constructor(private appStore: Store<ApplicationState>) {}

  ngOnInit() {
    if (isGmailExtension()) {
      this.isGmailExt = true;
    } else if (getExtensionMode()) {
      this.appStore
        .select(extSizeSelector)
        .pipe(take(1))
        .subscribe((size) => {
          if (size === ExtensionSize.TWENTY_PCT) {
            this.useSmallerLoader = true;
          }
        });
    }
  }
}
