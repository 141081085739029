import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SpacesResponse } from '@zi-core/http-model/response/spaces.response.model';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { PreviewTemplateDialogV2Component } from '@zi-common/pages-component/preview-template-dialog-v2/preview-template-dialog-v2.component';
import { MatDialog } from '@angular/material/dialog';
import { Tile } from '@zi-core/data-model/tile.model';
import { Contact } from '@zi-core/data-model/contact.model';

@Injectable({
  providedIn: 'root',
})
export class TemplateServiceService {
  constructor(private httpClient: HttpClient, private dialog: MatDialog) {}

  getOrgTemplates(orgId: number): Observable<SpacesResponse> {
    return this.httpClient.get<SpacesResponse>(`${environment.backend_url}/v1/templates?orgId=${orgId}`).pipe(
      map((res) => {
        return res;
      }),
    );
  }
  getTemplateById(templateId: number): Observable<SpacesResponse> {
    return this.httpClient.get<SpacesResponse>(`${environment.backend_url}/v1/templates?TemplateId=${templateId}`).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  previewTemplate(template, contacts: Array<Contact>, isOOTB?: boolean, isAbTestingEnabled?: boolean, senderId?: number) {
    let templateBody = isAbTestingEnabled ? _.get(template, 'attributes.message') : _.get(template, 'body');
    const templateSubject = _.get(template, 'subject');
    const tiles: Tile[] = _.get(template, 'attributes.attachments');
    let attachments = [];
    if (tiles?.length > 0) {
      attachments = tiles.map((elt) => {
        return {
          fileName: _.get(elt, 'attributes.fileName'),
          url: _.get(elt, 'attributes.pictureUrl') || _.get(elt, 'attributes.url'),
        };
      });
    }
    templateBody = templateBody.replace(/{/g, '&#123;');
    templateBody = templateBody.replace(/}/g, '&#125;');
    this.dialog.open(PreviewTemplateDialogV2Component, {
      width: '800px',
      height: '780px',
      data: {
        contacts, // Pass contacts to preview template dialog
        messageTemplateId: template.id,
        messageTemplateBody: templateBody,
        dialogSubject: templateSubject,
        attachments,
        isOOTB: !!isOOTB,
        senderId,
      },
    });
  }
}
