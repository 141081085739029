import { PipeTransform, Injectable } from '@angular/core';
import { TellwiseContactCsvDto, TellwiseContactError } from '@app/core/data-model/tellwise-contact.model';

@Injectable()
export class TellwiseContactErrorPipe implements PipeTransform {
  transform(tcdto: TellwiseContactCsvDto, contactOwnerName?: string, ...args: any[]): TellwiseContactError {
    return {
      failedReason: tcdto?.errorMessage,
      zoomId: tcdto?.zoomId,
      zoomCompanyId: tcdto?.zoomCompanyId,
      email: tcdto?.email,
      firstName: tcdto?.firstName,
      lastName: tcdto?.lastName,
      company: tcdto?.company,
      phone: tcdto?.directPhone,
      owner: contactOwnerName,
      salutation: tcdto?.salutation,
      jobTitle: tcdto?.jobTitle,
      department: tcdto?.department,
      doNotEmail: String(!!tcdto?.doNotEmail),
      doNotCall: String(!!tcdto?.doNotCall),
      description: tcdto?.description,
      industry: tcdto?.industry,
      annualRevenue: tcdto?.annualRevenue,
      numberOfEmployees: tcdto?.numberOfEmployees,
      companyPageUrl: tcdto?.companyPageUrl,
      companyGoogleUrl: tcdto?.companyGoogleUrl,
      companyFacebookUrl: tcdto?.companyFacebookUrl,
      companyLinkedInUrl: tcdto?.companyLinkedInUrl,
      companyTwitterUrl: tcdto?.companyTwitterUrl,
      street: tcdto?.street,
      city: tcdto?.city,
      state: tcdto?.state,
      zip: tcdto?.zip,
      country: tcdto?.country,
      otherPhone: tcdto?.otherPhone,
      mobilePhone: tcdto?.mobilePhone,
      homePhone: tcdto?.homePhone,
      assistantPhone: tcdto?.assistantPhone,
      hqPhone: tcdto?.hqPhone,
      facebookUrl: tcdto?.facebookUrl,
      googleUrl: tcdto?.googleUrl,
      linkedInUrl: tcdto?.linkedInUrl,
      twitterUrl: tcdto?.twitterUrl,
    };
  }
}
