import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContactsAssociatedToAccount } from '@app/core/data-model/analytics/contacts-associated-to-account.model';
import { AnalyticsEvent } from '@app/core/enums/analytics-event.enum';
import { getCurrentUserId } from '@app/core/ngrx/state/auth.state';
import { AnalyticsService } from '@app/core/service/analytics.service';
import { ApplicationState } from '@app/reducers';
import { select, Store } from '@ngrx/store';
import { PickListResponse, PickListValue } from '@zi-common/interface/contactStatus';
import { SelectOption } from '@zi-common/interface/selectOption';
import { MessageType } from '@zi-common/model/message/message-type';
import { Message } from '@zi-common/model/message/message.model';
import { CompanyService } from '@zi-common/service/company-service/company.service';
import { NotyService } from '@zi-common/service/noty/noty.service';
import { CompanyAccountContact } from '@zi-core/data-model/company-account-contact.model';
import { Contact } from '@zi-core/data-model/contact.model';
import { StatusPickListResourceType } from '@zi-core/enums/resource-type.enum';
import { PicklistStatusService } from '@zi-pages/service/picklist-status.service';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { SharpyErrorTypesEnum } from '@zi-common/model/sharpy-error-types.enum';

@Component({
  selector: 'app-contact-associate-to-account-dialog',
  templateUrl: './contact-associate-to-account-dialog.component.html',
  styleUrls: ['./contact-associate-to-account-dialog.component.scss'],
})
export class ContactAssociateToAccountDialogComponent implements OnInit {
  title: string;
  primaryButtonText: string;
  closeButtonText: string;
  associateHeaderText: string;
  associateFooterText: string;

  contacts: Array<Contact>;
  linkedCompanyAccountIdToDisable: number;
  isSaveEnabled: boolean;
  isLoading: boolean;
  statusPickList: Array<PickListValue>;
  defaultPickListVal: string;
  selectedOption: SelectOption;
  userId: number;

  constructor(
    public dialogRef: MatDialogRef<ContactAssociateToAccountDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private pickListStatusService: PicklistStatusService,
    private companyAccountService: CompanyService,
    private notyService: NotyService,
    private store: Store<ApplicationState>,
    private analyticsService: AnalyticsService,
  ) {
    this.contacts = _.get(this.data, 'contacts', []);
    const isBulkAction = this.contacts.length > 1;
    const contactName = _.get(this.contacts, '[0].firstName') + ' ' + _.get(this.contacts, '[0].lastName');
    const selectedContactsText = `the ${this.contacts.length} selected contacts`;

    this.title = `Associate contact${isBulkAction ? 's' : ''} to an existing account`;
    this.associateHeaderText = `Select an existing account to associate ${isBulkAction ? selectedContactsText : contactName} with`;
    this.associateFooterText = `* The contact${this.contacts.length > 1 ? 's' : ''} and ${
      this.contacts.length > 1 ? 'their' : 'its'
    } associated activities will be associated with the new account.`;

    this.primaryButtonText = 'Save';
    this.closeButtonText = 'Cancel';

    this.isSaveEnabled = false;
    this.isLoading = false;

    this.statusPickList = [];
    this.defaultPickListVal = '-';

    const linkedCompanyAccountIds = _.uniq(this.contacts.map((c) => c.companyAccountId).filter((c) => !!c));
    if (linkedCompanyAccountIds.length < 2) {
      const firstLinkedCompanyAccountId = _.get(linkedCompanyAccountIds, '[0]');
      if (this.contacts.every((c) => c.companyAccountId === firstLinkedCompanyAccountId)) {
        this.linkedCompanyAccountIdToDisable = firstLinkedCompanyAccountId;
      }
    }
  }

  ngOnInit() {
    this.getStatusPickList();
    this.store
      .pipe(select(getCurrentUserId))
      .pipe(take(1))
      .subscribe((currentUserId) => {
        this.userId = currentUserId;
      });
  }

  getStatusPickList() {
    this.pickListStatusService.getPickList(StatusPickListResourceType.CompanyAccount).subscribe((res: PickListResponse) => {
      this.statusPickList = _.get(res, 'pickList.values', []);
      this.defaultPickListVal = _.get(res, 'pickList.default', '-');
    });
  }

  onCompanyAccountSelected(selectedOption: SelectOption) {
    this.selectedOption = selectedOption;
    this.isSaveEnabled = !!selectedOption;
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.isLoading = true;
    const accountId: number = _.get(this.selectedOption.value, 'id');
    const contactIds: Array<number> = this.contacts.map((c) => c.id);
    this.companyAccountService
      .addCompanyAccountContactMemberships(accountId, contactIds)
      .pipe(
        take(1),
        map((res: Array<CompanyAccountContact>) => {
          if (res && res.length === contactIds.length) {
            this.notyService.postMessage(
              new Message(
                MessageType.SUCCESS,
                `Contact(s) were successfully associated with ${this.selectedOption.displayName}. Please wait for few minutes before you see the contacts linked with the account.`,
              ),
            );
            this.sendContactsAssociatedToAccountsAnalyticsEvent();
            this.dialogRef.close(true);
          } else {
            this.onRequestFailure();
          }
        }),
        catchError((error) => {
          let permissionError = '';
          if (error?.status === 403 && error?.error?.ResponseStatus?.ErrorCode === SharpyErrorTypesEnum.FORBIDDEN) {
            permissionError = error?.error?.ResponseStatus.Message;
          }
          this.onRequestFailure(permissionError);
          return of();
        }),
      )
      .subscribe();
  }

  onRequestFailure(permissionError = '') {
    this.isLoading = false;
    const errMsg = `Failed to associate contact(s) with ${this.selectedOption.displayName}${permissionError.length > 0 ? ': ' + permissionError : ''}`;
    this.notyService.postMessage(new Message(MessageType.ERROR, errMsg));
  }

  sendContactsAssociatedToAccountsAnalyticsEvent() {
    const selectedAccount = _.get(this.selectedOption, 'value');
    const associatedContactsEventProps: ContactsAssociatedToAccount = {
      NumberOfContacts: this.contacts.length,
      Contacts: this.contacts.map(({ id }) => id.toString()),
      AccountId: _.get(selectedAccount, 'id').toString(),
      AccountName: _.get(selectedAccount, 'name'),
      CTA: _.get(this.data, 'cta'),
      IsAccountOwner: this.userId === _.get(selectedAccount, 'ownerId'),
    };

    this.analyticsService.send(AnalyticsEvent.ASSOCIATED_CONTACTS_TO_ACCOUNT, associatedContactsEventProps);
  }
}
