import { ERROR_SYSTEM_NAME_TO_ENGAGE_FIELD_NAME, USER_HEADERS_CSV } from '@app/common/pages-component/map-file-fields/file-mapping.config';

export enum HEADER_MAP_VALUE {
  createdAt = 'createdAt',
  modifiedAt = 'modifiedAt',
  lastOpenedAt = 'lastOpenedAt',
  lastLinkClickedAt = 'lastLinkClickedAt',
  lastRepliedAt = 'lastRepliedAt',
  lastContentViewed = 'lastContentViewed',
  lastCalledAt = 'lastCalledAt',
  lastEmailedAt = 'lastEmailedAt',
  lastTextedAt = 'lastTextedAt',
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  directPhone = 'phone',
  salutation = 'salutation',
  company = 'company',
  jobTitle = 'jobTitle',
  department = 'department',
  description = 'description',
  doNotEmail = 'doNotEmail',
  doNotCall = 'doNotCall',
  status = 'status',
  industry = 'industry',
  annualRevenue = 'annualRevenue',
  numberOfEmployees = 'numberOfEmployees',
  website = 'companyPageUrl',
  street = 'street',
  city = 'city',
  provinceOrState = 'state',
  postalOrZip = 'zip',
  country = 'country',
  mobilePhone = 'mobilePhone',
  homePhone = 'homePhone',
  assistantPhone = 'assistantPhone',
  headquarterPhone = 'hqPhone',
  otherPhone = 'otherPhone',
  totalNumberOfEmails = 'totalNumberOfEmails',
  totalNumberOfCalls = 'totalNumberOfCalls',
  totalNumberOfSms = 'totalNumberOfSms',
  lastConversationDuration = 'lastConversationDuration',
  totalConversationDuration = 'totalConversationDuration',
  lastCallResult = 'lastCallResult',
  lastCallSentiment = 'lastCallSentiment',
  linkedInURL = 'linkedInUrl',
  facebookURL = 'facebookUrl',
  googleURL = 'googleUrl',
  twitterURL = 'twitterUrl',
  totalNumberOfEmailOpens = 'totalNumberOfEmailOpens',
  totalNumberOfLinkClicks = 'totalNumberOfLinkClicks',
  totalNumberOfEmailReplies = 'totalNumberOfEmailReplies',
  lastSalesWorkflow = 'lastSalesWorkflow',
}

export const DateValues = new Set([
  HEADER_MAP_VALUE.createdAt,
  HEADER_MAP_VALUE.modifiedAt,
  HEADER_MAP_VALUE.lastOpenedAt,
  HEADER_MAP_VALUE.lastLinkClickedAt,
  HEADER_MAP_VALUE.lastRepliedAt,
  HEADER_MAP_VALUE.lastContentViewed,
  HEADER_MAP_VALUE.lastCalledAt,
  HEADER_MAP_VALUE.lastEmailedAt,
  HEADER_MAP_VALUE.lastTextedAt,
]);

export const HEADER_MAP: { [headerDisplayName: string]: HEADER_MAP_VALUE } = {
  'First Name': HEADER_MAP_VALUE.firstName,
  'Last Name': HEADER_MAP_VALUE.lastName,
  Email: HEADER_MAP_VALUE.email,
  'Direct Phone': HEADER_MAP_VALUE.directPhone,
  Salutation: HEADER_MAP_VALUE.salutation,
  Company: HEADER_MAP_VALUE.company,
  'Job Title': HEADER_MAP_VALUE.jobTitle,
  Department: HEADER_MAP_VALUE.department,
  Description: HEADER_MAP_VALUE.description,
  'Do Not Email': HEADER_MAP_VALUE.doNotEmail,
  'Do Not Call': HEADER_MAP_VALUE.doNotCall,
  'Contact Status': HEADER_MAP_VALUE.status,
  Industry: HEADER_MAP_VALUE.industry,
  'Annual Revenue': HEADER_MAP_VALUE.annualRevenue,
  'Number of Employees': HEADER_MAP_VALUE.numberOfEmployees,
  Website: HEADER_MAP_VALUE.website,
  Street: HEADER_MAP_VALUE.street,
  City: HEADER_MAP_VALUE.city,
  'Province/State': HEADER_MAP_VALUE.provinceOrState,
  'Postal/Zip': HEADER_MAP_VALUE.postalOrZip,
  Country: HEADER_MAP_VALUE.country,
  'Mobile Phone': HEADER_MAP_VALUE.mobilePhone,
  'Home Phone': HEADER_MAP_VALUE.homePhone,
  'Assistant Phone': HEADER_MAP_VALUE.assistantPhone,
  'Headquarter Phone': HEADER_MAP_VALUE.headquarterPhone,
  'Other Phone': HEADER_MAP_VALUE.otherPhone,
  'Created At': HEADER_MAP_VALUE.createdAt,
  'Modified At': HEADER_MAP_VALUE.modifiedAt,
  'Total Number of Emails': HEADER_MAP_VALUE.totalNumberOfEmails,
  'Number of Emails Opened': HEADER_MAP_VALUE.totalNumberOfEmailOpens,
  'Number of Links Clicked': HEADER_MAP_VALUE.totalNumberOfLinkClicks,
  'Number of Emails Replied': HEADER_MAP_VALUE.totalNumberOfEmailReplies,
  'Last Opened At': HEADER_MAP_VALUE.lastOpenedAt,
  'Last Emailed At': HEADER_MAP_VALUE.lastEmailedAt,
  'Last SMS At': HEADER_MAP_VALUE.lastTextedAt,
  'Last Link Clicked At': HEADER_MAP_VALUE.lastLinkClickedAt,
  'Last Replied At': HEADER_MAP_VALUE.lastRepliedAt,
  'Total Number of Calls': HEADER_MAP_VALUE.totalNumberOfCalls,
  'Total Number of SMS': HEADER_MAP_VALUE.totalNumberOfSms,
  'Last Conversation Duration': HEADER_MAP_VALUE.lastConversationDuration,
  'Last Called At': HEADER_MAP_VALUE.lastCalledAt,
  'Total Conversation Duration': HEADER_MAP_VALUE.totalConversationDuration,
  'Last Call Result': HEADER_MAP_VALUE.lastCallResult,
  'Last Call Sentiment': HEADER_MAP_VALUE.lastCallSentiment,
  'LinkedIn URL': HEADER_MAP_VALUE.linkedInURL,
  'Facebook URL': HEADER_MAP_VALUE.facebookURL,
  'Google URL': HEADER_MAP_VALUE.googleURL,
  'Twitter URL': HEADER_MAP_VALUE.twitterURL,
  'Most Recent Salesflow Name': HEADER_MAP_VALUE.lastSalesWorkflow,
};

export const PHONE_CALL_SENTIMENT_MAP = {
  0: 'None',
  1: 'Negative',
  100: 'Neutral',
  200: 'Positive',
  300: 'Bad timing',
  400: 'No fit',
  500: 'Not interested',
  600: 'More info requested',
  700: 'Request for callback',
  800: 'Next step booked',
};

export const PHONE_CALL_RESULT_MAP = {
  0: 'None',
  1: 'Connected direct',
  2: 'Connect indirect',
  100: 'No answer',
  101: 'Left message',
  102: 'Gatekeeper',
  200: 'Wrong number',
  201: 'Referred',
  202: 'No longer there',
};

export const excludeAllFiltersMap = {
  tag_ids: "(not (prefix field='tags' ''))",
  companies: "(not (prefix field='companies' ''))",
  departments: "(not (prefix field='departments' ''))",
  countries: "(not (prefix field='countries' ''))",
  state_or_provinces: "(not (prefix field='state_or_provinces' ''))",
  cities: "(not (prefix field='cities' ''))",
  job_titles: "(not (prefix field='job_titles' ''))",
  list_ids: "(not (prefix field='list_ids' ''))",
  contact_statuses: "(not (prefix field='status' ''))",
  industries: "(not (prefix field='industries' ''))",
  owner_id: '0',
};

export const REVERSE_ORG_USERS_CSV_HEADER: string[] = reverseToArray(USER_HEADERS_CSV);

export const REVERSED_SYSTEM_NAME_TO_ENGAGE_FIELD_NAME: string[] = reverseToArray(ERROR_SYSTEM_NAME_TO_ENGAGE_FIELD_NAME);
export const HEADER_MAP_ARRAY: string[] = toArray(HEADER_MAP);

function toArray(keyValueEnum) {
  const keyValueMap: string[] = [];
  Object.keys(keyValueEnum).map((key) => {
    keyValueMap[key] = keyValueEnum[key];
  });
  return keyValueMap;
}

function reverseToArray(keyValueMap) {
  const valueKeyMap: string[] = [];
  Object.keys(keyValueMap).map((keyElem) => {
    const valueElem = keyValueMap[keyElem];
    valueKeyMap[valueElem] = keyElem;
  });
  return valueKeyMap;
}
