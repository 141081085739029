export enum DateAggregates {
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Yearly = 'Yearly',
}

export enum FilterDateRangeValuesEnum {
  today = 'TODAY',
  lastWeek = 'LAST_WEEK',
  lastTwoWeeks = 'LAST_2_WEEKS',
  currentMonth = 'CURRENT_MONTH',
  custom = 'CUSTOM',
  currentYear = 'CURRENT_YEAR',
  any = 'ANY_TIME',
}

export const DateAggregatesDayCount = {
  [DateAggregates.Daily]: 'days',
  [DateAggregates.Weekly]: 'weeks',
  [DateAggregates.Monthly]: 'months',
  [DateAggregates.Quarterly]: 'quarters',
  [DateAggregates.Yearly]: 'years',
};
