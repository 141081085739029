import { Injectable } from '@angular/core';
import { Message } from '@zi-common/model/message/message.model';
import { MessageType } from '@zi-common/model/message/message-type';
import { NotyService } from '@zi-common/service/noty/noty.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class FileDownloaderService {
  constructor(private notyService: NotyService, private httpClient: HttpClient) {}

  downloadFileFromServer(url: string, fileName: string) {
    try {
      this.httpClient.get(url, { responseType: 'blob' }).subscribe((response) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(response);
        link.download = fileName;
        link.click();
        link.remove();
      });
    } catch (e) {
      this.notyService.postMessage(new Message(MessageType.ERROR, 'Error with file downloading'));
    }
  }
}
