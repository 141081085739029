import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Invitation } from '@zi-core/data-model/invitation.model';
import { Note } from '@zi-core/data-model/note.model';
import { ResourceType } from '@zi-core/enums/resource-type.enum';
import { ContactEngagements, ContactSalesWorkflowRest, EventDto } from '@zi-core/http-model/response/contacts.response.model';
import { NotesResponse } from '@zi-core/http-model/response/note.response.model';
import { PhoneCall } from '@zi-core/http-model/response/phone-calls.response.model';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { Contact } from '@app/core/data-model/contact.model';
import { Task } from '@zi-core/data-model/task.model';
import {
  ContactDetailEvents,
  ContactDetailInvitationEventsSummary,
  ContactDetailInvitations,
  ContactDetailPhoneCalls,
  ContactDetailSalesflows,
  ContactDetailTasks,
  ContactExtended,
} from '@app/core/data-model/contact-details.model';
import {
  contactEventDetailsSelector,
  contactInvitationDetailsSelector,
  contactInvitationEventsSummarySelector,
  contactPhoneCallDetailsSelector,
  contactSalesflowDetailsSelector,
  contactTaskDetailSelector,
  getContactDetailExtendedFields,
} from '../ngrx/state/contact-detail-entity.state';
import {
  FetchContactEvents,
  FetchContactExtendedFields,
  FetchContactInvitationEventsSummary,
  FetchContactInvitations,
  FetchContactPhoneCalls,
  FetchContactSalesflows,
  FetchContactTasks,
} from '../ngrx/action/contact-detail-entity.action';
import * as _ from 'lodash';
import { ApplicationState } from '@app/reducers';
import { Store } from '@ngrx/store';
import { isAuthenticated } from '@app/core/ngrx/state/auth.state';
import { statusTypesEnum } from '@app/common/pages-component/contact-details/contact-tasks-tab/contact-tasks-tab.config';
import { InvitationEventsSummary } from '@zi-core/data-model/invitation-events-summary';
import { TaskInternalDataService } from '@app/pages/task/service/task-internal-data.service';

/**
 *  Contact Detail DataService
 */
@Injectable()
export class ContactDetailInternalDataService {
  constructor(private httpClient: HttpClient, private store: Store<ApplicationState>, private taskInternalDataService: TaskInternalDataService) {}

  // load extended fields for contact
  loadContactExtendedFields(contactId: number): Observable<Contact> {
    return this.httpClient.get(`${environment.backend_url}/v1/contacts/${contactId}`).pipe(
      map((res: { contacts: Array<Contact> }) => {
        return _.get(res, 'contacts[0]');
      }),
    );
  }

  // load tasks associated with the contact
  loadTasks(contactId: number): Observable<Array<Task>> {
    return this.httpClient.get(`${environment.backend_url}/v2/contacts/${contactId}/tasks`).pipe(
      map((res: { tasks: Array<Task> }) => {
        return res.tasks?.map(this.taskInternalDataService.mapTask) || [];
      }),
    );
  }

  // load events associated with the contact
  loadEvents(contactId: number): Observable<Array<EventDto>> {
    return this.httpClient.get(`${environment.backend_url}/v2/contacts/${contactId}/events`).pipe(
      map((res: { events: Array<EventDto> }) => {
        return res.events || [];
      }),
    );
  }

  // load invitations associated with the contact
  loadInvitations(contactId: number): Observable<Array<Invitation>> {
    return this.httpClient.get(`${environment.backend_url}/v1/contacts/${contactId}/invitations`).pipe(
      map((res: { invitations: Array<Invitation> }) => {
        return res.invitations || [];
      }),
    );
  }

  // load invitation Events summary associated with the contact
  loadInvitationEventsSummary(contactId: number): Observable<InvitationEventsSummary> {
    return this.httpClient.get(`${environment.backend_url}/v1/contacts/${contactId}/invitationEventsSummary`).pipe(
      map((res: InvitationEventsSummary) => {
        return res || {};
      }),
    );
  }

  // load salesflows associated with the contact
  loadSalesflows(contactId: number): Observable<Array<ContactSalesWorkflowRest>> {
    return this.httpClient.get(`${environment.backend_url}/v2/contacts/${contactId}/workflows`).pipe(
      map((res: Array<ContactSalesWorkflowRest>) => {
        return (res || []).map(this.mapContactSalesWorkflowRest);
      }),
    );
  }

  // load phone calls associated with the contact
  loadPhoneCalls(contactId: number): Observable<Array<PhoneCall>> {
    return this.httpClient.post(`${environment.backend_url}/v1/phonecalls`, { ContactIds: [contactId] }).pipe(
      map((res: { phoneCalls: Array<PhoneCall> }) => {
        return res.phoneCalls || [];
      }),
    );
  }

  // load notes associated with the contact
  loadNotes(contactId: number): Observable<Array<Note>> {
    return this.httpClient.get<NotesResponse>(`${environment.backend_url}/v1/note/${ResourceType.Contact}/${contactId}`).pipe(
      map((res: { notes: Array<Note> }) => {
        return res.notes || [];
      }),
    );
  }

  createNote(contactId: number, text: string): Observable<Note> {
    return this.httpClient.post(`${environment.backend_url}/v1/note`, { note: { resourceType: ResourceType.Contact, resourceId: contactId, text } }).pipe(
      map((res: { note: Note }) => {
        return res.note;
      }),
    );
  }

  updateNote(noteId: number, contactId: number, text: string): Observable<any> {
    return this.httpClient
      .put(`${environment.backend_url}/v1/note`, { note: { id: noteId, resourceType: ResourceType.Contact, resourceId: contactId, text } })
      .pipe(
        map((res) => {
          return res;
        }),
      );
  }

  deleteNote(noteId: number): Observable<NotesResponse> {
    return this.httpClient.delete<NotesResponse>(`${environment.backend_url}/v1/note/${noteId}`).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  getContactSalesflows(contactId: number, forceLoad: boolean = false): Observable<ContactDetailSalesflows> {
    if (forceLoad) {
      this.store.dispatch(FetchContactSalesflows({ id: contactId }));
    }

    return this.store.select(isAuthenticated).pipe(
      switchMap((auth) => {
        return auth ? this.store.select(contactSalesflowDetailsSelector(contactId)) : of();
      }),
      tap((contactSalesflows: ContactDetailSalesflows) => {
        const error: string = _.get(contactSalesflows, 'error');
        if (error) {
          throw new Error(error);
        }

        if (!_.get(contactSalesflows, 'loaded') && !_.get(contactSalesflows, 'loading')) {
          this.store.dispatch(FetchContactSalesflows({ id: contactId }));
        }
      }),
      filter((contactSalesflows: ContactDetailSalesflows) => _.get(contactSalesflows, 'loaded') && !_.get(contactSalesflows, 'loading')),
    );
  }

  getContactEmails(contactId: number, forceLoad: boolean = false): Observable<ContactDetailInvitations> {
    if (forceLoad) {
      this.store.dispatch(FetchContactInvitations({ id: contactId }));
    }

    return this.store.select(isAuthenticated).pipe(
      switchMap((auth) => {
        return auth ? this.store.select(contactInvitationDetailsSelector(contactId)) : of();
      }),
      tap((contactEmails: ContactDetailInvitations) => {
        const error: string = _.get(contactEmails, 'error');
        if (error) {
          throw new Error(error);
        }

        if (!_.get(contactEmails, 'loaded') && !_.get(contactEmails, 'loading')) {
          this.store.dispatch(FetchContactInvitations({ id: contactId }));
        }
      }),
      filter((contactEmails: ContactDetailInvitations) => _.get(contactEmails, 'loaded') && !_.get(contactEmails, 'loading')),
    );
  }

  getEmailEventsSummary(contactId: number, forceLoad: boolean = false): Observable<ContactDetailInvitationEventsSummary> {
    if (forceLoad) {
      this.store.dispatch(FetchContactInvitationEventsSummary({ id: contactId }));
    }
    return this.store.select(isAuthenticated).pipe(
      switchMap((auth) => {
        return auth ? this.store.select(contactInvitationEventsSummarySelector(contactId)) : of();
      }),
      tap((emailEventsSummary: ContactDetailInvitationEventsSummary) => {
        const error: string = _.get(emailEventsSummary, 'error');
        if (error) {
          throw new Error(error);
        }

        if (!_.get(emailEventsSummary, 'loaded') && !_.get(emailEventsSummary, 'loading')) {
          this.store.dispatch(FetchContactInvitationEventsSummary({ id: contactId }));
        }
      }),
      filter((emailEventsSummary: ContactDetailInvitationEventsSummary) => _.get(emailEventsSummary, 'loaded') && !_.get(emailEventsSummary, 'loading')),
    );
  }

  getExtendedContact(contactId: number, forceLoad: boolean = false): Observable<ContactExtended> {
    if (forceLoad) {
      this.store.dispatch(FetchContactExtendedFields({ id: contactId }));
    }

    return this.store.select(isAuthenticated).pipe(
      switchMap((auth) => {
        return auth ? this.store.select(getContactDetailExtendedFields(contactId)) : of();
      }),
      tap((contactExtended: ContactExtended) => {
        const error: string = _.get(contactExtended, 'error');
        if (error) {
          throw new Error(error);
        }

        if (!_.get(contactExtended, 'loaded') && !_.get(contactExtended, 'loading')) {
          this.store.dispatch(FetchContactExtendedFields({ id: contactId }));
        }
      }),
      filter((contactExtended: ContactExtended) => _.get(contactExtended, 'loaded') && !_.get(contactExtended, 'loading')),
    );
  }

  getContactTasks(contactId: number, forceLoad: boolean = false): Observable<ContactDetailTasks> {
    if (forceLoad) {
      this.store.dispatch(FetchContactTasks({ id: contactId }));
    }

    return this.store.select(isAuthenticated).pipe(
      switchMap((auth) => {
        return auth ? this.store.select(contactTaskDetailSelector(contactId)) : of();
      }),
      tap((contactTasks: ContactDetailTasks) => {
        const error: string = _.get(contactTasks, 'error');
        if (error) {
          throw new Error(error);
        }

        if (!_.get(contactTasks, 'loaded') && !_.get(contactTasks, 'loading')) {
          this.store.dispatch(FetchContactTasks({ id: contactId }));
        }
      }),
      filter((contactTasks: ContactDetailTasks) => _.get(contactTasks, 'loaded') && !_.get(contactTasks, 'loading')),
    );
  }

  getContactEvents(contactId: number, forceLoad: boolean = false): Observable<ContactDetailEvents> {
    if (forceLoad) {
      this.store.dispatch(FetchContactEvents({ id: contactId }));
    }

    return this.store.select(isAuthenticated).pipe(
      switchMap((auth) => {
        return auth ? this.store.select(contactEventDetailsSelector(contactId)) : of();
      }),
      tap((contactEvents: ContactDetailEvents) => {
        const error = _.get(contactEvents, 'error');
        if (error) {
          throw new Error(error);
        }

        if (!_.get(contactEvents, 'loaded') && !_.get(contactEvents, 'loading')) {
          this.store.dispatch(FetchContactEvents({ id: contactId }));
        }
      }),
      filter((contactEvents: ContactDetailEvents) => _.get(contactEvents, 'loaded') && !_.get(contactEvents, 'loading')),
    );
  }

  getContactPhoneCalls(contactId: number, forceLoad: boolean = false): Observable<ContactDetailPhoneCalls> {
    if (forceLoad) {
      this.store.dispatch(FetchContactPhoneCalls({ id: contactId }));
    }

    return this.store.select(isAuthenticated).pipe(
      switchMap((auth) => {
        return auth ? this.store.select(contactPhoneCallDetailsSelector(contactId)) : of();
      }),
      tap((contactPhoneCalls: ContactDetailPhoneCalls) => {
        const error: string = _.get(contactPhoneCalls, 'error');
        if (error) {
          throw new Error(error);
        }

        if (!_.get(contactPhoneCalls, 'loaded') && !_.get(contactPhoneCalls, 'loading')) {
          this.store.dispatch(FetchContactPhoneCalls({ id: contactId }));
        }
      }),
      filter((contactPhoneCalls: ContactDetailPhoneCalls) => _.get(contactPhoneCalls, 'loaded') && !_.get(contactPhoneCalls, 'loading')),
    );
  }

  loadEngagementsDetails(contactId: number): Observable<ContactEngagements> {
    return this.httpClient.get(`${environment.backend_url}/v1/contacts/detail/${contactId}`).pipe(
      map((res: ContactEngagements) => {
        res?.workflows?.map(this.mapContactSalesWorkflowRest);
        res?.tasks?.map(this.taskInternalDataService.mapTask);
        return res;
      }),
    );
  }

  filterIrrelevantTasks(tasks: Task[]) {
    return _.filter(tasks, (task: Task) => task.status !== statusTypesEnum.Skipped);
  }

  /**
   * Maps ContactSalesWorkflowRest response to client model
   * @param workflowRest - the response to map
   * @returns mapped ContactSalesWorkflowRest
   */
  private mapContactSalesWorkflowRest(workflowRest: ContactSalesWorkflowRest): ContactSalesWorkflowRest {
    if (workflowRest?.isOutOfOffice) {
      workflowRest.status = 'PausedOOO';
      return workflowRest;
    }
    return workflowRest;
  }
}
