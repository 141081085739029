export interface CrmIntegration {
  hubspot: boolean;
  dynamics: boolean;
  salesforce: boolean;
  uniesalesforce: boolean;
}

export interface crmIntegrationResponseInterface {
  userCrmIntegrations: CrmIntegration;
  orgCrmIntegrations: CrmIntegration;
}

export const CrmIntegrations: CrmIntegration = {
  hubspot: false,
  dynamics: false,
  salesforce: false,
  uniesalesforce: false,
};

export const CrmIntegrationsResponse: crmIntegrationResponseInterface = {
  userCrmIntegrations: CrmIntegrations,
  orgCrmIntegrations: CrmIntegrations,
};
