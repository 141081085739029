/**
 * Pre-prd environment config
 */
export const environment = {
  production: true,
  name: 'pre-prod',
  app_domain: 'https://engage-app-preprod.zoominfo.com',
  backend_url: 'https://engage-app-preprod.zoominfo.com/rest',
  workflow_service_url: 'https://workflow.prod.us-west-2.inf.tellwise.com/workflow',
  import_service_url: 'https://engage-app.zoominfo.com/import-service',
  rulesServiceUrl: 'https://reng-pub.prod.us-west-2.inf.tellwise.com/res/',
  tellwise_frontend: 'https://my.tellwise.com',
  tellwise_app_version: 'app.30.00000',
  feed_url: 'https://sse-elb.tellwise.com',
  icons_url: 'https://engage-web-assets.zoominfo.com',
  am4charts_license: 'CH211189295',
  am4maps_license: 'MP54750498',
  profilePictureLink: 'https://storage.zoominfo.com',
  companyPictureLink: 'https://res.cloudinary.com/zoominfo-com/image/upload',
  amplitude_api_key: 'b497e086f6cb3da6baca4fcfa0bb09e8',
  pendo_api_key: 'd626ab78-c06e-4470-6ae0-23d6cb7e5549',
  tellwise_aws_bucket: 'https://tellwise-sites.s3.amazonaws.com',
  zoominfo_app: 'https://app.zoominfo.com',
  marketingos_app: 'https://marketingos.zoominfo.com',
  recruiter_app: 'https://recruiter-app.zoominfo.com',
  zoominfo_app_search: 'https://app.zoominfo.com/#/apps/search',
  zoominfo_web: 'https://www.zoominfo.com',
  engage_backend_url: 'https://engage-app.zoominfo.com/dozi-connector',
  sms_messenging_service_url: 'https://messenger.prod.us-west-2.inf.tellwise.com/messenger-service',
  parseSignatureFromSentEmails: true,
  sf_live_chat_id: '5733p000000PCrh',
  sf_live_chat_org_id: '00D1U0000019dCp',
  knowledgeCenterUrl: 'https://university.zoominfo.com',
  helpCenterUrl: 'https://www.zoominfo.com/about/help-center',
  gce_internal_charging: false,
  orgIdsBlackList: [],
  debugLoggingVoip: false,
  university: 'https://university.zoominfo.com/access/jwt?jwt=',
  enable_dd: true,
  enable_tag_management: false,
  sisenseServer: 'https://zoominfo.sisense.com',
  enable_auth_token_local_storage_removed_listener: false,
  launchDarkly: {
    clientSideId: '61546f7856be6023556251a9',
    options: {},
  },
  email_provider_connection_check_org_ids: [], // org ids whitelist for email provider connection service
  zoominfo_login_url: 'https://login.zoominfo.com',
  recruiter_url: 'https://recruiter-app.zoominfo.com',
  admin_url: 'https://admin.zoominfo.com',
  dialer_url: 'https://dialer.prod.us-west-2.inf.tellwise.com/rest',
  use_standalone_engage_auth: false,
  analytics_iframe_url: 'https://customer-facing-analytics.zoominfo.com/dashboard/',
};
