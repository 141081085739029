import { DoziJWT } from './dozi-jwt.model';
import { MfaType } from './enums/mfa-types.model';

export class DoziJWTResponse extends DoziJWT {
  type: MfaType | unknown;
  email: string;
  phone: string;
  token: string;

  constructor(partial: Partial<DoziJWTResponse>) {
    super();
    Object.assign(this, partial);
  }
}
