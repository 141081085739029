export enum SmsMessageStatus {
  ACCEPTED = 'accepted',
  RECEIVED = 'received',
  RECEIVING = 'receiving',
  QUEUED = 'queued',
  DELIVERED = 'delivered',
  OPTED_OUT = 'opted-out',
  OPTED_IN = 'opted-in',
  UNDELIVERED = 'undelivered',
  FAILED = 'failed',
  SENDING = 'sending',
  SENT = 'sent',
}
