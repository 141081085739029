import { Injectable } from '@angular/core';
import { CallInfo } from '@app/caller/interface/call-info';
import { EngageModeCallEntity } from '@zi-pages/engage-mode-v2/model/engage-mode-call-entity.model';

@Injectable()
export class EngageModeCallerStorageV2Service {
  private engageModeCallInfo: Array<EngageModeCallEntity> = [];

  constructor() {}

  setCallInfoForContact(contactId: number, callInfo: CallInfo) {
    const engageModeCallEntities = [...this.engageModeCallInfo];
    const existingEntity: EngageModeCallEntity = engageModeCallEntities.find((entity) => entity.contactId === contactId);
    if (existingEntity) {
      existingEntity.callInfo = { ...callInfo };
    } else {
      engageModeCallEntities.push({ contactId, callInfo });
    }
    this.engageModeCallInfo = engageModeCallEntities;
  }

  getCallInfoForContact(contactId: number): EngageModeCallEntity {
    const engageModeCallEntities = [...this.engageModeCallInfo];
    return engageModeCallEntities.find((entity) => entity.contactId === contactId);
  }

  removeCallInfoForContact(contactId: number) {
    const engageModeCallEntities = [...this.engageModeCallInfo];
    const existingEntityIndex = engageModeCallEntities.findIndex((entity) => entity.contactId === contactId);
    if (existingEntityIndex !== -1) {
      engageModeCallEntities.splice(existingEntityIndex, 1);
    }
    this.engageModeCallInfo = engageModeCallEntities;
  }

  resetCallInfo() {
    this.engageModeCallInfo = [];
  }
}
