import { Injectable } from '@angular/core';
import { PermissionsService } from '@app/core/ngxPermissions/permissions.service';
import { doziAccessTokenCookieName, doziCookieDomain, doziParseSessionToken, doziUserId, doziZiid, doziZisession } from '@app/login/login.config';
import { ZicLogoMenuItem } from '@zoominfo/component-library/components/icon-button-menu/menu-item.model';
import { CookieService } from 'ngx-cookie-service';
import { NgxPermissionsObject } from 'ngx-permissions';
import { ZicWaffleMenuItem, salesMenuItem, recruiterMenuItem, marketingMenuItem, engageMenuItem, UmsToken, adminMenuItem } from './ums-waffle-navbar.config';
import { UtilitiesService } from '@zi-common/service/utilities-service/utilities-service';

@Injectable({
  providedIn: 'root',
})
export class UmsWaffleNavbarService {
  userApps = [];
  supportedPlatforms: Record<string, ZicWaffleMenuItem> = {
    DOZI: salesMenuItem,
    RECRUITER: recruiterMenuItem,
    MARKETING: marketingMenuItem,
    ENGAGE: engageMenuItem,
    ADMIN: adminMenuItem,
  };
  userPermissions: NgxPermissionsObject;

  constructor(private permissionsService: PermissionsService, private cookieService: CookieService, private utilitiesService: UtilitiesService) {
    this.userPermissions = this.permissionsService.getPermissions();
  }

  get umsToken(): UmsToken {
    return this.utilitiesService.decodeJWT(this.cookieService.get(doziAccessTokenCookieName));
  }

  populateUserApps(): void {
    const userZiPlatforms = [...this.utilitiesService.decodeJWT(this.cookieService.get(doziAccessTokenCookieName))?.ziPlatforms] ?? [];

    this.userApps = userZiPlatforms
      .filter((platform) => !!this.supportedPlatforms[platform])
      .map((platform) => this.supportedPlatforms[platform])
      .sort((item1, item2) => item1.index - item2.index);
  }

  clearSessionCookies(): void {
    const cookiesDomain = doziCookieDomain;
    this.cookieService.delete(doziZiid, '/', cookiesDomain, true, 'None');
    this.cookieService.delete(doziZisession, '/', cookiesDomain, true, 'None');
    this.cookieService.delete(doziParseSessionToken, '/', cookiesDomain, true, 'None');
    this.cookieService.delete(doziUserId, '/', cookiesDomain, true, 'None');
    this.cookieService.delete(doziAccessTokenCookieName, '/', cookiesDomain, true, 'None');
  }

  public get isAuthenticated(): boolean {
    return (
      this.cookieService.check(doziParseSessionToken) &&
      this.cookieService.check(doziUserId) &&
      this.cookieService.check(doziZiid) &&
      this.cookieService.check(doziZisession) &&
      this.cookieService.check(doziAccessTokenCookieName)
    );
  }

  /**
   * If user has at least 2 application access
   */
  showWaffle(): boolean {
    this.populateUserApps();
    return this.userApps.length > 1;
  }

  getWaffleMenuItems(): ZicLogoMenuItem[] {
    this.populateUserApps();
    return this.userApps;
  }
}
