import { Injectable } from '@angular/core';
import { AnalyticsEvent } from '@zi-core/enums/analytics-event.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AnalyticsService } from '@app/core/service/analytics.service';
import { PdmApplication, PdmPlatform, PdmProductCategory, PdmProductType } from '@app/core/enums/pdm-names.constant';
import { getExtensionMode } from '@app/extensionMode.config';
import { Store } from '@ngrx/store';
import { ApplicationState } from '@app/reducers';
import { extSizeSelector } from '@app/extension/ngrx/state/extension.state';
import { map } from 'rxjs/operators';
import { DialerErrorEvent } from '../interface/dialer-error-event.model';

@UntilDestroy()
@Injectable()
export class DialerAnalyticsService {
  private extensionSize: string = '';

  constructor(private analyticsService: AnalyticsService, protected store: Store<ApplicationState>) {
    this.store
      .select(extSizeSelector)
      .pipe(untilDestroyed(this), map(this.analyticsService.mapExtensionSize))
      .subscribe((extensionSize: string) => {
        this.extensionSize = extensionSize;
      });
  }

  public sendDialerErrorAnalyticsEvent(errorDetails: string, type?: string, errorCode?: string) {
    const dialerErrorAmplitudeObj: DialerErrorEvent = {
      type,
      errorCode,
      errorDetails,
      PDM_PLATFORM: PdmPlatform.ENGAGE,
      PDM_APPLICATION: getExtensionMode() ? PdmApplication.CHROME_EXTENSION : PdmApplication.WEB,
      PDM_PRODUCT_TYPE: PdmProductType.ENGAGE,
      PDM_PRODUCT_CATEGORY: PdmProductCategory.ENGAGE,
      PDM_APPLICATION_ATTRIBUTE: this.extensionSize,
    };
    this.analyticsService.send(AnalyticsEvent.DIALER_ERROR, dialerErrorAmplitudeObj);
  }
}
