<app-dialog-template [ngClass]="{'gmail-ext': isExtOnGMail}" [dialogTitle]="dialogTitle" (close)="close()" [automationId]="'app-dialog-template'">
  <app-dialog-content [automationId]="'app-dialog-content'">
    <div class="preview-container">
      <app-email-preview
        #EmailPreviewComponent
        *ngIf="!isLoading; else loader"
        [attachments]="attachments"
        [hasEmailSelf]="false"
        [rawMode]="true"
        [contacts]="contacts"
        [subject]="dialogSubject"
        [completeEmail]="completeEmail"
        [content]="templateContent"
        [senderId]="senderId"
      ></app-email-preview>
    </div>
  </app-dialog-content>
  <app-dialog-footer>
    <div class="left">
      <div class="pagination-container" [automationId]="'pagination-container'">
        <div class="pagination">
          <button
            class="arrow"
            type="button"
            (click)="onPreviousClicked()"
            [disabled]="!contacts || contacts?.length === 0 || currentContactIndex + 1 === 1"
            [automationId]="'arrow-left'"
          >
            <i class="icon-arrow_left"></i>
          </button>
          <div class="pagination-number">
            <div [ngClass]="{'bold' : contacts && contacts?.length !== 0}" [automationId]="'current-selected-page-'+currentContactIndex+1">
              {{ (!contacts || contacts?.length === 0) ? 0 : currentContactIndex+1 }}
            </div>
            /
            <div>{{ contacts && contacts?.length ? contacts?.length : 0}}</div>
          </div>
          <button
            class="arrow"
            type="button"
            (click)="onNextClicked()"
            [disabled]="!contacts || currentContactIndex + 1 === contacts?.length || contacts?.length === 0"
            [automationId]="'arrow-right'"
          >
            <i class="icon-arrow_right"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="buttons">
      <div class="close-btn">
        <app-secondary-btn [text]="'Close'" (clickButton)="close()"></app-secondary-btn>
      </div>
    </div>
  </app-dialog-footer>
</app-dialog-template>

<ng-template #loader>
  <div class="loading">
    <app-loader></app-loader>
  </div>
</ng-template>
