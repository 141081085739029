export const doziCookieDomain: string = '.zoominfo.com';
export const doziZiid: string = 'ziid';
export const doziZisession: string = 'zisession';
export const doziParseSessionToken: string = 'parseSessionToken';
export const doziUserId: string = 'userId';
export const doziAccessTokenCookieName: string = 'ziaccesstoken';
export const doNotShowReplaceContactInEngageMode: string = 'replaceContactCookie';

export enum supportedZiPlatforms {
  DOZI = 'DOZI',
  RECRUITER = 'RECRUITER',
  MARKETING = 'MARKETING',
  ENGAGE = 'ENGAGE',
  ADMIN = 'ADMIN',
}
