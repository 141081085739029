import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  AdaLiveAgentDisconnectAction,
  AdaLiveAgentDisconnectSuccessAction,
  AdaLiveAgentInitFailureAction,
  AdaLiveAgentInitSuccessAction,
  StartAdaLiveAgentInitAction,
} from '@zi-pages/ada-live-chat/action/ada-live-agent.action';
import { catchError, filter, map, mergeMap, take } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { getOrganization, getProfile, getZoomRoles } from '@zi-core/ngrx/state/auth.state';
import * as _ from 'lodash';
import { OrganizationService } from '@zi-common/service/organization/organization.service';
import { ApplicationState } from '@app/reducers';
import { Store } from '@ngrx/store';
import { LoggerServiceToken } from '@zi-core/config/logger-service.config';
import { ILoggerService } from '@zi-core/interface/logger.service.interface';

@Injectable()
export class AdaLiveAgentEffect {
  constructor(
    private action$: Actions,
    private orgService: OrganizationService,
    private appStore: Store<ApplicationState>,
    @Inject(LoggerServiceToken) private loggerService: ILoggerService,
  ) {}

  startAdaLiveAgentInit$ = createEffect(() => {
    return this.action$.pipe(
      ofType(StartAdaLiveAgentInitAction),
      mergeMap(() => {
        return forkJoin([
          this.appStore.select(getProfile).pipe(
            take(1),
            map((profile) => profile),
          ),
          this.appStore.select(getOrganization).pipe(take(1)),
          // get zoomRoles from state
          this.appStore.select(getZoomRoles).pipe(
            filter((zoomRoles) => !!zoomRoles),
            take(1),
          ),
        ]);
      }),
      map(([profile, orgInfo, zoomRoles]) => {
        const userName = _.get(profile, 'email', '');
        (window as any).adaEmbed.start({
          handle: 'zoominfo',
          metaFields: {
            'Client Name': _.get(orgInfo, 'organization.title', ''),
            Username: userName,
            'Account Name': _.get(profile, 'company', ''),
            'Client Phone': _.get(profile, 'attributes.phone', ''),
            'Platform Contact ID': _.get(profile, 'userId', ''),
            'Client Email': userName,
            entitlements: zoomRoles && zoomRoles.length > 0 ? zoomRoles : [],
          },
          hideMask: true,
        });
      }),
      map(() => {
        return AdaLiveAgentInitSuccessAction();
      }),
      catchError((err) => {
        this.loggerService.error('Error initializing Ada Live Agent :: ', err);
        return of(AdaLiveAgentInitFailureAction(err));
      }),
    );
  });

  disconnectAdaLiveAgent$ = createEffect(() => {
    return this.action$.pipe(
      ofType(AdaLiveAgentDisconnectAction),
      map(() => {
        // @ts-ignore
        window.adaEmbed.stop();
        return AdaLiveAgentDisconnectSuccessAction();
      }),
    );
  });
}
