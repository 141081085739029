import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Message } from '../../model/message/message.model';
import * as uuid from 'uuid';
import { EngageVersionStringTransformService } from '@zi-common/service/engage-version-string-transform/engage-version-string-transform.service';
import { EngageVersionEnum } from '@app/core/enums/engage-version.enum';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotyService {
  /**
   * An Observable from type Subject. Gets invoked when "postMessage" is used.
   * @returns Object from type "Message"
   * **/
  messageAdded = new Subject<Message[]>();
  private messages: Message[] = [];

  constructor(private transformService: EngageVersionStringTransformService) {}

  /**
   * Post a new message, responsible for storing the message and inform all the components subscribing to this change
   * @param message {Message}
   * **/
  postMessage(message: Message) {
    if (!message.id) {
      message.id = uuid.v4();
    }
    setTimeout(() => {
      this.removeMessage(message.id);
    }, message.delay);

    this.transformService.userRole$.pipe(take(1)).subscribe((userRole: EngageVersionEnum) => {
      const transformedText = this.transformService.getTransformedText(userRole, message.content);
      message.content = transformedText;
      this.storeMessage(message);
      return this.messageAdded.next(this.messages);
    });
  }

  /**
   * Store message in messages array
   * Limits the messages array to the last 20 messages.
   * @param message {Message} accept a message object from type Message
   * */
  storeMessage(message: Message) {
    this.messages.push(message);
    this.messages = this.messages.slice(-20); // FIXME - maybe should move to a config??
  }

  /**
   * Get all the stored messages
   * @returns A copy of all the current stored messages array {Message[]}
   * */
  getMessages() {
    return this.messages.slice();
  }

  /**
   * Remove message in messages array
   * @param messageId {string} accept a message object ID from type Message
   * */
  removeMessage(messageId: string) {
    this.messages.forEach((message, idx) => {
      if (message.id == messageId) {
        this.messages.splice(idx, 1);
      }
    });
    return this.messageAdded.next(this.messages);
  }
}
