import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NEWS_CATEGOTY_TYPE } from '@app/common/pages-component/zoominfo-details/scoops-and-news-tab/zoominfo-news/news-category/news-category.config';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { NewsRequest, NewsResponse, News } from '@app/common/interface/news';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  constructor(private httpClient: HttpClient) {}

  getNews(companyId: number, count: number = 10, categories: NEWS_CATEGOTY_TYPE[] = []): Observable<News[]> {
    const body: NewsRequest = {
      categories,
      count,
    };
    return this.httpClient.post<NewsResponse>(`${environment.engage_backend_url}/data/news/${companyId}`, body).pipe(map((res: NewsResponse) => res.news));
  }
}
