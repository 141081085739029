export enum ExtensionMessages {
  TO_TWENTY_PCT = 'toTwentyPct',
  TO_FIFTY_PCT = 'toFiftyPct',
  TO_NINETY_PCT = 'toNinetyPct',
  MINIMIZE = 'minimize',
  CLOSE = 'close',
  APP_INITIALIZED = 'appInitialized',
  IS_LOGGED_IN = 'isLoggedIn',
  LOGGED_OUT = 'loggedOut',
  START_LOADING = 'loadingStarted',
  FINISH_LOADING = 'loadingFinished',
  START_PARSING_PAGE = 'startParsingPage',
  SEND_PARSED_INFO = 'sendParsedInfo',
  GET_PARSED_INFO = 'getParsedInfo',
  RELOAD_PARSED_INFO = 'reloadParsedInfo',
  LOGOUT = 'logout',
  LOAD_CONTACT = 'loadContact',
  UPDATE_CONTACT = 'updateContact',
  REMOVE_CONTACT = 'removeContact',
  CREATE_CONTACT = 'createContact',
}

export enum ExtensionErrors {
  DOZI_UNKNOWN_ERROR = '',
  DOZI_CONNECTION_ERROR = 'To continue please <br>',
  DOZI_OUT_OF_CREDITS_ERROR = 'You do not have enough SalesOS credits for this action.',
  DOZI_TROUBLESHOOT_ERROR = 'Unknown error has occurred, please send an email to <a href="mailto:engagesupport@zoominfo.com" target="_blank">engagesupport@zoominfo.com</a> and include this error code:',
}

export enum DoziErrorCode {
  UNKNOWN = 0,
  NOT_CONNECTED = 999,
  OUT_OF_CREDITS = 2000,
  DOZI_PLATFORM_REFRESH_TOKEN_EXPIRED = 9815,
}
