import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { map, share, take, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { ApplicationState } from '@app/reducers';
import { getValidAuthToken } from '@app/core/ngrx/state/auth.state';
import { Profile } from '@zi-core/data-model/profile.model';
import { UserOrganization } from '@zi-core/data-model/user-organization.model';
import { NewUserRequest, OrgMemberRequestParams, OrgMemberResponse, OrgMemberUpdateRoleParams } from '@zi-core/http-model/response/organization.model';
import * as _ from 'lodash';
import { OwnerColorService } from '@zi-core/service/owner-color.service';
import { NBCreditUsage } from '@app/pages/admin/pages/org-management/neverbounce/neverbounce.config';

@Injectable({
  providedIn: 'root',
})
export class OrgMemberService {
  private orgId: number;
  private getMembers$: Observable<OrgMemberResponse>;
  private loading = false;
  profiles: Profile[];
  orgMembers: UserOrganization[];
  private orgMembersAndProfilesSubject = new ReplaySubject<OrgMemberResponse>(1);

  constructor(private httpClient: HttpClient, private store: Store<ApplicationState>, private ownerColorService: OwnerColorService) {}

  init() {
    this.store.pipe(take(1), select(getValidAuthToken)).subscribe((authToken) => {
      this.orgId = authToken.orgId;

      this.getOrgDetails().subscribe((res) => {
        this.profiles = res?.profiles?.map((profile) => {
          return {
            ...profile,
            color: this.ownerColorService.getColorByOwnerId(profile.userId),
          };
        });
        this.orgMembers = res.orgMembers;
        this.orgMembersAndProfilesSubject.next({ orgMembers: this.orgMembers, profiles: this.profiles });
      });
    });
  }

  private getOrgDetails(): Observable<OrgMemberResponse> {
    if (this.loading) {
      return this.getMembers$;
    }

    this.loading = true;

    this.getMembers$ = this.httpClient.get<OrgMemberResponse>(`${environment.backend_url}/v1/org/users?OrgId=${this.orgId}`).pipe(
      share(),
      tap(() => {
        this.loading = false;
        this.getMembers$ = null;
      }),
    );
    return this.getMembers$;
  }

  /**
   * For new implementation please refer getUserProfileById fn as it has a concrete return type.
   * TODO to cleanup
   * @deprecated
   */
  getProfileById(id): Observable<any> {
    if (this.profiles) {
      return of(this.profiles.find((member) => member.userId === id));
    }

    return this.getOrgDetails().pipe(
      map((res) => {
        const profile = res?.profiles?.find((member) => member.userId === id);
        if (profile) {
          profile.color = this.ownerColorService.getColorByOwnerId(profile.userId);
        }
        return of(profile);
      }),
    );
  }

  /**
   * return Profile by Email
   * @param email: string
   */
  getUserProfileByEmail(email: string): Observable<Profile> {
    if (this.profiles) {
      return of(this.profiles.find((member) => member.email?.trim() === email?.trim()));
    }

    return this.getOrgDetails().pipe(
      map((res: OrgMemberResponse) => _.get(res, 'profiles') || []),
      map((profiles: Profile[]) => {
        const profile = profiles.find((member: Profile) => member.email?.trim() === email?.trim());
        return profile;
      }),
    );
  }

  /**
   * Since the above getProfileById fn impl seems to be confused about what to return, this new fn has been created.
   * This fn returns the profile object based on the user id as input.
   * Note: Changing above impl will need some regression testing as it's widely used.
   * Hence for new implementation please use this fn.
   */
  getUserProfileById(id): Observable<Profile> {
    if (this.profiles) {
      return of(this.profiles.find((member) => member.userId === id));
    }

    return this.getOrgDetails().pipe(
      map((res: OrgMemberResponse) => _.get(res, 'profiles') || []),
      map((profiles: Profile[]) => {
        const profile = profiles.find((member: Profile) => member.userId === id);
        if (profile) {
          profile.color = this.ownerColorService.getColorByOwnerId(profile.userId);
        }
        return profile;
      }),
    );
  }

  /**
   * Get profiles from list of ids
   * @param ids - list of ids
   */
  public getProfilesByIds(ids: number[]): Observable<Profile[]> {
    if (this.profiles) {
      return of(ids.map((id) => this.profiles.find((member) => member.userId === id)).filter((profile) => !!profile));
    }

    return this.getOrgDetails().pipe(
      map((res) => {
        return ids
          .map((id) => {
            const profile = res?.profiles?.find((member) => member?.userId === id);
            if (profile) {
              profile.color = this.ownerColorService.getColorByOwnerId(profile?.userId);
            }
            return profile;
          })
          .filter((profile) => !!profile);
      }),
    );
  }

  getTimezoneById(id): Observable<any> {
    return this.getOrgDetails().pipe(
      map((res) => {
        const profile = res.profiles.find((member) => member.userId === id);
        return _.get(profile, 'attributes.timeZoneId', 'America/Los_Angeles');
      }),
    );
  }

  updateMember(memberParamsToUpdate: OrgMemberRequestParams) {
    return this.httpClient.put<any>(`${environment.backend_url}/v1/org/users`, memberParamsToUpdate).pipe(
      share(),
      tap((updatedMember: UserOrganization) => {
        this.orgMembers = this.orgMembers.map((orgMember) =>
          orgMember.userId === updatedMember.userId
            ? {
                ...updatedMember,
                roleId: memberParamsToUpdate?.roleId,
              }
            : {
                ...orgMember,
                roleId: orgMember?.roleId,
              },
        );
        this.orgMembersAndProfilesSubject.next({ orgMembers: this.orgMembers, profiles: this.profiles });
      }),
    );
  }

  updateMemberRole(memberParamsToUpdate: OrgMemberUpdateRoleParams) {
    this.orgMembers = this.orgMembers.map((orgMember) =>
      orgMember.userId === memberParamsToUpdate.userId
        ? {
            ...orgMember,
            roleId: memberParamsToUpdate?.roleId,
          }
        : orgMember,
    );

    this.orgMembersAndProfilesSubject.next({ orgMembers: this.orgMembers, profiles: this.profiles });
  }

  updateMemberInvitation(memberParamsToUpdate: OrgMemberRequestParams) {
    return this.httpClient.post<any>(`${environment.backend_url}/v1/org/users`, memberParamsToUpdate).pipe(
      share(),
      tap((updatedMember: UserOrganization) => {
        this.orgMembers = this.orgMembers.map((orgMember) => (orgMember.userId === updatedMember.userId ? updatedMember : orgMember));
        this.orgMembersAndProfilesSubject.next({ orgMembers: this.orgMembers, profiles: this.profiles });
      }),
    );
  }

  sendInvitationEmail(memberParamsToUpdate: OrgMemberRequestParams) {
    return this.httpClient.post(`${environment.backend_url}/v1/org/user/invitation`, memberParamsToUpdate, { responseType: 'text' }).pipe(
      map((res) => {
        return res == 'Invitation Email Has Been Sent' ? res : null;
      }),
    );
  }

  getOrgMembersObservable(): Observable<OrgMemberResponse> {
    return this.orgMembersAndProfilesSubject.asObservable();
  }

  deleteMember(userId: number) {
    return this.httpClient.delete<any>(`${environment.backend_url}/v1/org/users?UserId=${userId}&OrgId=${this.orgId}`).pipe(
      share(),
      tap(() => {
        this.orgMembers = this.orgMembers.filter((orgMember) => orgMember.userId !== userId);
        this.profiles = this.profiles.filter((orgMember) => orgMember.userId !== userId); // remove userId from profiles
        this.orgMembersAndProfilesSubject.next({ orgMembers: this.orgMembers, profiles: this.profiles });
      }),
    );
  }

  saveNewUser(params: NewUserRequest): Observable<OrgMemberResponse> {
    const reqParams = { ...params, orgId: this.orgId };
    return this.httpClient.post<OrgMemberResponse>(`${environment.backend_url}/v2/org/user`, reqParams).pipe(
      share(),
      tap((res: OrgMemberResponse) => {
        this.profiles = this.profiles.concat(res.profiles[0]);
        this.orgMembers = this.orgMembers.concat({
          ...res.orgMembers[0],
          roleId: params?.roleId,
        });
        this.orgMembersAndProfilesSubject.next({ orgMembers: this.orgMembers, profiles: this.profiles });
      }),
    );
  }

  clearData(): void {
    delete this.orgId;
    delete this.profiles;
    delete this.orgMembers;
  }

  getNeverbounceOrgUsage(): Observable<NBCreditUsage> {
    return this.httpClient.get<NBCreditUsage>(`${environment.engage_backend_url}/user/neverbounce/usage`).pipe(map((nbCredit) => nbCredit));
  }
}
