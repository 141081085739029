import { Action, createReducer, on } from '@ngrx/store';
import { GmailPluginState, initialGmailPluginState } from '@app/gmail-plugin/ngrx/state/gmail-plugin.state';
import { GmailContactLoaded, GmailPreviewLoaded, GmailSalesflowContactsLoaded } from '@app/gmail-plugin/ngrx/action/gmail-plugin.action';

const gmailPluginReducer = createReducer(
  initialGmailPluginState,
  on(GmailContactLoaded, (state: GmailPluginState, action) => {
    return { ...state, gmailContactCard: action.contact };
  }),
  on(GmailSalesflowContactsLoaded, (state: GmailPluginState, action) => {
    return { ...state, salesflowContacts: action.contacts };
  }),
  on(GmailPreviewLoaded, (state: GmailPluginState, action) => {
    return { ...state, previewData: action.previewData };
  }),
);
export function GmailPluginReducer(state: GmailPluginState, action: Action): GmailPluginState {
  return gmailPluginReducer(state, action);
}
