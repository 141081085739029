import { defaultLoadingState } from '@zi-core/ngrx/config/ngrx-loading-states.config';
import { SmsConversationDto } from '@zi-pages/sms/model/sms-conversation.dto';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SmsUserConversationItem, SmsUserConversationsDto } from '@zi-pages/sms/model/sms-user-conversations.dto';

export class SmsContactListState {
  list: SmsUserConversationsDto;
  loading: boolean;
  loaded: boolean;
  error: {
    hasError: boolean;
    error: any;
  };
}

export class SmsConversationState {
  smsConversation: SmsConversationDto;
  selectedContactInfo: SmsUserConversationItem;
  sendMsgLoading: boolean;
  loading: boolean;
  loaded: boolean;
  error: {
    hasError: boolean;
    error: any;
  };
}

export class SmsMessageState {
  totalUnreadSMSPresent: number;
  smsContactList: SmsContactListState;
  selectedConversation: SmsConversationState;
}

export const initialSmsMessageState: SmsMessageState = {
  totalUnreadSMSPresent: 0,
  smsContactList: {
    list: {
      conversations: [],
    },
    ...defaultLoadingState,
  },
  selectedConversation: {
    smsConversation: null,
    selectedContactInfo: null,
    sendMsgLoading: false,
    ...defaultLoadingState,
  },
};

export const smsMessageFeatureSelector = createFeatureSelector<SmsMessageState>('smsMessage');

export const smsMessageSmsConvoStateSelector = createSelector(smsMessageFeatureSelector, (state: SmsMessageState) => state.selectedConversation);

export const smsMessageLoadedConvoContactSelector = createSelector(smsMessageSmsConvoStateSelector, (state: SmsConversationState) => state.selectedContactInfo);

export const smsMessageLoadedConvoSelector = createSelector(smsMessageSmsConvoStateSelector, (state: SmsConversationState) => state.smsConversation);

export const smsMessageSelectedConvoLoading = createSelector(smsMessageSmsConvoStateSelector, (selectedConvo: SmsConversationState) => selectedConvo.loading);

export const smsMessageSendMsgLoading = createSelector(smsMessageSmsConvoStateSelector, (selectedConvo: SmsConversationState) => selectedConvo.sendMsgLoading);

export const smsMessageContactListStateSelector = createSelector(smsMessageFeatureSelector, (state: SmsMessageState) => state.smsContactList);

export const smsMessageContactListSelector = createSelector(
  smsMessageContactListStateSelector,
  (smsContactListState: SmsContactListState) => smsContactListState.list,
);

export const smsMessageContactListLoading = createSelector(
  smsMessageContactListStateSelector,
  (smsContactListState: SmsContactListState) => smsContactListState.loading,
);

export const totalUnreadSMSStateSelector = createSelector(smsMessageFeatureSelector, (state: SmsMessageState) => state.totalUnreadSMSPresent);
