import { DialerSelection } from '@app/core/enums/dialer.enum';

export const MAX_VOICEDROPS_LIMIT = 20;
export const EXISTING_TITLE_NAME_ERROR = 'This name already exists, please choose another name';
export const VOICEMAIL_PRE_RECORD_TIMEOUT = 9000;

export const DISABLE_VOICEMAIL_MSG_ACTIVE_CALL = 'A voicemail cannot be recorded when a call is active';
export const DISABLE_VOICEMAIL_DROP_MSG_ACTIVE_GREET = 'A voicemail drop cannot be recorded when a voicemail greeting is in progress';
export const DISABLE_VOICEMAIL_GREET_MSG_ACTIVE_DROP = 'A voicemail greeting cannot be recorded when a voicemail drop is in progress';
export const DISABLE_VOICEMAIL_DROP_MAX_LIMIT = `Limit of ${MAX_VOICEDROPS_LIMIT} Voicemail Drops`;

export type PhoneDetailsPerDialer = {
  [dialerType in DialerSelection.VOIP | DialerSelection.BRIDGE]: PhoneDetails;
};

export interface PhoneDetails {
  phoneNumber: string;
  phoneId: string;
}
