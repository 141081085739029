<app-dialog-template
  [automationId]="'create-new-contact-modal-title'"
  [dialogTitle]="mode === contactCreateMode.create ? 'Add Contact' : 'Update Contact'"
  (close)="close()"
>
  <app-dialog-content class="overflow-content">
    <div class="contact-form-container">
      <form [formGroup]="formGroup">
        <div class="item-row">
          <div class="sec-name">
            <i class="header-icon" [ngClass]="contactCreateConfigVariable.contactHeaderIcon"></i>
            <span [ngClass]="{'field-header': true}">Contact Details</span>
          </div>
        </div>
        <div class="item-row">
          <div class="sec-name">First Name<sup>*</sup></div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-first-name'"
              formControlName="firstName"
              class="input-element"
              [ngClass]="{'text-field-error': formGroup.get('firstName').invalid && this.formGroup.get('firstName').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderFirstName"
            />
            <div class="error-text" *ngIf="(formGroup.get('firstName').invalid && formGroup.get('firstName').touched)">
              <span><app-validation-errors [errors]="formGroup.get('firstName').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <div class="item-row">
          <div class="sec-name">Last Name<sup>*</sup></div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-last-name'"
              formControlName="lastName"
              class="input-element"
              [ngClass]="{'text-field-error': formGroup.get('lastName').invalid && this.formGroup.get('lastName').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderLastName"
            />
            <div class="error-text" *ngIf="(formGroup.get('lastName').invalid && formGroup.get('lastName').touched)">
              <span><app-validation-errors [errors]="formGroup.get('lastName').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <div class="item-row">
          <div class="sec-name">Email<sup>*</sup></div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-email'"
              formControlName="email"
              class="input-element"
              [pattern]="contactCreateConfigVariable.emailPattern"
              [ngClass]="{'text-field-error': formGroup.get('email').invalid && this.formGroup.get('email').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderEmail"
            />
            <div class="error-text" *ngIf="(formGroup.get('email').invalid && formGroup.get('email').touched)">
              <span><app-validation-errors [errors]="formGroup.get('email').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <div class="item-row">
          <div class="sec-name">Job Title</div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-jobtitle'"
              formControlName="jobTitle"
              class="input-element"
              [ngClass]="{'margin': formGroup.get('jobTitle').invalid, 'error': formGroup.get('jobTitle').invalid && this.formGroup.get('jobTitle').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderJobTitle"
            />
            <div class="error-text" *ngIf="(formGroup.get('jobTitle').invalid && formGroup.get('jobTitle').touched)">
              <span><app-validation-errors [errors]="formGroup.get('jobTitle').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <div class="item-row">
          <div class="sec-name">Department</div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-department'"
              formControlName="department"
              class="input-element"
              [ngClass]="{'margin': formGroup.get('department').invalid, 'error': formGroup.get('department').invalid && this.formGroup.get('department').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderDepartment"
            />
            <div class="error-text" *ngIf="(formGroup.get('department').invalid && formGroup.get('department').touched)">
              <span><app-validation-errors [errors]="formGroup.get('department').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <div class="item-row">
          <div class="sec-name">Salutation</div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-salutation'"
              formControlName="salutation"
              class="input-element"
              [ngClass]="{'margin': formGroup.get('salutation').invalid, 'error': formGroup.get('salutation').invalid && this.formGroup.get('salutation').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderSalutation"
            />
            <div class="error-text" *ngIf="(formGroup.get('salutation').invalid && formGroup.get('salutation').touched)">
              <span><app-validation-errors [errors]="formGroup.get('salutation').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <div class="item-row" [ngClass]="{'field-bordered': true}">
          <div class="sec-name">Description</div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-description'"
              formControlName="description"
              class="input-element"
              [ngClass]="{'margin': formGroup.get('description').invalid, 'error': formGroup.get('description').invalid && this.formGroup.get('description').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderDescription"
            />
            <div class="error-text" *ngIf="(formGroup.get('description').invalid && formGroup.get('description').touched)">
              <span><app-validation-errors [errors]="formGroup.get('description').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <!--Communication fields below-->
        <div class="item-row">
          <div class="sec-name">
            <i class="header-icon" [ngClass]="contactCreateConfigVariable.communicationHeaderIcon"></i>
            <span [ngClass]="{'field-header': true}">Communication Details</span>
          </div>
        </div>
        <div class="item-row">
          <div class="sec-name">Phone</div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-phone'"
              formControlName="phone"
              class="input-element"
              [ngClass]="{'margin': formGroup.get('phone').invalid, 'error': formGroup.get('phone').invalid && this.formGroup.get('phone').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderPhone"
            />
            <div class="error-text" *ngIf="(formGroup.get('phone').invalid && formGroup.get('phone').touched)">
              <span><app-validation-errors [errors]="formGroup.get('phone').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <div class="item-row">
          <div class="sec-name">Mobile Phone</div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-mobilephone'"
              formControlName="mobilePhone"
              class="input-element"
              [ngClass]="{'margin': formGroup.get('mobilePhone').invalid, 'error': formGroup.get('mobilePhone').invalid && this.formGroup.get('mobilePhone').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderPhone"
            />
            <div class="error-text" *ngIf="(formGroup.get('mobilePhone').invalid && formGroup.get('mobilePhone').touched)">
              <span><app-validation-errors [errors]="formGroup.get('mobilePhone').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <div class="item-row" [ngClass]="{'field-bordered': true}" *ngIf="!contactCreateConfigVariable.showMoreCommunicationFlag">
          <a (click)="toggleAdditionalCommunicationFields()" [automationId]="'more-communication-details'"> More Communication Details </a>
        </div>
        <div class="item-row" *ngIf="contactCreateConfigVariable.showMoreCommunicationFlag">
          <div class="sec-name">Home Phone</div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-homephone'"
              formControlName="homePhone"
              class="input-element"
              [ngClass]="{'margin': formGroup.get('homePhone').invalid, 'error': formGroup.get('homePhone').invalid && this.formGroup.get('homePhone').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderPhone"
            />
            <div class="error-text" *ngIf="(formGroup.get('homePhone').invalid && formGroup.get('homePhone').touched)">
              <span><app-validation-errors [errors]="formGroup.get('homePhone').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <div class="item-row" *ngIf="contactCreateConfigVariable.showMoreCommunicationFlag">
          <div class="sec-name">Assistant Phone</div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-assistantphone'"
              formControlName="assistantPhone"
              class="input-element"
              [ngClass]="{'margin': formGroup.get('assistantPhone').invalid, 'error': formGroup.get('assistantPhone').invalid && this.formGroup.get('assistantPhone').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderPhone"
            />
            <div class="error-text" *ngIf="(formGroup.get('assistantPhone').invalid && formGroup.get('assistantPhone').touched)">
              <span><app-validation-errors [errors]="formGroup.get('assistantPhone').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <div class="item-row" *ngIf="contactCreateConfigVariable.showMoreCommunicationFlag">
          <div class="sec-name">HQ Phone</div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-hqphone'"
              formControlName="hqPhone"
              class="input-element"
              [ngClass]="{'margin': formGroup.get('hqPhone').invalid, 'error': formGroup.get('hqPhone').invalid && this.formGroup.get('hqPhone').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderPhone"
            />
            <div class="error-text" *ngIf="(formGroup.get('hqPhone').invalid && formGroup.get('hqPhone').touched)">
              <span><app-validation-errors [errors]="formGroup.get('hqPhone').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <div class="item-row" *ngIf="contactCreateConfigVariable.showMoreCommunicationFlag">
          <div class="sec-name">Other Phone</div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-otherphone'"
              formControlName="otherPhone"
              class="input-element"
              [ngClass]="{'margin': formGroup.get('otherPhone').invalid, 'error': formGroup.get('otherPhone').invalid && this.formGroup.get('otherPhone').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderPhone"
            />
            <div class="error-text" *ngIf="(formGroup.get('otherPhone').invalid && formGroup.get('otherPhone').touched)">
              <span><app-validation-errors [errors]="formGroup.get('otherPhone').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <div class="item-row" [ngClass]="{'field-bordered': true}" *ngIf="contactCreateConfigVariable.showMoreCommunicationFlag">
          <a (click)="toggleAdditionalCommunicationFields()" [automationId]="'less-communication-details'"> Less Communication Details </a>
        </div>
        <!--Company fields below-->
        <div class="item-row">
          <div class="sec-name">
            <i class="header-icon" [ngClass]="contactCreateConfigVariable.companyHeaderIcon"></i>
            <span [ngClass]="{'field-header': true}">Company</span>
          </div>
        </div>
        <div class="item-row">
          <div class="sec-name">
            Company Name
            <sup *ngIf="isCompanyFieldAllowed">*</sup>
          </div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-company'"
              formControlName="company"
              class="input-element"
              [ngClass]="{'text-field-error': formGroup.get('company').invalid && this.formGroup.get('company').touched && isCompanyFieldAllowed}"
              [placeholder]="contactCreateConfigVariable.placeholderCompanyName"
            />
            <div class="error-text" *ngIf="(formGroup.get('company').invalid && formGroup.get('company').touched) && isCompanyFieldAllowed">
              <span><app-validation-errors [errors]="formGroup.get('company').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <div class="item-row">
          <div class="sec-name">Company Website</div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-companypageurl'"
              formControlName="companyPageUrl"
              class="input-element"
              [ngClass]="{'margin': formGroup.get('companyPageUrl').invalid, 'error': formGroup.get('companyPageUrl').invalid && this.formGroup.get('companyPageUrl').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderCompanyWebsite"
            />
            <div class="error-text" *ngIf="(formGroup.get('companyPageUrl').invalid && formGroup.get('companyPageUrl').touched)">
              <span><app-validation-errors [errors]="formGroup.get('companyPageUrl').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <div class="item-row" [ngClass]="{'field-bordered': true}" *ngIf="!contactCreateConfigVariable.showMoreCompanyFlag">
          <a (click)="toggleAdditionalCompanyFields()" [automationId]="'more-company-details'"> More Company Details </a>
        </div>
        <div class="item-row" *ngIf="contactCreateConfigVariable.showMoreCompanyFlag">
          <div class="sec-name">Industry</div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-industry'"
              formControlName="industry"
              class="input-element"
              [ngClass]="{'margin': formGroup.get('industry').invalid, 'error': formGroup.get('industry').invalid && this.formGroup.get('industry').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderIndustry"
            />
            <div class="error-text" *ngIf="(formGroup.get('industry').invalid && formGroup.get('industry').touched)">
              <span><app-validation-errors [errors]="formGroup.get('industry').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <div class="item-row" *ngIf="contactCreateConfigVariable.showMoreCompanyFlag">
          <div class="sec-name">Annual Revenue</div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-annualrevenue'"
              formControlName="annualRevenue"
              class="input-element"
              [ngClass]="{'margin': formGroup.get('annualRevenue').invalid, 'error': formGroup.get('annualRevenue').invalid && this.formGroup.get('annualRevenue').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderAnnualRevenue"
            />
            <div class="error-text" *ngIf="(formGroup.get('annualRevenue').invalid && formGroup.get('annualRevenue').touched)">
              <span><app-validation-errors [errors]="formGroup.get('annualRevenue').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <div class="item-row" *ngIf="contactCreateConfigVariable.showMoreCompanyFlag">
          <div class="sec-name">Number of Employees</div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-numberofemployees'"
              formControlName="numberOfEmployees"
              class="input-element"
              [ngClass]="{'margin': formGroup.get('numberOfEmployees').invalid, 'error': formGroup.get('numberOfEmployees').invalid && this.formGroup.get('numberOfEmployees').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderNumberOfEmployees"
            />
            <div class="error-text" *ngIf="(formGroup.get('numberOfEmployees').invalid && formGroup.get('numberOfEmployees').touched)">
              <span><app-validation-errors [errors]="formGroup.get('numberOfEmployees').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <div class="item-row" *ngIf="contactCreateConfigVariable.showMoreCompanyFlag">
          <div class="sec-name">Company LinkedIn</div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-companylinkedinurl'"
              formControlName="companyLinkedInUrl"
              class="input-element"
              [ngClass]="{'margin': formGroup.get('companyLinkedInUrl').invalid, 'error': formGroup.get('companyLinkedInUrl').invalid && this.formGroup.get('companyLinkedInUrl').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderCompanyLinkedin"
            />
            <div class="error-text" *ngIf="(formGroup.get('companyLinkedInUrl').invalid && formGroup.get('companyLinkedInUrl').touched)">
              <span><app-validation-errors [errors]="formGroup.get('companyLinkedInUrl').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <div class="item-row" *ngIf="contactCreateConfigVariable.showMoreCompanyFlag">
          <div class="sec-name">Company Twitter</div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-companytwitterurl'"
              formControlName="companyTwitterUrl"
              class="input-element"
              [ngClass]="{'margin': formGroup.get('companyTwitterUrl').invalid, 'error': formGroup.get('companyTwitterUrl').invalid && this.formGroup.get('companyTwitterUrl').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderCompanyTwitter"
            />
            <div class="error-text" *ngIf="(formGroup.get('companyTwitterUrl').invalid && formGroup.get('companyTwitterUrl').touched)">
              <span><app-validation-errors [errors]="formGroup.get('companyTwitterUrl').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <div class="item-row" [ngClass]="{'field-bordered': true}" *ngIf="contactCreateConfigVariable.showMoreCompanyFlag">
          <a (click)="toggleAdditionalCompanyFields()" [automationId]="'less-company-details'"> Less Company Details </a>
        </div>
        <!--Address fields below-->
        <div class="item-row">
          <div class="sec-name">
            <i class="header-icon" [ngClass]="contactCreateConfigVariable.addressHeaderIcon"></i>
            <span [ngClass]="{'field-header': true}">Address Information</span>
          </div>
        </div>
        <div class="item-row">
          <div class="sec-name">Country</div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-country'"
              formControlName="country"
              class="input-element"
              [ngClass]="{'margin': formGroup.get('country').invalid, 'error': formGroup.get('country').invalid && this.formGroup.get('country').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderCountry"
            />
            <div class="error-text" *ngIf="(formGroup.get('country').invalid && formGroup.get('country').touched)">
              <span><app-validation-errors [errors]="formGroup.get('country').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <div class="item-row">
          <div class="sec-name">Province/State</div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-state'"
              formControlName="state"
              class="input-element"
              [ngClass]="{'margin': formGroup.get('state').invalid, 'error': formGroup.get('state').invalid && this.formGroup.get('state').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderState"
            />
            <div class="error-text" *ngIf="(formGroup.get('state').invalid && formGroup.get('state').touched)">
              <span><app-validation-errors [errors]="formGroup.get('state').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <div class="item-row">
          <div class="sec-name">City</div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-city'"
              formControlName="city"
              class="input-element"
              [ngClass]="{'margin': formGroup.get('city').invalid, 'error': formGroup.get('city').invalid && this.formGroup.get('city').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderCity"
            />
            <div class="error-text" *ngIf="(formGroup.get('city').invalid && formGroup.get('city').touched)">
              <span><app-validation-errors [errors]="formGroup.get('city').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <div class="item-row">
          <div class="sec-name">Street</div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-street'"
              formControlName="street"
              class="input-element"
              [ngClass]="{'margin': formGroup.get('street').invalid, 'error': formGroup.get('street').invalid && this.formGroup.get('street').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderStreet"
            />
            <div class="error-text" *ngIf="(formGroup.get('street').invalid && formGroup.get('street').touched)">
              <span><app-validation-errors [errors]="formGroup.get('street').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <div class="item-row" [ngClass]="{'field-bordered': true}">
          <div class="sec-name">ZIP</div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-zip'"
              formControlName="zip"
              class="input-element"
              [ngClass]="{'margin': formGroup.get('zip').invalid, 'error': formGroup.get('zip').invalid && this.formGroup.get('zip').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderZip"
            />
            <div class="error-text" *ngIf="(formGroup.get('zip').invalid && formGroup.get('zip').touched)">
              <span><app-validation-errors [errors]="formGroup.get('zip').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <!--Social Media fields below-->
        <div class="item-row">
          <div class="sec-name">
            <i class="header-icon" [ngClass]="contactCreateConfigVariable.socialMediaHeaderIcon"></i>
            <span [ngClass]="{'field-header': true}">Social Media</span>
          </div>
        </div>
        <div class="item-row">
          <div class="sec-name">Contact LinkedIn</div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-linkedinurl'"
              formControlName="linkedInUrl"
              class="input-element"
              [ngClass]="{'margin': formGroup.get('linkedInUrl').invalid, 'error': formGroup.get('linkedInUrl').invalid && this.formGroup.get('linkedInUrl').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderContactLinkedin"
            />
            <div class="error-text" *ngIf="(formGroup.get('linkedInUrl').invalid && formGroup.get('linkedInUrl').touched)">
              <span><app-validation-errors [errors]="formGroup.get('linkedInUrl').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <div class="item-row" [ngClass]="{'field-bordered': true}">
          <div class="sec-name">Contact Twitter</div>
          <div class="field-and-validation-group">
            <input
              [automationId]="'create-new-contact-modal-input-twitterurl'"
              formControlName="twitterUrl"
              class="input-element"
              [ngClass]="{'margin': formGroup.get('twitterUrl').invalid, 'error': formGroup.get('twitterUrl').invalid && this.formGroup.get('twitterUrl').touched}"
              [placeholder]="contactCreateConfigVariable.placeholderContactTwitter"
            />
            <div class="error-text" *ngIf="(formGroup.get('twitterUrl').invalid && formGroup.get('twitterUrl').touched)">
              <span><app-validation-errors [errors]="formGroup.get('twitterUrl').errors"></app-validation-errors></span>
            </div>
          </div>
        </div>
        <!--   Custom fields below-->
        <ng-container *ngIf="customFieldsArray.length > 0">
          <div class="item-row">
            <div class="sec-name">
              <i class="header-icon" [ngClass]="contactCreateConfigVariable.customFieldsHeaderIcon"></i>
              <span [ngClass]="{'field-header': true}" [automationId]="'custom-fields'">Custom Fields</span>
            </div>
          </div>
          <div
            class="item-row"
            *ngFor="let field of customFieldsArray?.controls| slice:0:(contactCreateConfigVariable.showMoreCustomFieldsFlag ? customFieldsArray.length : 5); index as i;"
            [automationId]="'custom-field-item-row'"
          >
            <div class="sec-name">
              <app-dotten-text
                class="create-custom-field"
                [hasTooltip]="true"
                [text]="field.get('fieldName').value"
                [automationId]="field.get('fieldName').value.toLowerCase()"
              ></app-dotten-text>
            </div>
            <div class="field-and-validation-group">
              <input
                class="input-element"
                [placeholder]="'Type Something…'"
                [formControl]="field.get('value')"
                [automationId]="field.get('fieldName').value.toLowerCase()"
              />
              <div class="error-text" *ngIf="(field.get('value').invalid && field.get('value').touched)">
                <span><app-validation-errors [errors]="field.get('value').errors"></app-validation-errors></span>
              </div>
            </div>
          </div>
          <div class="item-row">
            <a
              (click)="contactCreateConfigVariable.showMoreCustomFieldsFlag = !contactCreateConfigVariable.showMoreCustomFieldsFlag"
              [automationId]="contactCreateConfigVariable.showMoreCustomFieldsFlag ? 'less-custom-fields' : 'more-custom-fields'"
              >{{contactCreateConfigVariable.showMoreCustomFieldsFlag ? 'Less Custom Fields' : 'More Custom Fields'}}</a
            >
          </div>
        </ng-container>
      </form>
    </div>
  </app-dialog-content>

  <app-dialog-footer>
    <app-primary-btn
      type="submit"
      [disabled]="formGroup.invalid"
      class="primary-button"
      [text]="mode === contactCreateMode.create ? 'Create' : 'Update'"
      (clickButton)="submit()"
      [automationId]="'btn-submit'"
    ></app-primary-btn>
    <app-secondary-btn [automationId]="'button-cancel'" [text]="'Cancel'" (clickButton)="close()"></app-secondary-btn>
  </app-dialog-footer>
</app-dialog-template>
