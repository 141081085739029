import { Injectable } from '@angular/core';
import { UserConfigurationService } from '@app/core/service/user-configuration.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as _ from 'lodash';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { GetSelectedDialerConfigAction, SaveSelectedDialerConfigAction, SaveSelectedDialerConfigErrorAction } from '../action/dialer-config.action';
import { of } from 'rxjs';

@Injectable()
export class DialerConfigEffect {
  constructor(private action$: Actions, private userConfigurationService: UserConfigurationService) {}

  getSelectedDialerConfig$ = createEffect(() => {
    return this.action$.pipe(
      ofType(GetSelectedDialerConfigAction),
      mergeMap(() =>
        this.userConfigurationService.getDialerConfig().pipe(
          map((res) => {
            return SaveSelectedDialerConfigAction({ dialerSelection: _.get(res, 'value.dialerSelection') });
          }),
          catchError((error: any) => {
            return of(SaveSelectedDialerConfigErrorAction());
          }),
        ),
      ),
    );
  });
}
