import { Injectable } from '@angular/core';
import { ContentService } from '@app/pages/content/service/content.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SalesflowEmailTemplateDialogComponent } from '@app/common/pages-component/salesflow-email-template-dialog/salesflow-email-template-dialog.component';
import { Observable, zip } from 'rxjs';
import { SalesflowStep } from '../component/salesflow-step-type/salesflow-step-type.config';
import { ScopeNumber } from '@app/core/enums/engage-filter.enum';
import { map } from 'rxjs/operators';
import { WorkflowTemplateService } from './workflow-template.service';
import { Template } from '@app/core/data-model/template.model';

@Injectable({
  providedIn: 'root',
})
export class SalesflowEmailTemplateService {
  constructor(private contentService: ContentService, private workflowService: WorkflowTemplateService, protected matDialog: MatDialog) {}

  openPrivateTemplateConfirm(privateTemplates: Template[]): MatDialogRef<SalesflowEmailTemplateDialogComponent> {
    return this.matDialog.open(SalesflowEmailTemplateDialogComponent, {
      width: '390px',
      height: '206px',
      data: {
        emailTemplates: privateTemplates,
      },
    });
  }

  getPrivateEmailTemplates(salesflowTemplateId: number): Observable<Template[]> {
    const contentEntityState$ = this.contentService.getContentEntityState();
    const getSalesflowSteps$ = this.workflowService.getWorkflowSteps(salesflowTemplateId);

    return zip(contentEntityState$, getSalesflowSteps$).pipe(
      map(([content, steps]) => {
        const emailSteps = steps.filter((s) => s.type === SalesflowStep.AutomaticEmail || s.type === SalesflowStep.ManualEmail);
        return emailSteps
          .map((e) => {
            if (content.entities[e.messageTemplateId] !== null) {
              return content.entities[e.messageTemplateId];
            }
          })
          .filter((found) => found?.scope === ScopeNumber.Owner);
      }),
    );
  }
}
