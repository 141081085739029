<div class="engage-ext">
  <div class="engage-ext-header">
    <div class="engage-ext-header-panel">
      <div class="engage-ext-header-panel-pop">
        <img class="engage-ext-header-panel-pop-logo" [src]="whiteLogoImg" />
      </div>
      <div class="engage-ext-header-panel-title">Engage</div>
    </div>
  </div>
  <div class="body-setup">
    <img class="image" [src]="defaultImg" width="319" />
    <div class="img-txt">{{title}}</div>
    <div class="img-txt-sub">{{subTitle}}</div>
    <app-primary-btn [text]="'Finish your setup'" (clickButton)="onClick()"></app-primary-btn>
  </div>
</div>
