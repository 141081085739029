import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '@app/reducers';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { getValidAuthToken } from '@zi-core/ngrx/state/auth.state';
import { map, mergeMap, take } from 'rxjs/operators';
import { UmsConstants } from '@app/common/model/ums/ums-auth.model';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class ImportInterceptorService implements HttpInterceptor {
  constructor(private store: Store<ApplicationState>, private cookieService: CookieService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = `${environment.import_service_url}`;

    if (request.url.startsWith(url) && !request.url.startsWith(url + '/sisense')) {
      return this.store.select(getValidAuthToken).pipe(
        map((token) => {
          const doziToken = token ? 'Bearer ' + token.token : '';
          return new HttpHeaders()
            .set('x-engage-authorization', doziToken)
            .set(UmsConstants.ZI_ACCESS_TOKEN_HEADER_NAME, this.cookieService.get(UmsConstants.ZI_ACCESS_TOKEN_COOKIE_NAME));
        }),
        take(1),
        mergeMap((headers) => {
          const transformedRequest = request.clone({
            headers,
            body: request.body,
          });
          return next.handle(transformedRequest);
        }),
        map((response) => {
          return response;
        }),
      );
    } else {
      return next.handle(request);
    }
  }
}
