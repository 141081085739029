import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LoadEmailerVersionAction, LoadEmailerVersionFailureAction, LoadEmailerVersionSuccessAction } from '@zi-pages/admin/ngrx/action/emailer-version.action';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { NotyService } from '@zi-common/service/noty/noty.service';
import { EmailerVersionService } from '@zi-core/service/emailer-version.service';
import { LoggerServiceToken } from '@zi-core/config/logger-service.config';
import { ILoggerService } from '@zi-core/interface/logger.service.interface';

@Injectable()
export class EmailerVersionEffect {
  constructor(
    private _action$: Actions,
    private notyService: NotyService,
    private emailerVersionService: EmailerVersionService,
    @Inject(LoggerServiceToken) private loggerService: ILoggerService,
  ) {}

  loadEmailerVersion$ = createEffect(() =>
    this._action$.pipe(
      ofType(LoadEmailerVersionAction),
      mergeMap((action) => {
        return this.emailerVersionService.getEmailerVersion().pipe(
          map((version) => {
            return LoadEmailerVersionSuccessAction({ version });
          }),
          catchError((error) => {
            this.loggerService.error('Error loading emailer version :: ', error);
            return of(LoadEmailerVersionFailureAction({ error }));
          }),
        );
      }),
    ),
  );
}
