import { environment } from '../../../environments/environment';

export const url = environment.icons_url;

export interface IOnBoardingStep {
  key: OnBoardingStep;
  title: string;
  text: string[];
  imgSrc: string;
  url: string;
  required: boolean;
  finishedText?: string[];
  class?: string;
}

export enum OnBoardingStep {
  PERSONAL = 'personal-details-setup',
  TIMEZONE = 'timezone-setup',
  DIALER = 'dialer-setup',
  EMAIL = 'email-setup',
  SIGNATURE = 'signature-setup',
  SALESFORCE = 'saleforce-setup',
  RECOMMENDATIONS = 'recommendations-setup',
}

export const OnBoardingSteps: IOnBoardingStep[] = [
  {
    key: OnBoardingStep.PERSONAL,
    title: 'Contact Details',
    text: ['Please verify your contact details are', 'correct and fill in any missing fields'],
    imgSrc: `${url}/ebr2022_ob_contact_details.png`,
    url: '/details',
    required: true,
  },
  {
    key: OnBoardingStep.EMAIL,
    title: 'Email Integration Setup',
    text: ['Select your email integration', 'You can always edit your selection later'],
    imgSrc: `${url}/ebr2022_ob_email_integration_setup.png`,
    url: '/email',
    required: true,
  },
  {
    key: OnBoardingStep.SALESFORCE,
    title: 'Integration Setup',
    text: ['Connect your SalesOS account and CRM account', 'to seamlessly prospect, import contacts and track', 'activity from within the platform'],
    imgSrc: `${url}/ebr2022_ob_integration_setup.png`,
    url: '/integration',
    required: false,
  },
  {
    key: OnBoardingStep.RECOMMENDATIONS,
    title: 'Recommendations',
    text: ['Get contacts recommended for you', 'based on your target persona'],
    imgSrc: `${url}/ebr2022_ob_recommendations.png`,
    url: '/recommendations',
    required: false,
    class: 'max-width',
  },
  {
    key: OnBoardingStep.SIGNATURE,
    title: 'Email Signature & Calendar Link',
    text: [''],
    imgSrc: `${url}/ebr2022_ob_email_signature.png`,
    url: '/signature',
    required: false,
  },
  {
    key: OnBoardingStep.DIALER,
    title: 'Dialer Setup',
    text: ['Configure your phone for one click dialing', 'and auto dialer capabilities. Be sure to', 'keep your phone nearby.'],
    finishedText: ['Create pre-recorded voicemails to', 'increase productivity and call volume'],
    imgSrc: `${url}/ebr2022_ob_dialer_setup.png`,
    url: '/dialer',
    required: false,
  },
  {
    key: OnBoardingStep.TIMEZONE,
    title: 'Verify & Set Timezone',
    text: [''],
    imgSrc: `${url}/ebr2022_ob_verify_set_timezone.png`,
    url: '/timezone',
    required: false,
  },
];

export class OnBoardingConfig {}
