import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';

import { ApplicationState } from '@app/reducers';
import { Template } from '@zi-core/data-model/template.model';
import { TemplateServiceService } from '@zi-common/service/templates-data/template-service.service';
import { finalize, tap } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-preview-template-dialog',
  templateUrl: './preview-template-dialog.component.html',
  styleUrls: ['./preview-template-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

/** Important
 * As this component uses ViewEncapsulation.None to show the css for [innerText] in html tag
 * Please make sure the Class Names for Css is unique, as ViewEncapuslation.None overrides the css at other places.
 * **/
export class PreviewTemplateDialogComponent implements OnInit {
  template: Template;
  dialogTitle;
  messageTemplateId;
  defaultDialogTitle = 'Preview Email';
  templatebody;
  isLoading: boolean;
  dialogSubject;
  templateContent: any;
  attachments: any[] = [];
  contactsName: string;
  isOOTB: boolean;
  constructor(
    public dialogRef: MatDialogRef<any>,
    protected sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
    protected store: Store<ApplicationState>,
    protected templateService: TemplateServiceService,
  ) {}

  ngOnInit() {
    this.templatebody = _.get(this.data, 'messageTemplateBody', null);
    this.messageTemplateId = _.get(this.data, 'messageTemplateId');
    this.dialogTitle = _.get(this.data, 'dialogTitle', this.defaultDialogTitle);
    this.attachments = _.get(this.data, 'attachments', []);
    this.contactsName = _.get(this.data, 'contacts', [])
      .map((contact) => contact.name)
      .join(', ');
    this.isOOTB = _.get(this.data, 'isOOTB', false);
    // Can either pass template id OR template body
    if (this.messageTemplateId && !this.isOOTB) {
      this.isLoading = true;
      this.templateService
        .getTemplateById(this.messageTemplateId)
        .pipe(
          finalize(() => (this.isLoading = false)),
          tap((resp) => {
            const respTemplates = _.get(resp, 'templates', []);
            const tiles = _.get(resp, 'tiles', []);
            const newAttachments = [];
            tiles.forEach((elt) => {
              newAttachments.push({ fileName: elt.attributes.fileName, url: elt.attributes.pictureUrl || elt.attributes.url });
            });
            this.attachments = newAttachments;
            this.template = respTemplates.find((template) => template.id === this.messageTemplateId) || {};
            if (this.template && this.template.attributes) {
              this.templateContent = _.get(this.template, 'attributes.message', '');
            }
          }),
        )
        .subscribe();
    } else if (this.isOOTB) {
      this.templateContent = this.templatebody;
    }

    this.dialogSubject = this.data.dialogSubject;
  }

  close() {
    this.onCloseDialog();
  }

  onCloseDialog(event?) {
    this.dialogRef.close(event);
  }
}
