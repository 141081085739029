import { map, filter, tap, catchError, distinctUntilChanged, take } from 'rxjs/operators';
import { NgxPermissionsService, NgxRolesService, NgxPermissionsObject } from 'ngx-permissions';
import { Observable, combineLatest, of, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ApplicationState } from '@app/reducers';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { AuthenticatedToken } from '@zi-core/http-model/response/authenticated-token.response.model';
import { getValidAuthToken, isUserAdmin, getZoomRoles } from '@zi-core/ngrx/state/auth.state';
import { Permissions } from '@zi-core/ngxPermissions/permissions';
import { GetUserPermissionsResponse, zoomRoleToPermissionsMap } from './permissions.config';
import { ZoomRole, NoneRole, EnterpriseRole } from './roles';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  permissionArr: string[] = [];
  role$: Observable<any>;
  combinedData$;
  private loaded = new BehaviorSubject<boolean>(false);
  loaded$: Observable<boolean> = this.loaded.asObservable();

  constructor(
    private store: Store<ApplicationState>,
    private http: HttpClient,
    private appStore: Store<ApplicationState>,
    private ngxPermissionsService: NgxPermissionsService,
    private ngxRolesService: NgxRolesService,
  ) {}

  subscribeToPermissions() {
    return this.store.select(getValidAuthToken).pipe(
      distinctUntilChanged(_.isEqual),
      map((authToken: AuthenticatedToken) => {
        if (authToken) {
          this.store
            .select(isUserAdmin)
            .pipe(
              take(1),
              map((isAdmin) => {
                const adminPermissions: Permissions = isAdmin ? Permissions.ADMIN : Permissions.USER;
                if (!this.permissionArr.includes(adminPermissions)) {
                  this.permissionArr.push(adminPermissions);
                  this.ngxPermissionsService.loadPermissions(this.permissionArr);
                }
              }),
            )
            .subscribe();
        }
      }),
    );
  }

  init() {
    this.loaded.next(false);

    let hasFeatureRoles = false;
    const zoomRole$: Observable<ZoomRole> = this.store.select(getZoomRoles).pipe(
      filter((val) => val != null),
      map((roles) => {
        hasFeatureRoles = roles.filter((element) => element.startsWith('fea:')).length > 0;
        return roles.some((r) => r.includes(':')) ? (!!roles.length ? EnterpriseRole : NoneRole) : NoneRole;
      }),
    );

    // Some items in `zoomUser.zoom_role` map directly to permissions and some do not, we map known zoom_roles here
    const rolePermissions$: Observable<Array<Permissions>> = this.store.select(getZoomRoles).pipe(
      filter((val) => val != null),
      map((roles) => roles.reduce((acc, role) => acc.concat(zoomRoleToPermissionsMap[role] || []), [])),
    );

    // Combine all into an observable that will only trigger once we've received all dependent items and then will
    // trigger on subsequent changes
    this.combinedData$ = combineLatest(zoomRole$, rolePermissions$)
      .pipe(
        take(1),
        map(([zoomRole, permissions]) => ({ ...zoomRole, permissions: [...zoomRole?.permissions, ...permissions] })),
        tap((role) => {
          // remove any existing zoominfo permissions before adding new ones
          const exitingZoomRoles = this.permissionArr.filter((permission) => permission !== Permissions.ADMIN && permission !== Permissions.USER);
          this.permissionArr = this.permissionArr.filter((permission) => !exitingZoomRoles.includes(permission));
          this.permissionArr = _.union(this.permissionArr, hasFeatureRoles ? role?.permissions : [Permissions.NO_ZOOM_ROLES]);
          this.ngxPermissionsService.loadPermissions(this.permissionArr);
          this.ngxRolesService.addRole(role.name, this.permissionArr);
          this.loaded.next(true);
        }),
        catchError((error) => {
          this.loaded.next(true);
          return of({ error });
        }),
      )
      .subscribe();
  }

  hasPermission(permissions: string[]) {
    return _.intersection(this.permissionArr, permissions).length > 0;
  }

  getPermissions(): NgxPermissionsObject {
    return this.ngxPermissionsService.getPermissions();
  }

  hasZoomInfoPermissions(): boolean {
    return _.isEmpty(this.ngxPermissionsService.getPermissions()[Permissions.NO_ZOOM_ROLES]);
  }

  getProfileAndPermissonsForUser(userId): Observable<GetUserPermissionsResponse> {
    return this.http.get(`${environment.backend_url}/v1/account/scopesandpermissions?UserId=${userId}`);
  }

  destroy() {
    this.permissionArr = [];
    this.ngxRolesService.flushRoles();
    this.ngxPermissionsService.flushPermissions();
  }
}
