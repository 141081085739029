import { find } from 'lodash';

export class TwilioErrorCodes {
  static readonly NO_INTERNATIONAL_PERMISSION = new TwilioErrorCodes(
    13227,
    'You might not have permission to call this international phone number. Please contact Engage support 1 844-992-DATA (3282)',
  );
  static readonly INVALID_ACCESS_TOKEN = new TwilioErrorCodes(20101);
  static readonly TOKEN_EXPIRED_OR_EXP_DATE_INVALID = new TwilioErrorCodes(20104);
  static readonly NUMBER_INVALID = new TwilioErrorCodes(13224, "The phone number you're trying to call might be invalid.");
  static readonly NO_FURTHER_INFORMATION = new TwilioErrorCodes(31000, 'Call failed to connect');
  static readonly APPLICATION_NOT_FOUND = new TwilioErrorCodes(31001, 'Call failed to connect. The number might be out of service.');
  static readonly CONNECTION_DECLINED = new TwilioErrorCodes(
    31002,
    'Call failed to connect. The number might be invalid or blocked. Please check the number and try again.',
  );
  static readonly CONNECTION_TIMEOUT = new TwilioErrorCodes(31003, 'Call connection error. Please check your internet connection and try again');
  static readonly WEBSOCKET_CONNECTION_ENDED = new TwilioErrorCodes(31005, 'Call connection error. Please check your internet connection and try again');
  static readonly NO_TRANSPORT_SEND_RECEIVE = new TwilioErrorCodes(31009, 'Call connection error. Please check your internet connection and try again');
  static readonly PHONE_NUMBER_IS_BLACKLISTED = new TwilioErrorCodes(13225, 'Call blocked by voice provider due to regulatory and fraud reasons');
  static readonly INVALID_JWT_TOKEN = new TwilioErrorCodes(31204);
  static readonly JWT_TOKEN_EXPIRED = new TwilioErrorCodes(31205);
  static readonly MICROPHONE_DENIED = new TwilioErrorCodes(31208, 'Microphone has to be enabled for dialer to function.');
  static readonly SIGNALING_CONNECTION_ERROR = new TwilioErrorCodes(53000, 'Call connection error. Please check your internet connection and try again');
  static readonly MICROPHONE_NOT_CONNECTED = new TwilioErrorCodes(
    31201,
    'Microphone has been disconnected. Please reconnect it or change audio settings to use dialer.',
  );
  static readonly BLOCKED_DUE_TO_LOW_TRUST_SCORE = new TwilioErrorCodes(
    32017,
    'Call was blocked due to poor reputation score.\nTrusted Calling can improve your score.',
  );

  static readonly values = [
    TwilioErrorCodes.NO_INTERNATIONAL_PERMISSION,
    TwilioErrorCodes.INVALID_ACCESS_TOKEN,
    TwilioErrorCodes.TOKEN_EXPIRED_OR_EXP_DATE_INVALID,
    TwilioErrorCodes.APPLICATION_NOT_FOUND,
    TwilioErrorCodes.CONNECTION_DECLINED,
    TwilioErrorCodes.CONNECTION_TIMEOUT,
    TwilioErrorCodes.WEBSOCKET_CONNECTION_ENDED,
    TwilioErrorCodes.NO_TRANSPORT_SEND_RECEIVE,
    TwilioErrorCodes.INVALID_JWT_TOKEN,
    TwilioErrorCodes.JWT_TOKEN_EXPIRED,
    TwilioErrorCodes.MICROPHONE_DENIED,
    TwilioErrorCodes.SIGNALING_CONNECTION_ERROR,
    TwilioErrorCodes.MICROPHONE_NOT_CONNECTED,
    TwilioErrorCodes.PHONE_NUMBER_IS_BLACKLISTED,
    TwilioErrorCodes.BLOCKED_DUE_TO_LOW_TRUST_SCORE,
    TwilioErrorCodes.NO_FURTHER_INFORMATION,
    TwilioErrorCodes.NUMBER_INVALID,
  ];

  static findByErrorCode(code: number) {
    return find(TwilioErrorCodes.values, { code });
  }

  private constructor(public code: number, public text?: string) {}
}
