export class SentEmail {
  application: string;
  source: string;
  setFollowUpTask: boolean;
  partOfSalesflow: boolean;
  salesflowStep: string;
  section: string;
  numberOfRecipients: number;
  sendOptions: string;
  addedTemplate: boolean;
  templateCategory: string;
  addedAttachment: boolean;
  addedUnsubscribeLink: boolean;
  enabledOpenTracking: boolean;
  setDeliveryTime: boolean;
  distanceFromCurrentDate: string;
  variablesIncluded: Array<string>;
  fromEmail: string | undefined;
  fromUserId: number;
  replyingTo: string;
  isReply: boolean;

  public constructor(email?: Partial<SentEmail>) {
    Object.assign(this, email);
  }
}
