import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ImportContactList } from '@zi-pages/contact/import-list-entity';

export class ImportListsState {
  error?: object;
  imports: ImportContactList[];
}

export const InitialContactListImportEmptyState = {
  imports: [],
};

export const importContactListsFeatureSelector = createFeatureSelector<ImportListsState>('importContactList');
export const importContactListsSelector = createSelector(importContactListsFeatureSelector, (state: ImportListsState) => state.imports || []);
