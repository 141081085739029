import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DoziIntegrationService } from '@app/core/service/dozi-integration.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-dozi-contact-us-dialog',
  templateUrl: './dozi-contact-us-dialog.component.html',
  styleUrls: ['./dozi-contact-us-dialog.component.scss'],
})
export class DoziContactUsDialogComponent implements OnInit {
  readonly contactUsSrc = environment.icons_url + `/illus-8.svg`;

  constructor(
    private dialogRef: MatDialogRef<DoziContactUsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private doziService: DoziIntegrationService,
  ) {}

  ngOnInit() {}

  onClose() {
    this.dialogRef.close();
  }
}
