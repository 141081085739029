import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { UserConfigurationKey } from '@zi-core/enums/user-configuration.enum';
import { Observable, throwError } from 'rxjs';
import { DialerSelection } from '../enums/dialer.enum';

@Injectable({
  providedIn: 'root',
})
export class UserConfigurationService {
  // mock url
  private baseUrl = `${environment.backend_url}/v1/userconfiguration`;

  constructor(private httpClient: HttpClient) {}

  getUserConfigByKey(key: UserConfigurationKey) {
    return this.httpClient.get<any>(this.baseUrl + `?key=` + key).pipe(
      map((res) => res),
      catchError((error: HttpErrorResponse) => {
        if (this.checkIfQuickFilterError(error)) {
          throw error;
        }
        return throwError(error);
      }),
    );
  }

  upsertUserConfiguration(key: UserConfigurationKey, valueObj) {
    return this.httpClient.post(this.baseUrl, { Key: key, Value: valueObj }).pipe(map((res) => res));
  }

  private checkIfQuickFilterError(error: HttpErrorResponse) {
    return error?.status === HttpStatusCode.NotAcceptable && error?.error?.includes('key=quickFilters');
  }

  public getDialerConfig(): Observable<any> {
    return this.getUserConfigByKey(UserConfigurationKey.DIALER_CONFIG).pipe(
      map((res) => {
        // backend values of 'dialer' and 'bridge' map to bridge on UI - backward compatibility
        res.value.dialerSelection = this.isDialerTypeLegacyBridge(res.value.dialerSelection) ? DialerSelection.BRIDGE : res.value.dialerSelection;
        return res;
      }),
      catchError((e) => throwError(e)),
    );
  }

  public isDialerTypeLegacyBridge(dialerSelection: string) {
    return dialerSelection === 'dialer';
  }
}
