import { Injectable, PipeTransform } from '@angular/core';
import {
  RetrieveUserConversationConvoItem,
  RetrieveUserConversationsResponse,
} from '@zi-pages/sms/model/retrieve-user-conversations-response';
import { SmsUserConversationItem, SmsUserConversationsDto } from '@zi-pages/sms/model/sms-user-conversations.dto';
import { SmsMessageDirection } from '@zi-pages/sms/model/sms-message-direction';
import { SmsMessagePriority } from '@zi-pages/sms/model/sms-message-priority';
import { SmsMessageStatus } from '@zi-pages/sms/model/sms-message-status';

@Injectable()
export class RetrieveUserConversationsToDtoPipe implements PipeTransform {
  transform(itemToTransform: RetrieveUserConversationsResponse): SmsUserConversationsDto {
    const listOfItems = [...itemToTransform.conversations];
    const transformedItem: SmsUserConversationsDto = { conversations: [] };
    if (listOfItems && listOfItems.length > 0) {
      listOfItems.forEach((item) => {
        const smsUserConversationItem: SmsUserConversationItem = {
          contact: null,
          conversation: null,
          mobilePhone: null,
        };
        smsUserConversationItem.contact = item.contact;
        if (!!item.conversation && item.conversation.length > 0) {
          const convoResp: RetrieveUserConversationConvoItem = item.conversation[0];
          smsUserConversationItem.conversation = {
            smsMessageBody: convoResp.body,
            lastUpdatedAt: new Date(convoResp.lastUpdatedAt),
            messageDirection: convoResp.direction?.toLowerCase() as SmsMessageDirection,
            messagePriority: convoResp.priority?.toLowerCase() as SmsMessagePriority,
            messageStatus: convoResp.status?.toLowerCase() as SmsMessageStatus,
            sentAt: new Date(convoResp.sentAt),
            fromNumber: convoResp.from,
            toNumber: convoResp.to,
            trackingId: convoResp.trackingId,
            isRead: convoResp.isRead,
          };
        }
        smsUserConversationItem.mobilePhone = this.determineMobilePhone(smsUserConversationItem);
        transformedItem.conversations.push(smsUserConversationItem);
      });
    }
    return transformedItem;
  }

  private determineMobilePhone(item: SmsUserConversationItem): string {
    if (item.contact) {
      return item.contact.mobilePhone;
    } else {
      if (SmsMessageDirection.INBOUND === item.conversation.messageDirection) {
        return item.conversation.fromNumber;
      } else {
        return item.conversation.toNumber;
      }
    }
  }
}
