import { Action, createReducer, on } from '@ngrx/store';
import {
  AddDialerPhoneNumber,
  DeleteDialerPhoneNumber,
  GetSelectedDialerConfigAction,
  ResetDialerPhoneNumber,
  SaveSelectedDialerConfigAction,
  SaveSelectedDialerConfigErrorAction,
} from '../action/dialer-config.action';
import { DialerConfigState, initialDialerConfigState } from '../state/dialer-config.state';
import { failureLoadedState, loadingState, successLoadedState } from '@zi-core/ngrx/config/ngrx-loading-states.config';
import { DialerSelection } from '@app/core/enums/dialer.enum';

const reducer = createReducer(
  initialDialerConfigState,

  on(GetSelectedDialerConfigAction, (state, payload) => {
    return {
      ...state,
      ...loadingState,
    };
  }),

  on(SaveSelectedDialerConfigAction, (state, payload) => {
    return {
      ...state,
      dialerSelection: payload.dialerSelection,
      ...successLoadedState,
    };
  }),

  on(SaveSelectedDialerConfigErrorAction, (state, payload) => {
    return {
      ...state,
      ...failureLoadedState,
    };
  }),

  on(AddDialerPhoneNumber, (state, payload) => {
    if (payload.dialerSelection === DialerSelection.VOIP) {
      return {
        ...state,
        voip: payload.twilioCallerId,
        ...successLoadedState,
      };
    } else if (payload.dialerSelection === DialerSelection.BRIDGE) {
      return {
        ...state,
        bridge: payload.twilioCallerId,
        ...successLoadedState,
      };
    }
  }),

  on(DeleteDialerPhoneNumber, (state, payload) => {
    if (payload.dialerSelection === DialerSelection.VOIP) {
      return {
        ...state,
        voip: { id: 0, userId: 0, orgId: 0, phone: '', phoneExt: '', externalId: '', accountSid: '', modifiedAt: '', type: DialerSelection.VOIP },
        ...successLoadedState,
      };
    } else if (payload.dialerSelection === DialerSelection.BRIDGE) {
      return {
        ...state,
        bridge: { id: 0, userId: 0, orgId: 0, phone: '', phoneExt: '', externalId: '', accountSid: '', modifiedAt: '', type: DialerSelection.BRIDGE },
        ...successLoadedState,
      };
    }
  }),

  on(ResetDialerPhoneNumber, (state, payload) => {
    if (payload.dialerSelection === DialerSelection.VOIP) {
      return {
        ...state,
        voip: null,
        ...successLoadedState,
      };
    } else if (payload.dialerSelection === DialerSelection.BRIDGE) {
      return {
        ...state,
        bridge: null,
        ...successLoadedState,
      };
    }
  }),
);

export function dialerConfigReducer(state: DialerConfigState, action: Action): DialerConfigState {
  return reducer(state, action);
}
