import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { getExtensionMode } from '@app/extensionMode.config';

@Injectable({
  providedIn: 'root',
})
/**
 * Exists to make sure the extension can never get into on-boarding.
 */
export class PreventExtensionEnterOnBoardingGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    if (getExtensionMode()) {
      this.router.navigate(['/setup/ext']);
      return false;
    }

    return true;
  }
}
