import { createAction, props } from '@ngrx/store';
import { SmsConversationDto, SmsConversationItem } from '@zi-pages/sms/model/sms-conversation.dto';
import { SmsSendMessageResponse } from '@zi-pages/sms/model/sms-send-message-response';
import { SmsUserConversationItem, SmsUserConversationsDto } from '@zi-pages/sms/model/sms-user-conversations.dto';
import { Contact } from '@zi-core/data-model/contact.model';
import { SmsMessageSseFeedData } from '@zi-pages/sms/model/sms-message-sse-feed-data';

export const LoadSmsConversationsForUserAction = createAction("[SMS] Get User's SMS Conversations", props<{ pageNum: number; pageSize: number }>());

export const LoadSmsConversationsForUserSuccessAction = createAction(
  "[SMS] Get User's SMS Conversations success",
  props<{ smsContactList: SmsUserConversationsDto }>(),
);

export const LoadSmsConversationsForUserFailureAction = createAction("[SMS] Get User's SMS Conversations failure", props<{ error: any }>());

export const GetSmsConversationByContactMobileAction = createAction(
  "[SMS] Get SMS Conversation By Contact's mobile number",
  props<{ contactMobileNumber: string; contactInfo: SmsUserConversationItem; pageNum: number; pageSize: number }>(),
);

export const GetSmsConversationByContactMobileSuccessAction = createAction(
  "[SMS] Get SMS Conversation By Contact's mobile number success",
  props<{ conversationResp: SmsConversationDto; contactInfo: SmsUserConversationItem }>(),
);

export const GetSmsConversationByContactMobileFailureAction = createAction(
  "[SMS] Get SMS Conversation By Contact's mobile number failure",
  props<{ error: any }>(),
);

export const SendSmsMessageAction = createAction(
  '[SMS] Send SMS Message',
  props<{ toNumber: string; messageBody: string; contact?: Contact; resendMessage?: SmsConversationItem; isFirstMessage?: boolean }>(),
);

export const SendSmsMessageSuccessAction = createAction(
  '[SMS] Send SMS Message Success',
  props<{ sendMessageResp: SmsSendMessageResponse; contact?: Contact; trackingIdForResend?: string; resendOriginalSendAt?: Date }>(),
);

export const SendSmsMessageFailureAction = createAction('[SMS] Send SMS Message Failure', props<{ error: any; resendMessage?: SmsConversationItem }>());

export const UpdateSmsMessageContactAction = createAction('[SMS] Update SMS Message Contact', props<{ contact: Contact }>());

export const ReceiveSmsMessageAction = createAction('[SMS] Receive SMS Message', props<{ data: SmsMessageSseFeedData; contact: Contact }>());

export const SearchSmsContactsAction = createAction('[SMS] Search Sms Contact Info', props<{ data: string }>());

export const ReceiveSendStatusCallbackAction = createAction('[SMS] Receive Send SMS Message Status Callback Action', props<{ data: SmsMessageSseFeedData }>());

export const DeleteSmsMessageAction = createAction('[SMS] Delete SMS Message', props<{ messageToDelete: SmsConversationItem }>());

export const DeleteSmsMessageSuccessAction = createAction('[SMS] Delete SMS Message - Success', props<{ messageToDelete: SmsConversationItem }>());

export const DeleteSmsMessageFailureAction = createAction('[SMS] Delete SMS Message - Failure', props<{ messageToDelete: SmsConversationItem }>());

export const ResetSmsConvoStateAction = createAction('[SMS] Reset SMS Convo State');

export const UpdateTotalUnreadSMSAction = createAction('[SMS] Update Total Unread SMSs State', props<{ totalUnreadSMSPresent: number }>());

export const UpdateIsReadForConversationAction = createAction('[SMS] Update Read Status for a conversation', props<{ mobilePhone: string }>());

export const UpdateIsReadForConversationSuccessAction = createAction('[SMS] Change the number of read messages', props<{ totalReadMessages: number }>());

export const UpdateIsReadForConversationFailureAction = createAction('[SMS] Updating isRead Failed');
