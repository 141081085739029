export interface StatusColorConfig {
  code: string;
  name: string;
}

export const CompanyAccountStatusColor: { [color: string]: StatusColorConfig } = {
  Blue: { code: '#5684e8', name: 'Blue' },
  Red: { code: '#e83636', name: 'Red' },
  Green: { code: '#42bc56', name: 'Green' },
  Yellow: { code: '#eace59', name: 'Yellow' },
  Gray: { code: '#c7c7c7', name: 'Gray' },
  Purple: { code: '#c2cff9', name: 'Purple' },
  DarkRed: { code: '#b63c83', name: 'DarkRed' },
  Orange: { code: '#e88970', name: 'Orange' },
  Pink: { code: '#f7a9b8', name: 'Pink' },
  LightOrange: { code: '#f3bdaa', name: 'LightOrange' },
  DarkPink: { code: '#dc66a7', name: 'DarkPink' },
  Default: { code: '#5684e8', name: 'Default' },
};
