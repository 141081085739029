export enum ContactUsLocation {
  REPLACE = 'REPLACE',
  ORG_CHART = 'ORG_CHART',
  TECH = 'TECH',
  COMPANY_ATTRIBUTES = 'COMPANY_ATTRIBUTES',
  TECH_ATTRIBUTE = 'TECH_ATTRIBUTE',
  SCOOP = 'SCOOP',
  NEWS = 'NEWS',
  NEWS_AND_SCOOP = 'NEWS_AND_SCOOP',
  NONE = 'NONE',
}
