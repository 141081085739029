import { CallInfo, ContactCallInfo, initialCallInfo } from '@app/caller/interface/call-info';
import { Observable } from 'rxjs';
import { EventEmitter } from '@angular/core';
import { EntityDialerService } from '@app/caller/service/entity-dialer.service';
import { AnalyticsService } from '@zi-core/service/analytics.service';
import { ApplicationState } from '@app/reducers';
import { Store } from '@ngrx/store';
import { CallStatusEnum } from '@app/caller/interface/call-status-enum';
import Connection from 'twilio-client/es5/twilio/connection';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { ContactInternalDataService } from '@zi-pages/contact/service/contact-internal-data.service';
import { Contact } from '@app/core/data-model/contact.model';
import { VoipDialerService } from '@app/caller/service/voip-dialer.service';
import { NotyService } from '@zi-common/service/noty/noty.service';
import { DialerUtilService } from '@app/caller/service';
import { ILoggerService } from '@zi-core/interface/logger.service.interface';

/**
 * This is not an injectable service. It is an object that represents all the call info. It works
 * similar to BridgeDialerService, but will be controlled by VoipDialerService.
 */
export class VoipCallEvent extends EntityDialerService {
  private prospectCallLegOpen$ = new EventEmitter<CallInfo>();
  private prospectCallStarted$ = new EventEmitter<CallInfo>();
  private prospectCallEnded$ = new EventEmitter<CallInfo>();
  private incomingCallAccepted$ = new EventEmitter<CallInfo>();
  private incomingCallRejected$ = new EventEmitter<CallInfo>();
  private warning$ = new EventEmitter<Set<string>>();
  private networkWarnings = [
    'high-rtt',
    'low-mos',
    'high-jitter',
    'high-packet-loss',
    'high-packets-lost-fraction',
    'low-bytes-received',
    'low-bytes-sent',
    'ice-connectivity-lost',
  ];
  private activeWarnings = new Set<string>();
  private isIncomingCall = false;
  // This is for use for the extension UI and template. The UI will set/get it directly.
  public hasExtensionFocus = false;
  private hasFailed = false;

  private TEMP_CALL_SID_PREFIX = 'TJ';

  constructor(
    protected _httpClient: HttpClient,
    protected _appStore: Store<ApplicationState>,
    protected analyticsService: AnalyticsService,
    protected contactInternalDataService: ContactInternalDataService,
    protected notyService: NotyService,
    protected dialerUtilService: DialerUtilService,
    private voipDialerService: VoipDialerService,
    private connection: Connection,
    private callInfo: CallInfo,
    protected loggerService: ILoggerService,
  ) {
    super(_httpClient, _appStore, analyticsService, contactInternalDataService, notyService, dialerUtilService, loggerService);
    if (callInfo.callStatus === CallStatusEnum.incomingCall) {
      // Only will be set for incoming calls. Will be null for outgoing at the start.
      callInfo.prospectCallId = this.retrieveProspectCallIdFromConnection(connection);
      callInfo.contactInfo = this.retrieveContactCallInfoFromConnection(connection);
      this.isIncomingCall = true;
    }

    this.modifyCallInfo(callInfo);
    this.initConnectionEventHandlers(connection);
  }

  public whenOutgoingCallIsInitialized$(): Observable<CallInfo> {
    return this.prospectCallLegOpen$.asObservable();
  }

  public whenProspectCallEnds$(): Observable<CallInfo> {
    return this.prospectCallEnded$.asObservable();
  }

  public whenIncomingCallAccepted$(): Observable<CallInfo> {
    return this.incomingCallAccepted$.asObservable();
  }

  public whenIncomingCallRejected$(): Observable<CallInfo> {
    return this.incomingCallRejected$.asObservable();
  }

  public whenWarningRaised$(): Observable<Set<string>> {
    return this.warning$.asObservable();
  }

  public endProspectCall(): void {
    this.connection.disconnect();
  }

  public acceptIncomingCall(): void {
    this.connection.accept();
  }

  public rejectIncomingCall(): void {
    this.connection.reject();
  }

  public muteCall(isMute: boolean): void {
    this.connection.mute(isMute);
  }

  public sendDigits(digit: string): void {
    this.connection.sendDigits(digit);
  }

  public setVoicemailId(voicemailId: string): void {
    const callInfo = this.getCallInfo();
    callInfo.voicemailId = voicemailId;
    this.modifyCallInfo(callInfo);
  }

  private handleCallStarted(ctn): void {
    const curCallInfo = this.getCallInfo();
    curCallInfo.isProspectCallActive = true;
    curCallInfo.callStatus = CallStatusEnum.callWithProspectStarted;
    if (!this.isIncomingCall && !curCallInfo.prospectCallId) {
      curCallInfo.prospectCallId = this.retrieveProspectCallIdFromConnection(ctn);
    }
    curCallInfo.isCallLoggerActionRequired =
      _.get(curCallInfo, 'contactInfo.id') !== 0 && _.get(curCallInfo, 'contactInfo.id') != null && _.get(curCallInfo, 'prospectCallId') != null;
    this.modifyCallInfo(curCallInfo);
    this.prospectCallStarted$.emit(this.getCallInfo());
  }

  private handleCallRinging(): void {
    const curCallInfo = this.getCallInfo();
    curCallInfo.isProspectCallActive = true;
    curCallInfo.callStatus = CallStatusEnum.connectingToProspect;
    if (!this.isIncomingCall && !curCallInfo.prospectCallId) {
      curCallInfo.prospectCallId = this.retrieveProspectCallIdFromConnection(this.connection);
    }
    curCallInfo.isCallLoggerActionRequired =
      _.get(curCallInfo, 'contactInfo.id') !== 0 && _.get(curCallInfo, 'contactInfo.id') != null && _.get(curCallInfo, 'prospectCallId') != null;
    this.modifyCallInfo(curCallInfo);
    this.prospectCallLegOpen$.emit(this.getCallInfo());
  }

  private handleCallEnding(ctn: Connection): void {
    const curCallInfo = this.getCallInfo();
    curCallInfo.callStatus = null;
    curCallInfo.isProspectCallActive = false;
    curCallInfo.recordingInfo = { ...initialCallInfo.recordingInfo };
    if (!this.isIncomingCall && !curCallInfo.prospectCallId) {
      curCallInfo.prospectCallId = this.retrieveProspectCallIdFromConnection(ctn);
    }
    this.modifyCallInfo(curCallInfo);
    this.connection = null;
    this.prospectCallEnded$.emit(this.getCallInfo());
  }

  private handleCallRejection(): void {
    this.incomingCallRejected$.emit(this.getCallInfo());
  }

  private handleWarning(warningName: string): void {
    this.activeWarnings.add(warningName);
    this.warning$.emit(this.activeWarnings);
  }

  private handleWarningCleared(warningName?: string): void {
    warningName ? this.activeWarnings.delete(warningName) : this.activeWarnings.clear();
    this.warning$.emit(this.activeWarnings);
  }

  private retrieveProspectCallIdFromConnection(connection: Connection): string {
    const callSid = connection.customParameters.has('CallSid') ? connection.customParameters.get('CallSid') : _.get(connection, 'parameters.CallSid');
    if (!this.isCallSidTemporary(callSid)) {
      return callSid;
    } else {
      // We want to treat temporary call sids as null, do not want to store them.
      return null;
    }
  }

  private retrieveContactCallInfoFromConnection(connection: Connection): ContactCallInfo {
    const contactReturnVal = {
      phoneNumberDialed: _.get(this.callInfo, 'contactInfo.phoneNumberDialed'),
    };

    return contactReturnVal;
  }

  protected modifyCallInfo(callInfo: CallInfo) {
    this.callInfo$.next(callInfo);
  }

  public updateCallContact(contact: Contact): Observable<object> {
    const contactId = contact.id;
    const callInfo = this.getCallInfo();
    const callSid = callInfo.prospectCallId;
    callInfo.isCallLoggerActionRequired = true;
    callInfo.contactInfo.name = contact.firstName + ' ' + contact.lastName;
    callInfo.contactInfo.id = contactId;
    this.modifyCallInfo(callInfo);
    return this.voipDialerService.updateCallContact(callSid, contactId);
  }

  private isCallSidTemporary(prospectCallId: string): boolean {
    return prospectCallId && prospectCallId.startsWith(this.TEMP_CALL_SID_PREFIX);
  }

  private initConnectionEventHandlers(connection: Connection): Connection {
    /**
     * Register a handler function to be called when this connection object has finished
     * connecting and changes its state to open.
     */
    connection.on('accept', (ctn: Connection) => {
      if (this.getCallInfo().callStatus === CallStatusEnum.incomingCall) {
        this.incomingCallAccepted$.emit();
      }
      this.handleCallStarted(ctn);
    });

    /**
     * Register a handler function to be called when the connection is cancelled and
     * Connection.status() has transitioned to closed. This is raised when Connection.ignore()
     * is called or when a pending invite while trying to connect to Twilio has been cancelled.
     */
    connection.on('cancel', (ctn: Connection) => {
      this.handleCallEnding(ctn);
      this.handleWarningCleared();
    });

    /**
     * Register a handler function to be called when this connection is closed.
     */
    connection.on('disconnect', (ctn: Connection) => {
      this.handleCallEnding(ctn);
      this.handleWarningCleared();
    });

    /**
     * Register a handler function to be called when any device error occurs during the lifetime
     * of this connection. These may be errors in the request, the capability token,
     * connection errors, or other application errors.
     *
     * The handler function receives an error object as an argument.
     * The error object may include the following properties:
     * - message: A string describing the error.
     * - code: A numeric error code described in the Twilio Client error code reference.
     * - connection: A reference to the Twilio.Connection object that was active when the
     *               error occurred.
     * - twilioError: When applicable, errors emitted now contain this twilioError field,
     *                providing more information about the error. This twilioError
     *                represents the new TwilioError format that will become the default Error
     *                format in the next major release of this SDK.
     */
    connection.on('error', (error) => {
      this.loggerService.error('connection error :: ', error);
      this.handleWarningCleared();
    });

    /**
     * Register a handler function to be called when a connection is muted or unmuted.
     *
     * The handler function receives a boolean indicating whether the connection is now muted
     * (true) or not (false), and the Twilio.Connection object that was muted or unmuted.
     */
    connection.on('mute', (isMute: boolean, ctn: Connection) => {
      // TODO: Implement mute functionality
    });

    /**
     * Register a handler function to be called when the connection is rejected and
     * Connection.status() has transitioned to closed.
     *
     * This is raised when Connection.reject() is called.
     */
    connection.on('reject', () => {
      this.handleCallRejection();
    });

    /**
     * Raised when the Connection has entered the ringing state.
     * By default, TwiML's Dial verb will connect immediately and this state will
     * be brief or skipped entirely. When using the Dial verb with answerOnBridge=true,
     * the ringing state will begin when the callee has been notified of the call and will
     * transition into open after the callee accepts the call, or closed if the call is rejected
     * or cancelled.
     */
    connection.on('ringing', (hasEarlyMedia: boolean) => {
      this.handleCallRinging();
    });

    /**
     * Register a handler function to be called with the Connection’s current input volume and
     * output volume on every animation frame.
     *
     * The handler will be invoked up to 60 times per second, and will scale down dynamically
     * on slower devices to preserve performance.
     *
     * The handler receives inputVolume and outputVolume as percentages of maximum volume
     * represented by a floating point number between 0.0 and 1.0, inclusive.
     * This value represents a range of relative decibel values between -100dB and -30dB.
     */
    connection.on('volume', (inputVolume: number, outputVolume: number) => {});

    /**
     * Raised when a call-quality-metric has crossed a threshold.
     *
     * Twilio.js raises warning events when it detects a drop in call quality or other conditions
     * that may indicate the user is having trouble with the call.
     *
     * Callbacks can be implemented on on these events to alert the user of an issue.
     */
    connection.on('warning', (warningName, warningData) => {
      this.loggerService.warn('Received warning from Twilio in regards to call :: ', {
        warningName,
        warningData,
        callInfo: this.callInfo,
      });
      if (this.networkWarnings.includes(warningName)) {
        this.handleWarning(warningName);
      }
    });

    /**
     * Called when a call-quality-metric has returned to normal.
     */
    connection.on('warning-cleared', (warningName) => {
      this.loggerService.log('Twilio warning has been cleared :: ', { warningName });
      if (this.networkWarnings.includes(warningName)) {
        this.handleWarningCleared(warningName);
      }
    });

    return connection;
  }
}
