import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class UrlService {
  constructor(private route: ActivatedRoute, private router: Router) {}

  /* Encode JSON obj to base64*/
  encodeToBase64(obj: any): string {
    // Need to account for "special chars"
    return btoa(
      encodeURIComponent(JSON.stringify(obj)).replace(/%([0-9A-F]{2})/g, (match, p1) => {
        return String.fromCharCode(parseInt(p1, 16));
      }),
    );
  }

  /* Decode from base64 to JSON obj */
  decodeFromBase64(encodedUrl: string): any {
    // Need to account for out "special chars"
    return JSON.parse(
      decodeURIComponent(
        Array.prototype.map
          .call(atob(encodedUrl), (c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join(''),
      ),
    );
  }

  getRoutePath(): string {
    return this.getRoutePathRec(this.route.snapshot.root.firstChild);
  }

  getRouteForAnalytics() {
    const url: string = _.get(this.route, "snapshot['_routerState'].url") || '';
    return url ? this.returnTheLastChildInRoute(url.split('?')[0]) : '';
  }

  returnTheLastChildInRoute(url: string) {
    if (!url) {
      return '';
    }
    const urlArray = url.split('/');
    const source = urlArray[urlArray.length - 1];
    let final;
    if (source === 'v2' && urlArray.length > 1) {
      final = urlArray[urlArray.length - 2];
    } else {
      final = urlArray[urlArray.length - 1];
    }
    return final;
  }

  private getRoutePathRec(firstChild: any, path: string = ''): string {
    if (!firstChild) {
      return path;
    }
    const currentPath = _.get(firstChild, 'routeConfig.path');
    if (currentPath) {
      path = currentPath;
    }

    return this.getRoutePathRec(firstChild.firstChild, path);
  }

  /**
   * Fetch the domain from website URL
   * Ex: URL 'https://www.google.com' will return the domain 'google.com'
   *
   * @param url - URL string
   */
  public getUrlDomain(url: string): string {
    if (url) {
      return url.replace('http://', '').replace('https://', '').replace('www.', '').split(/[/?#]/)[0];
    }
    return '';
  }
}
