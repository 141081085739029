import { Inject, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { LoggerServiceToken } from '@zi-core/config/logger-service.config';
import { ILoggerService } from '@zi-core/interface/logger.service.interface';

@Pipe({
  name: 'safeValueTrusted',
})
export class SafeValueTrustedPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer, @Inject(LoggerServiceToken) private loggerService: ILoggerService) {}

  /**
   * Used to bypass security and trust the given resource.
   * @param value The specified value to trust.
   * @param type The type of value that would need to be trusted.
   */
  transform(value: string, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    try {
      if (!value) {
        return '';
      }
      switch (type) {
        case 'html':
          return this.sanitizer.bypassSecurityTrustHtml(value);
        case 'style':
          return this.sanitizer.bypassSecurityTrustStyle(value);
        case 'script':
          return this.sanitizer.bypassSecurityTrustScript(value);
        case 'url':
          return this.sanitizer.bypassSecurityTrustUrl(value);
        case 'resourceUrl':
          return this.sanitizer.bypassSecurityTrustResourceUrl(value);
        default:
          return this.sanitizer.bypassSecurityTrustHtml(value);
      }
    } catch (error) {
      this.loggerService.error(error);
      return '';
    }
  }
}
