export enum ContactTaskFilterType {
  ContactOwner = 0,
  TaskOwner = 1,
  Status = 2,
  SalesflowStepNumber = 3,
  EmailsOpened = 4,
  EmailsReplied = 5,
  EmailsLinkedClicked = 6,
  EmailsUnSubscribed = 7,
  EmailsNoReply = 8,
  EmailsNotOpened = 9,
  EmailsLinkedNotClicked = 10,
  HadACall = 11,
  HadAConversation = 12,
  HadNoConversation = 13,
  HadNoCall = 14,
  Active = 15,
}

export const ContactTaskFilterAnalyticsNames = {
  [ContactTaskFilterType.ContactOwner]: 'Contact Owner',
  [ContactTaskFilterType.TaskOwner]: 'Task Owner',
  [ContactTaskFilterType.Status]: 'Status',
  [ContactTaskFilterType.SalesflowStepNumber]: 'Step',
  [ContactTaskFilterType.EmailsLinkedClicked]: 'Clicked',
  [ContactTaskFilterType.EmailsOpened]: 'Opened',
  [ContactTaskFilterType.EmailsReplied]: 'Replied',
  [ContactTaskFilterType.EmailsNoReply]: 'Not Replied',
  [ContactTaskFilterType.EmailsNotOpened]: 'Not Opened',
  [ContactTaskFilterType.EmailsLinkedNotClicked]: 'Not Clicked',
  [ContactTaskFilterType.HadACall]: 'Called',
  [ContactTaskFilterType.HadAConversation]: 'Conversation',
  [ContactTaskFilterType.HadNoConversation]: 'Not Conversation',
  [ContactTaskFilterType.HadNoCall]: 'Not Called',
  [ContactTaskFilterType.Active]: 'Active',
};
