import { CallInfo, ContactCallInfo, initialCallInfo } from '@app/caller/interface/call-info';
import { CallStatusEnum } from '@app/caller/interface/call-status-enum';
import { ApplicationState } from '@app/reducers';
import { Store } from '@ngrx/store';
import { taskTypesEnum } from '@zi-common/pages-component/contact-details/contact-tasks-tab/contact-tasks-tab.config';
import { AnalyticsSectionType } from '@zi-core/enums/analytics.enum';
import { TaskStatus } from '@zi-core/enums/task-status.enum';
import { AnalyticsService } from '@zi-core/service/analytics.service';
import { AddNewTaskAction, UpdateTasksStatus } from '@zi-pages/task/ngrx/action/task-entity.action';
import { ClearSelectionAction } from '@zi-pages/task/ngrx/action/task-selector.action';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, finalize, map, switchMap, take } from 'rxjs/operators';
import { CallLogInfo } from '@app/caller/interface/call-log-info';
import { EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { ContactInternalDataService } from '@zi-pages/contact/service/contact-internal-data.service';
import { crmActivitySyncConfigs } from '@app/common/component/indications/indications.config';
import { IdProviderType } from '@app/core/enums/id-povider-type.enum';
import { NotyService } from '@zi-common/service/noty/noty.service';
import { Contact } from '@app/core/data-model/contact.model';
import { DialerUtilService } from './dialer-util.service';
import { ConversationContact } from '@app/core/data-model/analytics/dialer-events.model';
import { contactEntityByIdSelector } from '@zi-pages/contact/ngrx/state/contact-entity.state';
import { FollowUpTask } from '../interface/follow-up-task';
import { GetContactSuccessAction } from '@app/pages/contact/ngrx/action/contact-entity.action';
import { ILoggerService } from '@zi-core/interface/logger.service.interface';

export abstract class EntityDialerService {
  protected callInfo$: BehaviorSubject<CallInfo> = new BehaviorSubject(_.cloneDeep(initialCallInfo));
  protected logCallCompleted$: EventEmitter<CallInfo> = new EventEmitter();
  protected initialCallInfo: CallInfo = initialCallInfo;

  protected constructor(
    protected _httpClient: HttpClient,
    protected _appStore: Store<ApplicationState>,
    protected analyticsService: AnalyticsService,
    protected contactInternalDataService: ContactInternalDataService,
    protected notyService: NotyService,
    protected dialerUtilService: DialerUtilService,
    protected loggerService: ILoggerService,
  ) {}

  protected abstract modifyCallInfo(callInfo: CallInfo): void;

  public checkIfActiveCallOrSession() {
    const existingCallInfo = this.getCallInfo();
    return this.checkIfSesionActive(existingCallInfo) || existingCallInfo.isCallLoggerActionRequired;
  }

  public logCall(
    callLogInfo: CallLogInfo,
    userId,
    logAndComplete = false,
    inputLogCallInfo: CallInfo = this.getCallInfo(),
    contactId = 0,
    isManualLog = false,
  ): Observable<any> {
    const taskId = _.get(inputLogCallInfo, 'taskId');
    const contactCalled = _.get(inputLogCallInfo, 'contactInfo');
    // To preserve original in case values need to be changed.
    const callLogData: any = _.cloneDeep(callLogInfo);

    callLogData.prospectCallId = _.get(inputLogCallInfo, 'prospectCallId');
    if (logAndComplete) {
      this.markTheTaskAsComplete(taskId);
    }

    // in engage mode, we allow users to navigate to other contacts & then navigate back & log calls for a previous prospect call
    // this is done by storing the callInfo object per contactId in engage mode caller service & passing it to logCall() as inputCallLogInfo
    // in this case, the currentCallInfo is different from the inputCallLogInfo & needs to be used for modifying call info
    // for standalone mode, the inputLogCallInfo & currentCallInfo will have the same data
    let currentCallInfo = this.getCallInfo();
    currentCallInfo.isCallLoggerActionRequired = false;
    currentCallInfo.contactInfo = { ...this.initialCallInfo.contactInfo };
    // Session has ended and log call has completed.
    if (currentCallInfo.callStatus == null) {
      currentCallInfo = _.cloneDeep(this.initialCallInfo);
      this.logCallCompleted$.emit(this.getCallInfo());
    }
    this.modifyCallInfo(currentCallInfo);

    return this._appStore.select(contactEntityByIdSelector(contactCalled.id ? contactCalled.id : contactId)).pipe(
      take(1),
      switchMap((contact: Contact) => {
        if (!contact) {
          return this.contactInternalDataService.getContactById(contactCalled.id ? contactCalled.id : contactId, true).pipe(
            map((c) => {
              this._appStore.dispatch(GetContactSuccessAction({ contact: c }));
              return c;
            }),
          );
        }
        return of(contact);
      }),
      map((contact: Contact) => {
        if (contact?.idProvider == IdProviderType.Hubspot && contact?.externalId && !callLogData?.input?.subject) {
          callLogData.input.subject = crmActivitySyncConfigs.DEFAULT_CALL_SUBJECT;
        }

        // Check if there's a follow up task. Due to how it's controlled, check if date exists to confirm.
        if (_.get(callLogData, 'followUpTask.time')) {
          const task: FollowUpTask = {
            enabled: true,
            dueAt: moment(callLogData.followUpTask.time).toISOString(),
            note: callLogData.followUpTask.notes,
            priority: callLogData.followUpTask?.priorityOption.displayName,
            type: callLogData.followUpTask?.taskOption.displayName,
          };

          callLogData.followUpTask = task;
          Object.assign(callLogData.followUpTask, { amount: 1 });
          this._appStore.dispatch(
            AddNewTaskAction({
              userId,
              contacts: [contact],
              task: callLogData.followUpTask,
              isFollowUpTask: true,
            }),
          );
          this.analyticsService.createdFollowUpTaskEvent(callLogData.followUpTask, 'EngageCall');
        }

        return of(callLogData);
      }),
      switchMap((res) => {
        if (!isManualLog) {
          return this._httpClient.post(`${this.dialerUtilService.dialerDomain}/v1/dial/call_log`, {
            CallSid: callLogData.prospectCallId,
            VoicemailId: inputLogCallInfo?.voicemailId,
            CallResult: callLogData.input.result,
            CallSentiment: callLogData.input.sentiment,
            CallSubject: callLogData.input.subject,
            CallComment: callLogData.input.comment || '',
            LogAndComplete: logAndComplete,
          });
        } else {
          return this._httpClient.post(`${environment.backend_url}/v1/manual_call_log/create`, {
            ContactId: contactId,
            CalledAt: new Date(new Date(callLogData.input.date).setHours(callLogData.input.time.hours, callLogData.input.time.minutes)),
            Duration: callLogData.input.duration,
            CallType: callLogData.input.logType,
            Phone: callLogData.input.phone,
            CallResult: callLogData.input.result,
            CallSentiment: callLogData.input.sentiment,
            CallSubject: callLogData.input.subject,
            CallComment: callLogData.input.comment || '',
          });
        }
      }),
      catchError((error) => {
        this.loggerService.error('Error saving call log :: ', error);
        return throwError(error);
      }),
    );
  }

  public cancelLogCall(emitEndSession = true): void {
    let callInfo = this.getCallInfo();
    callInfo.contactInfo = { ...this.initialCallInfo.contactInfo };
    callInfo.isCallLoggerActionRequired = false;
    if (callInfo.callStatus == null) {
      callInfo = _.cloneDeep(this.initialCallInfo);
      if (emitEndSession) {
        this.logCallCompleted$.emit(this.getCallInfo());
      }
    }
    this.modifyCallInfo(callInfo);
  }

  /**
   * Checks whether a call session is active
   * @param callInfo provide existing callInfo otherwise get it from service
   */
  public checkIfSesionActive(callInfo: CallInfo = null): boolean {
    const existingCallInfo = callInfo || this.getCallInfo();
    return existingCallInfo && Object.values(CallStatusEnum).includes(existingCallInfo.callStatus);
  }

  public isAbleToMakeNewCall(): boolean {
    const curCallInfo = this.getCallInfo();
    return !curCallInfo.isCallLoggerActionRequired && (curCallInfo.callStatus == null || curCallInfo.callStatus === CallStatusEnum.inSession);
  }

  public getCallInfo$(): Observable<CallInfo> {
    return this.callInfo$.asObservable().pipe(distinctUntilChanged());
  }

  public getCallInfo(): CallInfo {
    // Do not return actual reference from subject.
    return {
      ...this.callInfo$.value,
      contactInfo: { ...this.callInfo$.value.contactInfo },
    };
  }

  public getProspectCallId$(): Observable<string> {
    return this.callInfo$.asObservable().pipe(
      map((callInfo: CallInfo) => callInfo.prospectCallId),
      distinctUntilChanged(),
    );
  }

  public getCallStatus$(): Observable<CallStatusEnum> {
    return this.callInfo$.asObservable().pipe(
      filter((callInfo: CallInfo) => callInfo != null),
      map((callInfo: CallInfo) => callInfo.callStatus),
      distinctUntilChanged(),
    );
  }

  public getCallContactInfo$(): Observable<ContactCallInfo> {
    return this.callInfo$.asObservable().pipe(
      filter((callInfo: CallInfo) => callInfo != null),
      map((callInfo: CallInfo) => callInfo.contactInfo),
      distinctUntilChanged(),
    );
  }

  public getTaskId$(): Observable<number> {
    return this.callInfo$.asObservable().pipe(
      filter((callInfo: CallInfo) => callInfo != null),
      map((callInfo: CallInfo) => callInfo.taskId),
      distinctUntilChanged(),
    );
  }

  public whenLogCallCompleted(): Observable<CallInfo> {
    return this.logCallCompleted$.asObservable();
  }

  public turnRecordingOn() {
    const callInfo = this.getCallInfo();
    callInfo.recordingInfo.isRecordingOn = true;
    this.modifyCallInfo(callInfo);
  }

  public cancelRecording() {
    const callInfo = this.getCallInfo();
    const { prospectCallId } = callInfo;
    return this._httpClient.post(`${environment.backend_url}/v1/phonecalls/${prospectCallId}/cancel`, {}).pipe(
      finalize(() => {
        callInfo.recordingInfo.isRecordingCancelledForCurrentCall = true;
        callInfo.recordingInfo.isRecordingOn = false;
        this.modifyCallInfo(callInfo);
      }),
    );
  }

  protected markTheTaskAsComplete(taskId) {
    this._appStore.dispatch(UpdateTasksStatus({ tasks: [taskId], status: TaskStatus.Completed }));
    this._appStore.dispatch(ClearSelectionAction()); // Clear selections on tasks page
    // TODO: (5/5/2021): Since we have standalone caller, we should not be using EngagePanel all the time. Address as part of log call.
    this.analyticsService.markedTaskAsCompleteEvent(taskTypesEnum.Calls, AnalyticsSectionType.EngagePanel);
  }

  logConversationOccuredAnalytics(callStatus) {
    // We only want events for when a contact has picked up the phone
    if (callStatus !== CallStatusEnum.callWithProspectStarted) {
      return;
    }
    const callInfo = this.getCallInfo();
    const contactId: number = _.get(callInfo, 'contactInfo.id', 0);
    this.analyticsService.calledConversationOccuredEvent(
      new ConversationContact({
        contactMail: _.get(callInfo, 'contactInfo.email'),
        incomingCall: _.get(callInfo, 'isIncoming'),
        phoneNumber: _.get(callInfo, 'contactInfo.phoneNumberDialed', ''),
        partOfSalesflow: false,
        salesflowStep: '',
        callId: _.get(callInfo, 'callId', '0'),
        contactId: contactId?.toString(),
        zoomPersonId: (_.get(callInfo, 'contactInfo.zoomId') || '0')?.toString(),
      }),
    );
  }
}
