import { Injectable } from '@angular/core';
import { AutoDialNoAnswer, AutoDialPhoneTypes } from '@zi-pages/engage-mode-v2/engage-mode-v2.config';
import { EngageModeAutoDialEntity } from '@zi-pages/engage-mode-v2/model/engage-mode-autodial-entity.model';
import { EngageModeStorageV2Service } from '@zi-pages/engage-mode-v2/service/engage-mode-storage-v2.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EngageModeAutoDialV2Service {
  private autoDialConfig: BehaviorSubject<EngageModeAutoDialEntity>;
  autoDialConfig$: Observable<EngageModeAutoDialEntity>;

  private interval;
  private autoDialTimer: BehaviorSubject<number>;
  autoDialTimer$: Observable<number>;

  constructor(private engageModeStorageService: EngageModeStorageV2Service) {}

  initializeAutoDialSubscriptions() {
    this.autoDialConfig$ = this.autoDialConfig.asObservable();

    this.autoDialConfig$.pipe(distinctUntilChanged()).subscribe((autoDialConfig) => {
      this.autoDialTimer.next(autoDialConfig.interval);
      this.engageModeStorageService.storeEngageModeAutoDialConfigInSessionStorage(autoDialConfig);
    });
  }

  initializeAutoDialTimerSubscriptions() {
    this.autoDialTimer$ = this.autoDialTimer.asObservable();

    this.autoDialTimer$.pipe(distinctUntilChanged()).subscribe((autoDialTimer) => {
      this.engageModeStorageService.storeEngageModeAutoDialTimerConfigInSessionStorage(autoDialTimer);
    });
  }

  initializeEngageModeAutoDial(callRecording: boolean, allowCancelRecording: boolean) {
    this.autoDialConfig = new BehaviorSubject({
      enabled: false,
      interval: 0,
      phoneTypes: [AutoDialPhoneTypes.Direct],
      noAnswer: AutoDialNoAnswer.TryAll,
      callRecording,
      allowCancelRecording,
    });
    this.autoDialTimer = new BehaviorSubject(0);

    this.initializeAutoDialSubscriptions();
    this.initializeAutoDialTimerSubscriptions();
  }

  initializeEngageModeAutoDialFromSessionStorage() {
    // for browser refresh on engage mode
    const autoDialConfigFromStorage = this.engageModeStorageService.loadEngageModeAutoDialConfigFromSessionStorage();
    this.autoDialConfig = new BehaviorSubject({
      enabled: autoDialConfigFromStorage.enabled,
      interval: autoDialConfigFromStorage.interval,
      phoneTypes: autoDialConfigFromStorage.phoneTypes,
      noAnswer: autoDialConfigFromStorage.noAnswer,
      callRecording: autoDialConfigFromStorage.callRecording,
      allowCancelRecording: autoDialConfigFromStorage.allowCancelRecording,
    });
    this.autoDialTimer = new BehaviorSubject(this.engageModeStorageService.loadEngageModeAutoDialTimerConfigFromSessionStorage());

    this.initializeAutoDialSubscriptions();
    this.initializeAutoDialTimerSubscriptions();
  }

  getAutoDialConfig(): EngageModeAutoDialEntity {
    return this.autoDialConfig ? this.autoDialConfig.getValue() : null;
  }

  getAutoDialTimer(): number {
    return this.autoDialTimer ? this.autoDialTimer.getValue() : null;
  }

  toggleAutoDialEnabled() {
    const autoDialValue: EngageModeAutoDialEntity = { ...this.autoDialConfig.getValue() };
    autoDialValue.enabled = !autoDialValue.enabled;
    this.autoDialConfig.next(autoDialValue);
    this.resetAutoDialTimer();
  }

  toggleAutoDialCallRecoding() {
    const autoDialValue: EngageModeAutoDialEntity = { ...this.autoDialConfig.getValue() };
    autoDialValue.callRecording = !autoDialValue.callRecording;
    this.autoDialConfig.next(autoDialValue);
  }

  setAutoDialInterval(value: number) {
    const autoDialValue: EngageModeAutoDialEntity = { ...this.autoDialConfig.getValue() };
    autoDialValue.interval = value;
    this.autoDialConfig.next(autoDialValue);
  }

  setAutoDialPhoneTypes(value: Array<string>) {
    const autoDialValue: EngageModeAutoDialEntity = { ...this.autoDialConfig.getValue() };
    autoDialValue.phoneTypes = value;
    this.autoDialConfig.next(autoDialValue);
  }

  setAutoDialNoAnswer(value: string) {
    const autoDialValue: EngageModeAutoDialEntity = { ...this.autoDialConfig.getValue() };
    autoDialValue.noAnswer = value;
    this.autoDialConfig.next(autoDialValue);
  }

  startOrResumeAutoDialTimer() {
    let value = this.autoDialTimer.getValue();
    this.interval = setInterval(() => {
      this.autoDialTimer.next(value--);
    }, 1000);
  }

  pauseOrStopAutoDialTimer() {
    clearInterval(this.interval);
    this.interval = null;
  }

  resetAutoDialTimer() {
    this.pauseOrStopAutoDialTimer();
    const autoDialValue: EngageModeAutoDialEntity = this.autoDialConfig.getValue();
    this.autoDialTimer.next(autoDialValue.interval);
  }

  unsubscribeOnExit() {
    this.autoDialConfig.complete();
    this.engageModeStorageService.clearEngageModeDataAutoDialInSessionStorage();
  }
}
