import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ExtGoToNinetyPctAction } from '@app/extension/ngrx/action/extension.action';
import { getExtensionMode } from '@app/extensionMode.config';
import { ApplicationState } from '@app/reducers';
import { Store } from '@ngrx/store';
import { ErrorDialogComponent } from '@zi-common/component/error-dialog/error-dialog.component';
import { MessageType } from '@zi-common/model/message/message-type';
import { Message } from '@zi-common/model/message/message.model';
import { ContactsServiceService } from '@zi-common/service/contacts-service/contacts-service.service';
import { NotyService } from '@zi-common/service/noty/noty.service';
import { OrganizationService } from '@zi-common/service/organization/organization.service';
import { TaskType } from '@zi-core/enums/task-data.enum';
import { NavigationService } from '@zi-core/service/navigation.service';
import { EmailModeService } from '@zi-pages/email-v2/services/email-mode.service';
import { EngageActionTypeV2 } from '@zi-pages/engage-mode-v2/engage-mode-v2.config';
import { EngageModeEntity } from '@zi-pages/engage-mode-v2/model/engage-mode-entity.model';
import { EngageModeAutoDialV2Service } from '@zi-pages/engage-mode-v2/service/engage-mode-auto-dial-v2.service';
import { EngageModeCallLogV2Service } from '@zi-pages/engage-mode-v2/service/engage-mode-call-log-v2.service';
import { EngageModeV2Service } from '@zi-pages/engage-mode-v2/service/engage-mode-v2.service';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { BridgeDialerService } from '@app/caller/service/bridge-dialer.service';
import { VoipDialerService } from '@app/caller/service/voip-dialer.service';
import { getManualLogCallDialogOpen } from '@app/caller/state/call-log.state';

@Injectable()
export class EngageModeNavigationV2Service {
  constructor(
    private engageModeService: EngageModeV2Service,
    private engageModeAutoDialService: EngageModeAutoDialV2Service,
    private engageModeCallLogService: EngageModeCallLogV2Service,
    private navigationService: NavigationService,
    private contactsService: ContactsServiceService,
    private emailService: EmailModeService,
    private bridgeDialerService: BridgeDialerService,
    private organizationService: OrganizationService,
    private notyService: NotyService,
    private voipService: VoipDialerService,
    private router: Router,
    private appStore: Store<ApplicationState>,
    private _location: Location,
    private dialog: MatDialog,
  ) {}

  enterEngageMode(actionType: EngageActionTypeV2 = EngageActionTypeV2.Email, engageItems: Array<EngageModeEntity>) {
    // display warning/error if standalone emailer/caller are open
    const emailDraftsPresent = this.emailService.getEmailFormArray().length > 0;
    const bridgeActiveCall = this.bridgeDialerService.checkIfActiveCallOrSession();
    const isVoipCallActive = this.voipService.checkIfSesionActive() || this.voipService.hasOpenCallPanel();
    const incomingActive = this.voipService.getIncomingCallPending();
    const isManualLogActive = this.isManualLogDialogOpen();
    if (emailDraftsPresent || bridgeActiveCall || isVoipCallActive || incomingActive || isManualLogActive) {
      let content;
      let primaryButtonText = 'Ok';
      let secondaryBoltonDisabled = false;

      if (incomingActive) {
        content = 'Navigating to Engage Mode while an incoming call is pending is not possible.';
        secondaryBoltonDisabled = true;
      } else if (bridgeActiveCall || isVoipCallActive) {
        content = 'Navigating to Engage Mode during a call is not possible.';
        secondaryBoltonDisabled = true;
      } else if (isManualLogActive) {
        content = 'Navigating to Engage Mode during a call logging is not possible.';
        secondaryBoltonDisabled = true;
      } else {
        content = 'Navigating to Engage Mode will discard your drafts.\n\nAre you sure you want to navigate to Engage Mode?';
        primaryButtonText = 'Yes';
      }

      this.dialog
        .open(ErrorDialogComponent, {
          width: '400px',
          data: {
            title: 'Navigating to Engage Mode',
            content,
            secondaryBoltonDisabled,
            primaryButtonText,
          },
        })
        .afterClosed()
        .subscribe((dialogResponse) => {
          if (dialogResponse && !bridgeActiveCall && !isVoipCallActive) {
            this.navigateToEngageMode(actionType, engageItems);
          } else {
            return;
          }
        });
    } else {
      this.navigateToEngageMode(actionType, engageItems);
    }
  }

  navigateToEngageMode(actionType: EngageActionTypeV2 = EngageActionTypeV2.Email, engageItems: Array<EngageModeEntity>) {
    this.emailService.clearAllEmailTemplates();
    this.bridgeDialerService.cleanUpCallerSession();
    this.voipService.clearCallEventsForEngage();
    // if all the contacts in engageItems are unsubscribed, do not navigate to engage mode
    if (
      this.contactsService.allEngageModeContactUnsubscribed(
        engageItems.map((item) => item.contactData),
        actionType,
      )
    ) {
      return;
    }

    this.organizationService
      .getOrganization()
      .pipe(
        take(1),
        map((res) => {
          const callRecording = _.get(res.organization, 'allowRecordPhoneCalls', false);
          const allowCancelRecording = _.get(res.organization, 'allowCancelRecordPhoneCalls', false);
          return { callRecording, allowCancelRecording };
        }),
        catchError(() => {
          this.notyService.postMessage(new Message(MessageType.ERROR, 'Error retrieving call recording information.'));
          return of({ callRecording: false, allowCancelRecording: false });
        }),
      )
      .subscribe(({ callRecording, allowCancelRecording }) => {
        // initialize engage mode before navigation
        engageItems = this.filterOutOtherTasks(engageItems);
        if (engageItems.length > 0) {
          this.engageModeService.initializeEngageMode(actionType, engageItems);
          this.engageModeAutoDialService.initializeEngageModeAutoDial(callRecording, allowCancelRecording);
          this.engageModeCallLogService.initializeEngageModeCallLog();
          if (getExtensionMode()) {
            this.appStore.dispatch(ExtGoToNinetyPctAction({ redirectUrl: this.navigationService.getEngageV2Url() }));
          } else {
            this.router.navigate([this.navigationService.getEngageV2Url()]);
          }
        }
      });
  }

  exitEngageMode() {
    this._location.back();
  }

  replaceEngageEntity(entityToReplace, engageItems: Array<EngageModeEntity>) {
    this.engageModeService.replaceEntity(entityToReplace, engageItems);
  }

  private filterOutOtherTasks(engageItems: Array<EngageModeEntity>) {
    const otherTasks = engageItems.filter((item) => item.taskId && item.taskType === TaskType.Other);
    if (otherTasks.length > 0) {
      if (otherTasks.length === engageItems.length) {
        this.notyService.postMessage(new Message(MessageType.ERROR, `Cannot navigation to Engage Mode with Other type Tasks.`));
        engageItems = engageItems.filter((item) => !otherTasks.includes(item));
      } else {
        this.notyService.postMessage(
          new Message(
            MessageType.WARNING,
            `${otherTasks.length} out of ${engageItems.length} tasks ` + `selected were Other type Tasks, these have been excluded from Engage Mode.`,
          ),
        );
        engageItems = engageItems.filter((item) => !otherTasks.includes(item));
      }
    }
    return engageItems;
  }

  private isManualLogDialogOpen(): boolean {
    let isOpen: boolean;
    this.appStore.select(getManualLogCallDialogOpen).subscribe((isDialogOpen) => (isOpen = isDialogOpen));
    return isOpen;
  }
}
