import { ApplicationState } from '@app/reducers';
import { createSelector } from '@ngrx/store';
import { defaultLoadingState } from '@zi-core/ngrx/config/ngrx-loading-states.config';

export class AdaLiveAgentState {
  isInitialized: boolean;
  loading: boolean;
  loaded: boolean;
  error: {
    hasError: boolean;
    error: any;
  };
}

export const initialAdaLiveAgentState = {
  isInitialized: false,
  ...defaultLoadingState,
  error: {
    hasError: false,
    error: null,
  },
};

export const getAdaLiveAgentState = (state: ApplicationState) => state.adaLiveAgentStatus;

export const isAdaLiveAgentInitialized = createSelector(getAdaLiveAgentState, (state: AdaLiveAgentState) => state.isInitialized);

export const isAdaLiveAgentLoading = createSelector(getAdaLiveAgentState, (state: AdaLiveAgentState) => state.loading);
