export class SearchQueryAnalyticsModel {
  application = 'null';
  source = 'null';
  object = 'null';
  amount = 0;
  tags = [];
  salesflows = [];
  companies = [];
  numOfEmployees = 'null';
  annualRevenue = 'null';
  departments = [];
  industries = [];
  countries = [];
  states = [];
  cities = [];
  jobTitles = [];
  lastEngaged = 0;
  lastEmailed = 0;
  lastCalled = 0;
  emailTemplateDates = 'null';
  emailTemplateDatePeriod = 'null';
  emailTemplateOwner = [];
  taskType = [];
  taskDueDate = [];
  taskEmailActivity = [];
  taskPriority = [];
  engagementCallResult = [];
  engagementCallSentiment = [];
  engagementCallDuration = [];
  engagementEmailActivity = [];
  salesflowsStatus = 'false';
}
