<app-dialog-template class="dozi-trial-header" [st] hasCloseIcon="true" [dialogTitle]="'SalesOS Free Trial'" (close)="onCloseDialog()">
  <app-dialog-content [ngClass]='userTrialType ? "dozi-trial-content" : "dozi-trial-signup-content"'>
    <div *ngIf="!isLoading; else loaded">
      ​
      <div [ngSwitch]="userTrialType">
        <div *ngSwitchCase="trialStatus.TRIAL_PROVISIONED" class="main-container">
          <div class="response-container">
            <div class="display-error-msg msg-end">
              <p>Congratulations, look for an email from SalesOS to get started.</p>
              <br />
              <p>Please follow the instructions in your email to activate your SalesOS account and return here to complete the setup</p>
            </div>
            <div>
              <img class="image-success-src" width="204" height="154" [src]="successProvisionedSrc" />
            </div>
            <div>
              <div class="trial-ok">
                <app-primary-btn class="trial-ok-btn" [text]="'Continue to Login'" (clickButton)="onContinueToLogin()"> </app-primary-btn>
              </div>
            </div>
          </div>
        </div>

        <div *ngSwitchCase="trialStatus.ALREADY_USER" class="main-container">
          <div class="response-container">
            <p>Looks like you already have access to SalesOS.</p>
            <p>Connect to start seeing the benefits</p>
            <div>
              <img class="image-src image-src-zi" width="204" height="154" [src]="alreadyHaveAccessSrc" />
            </div>
            <div class="contact-us">
              <app-primary-btn class="contact-us-btn" (clickButton)="onContinueToLogin()" [text]="'Continue to Login'"></app-primary-btn>
            </div>
          </div>
        </div>

        <div *ngSwitchCase="trialStatus.TRIALED_IN_PAST" class="main-container">
          <div class="response-container">
            <div class="display-error-msg">
              <p>You don’t have an active SalesOS subscription.</p>
              <p>Miss the data? We don’t blame you!</p>
              <p>Reach back out to get access</p>
            </div>
            <div>
              <img class="image-inactive-src" width="204" height="154" [src]="errorSrc" />
            </div>
            <div class="contact-us">
              <app-primary-btn class="contact-us-btn" [text]="'Contact Us'" (clickButton)="onContactUs()"></app-primary-btn>
            </div>
          </div>
        </div>

        <div *ngSwitchCase="trialStatus.ALREADY_IN_TRIAL" class="main-container">
          <div class="response-container">
            <div class="display-error-msg">
              <p>It seems that this user was already provisioned.</p>
              <p>Please check you inbox or contact us if you have any issues.</p>
            </div>
            <div>
              <img class="image-src image-src-zi" width="204" height="154" [src]="alreadyHaveAccessSrc" />
            </div>
            <div class="contact-us">
              <app-primary-btn class="contact-us-btn" [text]="'Login Here'" (clickButton)="onContinueToLogin()"></app-primary-btn>
            </div>
          </div>
        </div>

        <div *ngSwitchCase="trialStatus.TRIAL_PROVISION_ERROR" class="main-container">
          <div class="response-container">
            <div class="display-error-msg">
              <p>Looks like something went wrong.</p>
              <p>Please contact us for further assistance</p>
            </div>
            <div>
              <img class="image-src" width="204" height="154" [src]="errorSrc" />
            </div>
            <div class="contact-us">
              <app-primary-btn class="contact-us-btn" [text]="'Contact Us'" (clickButton)="onContactUs()"></app-primary-btn>
            </div>
          </div>
        </div>

        <div *ngSwitchCase="trialStatus.NOT_WHITELISTED" class="main-container">
          <div class="response-container">
            <div class="display-error-msg">
              <p>Sorry!</p>
              <p>We cannot create an account for you at this point</p>
            </div>
            <div>
              <img class="image-src" width="185" height="154" [src]="cantCreateError" />
            </div>
            <div class="contact-us">
              <app-primary-btn class="contact-us-btn" [text]="'Contact Us'" (clickButton)="onContactUs()"></app-primary-btn>
            </div>
          </div>
        </div>

        <div *ngSwitchDefault class="dozi-trial-signup-content signup-container">
          <div class="trial-container">
            <p class="engage-text">Get the most out of Engage</p>
            <p class="engage-sub-text1">By pairing it with the most powerful B2B</p>
            <p class="engage-sub-text2">intelligence platform in the market</p>
            <iframe
              class="vidyard_iframe video-container"
              [src]="videoUrl | safeValue: 'resourceUrl'"
              width="600"
              height="338"
              scrolling="no"
              frameborder="0"
              allowtransparency="true"
              allowfullscreen
            >
            </iframe>
            <app-primary-btn [text]="'Start SalesOS free trial'" (clickButton)="onRegisterTrial()" class="trial-position-btn trial-start-btn">
            </app-primary-btn>

            <div class="sign-up-email">
              <p>For {{email}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #loaded>
      <app-loader class="dozi-trial-loader"></app-loader>
    </ng-template>
  </app-dialog-content>
</app-dialog-template>
