/**
 * Encodes special characters in a string to their corresponding HTML entities.
 *
 * @param value - The string to encode.
 * @returns The encoded string.
 */
export const encodeHtmlEntities = (value: string): string => {
  if (!value) {
    return '';
  }
  const htmlEntities: Record<string, string> = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
  };

  return value.replace(/&(?!amp;|lt;|gt;|quot;|#39;)/g, '&amp;').replace(/[<>"']/g, (match) => {
    return htmlEntities[match];
  });
};
