import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OrgEmailDailyQuotaResponse } from '@zi-core/http-model/response/email-quota.response';
import { ChartsData, LegendData, ProgressChartData } from '@zi-common/interface/charts';

@Injectable()
export class EmailNotificationService {
  backendUrl = environment.backend_url;
  url = `${this.backendUrl}/v1/alerts`;
  quotaUrl = `${this.backendUrl}/v1/org/quota/messages`;

  constructor(private httpClient: HttpClient) {}

  getCurrentSetting() {
    return this.httpClient.get(this.url);
  }

  changeAlertSetting(type, frequency) {
    return this.httpClient.post(this.url, { Frequencies: { [type]: frequency } });
  }

  /**
   * returns the email daily quota limits and usages
   */
  getQuotaUsageLimitsForEmails(): Observable<OrgEmailDailyQuotaResponse> {
    return this.httpClient.get<OrgEmailDailyQuotaResponse>(this.quotaUrl);
  }

  /**
   * formats the email quota response to chart data
   */
  parseEmailQuotaResponseIntoChartData(emailQuota: OrgEmailDailyQuotaResponse): ChartsData {
    const pending = emailQuota.autoMessagesScheduled + emailQuota.manualMessagesPlanned;
    const emailsConsumed = pending + emailQuota.messagesAlreadySent;
    const available = emailQuota.messagesQuota - emailsConsumed;
    const legendData: LegendData[] = [
      {
        key: 'sent',
        value: emailQuota.messagesAlreadySent || 0,
      },
      {
        key: 'pending',
        value: pending || 0,
      },
      {
        key: 'available',
        value: available < 0 ? 0 : available,
      },
    ];
    const chartData: ProgressChartData[] = [
      {
        category: '',
        from: 0,
        to: emailQuota.messagesAlreadySent || 0,
        name: 'sent',
      },
      {
        category: '',
        from: emailQuota.messagesAlreadySent || 0,
        to: emailsConsumed || 0,
        name: 'pending',
      },
      {
        category: '',
        from: emailsConsumed || 0,
        to: emailQuota.messagesQuota,
        name: 'available',
      },
    ];
    return { chartData, legendData };
  }
}
