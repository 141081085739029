export enum SalesflowStep {
  AutomaticEmail = 'AutomaticEmail',
  ManualEmail = 'ManualEmail',
  PhoneCall = 'PhoneCall',
  Other = 'Other',
  Email = 'Email',
}

export const STEPS = [
  { id: SalesflowStep.AutomaticEmail, title: 'Auto Email', icon: 'icon-email_yourself' },
  { id: SalesflowStep.ManualEmail, title: 'Manual Email', icon: 'icon-email' },
  { id: SalesflowStep.PhoneCall, title: 'Phone Call', icon: 'icon-call' },
  { id: SalesflowStep.Other, title: 'Other', icon: 'icon-other' },
];

export const Times = {
  _24HoursInSeconds: 60 * 60 * 24,
  _60MinutesInSeconds: 60 * 60,
  _60Seconds: 60,
};

export enum UpcomingActionText {
  Auto = 'To be sent',
  Not_Auto = 'Tasks will be created',
}

export const autoEmailComment = '** The email will be sent within 2 hours of the chosen time.';

export const autoEmailNote = 'Contacts added during this time period will also receive the email';

export const taskComment = '** The Task will become overdue after the selected time';
