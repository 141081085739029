import { Contact } from '@app/core/data-model/contact.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContactState } from '../../contact.state';

// contact filter state
export class ContactEntityState {
  ids: Array<number>;
  entities: { [id: string]: Contact };
  correlationId: number;
  loaded: boolean;
  loading: boolean;
  // TODO better object for error
  error?: string;
  found: number;
  lastUpdateTime: number;
  lastUploadTime: number;
  isProcessingInCloudSearch: boolean;
}

// initial state of Entities
export const initialContactEntityState: ContactEntityState = {
  ids: [],
  entities: {},
  correlationId: 0,
  loaded: false,
  loading: false,
  found: 0,
  lastUpdateTime: new Date().getTime(),
  lastUploadTime: 0,
  isProcessingInCloudSearch: false,
};

export const contactFeatureSelector = createFeatureSelector<ContactState>('contact');

export const contactEntityProcessInCloudsearch = createSelector(contactFeatureSelector, (state) => state?.contacts?.isProcessingInCloudSearch);

// contact entity selector
export const contactEntitySelector = createSelector(contactFeatureSelector, (state) => state.contacts);

// contact upload sucess
export const contactUploadTimeSelector = createSelector(contactEntitySelector, (state) => state.lastUploadTime);

// contact entities
export const contactEntitiesSelector = createSelector(contactEntitySelector, (state) => state.entities);

// contact entity as orders array
export const contactEntityArraySelector = createSelector(contactEntitySelector, (contacts: ContactEntityState) => {
  return {
    contacts: contacts.ids.map((id) => contacts.entities[id]),
    isProcessingInCloudSearch: contacts?.isProcessingInCloudSearch,
  };
});

// contact entity by id
export const contactEntityByIdSelector = (contactId) =>
  createSelector(contactEntitySelector, (contacts: ContactEntityState) => {
    return contacts.ids.map((id) => contacts.entities[id]).find((contact) => contact.id === contactId);
  });

// Get contact entity by id
export const getContactEntityByIdSelector = (contactId) =>
  createSelector(contactEntitySelector, (contacts: ContactEntityState) => {
    return contacts;
  });

export const contactEntityFound = createSelector(contactFeatureSelector, (state) => state.contacts.found);

export const contactUpdateTimestamp = createSelector(contactEntitySelector, (state) => state.lastUpdateTime);

export const contactEntityByManyIdSelector = (contactIds: number[]) =>
  createSelector(contactEntitySelector, (contacts: ContactEntityState) => {
    return contacts.ids.map((id) => contacts.entities[id]).filter((contact) => contactIds.includes(contact.id));
  });
