import { Injectable } from '@angular/core';
import { LocalTimeFormat } from '../pages-component/contact-local-time/contact-local-time.config';
import * as _ from 'lodash';
import * as moment from 'moment';
import * as moment_tz from 'moment-timezone';
import { extraTimezoneAbbreviations } from '@zi-common/service/time-helper.config';

@Injectable({
  providedIn: 'root',
})
export class TimeHelperService {
  constructor() {}

  /**
   * * Returns the time zone region in string format for displaying in UI
   * @param region The time zone region e.g. (America/New_York)
   */
  static getTimeZone(region: string): string {
    return TimeHelperService.addCustomTZAbbreviation(region, moment_tz.tz(region).format('z'));
  }

  protected static addCustomTZAbbreviation(region: string, tzAbb: string): string {
    if (/^([+\-] ?)?[0-9]+/.test(tzAbb)) {
      return _.get(extraTimezoneAbbreviations, region, tzAbb);
    }
    return tzAbb;
  }

  /**
   * * Will retrieve the time in the string format and timezone requested
   * @param time time in raw format with the offset
   * @param timeZone in timezone format (America/New_York)
   * @param showLongText decides what message will be displayed if no time is returned
   * @param timeFormat time attributes to display. NOTE: Add more enums for different formats
   */
  getLocalTime(time: any, timeZone: string, showLongText: boolean, timeFormat: LocalTimeFormat = LocalTimeFormat.HourOnly): string {
    let localTime: moment.Moment;
    if (!time) {
      return showLongText ? 'No Local Time' : '-';
    }

    // utc offset is in minutes and timezone is in millisecond
    localTime = moment.utc().utcOffset(+(time / 60000));
    switch (timeFormat) {
      case LocalTimeFormat.HourAndDayOffset:
        return `${localTime.format('z Z, H:mm (dddd)')}`;
      case LocalTimeFormat.HourAndDay:
        const tzFormat = moment_tz.tz(timeZone).format('z');
        return `${localTime.format('h:mm A (ddd)').concat(` ${tzFormat}`)}`;
      case LocalTimeFormat.HourAndDayShort:
        return `${localTime.format('(h:mm A, ddd)')}`;
      case LocalTimeFormat.DayAndMonthAndNumberShort:
        return `${localTime.format('ddd, MMM D')}`;
      case LocalTimeFormat.HourOnly:
      default:
        return localTime.format('h:mm A');
    }
  }
}
