import { FilterNode } from '@app/core/data-model/filter-node.model';
import { ApplicationState } from '@app/reducers';
import { createSelector } from '@ngrx/store';
import { QuickFilter } from '@app/core/data-model/quick-filter.model';
import { defaultLoadingState } from '@zi-core/ngrx/config/ngrx-loading-states.config';
import { DataLoadingState } from '@zi-common/interface/ngrx-state';

export class EngageFilterState {
  contact: EntityFilter;
  task: EntityFilter;
  salesflow: EntityFilter;
  template: EntityFilter;
  calls: EntityFilter;
  message: EntityFilter;
  companyAccount: EntityFilter;
}

export class EntityFilter {
  fields?: EntityFilterNodeState;
  tags?: EntityFilterNodeState;
  salesflow?: EntityFilterNodeState;
  quickFilters?: QuickFilterState;
}

export interface EntityFilterNodeState extends DataLoadingState {
  filters: FilterNode[];
  errorMessage?: string;
}

export interface QuickFilterState extends DataLoadingState {
  qFilters: QuickFilter[];
  errorMessage?: string;
}
const initEntityFilter = {
  fields: {
    filters: undefined,
    ...defaultLoadingState,
  },
  tags: {
    filters: undefined,
    ...defaultLoadingState,
  },
  salesflow: {
    filters: undefined,
    ...defaultLoadingState,
  },
  quickFilters: {
    qFilters: undefined,
    ...defaultLoadingState,
  },
};

export const initialEngageFilterState: EngageFilterState = {
  contact: { ...initEntityFilter },
  task: { ...initEntityFilter },
  salesflow: { ...initEntityFilter },
  template: { ...initEntityFilter },
  calls: { ...initEntityFilter },
  message: { ...initEntityFilter },
  companyAccount: { ...initEntityFilter },
};

export const getEngageFilterState = (state: ApplicationState) => state.filter;

// Contact filter selectors
export const getEngageContactFilterState = createSelector(getEngageFilterState, (state: EngageFilterState) => state.contact);
export const getContactFilterTags = createSelector(getEngageContactFilterState, (state: EntityFilter) => state.tags.filters);
export const hasContactFilterTagsLoaded = createSelector(getEngageContactFilterState, (state: EntityFilter) => !!state?.tags?.loaded);
export const getContactFilterFields = createSelector(getEngageContactFilterState, (state: EntityFilter) => state.fields.filters);
export const hasContactFilterFieldsLoaded = createSelector(getEngageContactFilterState, (state: EntityFilter) => !!state?.fields?.loaded);
export const getContactSalesflow = createSelector(getEngageContactFilterState, (state: EntityFilter) => state.salesflow.filters);
export const hasContactFilterSalesflowLoaded = createSelector(getEngageContactFilterState, (state: EntityFilter) => !!state?.salesflow?.loaded);
export const getContactQuickFilters = createSelector(getEngageContactFilterState, (state: EntityFilter) => state.quickFilters.qFilters);
export const hasContactQuickFiltersLoaded = createSelector(getEngageContactFilterState, (state: EntityFilter) => !!state?.quickFilters?.loaded);

// Task filter selectors
export const getEngageTaskFilterState = createSelector(getEngageFilterState, (state: EngageFilterState) => state.task);
export const getTaskFilterTags = createSelector(getEngageTaskFilterState, (state: EntityFilter) => state.tags.filters);
export const getTaskFilterFields = createSelector(getEngageTaskFilterState, (state: EntityFilter) => state.fields.filters);
export const getTaskQuickFilters = createSelector(getEngageTaskFilterState, (state: EntityFilter) => state.quickFilters.qFilters);

// Template filter selectors
export const getEngageTemplateFilterState = createSelector(getEngageFilterState, (state: EngageFilterState) => state.template);
export const getTemplateFilterTags = createSelector(getEngageTemplateFilterState, (state: EntityFilter) => state.tags.filters);
export const getTemplateFilterSalesflow = createSelector(getEngageTemplateFilterState, (state: EntityFilter) => state.salesflow.filters);

// Message filter selectors
export const getEngageMessageFilterState = createSelector(getEngageFilterState, (state: EngageFilterState) => state.message);
export const getSentEmailsFilters = createSelector(getEngageMessageFilterState, (state: EntityFilter) => state.fields.filters);

// Company Account filter selectors
export const getEngageCompanyAccountFilterState = createSelector(getEngageFilterState, (state: EngageFilterState) => state.companyAccount);
export const getCompanyAccountFiltersFields = createSelector(getEngageCompanyAccountFilterState, (state: EntityFilter) => state.fields.filters);

// Calls filter selectors
export const getEngageCallsFilterState = createSelector(getEngageFilterState, (state: EngageFilterState) => state.calls);
