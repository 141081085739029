import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PreviewTemplateDialogComponent } from '@zi-common/pages-component/preview-template-dialog/preview-template-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { ApplicationState } from '@app/reducers';
import { TemplateServiceService } from '@zi-common/service/templates-data/template-service.service';
import { finalize, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import { Contact } from '@zi-core/data-model/contact.model';
import { EmailPreviewComponent } from '@zi-common/pages-component/email-preview/email-preview.component';
import { isGmailExtension } from '@app/extensionMode.config';
import { TemplateCategory } from '@zi-common/interface/template-category.enum';

@Component({
  selector: 'app-preview-template-dialog-v2',
  templateUrl: './preview-template-dialog-v2.component.html',
  styleUrls: ['./preview-template-dialog-v2.component.scss'],
})
export class PreviewTemplateDialogV2Component extends PreviewTemplateDialogComponent implements OnInit {
  completeEmail: any;
  contacts: Array<Contact>; // Array of contacts
  currentContactIndex = 0;
  isTemplateOOTB: boolean;
  senderId: number;
  isExtOnGMail = false;
  // Reference to email preview component
  @ViewChild('EmailPreviewComponent') emailPreview: EmailPreviewComponent;

  constructor(
    public dialogRef: MatDialogRef<any>,
    private _sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _store: Store<ApplicationState>,
    private _templateService: TemplateServiceService,
  ) {
    super(dialogRef, _sanitizer, data, _store, _templateService);
  }

  ngOnInit(): void {
    this.isExtOnGMail = isGmailExtension();
    const templateId = this.messageTemplateId;
    this.messageTemplateId = 0;
    this.contacts = _.get(this.data, 'contacts', null);
    this.senderId = _.get(this.data, 'senderId', null);
    this.isTemplateOOTB = _.get(this.data, 'isOOTB', false);
    super.ngOnInit();
    if (templateId && !this.isTemplateOOTB) {
      this.isLoading = true;
      this.templateService
        .getTemplateById(this.messageTemplateId)
        .pipe(
          finalize(() => (this.isLoading = false)),
          tap((resp) => {
            const respTemplates = _.get(resp, 'templates', []);
            const tiles = _.get(resp, 'tiles', []);
            const newAttachments = [];
            tiles.forEach((elt) => {
              newAttachments.push({ fileName: elt.attributes.fileName, url: elt.attributes.pictureUrl || elt.attributes.url });
            });
            this.attachments = newAttachments;
            this.template = respTemplates.find((template) => template.id === this.messageTemplateId) || {};
            if (this.template && this.template.attributes) {
              this.templateContent = this.sanitizer.sanitize(_.get(this.template, 'attributes.message', ''), null);
              this.completeEmail = {
                to: this.contacts,
                message: this.templateContent,
                subject: this.template.subject,
                attachments: this.attachments,
                tileIds: this.attachments.map((t) => t.id),
              };
            }
          }),
        )
        .subscribe();
      this.messageTemplateId = templateId;
    } else if (this.isTemplateOOTB) {
      this.templateContent = _.get(this.data, 'messageTemplateBody', '');
    }
  }

  /**
   * On previous contact arrow clicked
   * Decrease the index of contact is displaying
   * Call to onPreviousClicked of email preview component
   */
  onPreviousClicked(): void {
    this.currentContactIndex--;
    this.emailPreview.onPreviousClicked();
  }

  /**
   * On next contact arrow clicked
   * Increase the index of contact is displaying
   * Call to onNextClicked of email preview component
   */
  onNextClicked(): void {
    this.currentContactIndex++;
    this.emailPreview.onNextClicked();
  }

  templateChanged(category: TemplateCategory): void {
    this.emailPreview.sendPreviewAnalytics(category);
  }
}
