<ng-container *ngIf="errors">
  <ng-container *ngIf="errors['required']; else email">{{validationMessages.required}}</ng-container>

  <ng-template #email>
    <ng-container *ngIf="errors['email']; else pattern">{{validationMessages.email}}</ng-container>
  </ng-template>

  <ng-template #pattern>
    <ng-container *ngIf="errors['pattern']; else phone">{{validationMessages.email}}</ng-container>
  </ng-template>

  <ng-template #phone>
    <ng-container *ngIf="errors['phone']; else newNameFieldError">{{validationMessages.phone}}</ng-container>
  </ng-template>

  <ng-template #newNameFieldError>
    <ng-container *ngIf="errors['new-Name-field-error']; else plusPhoneError">{{validationMessages.required}}</ng-container>
  </ng-template>

  <ng-template #plusPhoneError>
    <ng-container *ngIf="errors['equal-phone-error']; else customUrlError">{{validationMessages.equalPhoneError}}</ng-container>
  </ng-template>

  <ng-template #customUrlError>
    <ng-container *ngIf="errors['url-equal-field-error']; else plusUrlError">{{validationMessages.customUrlError}}</ng-container>
  </ng-template>

  <ng-template #plusUrlError>
    <ng-container *ngIf="errors['url-plus-field-error']; else generic">{{validationMessages.plusUrlError}}</ng-container>
  </ng-template>

  <ng-template #generic>
    <ng-container>{{validationMessages.generic}}</ng-container>
  </ng-template>
</ng-container>
