import { createAction, props } from '@ngrx/store';
import { AuthenticatedToken, RulesEngineToken } from '@zi-core/http-model/response/authenticated-token.response.model';
import { LoginRedirectParams } from '@zi-core/data-model/login-redirect-params.model';
import { Profile } from '@zi-core/data-model/profile.model';
import { IntegrationNamesMap } from '@zi-core/enums/integration-names-map.enum';
import { ConfigurationCRM } from '@app/core/http-model/response/crm.model';
import { Organization } from '@app/core/http-model/response/organization.model';
import { UserLicensesResponseModel } from '@zi-core/data-model/dozi-integration/response/user-licenses.response.model';
import { SalesflowQuotaConfig } from '@app/core/interface/salesflow-quota';

export const LoginSuccessAction = createAction(
  '[Login] Login Success',
  (
    authToken: AuthenticatedToken,
    email: string = '',
    toRemember: boolean = false,
    profile: Profile = null,
    loginRedirectParams: LoginRedirectParams,
    multiFactorAuthSetupRequired: boolean = false,
    name: string = null,
    mfaPhoneNumber: string = null,
    mfaEmail: string = null,
  ) => ({
    authToken,
    email,
    toRemember,
    profile,
    loginRedirectParams,
    multiFactorAuthSetupRequired,
    name,
    mfaPhoneNumber,
    mfaEmail,
  }),
);

export const LogoutAction = createAction('[Login] Logout', props<{ urlToNavigateTo?: string; options?: { skipLocationChange?: boolean } }>());

export const SetAuthenticatedToken = createAction('[Login] Set Authentication Token', props<{ payload: AuthenticatedToken }>());

export const SetRulesEngineToken = createAction('[Login] Set Rules Engine Token', props<{ payload: RulesEngineToken }>());

export const ResetRememberMeData = createAction('[Login] Reset Remember Me Data');

export const RefreshTokenAction = createAction('[Token] token/refresh_auth_token');

export const RefreshTokenSuccessAction = createAction('[Token] token/refresh_auth_token/success', props<{ authenticatedToken: AuthenticatedToken }>());

export const RefreshTokenFailureAction = createAction('[Token] token/refresh_auth_token/failure', props<{ error: any }>());

export const UpdateProfile = createAction('[Token] profile/UpdateProfile', props<{ profile: Profile }>());

export const GetUserZoominfoDetailsAction = createAction('[users] Get user details');

export const GetUserZoominfoDetailsSuccessAction = createAction('[users] Get user details success', props<{ zoomUser: any }>());

export const GetUserZoominfoDetailsErrorAction = createAction('[users] Get user details failure', props<{ error: any }>());

export const ClearUserZoominfoDetailsAction = createAction('[users] Clear zoomUser details');

export const LoadConnectedServices = createAction('[Login] Load Connected Services');

export const LoadOrgConnected = createAction('[Login] Load Org Connected');

export const LoadConnectedServicesSuccess = createAction('[Login] Load Connected Services / success', props<{ connectedServices: IntegrationNamesMap[] }>());

export const LoadOrgConnectedSuccess = createAction('[Login] Load Org Connceted / success', props<{ org: Organization }>());

export const LoadConfigurationCRM = createAction('[Login] Load Configuration CRM');

export const LoadConfigurationCRMSuccess = createAction('[Login] Load Configuration CRM success', props<{ configurationCRM: ConfigurationCRM }>());

export const VerifyOneTimePasswordAction = createAction(
  '[Login] Verify one time password',
  props<{ mfaPhoneNumber: string; mfaEmail: string; userName: string; encodedRedirectParams: string; multiFactorAuthJwt: string }>(),
);

export const ProvisionMultiFactorAuthAction = createAction(
  '[Login] Provision multi factor auth',
  props<{ name: string; email: string; encodedRedirectParams: string; multiFactorAuthJwt: string }>(),
);

export const ClearMultiFactorAuthTokenAction = createAction('[Token] Clear MultiFactorAuthToken');

export const GetUserWebsiteDomainListAction = createAction('[websiteDomains] Get user websites');

export const GetUserWebsiteDomainListSuccessAction = createAction('[websiteDomains] Get user websiteDomains success', props<{ websiteDomains: any }>());

export const GetUserWebsiteDomainListFailureAction = createAction('[websiteDomains] Get user websiteDomains failure', props<{ error: any }>());

export const LoadTwilioClientTokenAction = createAction('[VOIP] Load Twilio Client Token Action');

export const LoadTwilioClientTokenSuccessAction = createAction('[VOIP] Load Twilio Client Token Success Action', props<{ token: string }>());

export const LoadTwilioClientTokenFailureAction = createAction('[VOIP] Load Twilio Client Token Failure Action', props<{ error: any }>());

export const SetUserLicensesSuccessAction = createAction('[Auth] set/userLicenses/success', props<{ licenses: UserLicensesResponseModel }>());

export const SetUserLicensesFailureAction = createAction('[Auth] set/userLicenses/failure', props<{ error }>());

export const UpdateRecruiterUserLicenseAction = createAction('[Auth] set/recruiterUserLicenses', props<{ hasRecruiterLicense: boolean }>());

export const UpdateOrgSalesflowQuotaAction = createAction('[Auth] Update Org Salesflow Quota', props<{ salesflowQuotaConfig: SalesflowQuotaConfig }>());
