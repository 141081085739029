export enum PdmPlatform {
  ENGAGE = 'Engage',
}

export enum PdmApplication {
  WEB = 'Web',
  CHROME_EXTENSION = 'ChromeExtension',
}

export enum PdmProductType {
  ENGAGE = 'Engage',
}

export enum PdmProductCategory {
  ENGAGE = 'Engage',
}

export enum PdmApplicationAttribute {
  TWENTY = '20',
  EIGHTY = '80',
}
