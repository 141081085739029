export enum Permissions {
  ADMIN = 'canViewAdminFeature',
  USER = 'canAccessEngage',
  NO_ONE = 'noAccess',
  ZI_NEWS = 'canViewNews',
  ZI_NEWS_FEED = 'canViewNewsFeedFeature',
  ZI_SCOOPS = 'canViewScoops',
  NEVERBOUNCE = 'canViewNeverbounce',
  ZI_TECH_ATTRIBUTES = 'canViewTechAttributeFeature',
  TECHNOGRAPHICS = 'canViewTechnographics',
  COMPANY_ATTRIBUTES_DATA_AND_SEARCH_FILTER = 'canViewCompanyAttributesDataAndSearchFilter',
  WEBSIGHTS = 'canAccessWebsights',
  ZI_ORG_CHARTS = 'canViewOrgCharts',
  NO_ZOOM_ROLES = 'noZoomRoles',
  LD_WAFFLE_RECRUITER = 'ldCanViewWaffleRecruiter',
}

/**
 * Represents a group of permissions available to Enterprise role
 * @type {(any | Permissions)[]}
 */
export const ENTERPRISE_FEATURE_GROUP = [
  // ...DEFAULT_FEATURE_GROUP,
  // ...NAVIGATION_GROUP,
  // Permissions.HEADER_TABS,
  // Permissions.COMPANY_LOCATIONS,
  // Permissions.IMPORT_BUTTON,
  // Permissions.EXPORT_ENTERPRISE,
  // Permissions.ENTERPRISE_HELP,
  // Permissions.TA_PREVIEW,
  // Permissions.STACKED_SEARCH,
  // Permissions.REACHOUT_EXPORT
];

export const NONE_FEATURE_GROUP = [
  // Permissions.RESEND_EMAIL,
  // Permissions.NONE
];
