<app-dialog-template
  [dialogTitle]="title"
  [isCloseButtonDisabled]="isLoading"
  (close)="close()"
  class="associate-account-dialog-container"
  [automationId]="'associate-account-dialog-container'"
>
  <app-dialog-content class="associate-account-container">
    <div class="associate-account">
      <span class="associate-account-header">{{associateHeaderText}}</span>
      <app-company-account-search-dropdown
        class="associate-account-search-dropdown"
        [linkedCompanyAccountIdToDisable]="linkedCompanyAccountIdToDisable"
        [statusPickList]="statusPickList"
        [defaultPickListVal]="defaultPickListVal"
        [isClearDisabled]="isLoading"
        (optionSelected)="onCompanyAccountSelected($event)"
      >
      </app-company-account-search-dropdown>
      <span class="associate-account-footer">{{associateFooterText}}</span>
      <ng-container *ngIf="isLoading">
        <ng-container *ngTemplateOutlet="loader"></ng-container>
      </ng-container>
    </div>
  </app-dialog-content>

  <app-dialog-footer class="footer-buttons">
    <app-primary-btn [disabled]="!isSaveEnabled || isLoading" class="primary-button" [text]="primaryButtonText" (clickButton)="save()"></app-primary-btn>
    <app-secondary-btn [disabled]="isLoading" [text]="closeButtonText" (clickButton)="close()"></app-secondary-btn>
  </app-dialog-footer>
</app-dialog-template>

<ng-template #loader>
  <div class="loading">
    <app-loader></app-loader>
  </div>
</ng-template>
