import { createFeatureSelector, createSelector } from '@ngrx/store';

export class OnBoardingEntityState {
  unfinishedSteps: string[];
  currentStepIndex: number;
}

export const initialOnBoardingEntity: OnBoardingEntityState = {
  unfinishedSteps: null,
  currentStepIndex: null,
};

export const onBoardingFeatureSelector = createFeatureSelector<OnBoardingEntityState>('onBoarding');

export const getUnfinishedStepsSelector = createSelector(onBoardingFeatureSelector, (state) => state.unfinishedSteps);

export const getOnBoardingStep = createSelector(onBoardingFeatureSelector, (state) => state.currentStepIndex);
