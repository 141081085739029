export enum ButtonText {
  send = 'Send',
  schedule = 'Schedule',
  saveAndSend = 'Save and Send',
}

export enum EmailInvokedPage {
  CONTACT_LANDING = 'contact_landing',
  ENGAGE = 'contacts_engage',
  STANDALONE = 'standalone',
  SALESFLOW_CONTACTS = 'salesflow_contacts_page',
}

export enum AnalyticsSection {
  Action_Menu = 'Action Menu',
  Notification_Feed = 'Notifications Feed',
  Contact_Engagement_Inline = 'Contact Engagement Inline',
  Contact_Engagement_Action = 'Contact Engagement Action',
}
