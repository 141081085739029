import { Task } from '@zi-core/data-model/task.model';
import { createAction, props } from '@ngrx/store';
import { Invitation } from '@zi-core/data-model/invitation.model';
import { Note } from '@zi-core/data-model/note.model';
import { ContactSalesWorkflowRest, EventDto, ContactEngagements } from '@zi-core/http-model/response/contacts.response.model';
import { PhoneCall } from '@zi-core/http-model/response/phone-calls.response.model';
import { Contact } from '@app/core/data-model/contact.model';
import { ContactDetailsTab } from '@zi-core/data-model/contact-details.model';
import { InvitationEventsSummary } from '@zi-core/data-model/invitation-events-summary';

// fetch contact tasks
export const FetchContactExtendedFields = createAction('[Contact detail] detail/contact', props<{ id: number }>());

// contact details fetch success
export const FetchContactExtendedFieldsSuccess = createAction('[Contact detail] detail/contact/success', props<{ id: number; contact: Contact }>());

// contact details fetch fail
export const FetchContactExtendedFieldsFail = createAction('[Contact detail] detail/contact/fail', props<{ contactId: number; error: any }>());

// fetch contact engagements
export const FetchContactEngagements = createAction('[Contact detail] detail/engagements', props<{ contactId: number }>());

export const FetchContactEngagementsSuccess = createAction(
  '[Contact detail] detail/engagements/success',
  props<{ contactId: number; contactEngagements: ContactEngagements }>(),
);

export const FetchContactEngagementsFail = createAction('[Contact detail] detail/engagements/fail', props<{ contactId: number; error: any }>());

// fetch contact tasks
export const FetchContactTasks = createAction('[Contact detail] detail/task', props<{ id: number }>());

// success action for fetch contact task
export const FetchContactTasksSuccess = createAction('[Contact detail] detail/task/success', props<{ id: number; tasks: Array<Task> }>());

export const FetchContactTasksFail = createAction('[Contact detail] detail/task/Fail', props<{ contactId: number; error: any }>());

// fetch contact events
export const FetchContactEvents = createAction('[Contact detail] detail/event', props<{ id: number }>());

export const FetchContactEventsSuccess = createAction('[Contact detail] detail/event/success', props<{ id: number; events: Array<EventDto> }>());

export const FetchContactEventsSFail = createAction('[Contact detail] detail/event/fail', props<{ contactId: number; error: any }>());

// fetch contact Events
export const FetchContactInvitations = createAction('[Contact detail] detail/invitation', props<{ id: number }>());

export const FetchContactInvitationsSuccess = createAction(
  '[Contact detail] detail/invitation/success',
  props<{ id: number; invitations: Array<Invitation> }>(),
);

export const FetchContactInvitationsFail = createAction('[Contact detail] detail/invitation/failed', props<{ contactId: number; error: any }>());

// fetch contact Email Events Summary
export const FetchContactInvitationEventsSummary = createAction('[Contact detail Invitation Events] invitation/eventsSummary', props<{ id: number }>());

export const FetchContactInvitationEventsSummarySuccess = createAction(
  '[Contact detail Invitation Events] invitation/eventsSummary/success',
  props<{ id: number; invitationEventsSummary: InvitationEventsSummary }>(),
);

export const FetchContactInvitationEventsSummaryFail = createAction(
  '[Contact detail Invitation Events] invitation/eventsSummary/failed',
  props<{ contactId: number; error: any }>(),
);

// fetch contact salesflows
export const FetchContactSalesflows = createAction('[Contact detail] detail/salesflow', props<{ id: number }>());

export const FetchContactSalesflowsSuccess = createAction(
  '[Contact detail] detail/salesflow/success',
  props<{ id: number; salesflows: Array<ContactSalesWorkflowRest> }>(),
);

export const FetchContactSalesflowsFail = createAction('[Contact detail] detail/salesflow/fail', props<{ contactId: number; error: any }>());

export const UpdateContactSalesflows = createAction(
  '[Contact detail] detail/salesflow/update',
  props<{ contactId: number; salesflows: Array<ContactSalesWorkflowRest> }>(),
);

// fetch contact phone calls
export const FetchContactPhoneCalls = createAction('[Contact detail] detail/phoneCall', props<{ id: number }>());

export const FetchContactPhoneCallsSuccess = createAction('[Contact detail] detail/phoneCall/success', props<{ id: number; phoneCalls: Array<PhoneCall> }>());

export const FetchContactPhoneCallsFail = createAction('[Contact detail] detail/phoneCall/failed', props<{ contactId: number; error: any }>());

// fetch contact notes
export const FetchContactNotes = createAction('[Contact detail] detail/note/get', props<{ id: number }>());

export const FetchContactNotesSuccess = createAction('[Contact detail] detail/note/get/success', props<{ id: number; notes: Array<Note> }>());

export const FetchContactNotesFail = createAction('[Contact detail] detail/note/get/failed', props<{ contactId: number; error: any }>());

// add contact note
export const AddContactNote = createAction('[Contact detail] detail/note/add', props<{ id: number; text: string }>());

export const AddContactNoteSuccess = createAction('[Contact detail] detail/note/add/success', props<{ note: Note }>());

export const AddContactNoteFailure = createAction('[Contact detail] detail/note/add/failure', props<{ id: number; error: any }>());

// update contact note
export const UpdateContactNote = createAction('[Contact detail] detail/note/update', props<{ noteId: number; contactId: number; text: string }>());

export const UpdateContactNoteSuccess = createAction(
  '[Contact detail] detail/note/update/success',
  props<{ noteId: number; contactId: number; text: string }>(),
);

export const UpdateContactNoteFail = createAction('[Contact detail] detail/note/update/failed', props<{ contactId: number; error: any }>());

// delete contact note
export const DeleteContactNote = createAction('[Contact detail] detail/note/delete', props<{ id: number; noteId: number }>());

export const DeleteContactNoteSuccess = createAction('[Contact detail] detail/note/delete/success', props<{ id: number; noteId: number }>());

export const DeleteContactNoteFailure = createAction('[Contact detail] detail/note/delete/fail', props<{ id: number; error: any }>());

export const NewCallLog = createAction('[Contact detail] detail/callLog/new', props<{ contact: Contact; callLog: any }>());

export const NewCallLogSuccess = createAction('[Contact detail] detail/callLog/new/success', props<{ contactId: number; phoneCall: PhoneCall }>());

export const NewCallLogFail = createAction('[Contact detail] detail/callLog/new/fail', props<{ contactId: number; error: any }>());

// add contact task
export const AddContactDetailTask = createAction('[Contact detail] detail/task/add', props<{ tasks: Task[] }>());

// add contact task
export const AddUIFilter = createAction('[Contact detail ui filter] add', props<{ key: string; values: string[] }>());

export const AddUISort = createAction('[Contact detail ui sort] add', props<{ key: string; options: { displayName: string; value: string } }>());

export const AddUISortDirection = createAction('[Contact ui sort direction ] add direction', props<{ key: string; values: string }>());

// add contact task
export const UpdateContactDetailTasks = createAction('[Contact detail] detail/tasks/update', props<{ tasks: Task[] }>());

export const setContactDetailsInitialTab = createAction('[Contact detail] detail/initialTab/set', props<{ tab: ContactDetailsTab }>());

export const resetContactDetailsInitialTab = createAction('[Contact detail] detail/initialTab/reset');

export const ScheduledEmails = createAction('[Contact detail] scheduledEmails');
