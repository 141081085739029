import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router, UrlSerializer, UrlTree } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, take, tap } from 'rxjs/operators';
import { TwilioTokenRefreshService } from '@app/caller/service/twilio-token-refresh.service';
import { VoipDeviceService } from '@app/caller/service/voip-device.service';
import { ExtensionMessages } from '@app/extension/constants/extension-messages.enum';
import { ExtensionIframeMessagingService } from '@app/extension/service/extension-iframe-messaging.service';
import { turnOnExtensionMode, turnOnGmailExtensionMode } from '@app/extensionMode.config';
import { ApplicationState } from '@app/reducers';
import { DatadogService } from '@app/vendor/datadog/datadog.service';
import { AuthenticatedTokenState, getAuthState, getValidAuthToken } from '@zi-core/ngrx/state/auth.state';
import { PermissionsService } from '@zi-core/ngxPermissions/permissions.service';
import { NavigationService } from '@zi-core/service/navigation.service';
import { LoadEmailerVersionAction } from '@zi-pages/admin/ngrx/action/emailer-version.action';
import { UtilitiesService } from './common/service/utilities-service/utilities-service';
import { AudioDeviceService } from './core/service/audio-device.service';
import { TokenRefreshService } from './core/service/token-refresh.service';
import { FeatureFlagService } from './feature-flag/feature-flag.service';
import { FeatureFlagUser } from './feature-flag/interfaces/feature-flag-user.interface';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = 'dozi-apps-web-engage-ui';
  permissionSubscription: Subscription;
  pageLoaded = false;
  initStartTime;
  maxPageLoadTime = 1500;
  allSubs = [];

  constructor(
    private permissionsService: PermissionsService,
    private audioDeviceService: AudioDeviceService,
    private tokenRefreshService: TokenRefreshService,
    private twilioTokenRefreshService: TwilioTokenRefreshService,
    private utilitiesService: UtilitiesService,
    private navigationService: NavigationService,
    private featureFlagService: FeatureFlagService,
    private extensionIframeMessagingService: ExtensionIframeMessagingService,
    private _urlSerializer: UrlSerializer,
    private _appStore: Store<ApplicationState>,
    private voipDeviceService: VoipDeviceService,
    private router: Router,
    private datadogService: DatadogService,
  ) {}

  ngOnInit(): void {
    this.routeListener();
    this.tokenRefreshService.start();
    this._appStore
      .select(getValidAuthToken)
      .pipe(
        take(1),
        tap((token) => {
          if (token) {
            this.voipDeviceService.checkAndInitToken(token);
            this._appStore.dispatch(LoadEmailerVersionAction());
          }
        }),
      )
      .subscribe();
    this.identifyUserWithLaunchDarkly();
    this.permissionSubscription = this.permissionsService.subscribeToPermissions().subscribe();
    this.initStartTime = new Date().getTime() / 1000;
    const curLocation = this.navigationService.getCurrentInternalRoute();
    const urlTree: UrlTree = this._urlSerializer.parse(curLocation);
    const { queryParams } = urlTree;
    if (queryParams.isGmailExt) {
      turnOnGmailExtensionMode();
    }
    if (queryParams.isExt) {
      turnOnExtensionMode();
      this.extensionIframeMessagingService.sendMessage(ExtensionMessages.APP_INITIALIZED);
    }
  }
  ngAfterViewInit() {
    const loadTimeDifference = new Date().getTime() / 1000 - this.initStartTime;
    setTimeout(() => {
      this.pageLoaded = true;
    }, this.maxPageLoadTime - loadTimeDifference);
  }
  @HostListener('document:click.capture', ['$event'])
  documentClick(event: any): void {
    this.utilitiesService.documentClickedTarget.next(event.target);
  }

  @HostListener('window:keydown', ['$event'])
  documentButton(event: any): void {
    this.utilitiesService.buttonClicked.next(event);
  }

  ngOnDestroy(): void {
    this.permissionSubscription.unsubscribe();
    this.voipDeviceService.cleanUpDevice();
    this.allSubs.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  /**
   * Private/Protected functions
   */

  private routeListener() {
    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.datadogService.registerView(event.url);
      }
    });
  }

  private identifyUserWithLaunchDarkly() {
    this._appStore
      .pipe(
        untilDestroyed(this),
        select(getAuthState),
        distinctUntilChanged(_.isEqual),
        map((authState: AuthenticatedTokenState) => {
          let userObj: FeatureFlagUser;
          if (authState?.authenticatedToken && authState?.profile) {
            userObj = {
              key: `engage_${authState?.authenticatedToken?.userId || 0}`,
              email: authState?.profile?.email, // Engage User Email
              name: authState?.profile?.name, // Engage User Account Name
              custom: {
                engageUserId: `${authState?.authenticatedToken?.userId || 0}`, // Engage User Account Id
                engageOrgId: `${authState?.authenticatedToken.orgId.toString()}`, // Engage User Company Id
                sfdcUserId: '', // Engage User Salesforce Contact Id
                sfdcCompanyId: `${authState?.authenticatedToken?.zoomCustSalesforceId || 0}`, // Engage User Salesforce Company Id
              },
            };
          }
          return userObj;
        }),
        filter((userObj: FeatureFlagUser) => !!userObj),
        distinctUntilChanged(_.isEqual),
      )
      .subscribe((userObj: FeatureFlagUser) => {
        this.featureFlagService.login(userObj);
      });
  }
}
