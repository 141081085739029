import { Invitation } from '@zi-core/data-model/invitation.model';
import { Note } from '@zi-core/data-model/note.model';
import { Task } from '@zi-core/data-model/task.model';
import { ContactSalesWorkflowRest, EventDto } from '@zi-core/http-model/response/contacts.response.model';
import { PhoneCall } from '@zi-core/http-model/response/phone-calls.response.model';
import { Contact } from './contact.model';
import { InvitationEventsSummary } from '@zi-core/data-model/invitation-events-summary';

// contact details
export class ContactDetails {
  contact: ContactExtended;
  tasks: ContactDetailTasks;
  events: ContactDetailEvents;
  invitationEventsSummary: ContactDetailInvitationEventsSummary;
  invitations: ContactDetailInvitations;
  salesflows: ContactDetailSalesflows;
  phoneCalls: ContactDetailPhoneCalls;
  notes: ContactDetailNotes;
}

export class ContactExtended {
  loading: boolean;
  loaded: boolean;
  entity: Contact;
  error: string;
}

export class ContactDetailTasks {
  loading: boolean;
  loaded: boolean;
  entities: Array<Task>;
  error: string;
}

export class ContactDetailEvents {
  loading: boolean;
  loaded: boolean;
  entities: Array<EventDto>;
  error: string;
}

export class ContactDetailInvitationEventsSummary {
  loading: boolean;
  loaded: boolean;
  entity: InvitationEventsSummary;
  error: string;
}

export class ContactDetailInvitations {
  loading: boolean;
  loaded: boolean;
  entities: Array<Invitation>;
  error: string;
}

export class ContactDetailSalesflows {
  loading: boolean;
  loaded: boolean;
  entities: Array<ContactSalesWorkflowRest>;
  error: string;
}

export class ContactDetailPhoneCalls {
  loading: boolean;
  loaded: boolean;
  entities: Array<PhoneCall>;
  error: string;
}

export class ContactDetailNotes {
  loading: boolean;
  loaded: boolean;
  entities: Array<Note>;
  error: string;
}

export enum ContactDetailsTab {
  History = 'history',
  Activity = 'activity',
  Profile = 'profile',
  Salesflows = 'salesflows',
  Tasks = 'tasks',
  Emails = 'emails',
  Calls = 'calls',
}
