export enum countryNames {
  AUSTRALIA = 'Australia',
  CANADA = 'Canada',
  UNITED_KINGDOM = 'United Kingdom',
  USA = 'United States',
}

export enum countryISOCodes {
  AUSTRALIA = 'AU',
  CANADA = 'CA',
  UNITED_KINGDOM = 'GB',
  USA = 'US',
}

export enum countryPhoneCodes {
  AUSTRALIA = '+61',
  CANADA = '+1',
  UNITED_KINGDOM = '+44',
  USA = '+1',
}

export class CountryDetailsObject {
  name: string;
  ISOCode: string;
  phoneCode: string;
}

export const countryDetails: CountryDetailsObject[] = [
  { name: countryNames.AUSTRALIA, ISOCode: countryISOCodes.AUSTRALIA, phoneCode: countryPhoneCodes.AUSTRALIA },
  { name: countryNames.CANADA, ISOCode: countryISOCodes.CANADA, phoneCode: countryPhoneCodes.CANADA },
  { name: countryNames.UNITED_KINGDOM, ISOCode: countryISOCodes.UNITED_KINGDOM, phoneCode: countryPhoneCodes.UNITED_KINGDOM },
  { name: countryNames.USA, ISOCode: countryISOCodes.USA, phoneCode: countryPhoneCodes.USA },
];

/*
DEV NOTES:

While adding a new country:
    1. Update all the above enums and the countriesDetails object.
    2. Rename the svg file (for the flag icon to be displayed in the 'countries' dropdown), before uploading to Google Cloud, as follows:

    "Icon-<CountryCode>-Flag.svg" | Example: "Icon-US-Flag.svg"

    The country code MUST match with the countryISo code enum value.

*/
