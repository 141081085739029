export enum DialerErrorDetails {
  ONGOING_CALL = 'To call another number, please complete your current call and fill out/cancel the call log',
  DIALER_SELECTION_ERROR = 'Engage dialer selection error',
  DEVICE_INITIALIZATION_ERROR = 'Device initialization error',
  PHONENUM_DOES_NOT_EXIST = 'Phone number does not exist',
  SESSION_ACTIVE = 'To call another number current call needs to be completed and logged',
  WAITING_FOR_LOG = 'To call another number, please complete/cancel the call log',
  MIC_DISABLED = 'Mic is disabled',
  BLOCK_LISTED_CONTACT = 'Contact is in block list',
  UNSUBSCRIBED_FROM_CALLS = 'Contact has unsubscribed from Calls',
  DEFAULT_VOIP_ERROR = 'Error performing VoIP action. Please try again.',
  INVALID_NUMBER = 'Invalid Number. Please check your number.',
}

export enum DialerErrorTypes {
  TW_Sharpy = 'tw-sharpy error',
  TWILIO_ERROR = 'twilio error',
}
