import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { TwilioTokenRefreshService } from '@app/caller/service/twilio-token-refresh.service';
import { MessageType } from '@app/common/model/message/message-type';
import { Message } from '@app/common/model/message/message.model';
import { NotyService } from '@app/common/service/noty/noty.service';
import { getExtensionMode } from '@app/extensionMode.config';
import { FeatureFlagService } from '@app/feature-flag/feature-flag.service';
import { Flag } from '@app/feature-flag/types/flag.enum';
import { LoginService } from '@app/login/services/login.service';
import { ApplicationState } from '@app/reducers';
import { environment } from '@env/environment';
import { LoggerServiceToken } from '@zi-core/config/logger-service.config';
import { ILoggerService } from '@zi-core/interface/logger.service.interface';
import { getValidAuthToken, getValidRulesToken } from '@zi-core/ngrx/state/auth.state';
import { NavigationService } from '@zi-core/service/navigation.service';
import { TokenRefreshService } from '../service/token-refresh.service';

@Injectable({
  providedIn: 'root',
})
export class BackendInterceptorService implements HttpInterceptor {
  retryCounter = {};
  private maxRetries = 2;
  private deviceId = localStorage.getItem('deviceId');
  private engageExtBasePath = '/engageext';
  private engageExtFullPath = `${this.engageExtBasePath}?isExt=true`;
  isUmsIntegrationEnabled: boolean = false;

  constructor(
    private store: Store<ApplicationState>,
    private tokenRefreshService: TokenRefreshService,
    private twilioTokenRefreshService: TwilioTokenRefreshService,
    private router: Router,
    private notyService: NotyService,
    private navigationService: NavigationService,
    private loginService: LoginService,
    private cookieService: CookieService,
    private featureFlagService: FeatureFlagService,
    @Inject(LoggerServiceToken) private loggerService: ILoggerService,
  ) {}

  /**
   * Intercept the request sent using HttpClient
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith(environment.rulesServiceUrl)) {
      return this.store.select(getValidRulesToken).pipe(
        map((rulesToken) => {
          return new HttpHeaders({
            Authorization: rulesToken ? 'Bearer ' + rulesToken.token : '',
          });
        }),
        take(1),
        mergeMap((headers) => {
          const transformedRequest = request.clone({
            headers,
            body: request.body,
          });
          return next.handle(transformedRequest);
        }),
        map((response) => {
          return response;
        }),
      );
    }

    if (!this.isBackendRequest(request) && !this.isDialerServiceRequest(request)) {
      return next.handle(request);
    }

    // this condition handles New user password init case, the auth token will already exist in the header and we do not want
    // to read from store the Auth token
    if (request.headers.get('Authorization')) {
      const transformedRequest = request.clone({
        body: this.toPascalCase(request.body),
      });
      return next.handle(transformedRequest).pipe(
        map((response) => {
          if (response instanceof HttpResponse) {
            // Convert the response back from pascal case to camelcase
            return response.clone({ body: this.toCamelCase(response.body) });
          } else {
            return response;
          }
        }),
      );
    }

    // add authenticated token to request headers if it exists
    return this.featureFlagService.observe<Flag.UMS_INTEGRATION>(Flag.UMS_INTEGRATION).pipe(
      map((isUmsIntegrationEnabled) => {
        this.isUmsIntegrationEnabled = isUmsIntegrationEnabled;
        return this.isUmsIntegrationEnabled;
      }),
      mergeMap((isUmsIntegrationEnabled) => {
        return this.store.select(getValidAuthToken);
      }),
      take(1), // take only one value from observable
      map((authToken) => {
        const headerOptions: any = {
          TellwiseAppVersion: environment.tellwise_app_version,
          Authorization: authToken ? 'Token ' + authToken.token : '',
        };

        if (this.deviceId) {
          headerOptions.TellwiseDeviceId = this.deviceId;
        }

        return new HttpHeaders(headerOptions);
      }),
      mergeMap((headers) => {
        // TODO: If we want this concept to work, we need to omit event-heartbeat from this.
        this.tokenRefreshService.setUserToActive();
        const transformedRequest = request.clone({
          headers,
          body: this.toPascalCase(request.body),
        });
        return next.handle(transformedRequest);
      }),
      map((response) => {
        if (response instanceof HttpResponse) {
          // Convert the response back from pascal case to camelcase
          return response.clone({ body: this.toCamelCase(response.body) });
        } else {
          return response;
        }
      }),
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status == 401 && !this.router.url.includes(this.navigationService.getLoginUrl()) && !this.isUmsIntegrationEnabled) {
              // Need to use window.location.href. this.router.url is going to be at root.
              // This will handle issues with session expiration reroutes on extension!
              const isExtRoot = getExtensionMode() && window.location.href && window.location.href.includes(this.engageExtBasePath);
              this.notyService.postMessage(new Message(MessageType.ERROR, `Your session has expired. Please log in again.`));
              this.loggerService.log('Dispatch logout action from BackendInterceptorService');
              this.loginService.generalLogout(isExtRoot ? this.engageExtFullPath : null);
            } else if (err.status == 401 && !this.router.url.includes(this.navigationService.getLoginUrl()) && this.isUmsIntegrationEnabled) {
              const isExtRoot = getExtensionMode() && window.location.href && window.location.href.includes(this.engageExtBasePath);
              this.loggerService.log('Dispatch logout action from BackendInterceptorService');
              this.loginService.generalLogout(isExtRoot ? this.engageExtFullPath : null);
              if (isExtRoot) {
                this.notyService.postMessage(
                  new Message(
                    MessageType.ERROR,
                    `Your session has expired. Please log into your <a href="${environment.zoominfo_login_url}" target="_blank">SalesOS account.</a> Then refresh the Engage Chrome Extension.`,
                  ),
                );
              } else {
                this.navigationService.navigateToDoziLogin();
              }
            }
          }
        },
      ),
    );
  }

  /**
   * Is the request to tellwise backend
   */
  private isBackendRequest(request: HttpRequest<any>): boolean {
    return request.url.startsWith(environment.backend_url);
  }

  /**
   * Is the request to dialer service
   */
  private isDialerServiceRequest(request: HttpRequest<any>): boolean {
    return request.url.startsWith(environment.dialer_url);
  }

  /**
   * convert from camel case to pascal case
   */
  toPascalCase(json: any): any {
    return this.convertCase(json, (key: string) => _.startCase(key).replace(/ /g, ''));
  }

  /**
   * convert from pascal case to camelcase
   */
  public toCamelCase(json: any): any {
    return this.convertCase(json, (key: string) => _.camelCase(key));
  }

  /**
   * generic method to convert from camelcase to pascal and vice versa
   */
  convertCase(json: any, keyMappingFn: (key: string) => string) {
    if (!_.isPlainObject(json) && !_.isArray(json)) {
      return json;
    }
    // if array loop through and convert
    if (_.isArray(json)) {
      return _.map(json, (item) => this.convertCase(item, keyMappingFn));
    }
    // if object
    return _.transform(
      json,
      (result, value, key) => {
        result[keyMappingFn(key.toString())] = this.convertCase(value, keyMappingFn);
      },
      {},
    );
  }
}
