import { IntegrationNamesMap } from '@app/core/enums/integration-names-map.enum';
import * as _ from 'lodash';

export enum IndicationKey {
  CRM = 'crm',
  ZOOMINFO = 'zoominfo',
  ZOOMINFO_COMPANY = 'zoominfo company',
  SALESFORCE = 'salesforce',
  LINKEDIN = 'linkedin',
  LINKEDIN_COMPANY = 'linkedin company',
  TWITTER = 'twitter',
  TWITTER_COMPANY = 'twitter company',
  WEBSITE = 'website',
  TASK = 'task',
  SALESFLOW = 'salesflow',
  TAG = 'tag',
  EMAIL = 'email',
  CALL = 'call',
  HUBSPOT = 'hubspot',
  DYNAMICS = 'dynamics',
  UNIESALESFORCE = 'uniesalesforce',
}

export interface IndicationItem {
  key: IndicationKey;
  icon: string;
  tooltip: string;
  disabledTooltip?: string;
  active: boolean;
  disabled: boolean;
  // Used to determine if we want to disable the click event when item is inactive.
  // Some indications will trigger a click event also when inactive.
  disabledClick: boolean;
  unsubscribed: boolean;
}

/** Maps indication names to indications */
const indicationsByKey = {};

const ZOOMINFO: IndicationItem = {
  key: IndicationKey.ZOOMINFO,
  icon: 'icon-zoominfo',
  tooltip: 'SalesOS',
  active: false,
  disabled: false,
  disabledClick: false,
  unsubscribed: false,
};
indicationsByKey[IndicationKey.ZOOMINFO] = ZOOMINFO;

const ZOOMINFO_COMPANY: IndicationItem = {
  key: IndicationKey.ZOOMINFO_COMPANY,
  icon: 'icon-zoominfo',
  tooltip: 'SalesOS',
  active: false,
  disabled: false,
  disabledClick: false,
  unsubscribed: false,
};
indicationsByKey[IndicationKey.ZOOMINFO_COMPANY] = ZOOMINFO_COMPANY;

const SALESFORCE: IndicationItem = {
  key: IndicationKey.SALESFORCE,
  icon: 'icon-salesforce',
  tooltip: 'Salesforce',
  active: false,
  disabled: false,
  disabledClick: false,
  unsubscribed: false,
};
indicationsByKey[IndicationKey.SALESFORCE] = SALESFORCE;

const UNIESALESFORCE: IndicationItem = {
  key: IndicationKey.UNIESALESFORCE,
  icon: 'icon-salesforce',
  tooltip: 'Salesforce',
  active: false,
  disabled: false,
  disabledClick: false,
  unsubscribed: false,
};
indicationsByKey[IndicationKey.UNIESALESFORCE] = UNIESALESFORCE;

const DYNAMICS: IndicationItem = {
  key: IndicationKey.DYNAMICS,
  icon: 'icon-Dynamics_logo_24',
  tooltip: 'Dynamics',
  active: false,
  disabled: false,
  disabledClick: false,
  unsubscribed: false,
};
indicationsByKey[IndicationKey.DYNAMICS] = DYNAMICS;

const HUBSPOT: IndicationItem = {
  key: IndicationKey.HUBSPOT,
  icon: 'icon-hubspot',
  tooltip: 'HubSpot',
  active: false,
  disabled: false,
  disabledClick: false,
  unsubscribed: false,
};
indicationsByKey[IndicationKey.HUBSPOT] = HUBSPOT;

const LINKEDIN: IndicationItem = {
  key: IndicationKey.LINKEDIN,
  icon: 'icon-linkedin',
  tooltip: 'LinkedIn',
  active: false,
  disabled: false,
  disabledClick: false,
  unsubscribed: false,
};
indicationsByKey[IndicationKey.LINKEDIN] = LINKEDIN;

const LINKEDIN_COMPANY: IndicationItem = {
  key: IndicationKey.LINKEDIN_COMPANY,
  icon: 'icon-linkedin',
  tooltip: 'LinkedIn',
  active: false,
  disabled: false,
  disabledClick: false,
  unsubscribed: false,
};
indicationsByKey[IndicationKey.LINKEDIN_COMPANY] = LINKEDIN_COMPANY;

export const TASK: IndicationItem = {
  key: IndicationKey.TASK,
  icon: 'icon-task1',
  tooltip: 'Task',
  active: false,
  disabled: false,
  disabledClick: false,
  unsubscribed: false,
};
indicationsByKey[IndicationKey.TASK] = TASK;

export const SALESFLOW: IndicationItem = {
  key: IndicationKey.SALESFLOW,
  icon: 'icon-salesflow',
  tooltip: 'Salesflow',
  active: false,
  disabled: false,
  disabledClick: false,
  unsubscribed: false,
};
indicationsByKey[IndicationKey.SALESFLOW] = SALESFLOW;

const TAG: IndicationItem = {
  key: IndicationKey.TAG,
  icon: 'icon-tag',
  tooltip: 'No Tags',
  active: false,
  disabled: true,
  disabledClick: false,
  unsubscribed: false,
};
indicationsByKey[IndicationKey.TAG] = TAG;

export const EMAIL: IndicationItem = {
  key: IndicationKey.EMAIL,
  icon: 'icon-email',
  tooltip: 'Email',
  active: false,
  disabled: false,
  disabledClick: false,
  unsubscribed: false,
};
indicationsByKey[IndicationKey.EMAIL] = EMAIL;

export const CALL: IndicationItem = {
  key: IndicationKey.CALL,
  icon: 'icon-call',
  tooltip: 'Call',
  disabledTooltip: 'Cannot navigate to Engage Mode if selection count is less than 2',
  active: false,
  disabled: false,
  disabledClick: false,
  unsubscribed: false,
};
indicationsByKey[IndicationKey.CALL] = CALL;

const TWITTER: IndicationItem = {
  key: IndicationKey.TWITTER,
  icon: 'icon-twitter',
  tooltip: 'Twitter',
  active: false,
  disabled: false,
  disabledClick: false,
  unsubscribed: false,
};
indicationsByKey[IndicationKey.TWITTER] = TWITTER;

const TWITTER_COMPANY: IndicationItem = {
  key: IndicationKey.TWITTER_COMPANY,
  icon: 'icon-twitter',
  tooltip: 'Twitter',
  active: false,
  disabled: false,
  disabledClick: false,
  unsubscribed: false,
};
indicationsByKey[IndicationKey.TWITTER_COMPANY] = TWITTER_COMPANY;

const WEBSITE: IndicationItem = {
  key: IndicationKey.WEBSITE,
  icon: 'icon-website',
  tooltip: 'Website',
  active: false,
  disabled: false,
  disabledClick: false,
  unsubscribed: false,
};
indicationsByKey[IndicationKey.WEBSITE] = WEBSITE;

const CRMS = [SALESFORCE, HUBSPOT, DYNAMICS];
const CRMSV2 = [UNIESALESFORCE, HUBSPOT, DYNAMICS];

/**
 * Finds indications by name/key
 * @param crmName The name of the CRM to use
 * @param indicationNames The names of indications to find
 * @returns A list of matching indications
 */
export const findIndications = (crmName: string, indicationNames: string[], isUnieAuth: boolean = false): IndicationItem[] => {
  const indications: IndicationItem[] = [];
  let indication: IndicationItem;

  indicationNames.forEach((name) => {
    if (name === IndicationKey.CRM) {
      indication =
        isUnieAuth && crmName?.toLowerCase() === IntegrationNamesMap.UnieSalesforce
          ? CRMSV2.find((crm) => crm.key === crmName?.toLowerCase())
          : CRMS.find((crm) => crm.key === crmName?.toLowerCase());
    } else {
      indication = indicationsByKey[name];
    }

    if (indication) {
      indications.push(indication);
    }
  });

  return indications;
};

/** DEPRECATED Please use findIndications() instead */
export const indicationsConfig = (crmName: string, isUnieAuth: boolean = false) => {
  let CRM;
  if (isUnieAuth && crmName?.toLowerCase() === IntegrationNamesMap.UnieSalesforce) {
    CRM = CRMSV2.find((crm) => crm.key === (crmName ? crmName.toLowerCase() : crmName));
  } else {
    CRM = CRMS.find((crm) => crm.key === (crmName ? crmName.toLowerCase() : crmName));
  }

  return {
    contact: _.compact([ZOOMINFO, CRM, LINKEDIN, TASK, SALESFLOW]),
    task: _.compact([EMAIL, CALL, CRM, ZOOMINFO, LINKEDIN, TAG]),
    profilePageContact: _.compact([ZOOMINFO, CRM, LINKEDIN, TWITTER, TAG]),
    profilePageCompany: _.compact([ZOOMINFO_COMPANY, CRM, LINKEDIN_COMPANY, TWITTER_COMPANY, WEBSITE]),
    companyAccount: _.compact([ZOOMINFO_COMPANY, CRM, LINKEDIN_COMPANY]),
  };
};

export const crmActivitySyncConfigs = {
  DEFAULT_CALL_SUBJECT: '--',
};

export const SALESFORCE_CONTACT: IndicationItem = {
  key: IndicationKey.SALESFORCE,
  icon: 'icon-sync_contact',
  tooltip: 'Salesforce Contact',
  active: true,
  disabled: false,
  disabledClick: false,
  unsubscribed: false,
};

export const SALESFORCE_LEAD: IndicationItem = {
  key: IndicationKey.SALESFORCE,
  icon: 'icon-sync_lead',
  tooltip: 'Salesforce Lead',
  active: true,
  disabled: false,
  disabledClick: false,
  unsubscribed: false,
};

export const SALESFORCE_ACCOUNT: IndicationItem = {
  key: IndicationKey.SALESFORCE,
  icon: 'icon-sync_account',
  tooltip: 'Salesforce Account',
  active: true,
  disabled: false,
  disabledClick: false,
  unsubscribed: false,
};

export const DYNAMICS_CONTACT: IndicationItem = {
  key: IndicationKey.DYNAMICS,
  icon: 'icon-Dynamics_contact_24',
  tooltip: 'Dynamics Contact',
  active: true,
  disabled: false,
  disabledClick: false,
  unsubscribed: false,
};

export const DYNAMICS_LEAD: IndicationItem = {
  key: IndicationKey.DYNAMICS,
  icon: 'icon-Dynamics_lead_24',
  tooltip: 'Dynamics Lead',
  active: true,
  disabled: false,
  disabledClick: false,
  unsubscribed: false,
};

export const DYNAMICS_ACCOUNT: IndicationItem = {
  key: IndicationKey.DYNAMICS,
  icon: 'icon-Dynamics_account_24',
  tooltip: 'Dynamics Account',
  active: true,
  disabled: false,
  disabledClick: false,
  unsubscribed: false,
};
