export enum IntegrationNamesMap {
  Salesforce = 'salesforce',
  Gmail = 'gmail',
  Office365 = 'office365',
  Exchange = 'exchange',
  DiscoverOrg = 'discoverOrg',
  Hubspot = 'hubspot',
  Dynamics = 'dynamics',
  UnieSalesforce = 'uniesalesforce',
  UnieHubspot = 'uniehubspot',
  UnieDynamics = 'uniedynamics',
}
