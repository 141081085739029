import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ApplicationState } from '@app/reducers';
import { Store } from '@ngrx/store';
import { GetUserZoominfoDetailsAction } from '@zi-core/ngrx/action/auth.action';
import { DoziIntegrationService } from '@app/core/service/dozi-integration.service';
import { NotyService } from '@app/common/service/noty/noty.service';
import { getExclusiveUserRole } from '@zi-core/ngrx/state/auth.state';
import { take } from 'rxjs/operators';
import { EngageVersionEnum } from '@zi-core/enums/engage-version.enum';
import { UtilitiesService } from '@zi-common/service/utilities-service/utilities-service';
import { doziAccessTokenCookieName } from '@app/login/login.config';
import { CookieService } from 'ngx-cookie-service';
import { DoziAccountDialogComponent } from '@zi-common/pages-component/dozi-account-dialog/dozi-account-dialog.component';
import { DoziStep } from '@zi-core/data-model/dozi-integration/enums/dozi-steps.model';

@Component({
  selector: 'app-dozi-okta-integration-dialog',
  templateUrl: './dozi-okta-integration-dialog.component.html',
  styleUrls: ['./dozi-okta-integration-dialog.component.scss'],
})
export class DoziOktaIntegrationDialogComponent implements OnInit {
  errorMessage: string;
  engageVersionText: string;
  redirectUrl: string;
  doziUsername: string;
  doziTitle: string;

  constructor(
    private dialogRef: MatDialogRef<DoziOktaIntegrationDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    private doziService: DoziIntegrationService,
    private notyService: NotyService,
    private store: Store<ApplicationState>,
    private cookieService: CookieService,
    private utilitiesService: UtilitiesService,
  ) {}

  ngOnInit() {
    this.store
      .select(getExclusiveUserRole)
      .pipe(take(1))
      .subscribe((result) => {
        if (result === EngageVersionEnum.SALES) {
          this.engageVersionText = 'Sales';
        } else if (result === EngageVersionEnum.RECRUITER) {
          this.engageVersionText = 'Talent';
        }
        this.doziTitle = `Log In to ${this.engageVersionText}OS`;
      });
    this.redirectUrl = this.data.redirectUrl;
    this.doziUsername = this.utilitiesService.decodeJWT(this.cookieService.get(doziAccessTokenCookieName))?.ziUsername;
  }

  onCloseDialog(status?: string) {
    this.dialogRef.close(status);
  }

  oktaLogin() {
    this.doziService.loginSSO().subscribe(
      (res) => {
        this.errorMessage = '';
        this.store.dispatch(GetUserZoominfoDetailsAction());
        this.onCloseDialog(DoziStep.success);
      },
      (error) => {
        this.errorMessage = 'Error signing in.';
      },
    );
  }

  checkTrial() {
    return this.dialog.open(DoziAccountDialogComponent, {
      width: '648px',
      height: '667px',
      data: {},
    });
  }
}
