import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NotyService } from '@app/common/service/noty/noty.service';
import { Message } from '@app/common/model/message/message.model';
import { MessageType } from '@app/common/model/message/message-type';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-noty',
  templateUrl: './noty.component.html',
  styleUrls: ['./noty.component.scss'],
})
export class NotyComponent implements OnInit {
  private classesMap;
  messages$: Observable<Message[]>;
  MessageType = MessageType;

  constructor(private notyService: NotyService) {}

  ngOnInit() {
    this.initClassMapObj();
    this.messages$ = this.notyService.messageAdded.pipe(
      map((messages: Message[]) => {
        return messages.map((message) => {
          message.styleClass = this.getMessageProperties(message.type).styleClass;
          message.imgSrc = this.getMessageProperties(message.type).imageSrc;
          return message;
        });
      }),
    );
  }

  /**
   * Setup the "classesMap" object to store all message properties (Style Class, Image source)
   * Invoked on the Init of the noty.component.ts
   */
  initClassMapObj() {
    this.classesMap = {};
    this.classesMap[MessageType.DEFAULT] = { styleClass: 'default', imageSrc: '' };
    this.classesMap[MessageType.ERROR] = { styleClass: 'error', imageSrc: environment.icons_url + '/error.svg' };
    this.classesMap[MessageType.INFO] = { styleClass: 'info', imageSrc: environment.icons_url + '/noty_info_icon.svg' };
    this.classesMap[MessageType.WARNING] = { styleClass: 'warning', imageSrc: environment.icons_url + '/noty_warning_icon.svg' };
    this.classesMap[MessageType.SUCCESS] = { styleClass: 'success', imageSrc: environment.icons_url + '/noty_success.svg' };
  }

  /**
   * Extract the message's properties to build the relevant message (Class, Image source).
   * Invoked for every creation of a message on the noty.component.html
   * @param type {MessageType} The type of the message (ERROR, SUCCESS, INFO, WARNING, DEFAULT)
   * @returns Message properties from classes map object {object}
   */
  getMessageProperties(type: MessageType) {
    return this.classesMap[type] || this.classesMap[MessageType.DEFAULT];
  }

  /**
   * Deletes the current message.
   */
  deleteMessage(messageId: string) {
    this.notyService.removeMessage(messageId);
  }
}
