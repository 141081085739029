export enum FilterCategory {
  ContactFields = 'ContactFields',
  Tags = 'Tags',
  Salesflow = 'Salesflow',
  QuickFilters = 'QuickFilters',
  MessageFields = 'MessageFields',
  CompanyAccountFields = 'CompanyAccountFields',
}
export enum FilterType {
  Leaf = 1,
  Node = 2,
}

export enum FilterEntity {
  Contact = 'Contact',
  Task = 'Task',
  Salesflow = 'Salesflow',
  MessageTemplate = 'MessageTemplate',
  Message = 'Message',
  CompanyAccount = 'CompanyAccount',
}

export enum Scope {
  None = 'None', // currently not used
  Owner = 'Owner',
  Team = 'Team', // currently not used
  Organization = 'Organization',
}

export enum FilterSort {
  None = 'None',
  Name = 'Name',
  Company = 'Company',
  JobTitle = 'JobTitle',
  Department = 'Department',
  Email = 'Email',
  Phone = 'Phone',
  LastEmail = 'LastEmail',
  LastCall = 'LastCall',
  NumberOfEmails = 'NumberOfEmails',
  NumberOfCalls = 'NumberOfCalls',
  LastAdded = 'LastAdded',
  LastUpdated = 'LastUpdated',
  TaskDueTime = 'DueAt',
  FirstName = 'FirstName',
  LocalTime = 'LocalTime',
  CreatedAt = 'CreatedAt',
  LastTouched = 'LastTouched',
  NumberOfContacts = 'NumberOfContacts',
  Salesflow = 'Salesflow',
  SalesflowStep = 'SalesflowStep',
}

export enum FilterSortOrder {
  None = 'None',
  Ascending = 'Ascending',
  Descending = 'Descending',
  Oldest = 'Oldest',
  Newest = 'Newest',
}

export enum FilterMultiSortOrder {
  Ascending = 0,
  Descending = 1,
}

export enum ScopeNumber {
  None = 0,
  Owner = 1,
  Team = 2,
  Organization = 3,
}

export enum Level {
  Organization,
  User,
  Team,
}

export enum Selection {
  All = 'All',
  Clear = 'Clear',
}

export enum PageSelection {
  All = 'Select All',
  Page = 'Select Page',
  ClearPage = 'Clear Page',
  ClearAll = 'Clear All',
  Quantity = 'Quantity',
}

export enum ImportHistory {
  Name = 'importName',
  Date = 'createdAt',
  NumberOfContacts = 'contactCount',
  ImportSource = 'source',
  NextSync = 'NextSync',
  ImportOwner = 'userId',
}

export enum SyncSettings {
  NoSync = 'Name',
  Daily = 'Date',
  Weekly = 'NumberOfContacts',
  Monthly = 'ImportSource',
}
