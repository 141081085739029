import { Action, createReducer, on } from '@ngrx/store';
import { initialLabelsState, LabelResourceTypeState, LabelsState } from '@zi-core/ngrx/state/labels.state';
import {
  CreateLabelSuccessAction,
  DeleteLabelAction,
  DeleteLabelFailureAction,
  DeleteLabelSuccessAction,
  DeleteUnusedTagsAction,
  DeleteUnusedTagsFailureAction,
  EditLabelSuccessAction,
  LoadLabelsByResourceTypeAction,
  LoadLabelsByResourceTypeSuccessAction,
  SetShowUnusedTagsOnlyAction,
  SetSortDirectionAction,
  SetSortFieldAction,
  ToggleShowUnusedTagsOnlyAction,
} from '@zi-core/ngrx/action/labels.action';
import { LabelBucketDto, LabelCountBreakdown, LabelItem } from '@zi-core/http-model/response/labels.response.model';
import { ClearGlobalSearch, SetGlobalSearch } from '@zi-pages/ngrx/action/global.action';
import { LabelResourceType, ResourceType } from '@zi-core/enums/resource-type.enum';
import * as get from 'lodash.get';

const labelsReducer = createReducer(
  initialLabelsState,
  on(LoadLabelsByResourceTypeAction, (state: LabelsState, { resourceType }) => {
    const prevResourceTypeEntity: LabelResourceTypeState = { ...state[resourceType] };
    const newResourceTypeEntity: LabelResourceTypeState = { ...prevResourceTypeEntity, loaded: false, loading: true };
    return { ...state, [resourceType]: newResourceTypeEntity };
  }),

  on(LoadLabelsByResourceTypeSuccessAction, (state: LabelsState, { labelBuckets, resourceType }) => {
    const newEntities = {};
    labelBuckets.forEach((label: LabelBucketDto) => {
      if (newEntities[label.id]) {
        const lblChg = { ...newEntities[label.id] };
        lblChg.count += label.count;
        lblChg.countBreakdown = updateCountBreakdown(label, newEntities[label.id].countBreakdown);
        newEntities[label.id] = lblChg;
      } else {
        const newLbl: LabelItem = { ...label, countBreakdown: updateCountBreakdown(label) };
        newEntities[label.id] = newLbl;
      }
    });
    const newResourceTypeEntity: LabelResourceTypeState = { loaded: true, loading: false, entities: newEntities };
    return { ...state, [resourceType]: newResourceTypeEntity };
  }),

  on(CreateLabelSuccessAction, (state: LabelsState, { label, resourceType }) => {
    const newEntities = { ...state[resourceType].entities };
    const newLabel: LabelItem = { ...label, count: 0, countBreakdown: {}, resourceType: 0 };
    if (resourceType === LabelResourceType.Contact) {
      newLabel.countBreakdown.contactsCount = 0;
      newLabel.resourceType = ResourceType.BasicContact;
    } else if (
      resourceType === LabelResourceType.Content ||
      resourceType === LabelResourceType.SalesflowTemplate ||
      resourceType === LabelResourceType.EmailTemplate
    ) {
      newLabel.countBreakdown.salesflowCount = 0;
      newLabel.countBreakdown.emailTemplateCount = 0;
      newLabel.resourceType = resourceType === LabelResourceType.SalesflowTemplate ? ResourceType.SalesflowTemplate : ResourceType.EmailTemplate;
    }

    newEntities[label.id] = newLabel;
    return { ...state, [resourceType]: { ...[resourceType], entities: newEntities } };
  }),

  on(EditLabelSuccessAction, (state: LabelsState, { resourceType, label }) => {
    const newEntities = { ...state[resourceType].entities };
    newEntities[label.id] = label;
    return { ...state, [resourceType]: { ...[resourceType], entities: newEntities } };
  }),

  on(DeleteUnusedTagsAction, (state: LabelsState, { resourceType }) => {
    const prevResourceTypeEntity: LabelResourceTypeState = { ...state[resourceType] };
    const newResourceTypeEntity: LabelResourceTypeState = { ...prevResourceTypeEntity, loaded: false, loading: true };
    return { ...state, [resourceType]: newResourceTypeEntity };
  }),

  on(DeleteUnusedTagsFailureAction, (state: LabelsState, { resourceType }) => {
    const prevResourceTypeEntity: LabelResourceTypeState = { ...state[resourceType] };
    const newResourceTypeEntity: LabelResourceTypeState = { ...prevResourceTypeEntity, loaded: true, loading: false };
    return { ...state, [resourceType]: newResourceTypeEntity };
  }),

  on(DeleteLabelAction, (state: LabelsState, { label, resourceType }) => {
    const prevResourceTypeEntity: LabelResourceTypeState = { ...state[resourceType] };
    const newResourceTypeEntity: LabelResourceTypeState = { ...prevResourceTypeEntity, loaded: false, loading: true };
    return { ...state, [resourceType]: newResourceTypeEntity };
  }),

  on(DeleteLabelSuccessAction, (state: LabelsState, { label, resourceType }) => {
    const newEntities = { ...state[resourceType].entities };
    if (newEntities[label.id]) {
      delete newEntities[label.id];
    }
    return { ...state, [resourceType]: { ...[resourceType], entities: newEntities } };
  }),

  on(DeleteLabelFailureAction, (state: LabelsState, { label, resourceType }) => {
    const prevResourceTypeEntity: LabelResourceTypeState = { ...state[resourceType] };
    const newResourceTypeEntity: LabelResourceTypeState = { ...prevResourceTypeEntity, loaded: true, loading: false };
    return { ...state, [resourceType]: newResourceTypeEntity };
  }),

  on(SetGlobalSearch, (state: LabelsState, { module, search }) => {
    if (get(state, 'filter.search', search) === search || module !== 'TAGS') {
      return state;
    }
    return {
      ...state,
      filter: {
        ...state.filter,
        search,
      },
    };
  }),

  on(ClearGlobalSearch, (state: LabelsState, { module }) => {
    if (get(state, 'filter.search', '') === '' || module !== 'TAGS') {
      return state;
    }
    return {
      ...state,
      filter: {
        ...state.filter,
        search: '',
      },
    };
  }),

  on(SetSortFieldAction, (state: LabelsState, { field }) => {
    return {
      ...state,
      filter: {
        ...state.filter,
        sort: {
          ...state.filter.sort,
          field,
        },
      },
    };
  }),

  on(SetSortDirectionAction, (state: LabelsState, { order }) => {
    return {
      ...state,
      filter: {
        ...state.filter,
        sort: {
          ...state.filter.sort,
          order,
        },
      },
    };
  }),

  on(SetShowUnusedTagsOnlyAction, (state: LabelsState, { showUnusedTagsOnly }) => {
    return {
      ...state,
      filter: {
        ...state.filter,
        showUnusedTagsOnly,
      },
    };
  }),

  on(ToggleShowUnusedTagsOnlyAction, (state: LabelsState) => {
    const newShowUnusedTagsOnly = !state.filter.showUnusedTagsOnly;
    return {
      ...state,
      filter: {
        ...state.filter,
        showUnusedTagsOnly: newShowUnusedTagsOnly,
      },
    };
  }),
);

function updateCountBreakdown(label: LabelBucketDto, countBreakdown: LabelCountBreakdown = {}) {
  const newCountBkdwn = { ...countBreakdown };
  switch (label.resourceType) {
    case ResourceType.BasicContact:
      newCountBkdwn.contactsCount = label.count;
      break;
    case ResourceType.EmailTemplate:
      newCountBkdwn.emailTemplateCount = label.count;
      break;
    case ResourceType.SalesflowTemplate:
      newCountBkdwn.salesflowCount = label.count;
      break;
    default:
      break;
  }
  return newCountBkdwn;
}

export function LabelsReducer(state: LabelsState, action: Action) {
  return labelsReducer(state, action);
}
