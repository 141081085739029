import { Injectable } from '@angular/core';
import { countryDetails } from '../constant/country-iso-codes';
import { CountryDetailsObject } from '../constant/country-iso-codes';

@Injectable()
export class CountryCodesService {
  private readonly countryDetails: CountryDetailsObject[] = [];

  constructor() {
    this.countryDetails = countryDetails;
  }

  /**
   * @returns An Array of objects containing names, ISO codes and phone codes for the available countries
   *  */
  public getCountryDetails(): CountryDetailsObject[] {
    return this.countryDetails;
  }
}
