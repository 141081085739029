import { Inject, Injectable } from '@angular/core';
import { UrlService } from '@zi-core/service/url.service';
import { ExtensionSource } from '@app/extension/constants/extension-source.enum';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import {
  DoziContactsParams,
  DynamicsContactParams,
  ParsedContactIds,
  ParsedContactIdsInfo,
  SalesforceCasesParams,
  SalesforceContactParams,
} from '@app/extension/model/parsed-contact-info.model';
import { catchError, flatMap, map, take } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { ExtensionRedirectionService } from '@app/extension/service/extension-redirection.service';
import { ExtSetParsedContactInfoFailureAction } from '@app/extension/ngrx/action/extension.action';
import { Contact } from '@zi-core/data-model/contact.model';
import { ApplicationState } from '@app/reducers';
import { ImportContactResponse } from '@zi-core/http-model/response/contacts.response.model';
import { ContactSource } from '@zi-core/enums/contact-source.enum';
import { DoziIntegrationService } from '@app/core/service/dozi-integration.service';
import { PersonExportRequest } from '@app/core/data-model/dozi-integration/person-export-request.model';
import { NotyService } from '@app/common/service/noty/noty.service';
import { MessageType } from '@app/common/model/message/message-type';
import { Message } from '@app/common/model/message/message.model';
import { DoziErrorCode, ExtensionErrors } from '../constants/extension-messages.enum';
import { EXTENSION_NAVIGATION_URLS, ExtensionNavigationPageNames } from '../constants/redirection-page-names.constant';
import { ImportDuplicateSettings } from '@app/core/enums/import-list.enum';
import { ContactV2UpsertRequest } from '@app/core/http-model/request/contacts.request.model';
import { LoggerServiceToken } from '@zi-core/config/logger-service.config';
import { ILoggerService } from '@zi-core/interface/logger.service.interface';

@Injectable({
  providedIn: 'root',
})
export class ExtensionMatcherService {
  readonly accountSettingsUrl = `${environment.app_domain}/#/${EXTENSION_NAVIGATION_URLS[ExtensionNavigationPageNames.INTEGRATION][0]}`;
  isDoziConnected: boolean = false;

  constructor(
    private urlService: UrlService,
    private extensionRedirectionService: ExtensionRedirectionService,
    private _appStore: Store<ApplicationState>,
    private _httpClient: HttpClient,
    private doziService: DoziIntegrationService,
    private notyService: NotyService,
    @Inject(LoggerServiceToken) private loggerService: ILoggerService,
  ) {}

  public getEntitiesByIds(encodedParsedInfo: string): Observable<Contact[]> {
    if (!_.isEmpty(encodedParsedInfo)) {
      const decodedParsedInfo: ParsedContactIdsInfo = this.urlService.decodeFromBase64(encodedParsedInfo);
      this.loggerService.log('decodedParsedInfo is :: ', decodedParsedInfo);
      if (_.isEmpty(decodedParsedInfo) || _.get(decodedParsedInfo, 'idInfo.origAllIds', []).length === 0) {
        return of([]);
      }

      const { source } = decodedParsedInfo;
      let getEntities;
      switch (source) {
        case ExtensionSource.SALESFORCE: {
          getEntities = this.getSFEntitiesByIds(decodedParsedInfo.idInfo);
          break;
        }
        case ExtensionSource.DYNAMICS: {
          getEntities = this.getDynamicsEntitiesByIds(decodedParsedInfo.idInfo);
          break;
        }
        case ExtensionSource.DOZI: {
          getEntities = this.getDoziEntitiesByIds(decodedParsedInfo.idInfo);
          break;
        }
        default: {
          this.loggerService.error('Source :: ' + source + ' not supported');
        }
      }

      if (getEntities != null) {
        return getEntities.pipe(
          take(1),
          flatMap((results: { contacts: any[] }) => {
            const finalResults = { contacts: results.contacts.map((el) => ({ ...el, zoomId: el.zoomId })) };
            return this.resolveTellwiseContacts(finalResults.contacts);
          }),
        );
      } else {
        return of([]);
      }
    }
  }

  private getSFEntitiesByIds(decodedParsedIds: ParsedContactIds) {
    if (decodedParsedIds.CaseId) {
      return this._httpClient.get(`${environment.backend_url}/v1/crm/salesforce/case/${decodedParsedIds.CaseId}`).pipe(
        take(1),
        catchError((error) => {
          return this.handleCrmRequestError(error, ExtensionSource.SALESFORCE);
        }),
      );
    } else if (decodedParsedIds.CaseIds) {
      const params: SalesforceCasesParams = {
        CaseIds: decodedParsedIds.CaseIds,
      };
      return this._httpClient.post(`${environment.backend_url}/v1/crm/salesforce/cases`, { ...params }).pipe(
        take(1),
        catchError((error) => {
          return this.handleCrmRequestError(error, ExtensionSource.SALESFORCE);
        }),
      );
    } else {
      const params: SalesforceContactParams = {
        LeadIds: decodedParsedIds.LeadIds || [],
        ContactIds: decodedParsedIds.ContactIds || [],
        PhoneCallIds: decodedParsedIds.PhoneCallIds || [],
        EventIds: decodedParsedIds.EventIds || [],
        ActivityIds: decodedParsedIds.ActivityIds || [],
      };
      return this._httpClient.post(`${environment.backend_url}/v1/crm/salesforce/contacts/list2`, { ...params }).pipe(
        take(1),
        catchError((error) => {
          return this.handleCrmRequestError(error, ExtensionSource.SALESFORCE);
        }),
      );
    }
  }

  private getDynamicsEntitiesByIds(decodedParsedIds: ParsedContactIds) {
    if (decodedParsedIds?.ContactIds || decodedParsedIds?.LeadIds || decodedParsedIds?.ActivityIds) {
      const params: DynamicsContactParams = {
        LeadIds: decodedParsedIds.LeadIds || [],
        ContactIds: decodedParsedIds.ContactIds || [],
        ActivityIds: decodedParsedIds.ActivityIds || [],
      };

      return this._httpClient.post(`${environment.backend_url}/v1/crm/dynamics/contacts/list2`, { ...params }).pipe(
        take(1),
        catchError((error) => {
          return this.handleCrmRequestError(error, ExtensionSource.DYNAMICS);
        }),
      );
    } else if (decodedParsedIds?.OpportunityIds && decodedParsedIds?.OpportunityIds?.length > 0) {
      return this._httpClient.get(`${environment.backend_url}/v1/crm/opportunity/${decodedParsedIds.OpportunityIds[0]}`).pipe(
        take(1),
        catchError((error) => {
          return this.handleCrmRequestError(error, ExtensionSource.DYNAMICS);
        }),
      );
    } else if (decodedParsedIds?.AccountIds && decodedParsedIds?.AccountIds?.length > 0) {
      return this._httpClient.get(`${environment.backend_url}/v1/crm/account/${decodedParsedIds.AccountIds[0]}`).pipe(
        take(1),
        catchError((error) => {
          return this.handleCrmRequestError(error, ExtensionSource.DYNAMICS);
        }),
      );
    } else if (decodedParsedIds?.View) {
      const loadContents = true;
      return this._httpClient
        .get(`${environment.backend_url}/v1/crm/listview/${decodedParsedIds.View.EntityType}/${decodedParsedIds.View.Id}/${loadContents}/500/1`)
        .pipe(
          take(1),
          catchError((error) => {
            return this.handleCrmRequestError(error, ExtensionSource.DYNAMICS);
          }),
        );
    }
  }

  private getDoziEntitiesByIds(decodedParsedIds: ParsedContactIds) {
    this.loggerService.log('decodedParsedIds in getDoziEntitiesByIds are :: ', decodedParsedIds);
    if (!decodedParsedIds || _.isEmpty(decodedParsedIds.DoziIds)) {
      return of([]);
    }
    const params: DoziContactsParams = {
      Ids: decodedParsedIds.DoziIds,
    };
    return this._httpClient.post(`${environment.backend_url}/v1/dozi/contacts/list`, { ...params }).pipe(
      take(1),
      map((response) => {
        const contacts = _.get(response, 'contacts');
        if (!response || _.isEmpty(contacts)) {
          return { contacts: [] };
        } else {
          return { contacts };
        }
        // return _.get(response, 'contacts', { contacts: [] });
      }),
      catchError((error) => {
        this.loggerService.error('error happened :: ', error);
        this._appStore.dispatch(ExtSetParsedContactInfoFailureAction({ error: { error, source: ExtensionSource.DOZI } }));
        return throwError(error);
      }),
    );
  }

  private resolveTellwiseContacts(parsedContacts): Observable<Contact[]> {
    this.loggerService.log('in resolveTellwiseContacts :: contacts are :: ', parsedContacts);
    return this._httpClient.post(`${environment.backend_url}/v1/contacts/resolve`, { Contacts: parsedContacts }).pipe(
      take(1),
      catchError((err: any) => {
        this.loggerService.error('error happened :: ', err);
        this._appStore.dispatch(ExtSetParsedContactInfoFailureAction({ error: { err } }));
        return throwError(err);
      }),
      map((resolveResults: { contacts: Contact[] }) => {
        const resolvedContacts: Contact[] = resolveResults.contacts;
        this.loggerService.log('resolved contacts :: ', resolvedContacts);
        return resolvedContacts.map((resolvedContact: Contact, index) => {
          if (!resolvedContact || !resolvedContact.id) {
            return _.cloneDeep(parsedContacts[index]);
          }

          const contact = parsedContacts[index] || {};
          const statsOnlyContact = {
            id: resolvedContact.id,
            totalNumberOfEmails: resolvedContact.totalNumberOfEmails,
            totalNumberOfCalls: resolvedContact.totalNumberOfCalls,
            totalNumberOfIncompleteTasks: resolvedContact.totalNumberOfIncompleteTasks,
            lastOpenedAt: resolvedContact.lastOpenedAt,
            lastCalledAt: resolvedContact.lastCalledAt,
            lastRepliedAt: resolvedContact.lastRepliedAt,
            lastContentViewedAt: resolvedContact.lastContentViewedAt,
            lastEmailedAt: resolvedContact.lastEmailedAt,
            lastTextedAt: resolvedContact.lastTextedAt,
            lastLinkClickedAt: resolvedContact.lastLinkClickedAt,
            idProvider: resolvedContact.idProvider,
            externalId: resolvedContact.externalId || contact.externalId,
            doNotEmail: resolvedContact.doNotEmail || contact.doNotEmail,
            doNotCall: resolvedContact.doNotCall || contact.doNotCall,
            type: resolvedContact.type,
            source: resolvedContact.source,
            crmInfo: resolvedContact.crmInfo,
            ownerId: resolvedContact.ownerId,
            orgId: resolvedContact.orgId,
            phoneStatus: resolvedContact.phoneStatus,
            mobilePhoneStatus: resolvedContact.mobilePhoneStatus,
            otherPhoneStatus: resolvedContact.otherPhoneStatus,
            homePhoneStatus: resolvedContact.homePhoneStatus,
            assistantPhoneStatus: resolvedContact.assistantPhoneStatus,
            hqPhoneStatus: resolvedContact.hqPhoneStatus,
          };
          return _.extend({}, parsedContacts[index], statsOnlyContact);
        });
      }),
    );
  }

  private handleCrmRequestError(error, source: ExtensionSource) {
    if (_.get(error, 'error.ResponseStatus')) {
      const errorResponseStatus = error.error.ResponseStatus;
      if (errorResponseStatus.Message === 'Please connect to your CRM.') {
        this._appStore.dispatch(ExtSetParsedContactInfoFailureAction({ error: { crmNotConnected: true } }));
        return throwError(error);
      } else {
        this.loggerService.error('ERROR: ', errorResponseStatus);
        this._appStore.dispatch(ExtSetParsedContactInfoFailureAction({ error: { error, source } }));
        return throwError(error);
      }
    }
    this._appStore.dispatch(ExtSetParsedContactInfoFailureAction({ error: { error, source } }));
    return throwError(error);
  }

  createOrUpdateExtensionContactFromDozi(contact: Contact): Observable<Contact> {
    return this.doziService.export(contact.zoomId).pipe(
      map((res) => {
        if (_.isEmpty(res.exports)) {
          return contact;
        } else {
          return res.exports[0];
        }
      }),
      catchError((error) => {
        this.loggerService.error(`error on updating contact :: ${contact.name}`, error);
        return this.invokeDoziError(error);
      }),
    );
  }

  createOrUpdateExtensionContact(contact: Contact): Observable<Contact> {
    // if GCE_INTERNAL_CHARGING is true, then don't use DOZI connector ( only enabled in pre-prod)
    if (contact.source === ContactSource.Dozi && !environment.gce_internal_charging) {
      return this.createOrUpdateExtensionContactFromDozi(contact);
    } else {
      return this.createOrUpdateExtensionContactFromTellwise(contact);
    }
  }

  createOrUpdateExtensionBulkContact(contacts: Contact[]): Observable<Contact[]> {
    // if GCE_INTERNAL_CHARGING is true, then don't use DOZI connector ( only enabled in pre-prod)
    if (_.get(contacts, '[0].source') === ContactSource.Dozi && !environment.gce_internal_charging) {
      return this.createOrUpdateExtensionBulkContactFromDozi(contacts);
    } else {
      return this.createOrUpdateExtensionBulkContactFromTellwise(contacts);
    }
  }

  private createOrUpdateExtensionContactFromTellwise(contact: Contact): Observable<Contact> {
    const source = this.getSourceForImport(contact);
    const req: ContactV2UpsertRequest = {
      contacts: [contact],
      source: source,
      labelIds: [],
      conflictResolutionRule: ImportDuplicateSettings.SKIP,
    };

    return this._httpClient.post<ImportContactResponse>(`${environment.backend_url}/v2/contacts/update`, req).pipe(
      map((res: ImportContactResponse) => {
        if (_.isEmpty(res)) {
          return contact;
        } else {
          return _.get(res, 'result.contacts[0]');
        }
      }),
      catchError((error) => {
        this.loggerService.error(`error on updating contact :: ${contact.name}`, error);
        return throwError(error);
      }),
    );
  }

  private createOrUpdateExtensionBulkContactFromDozi(contacts: Contact[]): Observable<Contact[]> {
    const source = this.getSourceForImport(contacts[0]);
    const req: PersonExportRequest = { ids: contacts.map((c) => c.zoomId.toString()) };
    return this.doziService.exportBulk(req).pipe(
      map((res) => {
        if (_.isEmpty(res)) {
          return contacts;
        } else {
          return res.exports;
        }
      }),
      catchError((error) => {
        this.loggerService.error(`error on updating contacts :: `, error);
        return this.invokeDoziError(error);
      }),
    );
  }

  private createOrUpdateExtensionBulkContactFromTellwise(contacts: Contact[]): Observable<Contact[]> {
    const source = this.getSourceForImport(contacts[0]);
    const req: ContactV2UpsertRequest = {
      contacts: contacts,
      source: source,
      labelIds: [],
      conflictResolutionRule: ImportDuplicateSettings.SKIP,
    };

    return this._httpClient.post<ImportContactResponse>(`${environment.backend_url}/v2/contacts/update`, req).pipe(
      map((res: ImportContactResponse) => {
        if (_.isEmpty(res)) {
          return contacts;
        } else {
          return _.get(res, 'result.contacts');
        }
      }),
      catchError((error) => {
        this.loggerService.error(`error on updating contacts :: `, error);
        return throwError(error);
      }),
    );
  }

  private getSourceForImport(contact: Contact) {
    let source = '';
    if (contact.source === ContactSource.Dozi) {
      source = 'Dozi';
    } else if (contact.source === ContactSource.Salesforce) {
      source = 'Salesforce';
    } else if (contact.source === ContactSource.Dynamics) {
      source = 'Dynamics';
    }

    return source;
  }

  private invokeDoziConnectionErrorMessage() {
    this.notyService.postMessage(
      new Message(MessageType.ERROR, `${ExtensionErrors.DOZI_CONNECTION_ERROR} <a href="${this.accountSettingsUrl}" target="_blank">Account Settings.</a>`),
    );
  }

  private invokeDoziError(error): Observable<never> {
    const doziErrorCode: number = _.get(error, 'code', DoziErrorCode.NOT_CONNECTED);
    switch (doziErrorCode) {
      case DoziErrorCode.NOT_CONNECTED:
      case DoziErrorCode.DOZI_PLATFORM_REFRESH_TOKEN_EXPIRED:
        return throwError(ExtensionErrors.DOZI_CONNECTION_ERROR);
      case DoziErrorCode.OUT_OF_CREDITS:
        return throwError(ExtensionErrors.DOZI_OUT_OF_CREDITS_ERROR);
      case DoziErrorCode.UNKNOWN:
        return throwError(ExtensionErrors.DOZI_UNKNOWN_ERROR);
      default:
        return throwError(`${ExtensionErrors.DOZI_TROUBLESHOOT_ERROR} ${doziErrorCode}`);
    }
  }

  /**
   * Gets the error based on specific errors, returns null otherwise for default
   */
  getExtensionError(error: ExtensionErrors | string): ExtensionErrors | string {
    switch (error) {
      case ExtensionErrors.DOZI_CONNECTION_ERROR:
        return `${ExtensionErrors.DOZI_CONNECTION_ERROR} <a href="${this.accountSettingsUrl}" target="_blank">connect your SalesOS account</a>`;
      case ExtensionErrors.DOZI_OUT_OF_CREDITS_ERROR:
        return `${ExtensionErrors.DOZI_OUT_OF_CREDITS_ERROR}`;
      case ExtensionErrors.DOZI_UNKNOWN_ERROR:
        return ExtensionErrors.DOZI_UNKNOWN_ERROR;
      default:
        return typeof error === 'string' ? error : _.get(error, 'error.ResponseStatus.Message');
    }
  }
}
