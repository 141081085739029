import { Injectable } from '@angular/core';
import { ILoggerService } from '../../interface/logger.service.interface';
import { InspectOptions } from 'util';
import { environment } from '@env/environment';

/* eslint-disable no-console */
@Injectable()
export class BrowserLoggerService implements ILoggerService {
  log(message: string, ...optionalParams: any[]): void {
    if (!environment.production) {
      if (optionalParams?.length) {
        console.log(message, ...optionalParams);
      } else {
        console.log(message);
      }
    }
  }

  error(message: string, ...optionalParams: any[]): void {
    if (optionalParams?.length) {
      console.error(message, ...optionalParams);
    } else {
      console.error(message);
    }
  }

  warn(message: string, ...optionalParams: any[]): void {
    if (optionalParams?.length) {
      console.warn(message, ...optionalParams);
    } else {
      console.warn(message);
    }
  }

  dir(obj: any, options?: InspectOptions): void {
    if (!environment.production) {
      if (options) {
        console.dir(obj, options);
      } else {
        console.dir(obj);
      }
    }
  }
}
