import { Config } from 'dompurify';

export const DOMSanitizeConfiguration: Config = {
  ALLOWED_ATTR: [
    'cellpadding',
    'cellspacing',
    'alt',
    'border',
    'class',
    'colspan',
    'contenteditable',
    'fr-original-class',
    'fr-original-style',
    'height',
    'href',
    'id',
    'noshade',
    'rowspan',
    'src',
    'style',
    'target',
    'title',
    'width',
    'color', // ATTR added automatically by chrome spell check to 'font' tag.
    'face', // ATTR added automatically by chrome spell checkto 'font' tag.
  ],
  ADD_DATA_URI_TAGS: ['a'],
};
