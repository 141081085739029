import { UserType } from '../user-type.enum';

export interface OptionInterface {
  hitCount?: number;
  displayName: string;
  value: any;
  checked: boolean;
  isEnabled: boolean;
  data?: any;
  isNegation?: boolean;
  category?: string;
  entity?: string;
  key?: string;
}

export class SelectComponentObject {
  constructor(
    public displayName: string = '',
    public isEnabled: boolean = true,
    public isLink: boolean = false,
    public link: string = '',
    public value: any = null,
    public isClickable: boolean = true,
    public circleColor: any = null,
    public toolTipText: string = '',
    public iconLink?: string,
    public userType?: UserType,
    public line1?: string,
    public line2?: string,
    public isCalculated?: boolean,
    public isUpdateable?: boolean,
  ) {}
}

export class SortOption {
  constructor(public displayName: string, public value: any, public enabled?: boolean) {}
}

export class SortSection {
  constructor(public options: SortOption[], public displayName?: string) {}
}

export class ScopeOption {
  constructor(public type: any, public value: any, public key: any) {}
}

export class SelectOptionObject implements OptionInterface {
  constructor(
    public displayName: string = '',
    public value: number = null,
    public isEnabled: boolean = true,
    public checked: boolean = true,
    public email: string = '',
  ) {}
}
