<div class="dotten-text-container">
  <span
    class="tooltip"
    *ngIf="needTooltip && hasTooltip"
    [matTooltip]="customTooltipText? customTooltipText : text"
    [matTooltipClass]="'engage-tooltip ' + tooltipClass"
    [matTooltipPosition]="position"
    [matTooltipDisabled]="disabled"
    [automationId]="'dotten-text-tooltip'"
  >
    <div #someInput class="dotten-text" [ngClass]="{'link': isLink}" *ngIf="text">
      <span>{{text}}</span>
    </div>
    <div #someInput class="dotten-text" [ngClass]="{'link': isLink}" *ngIf="!text"><ng-content></ng-content></div>
  </span>
  <div
    #someInput
    class="dotten-text"
    [ngClass]="{'link': isLink, 'disabled': disabled}"
    *ngIf="(!needTooltip || !hasTooltip) && text"
    [automationId]="text?.toString() | lowercase"
  >
    <span>{{text}}</span>
  </div>
  <div #someInput class="dotten-text" [ngClass]="{'link': isLink, 'disabled': disabled}" *ngIf="(!needTooltip || !hasTooltip) && !text">
    <ng-content></ng-content>
  </div>
</div>
