import { Contact } from '@zi-core/data-model/contact.model';
import { createAction, props } from '@ngrx/store';
import { FilterQueryNode } from '@zi-core/data-model/filter-node.model';
import { FilterSortOrder, FilterSort, Scope } from '@zi-core/enums/engage-filter.enum';

export const LoadFilteredAccountDetailContactsSuccessAction = createAction(
  '[CompanyAccount Detail Contact Entity] companyAccount/contacts/getByAccountId/success',
  props<{ payload: Contact[]; requestId: number; found: number }>(),
);

export const LoadFilteredAccountDetailContactsFailureAction = createAction(
  '[CompanyAccount Detail Contact Entity] companyAccount/contacts/getByAccountId/failure',
  props<{ payload?: Contact; error: string; errorCode?: string }>(),
);

export const LoadFilteredAccountDetailContactsAction = createAction(
  '[CompanyAccount Detail Contact Entity] companyAccount/contacts/loadFilteredContacts',
  props<{
    requestId: number;
    page?: number;
    count?: number;
    scope: Scope;
    sortOrder?: FilterSortOrder;
    sortField?: FilterSort;
    nodes: FilterQueryNode[];
    search: string;
  }>(),
);

export const LoadSearchAccountDetailContactAction = createAction(
  '[CompanyAccount Detail Contact Entity] companyAccount/contacts/loadSearchContacts',
  props<{
    requestId: number;
    accountId: number;
    page?: number;
    count?: number;
    scope: Scope;
    sortOrder?: FilterSortOrder;
    sortField?: FilterSort;
    search: string;
  }>(),
);

export const UpdateCompanyAccountContactSubscriptionSetting = createAction(
  '[CompanyAccount Detail Contact Entity] companyAccount/contact/subscribeSetting/update',
  props<{ contact: Contact; refreshCompAccActivitiesSummary?: boolean; companyAccountId?: number; oldContact?: Contact }>(),
);

export const UpdateCompanyAccountContactSubscriptionSettingSuccess = createAction(
  '[CompanyAccount Detail Contact Entity] companyAccount/contact/subscribeSetting/update/success',
  props<{ contact: Contact }>(),
);

export const UpdateCompanyAccountContactSubscriptionSettingFailure = createAction(
  '[CompanyAccount Detail Contact Entity] companyAccount/contact/subscribeSetting/update/failure',
  props<{ error: string }>(),
);

// update CompanyAccountDetailContact status action
export const UpdateCompanyAccountDetailContactEntityStatusAction = createAction(
  '[CompanyAccount Detail Contact Entity] companyAccount/contact/status/update',
  props<{ status: string; contactId: number }>(),
);

// update CompanyAccountDetailContact status success
export const UpdateCompanyAccountDetailContactEntityStatusSuccessAction = createAction(
  '[CompanyAccount Detail Contact Entity] companyAccount/contact/status/update/success',
  props<{ status: string; contactId: number }>(),
);

// update CompanyAccountDetailContact status failure
export const UpdateCompanyAccountDetailContactEntityStatusFailureAction = createAction(
  '[CompanyAccount Detail Contact Entity] companyAccount/contact/status/update/failure',
  props<{ error: any }>(),
);

// update CompanyAccountDetailContact bulk success
export const UpdateCompanyAccountDetailBulkContactSuccessAction = createAction(
  '[CompanyAccount Detail Contact Entity] companyAccount/contact/update/success',
  props<{ payload: Contact[] }>(),
);

// update CompanyAccountDetailContact
export const UpdateCompanyAccountDetailContactAction = createAction(
  '[CompanyAccount Detail Contact Entity] companyAccount/contact/update',
  props<{ payload: Contact; updateEngageSettings?: boolean; oldContact?: Contact }>(),
);

// update CompanyAccountDetailContact success
export const UpdateCompanyAccountDetailContactSuccessAction = createAction(
  '[CompanyAccount Detail Contact Entity] companyAccount/contact/update/success',
  props<{ payload: Contact }>(),
);

// update CompanyAccountDetailContact failure
export const UpdateCompanyAccountDetailContactFailureAction = createAction(
  '[CompanyAccount Detail Contact Entity] companyAccount/contact/update/failure',
  props<{ error: string }>(),
);
