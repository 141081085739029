import { HttpClient } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';

@Injectable()
export class AutoCompleteService {
  url: string;
  /**
   * The autoCompleteEndPoint
   */
  autoCompleteEndPoint: string;
  /**
   * The autoCompleteUrl
   */
  autoCompleteUrl: string;

  /**
   * The resultsJsonPath
   */
  resultsJsonPath: string;
  /**
   * The resultObjJsonPath
   */
  resultObjJsonPath: string;
  constructor(private http: HttpClient) {
    this.autoCompleteUrl = `${environment.backend_url}`;
    this.autoCompleteEndPoint = '/v1/search/query';
    this.resultsJsonPath = 'contacts';
    this.resultObjJsonPath = 'name';
  }

  public searchWordInUrl(byMail = true, take = 20, val) {
    const query = {
      Type: 104,
      Query: val,
      WithFacets: byMail,
      WithWords: byMail,
      Take: take,
      Filter: '',
    };
    this.url = this.autoCompleteUrl + this.autoCompleteEndPoint;
    return this.http.post(this.url, query).pipe(
      map((res) => {
        const itemsArr = _.get(res, this.resultsJsonPath);
        if (res && Array.isArray(itemsArr)) {
          return itemsArr;
        }
        return [];
      }),
    );
  }
}
