export const validationMessages = Object.freeze({
  generic: 'Invalid value entered',
  maxLength(fieldName: string, maxValue: number) {
    return `${fieldName} cannot contain more than ${maxValue} characters`;
  },
  phone: 'Please enter a valid phone number',
  required: '*Required field',
  email: 'Please enter a valid email address',
  pattern: 'Please enter a valid email address',
  url: 'Please enter a valid URL',
  number: 'Please enter a valid number',
  equalPhoneError: 'Phone number cannot contain =',
  customUrlError: 'URL cannot contain =',
  plusUrlError: 'URL cannot start with +',
});
