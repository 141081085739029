import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { getUserPermissionsFromAuthToken } from '@app/core/ngrx/state/auth.state';
import { PermissionEnum } from '@app/pages/admin/pages/profiles-and-permissions/profiles-and-permissions.config';
import { ApplicationState } from '@app/reducers';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { CompanyAccountStatusColor } from '@zi-common/interface/company-account-status';
import { PickListValue } from '@zi-common/interface/contactStatus';
import { SelectOption } from '@zi-common/interface/selectOption';
import { UtilitiesService } from '@zi-common/service/utilities-service/utilities-service';
import { CompanyAccount } from '@zi-core/data-model/account.model';
import { FilterEntity, FilterSort, FilterSortOrder, Scope, ScopeNumber } from '@zi-core/enums/engage-filter.enum';
import { EngageFilterService } from '@zi-core/service/engage-filter.service';
import { SettingService } from '@zi-core/service/setting.service';
import * as _ from 'lodash';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { catchError, debounceTime, filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-company-account-search-dropdown',
  templateUrl: './company-account-search-dropdown.component.html',
  styleUrls: ['./company-account-search-dropdown.component.scss'],
})
export class CompanyAccountSearchDropdownComponent implements OnInit, OnDestroy {
  @Input() linkedCompanyAccountIdToDisable: number;
  @Input() placeholder = 'Enter Account name';
  @Input() statusPickList: Array<PickListValue> = [];
  @Input() defaultPickListVal = '-';
  @Input() isClearDisabled = false;

  @Output() optionSelected = new EventEmitter<SelectOption>();

  $dropdownOptions: BehaviorSubject<SelectOption[]>;
  $inputFocus: BehaviorSubject<boolean>;
  searchValue: FormControl;
  selectedOption: SelectOption;
  noResultsFound: boolean;
  autocompleteDebounceTime: number;
  companyAccountStatusColors: { [id: number]: string };
  disabledToolTipText: string;

  searchValueListenerSub: Subscription;
  clickListenerSub: Subscription;

  @ViewChild('inputSearchDropdown') inputSearchDropdownRef: ElementRef;
  @ViewChild('inputField') inputRef: ElementRef;

  constructor(
    private utilitiesService: UtilitiesService,
    private engageFilterService: EngageFilterService,
    private settingService: SettingService,
    private cdr: ChangeDetectorRef,
    private store: Store<ApplicationState>,
  ) {
    this.$dropdownOptions = new BehaviorSubject<SelectOption[]>([]);
    this.$inputFocus = new BehaviorSubject<boolean>(true);
    this.searchValue = new FormControl();
    this.noResultsFound = false;
    this.autocompleteDebounceTime = 100;
    this.companyAccountStatusColors = {};
    this.disabledToolTipText = 'Contact(s) have already been linked to this account';
  }
  userScope: Scope;

  ngOnInit() {
    this.clickListenerSub = this.utilitiesService.documentClickedTarget.subscribe((target) => {
      this.documentClickListener(target);
    });

    this.store
      .select(getUserPermissionsFromAuthToken)
      .pipe(untilDestroyed(this))
      .subscribe((permissions) => {
        const viewPermission = permissions.find((permission) => permission?.permissionId === PermissionEnum.ACCOUNT_MANAGEMENT_VIEW_ACCOUNTS);
        this.userScope =
          viewPermission?.scopeId === ScopeNumber.Organization ? Scope.Organization : viewPermission?.scopeId === ScopeNumber.Team ? Scope.Team : Scope.Owner;
      });

    this.searchValueListenerSub = this.searchAccounts().subscribe();
  }

  documentClickListener(target: any): void {
    if (!this.inputSearchDropdownRef.nativeElement.contains(target)) {
      this.setDropdownData([], false);
    }
  }

  /**
   * Fn fetches company accounts based on search criteria
   * Request to backend filters endpoint is not sent if:
   * 1. searchValue is empty
   * 2. user has selected a company account
   * 3. input is not in focus
   */
  searchAccounts() {
    return this.searchValue.valueChanges.pipe(
      debounceTime(this.autocompleteDebounceTime),
      withLatestFrom(this.$inputFocus),
      filter(([searchValue, inFocus]) => {
        if (!searchValue || this.selectedOption || !inFocus) {
          this.setDropdownData([], false);
          return false;
        }
        return true;
      }),
      mergeMap(() => {
        return this.engageFilterService.getFilterResults({
          chips: [],
          scope: this.userScope,
          entity: FilterEntity.CompanyAccount,
          pageNumber: 1,
          pageSize: this.settingService.recordsPerPage,
          sortBy: FilterSort.LastTouched,
          sortOrder: FilterSortOrder.Descending,
          search: this.searchValue.value,
        });
      }),
      map((response: any) => {
        // check current value of search term & focus since subscribe on retrieved filter results
        // may be fired after the search term has changed
        if (!this.searchValue.value || this.selectedOption || !this.$inputFocus.value) {
          this.setDropdownData([], false);
          return;
        }

        if (response && response.companyAccounts) {
          const dropdownOptions: SelectOption[] = response.companyAccounts.map((ca: CompanyAccount) => {
            return { displayName: ca.name, value: ca, selected: false };
          });
          this.setDropdownData(dropdownOptions, dropdownOptions.length < 1);
          this.getStatusColors(response.companyAccounts);
        } else {
          this.setDropdownData([], true);
        }
      }),
      catchError(() => {
        this.setDropdownData([], false);
        return of();
      }),
    );
  }

  setSelectedOption(selectedOption: SelectOption) {
    this.selectedOption = selectedOption;
    this.optionSelected.emit(selectedOption);
  }

  setDropdownData(dropdownOptions: SelectOption[], noResultsFound: boolean) {
    this.$dropdownOptions.next(dropdownOptions);
    this.noResultsFound = noResultsFound;
    this.cdr.detectChanges();
  }

  clickOption(selectedOption: SelectOption) {
    if (_.get(selectedOption, 'value.id') !== this.linkedCompanyAccountIdToDisable) {
      this.setSelectedOption(selectedOption);
      this.searchValue.setValue(selectedOption.displayName);
    }
  }

  onKeyUp() {
    this.setSelectedOption(null);
  }

  clear() {
    this.setSelectedOption(null);
    this.searchValue.setValue(null);
    this.setDropdownData([], false);
    this.inputRef.nativeElement.focus();
  }

  getStatusColors(companyAccounts: CompanyAccount[]) {
    this.companyAccountStatusColors = {};
    companyAccounts.forEach((ca) => {
      const caStatus: string = ca.status || this.defaultPickListVal;
      const foundStatus = this.statusPickList.find((s) => s.status === caStatus);
      this.companyAccountStatusColors[ca.id] = foundStatus
        ? CompanyAccountStatusColor[foundStatus.color]
          ? CompanyAccountStatusColor[foundStatus.color].code
          : ''
        : '';
    });
  }

  ngOnDestroy(): void {
    this.$dropdownOptions.complete();
    this.$inputFocus.complete();
    this.clickListenerSub.unsubscribe();
    this.searchValueListenerSub.unsubscribe();
  }
}
