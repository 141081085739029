import { defaultFollowUpTaskPanelValue, FollowUpTaskPanelValue } from '@app/caller/component/follow-up-task-panel/follow-up-task-panel.config';
import { PhoneTypesEnum } from '@app/caller/interface/phone-types-enum';

export interface CallLogInfo {
  input: CallLogInfoUserInput;
  followUpTask: CallLogFollowUpTask;
  prospectCallId: string;
}

export interface ManulalLogCallInfo {
  isDialogOpen: boolean;
  contactId: number;
}

export interface CallLogInfoUserInput {
  result: string;
  sentiment: string;
  subject: string;
  comment: string;
  date?: string;
  time?: any;
  duration?: string;
  logType?: string;
  phone?: string;
  phoneType?: PhoneTypesEnum; // Used for indicate type of phone number
}

export interface CallLogFollowUpTask extends FollowUpTaskPanelValue {}

export const initialCallLogInfoUserInput: CallLogInfoUserInput = {
  result: null,
  sentiment: null,
  subject: null,
  comment: null,
  date: null,
  time: null,
  logType: null,
  duration: null,
  phone: null,
  phoneType: null,
};

export const initialCallLogFollowUpTask: CallLogFollowUpTask = {
  ...defaultFollowUpTaskPanelValue,
};

export const initialCallLogInfo: CallLogInfo = {
  input: initialCallLogInfoUserInput,
  followUpTask: initialCallLogFollowUpTask,
  prospectCallId: null,
};

export const initialManulalLogCallInfo: ManulalLogCallInfo = {
  isDialogOpen: false,
  contactId: null,
};
