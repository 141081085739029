import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { AnalyticsBaseService } from '@zi-core/service/analytics.base.service';
import { Router } from '@angular/router';
import { LoggerServiceToken } from '@zi-core/config/logger-service.config';
import { ILoggerService } from '@zi-core/interface/logger.service.interface';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private analyticsBaseService: AnalyticsBaseService;
  private readonly chunkFailedMessage: RegExp;

  constructor(private injector: Injector, private router: Router, @Inject(LoggerServiceToken) private loggerService: ILoggerService) {
    this.chunkFailedMessage = /Loading chunk [\d]+ failed/;
    setTimeout(() => {
      // For injecting service
      this.analyticsBaseService = this.injector.get(AnalyticsBaseService);
    });
  }

  /**
   * Override fn from ErrorHandler
   * @param error - any error object
   */
  handleError(error: any): void {
    this.sendErrorEvent(error);
    this.loggerService.error(error);

    this.handleLazyLoadChunkError(error);
  }

  /**
   * Private fns
   */

  /**
   * Since there are many chunks, sometimes (rarely) there can be an issue in loading a chunk
   * reloading the browser helps (since it relates to network issue)
   *
   * @param error any error object even isntance of HttpErrorResponse/ Error
   * @private
   */
  private handleLazyLoadChunkError(error: any) {
    if (this.chunkFailedMessage.test(error?.message)) {
      this.sendApplicationReloadEvent(error);
      window.location.reload();
    }
  }

  private sendApplicationReloadEvent(error: any) {
    this.analyticsBaseService?.sendApplicationReloadRequest(error?.message, this.router.url);
  }

  private sendErrorEvent(error: any) {
    // Not error?.message to ease fixing
    const message = JSON.stringify(error?.stack || error?.message || error);
    this.analyticsBaseService?.sendErrorEvent('FromGlobalErrorHandlerInEngage', message, this.router.url);
  }
}
