import { createAction, props } from '@ngrx/store';
import { ExtensionTab } from '@app/extension/model/extension-tab.model';
import { Contact } from '@zi-core/data-model/contact.model';
import { ExtensionSource } from '@app/extension/constants/extension-source.enum';
import { ExtensionSize } from '@app/extension/constants/extension-size.enum';

export const ExtUpdateSelectedTabAction = createAction('[Extension] Update Selected Tab', props<{ selectedExtTab: ExtensionTab }>());

export const ExtGoToTwentyPctAction = createAction('[Extension] Move Extension to 20% View', props<{ sizeComingFrom: ExtensionSize }>());

export const ExtGoToTwentyPctSuccessAction = createAction('[Extension] Move Extension to 20% View Success');

export const ExtGoToFiftyPctAction = createAction('[Extension] Move Extension to 50% View', props<{ redirectUrl: string; queryParams?: any }>());

export const ExtGoToNinetyPctAction = createAction(
  '[Extension] Move Extension to 90% View',
  props<{ redirectUrl?: string; queryParams?: any; notExpandIFrame?: boolean }>(),
);

export const ExtMinimizeAction = createAction('[Extension] Minimize Extension');

export const ExtLoadingStartAction = createAction('[Extension] Loading Start');

export const ExtLoadingEndAction = createAction('[Extension] Loading End');

export const ExtReceiveNewParsedContactIdsInfoAction = createAction(
  '[Extension] Receive New Parsed Contact Info',
  props<{ encodedParsedContactIdsInfo: string; source: ExtensionSource }>(),
);

export const ExtLoadContactsAction = createAction('[Extension] Set contact in state', props<{ contact: Contact[]; source: ExtensionSource }>());

export const ExtUpdateContactsAction = createAction('[Extension] update contacts in state', props<{ contact: Contact; source: ExtensionSource }>());

export const ExtRemoveContactsAction = createAction('[Extension] remove contacts in state', props<{ contacts: Contact[]; source: ExtensionSource }>());

export const ExtParsingLoadedAction = createAction('[Extension] contacts loading completed');

export const ExtSetParsedContactInfoSuccessAction = createAction('[Extension] Set Parsed Contact Info Success', props<{ parsedContacts: Contact[] }>());

export const ExtSetParsedContactInfoFailureAction = createAction('[Extension] Set Parsed Contact Info Failure', props<{ error: any }>());

export const ExtSetProfilePageContactAction = createAction(
  '[Extension Profile] Set Contact Profile Success',
  props<{ parsedContacts: Contact[]; page: string }>(),
);

export const ExtLoggedInMsgSentAction = createAction('[Extension] Logged In Message Sent');

export const ExtLoggedOutMsgSentAction = createAction('[Extension] Logged Out Message Sent');

export const ExtStartParsingPageAction = createAction('[Extension] Start Parsing Page');

export const ExtGetParsedContactInfoAction = createAction('[Extension] Get Parsed Contact Info');

// contact update
export const ExtAddContactAction = createAction('[Extension Contact] entity/add', props<{ payload: Contact }>());

//  contact success
export const ExtAddContactSuccessAction = createAction('[Extension Contact] entity/add/success', props<{ payload: any }>());

//  contact success
export const ExtAddContactFailureAction = createAction('[Extension Contact] entity/add/failure', props<{ error: any }>());

// bulk update
export const ExtAddBulkContactAction = createAction('[Extension Bulk Contact] bulk/add', props<{ payload: Contact[] }>());

//  contact success
export const ExtAddBulkContactSuccessAction = createAction('[Extension Bulk Contact] bulk/add/success', props<{ payload: any }>());

//  contact success
export const ExtAddBulkContactFailureAction = createAction('[Extension Bulk Contact] bulk/add/failure', props<{ error: any }>());

export const ExtSelectContactId = createAction('[Extension SelectId] Select contact Id', props<{ contactId: any }>());

export const ExtSelectContactAllId = createAction('[Extension SelectId] Select all Id', props<{ contactsIds: any[] }>());

export const ExtClearContactId = createAction('[Extension ClearId] Clear Id', props<{ contactId: any }>());

export const ExtClearAllContactId = createAction('[Extension ClearId] Clear All Contact');

export const ExtNoOpAction = createAction('[Extension] No Op Action');

export const ExtUpdateCurrentIndex = createAction('[Extension update index] Update Index', props<{ index: number }>());
