import { createAction, props } from '@ngrx/store';
import { LabelResourceType } from '@zi-core/enums/resource-type.enum';
import { LabelBucketDto, LabelDto, LabelItem } from '@zi-core/http-model/response/labels.response.model';
import { FilterSort, FilterSortOrder, Scope } from '@zi-core/enums/engage-filter.enum';

export const LoadLabelsByResourceTypeAction = createAction('[Labels] load/resource-type', props<{ resourceType: LabelResourceType; scope?: Scope }>());

export const LoadLabelsByResourceTypeSuccessAction = createAction(
  '[Labels] load/resource-type/success',
  props<{ labelBuckets: LabelBucketDto[]; resourceType: LabelResourceType }>(),
);

export const CreateLabelAction = createAction('[Labels] create/label', props<{ resourceType: LabelResourceType; title: string; color: number }>());
export const CreateLabelSuccessAction = createAction('[Labels] create/label/success', props<{ label: LabelDto; resourceType: LabelResourceType }>());
export const CreateLabelFailureAction = createAction('[Labels] create/label/failure');

export const EditLabelAction = createAction('[Labels] edit/label', props<{ resourceType: LabelResourceType; label: LabelItem; newTitle: string }>());
export const EditLabelSuccessAction = createAction('[Labels] edit/label/success', props<{ resourceType: LabelResourceType; label: LabelItem }>());
export const EditLabelFailureAction = createAction('[Labels] edit/label/failure');
export const SetSortFieldAction = createAction('[Labels] set sort field', props<{ field: FilterSort }>());
export const SetSortDirectionAction = createAction('[Labels] set sort direction', props<{ order: FilterSortOrder }>());
export const SetShowUnusedTagsOnlyAction = createAction('[Labels] set show unused tags only', props<{ showUnusedTagsOnly: boolean }>());
export const ToggleShowUnusedTagsOnlyAction = createAction('[Labels] toggle show unused tags only');

export const DeleteUnusedTagsAction = createAction('[Labels] delete unused/label', props<{ resourceType: LabelResourceType }>());

export const DeleteUnusedTagsSuccessAction = createAction('[Labels] delete unused/label/success', props<{ resourceType: LabelResourceType }>());

export const DeleteUnusedTagsFailureAction = createAction('[Labels] delete unused/label/failure', props<{ resourceType: LabelResourceType }>());

export const DeleteLabelAction = createAction('[Labels] delete/label', props<{ label: LabelBucketDto; resourceType: LabelResourceType }>());

export const DeleteLabelSuccessAction = createAction('[Labels] delete/label/success', props<{ label: LabelBucketDto; resourceType: LabelResourceType }>());

export const DeleteLabelFailureAction = createAction('[Labels] delete/label/failure', props<{ label: LabelBucketDto; resourceType: LabelResourceType }>());
