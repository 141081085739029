export const FOLLOWUPSF_NO_TEMPLATES = 'You do not have access to any Salesflows';
export const FOLLOWUPSF_DEFAULT = 'Set Follow Up Salesflow';
export const FOLLOWUPSF_GROUPS = 'Follow-Up Salesflows are only available in Campaign Mode';
export const FOLLOWUPTF = 'Set Follow Up Talentflow';
// This constant represents the string "Can't send the email because all recipients have unsubscribed".
export const CANT_SEND_EMAIL_BECAUSE_ALL_RECIPIENTS_ARE_EMAILS_UNSUBSCRIBED = "Can't send the email because all recipients have unsubscribed";
// This constant represents the string "Unable to send email because your Calendar link isn't connected.\nTo connect your calendar, navigate to your account profile tab.".
export const CANT_SEND_EMAIL_BECAUSE_USER_DOES_NOT_HAVE_CALENDAR_LINK =
  "Unable to send email because your Calendar link isn't connected.\nTo connect your calendar, navigate to your account profile tab.";
export const SUBJECT_MAX_LENGTH = 150;
