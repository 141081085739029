import { initialSfLiveAgentState, SfLiveAgentState } from '@zi-pages/sf-live-agent/ngrx/state/sf-live-agent.state';
import { Action, createReducer, on } from '@ngrx/store';
import {
  SfLiveAgentDisconnectAction,
  SfLiveAgentDisconnectSuccessAction,
  SfLiveAgentInitFailureAction,
  SfLiveAgentInitSuccessAction,
  StartSfLiveAgentInitAction,
} from '@zi-pages/sf-live-agent/ngrx/action/sf-live-agent.action';

const sfLiveAgentReducer = createReducer(
  { ...initialSfLiveAgentState },
  on(StartSfLiveAgentInitAction, (state: SfLiveAgentState) => {
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
      error: null,
    };
  }),

  on(SfLiveAgentInitSuccessAction, (state: SfLiveAgentState) => {
    return {
      isInitialized: true,
      isLoading: false,
      isLoaded: true,
      error: null,
    };
  }),

  on(SfLiveAgentInitFailureAction, (state: SfLiveAgentState, { error }) => {
    return {
      isInitialized: false,
      isLoading: false,
      isLoaded: false,
      error: {
        hasError: true,
        error,
      },
    };
  }),

  on(SfLiveAgentDisconnectAction, (state: SfLiveAgentState) => {
    return {
      ...state,
      isLoading: true,
      isLoaded: false,
      error: {
        hasError: false,
        error: null,
      },
    };
  }),

  on(SfLiveAgentDisconnectSuccessAction, (state: SfLiveAgentState) => {
    return {
      isInitialized: false,
      isLoading: false,
      isLoaded: false,
      error: {
        hasError: false,
        error: null,
      },
    };
  }),
);

export function SfLiveAgentReducer(state: SfLiveAgentState, action: Action): SfLiveAgentState {
  return sfLiveAgentReducer(state, action);
}
