import { Template } from '@app/core/data-model/template.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContentEntityState } from '@zi-pages/content/ngrx/state/content-entity.state';
import { ContentFilterState, ContentSortOrder } from '@zi-pages/content/ngrx/state/content-filter.state';
import { ContentStatisticsState } from '@zi-pages/content/ngrx/state/content-statistics.state';
import { ContentTilesState } from '@zi-pages/content/ngrx/state/content-tiles.state';
import { FilterDateRangeValuesEnum } from '@app/core/enums/date-aggregates.enum';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ContentSelectorState } from '@zi-pages/content/ngrx/state/content-selector.state';
import { Scope } from '@app/core/enums/engage-filter.enum';
import { getAuthState, AuthenticatedTokenState } from '@app/core/ngrx/state/auth.state';
import { Team, User } from '@app/pages/admin/components/admin-teams-page/teams.config';
import { TEMPLATE_CREATED_ITEM_FILTER_KEY } from '../../content.config';
import { TeamTemplateCreatedByFilterItem, TemplateCreatedByFilterItem } from '@app/common/pages-component/templates-panel/template-panel.config';
import { FilterQueryNode } from '@zi-core/data-model/filter-node.model';
import { TemplateAndStats } from '@app/core/data-model/template-and-stats.model';

const TEMPLATE_NO_OWNER_NAME = 'no owner';
const TEMPLATE_NO_OWNER_ID = -1;

export class ContentState {
  templates: ContentEntityState;
  statistics: ContentStatisticsState;
  tiles: ContentTilesState;
  filters: ContentFilterState;
  selector: ContentSelectorState;
}
export function filterByDate(entity: Template, dateFieldVal, dateMin, dateMax): boolean {
  if (!dateFieldVal || !dateMin || !dateMax) {
    // don't filter
    return true;
  }
  return dateMin < _.get(entity, dateFieldVal) && dateMax > _.get(entity, dateFieldVal);
}

export function filterAndSortEntities(
  contentEntityState: ContentEntityState,
  contentFilterState: ContentFilterState,
  css: ContentStatisticsState,
  userId,
): TemplateAndStats[] {
  const tagIds = (contentFilterState.query.tags || []).map((tag) => tag.value.toLowerCase());
  const salesflowIds = (contentFilterState.query.salesflow || []).map((salesflow) => salesflow.key);
  const dateField = _.get(contentFilterState.memFilter.date_field, '[0].value');
  const dateRange = _.get(contentFilterState.memFilter.date_range, '[0].value');
  const startingDate = _.get(contentFilterState.memFilter.date_range, '[0].customDate.min');
  const endingDate = _.get(contentFilterState.memFilter.date_range, '[0].customDate.max');
  const users = (contentFilterState.memFilter.owner || [])?.map((owner) => owner?.value?.toString());
  const teamIds = (contentFilterState.memFilter.team || [])?.reduce((acc, team) => {
    if (!_.get(team, 'value')) {
      return acc;
    }
    return acc.concat(_.get(team, 'value'));
  }, []);

  let min, max;

  switch (dateRange) {
    case FilterDateRangeValuesEnum.any:
      min = moment(0).unix();
      max = moment().add(1, 'd').unix();
      break;
    case FilterDateRangeValuesEnum.lastWeek:
      min = moment().subtract('7', 'd').unix();
      max = moment().add(1, 'd').unix();
      break;
    case FilterDateRangeValuesEnum.lastTwoWeeks:
      min = moment().subtract('14', 'd').unix();
      max = moment().add(1, 'd').unix();
      break;
    case FilterDateRangeValuesEnum.currentMonth:
      min = moment().startOf('month').unix();
      max = moment().add(1, 'd').unix();
      break;
    case FilterDateRangeValuesEnum.currentYear:
      min = moment().startOf('year').unix();
      max = moment().add(1, 'd').unix();
      break;
    case FilterDateRangeValuesEnum.custom:
      min = moment(startingDate).startOf('day').unix();
      max = moment(endingDate).endOf('day').unix();
      break;
  }

  let filterTemplates: TemplateAndStats[] = contentEntityState.ids
    .map((id) => contentEntityState.entities[id])
    .filter((template) => contentFilterState.scope !== Scope.Owner || (template.ownerId === userId && contentFilterState.scope === Scope.Owner))
    .filter((entity) => filterByDate(entity, dateField, min, max))
    // filter by userid and teams
    .filter((entity) => (users.length === 0 && teamIds.length === 0) || users.includes(entity.ownerId.toString()) || teamIds.includes(entity.teamId))
    // filter the entities by search
    .filter((entity) => entity && (entity.title || '').toLowerCase().includes(contentFilterState.search))
    // filter by tags
    .filter((entity) => {
      if (tagIds.length === 0) {
        return true;
      }
      return (
        _.intersectionWith(tagIds, entity.tags as string[], (tag1, tag2) => {
          return tag1.toLowerCase() === new String(tag2).toLowerCase();
        }).length > 0
      );
    })
    // filter by salesflow
    .filter((entity) => {
      if (salesflowIds.length === 0) {
        return true;
      }
      return (
        _.intersectionWith(
          salesflowIds,
          (entity.salesWorkflowTemplates || []).map((sf) => sf.id),
          (sf1, sf2) => {
            return new String(sf1).toLowerCase() === new String(sf2).toLowerCase();
          },
        ).length > 0
      );
    })
    // join template with statistics
    .map((entity) => ({ template: entity, stats: css.entities[entity.id] }))
    // sort by field , inverse sort order by multiplying with -1
    .sort((t1, t2) => {
      return (
        (_.get(t2, contentFilterState.sort.field, 0) - _.get(t1, contentFilterState.sort.field, 0)) *
        (contentFilterState.sort.order === ContentSortOrder.ASC ? -1 : +1)
      );
    });

  return filterTemplates;
}

export const contentFeatureSelector = createFeatureSelector<ContentState>('Content');

// Content Statistics selectors
export const contentStatisticsSelector = createSelector(contentFeatureSelector, (state) => state.statistics);

// Content Entity selectors
export const contentEntitySelector = createSelector(contentFeatureSelector, (state) => state.templates);

export const contentStateSelector = createSelector(contentFeatureSelector, (state) => state.selector);

// Content Filter selectors
export const contentFilterSelector = createSelector(contentFeatureSelector, (state) => state.filters);

export const contentFilterScopeSelector = createSelector(contentFilterSelector, (state) => state.scope);

export const contentFilterPageSelector = createSelector(contentFilterSelector, (state) => state.page);

export const contentFilterSortSelector = createSelector(contentFilterSelector, (state) => state.sort);
export const contentFilterLoadedFromUrlSelector = createSelector(contentFilterSelector, (state) => state.loadFromUrl);

export const contentSelectedCount = createSelector(contentStateSelector, (state: ContentSelectorState) => state.count);
// selected ids
export const contentSelectedIds = createSelector(contentStateSelector, (state: ContentSelectorState) => state.ids);

export const contentSelectedEntities = createSelector(contentStateSelector, (state: ContentSelectorState) => Object.values(state.entities));

// is contact selected
export const contentSelected = (contentId) => createSelector(contentStateSelector, (state: ContentSelectorState) => state.ids.includes(contentId));

export const contentFilterSearchSelector = createSelector(contentFilterSelector, (state) => state.search);

export const contentFilterAllSelector = createSelector(contentFilterSelector, (contentFilters) => {
  // only display when both date field and range are available. The chip displya should only show one chip
  const dateField: FilterQueryNode = _.get(contentFilters, 'memFilter.date_field.[0]');
  const dateRange: FilterQueryNode = _.get(contentFilters, 'memFilter.date_range.[0]');

  return _.flatMap(contentFilters.query)
    .concat(
      _.filter(contentFilters.memFilter, (value, key) => {
        return key !== 'date_field' && key !== 'date_range';
      }).reduce((a, b) => a.concat(b), []),
    )
    .concat(
      dateField && dateRange
        ? [
            {
              ...dateField,
              displayName: dateField.displayName + ' in ' + dateRange.displayName,
            },
          ]
        : [],
    );
});

export const contentFilterAllSelectorCustomDates = createSelector(contentFilterSelector, (content: ContentFilterState) => {
  const customDates: { min: Date; max: Date } = _.get(content, 'memFilter.date_range.[0].customDate');
  return customDates;
});

export const contentEntityLoaded = createSelector(contentEntitySelector, (cEntity) => cEntity.loaded);

export const contentEntityNewEntitySelector = createSelector(contentEntitySelector, (cEntity) => cEntity.newEntityId);

export const contentFilterQuerySelector = createSelector(contentFilterSelector, (cfs) => cfs.query);

export const contentEntityArraySelector = createSelector(contentEntitySelector, (content: ContentEntityState) => content.ids.map((id) => content.entities[id]));

export const contentEntityFilteredEntitiesSelector = createSelector(contentEntitySelector, (content: ContentEntityState) =>
  content.filteredIds.map((id) => content.entities[id]),
);

export const contentEntitySortedSelector = createSelector(contentEntityArraySelector, (contents) =>
  _.values(contents).sort((first, second) => (first.title.toUpperCase() < second.title.toUpperCase() ? -1 : 1)),
);

// pagination selector for content
export const contentEntityPageSelector = (pageSize) =>
  createSelector(
    contentEntitySelector,
    contentFilterSelector,
    contentStatisticsSelector,
    getAuthState,
    (content: ContentEntityState, filter: ContentFilterState, css: ContentStatisticsState, tokenState: AuthenticatedTokenState) => {
      let filteredTemplates = filterAndSortEntities(content, filter, css, tokenState.profile.userId);

      // paginate
      let paginatedList = [];
      if (pageSize === Infinity) {
        return { total: filteredTemplates.length, paginatedResult: filteredTemplates };
      }
      paginatedList = filteredTemplates.filter((value, index) => index >= (filter.page - 1) * pageSize && index < filter.page * pageSize);
      return { total: filteredTemplates.length, paginatedResult: paginatedList };
    },
  );

export const contentFilteredOwnerMapSelector = (currentUserId, userMap) =>
  createSelector(contentEntityArraySelector, (templates) => {
    const createdByMap = new Map<number, TemplateCreatedByFilterItem>();
    templates.forEach((template) => {
      // Build created by filter
      let ownerIdToUse = template.ownerId;
      if (!userMap[template.ownerId]) {
        return;
      }
      if (createdByMap.has(ownerIdToUse)) {
        const createdByObj = createdByMap.get(ownerIdToUse);
        createdByObj.hitCount += 1;
        createdByMap.set(ownerIdToUse, createdByObj);
      } else {
        const newCreatedByObj = {
          value: userMap[template.ownerId] ? template.ownerId : TEMPLATE_NO_OWNER_ID,
          displayName: userMap[template.ownerId] ? userMap[template.ownerId] : TEMPLATE_NO_OWNER_NAME,
          hitCount: 1,
          key: TEMPLATE_CREATED_ITEM_FILTER_KEY,
          ownerType: 'owner',
        };
        createdByMap.set(ownerIdToUse, newCreatedByObj);
      }
    });

    let currentUser = createdByMap.get(currentUserId);
    if (currentUser) {
      currentUser.displayName = currentUser.displayName + ' (Me)';
      createdByMap.delete(currentUserId);
    } else {
      currentUser = {
        value: currentUserId,
        displayName: userMap[currentUserId] + ' (Me)',
        hitCount: 0,
        key: TEMPLATE_CREATED_ITEM_FILTER_KEY,
        ownerType: 'owner',
      } as TemplateCreatedByFilterItem;
    }

    let createdByMapValues = [...createdByMap.values()].sort((a, b) => {
      return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase());
    });
    if (currentUser) {
      createdByMapValues.unshift(currentUser);
    }
    return createdByMapValues;
  });

export const templateFilteredTeamMapSelector = (currentUserTeamId, teamMap: Team[]) =>
  createSelector(contentEntityArraySelector, (templates) => {
    const teamCreatedByMap = new Map<number, TeamTemplateCreatedByFilterItem>();
    let isMyTeam = false;
    let myTeam: TeamTemplateCreatedByFilterItem = undefined;
    teamMap.forEach((team: Team) => {
      const memberOwnerIds = team?.members?.map((user: User) => user.userId);
      const templatesByTeam = templates.filter((template) => {
        return memberOwnerIds.includes(template.ownerId) && template.scope === 3;
      });
      let teamIdToUse = team.teamId;
      isMyTeam = teamIdToUse === currentUserTeamId;
      let templateTeamMembers = [];
      templatesByTeam.forEach((template) => {
        if (!templateTeamMembers.includes(template.ownerId)) templateTeamMembers.push(template.ownerId);
      });
      const newCreatedByObj = {
        value: team?.teamId,
        members: templateTeamMembers,
        displayName: isMyTeam ? team.name + ' (My Team)' : team.name,
        hitCount: templatesByTeam?.length || 0,
        key: TEMPLATE_CREATED_ITEM_FILTER_KEY,
        ownerType: 'team',
      };
      if (isMyTeam) {
        myTeam = newCreatedByObj;
      } else {
        teamCreatedByMap.set(teamIdToUse, newCreatedByObj);
      }
    });
    let teamCreatedByMapValues = [...teamCreatedByMap.values()].sort((a, b) => {
      return a.displayName.toLowerCase().localeCompare(b.displayName.toLowerCase());
    });
    if (myTeam) {
      teamCreatedByMapValues.unshift(myTeam);
    }
    return teamCreatedByMapValues;
  });

export const defaultOwnerFilterSelector = (createdById, createdByTeamId, userMap, teamMap) => {
  const defaultOwnerTeamMap: { [key: string]: TeamTemplateCreatedByFilterItem } = {};

  const noOwnerObj = {
    value: TEMPLATE_NO_OWNER_ID,
    members: [],
    displayName: TEMPLATE_NO_OWNER_NAME,
    hitCount: 0,
    key: TEMPLATE_CREATED_ITEM_FILTER_KEY,
    ownerType: 'owner',
  };
  defaultOwnerTeamMap['No Owner'] = noOwnerObj;

  const meObj = {
    value: createdById,
    members: [],
    displayName: userMap[createdById] + ' (Me)',
    hitCount: 0,
    key: TEMPLATE_CREATED_ITEM_FILTER_KEY,
    ownerType: 'owner',
  };
  defaultOwnerTeamMap.Me = meObj;

  defaultOwnerTeamMap['My Team'] = { value: '', displayName: 'My Team' } as TeamTemplateCreatedByFilterItem;

  let myTeam: Team;

  if (!!createdByTeamId) {
    myTeam = teamMap?.find((team: Team) => {
      return team?.teamId === createdByTeamId;
    });
    if (!myTeam) {
      return;
    }
    defaultOwnerTeamMap['My Team'] = {
      value: myTeam?.teamId,
      members: myTeam?.members?.map((member) => member.userId),
      displayName: myTeam.name + '(My Team)',
      hitCount: 0,
      key: TEMPLATE_CREATED_ITEM_FILTER_KEY,
      ownerType: 'team',
    };
  }

  return defaultOwnerTeamMap;
};

export const ownerContentEntitySelector = createSelector(contentEntitySelector, (content: ContentEntityState) =>
  content.ids.map((id) => content.entities[id]).filter((template) => template.scope === 1),
);

export const idFilterContentEntitySelector = (id) => createSelector(contentEntitySelector, (content: ContentEntityState) => content.entities[id]);

export const orgContentEntitySelector = createSelector(contentEntitySelector, (content: ContentEntityState) =>
  content.ids.map((id) => content.entities[id]).filter((template) => template.scope === 3),
);

export const contentEntityDetailsSelector = (requestedTemplateId) =>
  createSelector(contentEntitySelector, (content: ContentEntityState) => {
    return content.entities ? content.entities[requestedTemplateId] : '';
  });

export const contentStatisticsArraySelector = createSelector(contentStatisticsSelector, (content: ContentStatisticsState) =>
  content.templateIds.map((templateId) => content.entities[templateId]),
);

export const getContentStatisticsByTemplateId = (requestedTemplateId) =>
  createSelector(contentStatisticsSelector, (contentStatistics: ContentStatisticsState) => {
    const index = contentStatistics.templateIds.find((templateId) => templateId === requestedTemplateId);
    return contentStatistics.entities[index];
  });

// Content Tiles selectors
export const contentTilesSelector = createSelector(contentFeatureSelector, (state) => state.tiles);

export const contentTilesArraySelector = createSelector(contentTilesSelector, (content: ContentTilesState) =>
  content.templateIds.map((templateId) => content.entities[templateId]),
);

export const getContentTilesByTemplateId = (requestedTemplateId) =>
  createSelector(contentTilesSelector, (contentTiles: ContentTilesState) => {
    const index = contentTiles.templateIds.find((templateId) => templateId === requestedTemplateId);
    return contentTiles.entities[index];
  });

export const contentFilterByNameSelector = (filterName) => createSelector(contentFilterSelector, (contentFilters) => contentFilters.query[filterName]);

export const contentFilterByTag = contentFilterByNameSelector('tags');
export const contentFilterBySalesflow = contentFilterByNameSelector('salesflow');

export const contentMemoryFilters = createSelector(contentFilterSelector, (contentFilters) =>
  _.values(contentFilters.memFilter).reduce((a, b) => a.concat(b), []),
);
