export enum DoziFeature {
  orgCharts = 'orgCharts',
  scoops = 'scoops',
  news = 'news',
  scoopsNews = 'scoopsNews',
  technologies = 'technologies',
  attributes = 'attributes',
  technologiesAttributes = 'technologiesAttributes',
  contactUs = 'contactUs',
  replace = 'replace',
  intents = 'intent',
  none = '',
}
