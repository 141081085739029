/**
 * Note: Please add events in alphabetical order and include prefix of page module
 */
export enum AnalyticsEvent {
  ACCOUNT_OPEN_CREATE_NOTE_DIALOG = 'ClickedAddAccountNote',
  ACCOUNT_NEW_NOTE_CREATED = 'SavedNewAccountNote',
  ACCOUNT_NOTE_UPDATED = 'EditedAccountNote',
  ACCOUNT_NOTE_DELETED = 'DeletedAccountNote',
  ACCOUNT_CREATED_UPDATED = 'CreatedOrUpdatedAccount',
  ACCOUNT_DELETE = 'DeleteAccounts',
  ACCOUNT_DETAILS_STAGE_CHANGE = 'ChangedAccountStage',
  ACCOUNT_DETAILS_VIEWED_ACCOUNT_TAB = 'ViewedAccountTab',
  ACCOUNTS_LANDING_APPLIED_SEARCH_FILTER = 'EngageAppliedSearchFilter',
  ACCOUNTS_LANDING_TOGGLE_FILTER_PANEL = 'ClickedShowOrHideFilters',
  ACCOUNTS_LANDING_SCOPE_TOGGLE = 'ClickedShowAccounts',
  ACCOUNTS_LANDING_CARD_ACTION_MENU = 'ClickedAccountCardActionMenu',
  ACCOUNTS_SORTED = 'SortedAccounts',
  ADDED_ABTEST_TO_SALESFLOW_STEP = 'AddedABTestToSalesflowStep',
  ADDED_EMAIL_TO_SEND_ON_BEHALF_OF = 'AddedEmailToSendOnBehalfOf',
  ADMIN_USER_STATS_DOWNLOAD_CSV = 'UserStatsDownloadedCSV',
  ANALYTICS_EMAILS_CLICKED_IMPORT_MORE = 'AnalyticsEmailsClickedImportMore',
  ANALYTICS_EMAILS_CLICKED_SEND_EMAIL = 'AnalyticsEmailsClickedSendEmail',
  ANALYTICS_REPORT_CLICKED = 'AnalyticsReportClicked',
  ANALYTICS_REPORT_CLICKED_EXPORT = 'AnalyticsReportClickedExport',
  ANALYTICS_REPORT_COMPLETED_EXPORT = 'AnalyticsReportCompletedExport',
  ANALYTICS_REPORT_CHANGED_DASH_FILTER_TOGGLE = 'AnalyticsReportChangedDashboardFilterToggle',
  ANALYTICS_REPORT_DASH_APPLIED_FILTER = 'AnalyticsReportDashboardAppliedFilter',
  APPLICATION_RELOAD_REQUEST = 'ApplicationReloadRequest',
  ASSOCIATED_CONTACTS_TO_ACCOUNT = 'AssociatedContactsToAccount',
  CALLED_CONTACT = 'CalledContact',
  CANCELED_TRUSTED_CALLING_FORM = 'CanceledTrustedCallingForm',
  CHANGED_QUOTA = 'ChangedQuota',
  CLEARED_ALL_FILTERS = 'ClearedAllFilters',
  CLICKED_LOG_CALL = 'ClickedLogCall',
  CLICKED_NEW = 'ClickedNew',
  CLICKED_BUTTON_IN_LOG_CALL = 'ClickedButtonInLogCall',
  CLICKED_CONTACT_CARD_ACTION_MENU = 'ClickedContactCardActionMenu',
  CLICKED_REGISTER_TRUSTED_CALLING = 'ClickedRegisterTrustedCalling',
  CONFRIMED_TEMPLATES_FOR_DELETION = 'ConfirmedTemplatesDeletion',
  CONTACT_REMOVED_QUICK_FILTER = 'RemovedQuickFilter',
  CONTACTS_ENGAGE_APPLIED_SEARCH_FILTER = 'EngageAppliedSearchFilter',
  CONVERSATION_OCCURED = 'ConversationsOccured',
  CREATED_CONTACT = 'CreatedContact',
  CREATED_FOLLOW_UP_TASK = 'CreatedFollowUpTask',
  CREATED_TASK = 'CreatedTask',
  DIALER_ERROR = 'DialerError',
  EDITED_TASK = 'EditedTask',
  EMAILER_ERROR = 'EmailerError',
  EMAILER_ICON_CLICKED = 'EmailerIconClicked',
  ERROR_OCCURRED_IN_EVENT = 'ErrorOccurredInEvent',
  FAILED_TO_SEND_EVENT = 'FailedToSendEvent',
  IMPORTED_ACCOUNTS_START_IMPORT = 'ImportedAccounts_StartImport',
  IMPORTED_ACCOUNTS_UPLOADED_FILE = 'ImportedAccounts_UploadedFile',
  IMPORTED_ACCOUNTS_MAPPED_FIELDS = 'ImportedAccounts_MappedFields',
  IMPORTED_ACCOUNTS_IMPORTED_SETTINGS = 'ImportedAccounts_ImportedSettings',
  IMPORTED_ACCOUNTS_IMPORT_ACCOUNTS = 'ImportedAccounts',
  IMPORTED_CONTACTS = 'ImportedContacts',
  LOGGED_IN = 'LoggedIn',
  LOGGED_INTO_ENGAGE = 'LoggedInToEngage',
  MARK_TASK_AS_COMPLETE = 'MarkedTaskAsComplete',
  OPENED_EMAIL_EDITOR = 'OpenedEmailEditor',
  OPENED_PAGE = 'OpenedPage',
  PROVISIONED_DIALER_USER = 'ProvisionedDialerUser',
  SALESFLOW_CONTACTS_TAB_CONTACTS_SEARCH = 'SearchedEngageContacts',
  SELECTED_TEMPLATES_FOR_DELETION = 'SelectedTemplatesDeletion',
  SELECTED_VALUE_LOG_CALL = 'SelectedValueLogCall',
  SELECTED_VIEW_SORT_DROPDOWN = 'SelectedViewSortDropdown',
  SUBMITTED_TRUSTED_CALLING_FORM = 'SubmittedTrustedCallingForm',
  TAGGED_CONTACT = 'TaggedContact',
  TOGGLED_ABTEST_TEMPLATE = 'ToggledABTestTemplate',
  VERIFIED_PHONE_NUMBER_FOR_CALLS = 'VerifiedPhoneNumberForCalls',
  VIEWED_EMAIL_ASSISTANT = 'ViewedEmailAssistant',
  NOTIFICATION_CLICKED = 'FeedNotificationClicked',
  CLICKED_VIEW_ACTION = 'ClickedViewAction',
}
