export const ContactActionsNames = {
  AddTag: 'Add Tag',
  AddToSalesflow: 'Add to Salesflow',
  AssociateToAccount: 'Associate to Account',
  LinkedIn: 'LinkedIn',
  Unsubscribe: 'Unsubscribe',
  SubscriptionSettings: 'Subscription Settings',
  CRMRecord: 'CRM Record',
  CRM: 'CRM',
  NewCrmTask: 'New CRM Task',
  AddOrRemoveTag: 'Add/Remove Tag',
  Delete: 'Delete',
  Email: 'Email',
  Call: 'Call',
  DownloadCSV: 'Download CSV',
  AssignTask: 'Assign Task',
  GoToEngage: 'Engage',
  GoToEngageEmail: 'Email',
  GoToEngagePhone: 'Call',
  Skip: 'Skip',
  Stop: 'Remove from salesflow',
  Resume: 'Resume',
  Pause: 'Pause',
  MarkAsComplete: 'Mark as completed',
  AddNewTask: 'Add New Task',
  Remove: 'Remove',
  SalesforceRecord: 'Open in Salesforce',
  Replace: 'Replace',
  ChangeStatus: 'Change Status',
  ChangeOwner: 'Change Owner',
  LogACall: 'Log a Call',
  EmailReply: 'Reply',
  SendNow: 'Send Now',
  AddToTalentflow: 'Add to Talentflow',
};

export interface ContactAction {
  displayName: string;
  condition: boolean;
  disabled: boolean;
}
