import { createAction, props } from '@ngrx/store';

import { FilterCategory, FilterEntity, Scope } from '@app/core/enums/engage-filter.enum';
import { FilterNode } from '@app/core/data-model/filter-node.model';
import { SearchByFilterLeafsRequest } from '@app/core/http-model/request/engage-filter.request.model';
import { QuickFilter } from '@app/core/data-model/quick-filter.model';
import { UserConfigurationKey } from '@app/core/enums/user-configuration.enum';
import { CustomViewAction } from '@app/common/service/quick-filters/quick-filters.config';

/**
 * Action: LoadEngageFilterNodesAction
 *
 * Makes an API call to get a list of nodes. Nodes can either be a "Node" or a "Leaf"("Leaf" is the same as "Chip").
 * See engage-filter.enum.ts for node type(called FilterType in enum).
 *
 * FilterCategory - is the area(or the page) of the app. For example, this could be the contacts page or the task page. Depending
 * on the area, we need to send the corect FilterCategory so that it will bring back the correct nodes for that page.
 *
 * FilterEntity - is the actual filter type for each FilterCategory. For example, this could be contact categories, contacts with saelsflow,
 * tasks with tags, and etc.
 *
 * Scope - is either "Owner" or "Organization". "Onwer" will bring back results that are yours and "Organization" will bring back all
 * non-private results.
 *
 * NOTE:
 * Not all reponses are a list of Nodes that are nodes. In some cases, the reponse can be a list of Nodes that are leaves. For example,
 * contact-contactFields-owner(FilterCategory-FilterEntity-Scope) will bring back a list of Nodes that are nodes where each Node contains
 * a list of Nodes that are leaves where as contact-tags-owner will bring back a list of Nodes that are leaves.
 *
 */
export const LoadEngageFilterNodesAction = createAction(
  '[Engage filter] entity/load_nodes',
  props<{ selectedCategory: FilterCategory; selectedEntity: FilterEntity; selectedScope: Scope; parentId?: number }>(),
);

export const LoadEngageFilterNodesSuccessAction = createAction(
  '[Engage filter] entity/load_nodes/success',
  props<{ filterNodes: FilterNode[]; selectedEntity: FilterEntity; selectedCategory: FilterCategory; selectedScope: Scope }>(),
);

export const LoadEngageFilterNodesFailureAction = createAction(
  '[Engage filter] entity/load_nodes/failure',
  props<{ selectedEntity: FilterEntity; selectedCategory: FilterCategory; error: any }>(),
);

/**
 * Action - LoadEngageFilterResultsAction
 *
 * Based on the Entity, Scope, and a list of Chips, it brings back the filter result.
 */
export const LoadEngageFilterResultsAction = createAction(
  '[Engage filter] entity/load_results',
  props<{ resource: SearchByFilterLeafsRequest; requestId: number }>(),
);

export const LoadEngageFilterResultFailureAction = createAction('[Engage filter] entity/load_results/failure', props<{ error: any }>());

/*
 QuickFilters actions
*/
export const LoadQuickFiltersAction = createAction(
  '[Engage filter] entity/load_quick_filters',
  props<{ key: UserConfigurationKey; selectedCategory: FilterCategory; selectedEntity: FilterEntity }>(),
);

export const LoadQuickFiltersSuccessAction = createAction(
  '[Engage filter] entity/load_quick_filters/success',
  props<{ selectedCategory: FilterCategory; selectedEntity: FilterEntity; quickFilters: QuickFilter[] }>(),
);

export const LoadQuickFiltersFailureAction = createAction(
  '[Engage filter] entity/load_quick_filters/failure',
  props<{ key: UserConfigurationKey; selectedCategory: FilterCategory; selectedEntity: FilterEntity; error: any }>(),
);

export const AddQuickFilterAction = createAction(
  '[Engage filter] entity/add_quick_filter',
  props<{ key: UserConfigurationKey; filter: QuickFilter; allFilters: QuickFilter[] }>(),
);

export const RemoveQuickFilterAction = createAction(
  '[Engage filter] entity/remove_quick_filter',
  props<{ key: UserConfigurationKey; filter: QuickFilter; allFilters: QuickFilter[] }>(),
);

export const EditQuickFilterAction = createAction(
  '[Engage filter] entity/edit_quick_filter',
  props<{ key: UserConfigurationKey; filter: QuickFilter; allFilters: QuickFilter[] }>(),
);

export const EditLayoutQuickFilterAction = createAction(
  '[Engage filter] entity/quick_filter_edit_layout',
  props<{ filter: QuickFilter; allFilters: QuickFilter[] }>(),
);

export const UpdateQuickFilterAction = createAction(
  '[Engage filter] entity/update_quick_filter',
  props<{ key: UserConfigurationKey; quickFilters: QuickFilter[]; filterName: string; action: CustomViewAction }>(),
);

export const UpdateQuickFiltersSuccessAction = createAction(
  '[Engage filter] entity/load_quick_filters/success',
  props<{ key: UserConfigurationKey; quickFilters: QuickFilter[] }>(),
);

export const UpdateQuickFiltersFailureAction = createAction('[Engage filter] entity/load_quick_filters/failure', props<{ error: any }>());
