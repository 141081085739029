import { ChangeDetectorRef, EventEmitter, Input, OnChanges, OnDestroy, Output, PipeTransform, SimpleChanges, Directive } from '@angular/core';
import { AbstractControl, ControlValueAccessor } from '@angular/forms';
import { validationMessages } from '@zi-common/model/forms/validation-messages';

@Directive()
export abstract class AbstractFieldComponent<T> implements ControlValueAccessor, OnChanges, OnDestroy {
  public readonly NONE = 'None';

  @Input() label: string;
  @Input() individual = true;
  @Input() hasIcon = true;
  @Input() readOnly = true;
  @Input() formControl: AbstractControl;
  @Input() id?: string;
  @Input() displayPipe?: PipeTransform;
  @Input() editable = true;
  @Output() valueChange: EventEmitter<T> = new EventEmitter();
  @Output() fieldChange: EventEmitter<FieldChange> = new EventEmitter();
  @Output() readOnlyChange: EventEmitter<boolean> = new EventEmitter();
  public disabled: boolean;
  protected changeDetector?: ChangeDetectorRef;
  protected preEditValue: T;
  private innerValue: T;
  protected onChange: (event: any) => void = () => {};
  protected onTouched: () => void = () => {};

  get value(): T {
    return this.innerValue;
  }

  set value(value: T) {
    if (this.innerValue !== value) {
      this.innerValue = value;
      this.onChange(value);
    }
  }

  get hasError() {
    return this.formControl && this.formControl.invalid;
  }

  get error() {
    let message = validationMessages.generic;
    if (this.hasError) {
      const errorType = Object.keys(this.formControl.errors)[0];
      message = validationMessages[errorType] || message;
    }
    return message;
  }

  public get readonlyValue() {
    return this.value ? this.value.toString() : this.NONE;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.label && this.label) {
      this.label = this.label.toUpperCase();
    }
    if (changes.readOnly) {
      if (this.changeDetector) {
        this.changeDetector.detectChanges();
      }
    }
  }

  public ngOnDestroy(): void {
    this.changeDetector = null;
  }

  public edit() {
    if (this.editable) {
      this.toggleReadOnly();
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (this.changeDetector) {
      this.changeDetector.markForCheck();
    }
  }

  public writeValue(value: any): void {
    this.value = value;
    if (this.changeDetector) {
      this.changeDetector.detectChanges();
    }
  }

  protected toggleReadOnly() {
    this.readOnly = !this.readOnly;
    this.readOnlyChange.emit(this.readOnly);
    if (this.changeDetector) {
      this.changeDetector.detectChanges();
    }
  }
}

export class FieldChange {
  constructor(public field: string, public value: any, public fieldLabel?: string) {}
}
