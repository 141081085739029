import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ContactDetails, ContactDetailsTab } from '@zi-core/data-model/contact-details.model';
import { ContactState } from '../../contact.state';
import * as _ from 'lodash';
import { FilterSortOrder } from '@zi-core/enums/engage-filter.enum';

export enum UI_FILTER_TYPE {
  calls = 'Calls',
  history = 'History',
  type = 'Type',
  status = 'Status',
}

export enum SORT_OPTION_VALUE {
  sentDate = 'sentDate',
  lastActivityDate = 'lastActivityDate',
}

export interface SortOption {
  displayName: string;
  value: any;
}

// contact detail entity state
export class ContactDetailEntityState {
  ids: Array<number>;
  entities: { [id: string]: ContactDetails };
  correlationId: number;
  loaded: boolean;
  loading: boolean;
  initialTab: ContactDetailsTab;
  // TODO better object for error
  error?: string;
  uiFilter: {
    [UI_FILTER_TYPE.calls]: string[];
    [UI_FILTER_TYPE.history]: string[];
    [UI_FILTER_TYPE.type]: string[];
    [UI_FILTER_TYPE.status]: string[];
  };
  uiSort: {
    EmailsOption: SortOption;
    EmailsSort: string;
  };
}

// initial state of Entities
export const initialContactDetailEntityState: ContactDetailEntityState = {
  ids: [],
  entities: {},
  correlationId: 0,
  loaded: false,
  loading: false,
  initialTab: ContactDetailsTab.History,
  uiFilter: {
    [UI_FILTER_TYPE.calls]: ['all'],
    [UI_FILTER_TYPE.history]: ['all'],
    [UI_FILTER_TYPE.type]: ['all'],
    [UI_FILTER_TYPE.status]: ['all'],
  },
  uiSort: {
    EmailsOption: { displayName: 'Sent Date', value: SORT_OPTION_VALUE.sentDate },
    EmailsSort: FilterSortOrder.Descending,
  },
};

export const contactFeatureSelector = createFeatureSelector<ContactState>('contact');

export const contactDetailSelector = createSelector(contactFeatureSelector, (state) => _.get(state, 'details'));

export const getContactDetailExtendedFields = (contactId) =>
  createSelector(contactDetailSelector, (state) => _.get(state, `entities[${contactId}].contact`, null));

export const contactTaskDetailSelector = (contactId) => createSelector(contactDetailSelector, (state) => _.get(state.entities[contactId], 'tasks', null));
// (state) => state.entities[contactId].tasks);

export const contactEventDetailsSelector = (contactId) => createSelector(contactDetailSelector, (state) => _.get(state.entities[contactId], 'events', null));
// (state) => state.entities[contactId].events);

export const contactInvitationDetailsSelector = (contactId) =>
  createSelector(contactDetailSelector, (state) => _.get(state.entities[contactId], 'invitations', null));
// (state) => state.entities[contactId].invitations);

export const contactSalesflowDetailsSelector = (contactId) =>
  createSelector(contactDetailSelector, (state) => _.get(state.entities[contactId], 'salesflows', null));
// (state) => state.entities[contactId].salesflows);

export const contactPhoneCallDetailsSelector = (contactId) =>
  createSelector(contactDetailSelector, (state) => _.get(state.entities[contactId], 'phoneCalls', null));
// (state) => state.entities[contactId].phoneCalls);

export const contactNoteDetailsSelector = (contactId) => createSelector(contactDetailSelector, (state) => _.get(state.entities[contactId], 'notes', null));
// (state) => state.entities[contactId].notes);

export const uiFilterCalls = createSelector(contactDetailSelector, (state) => state.uiFilter.Calls);

export const uiFilterHistory = createSelector(contactDetailSelector, (state) => state.uiFilter.History);

export const uiFilterTask = createSelector(contactDetailSelector, (state) => {
  return { status: state.uiFilter.Status, type: state.uiFilter.Type };
});

export const uiEmailSortOptions = createSelector(contactDetailSelector, (state) => state.uiSort.EmailsOption);

export const uiEmailSortDirection = createSelector(contactDetailSelector, (state) => state.uiSort.EmailsSort);

export const contactDetailsOpenedTab = createSelector(contactDetailSelector, (state) => state.initialTab);

export const contactInvitationEventsSummarySelector = (contactId) =>
  createSelector(contactDetailSelector, (state) => _.get(state.entities[contactId], 'invitationEventsSummary', null));

// contact entity as orders array
/* export const contactEntityArraySelector = createSelector(contactDetailSelector, (contactDetail: ContactDetailEntityState) => {

}); */
