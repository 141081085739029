import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CategoryMappings, Scoop, ScoopDataMapping, ScoopFilterQuery, ScoopMapping, ScoopsMappingResponse, ScoopsResponse } from '@app/common/interface/scoops';
import { environment } from '@env/environment';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScoopsService {
  private topicsMapping: ScoopDataMapping;
  private typesMapping: ScoopDataMapping;

  constructor(private httpClient: HttpClient) {}

  getScoops(companyId: number): Observable<Scoop[]> {
    return this.httpClient.get<ScoopsResponse>(`${environment.engage_backend_url}/data/scoop/${companyId}`).pipe(map((res: ScoopsResponse) => res.scoops));
  }

  getScoopsFromFilter(scoopFilterQuery: ScoopFilterQuery): Observable<Scoop[]> {
    return this.httpClient.post(`${environment.engage_backend_url}/data/scoop`, scoopFilterQuery).pipe(map((res: ScoopsResponse) => res.scoops));
  }

  loadScoopsMapping(): Observable<ScoopsMappingResponse> {
    return this.httpClient.get<ScoopsMappingResponse>(`${environment.engage_backend_url}/mapping/scoop`).pipe(
      tap((res: ScoopsMappingResponse) => {
        this.topicsMapping = this.buildTopicMapping(res.topics);
        this.typesMapping = this.buildTopicMapping(res.types);
      }),
    );
  }

  getTopicsMapping(): ScoopDataMapping {
    return this.topicsMapping;
  }

  getTypesMapping(): ScoopDataMapping {
    return this.typesMapping;
  }

  /**
   * Will replace variable constants in the scoop description and return the result
   * @param scoop
   */
  setPersonsLinksInDescription(scoop: Scoop): string {
    let description: string = _.get(scoop, 'description', '');
    const personRecords = _.get(scoop, 'personRecords', []);

    personRecords.forEach((personRecord) => {
      const fullName = `${personRecord.firstName} ${personRecord.lastName}`;
      const personId: number = personRecord.personId;
      description = description.replace(
        `&CONTACT_${personId}`,
        `<a href="${environment.zoominfo_app}/#/apps/profile/person/${personId}" class="person-link" target="_blank">${fullName}</a>`,
      );
    });

    return description;
  }

  private buildTopicMapping(categoryMappings: CategoryMappings[]): ScoopDataMapping {
    const scoopsDataMapping: ScoopDataMapping = {};
    categoryMappings.forEach((cMappings) => {
      const scopeMappings: ScoopMapping[][] = Object.values(cMappings);

      scopeMappings.forEach((sMappings) => {
        sMappings.forEach((scopeMapping) => {
          scoopsDataMapping[scopeMapping.id] = scopeMapping.displayName;
        });
      });
    });

    return scoopsDataMapping;
  }
}
