import { SortSection, SortOption } from '@app/common/model/dropdown/select.model';

export enum PermissionEnum {
  ADMIN_ACCESS = 1,
  CONTACT_MANAGEMENT_VIEW_CONTACTS = 10,
  CONTACT_MANAGEMENT_DELETE_CONTACTS = 11,
  SET_CONTACTS_TO_OPT_OUT = 12,
  ACCOUNT_MANAGEMENT_VIEW_ACCOUNTS = 20,
  CONTENT_MANAGEMENT_TEMPLATE_SHARED_VIEW = 30,
  CONTENT_MANAGEMENT_TEMPLATE_PRIVATE_VIEW = 31,
  CONTENT_MANAGEMENT_TEMPLATE_EDIT = 32,
  CONTENT_MANAGEMENT_TEMPLATE_CREATE = 33,
  CONTENT_MANAGEMENT_TEMPLATE_DELETE = 34,
  EMAIL_TEMPLATE_SHARE_PERMISSION = 35,
  CONTENT_MANAGEMENT_SALESFLOWS_SHARED_VIEW = 40,
  CONTENT_MANAGEMENT_SALESFLOWS_PRIVATE_VIEW = 41,
  CONTENT_MANAGEMENT_SALESFLOWS_CREATE = 42,
  CONTENT_MANAGEMENT_SALESFLOWS_DELETE = 43,
  CONTENT_MANAGEMENT_SALESFLOWS_EDIT = 44,
  SALESFLOW_SHARE_PERMISSION = 45,
  IMPORT_CONTACTS_CSV = 50,
  IMPORT_CONTACTS_DOZI = 51,
  IMPORT_CONTACTS_CRM = 52,
}

export enum CategoryProfile {
  ADMIN = 'Admin Management',
  CONTACT_MANAGEMENT = 'Contact Management',
  ACCOUNT_MANAGEMENT = 'Account Management',
  CONTENT_MANAGEMENT_SALESFLOW = 'Content Management - Salesflows',
  CONTENT_MANAGEMENT_TEMPLATE = 'Content Management - Email Templates',
  IMPORT_CONTACTS = 'Import Contacts',
}

export enum ProfilesEnum {
  ADMIN = 1,
  CONTENT_MANAGER = 2,
  SALES_MANAGER = 3,
  BASIC = 4,
}

export enum ProfileTypeEnum {
  SYSTEM = 0,
  CUSTOM = 1,
}

export const ProfileAndPermissionsConfig = {
  MAX_PROFILES_IN_ORG: 50,
};

export enum CreationMethodsEnum {
  EXIST = 1,
  BLANK = 0,
}

export const ProfilesPermissions = [
  {
    id: ProfilesEnum.ADMIN,
  },
  {
    id: ProfilesEnum.CONTENT_MANAGER,
  },
  {
    id: ProfilesEnum.SALES_MANAGER,
  },
  {
    id: ProfilesEnum.BASIC,
  },
];

export const enum AdminProfilesSort {
  USER_NAME = 'User Name',
  TITLE = 'Title',
  TEAM = 'Team',
  LAST_ADDED = 'Last Added',
  LAST_UPDATED = 'Last Updated',
}

export const RolesAndPermissionsSortOptions: SortSection[] = [
  {
    displayName: '',
    options: [
      new SortOption('User Name', AdminProfilesSort.USER_NAME),
      new SortOption('Title', AdminProfilesSort.TITLE),
      new SortOption('Team', AdminProfilesSort.TEAM),
      new SortOption('Last Added', AdminProfilesSort.LAST_ADDED),
      new SortOption('Last Updated', AdminProfilesSort.LAST_UPDATED),
    ],
  },
];

export const enum SystemProfiles {
  NONE = 0,
  ADMIN = 1,
  CONTENT_MANAGER = 2,
  SALES_MANAGER = 3,
  BASIC = 4,
}

export const FirstCustomProfileId = 5;

export interface UserInProfilePermissions {
  name: string;
  teamId: number;
  email: string;
  userId: number;
  title: string;
  createdAt: Date;
  modifiedAt: Date;
  roleId: number;
}
