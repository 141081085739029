import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import {
  DeleteSmsMessageAction,
  DeleteSmsMessageFailureAction,
  DeleteSmsMessageSuccessAction,
  GetSmsConversationByContactMobileAction,
  GetSmsConversationByContactMobileFailureAction,
  GetSmsConversationByContactMobileSuccessAction,
  LoadSmsConversationsForUserAction,
  LoadSmsConversationsForUserFailureAction,
  LoadSmsConversationsForUserSuccessAction,
  ReceiveSendStatusCallbackAction,
  SearchSmsContactsAction,
  SendSmsMessageAction,
  SendSmsMessageFailureAction,
  SendSmsMessageSuccessAction,
  UpdateIsReadForConversationAction,
  UpdateIsReadForConversationFailureAction,
  UpdateIsReadForConversationSuccessAction,
} from '@zi-pages/sms/ngrx/action/sms-message.action';
import { SmsService } from '@zi-pages/sms/service/sms.service';
import { NotyService } from '@zi-common/service/noty/noty.service';
import { Message } from '@zi-common/model/message/message.model';
import { MessageType } from '@zi-common/model/message/message-type';
import { AnalyticsService } from '@app/core/service/analytics.service';
import { SmsMessageStatus } from '@zi-pages/sms/model/sms-message-status';
import { smsMessageLoadedConvoSelector } from '@zi-pages/sms/ngrx/state/sms-message.state';
import { ApplicationState } from '@app/reducers';
import { Store } from '@ngrx/store';
import { SharpyErrorTypesEnum } from '@zi-common/model/sharpy-error-types.enum';
import { LoggerServiceToken } from '@zi-core/config/logger-service.config';
import { ILoggerService } from '@zi-core/interface/logger.service.interface';

@Injectable()
export class SmsMessageEffect {
  constructor(
    private action$: Actions,
    private smsService: SmsService,
    private notyService: NotyService,
    private analyticsService: AnalyticsService,
    private _store: Store<ApplicationState>,
    @Inject(LoggerServiceToken) private loggerService: ILoggerService,
  ) {}

  LoadSmsConversationsForUserAction = createEffect(() =>
    this.action$.pipe(
      ofType(LoadSmsConversationsForUserAction),
      switchMap(({ pageNum, pageSize }) => {
        return this.smsService.retrieveConversationsForUser(pageNum, pageSize).pipe(
          map((resp) => {
            return LoadSmsConversationsForUserSuccessAction({ smsContactList: resp });
          }),
          catchError((err) => {
            this.loggerService.error('Error loading SMS conversations for user :: ', err);
            this.notyService.postMessage(new Message(MessageType.ERROR, 'Error loading SMS Conversations for user. Please reload and try again.'));
            return of(LoadSmsConversationsForUserFailureAction({ error: err }));
          }),
        );
      }),
    ),
  );

  GetSmsConversationByContactMobileAction$ = createEffect(() =>
    this.action$.pipe(
      ofType(GetSmsConversationByContactMobileAction),
      switchMap(({ contactMobileNumber, contactInfo, pageNum, pageSize }) => {
        return this.smsService
          .retrieveConversationForContactByMobile(contactMobileNumber, pageNum, pageSize)
          .pipe(map((resp) => GetSmsConversationByContactMobileSuccessAction({ conversationResp: resp, contactInfo })));
      }),
      catchError((err) => {
        this.loggerService.error('Error getting SMS Conversation for Contact :: ', err);
        return of(GetSmsConversationByContactMobileFailureAction({ error: err }));
      }),
    ),
  );

  UpdateIsReadForConversationAction$ = createEffect(() =>
    this.action$.pipe(
      ofType(UpdateIsReadForConversationAction),
      switchMap(({ mobilePhone }) => {
        return this.smsService.updateIsReadForContact(mobilePhone).pipe(map((resp) => UpdateIsReadForConversationSuccessAction({ totalReadMessages: resp })));
      }),
      catchError((err) => {
        this.loggerService.error('Error updating isRead for SMS Conversation for Contact');
        return of(UpdateIsReadForConversationFailureAction());
      }),
    ),
  );

  SearchSmsContactInfoAction$ = createEffect(() =>
    this.action$.pipe(
      ofType(SearchSmsContactsAction),
      switchMap(({ data }) => {
        return this.smsService.searchContactsThroughSms(data).pipe(
          map((resp) => {
            return LoadSmsConversationsForUserSuccessAction({ smsContactList: resp });
          }),
          catchError((err) => {
            this.loggerService.error('Error loading SMS conversations for user :: ', err);
            this.notyService.postMessage(new Message(MessageType.ERROR, 'Error loading SMS Conversations for user. Please reload and try again.'));
            return of(LoadSmsConversationsForUserFailureAction({ error: err }));
          }),
        );
      }),
    ),
  );

  SendSmsMessageAction$ = createEffect(() =>
    this.action$.pipe(
      ofType(SendSmsMessageAction),
      switchMap(({ toNumber, messageBody, contact, resendMessage, isFirstMessage }) => {
        return this.smsService.sendMessage(toNumber, messageBody, resendMessage?.trackingId, contact?.id).pipe(
          map((resp) => {
            if (!resp || Object.keys(resp).length === 0) {
              this.notyService.postMessage(
                new Message(
                  MessageType.ERROR,
                  'Error sending SMS Message to this number. It is possible it is an invalid number. ' + 'If problem persists, please contact support.',
                ),
              );
              this.analyticsService.send('SMS_SentMessage', {
                ContactId: contact?.id?.toString(),
                TrackingId: resendMessage?.trackingId,
                Status: SmsMessageStatus.FAILED,
                Message: messageBody,
                UserPhoneNumber: resendMessage?.fromNumber,
                ContactPhoneNumber: toNumber,
                IsFirstMessage: isFirstMessage,
                IsRetry: resendMessage != null,
              });
              return SendSmsMessageFailureAction({ error: 'Invalid SMS response' });
            }
            return SendSmsMessageSuccessAction({
              sendMessageResp: resp,
              contact,
              trackingIdForResend: resendMessage?.trackingId,
              resendOriginalSendAt: resendMessage?.sentAt,
            });
          }),
          catchError((err) => {
            this.loggerService.error('Error sending SMS Message :: ', err);
            this.analyticsService.send('SMS_SentMessage', {
              ContactId: contact?.id?.toString(),
              TrackingId: resendMessage?.trackingId,
              Status: SmsMessageStatus.FAILED,
              Message: messageBody,
              UserPhoneNumber: resendMessage?.fromNumber,
              ContactPhoneNumber: toNumber,
              IsFirstMessage: isFirstMessage,
              IsRetry: resendMessage != null,
            });
            let errorMessage = '';
            if (err?.status === 403 && err?.error?.ResponseStatus?.ErrorCode === SharpyErrorTypesEnum.FORBIDDEN) {
              errorMessage = err.error.ResponseStatus.Message;
            } else if (new RegExp('not a valid phone number').test(err?.error?.ResponseStatus?.Message)) {
              errorMessage = 'Invalid phone number.';
            }
            const errorMsg = `Error sending SMS Message${errorMessage.length > 0 ? ': ' + errorMessage : '.'}`;
            this.notyService.postMessage(new Message(MessageType.ERROR, errorMsg));
            return of(SendSmsMessageFailureAction({ error: err, resendMessage }));
          }),
        );
      }),
    ),
  );

  DeleteSmsMessageAction$ = createEffect(() =>
    this.action$.pipe(
      ofType(DeleteSmsMessageAction),
      switchMap(({ messageToDelete }) => {
        return this.smsService.deleteMessage(messageToDelete.trackingId).pipe(
          map((resp) => DeleteSmsMessageSuccessAction({ messageToDelete })),
          catchError((err) => {
            this.loggerService.error('Error deleting SMS Message :: ', err);
            this.notyService.postMessage(new Message(MessageType.ERROR, 'Error deleting SMS Message'));
            return of(DeleteSmsMessageFailureAction({ messageToDelete }));
          }),
        );
      }),
    ),
  );

  ReceiveSendStatusCallbackAction$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(ReceiveSendStatusCallbackAction),
        withLatestFrom(this._store.select(smsMessageLoadedConvoSelector)),
        map(([action, convo]) => {
          const { data } = action;
          const convoMap = convo?.smsConvoDateMap;
          const sentAtDate = new Date(data.SentAt);
          const dayMonYear = new Date(sentAtDate.getFullYear(), sentAtDate.getMonth(), sentAtDate.getDate()).toISOString();
          if (convoMap && convoMap[dayMonYear]) {
            const convoOnDay = [...convoMap[dayMonYear]];
            const msg = convoOnDay.find((item) => {
              return item.trackingId === data.TrackingId;
            });
            const status = data.Status?.toLowerCase();
            if (msg && (status === SmsMessageStatus.DELIVERED || status === SmsMessageStatus.FAILED || status === SmsMessageStatus.UNDELIVERED)) {
              this.analyticsService.send('SMS_SentMessage', {
                ContactId: data?.Contact?.Id?.toString(),
                TrackingId: data?.TrackingId,
                Status: data?.Status?.toLowerCase(),
                Message: data?.Body,
                UserPhoneNumber: msg.fromNumber,
                ContactPhoneNumber: data?.ToPhone,
                IsFirstMessage: Object.keys(convoMap).length === 1 && convoOnDay.length === 1,
              });
            }
          }
        }),
      ),
    { dispatch: false },
  );
}
