import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from '@zi-core/guard/login.guard';
import { AuthenticationGuard } from './core/guard/authentication.guard';
import { DevModeGuard } from './core/guard/dev-mode.guard';
import { UrlService } from '@zi-core/service/url.service';
import { OnBoardingGuard } from '@zi-core/guard/on-boarding.guard';
import { PreventExtensionEnterOnBoardingGuard } from '@zi-core/guard/prevent-extension-enter-on-boarding.guard';
import { MultiFactorAuthJwtGuard } from './core/guard/multi-factor-auth-jwt.guard';
import { ExtensionOnboardingSetupComponent } from '@app/extension-onboarding-setup/extension-onboarding-setup.component';
import { AppCustomPreloadingStrategy } from '@app/custom-preloading-strategy';
import { OutlookExtensionComponent } from '@app/outlook-extension/outlook-extension.component';

const routes: Routes = [
  {
    path: 'app',
    loadChildren: () => import('./pages/pages.module').then((m) => m.PagesModule),
    canActivate: [AuthenticationGuard, OnBoardingGuard],
  },
  {
    path: 'on-boarding',
    loadChildren: () => import('./pages/on-boarding/on-boarding.module').then((m) => m.OnBoardingModule),
    canActivate: [AuthenticationGuard, PreventExtensionEnterOnBoardingGuard],
    data: {
      show_navigation: false,
    },
  },
  {
    path: 'engageext',
    loadChildren: () => import('./extension/extension.module').then((m) => m.ExtensionModule),
    canActivate: [AuthenticationGuard, OnBoardingGuard],
  },
  {
    path: 'gmailplugin',
    loadChildren: () => import('./gmail-plugin/gmail-plugin.module').then((m) => m.GmailPluginModule),
    canActivate: [AuthenticationGuard, OnBoardingGuard],
  },
  {
    path: 'ext-setup',
    pathMatch: 'full',
    component: ExtensionOnboardingSetupComponent,
  },
  {
    path: 'outlookext',
    pathMatch: 'full',
    component: OutlookExtensionComponent,
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'resetPassword',
    loadChildren: () => import('./newuser-password-reset/newuser-password-reset.module').then((m) => m.NewuserPasswordResetModule),
    // canActivate: [LoginGuard]
  },
  {
    path: 'provisionMfa',
    loadChildren: () => import('./provision-mfa/provision-mfa.module').then((m) => m.ProvisionMfaModule),
    canActivate: [MultiFactorAuthJwtGuard],
  },
  {
    path: 'verifyOtp',
    loadChildren: () => import('./verify-otp/verify-otp.module').then((m) => m.VerifyOtpModule),
    canActivate: [MultiFactorAuthJwtGuard],
  },
  {
    path: 'unsubscribe',
    loadChildren: () => import('./unsubscribe-user/unsubscribe-user.module').then((m) => m.UnsubscribeUserModule),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'app',
  },
  {
    path: 'common-components',
    loadChildren: () => import('./common/common-routing.module').then((m) => m.CommonRoutingModule),
    canActivate: [AuthenticationGuard, DevModeGuard],
  },
];

@NgModule({
  /* UseHash: true has been removed from here since EngageLocationStrategy is
   provided in the providers, and that extends HashLocationStrategy. */
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: AppCustomPreloadingStrategy })],
  exports: [RouterModule],
  providers: [UrlService, AppCustomPreloadingStrategy],
})
export class AppRoutingModule {}
