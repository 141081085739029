import { ContactTaskFilterType } from '@app/core/enums/contact-task-filter-type.enum';
import { environment } from '@env/environment';
import { contactTasksEmailStatsEnum } from './model/simple-contact-task.model';
import { SalesWorkflowTemplateInsights } from '@zi-core/http-model/response/sales-workflow-template.response.model';

export const SalesFlowConfig = {
  TEMPLATE_STATUS: {
    PAUSED: { text: 'Paused', color: '#e83636' },
    ACTIVE: { text: 'Active', color: '#42bc56' },
  },
};

export const contactBulkActions = {
  SendNow: { text: 'Send Now', request: 'Send Now' },
  Stop: { text: 'Remove from salesflow', request: 'Stopped' },
  Pause: { text: 'Pause', request: 'Paused' },
  Resume: { text: 'Resume', request: 'WaitingOnTask' },
  Skip: { text: 'Skip', request: 'Skipped' },
  Unsubscribe: { text: 'Unsubscribe', request: 'Unsubscribe' },
  MarkAsComplete: { text: 'MarkAsComplete', request: 'Completed' },
  LinkedIn: { text: 'LinkedIn', request: 'LinkedIn' },
  StartNow: { text: 'Start now', request: 'Start now' },
  Cancel: { text: 'Cancel', request: 'Cancel' },
  Remove: { text: 'Remove', request: 'Remove' },
  Email: { text: 'Email', request: 'Email' },
  Call: { text: 'Email', request: 'Phone' },
  DownloadCSV: { text: 'Download CSV', request: 'Download CSV' },
  AssignTask: { text: 'Assign Task', request: 'Assign Task' },
  Tag: { text: 'Add/Remove Tag', request: 'Tag' },
};

export const bulkActions = [
  { displayName: contactBulkActions.DownloadCSV.text, value: contactBulkActions.DownloadCSV.request, isEnabled: true },
  { displayName: contactBulkActions.AssignTask.text, value: contactBulkActions.AssignTask.request, isEnabled: true },
  { displayName: contactBulkActions.Tag.text, value: contactBulkActions.Tag.request, isEnabled: true },
];

export const stepBulkActions = [
  { displayName: contactBulkActions.SendNow.text, value: contactBulkActions.SendNow.request, isEnabled: true },
  { displayName: contactBulkActions.Stop.text, value: contactBulkActions.Stop.request, isEnabled: true },
  { displayName: contactBulkActions.Pause.text, value: contactBulkActions.Pause.request, isEnabled: true },
  { displayName: contactBulkActions.Skip.text, value: contactBulkActions.Skip.request, isEnabled: true },
  { displayName: contactBulkActions.Resume.text, value: contactBulkActions.Resume.request, isEnabled: true },
  ...bulkActions,
];

export const scheduledActions = [
  { displayName: contactBulkActions.StartNow.text, value: contactBulkActions.StartNow.request, isEnabled: true },
  { displayName: contactBulkActions.Cancel.text, value: contactBulkActions.Cancel.request, isEnabled: true },
  { displayName: contactBulkActions.Email.text, value: contactBulkActions.Email.request, isEnabled: true },
  ...bulkActions,
];

export const waitingRoomActions = [
  { displayName: contactBulkActions.Remove.text, value: contactBulkActions.Remove.request, isEnabled: true },
  { displayName: contactBulkActions.Email.text, value: contactBulkActions.Email.request, isEnabled: true },
  ...bulkActions,
];
export const exitedActions = [
  // {displayName: contactBulkActions.Resume.text, value: contactBulkActions.Resume.request},
  { displayName: contactBulkActions.Email.text, value: contactBulkActions.Email.request, isEnabled: true },
  ...bulkActions,
];

export const SalesflowTab = {
  CONTACTS: 'contacts',
  STEPS: 'steps',
  SCHEDULED: 'scheduled',
  WAITING_ROOM: 'waiting_room',
  EXITED: 'exited',
};

export const TabsMapping = {
  contacts: {
    displayName: 'Contacts',
    value: SalesflowTab.CONTACTS,
  },
  steps: {
    displayName: 'Steps & Stats',
    value: SalesflowTab.STEPS,
  },
  scheduled: {
    displayName: 'Scheduled',
    value: SalesflowTab.SCHEDULED,
  },
  waiting_room: {
    displayName: 'Waiting Room',
    value: SalesflowTab.WAITING_ROOM,
  },
  exited: {
    displayName: 'Exited',
    value: SalesflowTab.EXITED,
  },
};

export const contactStatus = {
  PAUSED: 'Paused',
  INPROGRESS: 'NotStarted',
  EMAILSENT: 'InProgress',
};

export const enum SalesflowSortOption {
  NAME = 'NAME',
  DATE_CREATED = 'DATE_CREATED',
  CREATED_BY = 'CREATED_BY',
  DATE_UPDATED = 'DATE_UPDATED',
}

export const enum SalesflowContactsTopFilterEnum {
  ACTIVE = 'Active',
  OPENED = 'Opened',
  REPLIED = 'Replied',
  LINKS_CLICKED = 'Links clicked',
  NO_REPLY = 'No reply',
  NOT_OPENED = 'Not opened',
  LINK_NOT_CLICKED = 'Links not clicked',
  CALLED = 'Called',
  HAD_CONVERSATION = 'Had conversation',
  NO_CONVERSATION = 'No conversation',
}

const iconsUrl = environment.icons_url;
export const SalesflowContactsQuickFilters = {
  defaultFilters: [
    {
      key: SalesflowContactsTopFilterEnum.ACTIVE,
      displayName: SalesflowContactsTopFilterEnum.ACTIVE,
      isDefault: true,
      isActive: true,
      icon: `${iconsUrl}/active-icon.svg`,
      chips: [{ totalCount: 0 }],
    },
    {
      key: contactTasksEmailStatsEnum.EmailsOpened,
      displayName: SalesflowContactsTopFilterEnum.OPENED,
      isDefault: true,
      isActive: true,
      icon: `${iconsUrl}/email_opened_color.svg`,
      chips: [{ totalCount: 0, filterType: ContactTaskFilterType.EmailsOpened }],
    },
    {
      key: contactTasksEmailStatsEnum.EmailsNotOpened,
      displayName: SalesflowContactsTopFilterEnum.NOT_OPENED,
      isDefault: true,
      isActive: true,
      icon: `${iconsUrl}/email-icon-v3.svg`,
      chips: [{ totalCount: 0, filterType: ContactTaskFilterType.EmailsNotOpened }],
    },
    {
      key: contactTasksEmailStatsEnum.EmailsReplied,
      displayName: SalesflowContactsTopFilterEnum.REPLIED,
      isDefault: true,
      isActive: true,
      icon: `${iconsUrl}/email_replied_color.svg`,
      chips: [{ totalCount: 0, filterType: ContactTaskFilterType.EmailsReplied }],
    },
    {
      key: contactTasksEmailStatsEnum.EmailsNotReplied,
      displayName: SalesflowContactsTopFilterEnum.NO_REPLY,
      isDefault: true,
      isActive: true,
      icon: `${iconsUrl}/email_replied_color.svg`,
      chips: [{ totalCount: 0, filterType: ContactTaskFilterType.EmailsNoReply }],
    },
    {
      key: contactTasksEmailStatsEnum.EmailsLinkClicked,
      displayName: SalesflowContactsTopFilterEnum.LINKS_CLICKED,
      isDefault: true,
      isActive: true,
      icon: `${iconsUrl}/link_clicked.svg`,
      chips: [{ totalCount: 0, filterType: ContactTaskFilterType.EmailsLinkedClicked }],
    },
    {
      key: contactTasksEmailStatsEnum.EmailsLinkNotClicked,
      displayName: SalesflowContactsTopFilterEnum.LINK_NOT_CLICKED,
      isDefault: true,
      isActive: true,
      icon: `${iconsUrl}/link_clicked.svg`,
      chips: [{ totalCount: 0, filterType: ContactTaskFilterType.EmailsLinkedNotClicked }],
    },
    {
      key: contactTasksEmailStatsEnum.CallsCount,
      displayName: SalesflowContactsTopFilterEnum.CALLED,
      isDefault: true,
      isActive: true,
      icon: `${iconsUrl}/called.svg`,
      chips: [{ totalCount: 0, filterType: ContactTaskFilterType.HadACall }],
    },
    {
      key: contactTasksEmailStatsEnum.ConversationCount,
      displayName: SalesflowContactsTopFilterEnum.HAD_CONVERSATION,
      isDefault: true,
      isActive: true,
      icon: `${iconsUrl}/had_conversation.svg`,
      chips: [{ totalCount: 0, filterType: ContactTaskFilterType.HadAConversation }],
    },
    {
      key: contactTasksEmailStatsEnum.NoConversationCount,
      displayName: SalesflowContactsTopFilterEnum.NO_CONVERSATION,
      isDefault: true,
      isActive: true,
      icon: `${iconsUrl}/no_conversation.svg`,
      chips: [{ totalCount: 0, filterType: ContactTaskFilterType.HadNoConversation }],
    },
  ],
};

// Used to set localStorage back to Contacts tab in salesflow
export const CurrentSalesflowTabKey: string = 'CurrentTab';

export const DEFAULT_SALESFLOW_TEMPLATE_INSIGHTS = (id) =>
  ({
    id,
    emailsSentCount: 0,
    emailsDeliveredCount: 0,
    emailsFailedCount: 0,
    emailsOpenedCount: 0,
    emailsLinkClickedCount: 0,
    emailsRepliedCount: 0,
    emailsAcceptedCount: 0,
    emailsUnsubscribedCount: 0,
    callsCount: 0,
    conversationCount: 0,
  } as SalesWorkflowTemplateInsights);
