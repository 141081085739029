import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EngageVersionStringTransformService } from '@app/common/service/engage-version-string-transform/engage-version-string-transform.service';
/**
 * Make sure to use (clickButton) and not (click)!!!
 */
@Component({
  selector: 'app-primary-btn',
  templateUrl: './primary-btn.component.html',
  styleUrls: ['./primary-btn.component.scss'],
})
export class PrimaryBtnComponent implements OnInit {
  @Input() text = '';
  @Input() disabled = false;
  @Input() iconSrc;
  @Input() enableAppVerTranslation = false;
  @Input() enableLoader = false;
  /**
   * Always use this output, never (click)
   */
  @Output() clickButton = new EventEmitter();

  constructor(public stringTransformService: EngageVersionStringTransformService) {}

  ngOnInit() {}

  onClick(event) {
    if (!this.enableLoader) {
      this.clickButton.emit(event);
    }
  }
}
