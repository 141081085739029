/**
 * TODO - cleanup
 * The below config doesn't seem to be used in froala
 */
export const actionButtonPanel = [
  {
    title: 'Unsubscribe Link',
    value: 'unsubscribe',
    src: `/assets/icons/email.svg`,
    src_m: '/assets/icons/email.svg',
    src_b: '/assets/icons/email_b.svg',
    src_d: '/assets/icons/email_d.svg',
    isActive: false,
    hasBorder: false,
  },
  {
    title: 'Attachment',
    value: 'attachment',
    src: '/assets/icons/email.svg',
    src_m: '/assets/icons/email.svg',
    src_b: '/assets/icons/email_b.svg',
    src_d: '/assets/icons/email_d.svg',
    isActive: false,
    hasBorder: false,
  },
  {
    title: 'Send Email to Yourself',
    value: 'send_yourself',
    src: '/assets/icons/email.svg',
    src_m: '/assets/icons/email.svg',
    src_b: '/assets/icons/email_b.svg',
    src_d: '/assets/icons/email_d.svg',
    isActive: false,
    hasBorder: false,
  },
];
export const upperButtonPanel = [
  {
    title: 'Email',
    src: '/assets/icons/email.svg',
    src_m: '/assets/icons/email.svg',
    src_b: '/assets/icons/email_b.svg',
    src_d: '/assets/icons/email_d.svg',
    isActive: false,
    hasBorder: false,
  },
  {
    title: 'Call',
    src: '/assets/icons/call.svg',
    src_m: '/assets/icons/call.svg',
    src_b: '/assets/icons/call_b.svg',
    src_d: '/assets/icons/call_d.svg',
    isActive: false,
    hasBorder: false,
  },
  {
    title: 'Other',
    src: '/assets/icons/other.svg',
    src_m: '/assets/icons/other.svg',
    src_b: '/assets/icons/other_b.svg',
    src_d: '/assets/icons/other_d.svg',
    isActive: false,
    hasBorder: true,
  },
  {
    title: 'Salesflow',
    src: '/assets/icons/salesflow.svg',
    src_m: '/assets/icons/salesflow.svg',
    src_b: '/assets/icons/salesflow_b.svg',
    src_d: '/assets/icons/salesflow_d.svg',
    isActive: false,
    hasBorder: false,
  },
];

export enum RecognizedVariables {
  UnsubscribeUrl = '{UnsubscribeUrl}',
  MySignature = '{MySignature}',
  MyCalendarLink_ = '{MyCalendarLink_}',
  MyCalendarLink = '{MyCalendarLink}',
  FirstName = '{FirstName}',
  LastName = '{LastName}',
  Name = '{Name}',
  Title = '{Title}',
  Phone = '{Phone}',
  City = '{City}',
  State = '{State}',
  Country = '{Country}',
  Salutation = '{Salutation}',
  MyFirstName = '{MyFirstName}',
  MyLastName = '{MyLastName}',
  MyName = '{MyName}',
  MyEmail = '{MyEmail}',
  MyCompany = '{MyCompany}',
  MyTitle = '{MyTitle}',
  MyPhone = '{MyPhone}',
  Dayofmonthtoday = '{Dayofmonthtoday}',
  Monthtoday = '{Monthtoday}',
  Dayofweektoday = '{Dayofweektoday}',
  Dayofmonthnextbusinessday = '{Dayofmonthnextbusinessday}',
  Monthnextbusinessday = '{Monthnextbusinessday}',
  Dayofweeknextbusinessday = '{Dayofweeknextbusinessday}',
  Dayofmonthnextnextbusinessday = '{Dayofmonthnextnextbusinessday}',
  Monthnextnextbusinessday = '{Monthnextnextbusinessday}',
  Dayofweeknextnextbusinessday = '{Dayofweeknextnextbusinessday}',
  Company = '{Company}',
  CompanyWebsite = '{CompanyWebsite}',
  Industry = '{Industry}',
}

export const variableMap = {
  [RecognizedVariables.MySignature]: 'My Signature',
  [RecognizedVariables.MyCalendarLink]: 'My Calendar Link',
  [RecognizedVariables.FirstName]: 'First Name',
  [RecognizedVariables.LastName]: 'Last Name',
  [RecognizedVariables.Name]: 'Full Name',
  [RecognizedVariables.Company]: 'Company',
  [RecognizedVariables.Title]: 'Title',
  [RecognizedVariables.Phone]: 'Phone',
  [RecognizedVariables.City]: 'City',
  [RecognizedVariables.State]: 'State',
  [RecognizedVariables.Country]: 'Country',
  [RecognizedVariables.Salutation]: 'Salutation',
  [RecognizedVariables.MyFirstName]: 'My First Name',
  [RecognizedVariables.MyLastName]: 'My Last Name',
  [RecognizedVariables.MyName]: 'My Full Name',
  [RecognizedVariables.MyEmail]: 'My Email',
  [RecognizedVariables.MyCompany]: 'My Company',
  [RecognizedVariables.MyTitle]: 'My Title',
  [RecognizedVariables.MyPhone]: 'My Phone',
  [RecognizedVariables.Dayofmonthtoday]: 'Day of Month Today',
  [RecognizedVariables.Monthtoday]: 'Month of Today',
  [RecognizedVariables.Dayofweektoday]: 'Day of Week Today',
  [RecognizedVariables.Dayofmonthnextbusinessday]: 'Next Business Day',
  [RecognizedVariables.Monthnextbusinessday]: 'Month of Next Business Day',
  [RecognizedVariables.Dayofweeknextbusinessday]: 'Day of Week of Next Business Day',
  [RecognizedVariables.Dayofmonthnextnextbusinessday]: 'Day of Month Next Next Business Day',
  [RecognizedVariables.Monthnextnextbusinessday]: 'Month of Next Next Business Day',
  [RecognizedVariables.Dayofweeknextnextbusinessday]: 'Day of Week Next Next Business Day',
};

// Default variables of contact tab in merge variable dialog
export const variableMapContact = {
  [RecognizedVariables.MySignature]: 'My Signature',
  [RecognizedVariables.MyCalendarLink]: 'My Calendar Link',
  [RecognizedVariables.FirstName]: 'First Name',
  [RecognizedVariables.LastName]: 'Last Name',
  [RecognizedVariables.Name]: 'Full Name',
  [RecognizedVariables.Title]: 'Title',
  [RecognizedVariables.Phone]: 'Phone',
  [RecognizedVariables.City]: 'City',
  [RecognizedVariables.State]: 'State',
  [RecognizedVariables.Country]: 'Country',
  [RecognizedVariables.Salutation]: 'Salutation',
  [RecognizedVariables.MyFirstName]: 'My First Name',
  [RecognizedVariables.MyLastName]: 'My Last Name',
  [RecognizedVariables.MyName]: 'My Full Name',
  [RecognizedVariables.MyEmail]: 'My Email',
  [RecognizedVariables.MyCompany]: 'My Company',
  [RecognizedVariables.MyTitle]: 'My Title',
  [RecognizedVariables.MyPhone]: 'My Phone',
  [RecognizedVariables.Dayofmonthtoday]: 'Day of Month Today',
  [RecognizedVariables.Monthtoday]: 'Month of Today',
  [RecognizedVariables.Dayofweektoday]: 'Day of Week Today',
  [RecognizedVariables.Dayofmonthnextbusinessday]: 'Next Business Day',
  [RecognizedVariables.Monthnextbusinessday]: 'Month of Next Business Day',
  [RecognizedVariables.Dayofweeknextbusinessday]: 'Day of Week of Next Business Day',
  [RecognizedVariables.Dayofmonthnextnextbusinessday]: 'Day of Month Next Next Business Day',
  [RecognizedVariables.Monthnextnextbusinessday]: 'Month of Next Next Business Day',
  [RecognizedVariables.Dayofweeknextnextbusinessday]: 'Day of Week Next Next Business Day',
};

// Default variables of company account tab in merge variable dialog
export const variableMapCompanyAccount = {
  [RecognizedVariables.Company]: 'Company',
  [RecognizedVariables.CompanyWebsite]: 'Company Website',
  [RecognizedVariables.Industry]: 'Industry',
};

export const tracking = {
  ON: 'Tracking ON',
  OFF: 'Tracking OFF',
};

export enum TrackingLink {
  ON = 'This link will be tracked.',
  OFF = 'Not tracked.',
}

export enum FroalaSections {
  Subject = 'Subject',
  Content = 'Content',
  Other = 'Other',
}

// This array specifies which HTML tags are allowed to be empty (i.e., have no content between the opening and closing tags)
// except for hyperlinks, which we do not want to allow to prevent an issue when there is an empty hyperlink tag that has link variable of {MyCalendarLink_}
export const defaultHtmlAllowedEmptyTagsExceptHyperLinks = [
  'textarea',
  'iframe',
  'object',
  'video',
  'style',
  'script',
  '.fa',
  '.fr-emoticon',
  '.fr-inner',
  'path',
  'line',
  'hr',
];

// This array is the same default array provided by froala, but now font tag was added to support chrome spell check that inserts font tag
export const defaultHtmlAllowedTagsWithFontTag = [
  'a',
  'abbr',
  'address',
  'area',
  'article',
  'aside',
  'audio',
  'b',
  'base',
  'bdi',
  'bdo',
  'blockquote',
  'br',
  'button',
  'canvas',
  'caption',
  'cite',
  'code',
  'col',
  'colgroup',
  'datalist',
  'dd',
  'del',
  'details',
  'dfn',
  'dialog',
  'div',
  'dl',
  'dt',
  'em',
  'embed',
  'fieldset',
  'figcaption',
  'figure',
  'font',
  'footer',
  'form',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'header',
  'hgroup',
  'hr',
  'i',
  'img',
  'input',
  'ins',
  'kbd',
  'keygen',
  'label',
  'legend',
  'li',
  'link',
  'main',
  'map',
  'mark',
  'menu',
  'menuitem',
  'meter',
  'nav',
  'noscript',
  'object',
  'ol',
  'optgroup',
  'option',
  'output',
  'p',
  'param',
  'pre',
  'progress',
  'queue',
  'rp',
  'rt',
  'ruby',
  's',
  'samp',
  'script',
  'style',
  'section',
  'select',
  'small',
  'source',
  'span',
  'strike',
  'strong',
  'sub',
  'summary',
  'sup',
  'table',
  'tbody',
  'td',
  'textarea',
  'tfoot',
  'th',
  'thead',
  'time',
  'title',
  'tr',
  'track',
  'u',
  'ul',
  'var',
  'video',
  'wbr',
];

/*
 * An array that includes the default HTML allowed attributes in froala.
 * In addition to the attributes, the array also includes 'color' and 'face' attributes
 * These attributes are crucial for handling and supporting chrome spell check as it converts the div to font tag
 * And the inline style to 'color' and 'face' attributes
 */
export const defaultHtmlAllowedAttrsWithColorAndFace = [
  'accept',
  'accept-charset',
  'accesskey',
  'action',
  'align',
  'allowfullscreen',
  'allowtransparency',
  'alt',
  'aria-.*',
  'async',
  'autocomplete',
  'autofocus',
  'autoplay',
  'autosave',
  'background',
  'bgcolor',
  'border',
  'charset',
  'cellpadding',
  'cellspacing',
  'checked',
  'cite',
  'class',
  'color',
  'cols',
  'colspan',
  'content',
  'contenteditable',
  'contextmenu',
  'controls',
  'coords',
  'data',
  'data-.*',
  'datetime',
  'default',
  'defer',
  'dir',
  'dirname',
  'disabled',
  'download',
  'draggable',
  'dropzone',
  'enctype',
  'for',
  'form',
  'formaction',
  'frameborder',
  'headers',
  'height',
  'hidden',
  'high',
  'href',
  'hreflang',
  'http-equiv',
  'icon',
  'id',
  'ismap',
  'itemprop',
  'keytype',
  'kind',
  'label',
  'lang',
  'language',
  'list',
  'loop',
  'low',
  'max',
  'maxlength',
  'media',
  'method',
  'min',
  'mozallowfullscreen',
  'multiple',
  'muted',
  'name',
  'novalidate',
  'open',
  'optimum',
  'pattern',
  'ping',
  'placeholder',
  'playsinline',
  'poster',
  'preload',
  'pubdate',
  'radiogroup',
  'readonly',
  'rel',
  'required',
  'reversed',
  'rows',
  'rowspan',
  'sandbox',
  'scope',
  'scoped',
  'scrolling',
  'seamless',
  'selected',
  'shape',
  'size',
  'sizes',
  'span',
  'src',
  'srcdoc',
  'srclang',
  'srcset',
  'start',
  'step',
  'summary',
  'spellcheck',
  'style',
  'tabindex',
  'target',
  'title',
  'type',
  'translate',
  'usemap',
  'value',
  'valign',
  'webkitallowfullscreen',
  'width',
  'wrap',
  'color',
  'face',
];

export const UnsubscribeLinks = ['{unsubscribeurl}', '%7bunsubscribeurl%7d'];

export const defaultFontSize = '12';
