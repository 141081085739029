import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { EngageActionEmailOutput } from '@app/pages/engage-mode-v2/engage-action-email-output';
import { PdmApplication } from '@zi-core/enums/pdm-application.enum';
import { FeatureFlagService } from '@app/feature-flag/feature-flag.service';
import { Flag } from '@app/feature-flag/types/flag.enum';
import { switchMap, take } from 'rxjs/operators';
import { DataSerializationService } from '@zi-common/service/data-serialization.service';
import { ApiEncodedPayload } from '@zi-core/http-model/request/api-encoded-payload.model';
import { StringEncodingMethod } from '@zi-core/enums/string-encoding-method.enum';

/**
 *  Email Service
 */
@Injectable()
export class EmailService {
  private readonly postCreateInvitationUrl = `${environment.backend_url}/v1/invitations/create`;
  private readonly postEncodedCreateInvitationUrl = `${environment.backend_url}/v2/encoded/invitations/create`;
  private readonly postCreateInvitationTestUrl = `${environment.backend_url}/v1/invitations/sendtest`;
  private readonly postEncodedCreateInvitationTestUrl = `${environment.backend_url}/v2/encoded/invitations/sendtest`;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly featureFlagService: FeatureFlagService,
    private readonly dataSerializationService: DataSerializationService,
  ) {}

  sendEmail(emailData: EngageActionEmailOutput): Observable<any> {
    const reqObj = {
      SpaceId: 0,
      TemplateId: emailData.templateId,
      TileIds: emailData.tileIds,
      Attachments: emailData.emailAttachments,
      To: emailData.emailToArray,
      Cc: emailData.emailCcArray, // [{Email: example@gmail1.com}, {Email: example2@gmail.com}] or []
      Bcc: emailData.emailBccArray,
      SendEmail: true,
      IsGroup: emailData.isGroup,
      Subject: emailData.emailSubject,
      Message: emailData.emailContent,
      SpaceTags: [],
      SendAt: emailData.sendAt,
      PromoCode: null,
      FollowUpTask: emailData.followUpTask,
      PdmApplication: PdmApplication.Engage,
    };
    return this.featureFlagService.observe<Flag.ENABLE_API_ENCODING_FOR_WAF>(Flag.ENABLE_API_ENCODING_FOR_WAF).pipe(
      take(1),
      switchMap((apiEncodingEnabled: boolean) => {
        if (apiEncodingEnabled) {
          reqObj.Message = this.dataSerializationService.getEncodedString(reqObj.Message, StringEncodingMethod.Base64);
          return this.httpClient.post(this.postEncodedCreateInvitationUrl, {
            data: reqObj,
            encoding: StringEncodingMethod.Base64,
          } as ApiEncodedPayload);
        }
        return this.httpClient.post(this.postCreateInvitationUrl, reqObj);
      }),
    );
  }

  sentTest(emailData: EngageActionEmailOutput): Observable<any> {
    const reqObj = { Subject: emailData.emailSubject, Message: emailData.emailContent, TileIds: emailData.tileIds, Attachments: emailData.emailAttachments };

    return this.featureFlagService.observe<Flag.ENABLE_API_ENCODING_FOR_WAF>(Flag.ENABLE_API_ENCODING_FOR_WAF).pipe(
      take(1),
      switchMap((apiEncodingEnabled: boolean) => {
        if (apiEncodingEnabled) {
          reqObj.Message = this.dataSerializationService.getEncodedString(reqObj.Message, StringEncodingMethod.Base64);
          return this.httpClient.post(this.postEncodedCreateInvitationTestUrl, {
            data: reqObj,
            encoding: StringEncodingMethod.Base64,
          } as ApiEncodedPayload);
        }
        return this.httpClient.post(this.postCreateInvitationTestUrl, reqObj);
      }),
    );
  }
}
