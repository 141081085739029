import { Injectable, PipeTransform } from '@angular/core';
import { RetrieveConversationItem, RetrieveConversationResponse } from '@zi-pages/sms/model/retrieve-conversation-response';
import { SmsConversationDto, SmsConversationItem } from '@zi-pages/sms/model/sms-conversation.dto';
import { SmsMessageDirection } from '@zi-pages/sms/model/sms-message-direction';
import { SmsMessagePriority } from '@zi-pages/sms/model/sms-message-priority';
import { SmsMessageStatus } from '@zi-pages/sms/model/sms-message-status';

@Injectable()
export class RetrieveConversationToDtoPipe implements PipeTransform {
  transform(retrieveConvoResp: RetrieveConversationResponse): SmsConversationDto {
    const smsConvoDateMap: { [key: string]: SmsConversationItem[] } = {};
    const conversations = retrieveConvoResp?.data[0]?.conversation;
    if (!conversations) {
      return {
        smsConvoDateMap,
      };
    }

    conversations.forEach((conversation: RetrieveConversationItem) => {
      const sentAtDate = new Date(conversation.sentAt);
      const item: SmsConversationItem = {
        smsMessageBody: conversation.body,
        messageDirection: conversation.direction?.toLowerCase() as SmsMessageDirection,
        messagePriority: conversation.priority?.toLowerCase() as SmsMessagePriority,
        messageStatus: conversation.status?.toLowerCase() as SmsMessageStatus,
        sentAt: sentAtDate,
        lastUpdatedAt: new Date(conversation.lastUpdatedAt),
        externalId: conversation.externalId,
        fromNumber: conversation.from,
        toNumber: conversation.to,
        trackingId: conversation.trackingId,
        isFailureActionLoading: false,
      };
      const dayMonYear = new Date(sentAtDate.getFullYear(), sentAtDate.getMonth(), sentAtDate.getDate()).toISOString();
      if (smsConvoDateMap[dayMonYear]) {
        smsConvoDateMap[dayMonYear].push(item);
      } else {
        smsConvoDateMap[dayMonYear] = [item];
      }
    });

    return {
      smsConvoDateMap,
    };
  }
}
