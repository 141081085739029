import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import DOMPurify from 'dompurify';
import { DOMSanitizeConfiguration } from '@zi-common/component/froala-editor-v2/froala-editor.helper';

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  /**
   * Used to prevent XSS by sanitizing  html string to be safe to use in but allow inline style.
   * @param value The specified value for sanitization
   * @param isBypass Flag used to mark a string as trust after we sanitized it
   *                  this flag can used only when pipe called with property binding,
   *                  otherwise it will append prefix and suffix to string (angular mechanism)
   */
  transform(value: string, isBypass: boolean = true): SafeHtml | string {
    let res = '';
    if (!value) {
      return res;
    }
    res = DOMPurify.sanitize(value, DOMSanitizeConfiguration) as string;
    if (isBypass) {
      res = this.sanitizer.bypassSecurityTrustHtml(res) as string;
    }
    return res;
  }
}
