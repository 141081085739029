export enum CrmEntityType {
  None = 0,
  Lead = 1,
  Contact = 2,
  Task = 3,
  Phone = 4,
  Event = 5,
  User = 6,
  Account = 7,
  Opportunity = 8,
  Case = 9,
  RecordType = 10,
}

export const CrmEntitiesNames = {
  [CrmEntityType.None]: 'None',
  [CrmEntityType.Contact]: 'Contact',
  [CrmEntityType.Lead]: 'Lead',
  [CrmEntityType.Account]: 'Account',
  [CrmEntityType.User]: 'User',
};

export enum CrmSyncType {
  // AKA Outbound
  EngageToSalesforce = 1,
  // AKA Inbound
  SalesforceToEngage = 2,
  BiDirectionalSync = 3,
}

export enum ContactsIntegration {
  None = 0,
  Active = 4,
}
