import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { TwilioTokenRefreshService } from '@app/caller/service/twilio-token-refresh.service';
import { VoipDeviceService } from '@app/caller/service/voip-device.service';
import { CrmService } from '@app/common/service/crm/crm.service';
import { DoziIntegrationService } from '@app/core/service/dozi-integration.service';
import { TokenRefreshService } from '@app/core/service/token-refresh.service';
import { ExtensionIframeMessagingService } from '@app/extension/service/extension-iframe-messaging.service';
import { getExtensionMode } from '@app/extensionMode.config';
import { LoginService } from '@app/login/services/login.service';
import { ApplicationState } from '@app/reducers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MessageType } from '@zi-common/model/message/message-type';
import { Message } from '@zi-common/model/message/message.model';
import { NotyService } from '@zi-common/service/noty/noty.service';
import { OrganizationService } from '@zi-common/service/organization/organization.service';
import {
  ClearUserZoominfoDetailsAction,
  GetUserWebsiteDomainListAction,
  GetUserWebsiteDomainListFailureAction,
  GetUserWebsiteDomainListSuccessAction,
  GetUserZoominfoDetailsAction,
  GetUserZoominfoDetailsSuccessAction,
  LoadConfigurationCRM,
  LoadConfigurationCRMSuccess,
  LoadConnectedServices,
  LoadConnectedServicesSuccess,
  LoadOrgConnected,
  LoadOrgConnectedSuccess,
  LoadTwilioClientTokenAction,
  LoadTwilioClientTokenFailureAction,
  LoadTwilioClientTokenSuccessAction,
  LoginSuccessAction,
  LogoutAction,
  ProvisionMultiFactorAuthAction,
  RefreshTokenAction,
  RefreshTokenFailureAction,
  RefreshTokenSuccessAction,
  SetRulesEngineToken,
  UpdateRecruiterUserLicenseAction,
  VerifyOneTimePasswordAction,
} from '@zi-core/ngrx/action/auth.action';
import { Permissions } from '@zi-core/ngxPermissions/permissions';
import { PermissionsService } from '@zi-core/ngxPermissions/permissions.service';
import { AnalyticsService } from '@zi-core/service/analytics.service';
import { IntegrationService } from '@zi-core/service/integration.service';
import { NavigationService } from '@zi-core/service/navigation.service';
import * as _ from 'lodash';
import { forkJoin, of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { LoadEmailerVersionAction } from '@zi-pages/admin/ngrx/action/emailer-version.action';
import { FeatureFlagService } from '@app/feature-flag/feature-flag.service';
import { Flag } from '@app/feature-flag/types/flag.enum';
import { UmsWaffleNavbarService } from '@app/common/service/ums-waffle-menu/ums-waffle-navbar.service';

@Injectable()
export class AuthEffects {
  constructor(
    private action$: Actions,
    private _httpClient: HttpClient,
    private router: Router,
    private loginService: LoginService,
    private navigationService: NavigationService,
    private tokenRefreshService: TokenRefreshService,
    private extensionIFrameMessagingService: ExtensionIframeMessagingService,
    private doziIntegrationService: DoziIntegrationService,
    private integrationService: IntegrationService,
    private organizationService: OrganizationService,
    private analyticsService: AnalyticsService,
    private permissionsService: PermissionsService,
    private crmService: CrmService,
    private store: Store<ApplicationState>,
    private notyService: NotyService,
    private voipDeviceService: VoipDeviceService,
    private twilioTokenRefreshService: TwilioTokenRefreshService,
    private featureFlagService: FeatureFlagService,
    private doziTokenService: UmsWaffleNavbarService,
    private _urlSerializer: UrlSerializer,
  ) {}

  navigateToLoginPage$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(LogoutAction),
        mergeMap((action) =>
          this.featureFlagService.observe<Flag.UMS_INTEGRATION>(Flag.UMS_INTEGRATION).pipe(
            map((isUmsIntegrationEnabled) => {
              this.tokenRefreshService.stop();
              this.twilioTokenRefreshService.stop();
              this.voipDeviceService.cleanUpDevice();
              if (getExtensionMode()) {
                this.extensionIFrameMessagingService.sendLoggedOutMessage();
              }

              if (isUmsIntegrationEnabled) {
                this.doziTokenService.clearSessionCookies();
                this.navigationService.navigateToDoziLogin();
              } else {
                this.navigationService.navigateToLogin(action.urlToNavigateTo, action.options);
              }
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  navigateToVerifyOtpPage$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(VerifyOneTimePasswordAction),
        tap((action) => {
          const urlToUse = this.navigationService.getVerifyOtpUrl();

          const queryParams = {
            mfaEmail: action.mfaEmail,
            mfaPhoneNumber: action.mfaPhoneNumber,
            multiFactorAuthJwt: action.multiFactorAuthJwt,
            userName: action.userName,
            encodedRedirectParams: action.encodedRedirectParams,
          };

          this.router.navigate([urlToUse], { skipLocationChange: true, queryParams });
        }),
      ),
    { dispatch: false },
  );

  navigateToProvisionMfaPage$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(ProvisionMultiFactorAuthAction),
        tap((action) => {
          const urlToUse = this.navigationService.getProvisionMfaUrl();

          const queryParams = {
            name: action.name,
            email: action.email,
            multiFactorAuthJwt: action.multiFactorAuthJwt,
            encodedRedirectParams: action.encodedRedirectParams,
          };

          this.router.navigate([urlToUse], { skipLocationChange: true, queryParams });
        }),
      ),
    { dispatch: false },
  );

  navigateToAppPage$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(LoginSuccessAction),
        tap((action) => {
          // set organizationService orgId from auth token on login
          this.organizationService.init();
          this.analyticsService.initAmplitude();
          this.voipDeviceService.checkAndInitToken(action.authToken);
          this.store.dispatch(LoadEmailerVersionAction());
          const redirectParams = action.loginRedirectParams;
          let urlToUse;
          let queryParamsToUse = {};

          if (redirectParams) {
            urlToUse = redirectParams.redirectRoute;
            queryParamsToUse = redirectParams.redirectQueryParams;
          }

          if (action.multiFactorAuthSetupRequired) {
            urlToUse = this.navigationService.getProvisionMfaUrl();
          }
          if (getExtensionMode()) {
            this.extensionIFrameMessagingService.sendLoggedInMessage();
          }

          if (urlToUse) {
            this.router.navigate([urlToUse], { skipLocationChange: true, queryParams: queryParamsToUse });
          }
        }),
      ),
    { dispatch: false },
  );

  getJwtToken$ = createEffect(() => {
    return this.action$.pipe(
      ofType(LoginSuccessAction),
      mergeMap(() =>
        this.loginService.getJwtRulesEngineToken().pipe(
          map((rulesEngineTokenResponse) => {
            return SetRulesEngineToken({ payload: rulesEngineTokenResponse });
          }),
        ),
      ),
    );
  });

  loadConnectedServices$ = createEffect(() => {
    return this.action$.pipe(
      ofType(LoadConnectedServices),
      mergeMap(() => this.integrationService.getConnectedServices().pipe(map((res) => LoadConnectedServicesSuccess({ connectedServices: res })))),
    );
  });

  LoadOrgConnected$ = createEffect(() => {
    return this.action$.pipe(
      ofType(LoadOrgConnected),
      mergeMap(() => this.organizationService.getOrganization().pipe(map((res) => LoadOrgConnectedSuccess({ org: _.get(res, 'organization') })))),
    );
  });

  loadConfigurationCRM$ = createEffect(() => {
    return this.action$.pipe(
      ofType(LoadConfigurationCRM),
      mergeMap(() => this.crmService.getConfigurationCRM()),
      map((res) => LoadConfigurationCRMSuccess({ configurationCRM: res })),
    );
  });

  refreshToken$ = createEffect(() => {
    return this.action$.pipe(
      ofType(RefreshTokenAction),
      mergeMap(() =>
        this.loginService.refreshToken().pipe(
          map((authenticatedTokenResponse) => {
            return RefreshTokenSuccessAction({ authenticatedToken: authenticatedTokenResponse.authenticatedToken });
          }),
        ),
      ),
      catchError((error: HttpErrorResponse) => of(RefreshTokenFailureAction({ error: error.message }))),
    );
  });

  userZoominfoDetails$ = createEffect(() => {
    return this.action$.pipe(
      ofType(GetUserZoominfoDetailsAction),
      mergeMap(() => {
        return forkJoin([this.doziIntegrationService.checkIfConnectedService(), this.doziIntegrationService.getUser()]).pipe(
          map(([doziRes, user]) => {
            if (doziRes.connected) {
              return GetUserZoominfoDetailsSuccessAction({ zoomUser: user });
            } else {
              return ClearUserZoominfoDetailsAction();
            }
          }),
          catchError((error) => of(ClearUserZoominfoDetailsAction())),
        );
      }),
    );
  });

  refreshPermissions$ = createEffect(() =>
    this.action$.pipe(
      ofType(GetUserZoominfoDetailsSuccessAction),
      map((action) => {
        // set permissions based on logged in zoominfo user after store is updated
        this.permissionsService.init();
        return GetUserWebsiteDomainListAction();
      }),
    ),
  );

  userWebsiteDomainList$ = createEffect(() => {
    return this.action$.pipe(
      ofType(GetUserWebsiteDomainListAction),
      mergeMap(() => {
        if (this.permissionsService.hasPermission([Permissions.WEBSIGHTS])) {
          return this.doziIntegrationService.GetWebsiteDomainList().pipe(
            map((res) => {
              return GetUserWebsiteDomainListSuccessAction({
                websiteDomains: Array.isArray(res.websites) && res.websites.length > 10 ? res.websites.slice(0, 10) : res.websites,
              });
            }),
            catchError((error: any) => of(GetUserWebsiteDomainListFailureAction({ error }))),
          );
        } else {
          return of(GetUserWebsiteDomainListSuccessAction({ websiteDomains: [] }));
        }
      }),
      catchError((error: any) => of(GetUserWebsiteDomainListFailureAction({ error }))),
    );
  });

  loadTwilioClientToken$ = createEffect(() => {
    return this.action$.pipe(
      ofType(LoadTwilioClientTokenAction),
      mergeMap(() => {
        return this.voipDeviceService.getTwilioClientToken();
      }),
      map((tokenResp) => {
        return LoadTwilioClientTokenSuccessAction({ token: tokenResp.token });
      }),
      catchError((error) => {
        this.notyService.postMessage(new Message(MessageType.ERROR, 'Error getting Twilio Client Token for VOIP. Please reload and try again.'));
        return of(LoadTwilioClientTokenFailureAction({ error }));
      }),
    );
  });

  loadTwilioClientTokenSuccess$ = createEffect(
    () => {
      return this.action$.pipe(
        ofType(LoadTwilioClientTokenSuccessAction),
        tap(() => {
          this.voipDeviceService.initDevice();
          this.twilioTokenRefreshService.start();
        }),
      );
    },
    { dispatch: false },
  );

  loadTwilioClientTokenFailure$ = createEffect(
    () => {
      return this.action$.pipe(
        ofType(LoadTwilioClientTokenFailureAction),
        tap(() => {
          // Getting token failed, clean up device in case
          this.voipDeviceService.cleanUpDevice();
        }),
      );
    },
    { dispatch: false },
  );

  reloadPageOnPlatformChangeSuccess$ = createEffect(
    () => {
      return this.action$.pipe(
        ofType(UpdateRecruiterUserLicenseAction),
        tap(() => {
          window.location.reload();
        }),
      );
    },
    { dispatch: false },
  );
}
