import { BehaviorSubject, fromEventPattern, Observable, of, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap, catchError } from 'rxjs/operators';
import { Inject, Injectable, OnDestroy } from '@angular/core';

import { LDClient, initialize as ldInitialize, LDUser } from 'launchdarkly-js-client-sdk';

import { FeatureFlagUser } from './interfaces/feature-flag-user.interface';

import { environment } from '../../environments/environment';
import { FlagSet } from './interfaces/flag-set.interface';
import { DefaultFlagSet } from './constants/default-flag-set.constant';
import { Flag } from './types/flag.enum';
import { FlagChangeSet } from './types/flag-change-set.type';
import { LoggerServiceToken } from '@zi-core/config/logger-service.config';
import { ILoggerService } from '@zi-core/interface/logger.service.interface';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService implements OnDestroy {
  /**
   * Subject for destruction of the service.
   */
  private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  /**
   * Mapping of flag keys to their behavior subjects.
   */
  private readonly changeMap: Map<Flag, BehaviorSubject<FlagSet[Flag]>> = new Map();

  /**
   * The flag set.
   */
  public readonly flags$: BehaviorSubject<FlagSet> = new BehaviorSubject(DefaultFlagSet);

  /**
   * An observable tracking whether or not the LaunchDarkly client is ready.
   */
  public readonly ldReady$: Observable<boolean>;

  /**
   * An observable to LaunchDarkly client flag set changes listening to the 'change' event.
   */
  public readonly ldChanges$: Observable<FlagChangeSet>;

  /**
   * The configuration for LaunchDarkly.
   */
  private readonly config = environment.launchDarkly;

  /**
   * An anonymous user for LaunchDarkly.
   */
  private readonly anonymousUser: LDUser = {
    anonymous: true,
  };

  /**
   * The LaunchDarkly client.
   */
  public readonly client: LDClient;

  /**
   * Constructor.
   */
  constructor(@Inject(LoggerServiceToken) private loggerService: ILoggerService) {
    // set the flags from the default flags in case launch darkly doesn't have the flags defined in the defaults
    this.flags = DefaultFlagSet;

    // initialize the client
    this.client = ldInitialize(this.config.clientSideId, this.anonymousUser, {
      ...this.config.options,
      bootstrap: 'localStorage', // use cache when set since this will fire the ready event asap
      sendLDHeaders: false, // avoid preflight requests for performance
      streaming: true, // stream by default
    });

    // listen for readiness
    this.ldReady$ = fromEventPattern(
      (handler) => {
        this.client.on('ready', handler);
      },
      (handler) => {
        this.client.off('ready', handler);
      },
    );

    // listen for all changes
    this.ldChanges$ = fromEventPattern(
      (handler) => {
        this.client.on('change', handler);
      },
      (handler) => {
        this.client.off('change', handler);
      },
    );

    this.subscribeToChange();
  }

  /**
   * Get observable of ready event
   */
  public getReadyObs(): Observable<boolean> {
    return this.ldReady$.pipe(
      takeUntil(this.destroyed$),
      tap(() => {
        // this.flags = this.client.allFlags() as FlagSet;
        // instead of getting flags from allFlags(), which evaluates all flags, retrieve them from the known flags.

        // map the current values in the changed flags to a flag set
        const newFlags: Partial<FlagSet> = {};

        Object.entries(this.flags).forEach(([key, value]) => {
          newFlags[key] = this.client.variation(key, value);
        });

        // set
        this.flags = newFlags;
      }),
      catchError(() => {
        return of(false);
      }),
    );
  }

  /**
   * Subscribe to the change event.
   */
  private subscribeToChange(): void {
    this.ldChanges$
      .pipe(
        takeUntil(this.destroyed$),
        tap((changedFlags) => {
          // map the current values in the changed flags to a flag set
          const flags: Partial<FlagSet> = {};

          // break down the keys
          const keys = Object.keys(changedFlags) as Flag[];

          // loop through each and overwrite
          keys.forEach((key) => {
            flags[key] = changedFlags[key].current;
          });

          this.flags = flags;
        }),
      )
      .subscribe();
  }

  /**
   * Set flags in a patching method.
   */
  public set flags(newFlags: Partial<FlagSet>) {
    // get the current flags
    const flags = this.flags$.value;

    // break down the keys
    const keys = Object.keys(newFlags) as Flag[];

    // loop through each and overwrite
    keys.forEach((key) => {
      // set the value
      const value = (newFlags as FlagSet)[key];

      // set the key with value (cast as non partial to avoid type errors)
      flags[key] = value;

      // set the observable
      if (this.changeMap.has(key)) {
        const subject = this.changeMap.get(key) as BehaviorSubject<typeof value>;

        if (subject !== undefined) {
          subject.next(value);
        }
      } else {
        const subject = new BehaviorSubject<typeof value>(value);

        this.changeMap.set(key, subject);
      }
    });

    this.flags$.next(flags);
  }

  /**
   * Get flags.
   */
  public get flags() {
    return this.flags$.value;
  }

  /**
   * Observe specific events for a specific flag.
   *
   * @param key The flag key.
   * @returns An observable for a behavior subject.
   */
  public observe<T extends Flag>(key: T): Observable<FlagSet[T]> {
    const subject = this.changeMap.get(key) as BehaviorSubject<FlagSet[T]>;

    if (subject !== undefined) {
      // only observe changes to the subject to avoid thrashing
      return subject.asObservable().pipe(distinctUntilChanged());
    }

    throw new Error('flag key and subject not defined');
  }

  /**
   * Identifies the user to LaunchDarkly. This should be called after the user has logged in.
   *
   * @param user - The ZoomInfo specific LaunchDarkly user.
   */
  public async login(user: FeatureFlagUser): Promise<void> {
    try {
      await this.client.waitForInitialization();

      // identify the user and make sure they are NOT anonymous
      const flags = await this.client.identify({ ...user, anonymous: false });

      this.flags = flags as FlagSet;
    } catch (e) {
      this.loggerService.error(e);
    }
  }

  /**
   * Identify the user to LaunchDarkly as anonymous.
   */
  public async logout(): Promise<void> {
    try {
      await this.client.waitForInitialization();

      // identify the user as anonymous
      const flags = await this.client.identify({ anonymous: true });

      this.flags = flags as FlagSet;
    } catch (e) {
      this.loggerService.error(e);
    }
  }

  /**
   * Closes the LaunchDarkly connection.
   */
  public async ngOnDestroy(): Promise<void> {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    await this.client.close();
  }
}
