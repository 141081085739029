export enum UserConfigurationKey {
  ON_BOARDING = 'onBoarding',
  FEED = 'feed',
  ALERTS = 'alerts',
  DIALER_CONFIG = 'dialerConfig',
  SIGNATURE = 'signature',
  QUICK_FILTERS = 'quickFilters',
  TASK_QUICK_FILTERS = 'taskQuickFilters',
  DISPLAY_ENGAGE_MODE_WARNING = 'engage.configuration.exit',
}
