import { FollowUpTask } from '@app/caller/interface/follow-up-task';
import { TaskPriority } from '@app/caller/interface/task-priority-enum';
import { TaskType } from '@app/caller/interface/task-type-enum';

export interface FollowUpTaskPanelValue {
  time: Date;
  priorityOption: { displayName: TaskPriority };
  taskOption: { displayName: TaskType };
  notes: string;
}

export interface FollowUpTaskPanelOptions {
  /**
   * Indicates if the component is inside extension on 20% of the screen.
   */
  is20PctExt?: boolean;

  /**
   * Timezone of the user.
   */
  timezone: string;

  /**
   * Timezone code for display.
   */
  timezoneCode: string;

  /**
   * Tooltip text above the timezone display.
   */
  timezoneTooltipText: string;

  /**
   * The follow up task object.
   */
  followUpTask: FollowUpTaskPanelValue;

  /**
   * Initial follow up task object.
   */
  initialFollowUpTask?: FollowUpTask;
}

export const defaultFollowUpTaskPanelValue: FollowUpTaskPanelValue = {
  time: null,
  priorityOption: null,
  taskOption: null,
  notes: null,
};

export const defaultFollowupTaskPanelOptions: FollowUpTaskPanelOptions = {
  is20PctExt: false,
  timezone: '',
  timezoneCode: '',
  timezoneTooltipText: '',
  followUpTask: defaultFollowUpTaskPanelValue,
};
