import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, filter, map, take, withLatestFrom } from 'rxjs/operators';
import * as uuid from 'uuid';
import { BridgeDialerService } from '@app/caller/service/bridge-dialer.service';
import { VoipDeviceService } from '@app/caller/service/voip-device.service';
import { VoipDialerService } from '@app/caller/service/voip-dialer.service';
import { SigninRequest } from '@app/core/http-model/request/sign-in.request.model';
import { AuthenticatedTokenResponse, RulesEngineToken } from '@app/core/http-model/response/authenticated-token.response.model';
import { AnalyticsService } from '@app/core/service/analytics.service';
import { getExtensionMode } from '@app/extensionMode.config';
import { ApplicationState } from '@app/reducers';
import { environment } from '@env/environment';
import { MessageType } from '@zi-common/model/message/message-type';
import { Message } from '@zi-common/model/message/message.model';
import { NotyService } from '@zi-common/service/noty/noty.service';
import { OrgMemberService } from '@zi-common/service/org-member/org-member.service';
import { LoggerServiceToken } from '@zi-core/config/logger-service.config';
import { MultiFactorAuthVerificationRequest, SendOneTimePasswordRequest } from '@zi-core/http-model/request/multi-factor-auth-verification.request.model';
import { ILoggerService } from '@zi-core/interface/logger.service.interface';
import { LoginSuccessAction, LogoutAction } from '@zi-core/ngrx/action/auth.action';
import { getProfile } from '@zi-core/ngrx/state/auth.state';
import { NavigationService } from '@zi-core/service/navigation.service';
import { EmailModeService } from '@zi-pages/email-v2/services/email-mode.service';
import { EngageModeV2Service } from '@zi-pages/engage-mode-v2/service/engage-mode-v2.service';
import { TokenRefreshService } from '@zi-core/service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  changeRoutingViaEngageMode$: Subject<string> = new Subject<string>();
  allSubscriptions: Array<Subscription> = [];
  hex_digits = '0123456789abcdef';
  key_deviceId = 'deviceId';

  constructor(
    private http: HttpClient,
    private emailModeService: EmailModeService,
    private orgMembersService: OrgMemberService,
    private store: Store<ApplicationState>,
    private bridgeDialerService: BridgeDialerService,
    private voipDeviceService: VoipDeviceService,
    private voipDialerService: VoipDialerService,
    private navigationService: NavigationService,
    private engageModeV2Service: EngageModeV2Service,
    private analyticsService: AnalyticsService,
    private notyService: NotyService,
    private tokenRefreshService: TokenRefreshService,
    @Inject(LoggerServiceToken) private loggerService: ILoggerService,
  ) {}

  /**
   * Signin/login request
   */
  login(credential: SigninRequest) {
    return this.http.post<AuthenticatedTokenResponse>(`${environment.backend_url}/v1/account/signin`, credential, {
      withCredentials: true,
    });
  }

  logout() {
    const isEngageModeOn$ = this.engageModeV2Service.isEngageMode$.pipe(take(1)).subscribe((isEngageModeOn: boolean) => {
      this.orgMembersService.clearData();
      if (!isEngageModeOn || getExtensionMode()) {
        if (this.navigationService.checkCallStatus('sign out.')) {
          return;
        }
        this.engageModeV2Service.setAccessDeniedFlag(false);
        this.emailModeService.clearAllEmailTemplates();
        this.bridgeDialerService.cleanUpCallerSession();
        this.voipDialerService.cleanUp();
        this.voipDeviceService.cleanUpDevice();
        this.tokenRefreshService.stop();
        this.store.dispatch(LogoutAction({}));
      } else {
        this.changeRoutingViaEngageMode$.next('/login');
      }
    });
    this.allSubscriptions.push(isEngageModeOn$);
  }

  // Exists because the extension/app does not know about engageMode if pages.module isn't ever loaded (which it won't in the
  // case of auth guard/extension logouts.
  generalLogout(urlToNavigateTo?) {
    if (urlToNavigateTo) {
      this.store.dispatch(LogoutAction({ urlToNavigateTo }));
    } else {
      this.store.dispatch(LogoutAction({}));
    }
  }

  sendVerifyingCodeToEmail(email: string) {
    const params = { email };
    return this.http.post(`${environment.backend_url}/v1/password/reset`, params).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  oneTimePasswordResetLinkValidation(otpLink: string) {
    const params = { oneTimePasswordResetLink: otpLink };
    return this.http.post(`${environment.backend_url}/v1/password/otp/reset`, params).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  verifyCodeFromEmail(email: string, code: string) {
    const params = { email, code };
    return this.http.post(`${environment.backend_url}/v1/email/verify`, params).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  setNewPasswordAndConfirm(newPassword: string, otpLink: string) {
    const params = {
      old: null,
      new: newPassword,
      oneTimePasswordResetLink: otpLink ? otpLink : null,
    };
    return this.http.post(`${environment.backend_url}/v1/password/change`, params).pipe(
      map((response) => {
        return response;
      }),
    );
  }

  refreshToken(): Observable<AuthenticatedTokenResponse> {
    return this.http.get<AuthenticatedTokenResponse>(`${environment.backend_url}/v1/token/refresh`).pipe(map((res) => res));
  }

  getJwtRulesEngineToken(): Observable<RulesEngineToken> {
    return this.http.get<RulesEngineToken>(`${environment.backend_url}/v1/auth/GetJwtForCurrentUser`).pipe(map((res) => res));
  }

  verifyOneTimePassword(params: MultiFactorAuthVerificationRequest): Observable<AuthenticatedTokenResponse> {
    return this.http.post<AuthenticatedTokenResponse>(`${environment.backend_url}/v1/account/signin/mfa`, params).pipe(
      map((res) => {
        this.analyticsService.initAmplitude();
        return res;
      }),
    );
  }

  sendOneTimePassword(params: SendOneTimePasswordRequest) {
    return this.http.post<AuthenticatedTokenResponse>(`${environment.backend_url}/v1/account/mfa/otp`, params);
  }

  deviceId(): string {
    let id = 'privateBrowser';
    id = localStorage.getItem(this.key_deviceId);

    if (!id || typeof id !== 'string') {
      id = uuid.v4();
      localStorage.setItem(this.key_deviceId, id);
    }

    return id;
  }

  /**
   * Get observable of ready event (successful login)
   */
  public getReadyObs(): Observable<boolean> {
    return this.login(null).pipe(
      map((response) => {
        this.loggerService.log('Logged into engage - ' + !!response.authenticatedToken);
        this.store.dispatch(
          LoginSuccessAction(
            response.authenticatedToken,
            null,
            null,
            response?.profile,
            null,
            response.multiFactorAuthSetupRequired,
            response.name,
            response.mfaPhoneNumber,
            response.mfaEmail,
          ),
        );
      }),
      withLatestFrom(this.store.select(getProfile)),
      filter((profile) => !!profile),
      map(() => true),
      catchError((errorResponse) => {
        this.notyService.postMessage(
          new Message(
            MessageType.ERROR,
            `Your session has expired. Please log into your <a href="${environment.zoominfo_login_url}" target="_blank">SalesOS account.</a> Then refresh the Engage Chrome Extension.`,
          ),
        );
        return of(false);
      }),
    );
  }
}
