<button
  class="primary-btn"
  [automationId]="'primary-btn-'+text?.toLowerCase()"
  (click)="onClick($event)"
  [disabled]="disabled"
  [ngClass]="{ 'disabled': disabled, 'withIcon': iconSrc }"
>
  <ng-container *ngIf="!enableLoader; else Loader">
    <i *ngIf="iconSrc" [class]="'icon ' + iconSrc"></i>
    <span [innerHTML]="enableAppVerTranslation ? (text | stringTransform : (stringTransformService.userRole$ | async)) : text"></span>
  </ng-container>
</button>

<ng-template #Loader>
  <div class="btn-loader">
    <app-loader></app-loader>
  </div>
</ng-template>
