export const ContactCreateConfigVariable = {
  showMoreCommunicationFlag: false,
  showMoreCompanyFlag: false,
  showMoreCustomFieldsFlag: false,
  contactHeaderIcon: 'icon-user',
  communicationHeaderIcon: 'icon-call',
  companyHeaderIcon: 'icon-employee',
  addressHeaderIcon: 'icon-local_time',
  socialMediaHeaderIcon: 'icon-website',
  customFieldsHeaderIcon: 'icon-adjustment',
  placeholderFirstName: 'e.g. John',
  placeholderLastName: 'e.g. Smith',
  placeholderEmail: 'e.g. john.smith@zoominfo.com',
  placeholderJobTitle: 'e.g. Sales Manager',
  placeholderDepartment: 'e.g. Sales',
  placeholderSalutation: 'e.g. Mr.',
  placeholderDescription: 'e.g. Zoominfo is the most comprehensive...',
  placeholderPhone: 'e.g. 9-999-999-9999',
  placeholderCompanyName: 'e.g. Zoominfo',
  placeholderCompanyWebsite: 'e.g. www.zoominfo.com',
  placeholderIndustry: 'e.g. Industry',
  placeholderAnnualRevenue: 'e.g. 999,999,999',
  placeholderNumberOfEmployees: 'e.g. 1,000',
  placeholderCompanyLinkedin: 'e.g. www.linkedin.com/company/zoominfo',
  placeholderCompanyTwitter: 'e.g. www.twitter.com/zoominfo',
  placeholderCountry: 'e.g. USA',
  placeholderState: 'e.g. WA',
  placeholderCity: 'e.g. Vancouver',
  placeholderStreet: 'e.g. 805 Broadway St',
  placeholderZip: 'e.g. 98660',
  placeholderContactLinkedin: 'e.g. www.linkedin.com/company/zoominfo',
  placeholderContactTwitter: 'e.g. www.twitter.com/zoominfo',
  placeholderField: 'Type Something...',
  // tslint:disable-next-line
  emailPattern:
    '^(("")("".+?(?<!\\\\)"@)|(([A-Z0-9a-z]((\\.(?!\\.))|[-!#\\$%&\'\\*\\+/=\\?\\^`\\{\\}\\|~\\w])*)(?<=[A-Z0-9a-z])(_)?@))((\\[)(\\[(\\d{1,3}\\.){3}\\d{1,3}\\])|(([A-Z0-9a-z][-\\w]*[A-Z0-9a-z]*\\.)+[a-zA-Z0-9][\\-a-zA-Z0-9]{0,22}[a-zA-Z0-9]))$',
};
export const TREE_DATA = {
  'Primary Information': ['Job Title', 'Company', 'Phone', 'Salutation', 'Department', 'Description', 'Do Not Call', 'Do Not Email'],
  'Business Information': ['Industry', 'Annual Revenue', 'Number Of Employees', 'Company Website', 'Company LinkedIn', 'Company Twitter'],
  'Address Information': [
    'Street',
    'City',
    'Province/State',
    'Postal/Zip',
    'Country',
    'Mobile Phone',
    'Home Phone',
    'Assistant Phone',
    'Headquarter Phone',
    'Other Phone',
  ],
  Social: ["Contact's LinkedIn URL", "Contact's Twitter"],
};

export const contactForm = [
  {
    title: 'Phone',
    type: 'string',
    formControl: 'phone',
    showFlag: true,
    deleteButton: true,
    parent: 'primary',
  },
  {
    title: 'Company',
    type: 'string',
    formControl: 'company',
    showFlag: true,
    deleteButton: true,
    parent: 'primary',
  },
  {
    title: 'Job Title',
    type: 'string',
    formControl: 'jobTitle',
    showFlag: true,
    deleteButton: true,
    parent: 'primary',
  },
  {
    title: 'Salutation',
    type: 'string',
    formControl: 'salutation',
    showFlag: false,
    deleteButton: true,
    parent: 'primary',
  },
  {
    title: 'Department',
    type: 'string',
    formControl: 'department',
    showFlag: false,
    deleteButton: true,
    parent: 'primary',
  },
  {
    title: 'Do Not Email',
    type: 'boolean',
    formControl: 'doNotEmail',
    showFlag: false,
    deleteButton: true,
    parent: 'primary',
  },
  {
    title: 'Do Not Call',
    type: 'boolean',
    formControl: 'doNotCall',
    showFlag: false,
    deleteButton: true,
    parent: 'primary',
  },
  {
    title: 'Description',
    type: 'string',
    formControl: 'description',
    showFlag: false,
    deleteButton: true,
    parent: 'primary',
  },
  {
    title: 'Industry',
    type: 'string',
    formControl: 'industry',
    showFlag: false,
    deleteButton: true,
    parent: 'business',
  },
  {
    title: 'Annual Revenue',
    type: 'number',
    formControl: 'annualRevenue',
    showFlag: false,
    deleteButton: true,
    parent: 'business',
  },
  {
    title: 'Number Of Employees',
    type: 'number',
    formControl: 'numberOfEmployees',
    showFlag: false,
    deleteButton: true,
    parent: 'business',
  },
  {
    title: 'Company Website',
    formControl: 'companyPageUrl',
    type: 'string',
    showFlag: false,
    deleteButton: true,
    parent: 'business',
  },
  {
    title: 'Company LinkedIn',
    type: 'string',
    formControl: 'companyLinkedInUrl',
    showFlag: false,
    deleteButton: true,
    parent: 'business',
  },
  {
    title: 'Company Twitter',
    type: 'string',
    formControl: 'companyTwitterUrl',
    showFlag: false,
    deleteButton: true,
    parent: 'business',
  },
  {
    title: 'Street',
    type: 'string',
    formControl: 'street',
    showFlag: false,
    deleteButton: true,
    parent: 'address',
  },
  {
    title: 'City',
    type: 'string',
    formControl: 'city',
    showFlag: false,
    deleteButton: true,
    parent: 'address',
  },
  {
    title: 'Province/State',
    type: 'string',
    formControl: 'state',
    showFlag: false,
    deleteButton: true,
    parent: 'address',
  },
  {
    title: 'Postal/Zip',
    type: 'string',
    formControl: 'zip',
    showFlag: false,
    deleteButton: true,
    parent: 'address',
  },
  {
    title: 'Country',
    type: 'string',
    formControl: 'country',
    showFlag: false,
    deleteButton: true,
    parent: 'address',
  },
  {
    title: 'Mobile Phone',
    type: 'string',
    formControl: 'mobilePhone',
    showFlag: false,
    deleteButton: true,
    parent: 'address',
  },
  {
    title: 'Home Phone',
    type: 'string',
    formControl: 'homePhone',
    showFlag: false,
    deleteButton: true,
    parent: 'address',
  },
  {
    title: 'Assistant Phone',
    type: 'string',
    formControl: 'assistantPhone',
    showFlag: false,
    deleteButton: true,
    parent: 'address',
  },
  {
    title: 'Headquarter Phone',
    type: 'string',
    formControl: 'hqPhone',
    showFlag: false,
    deleteButton: true,
    parent: 'address',
  },
  {
    title: 'Other Phone',
    type: 'string',
    formControl: 'otherPhone',
    showFlag: false,
    deleteButton: true,
    parent: 'address',
  },
  {
    title: "Contact's LinkedIn URL",
    type: 'string',
    formControl: 'linkedInUrl',
    showFlag: false,
    deleteButton: true,
    parent: 'social',
  },
  {
    title: "Contact's Twitter",
    type: 'string',
    formControl: 'twitterUrl',
    showFlag: false,
    deleteButton: true,
    parent: 'social',
  },
];

export enum ContactCreateMode {
  'create',
  'update',
}
