import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, RouteConfigLoadEnd } from '@angular/router';
import { NotyService } from '../noty/noty.service';
import { MessageType } from '@zi-common/model/message/message-type';
import { Message } from '@zi-common/model/message/message.model';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class OAuthMessageService {
  readonly LOGIN_SUCCESS: string = 'Successfully connected to SalesOS.';

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private notyService: NotyService) {
    this.router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadEnd) {
        const oauthLoginErrorQp = _.get(this.activatedRoute, 'snapshot.queryParams.oauthLoginErr', '');
        if (oauthLoginErrorQp) {
          const decoded_error_query_param = atob(oauthLoginErrorQp);
          let errorQueryParamObj;

          try {
            errorQueryParamObj = JSON.parse(decoded_error_query_param);
          } catch (err) {
            errorQueryParamObj = {};
          }

          if (errorQueryParamObj.message) {
            if (errorQueryParamObj.code) {
              this.notyService.postMessage(new Message(MessageType.ERROR, errorQueryParamObj.message));
            } else {
              this.notyService.postMessage(new Message(MessageType.SUCCESS, this.LOGIN_SUCCESS));
            }
          }

          // Remove from URL
          const queryParams = { ...this.activatedRoute.snapshot.queryParams } || {};
          delete queryParams.oauthLoginErr;
          this.router.navigate([], queryParams);
        }
      }
    });
  }
}
