export enum GmailIframeActions {
  // make sure init is always 0
  Init,
  TemplateRoute,
  MergeVariablesRoute,
  SendLaterRoute,
  SendContact,
  ContactCreate,
  Dialog,
  Reset,
  TurnOff,
  Preview,
  Task,
  Salesflow,
}

export enum GmailIframeReceiveActions {
  CLOSE = 'CLOSE',
  TEMPLATE = 'TEMPLATE',
  VARIABLE = 'VARIABLE',
  SEND_LATER = 'SEND_LATER',
  CREATE_CONTACT = 'CREATE_CONTACT',
  CONTACT_CREATE_CLOSE = 'CONTACT_CREATE_CLOSE',
  CONTACT_CREATE_SUCCESS = 'CONTACT_CREATE_SUCCESS',
  CONTACT_CREATE_FAILURE = 'CONTACT_CREATE_FAILURE',
  TOGGLE_ON_OFF = 'TOGGLE_ON_OFF',
  DIALOG_ACCEPT = 'DIALOG_ACCEPT',
  FOLLOW_TASK = 'FOLLOW_TASK',
  FOLLOW_SALESFLOW = 'FOLLOW_SALESFLOW',
  CONTACT_IMPORT_SUCCESS = 'CONTACT_IMPORT_SUCCESS',
  CONTACT_IMPORT_FAILURE = 'CONTACT_IMPORT_FAILURE',
  EMAIL_YOURSELF_SUCCESS = 'EMAIL_YOURSELF_SUCCESS',
  EMAIL_YOURSELF_FAILURE = 'EMAIL_YOURSELF_FAILURE',
}
