export enum CATEGORY_TO_ICON {
  'Call' = 'icon-call',
  'PhoneCall' = 'icon-call',
  'AutomaticEmail' = 'icon-email_yourself',
  'Email' = 'icon-email',
  'ManualEmail' = 'icon-email',
  'None' = 'icon-other',
  'Other' = 'icon-other',
  'NotStarted' = 'icon-other',
  'Completed' = 'icon-other',
  'Failed' = 'icon-other',
  'Cancelled' = 'icon-other',
  'InProgress' = 'icon-other',
  'Paused' = 'icon-other',
  'Skipped' = 'icon-other',
  'Expired' = 'icon-other',
  'Replied' = 'icon-other',
  'Unsubscribed' = 'icon-other',
  'Blocklisted' = 'icon-other',
}

export enum taskTypesEnum {
  Emails = 'Email',
  AutomaticEmails = 'AutomaticEmail',
  Calls = 'Call',
  Other = 'Other',
  None = 'None',
}

export enum statusTypesEnum {
  NotStarted = 'NotStarted',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
  Pending = 'Pending',
  Skipped = 'Skipped',
  Unsubscribed = 'Unsubscribed',
  Blocklisted = 'Blocklisted',
  Failed = 'Failed',
  None = 'None',
  InProgress = 'InProgress',
  Expired = 'Expired',
  Replied = 'Replied',
  Paused = 'Paused',
  PausedOOO = 'PausedOOO',
  Default = '-',
}

export const STATUS_TYPES = {
  [statusTypesEnum.NotStarted]: 'Not Started',
  [statusTypesEnum.Completed]: 'Completed',
  [statusTypesEnum.Failed]: 'Failed',
  [statusTypesEnum.None]: 'None',
  [statusTypesEnum.Cancelled]: 'Cancelled',
  [statusTypesEnum.InProgress]: 'In Progress',
  [statusTypesEnum.Paused]: 'Paused',
  [statusTypesEnum.PausedOOO]: 'Paused',
  [statusTypesEnum.Skipped]: 'Skipped',
  [statusTypesEnum.Expired]: 'Expired',
  [statusTypesEnum.Replied]: 'Replied',
  [statusTypesEnum.Unsubscribed]: 'Unsubscribed',
  [statusTypesEnum.Blocklisted]: 'Blocklisted',
  [statusTypesEnum.Default]: '-',
};
