import { Injectable } from '@angular/core';
import { ApplicationState } from '@app/reducers';
import { select, Store } from '@ngrx/store';
import { MessageType } from '@zi-common/model/message/message-type';
import { Message } from '@zi-common/model/message/message.model';
import { NotyService } from '@zi-common/service/noty/noty.service';
import { Contact } from '@zi-core/data-model/contact.model';
import { contactEntityByManyIdSelector, getContactEntityByIdSelector } from '@zi-pages/contact/ngrx/state/contact-entity.state';
import { ContactDetailInternalDataService } from '@zi-pages/contact/service/contact-detail-internal-data.service';
import { ContactInternalDataService } from '@zi-pages/contact/service/contact-internal-data.service';
import { EngageActionTypeV2, EngageModeEntityStateCategory } from '@zi-pages/engage-mode-v2/engage-mode-v2.config';
import { EngageModeEntity } from '@zi-pages/engage-mode-v2/model/engage-mode-entity.model';
import { EngageModeAutoDialV2Service } from '@zi-pages/engage-mode-v2/service/engage-mode-auto-dial-v2.service';
import { EngageModeStorageV2Service } from '@zi-pages/engage-mode-v2/service/engage-mode-storage-v2.service';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, empty, Observable, ReplaySubject, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { CallLogState } from '@app/caller/state/call-log.state';
import { ResetCallLog } from '@app/caller/actions/call-log.actions';
import { getExtensionMode } from '@app/extensionMode.config';
import { MultisortOption } from '@app/core/data-model/multisort-option.model';
import { FilterSort } from '@app/core/enums/engage-filter.enum';

@Injectable({
  providedIn: 'root',
})
export class EngageModeV2Service {
  private isEngageMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isEngageMode$: Observable<boolean> = this.isEngageMode.asObservable();

  private entities: BehaviorSubject<Array<EngageModeEntity>>;
  entities$: Observable<Array<EngageModeEntity>>;

  private currentEntityIndex: BehaviorSubject<number>;
  currentEntityIndex$: Observable<number>;

  private globalActionType: BehaviorSubject<EngageActionTypeV2>;
  globalActionType$: Observable<EngageActionTypeV2>;

  private completedEntitiesCount: BehaviorSubject<number>;
  completedEntitiesCount$: Observable<number>;

  private skippedEntitiesCount: BehaviorSubject<number>;
  skippedEntitiesCount$: Observable<number>;

  private awaitingEntitiesCount: BehaviorSubject<number>;
  awaitingEntitiesCount$: Observable<number>;

  private loaded: BehaviorSubject<boolean>;
  loaded$: Observable<boolean>;

  private displayContactData: BehaviorSubject<boolean>;
  displayContactData$: Observable<boolean>;

  private contactNotAccessibleState: BehaviorSubject<boolean>;
  contactNotAccessibleState$: Observable<boolean>;

  accessDeniedSubscription: Subscription;
  accessDeniedSubscriptionFlag: boolean = false;

  private isInEngageCall: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isInEngageCall$: Observable<boolean> = this.isInEngageCall.asObservable();

  private selectedMultisort: BehaviorSubject<MultisortOption[]>;
  selectedMultisort$: Observable<MultisortOption[]>;
  isMultiSort: boolean;

  selectedCategory: ReplaySubject<EngageModeEntityStateCategory>;
  selectedCategory$: Observable<EngageModeEntityStateCategory>;

  constructor(
    private engageModeStorageService: EngageModeStorageV2Service,
    private engageModeAutoDialService: EngageModeAutoDialV2Service,
    private contactInternalDataService: ContactInternalDataService,
    private contactDetailInternalDataService: ContactDetailInternalDataService,
    private notyService: NotyService,
    private store: Store<ApplicationState>,
    private callLogStore: Store<CallLogState>,
  ) {}

  /**
   * returns the observable for isInEngageCall
   */
  getEngageModeIsInEngageCall(): Observable<boolean> {
    return this.isInEngageCall$;
  }

  /**
   * update the isEngageCall state
   * @param value - boolean
   */
  updateIsInEngageCall(value: boolean) {
    this.isInEngageCall.next(value);
  }

  getEngageModeState() {
    return this.isEngageMode.getValue();
  }

  initializeSubscriptions(displayError: boolean = true) {
    this.entities$ = this.entities.asObservable();
    this.currentEntityIndex$ = this.currentEntityIndex.asObservable();
    this.globalActionType$ = this.globalActionType.asObservable();
    this.displayContactData$ = this.displayContactData.asObservable();

    this.completedEntitiesCount = new BehaviorSubject(0);
    this.completedEntitiesCount$ = this.completedEntitiesCount.asObservable();

    this.skippedEntitiesCount = new BehaviorSubject(0);
    this.skippedEntitiesCount$ = this.skippedEntitiesCount.asObservable();

    this.awaitingEntitiesCount = new BehaviorSubject(this.entities.value.length);
    this.awaitingEntitiesCount$ = this.awaitingEntitiesCount.asObservable();

    this.loaded = new BehaviorSubject<boolean>(false);
    this.loaded$ = this.loaded.asObservable();

    this.contactNotAccessibleState = new BehaviorSubject<boolean>(false);
    this.contactNotAccessibleState$ = this.contactNotAccessibleState.asObservable();

    this.selectedCategory = new ReplaySubject();
    this.selectedCategory$ = this.selectedCategory.asObservable();

    this.selectedMultisort$ = this.selectedMultisort.asObservable();

    this.selectedMultisort$.pipe(distinctUntilChanged()).subscribe((multisort: MultisortOption[]) => {
      this.isMultiSort = multisort.length > 0;
      return this.engageModeStorageService.storeEngageModeMultiSort(multisort);
    });

    this.entities$.pipe(distinctUntilChanged()).subscribe((entities: Array<EngageModeEntity>) => {
      // update skipped & completed entities count
      this.skippedEntitiesCount.next(entities.filter((entity) => entity.contactSkipped).length);
      this.completedEntitiesCount.next(entities.filter((entity) => entity.emailCompleted || entity.callCompleted).length);
      this.awaitingEntitiesCount.next(entities.filter((entity) => !(entity.emailCompleted || entity.callCompleted || entity.contactSkipped)).length);

      this.engageModeStorageService.storeEngageModeEntitiesInSessionStorage(entities);
    });

    this.currentEntityIndex$.pipe().subscribe((currentEntityIndex: number) => {
      // load contact details every time the currentEntityIndex is updated
      this.loadContactDetailsForIndex(currentEntityIndex, displayError);
      displayError = true;
      this.engageModeStorageService.storeEngageModeCurrIndexInSessionStorage(currentEntityIndex);
      const entity = this.getEntityAtIndex(currentEntityIndex);
      let changeToCategory;
      if (entity?.callCompleted || entity?.emailCompleted) {
        changeToCategory = EngageModeEntityStateCategory.Done;
      } else if (entity?.contactSkipped) {
        changeToCategory = EngageModeEntityStateCategory.Skipped;
      } else {
        changeToCategory = EngageModeEntityStateCategory.Awaiting;
      }
      this.selectedCategory.next(changeToCategory);
    });

    this.globalActionType$.pipe(distinctUntilChanged()).subscribe((actionType: EngageActionTypeV2) => {
      this.engageModeStorageService.storeEngageModeActionTypeInSessionStorage(actionType);
    });

    this.isEngageMode$.subscribe((mode) => {
      this.engageModeStorageService.storeIsEngageModeInSessionStorage(mode);
    });

    this.displayContactData$.subscribe((displayData) => {
      this.engageModeStorageService.storeEngageModeDisplayContactDataDisplayInSessionStorage(displayData);
    });
  }

  initializeEngageMode(actionType: EngageActionTypeV2, engageItems: Array<EngageModeEntity>) {
    engageItems = this.saveDefaultSort(engageItems);
    this.selectedMultisort = new BehaviorSubject([]);
    this.entities = new BehaviorSubject(engageItems);
    this.currentEntityIndex = new BehaviorSubject(0);
    this.globalActionType = new BehaviorSubject(actionType);
    this.isEngageMode.next(true);
    this.displayContactData = new BehaviorSubject(true);

    this.initializeSubscriptions();
  }

  initializeEngageModeFromSessionStorage() {
    // for browser refresh on engage mode
    this.selectedMultisort = new BehaviorSubject(this.engageModeStorageService.loadEngageModeMultiSort());
    this.entities = new BehaviorSubject(this.engageModeStorageService.loadEngageModeEntitiesFromSessionStorage());
    this.currentEntityIndex = new BehaviorSubject(this.engageModeStorageService.loadEngageModeCurrIndexFromSessionStorage());
    this.globalActionType = new BehaviorSubject(this.engageModeStorageService.loadEngageModeActionTypeFromSessionStorage());
    const engageMode = this.engageModeStorageService.loadIsEngageModeFromSessionStorage();
    this.isEngageMode.next(_.isEmpty(engageMode) ? false : engageMode === 'true');
    const displayData = this.engageModeStorageService.loadEngageModeDisplayContactDataFromSessionStorage();
    this.displayContactData = new BehaviorSubject(_.isEmpty(displayData) ? true : displayData === 'true');

    this.initializeSubscriptions(!this.accessDeniedSubscriptionFlag || !getExtensionMode());
  }

  loadContactDetailsForIndex(entityIndex: number, displayError: boolean) {
    this.entities$.pipe(take(1)).subscribe(
      // ZE-29459 We should not dispacth GetContactAction here, since there can be timing issues
      // with other components consuming the ContactEntityState. e.g ContactFilterLogicComponent clears the state on NgonDestroy,
      // If we dispach GetContactAction here, it's possible our state will get wipe by ngOndestroy from ContactFilterLogicComponent.
      // Which leads to state management to issues.
      // As a result, the contact data retrieval has been moved to engage-mode-v2 component ngOnInit.
      (entities: Array<EngageModeEntity>) => {
        const entity: EngageModeEntity = entities[entityIndex];
        if (entity && entity.contactId) {
          // Reset the call log, when you move to a new contact on Engage mode.
          this.callLogStore.dispatch(ResetCallLog());
          const contactEntity$: Observable<Contact[]> = this.store.pipe(
            select(contactEntityByManyIdSelector([entity.contactId])),
            filter((contact: Contact[]) => !!contact?.length),
          );

          this.setAccessDeniedFlag(true);
          this.accessDeniedSubscription = this.store
            .select(getContactEntityByIdSelector([entity.contactId]))
            .pipe(
              map((contactRes) => {
                const error = _.get(contactRes, 'error');
                if (error) {
                  throw new Error(error);
                }
                this.contactNotAccessibleState.next(false);
              }),
              catchError(() => {
                this.contactNotAccessibleState.next(true);
                this.loaded.next(true);
                return empty();
              }),
            )
            .subscribe();

          combineLatest([
            contactEntity$,
            this.contactDetailInternalDataService.loadTasks(entity.contactId),
            this.contactDetailInternalDataService.loadSalesflows(entity.contactId),
          ])
            .pipe(
              distinctUntilChanged(_.isEqual),
              map(([contactList, tasks, salesflows]) => {
                entity.contactData = contactList[0];
                entity.numOfTasks = tasks.length;
                entity.numOfSalesflows = salesflows.length;
                this.entities.next(entities);
                this.loaded.next(true);
              }),
              catchError(() => {
                this.notyService.postMessage(new Message(MessageType.ERROR, 'Error loading profile for selected contact.'));
                this.loaded.next(true);
                return empty();
              }),
            )
            .subscribe();
        }
      },
      () => this.loaded.next(true),
    );
  }

  getEntities(): Array<EngageModeEntity> {
    return this.entities ? this.entities.getValue() : null;
  }

  getCurrentEntityIndex(): number {
    return this.currentEntityIndex.getValue();
  }

  getEntityAtIndex(entityIndex: number): EngageModeEntity {
    if (this.entities) {
      if (entityIndex >= 0 && entityIndex < this.entities.getValue().length) {
        return this.entities.getValue()[entityIndex];
      }
    }
    return null;
  }

  updateEntityContact(entityIndex: number, contactData: Contact) {
    const entities: Array<EngageModeEntity> = [...this.entities.getValue()];
    if (entities[entityIndex]) {
      entities[entityIndex].contactData = contactData;
      this.entities.next(entities);
    }
  }

  navigateToEntityIndex(entityIndex: number) {
    if (entityIndex >= 0 && entityIndex < this.entities.getValue().length) {
      this.loaded.next(false);
      this.currentEntityIndex.next(entityIndex);
    }
  }

  skipEntity(entityIndex: number, navigateToNextIndex: boolean) {
    const newEntities: Array<EngageModeEntity> = [...this.entities.getValue()];
    // set skipped status only if email/call hasn't been completed for the entity
    if (!newEntities[entityIndex].emailCompleted && !newEntities[entityIndex].callCompleted) {
      newEntities[entityIndex].contactSkipped = true;
      this.entities.next(newEntities);
    }
    entityIndex++;
    if (this.isMultiSort) {
      // rearrange entities when multisort enabled to maintain the sort after skip
      this.reArrangeEntities();
    }
    if (navigateToNextIndex) {
      this.navigateToEntityIndex(entityIndex);
    }
  }

  deleteEntity(entityIndex: number) {
    const newEntities: Array<EngageModeEntity> = [...this.entities.getValue()];
    if (entityIndex >= 0 && entityIndex < this.entities.getValue().length) {
      newEntities.splice(entityIndex, 1);
    }
    this.entities.next(newEntities);
  }

  completedEvent(entityIndex: number, eventType: string, eventCompleted: boolean, navigateToNextIndex = true) {
    const newEntities: Array<EngageModeEntity> = [...this.entities.getValue()];
    newEntities[entityIndex][eventType] = eventCompleted;
    newEntities[entityIndex].contactSkipped = false;
    this.entities.next(newEntities);
    // completed contacts exists before awaiting contacts in the entities array, so need to increase index by 1
    entityIndex++;
    if (this.isMultiSort && (navigateToNextIndex || eventType === 'callCompleted')) {
      // rearrange entities when multisort enabled to maintain the sort after complete
      // call rearrange only if navigateToNextIndex is true to prevent move to another contact when in call/auto dial enabled
      this.reArrangeEntities();
    }
    if (navigateToNextIndex) {
      this.navigateToEntityIndex(entityIndex);
    }
  }

  /**
   * Re-Arrange entities after skip/complete events in order to maintain the sort
   * When moving entities from one category to another
   */
  reArrangeEntities(): void {
    let awaitingEntities = [...this.entities.value].filter((e: EngageModeEntity) => !(e.emailCompleted || e.callCompleted) && !e.contactSkipped);
    let completedEntities = [...this.entities.value].filter((e: EngageModeEntity) => e.emailCompleted || e.callCompleted);
    let skippedEntities = [...this.entities.value].filter((e: EngageModeEntity) => e.contactSkipped);
    const finalEntitiesList = completedEntities.concat(skippedEntities).concat(awaitingEntities);
    this.entities.next(finalEntitiesList);
  }

  removeEntityTaskDetailsAfterCompletion(entity: EngageModeEntity) {
    const newEntities: Array<EngageModeEntity> = [...this.entities.getValue()];
    const updatedEntity = newEntities.find((e) => e.taskId === entity.taskId);
    if (updatedEntity) {
      updatedEntity.taskId = null;
      updatedEntity.taskType = null;
      updatedEntity.taskTemplateId = null;
      this.entities.next(newEntities);
    }
  }

  toggleDisplayContactData() {
    const displayData = this.displayContactData.getValue();
    this.displayContactData.next(!displayData);
  }

  replaceEntity(entityToReplace: EngageModeEntity, newEntities: Array<EngageModeEntity>, entityIndex?: number) {
    const existingEntities: Array<EngageModeEntity> = [...this.entities.getValue()];

    // filter out newEntities already present in engage mode
    newEntities = newEntities.filter((ne) => !existingEntities.find((e) => e.contactId === ne.contactId));

    const entityToReplaceIndex = existingEntities.indexOf(entityToReplace);
    if (entityToReplaceIndex !== -1) {
      existingEntities.splice(entityToReplaceIndex, 1);
      existingEntities.splice(entityToReplaceIndex, 0, ...newEntities);
      this.entities.next(existingEntities);

      if (entityIndex) {
        this.currentEntityIndex.next(entityIndex);
      } else {
        const currentIndex = this.getCurrentEntityIndex();
        // if currentIndex is after the entity being replaced & entityToReplace is being replaced by 2 or more entities,
        // we need to update the current index to avoid directing the user to another entity on the replace
        if (currentIndex > entityIndex && newEntities.length > 1) {
          this.currentEntityIndex.next(currentIndex + (newEntities.length - 1));
        }
      }
    }
  }

  setAccessDeniedFlag(value: boolean) {
    this.accessDeniedSubscriptionFlag = value;
  }

  /**
   * save the initial sort of entities when first landing in engage mode
   * @param items
   * @returns
   */
  saveDefaultSort(items: EngageModeEntity[]): EngageModeEntity[] {
    return items.map((item: EngageModeEntity, index) => {
      return { ...item, orderId: index };
    });
  }

  /**
   * sort the entities list based on the multisort value.
   * @param multisort
   */
  sortEntities(multisort: MultisortOption[]): void {
    this.engageModeStorageService.storeEngageModeMultiSort(multisort);
    let sortByValues = [];
    let sortOrderValues = [];

    if (!multisort.length) {
      this.sortByDefault();
      return;
    }

    multisort.forEach((sort) => {
      switch (sort.sortBy) {
        case FilterSort.LocalTime:
          sortByValues.push((e: EngageModeEntity) => e.contactData.timezoneOffset);
          break;
        case FilterSort.Name:
          sortByValues.push((e: EngageModeEntity) => e.contactData.firstName + ' ' + e.contactData.lastName);
          break;
        case FilterSort.Company:
          sortByValues.push((e: EngageModeEntity) => e.contactData.company);
          break;
      }
      if (sort.sortOrder === 1) {
        sortOrderValues.push('desc');
      } else {
        sortOrderValues.push('asc');
      }
    });

    const completedEntities = [...this.entities.value].filter((e: EngageModeEntity) => e.emailCompleted || e.callCompleted);
    const skippedEntities = [...this.entities.value].filter((e: EngageModeEntity) => e.contactSkipped);
    const awaitingEntities = [...this.entities.value].filter((e: EngageModeEntity) => !(e.emailCompleted || e.callCompleted) && !e.contactSkipped);

    const sortedAwaitingEntities = _.orderBy(awaitingEntities, sortByValues, sortOrderValues);
    const sortedCompletedEntities = _.orderBy(completedEntities, sortByValues, sortOrderValues);
    const sortedSkippedEntities = _.orderBy(skippedEntities, sortByValues, sortOrderValues);
    const finalEntitiesList = sortedCompletedEntities.concat(sortedSkippedEntities).concat(sortedAwaitingEntities);
    const firstAwaitingEntityIndex = sortedAwaitingEntities.length == 0 ? 0 : sortedCompletedEntities.length;
    this.entities.next(finalEntitiesList);
    this.currentEntityIndex.next(firstAwaitingEntityIndex);
  }

  private sortByDefault() {
    let sortByValues = [];
    let sortOrderValues = [];

    this.isMultiSort = false;
    // for the default sort value
    sortByValues.push((e: EngageModeEntity) => e.orderId);
    sortOrderValues.push('asc');

    const sortedEntitiesList = _.orderBy([...this.entities.value], sortByValues, sortOrderValues);
    const firstAwaitingEntityIndex = sortedEntitiesList.findIndex((e: EngageModeEntity) => !(e.emailCompleted || e.callCompleted) && !e.contactSkipped);
    this.entities.next(sortedEntitiesList);
    this.currentEntityIndex.next(firstAwaitingEntityIndex);
  }

  unsubscribeOnExit() {
    this.entities.complete();
    this.currentEntityIndex.complete();
    this.globalActionType.complete();
    this.completedEntitiesCount.complete();
    this.skippedEntitiesCount.complete();
    this.awaitingEntitiesCount.complete();
    this.loaded.complete();
    this.isEngageMode.next(false);
    this.displayContactData.complete();
    this.contactNotAccessibleState.complete();
    this.engageModeStorageService.clearEngageModeDataInSessionStorage();
    this.accessDeniedSubscription?.unsubscribe();
    this.setAccessDeniedFlag(false);
    this.selectedMultisort.complete();
  }
}
