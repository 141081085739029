import { CallLogInfo, initialCallLogInfo, initialManulalLogCallInfo, ManulalLogCallInfo } from '@app/caller/interface/call-log-info';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface CallLogState {
  callLogInfo: CallLogInfo;
  contactStatus: ContactStatusState;
  manualLogCallInfo: ManulalLogCallInfo;
}

export interface ContactStatusState {
  contactStatus: string;
  contactStatusColor: string;
}

export const initialContactStatusState: ContactStatusState = {
  contactStatus: '',
  contactStatusColor: null,
};

export const initialCallLogState: CallLogState = {
  callLogInfo: initialCallLogInfo,
  contactStatus: initialContactStatusState,
  manualLogCallInfo: initialManulalLogCallInfo,
};

export const getCallLogState = createFeatureSelector<CallLogState>('call-log');

export const getCallLogInfo = createSelector(getCallLogState, (state) => state.callLogInfo);

export const getCallLogUserInput = createSelector(getCallLogInfo, (callLogInfo) => callLogInfo.input);
export const getCallLogResult = createSelector(getCallLogUserInput, (input) => input.result);
export const getCallLogSentiment = createSelector(getCallLogUserInput, (input) => input.sentiment);
export const getCallLogSubject = createSelector(getCallLogUserInput, (input) => input.subject);
export const getCallLogComment = createSelector(getCallLogUserInput, (input) => input.comment);
export const getCallLogDate = createSelector(getCallLogUserInput, (input) => input.date);
export const getCallLogTime = createSelector(getCallLogUserInput, (input) => input.time);
export const getCallLogType = createSelector(getCallLogUserInput, (input) => input.logType);
export const getCallLogDuration = createSelector(getCallLogUserInput, (input) => input.duration);
export const getCallLogPhone = createSelector(getCallLogUserInput, (input) => input.duration);

export const getCallLogFollowUpTask = createSelector(getCallLogInfo, (callLogInfo) => callLogInfo.followUpTask);

export const getContactStatusState = createSelector(getCallLogState, (state) => state.contactStatus);
export const getContactStatus = createSelector(getContactStatusState, (contactStatusState) => contactStatusState.contactStatus);
export const getContactStatusColor = createSelector(getContactStatusState, (contactStatusState) => contactStatusState.contactStatusColor);
export const getManualLogCallInfo = createSelector(getCallLogState, (state) => state.manualLogCallInfo);
export const getManualLogCallDialogOpen = createSelector(getManualLogCallInfo, (manualLogCallInfo) => manualLogCallInfo.isDialogOpen);
export const getManualLogCallContactId = createSelector(getManualLogCallInfo, (manualLogCallInfo) => manualLogCallInfo.contactId);
