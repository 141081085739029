import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { PickListRequest, PickListResponse, PickListValue } from '@zi-common/interface/contactStatus';

@Injectable({
  providedIn: 'root',
})
export class PicklistStatusService {
  constructor(private httpClient: HttpClient) {}

  getPickList(entity: string): Observable<PickListResponse> {
    return this.httpClient.get<any>(`${environment.backend_url}/v1/picklist/${entity}/Status`);
  }

  setPickList(entity: string, defaultVal: string, newStatusesList: PickListValue[]): Observable<PickListResponse> {
    const params: PickListRequest = { resourceType: entity, fieldName: 'Status', default: defaultVal, values: newStatusesList };
    return this.httpClient.post<any>(`${environment.backend_url}/v1/picklist/${entity}/Status`, params);
  }
}
