import { Component, OnInit, Inject } from '@angular/core';
import { DoziFeature } from '@app/core/data-model/dozi-integration/enums/dozi-feature.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DoziIntegrationService } from '@app/core/service/dozi-integration.service';
import { ContactUsRequest } from '@app/core/data-model/dozi-integration/dozi-contact-us-request.model';
import { ContactUsType } from '@app/core/data-model/dozi-integration/enums/contact-us-type.model';
import { DoziContactUsDialogComponent } from '../dozi-contact-us-dialog/dozi-contact-us-dialog.component';
import * as _ from 'lodash';
import { ApplicationState } from '@app/reducers';
import { Store } from '@ngrx/store';
import { getAuthState } from '@app/core/ngrx/state/auth.state';
import { take, map } from 'rxjs/operators';
import { AnalyticsService } from '@app/core/service/analytics.service';
import { ClickedLearnMoreLock, ClickedContactUsLock } from '@app/core/data-model/analytics/dozi-events.model';

@Component({
  selector: 'app-dozi-features-dialog',
  templateUrl: './dozi-features-dialog.component.html',
  styleUrls: ['./dozi-features-dialog.component.scss'],
})
export class DoziFeaturesDialogComponent implements OnInit {
  isLoading = false;
  doziFeatureToShow: DoziFeature;
  fullName: string;
  contactType: ContactUsType;
  videoUrl: string;
  title: string;
  contents: string[] = [];

  doziFeature = DoziFeature;

  constructor(
    private dialogRef: MatDialogRef<DoziFeaturesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private doziService: DoziIntegrationService,
    private dialog: MatDialog,
    private appStore: Store<ApplicationState>,
    private analyticsService: AnalyticsService,
  ) {
    this.doziFeatureToShow = _.get(this.data, 'doziFeatureToShow', DoziFeature.contactUs);
    this.contactType = _.get(this.data, 'contactType', ContactUsType.NONE);
    this.title = _.get(this.data, 'title', '');
    this.videoUrl = _.get(this.data, 'videoUrl', '');
    this.contents = _.get(this.data, 'contents', '');
  }

  ngOnInit() {
    this.appStore
      .select(getAuthState)
      .pipe(
        take(1),
        map((res) => {
          this.fullName = _.get(res, 'profile.name');
          this.SendClickEvent();
        }),
      )
      .subscribe();
    this.analyticsService.clickedLearnMoreLock(new ClickedLearnMoreLock({ feature: this.doziFeatureToShow }));
  }

  onClose() {
    this.dialogRef.close();
  }

  onContactUs(feature: DoziFeature) {
    this.analyticsService.clickedContactUsLock(new ClickedContactUsLock({ feature: this.doziFeatureToShow }));
    this.SendRequest(feature);
  }

  /**
   * Send empty click event with feature for campaign tracking
   */
  private SendClickEvent() {
    const req: ContactUsRequest = {
      source: this.doziService.getContactUsSource(this.doziFeatureToShow),
      name: this.fullName,
      type: ContactUsType.NONE, // Determines a click event
    };

    // Do nothing this is a ghost call
    this.doziService.sendContactusRequest(req).subscribe((res) => res);
  }

  private SendRequest(featureSource: DoziFeature) {
    this.isLoading = true;
    const req: ContactUsRequest = {
      source: this.doziService.getContactUsSource(featureSource),
      name: this.fullName,
      type: this.contactType,
    };

    this.doziService.sendContactusRequest(req).subscribe(
      (res) => {
        this.isLoading = false;
        this.dialogRef.close();
        this.openContactUs();
      },
      (error) => {
        this.isLoading = false;
        this.dialogRef.close();
        this.openContactUs();
      },
    );
  }

  private openContactUs() {
    return this.dialog.open(DoziContactUsDialogComponent, {
      width: '434px',
      height: '381px',
      data: {},
    });
  }
}
