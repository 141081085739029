import { DotsOption } from '@app/common/interface/dots-option';
import { environment } from '@env/environment';
import { Tab } from '@zi-common/component/tabs/tabs.config';
import { ToggleIconInterface } from '@zi-common/component/toggle-icon-without-background/toggle-icon-without-background.component';
import { ContactActionsNames } from '@zi-common/model/actions/contactActionNames';
import { CompanyAccountFilter } from '@zi-common/model/company-account-details.model';
import { ScopeOption, SelectComponentObject, SortOption, SortSection } from '@zi-common/model/dropdown/select.model';
import { EmptyStateModel } from '@zi-common/model/empty-state.model';
import { FilterNode } from '@zi-core/data-model/filter-node.model';
import { CompanyAccountDetailsTab } from '@zi-core/enums/company-account.enum';
import { FilterSort, Level, PageSelection, Scope } from '@zi-core/enums/engage-filter.enum';
import { CompanyAccountsStatistics } from '@zi-core/data-model/company-accounts-statistics.model';
import { CompanyAccountActionNames } from '@zi-core/enums/company-account-action-names.enum';
import { ContactMoreOptionName } from '@zi-common/model/contact-options.enum';

export const AccountCompanyPlaceholderDomain = `${environment.icons_url}/company-avatar.svg`;

export const sortOptionsContacts: SortSection[] = [
  {
    displayName: '',
    options: [
      new SortOption('Name', FilterSort.Name),
      new SortOption('Job Title', FilterSort.JobTitle),
      new SortOption('Department', FilterSort.Department),
      new SortOption('Last Touched', FilterSort.LastTouched),
    ],
  },
];

export const accountBulkActions = [new SelectComponentObject(CompanyAccountActionNames.REMOVE_ACCOUNT)];

export const selectionActionsContact = [
  new SelectComponentObject('Select All', true, false, '', PageSelection.All),
  new SelectComponentObject('Select Page', true, false, '', PageSelection.Page),
  new SelectComponentObject('Clear Page', true, false, '', PageSelection.ClearPage),
  new SelectComponentObject('Clear All', true, false, '', PageSelection.ClearAll),
];

export const accountDetailEmptyState: EmptyStateModel = {
  imgSrc: '/ebr2022_2x_empty_state.png',
  imgSize: '520px',
  imageHeight: '238px',
  titleText: 'Account Not Found',
  subTitleText: ``,
};

export const contactEmptyState = {
  img: '/ebr2022_2x_empty_state.png',
  imgSize: '319px',
  imageHeight: '146px',
  title: 'No contacts yet',
  button: 'Add Contacts',
};

export const companyAccountEmptyState: EmptyStateModel = {
  imgSrc: '/ebr2022_2x_empty_state.png',
  imgSize: '520px',
  imageHeight: '238px',
  titleText: 'No Accounts Added',
  subButtonText: 'Import Account from CSV',
  subLinkText: 'Create Account Manually',
};

export const companyAccountFilterEmptyState: EmptyStateModel = {
  imgSrc: '/ebr2022_2x_empty_state.png',
  imgSize: '319px',
  imageHeight: '146px',
  titleText: 'No Accounts Found',
};

export const accountTabs: Tab[] = [
  { displayName: 'Engage', value: 'engage', isSelected: true },
  { displayName: 'Recommended', value: 'recommended', isSelected: false },
];

export const CompanyAccountDetailsRightTabs: Tab[] = [
  new Tab('Activities', CompanyAccountDetailsTab.ACTIVITIES),
  new Tab('Reps', CompanyAccountDetailsTab.REPS),
  new Tab('Salesflows', CompanyAccountDetailsTab.SALESFLOWS),
  new Tab('Notes', CompanyAccountDetailsTab.NOTES),
];

export const orgChartToggleIcon: ToggleIconInterface = {
  icon: 'icon-all_small',
  tooltip: '',
  disabled: false,
  name: 'orgChart',
};

export const listViewToggleIcon: ToggleIconInterface = {
  icon: 'icon-display_1',
  tooltip: '',
  disabled: false,
  name: 'listView',
};

export const defaultAccountIdFilterNode: FilterNode = {
  hitCount: 0,
  list: [],
  referenceId: 0,
  key: CompanyAccountFilter.CompanyAccountId,
  value: '',
  category: 'ContactFields',
  type: 1,
  entity: 'Contact',
};

export const companyContactDetailmoreOptions: DotsOption[] = [
  {
    displayName: ContactMoreOptionName.Edit,
    isLink: false,
    link: '',
    disabled: false,
  },
  {
    displayName: ContactMoreOptionName.SubscriptionSettings,
    isLink: false,
    link: '',
    disabled: false,
  },
];

export const companyAccountDetailHeaderActions: DotsOption[] = [
  {
    displayName: CompanyAccountActionNames.TWITTER,
    value: CompanyAccountActionNames.TWITTER,
    disabled: false,
  },
  {
    displayName: CompanyAccountActionNames.WEBSITE,
    value: CompanyAccountActionNames.WEBSITE,
    disabled: false,
  },
  {
    displayName: CompanyAccountActionNames.EDIT_ACCOUNT,
    value: CompanyAccountActionNames.EDIT_ACCOUNT,
    disabled: false,
  },
  {
    displayName: CompanyAccountActionNames.REMOVE_ACCOUNT,
    value: CompanyAccountActionNames.REMOVE_ACCOUNT,
    disabled: false,
  },
];

export const searchDebounceTime = 300;

export const sortOptionsAccounts: SortSection[] = [
  {
    displayName: '',
    options: [
      new SortOption('Last Touched', FilterSort.LastTouched),
      new SortOption('Number of Contacts', FilterSort.NumberOfContacts),
      new SortOption('Account Name', FilterSort.Name),
      new SortOption('Last Added', FilterSort.LastAdded),
      new SortOption('Last Updated', FilterSort.LastUpdated),
    ],
  },
];

export const bulkActions = [
  new SelectComponentObject(ContactActionsNames.Email),
  new SelectComponentObject(ContactActionsNames.DownloadCSV),
  new SelectComponentObject(ContactActionsNames.ChangeStatus),
  new SelectComponentObject(ContactActionsNames.AssignTask),
  new SelectComponentObject(ContactActionsNames.AddToSalesflow),
  new SelectComponentObject(ContactActionsNames.AddOrRemoveTag),
  new SelectComponentObject(ContactActionsNames.Unsubscribe),
  new SelectComponentObject(ContactActionsNames.AssociateToAccount),
  new SelectComponentObject(ContactActionsNames.Delete),
  new SelectComponentObject(ContactActionsNames.ChangeOwner),
];

export const selectionActionsAccount = [
  new SelectComponentObject('Select All', true, false, '', PageSelection.All),
  new SelectComponentObject('Select Page', true, false, '', PageSelection.Page),
  new SelectComponentObject('Clear Page', true, false, '', PageSelection.ClearPage),
  new SelectComponentObject('Clear All', true, false, '', PageSelection.ClearAll),
];

export const companyAccountsListViewRowActions = () => {
  const rowOptions: DotsOption[] = [
    {
      displayName: CompanyAccountActionNames.TWITTER,
      value: CompanyAccountActionNames.TWITTER,
      disabled: false,
    },
    {
      displayName: CompanyAccountActionNames.WEBSITE,
      value: CompanyAccountActionNames.WEBSITE,
      disabled: false,
    },
    {
      displayName: CompanyAccountActionNames.EDIT_ACCOUNT,
      value: CompanyAccountActionNames.EDIT_ACCOUNT,
      disabled: false,
    },
    {
      displayName: CompanyAccountActionNames.ASSOCIATE_CONTACTS,
      value: CompanyAccountActionNames.ASSOCIATE_CONTACTS,
      disabled: false,
    },
    {
      displayName: CompanyAccountActionNames.REMOVE_ACCOUNT,
      value: CompanyAccountActionNames.REMOVE_ACCOUNT,
      disabled: false,
    },
    {
      displayName: CompanyAccountActionNames.IMPORT_CONTACTS,
      value: CompanyAccountActionNames.IMPORT_CONTACTS,
      disabled: false,
    },
  ];
  return rowOptions;
};

export const defaultCompanyAccountStatistics: CompanyAccountsStatistics = {
  totalNumberOfAccounts: 0,
  totalNumberOfContacts: 0,
  totalNumberOfEmailOpens: 0,
  totalNumberOfLinkClicks: 0,
  totalNumberOfEmailReplies: 0,
  totalNumberOfCalls: 0,
  totalNumberOfEmails: 0,
};

export interface AccountScopeOptions {
  organization: ScopeOption;
  owner: ScopeOption;
  team: ScopeOption;
}

export const scopeOptions: AccountScopeOptions = {
  organization: new ScopeOption(0, Scope.Organization, Level.Organization),
  owner: new ScopeOption(1, Scope.Owner, Level.User),
  team: new ScopeOption(2, Scope.Team, Level.Team),
};
