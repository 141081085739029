import { Action, createReducer, on } from '@ngrx/store';
import { EmailerVersionState, initialEmailerVersionState } from '@zi-pages/admin/ngrx/state/emailer-version.state';
import { LoadEmailerVersionAction, LoadEmailerVersionFailureAction, LoadEmailerVersionSuccessAction } from '@zi-pages/admin/ngrx/action/emailer-version.action';

const emailerVersionReducer = createReducer(
  initialEmailerVersionState,
  on(LoadEmailerVersionAction, (state: EmailerVersionState) => {
    return {
      version: null,
      loaded: false,
      loading: true,
      error: null,
    };
  }),

  on(LoadEmailerVersionSuccessAction, (state: EmailerVersionState, { version }) => {
    return {
      version,
      loaded: true,
      loading: false,
      error: null,
    };
  }),

  on(LoadEmailerVersionFailureAction, (state: EmailerVersionState, { error }) => {
    return {
      version: null,
      loaded: false,
      loading: false,
      error,
    };
  }),
);

export function EmailerVersionReducer(state: EmailerVersionState, action: Action): EmailerVersionState {
  return emailerVersionReducer(state, action);
}
