import { createAction, props } from '@ngrx/store';

export const StartAdaLiveAgentInitAction = createAction('[AdaLiveAgent] Start initialization');

export const AdaLiveAgentInitSuccessAction = createAction('[AdaLiveAgent] Initialization successfully completed');

export const AdaLiveAgentInitFailureAction = createAction('[AdaLiveAgent] Initialization unsuccessful', props<{ error: any }>());

export const AdaLiveAgentDisconnectAction = createAction('[AdaLiveAgent] Start Disconnect');

export const AdaLiveAgentDisconnectSuccessAction = createAction('[AdaLiveAgent] Disconnected');
