import { UserPermissions } from '../data-model/profile.model';
import { Permissions } from './permissions';

export const DomainsWhiteList = ['zoominfo.com', 'discoverorg.com', 'tellwise.com'];

// Map of product roles to permissions
const productRolesToPermissionsMap = {
  'dat:new': Permissions.ZI_NEWS,
  'fea:nfe': Permissions.ZI_NEWS_FEED,
  'dat:sco': Permissions.ZI_SCOOPS,
  'dat:org': Permissions.ZI_ORG_CHARTS,
  'dat:tec': Permissions.TECHNOGRAPHICS,
  'dat:caf': Permissions.COMPANY_ATTRIBUTES_DATA_AND_SEARCH_FILTER,
  'fea:i2o': Permissions.WEBSIGHTS,
  'fea:nb': Permissions.NEVERBOUNCE,
};

export const zoomRoleToPermissionsMap = {
  ...productRolesToPermissionsMap,
};

export interface GetUserPermissionsResponse {
  role?: {
    permissions: UserPermissions[];
  };
}
