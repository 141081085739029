import { Action, createReducer, on } from '@ngrx/store';
import { initialSmsMessageState, SmsConversationState, SmsMessageState } from '@zi-pages/sms/ngrx/state/sms-message.state';
import {
  DeleteSmsMessageAction,
  DeleteSmsMessageFailureAction,
  DeleteSmsMessageSuccessAction,
  GetSmsConversationByContactMobileAction,
  GetSmsConversationByContactMobileFailureAction,
  GetSmsConversationByContactMobileSuccessAction,
  LoadSmsConversationsForUserAction,
  LoadSmsConversationsForUserFailureAction,
  LoadSmsConversationsForUserSuccessAction,
  ReceiveSendStatusCallbackAction,
  ReceiveSmsMessageAction,
  ResetSmsConvoStateAction,
  SearchSmsContactsAction,
  SendSmsMessageAction,
  SendSmsMessageFailureAction,
  SendSmsMessageSuccessAction,
  UpdateIsReadForConversationAction,
  UpdateIsReadForConversationSuccessAction,
  UpdateSmsMessageContactAction,
  UpdateTotalUnreadSMSAction,
} from '@zi-pages/sms/ngrx/action/sms-message.action';
import { defaultLoadingState, failureLoadedState, loadingState, successLoadedState } from '@zi-core/ngrx/config/ngrx-loading-states.config';
import { SmsConversationItem } from '@zi-pages/sms/model/sms-conversation.dto';
import { SmsMessageDirection } from '@zi-pages/sms/model/sms-message-direction';
import { SmsMessageStatus } from '@zi-pages/sms/model/sms-message-status';
import * as _ from 'lodash';
import { SmsUserConversationItem } from '@zi-pages/sms/model/sms-user-conversations.dto';
import { Contact } from '@zi-core/data-model/contact.model';

const smsMessageReducer = createReducer(
  initialSmsMessageState,

  on(LoadSmsConversationsForUserAction, (state: SmsMessageState, { pageNum, pageSize }) => {
    return {
      ...state,
      smsContactList: {
        ...state.smsContactList,
        ...loadingState,
      },
    };
  }),

  on(SearchSmsContactsAction, (state: SmsMessageState, { data }) => {
    return {
      ...state,
      smsContactList: {
        ...state.smsContactList,
        ...loadingState,
      },
    };
  }),

  on(LoadSmsConversationsForUserSuccessAction, (state: SmsMessageState, { smsContactList }) => {
    return {
      ...state,
      smsContactList: {
        list: { ...smsContactList },
        ...successLoadedState,
      },
      selectedConversation: {
        smsConversation: null,
        selectedContactInfo: null,
        sendMsgLoading: false,
        ...defaultLoadingState,
      },
    };
  }),

  on(LoadSmsConversationsForUserFailureAction, (state: SmsMessageState, { error }) => {
    return {
      ...state,
      smsContactList: {
        ...state.smsContactList,
        ...failureLoadedState,
        error,
      },
      selectedConversation: {
        smsConversation: null,
        selectedContactInfo: null,
        sendMsgLoading: false,
        ...defaultLoadingState,
      },
    };
  }),

  on(GetSmsConversationByContactMobileAction, (state: SmsMessageState, { contactMobileNumber, contactInfo }) => {
    return {
      ...state,
      selectedConversation: {
        ...state.selectedConversation,
        selectedContactInfo: contactInfo,
        ...loadingState,
      },
    };
  }),

  on(GetSmsConversationByContactMobileSuccessAction, (state: SmsMessageState, { conversationResp, contactInfo }) => {
    return {
      ...state,
      selectedConversation: {
        ...state.selectedConversation,
        selectedContactInfo: contactInfo,
        smsConversation: { ...conversationResp },
        ...successLoadedState,
      },
    };
  }),

  on(GetSmsConversationByContactMobileFailureAction, (state: SmsMessageState, { error }) => {
    return {
      ...state,
      selectedConversation: {
        ...state.selectedConversation,
        ...failureLoadedState,
        error,
      },
    };
  }),

  on(SendSmsMessageAction, (state: SmsMessageState, { messageBody, resendMessage }) => {
    if (!!resendMessage) {
      const smsConvoDateMap = editConvoMapForFailureAction(
        resendMessage,
        resendMessage.sentAt,
        state.selectedConversation.smsConversation.smsConvoDateMap,
        true,
        true,
        false,
      );
      return {
        ...state,
        selectedConversation: {
          ...state.selectedConversation,
          sendMsgLoading: true,
          smsConversation: {
            ...state.selectedConversation.smsConversation,
            smsConvoDateMap,
          },
        },
      };
    } else {
      return {
        ...state,
        selectedConversation: {
          ...state.selectedConversation,
          sendMsgLoading: true,
        },
      };
    }
  }),

  on(SendSmsMessageSuccessAction, (state: SmsMessageState, { sendMessageResp, contact, trackingIdForResend, resendOriginalSendAt }) => {
    const selectedConvo = { ...state.selectedConversation };
    if (selectedConvo.loaded && selectedConvo.smsConversation != null) {
      const sentAtDate = new Date(sendMessageResp.data?.sentAt);
      const newConvo: SmsConversationItem = {
        smsMessageBody: sendMessageResp.data?.body,
        messageDirection: SmsMessageDirection.OUTBOUND,
        messageStatus: sendMessageResp.data?.status?.toLowerCase() as SmsMessageStatus,
        sentAt: sentAtDate,
        lastUpdatedAt: new Date(sendMessageResp.data?.lastUpdatedAt),
        fromNumber: sendMessageResp.data?.from,
        toNumber: sendMessageResp.data?.to,
        trackingId: sendMessageResp.data?.trackingId,
        isRead: true,
        isFailureActionLoading: false,
      };
      let smsConvoDateMap;
      if (!!trackingIdForResend) {
        smsConvoDateMap = editConvoMapForFailureAction(
          newConvo,
          resendOriginalSendAt,
          state.selectedConversation.smsConversation.smsConvoDateMap,
          false,
          false,
          true,
        );
      } else {
        const convoAlreadyHasMessage = isExistingMessage(selectedConvo, sendMessageResp.data.trackingId);
        if (!convoAlreadyHasMessage) {
          smsConvoDateMap = addToConvoMap(newConvo, sentAtDate, state.selectedConversation.smsConversation.smsConvoDateMap);
        } else {
          smsConvoDateMap = { ...state.selectedConversation?.smsConversation?.smsConvoDateMap };
        }
      }

      // Now need to update the SMS Convo Contact List as well.
      const conversations = updateConvoWithNewMessage(state.smsContactList.list.conversations, newConvo, newConvo.toNumber, contact, false);
      if (selectedConvo.selectedContactInfo.contact.mobilePhone !== newConvo.toNumber) {
        return {
          ...state,
          smsContactList: {
            ...state.smsContactList,
            list: {
              conversations,
            },
          },
          selectedConversation: {
            ...state.selectedConversation,
            sendMsgLoading: false,
          },
        };
      }
      return {
        ...state,
        smsContactList: {
          ...state.smsContactList,
          list: {
            conversations,
          },
        },
        selectedConversation: {
          ...state.selectedConversation,
          sendMsgLoading: false,
          smsConversation: {
            ...state.selectedConversation.smsConversation,
            smsConvoDateMap,
          },
          selectedContactInfo: {
            ...state.selectedConversation.selectedContactInfo,
            conversation: newConvo,
          },
        },
      };
    }
    return {
      ...state,
    };
  }),

  on(SendSmsMessageFailureAction, (state: SmsMessageState, { resendMessage }) => {
    if (!!resendMessage) {
      const smsConvoDateMap = editConvoMapForFailureAction(
        resendMessage,
        resendMessage.sentAt,
        state.selectedConversation.smsConversation.smsConvoDateMap,
        false,
        false,
        false,
      );
      return {
        ...state,
        selectedConversation: {
          ...state.selectedConversation,
          sendMsgLoading: false,
          smsConversation: {
            ...state.selectedConversation.smsConversation,
            smsConvoDateMap,
          },
        },
      };
    } else {
      return {
        ...state,
        selectedConversation: {
          ...state.selectedConversation,
          sendMsgLoading: false,
        },
      };
    }
  }),

  on(UpdateSmsMessageContactAction, (state: SmsMessageState, { contact }) => {
    let convos = [...state.smsContactList.list.conversations];
    const idxToLookUp = convos.findIndex((convo) => {
      return convo.mobilePhone === contact.mobilePhone;
    });
    if (idxToLookUp > -1) {
      convos = convos.map((convo, idx) => {
        if (idx === idxToLookUp) {
          return { contact, conversation: { ...convo.conversation }, mobilePhone: contact.mobilePhone };
        }
        return convo;
      });
    }
    return {
      ...state,
      selectedConversation: {
        ...state.selectedConversation,
        selectedContactInfo: {
          ...state.selectedConversation.selectedContactInfo,
          contact,
        },
      },
      smsContactList: {
        ...state.smsContactList,
        list: {
          conversations: [...convos],
        },
      },
    };
  }),

  on(ResetSmsConvoStateAction, (state: SmsMessageState) => {
    return {
      ...state,
      selectedConversation: {
        ...initialSmsMessageState.selectedConversation,
      },
    };
  }),

  on(ReceiveSmsMessageAction, (state: SmsMessageState, { data, contact }) => {
    const smsConvoItem: SmsConversationItem = {
      smsMessageBody: data.Body,
      messageDirection: SmsMessageDirection.INBOUND,
      messageStatus: data.Status?.toLowerCase() as SmsMessageStatus,
      sentAt: new Date(data.SentAt),
      lastUpdatedAt: new Date(data.LastUpdatedAt),
      fromNumber: data.ToPhone,
      trackingId: data.TrackingId,
      isFailureActionLoading: false,
      isRead: state.selectedConversation?.selectedContactInfo?.mobilePhone === data.ToPhone ? true : false,
    };
    let selectedConversation = state.selectedConversation;
    let selectedContactInfoContact = state.selectedConversation?.selectedContactInfo?.contact;

    // First address the contact list.
    const convos = updateConvoWithNewMessage(state.smsContactList.list.conversations, smsConvoItem, smsConvoItem.fromNumber, contact, true);
    const selectedConvo = { ...state.selectedConversation };
    const convoAlreadyHasMessage = isExistingMessage(selectedConvo, data.TrackingId);
    // Then add it to convo if needed.
    if (state.selectedConversation?.selectedContactInfo?.mobilePhone === smsConvoItem.fromNumber && !convoAlreadyHasMessage) {
      const convoMap = addToConvoMap(smsConvoItem, smsConvoItem.sentAt, state.selectedConversation.smsConversation.smsConvoDateMap);

      // Finally, edit the selected contact info if necessary
      if (
        !!state.selectedConversation.selectedContactInfo.contact &&
        (smsConvoItem.messageStatus === SmsMessageStatus.OPTED_IN || smsConvoItem.messageStatus === SmsMessageStatus.OPTED_OUT)
      ) {
        selectedContactInfoContact = {
          ...state.selectedConversation.selectedContactInfo.contact,
          doNotSms: smsConvoItem.messageStatus === SmsMessageStatus.OPTED_OUT,
          optOutSms: smsConvoItem.messageStatus === SmsMessageStatus.OPTED_OUT,
        };
      }

      selectedConversation = {
        ...selectedConversation,
        smsConversation: {
          ...selectedConversation?.smsConversation,
          smsConvoDateMap: convoMap,
        },
        selectedContactInfo: {
          ...selectedConversation?.selectedContactInfo,
          contact: selectedContactInfoContact,
        },
      };
    }

    return {
      ...state,
      smsContactList: {
        ...state.smsContactList,
        list: {
          conversations: convos,
        },
      },
      selectedConversation: {
        ...selectedConversation,
      },
    };
  }),

  on(ReceiveSendStatusCallbackAction, (state: SmsMessageState, { data }) => {
    if (!_.isEmpty(state.selectedConversation?.smsConversation?.smsConvoDateMap)) {
      let convoMap = { ...state.selectedConversation.smsConversation.smsConvoDateMap };
      const sentAtDate = new Date(data.SentAt);
      const dayMonYear = new Date(sentAtDate.getFullYear(), sentAtDate.getMonth(), sentAtDate.getDate()).toISOString();
      if (convoMap[dayMonYear]) {
        let convoOnDay = [...convoMap[dayMonYear]];
        const idxOfSpecificMsg = convoOnDay.findIndex((convo) => {
          return convo.trackingId === data.TrackingId;
        });

        if (idxOfSpecificMsg > -1) {
          convoOnDay = convoOnDay.map((msg, idx) => {
            if (idx === idxOfSpecificMsg) {
              return {
                ...msg,
                lastUpdatedAt: new Date(data.LastUpdatedAt),
                messageStatus: data.Status.toLowerCase() as SmsMessageStatus,
              };
            }
            return msg;
          });
          convoMap = {
            ...convoMap,
            [dayMonYear]: convoOnDay,
          };
        }
      }

      return {
        ...state,
        selectedConversation: {
          ...state.selectedConversation,
          smsConversation: {
            ...state.selectedConversation.smsConversation,
            smsConvoDateMap: {
              ...convoMap,
            },
          },
        },
      };
    }

    return {
      ...state,
    };
  }),

  on(DeleteSmsMessageAction, (state: SmsMessageState, { messageToDelete }) => {
    const smsConvoDateMap = editConvoMapForFailureAction(
      messageToDelete,
      messageToDelete.sentAt,
      state.selectedConversation.smsConversation.smsConvoDateMap,
      true,
      true,
      false,
    );
    return {
      ...state,
      selectedConversation: {
        ...state.selectedConversation,
        smsConversation: {
          ...state.selectedConversation.smsConversation,
          smsConvoDateMap,
        },
      },
    };
  }),

  on(DeleteSmsMessageSuccessAction, (state: SmsMessageState, { messageToDelete }) => {
    const dayMonYear = new Date(messageToDelete.sentAt.getFullYear(), messageToDelete.sentAt.getMonth(), messageToDelete.sentAt.getDate()).toISOString();
    const smsConvoDateMapEdit = { ...state.selectedConversation.smsConversation.smsConvoDateMap };
    if (smsConvoDateMapEdit[dayMonYear]) {
      smsConvoDateMapEdit[dayMonYear] = [...smsConvoDateMapEdit[dayMonYear]].filter((val) => {
        return val.trackingId !== messageToDelete.trackingId;
      });

      if (smsConvoDateMapEdit[dayMonYear].length === 0) {
        delete smsConvoDateMapEdit[dayMonYear];
      }
    }

    return {
      ...state,
      selectedConversation: {
        ...state.selectedConversation,
        sendMsgLoading: false,
        smsConversation: {
          ...state.selectedConversation.smsConversation,
          smsConvoDateMap: smsConvoDateMapEdit,
        },
      },
    };
  }),

  on(DeleteSmsMessageFailureAction, (state: SmsMessageState, { messageToDelete }) => {
    const smsConvoDateMap = editConvoMapForFailureAction(
      messageToDelete,
      messageToDelete.sentAt,
      state.selectedConversation.smsConversation.smsConvoDateMap,
      true,
      false,
      false,
    );
    return {
      ...state,
      selectedConversation: {
        ...state.selectedConversation,
        smsConversation: {
          ...state.selectedConversation.smsConversation,
          smsConvoDateMap,
        },
      },
    };
  }),

  on(UpdateTotalUnreadSMSAction, (state: SmsMessageState, { totalUnreadSMSPresent }) => {
    return {
      ...state,
      totalUnreadSMSPresent: totalUnreadSMSPresent,
    };
  }),

  on(UpdateIsReadForConversationAction, (state: SmsMessageState, { mobilePhone }) => {
    let convos = [...state.smsContactList.list.conversations];
    convos = updateIsReadForContact(mobilePhone, convos);
    return {
      ...state,
      smsContactList: {
        ...state.smsContactList,
        list: {
          ...state.smsContactList.list,
          conversations: convos,
        },
      },
    };
  }),

  on(UpdateIsReadForConversationSuccessAction, (state: SmsMessageState, { totalReadMessages }) => {
    const newtotalReadMessages = state.totalUnreadSMSPresent - totalReadMessages;
    return {
      ...state,
      totalUnreadSMSPresent: newtotalReadMessages,
      selectedConversation: {
        ...state.selectedConversation,
        ...successLoadedState,
      },
    };
  }),
);

function updateIsReadForContact(mobileNumber: string, conversations: SmsUserConversationItem[]) {
  let convos = conversations.map((convo) => {
    if (convo.mobilePhone === mobileNumber) {
      convo = { ...convo, conversation: { ...convo.conversation, isRead: true } };
    }
    return convo;
  });
  return convos;
}

function addToConvoMap(newConvo: SmsConversationItem, sentAtDate: Date, smsConvoDateMap: { [key: string]: SmsConversationItem[] }) {
  const dayMonYear = new Date(sentAtDate.getFullYear(), sentAtDate.getMonth(), sentAtDate.getDate()).toISOString();
  const smsConvoDateMapEdit = { ...smsConvoDateMap };
  if (smsConvoDateMapEdit[dayMonYear]) {
    const arrayVal = [...smsConvoDateMapEdit[dayMonYear]];
    arrayVal.unshift(newConvo);
    smsConvoDateMapEdit[dayMonYear] = arrayVal;
  } else {
    smsConvoDateMapEdit[dayMonYear] = [newConvo];
  }
  return smsConvoDateMapEdit;
}

function editConvoMapForFailureAction(
  newConvo: SmsConversationItem,
  sentAtDate: Date,
  smsConvoDateMap: { [key: string]: SmsConversationItem[] },
  isPreSend: boolean,
  isFailureActionLoading: boolean,
  moveMessage: boolean,
) {
  const isDayToday = isToday(sentAtDate);
  const dayMonYear = new Date(sentAtDate.getFullYear(), sentAtDate.getMonth(), sentAtDate.getDate()).toISOString();
  const today = new Date();
  const todayDayMonYear = new Date(today.getFullYear(), today.getMonth(), today.getDate()).toISOString();
  const smsConvoDateMapEdit = { ...smsConvoDateMap };
  if (smsConvoDateMapEdit[dayMonYear]) {
    const arrayVal = [...smsConvoDateMapEdit[dayMonYear]];
    const todayArrayVal = smsConvoDateMapEdit[todayDayMonYear] ? [...smsConvoDateMapEdit[todayDayMonYear]] : [];
    const foundIdx = arrayVal.findIndex((val) => {
      return val.trackingId === newConvo.trackingId;
    });
    if (foundIdx < 0) {
      // Somethings odd if we're here...
      if (!isPreSend) {
        // to be safe let's treat it as new for ngrx
        return addToConvoMap(newConvo, today, smsConvoDateMapEdit);
      } else {
        // If it's presend, do nothing.
        return;
      }
    }

    const objFound = { ...arrayVal[foundIdx], isFailureActionLoading };
    if (moveMessage) {
      arrayVal.splice(foundIdx, 1);
      if (!isDayToday) {
        todayArrayVal.unshift(newConvo);
        smsConvoDateMapEdit[todayDayMonYear] = todayArrayVal;
      } else {
        arrayVal.unshift(newConvo);
      }
      smsConvoDateMapEdit[dayMonYear] = arrayVal;
      if (smsConvoDateMapEdit[dayMonYear].length === 0) {
        delete smsConvoDateMapEdit[dayMonYear];
      }
    } else {
      arrayVal[foundIdx] = objFound;
      smsConvoDateMapEdit[dayMonYear] = arrayVal;
    }

    return smsConvoDateMapEdit;
  } else if (!isPreSend) {
    // Somethings odd if we're here...to be safe let's treat it as new for ngrx.
    return addToConvoMap(newConvo, sentAtDate, smsConvoDateMapEdit);
  }
}

function updateConvoWithNewMessage(
  convoList: SmsUserConversationItem[],
  newConvo: SmsConversationItem,
  mobileNumber: string,
  contact: Contact,
  isCheckOptOut: boolean,
): SmsUserConversationItem[] {
  // Now need to update the SMS Convo Contact List as well.
  const conversations = [...convoList];
  const convoToEditIdx = conversations.findIndex((item) => {
    return item.mobilePhone === mobileNumber;
  });
  if (convoToEditIdx > -1) {
    const convoToEdit = { ...conversations[convoToEditIdx] };
    convoToEdit.conversation = { ...newConvo };
    if (newConvo.messageStatus === SmsMessageStatus.OPTED_OUT || newConvo.messageStatus === SmsMessageStatus.OPTED_IN) {
      convoToEdit.contact = {
        ...convoToEdit.contact,
        optOutSms: newConvo.messageStatus === SmsMessageStatus.OPTED_OUT,
        doNotSms: newConvo.messageStatus === SmsMessageStatus.OPTED_OUT,
      };
    }
    conversations.splice(convoToEditIdx, 1);
    conversations.unshift(convoToEdit);
  } else {
    conversations.unshift({ contact, conversation: { ...newConvo }, mobilePhone: mobileNumber });
  }

  return conversations;
}

function isToday(dateToCheck: Date): boolean {
  const today = new Date();
  return today.getDate() === dateToCheck.getDate() && today.getMonth() === dateToCheck.getMonth() && today.getFullYear() === dateToCheck.getFullYear();
}

function isExistingMessage(selectedConvo: SmsConversationState, trackingId: string): boolean {
  let convoAlreadyHasMessage = false;
  for (const key in selectedConvo?.smsConversation?.smsConvoDateMap) {
    if (!!selectedConvo?.smsConversation?.smsConvoDateMap[key]) {
      const dateList = selectedConvo?.smsConversation?.smsConvoDateMap[key];
      if (dateList.some((c) => c?.trackingId === trackingId)) {
        convoAlreadyHasMessage = true;
      }
    }
  }
  return convoAlreadyHasMessage;
}

export function SmsMessageReducer(state: SmsMessageState, action: Action): SmsMessageState {
  return smsMessageReducer(state, action);
}
