import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '@app/reducers';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DoziAccountDialogComponent } from '@app/common/pages-component/dozi-account-dialog/dozi-account-dialog.component';
import { DoziFeature } from '../data-model/dozi-integration/enums/dozi-feature.model';
import { DoziFeaturesDialogComponent } from '@app/common/pages-component/dozi-features-dialog/dozi-features-dialog.component';
import { ContactUsType } from '../data-model/dozi-integration/enums/contact-us-type.model';
import { DoziContactUsDialogComponent } from '@app/common/pages-component/dozi-contact-us-dialog/dozi-contact-us-dialog.component';
import { DoziOktaIntegrationDialogComponent } from '@zi-common/pages-component/dozi-okta-integration-dialog/dozi-okta-integration-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DoziManagerService {
  constructor(private store: Store<ApplicationState>, private dialog: MatDialog) {}

  openDoziLoginDialog(data?: any): MatDialogRef<DoziOktaIntegrationDialogComponent> {
    return this.dialog.open(DoziOktaIntegrationDialogComponent, {
      maxWidth: '335px',
      minHeight: '300px',
      data: data ? data : {},
    });
  }

  openDoziTrialDialog(): MatDialogRef<DoziAccountDialogComponent> {
    return this.dialog.open(DoziAccountDialogComponent, {
      width: '648px',
      height: '667px',
      data: {},
    });
  }

  openDoziFeaturesDialog(
    featureToOpen: DoziFeature,
    name: string,
    type: ContactUsType,
    title: string,
    contents: string[],
    videoUrl: string,
  ): MatDialogRef<DoziFeaturesDialogComponent> {
    return this.dialog.open(DoziFeaturesDialogComponent, {
      width: '940px',
      height: '349px',
      data: {
        doziFeatureToShow: featureToOpen,
        fullName: name,
        contactType: type,
        title: title,
        contents: contents,
        videoUrl: videoUrl,
      },
    });
  }

  openContactUs(): MatDialogRef<DoziContactUsDialogComponent> {
    return this.dialog.open(DoziContactUsDialogComponent, {
      width: '434px',
      height: '381px',
      data: {},
    });
  }
}
