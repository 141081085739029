import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AudioDeviceService {
  private INPUT_DEVICE = 'audioinput';
  private OUTPUT_DEVICE = 'audiooutput';
  private DEFAULT_ID = 'default';

  private deviceSubject = new BehaviorSubject<MediaDeviceInfo[]>([]);
  private inputDevices$: Observable<MediaDeviceInfo[]> = of([]);
  private outputDevices$: Observable<MediaDeviceInfo[]> = of([]);

  constructor() {
    this.refresh();
    navigator.mediaDevices.addEventListener('devicechange', () => {
      this.refresh();
    });

    this.inputDevices$ = this.deviceSubject.pipe(
      map((devices: MediaDeviceInfo[]) =>
        devices.filter((device) => {
          return device.kind === this.INPUT_DEVICE;
        }),
      ),
    );

    this.outputDevices$ = this.deviceSubject.pipe(
      map((devices: MediaDeviceInfo[]) =>
        devices.filter((device) => {
          return device.kind === this.OUTPUT_DEVICE;
        }),
      ),
    );
  }

  // fetch audio devices
  refresh() {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      this.deviceSubject.next(devices);
    });
  }

  getDeviceObservable() {
    return this.deviceSubject.asObservable();
  }

  getInputDevices$() {
    return this.inputDevices$;
  }

  getOutputDevices$() {
    return this.outputDevices$;
  }

  checkIfInputDeviceIsValid(id: string) {
    return !!this.deviceSubject.value.filter((d) => d.kind === this.INPUT_DEVICE).find((device) => device.deviceId === id);
  }

  checkIfOutputDeviceIsValid(id: string) {
    return !!this.deviceSubject.value.filter((d) => d.kind === this.OUTPUT_DEVICE).find((device) => device.deviceId === id);
  }
}
