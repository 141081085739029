import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@env/environment';
import {
  SalesWorkflowTemplate,
  SalesWorkflowTemplateInsights,
  SalesWorkflowDetailInsights,
  SalesWorkflowTemplateStatistics,
  SalesWorkflowTemplateStep,
  SalesWorkflowTemplateSummary,
  StepTask,
  OrgAndUserLevelStatistics,
  SalesWorkflowTemplateNameWithIds,
  SalesWorkflowDetailInsightsWithActiveSteps,
} from '@app/core/http-model/response/sales-workflow-template.response.model';
import {
  SalesWorkflowTemplateCreate,
  SalesWorkflowTemplateCopy,
  SalesWorkflowTemplateStepCreate,
  SalesWorkflowTemplateUpdate,
  SalesWorkflowTemplateStepUpdate,
  ExitConditions,
  SalesWorkflowTemplateStepsUpdate,
  SalesWorkflowStepABTestingTemplatToggle,
} from '@app/core/http-model/request/sales-workflow-template.request.model';
import {
  BulkUpdateWorkflowsStatusRequest,
  ContactListRequest,
  SalesWorkflowSendNowRequest,
  UpdateContactStatusRequest,
  UpdateContactStatusV2Request,
} from '@app/core/http-model/request/sales-workflow.request.model';
import {
  ScheduledContactsResponse,
  SendNowScheduledItemBulkResponse,
  WaitingRoomBatchResponse,
  WaitingRoomContactsResponse,
} from '@zi-core/http-model/response/sales-workflow.response';
import { ContactAllTasksResponse } from '@zi-pages/salesflow/model/simple-contact-task.model';
import { Profile } from '@zi-core/data-model/profile.model';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FlagSet } from '@app/feature-flag/interfaces/flag-set.interface';
import { Flag } from '@app/feature-flag/types/flag.enum';
import { FeatureFlagService } from '@app/feature-flag/feature-flag.service';
import { DEFAULT_SALESFLOW_TEMPLATE_INSIGHTS } from '@zi-pages/salesflow/salesflow.config';

@Injectable()
export class WorkflowTemplateService implements OnDestroy {
  private workflowTemplatesBaseUrl = `${environment.backend_url}/v1/workflow_templates`;
  private workflowsBaseUrl = `${environment.backend_url}/v1/workflows`;
  private workflowsV2BaseUrl = `${environment.backend_url}/v2/workflows`;
  private taskListBaseUrl = `${environment.backend_url}/v2/contacttasks`;
  private taskUpdateBaseUrl = `${environment.backend_url}/v2/contacts/workflows/`;
  private contactsBaseUrl = `${environment.backend_url}/v1/contacts`;
  private salesflowLibrary = `${environment.backend_url}/v1/salesworkflowlibrary`;
  private workflowTemplatesBaseUrlV2 = `${environment.backend_url}/v2/workflow_templates`;
  private taskListBaseUrlV3 = `${environment.backend_url}/v3/contacttasks`;

  salesflowVisibilityPhase1FeatureFlag$: Observable<FlagSet[Flag.SALESFLOW_VISIBILITY_PHASE1]>;
  isSalesflowVisibilityFlag: boolean;
  subscriptions: Subscription[];
  isSearchBarFlagEnabled: boolean;

  constructor(private httpClient: HttpClient, protected featureFlagService: FeatureFlagService) {
    this.setupFeatureFlags();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  getScheduledItems(workflowId: number, page: number, rpp = 25): Observable<WaitingRoomContactsResponse> {
    return this.httpClient.get<WaitingRoomContactsResponse>(`${this.workflowsV2BaseUrl}/scheduled/${workflowId}`, {
      params: {
        PageSize: rpp,
        Page: page,
      },
    });
  }

  getWaitingRoomItems(workflowId: number, page: number, rpp = 25): Observable<WaitingRoomContactsResponse> {
    return this.httpClient.get<WaitingRoomContactsResponse>(`${this.workflowsV2BaseUrl}/waitingroom/${workflowId}`, {
      params: {
        PageSize: rpp,
        Page: page,
      },
    });
  }

  getWorkflowTemplates(): Observable<SalesWorkflowTemplate[]> {
    return this.httpClient.get<SalesWorkflowTemplate[]>(this.workflowTemplatesBaseUrl).pipe(map((res) => res));
  }

  getSalesflowLibrary() {
    return this.httpClient.get(this.salesflowLibrary);
  }

  getSalesflowLibraryTemplateById(OOTBTemplateId: number) {
    const params = new HttpParams().set('OOTBTemplateId', `${OOTBTemplateId}`);
    return this.httpClient.get(`${this.salesflowLibrary}/template`, { params });
  }

  copyOotbSalesflowLibray(OOTBSalesworkflowTemplateToCopy: any): Observable<any> {
    return this.httpClient.post(`${this.salesflowLibrary}/duplicate`, OOTBSalesworkflowTemplateToCopy);
  }

  getWorkflowTemplatesSummary(): Observable<SalesWorkflowTemplateSummary[]> {
    return this.httpClient.get<SalesWorkflowTemplateSummary[]>(`${this.workflowTemplatesBaseUrl}/dashboard`).pipe(map((res) => res));
  }

  getWorkflowTemplateSummary(workflowTemplateId: number): Observable<SalesWorkflowTemplateSummary> {
    return this.httpClient.get<SalesWorkflowTemplateSummary>(`${this.workflowTemplatesBaseUrl}/dashboard/${workflowTemplateId}`).pipe(map((res) => res));
  }

  getWorkflowTemplate(workflowTemplateId: number): Observable<SalesWorkflowTemplate> {
    return this.httpClient.get<SalesWorkflowTemplate>(`${this.workflowTemplatesBaseUrl}/${workflowTemplateId}`).pipe(map((res) => res));
  }

  getWorkflowTemplateNameAndIds(workflowTemplateId: number): Observable<SalesWorkflowTemplateNameWithIds> {
    return this.httpClient.get<SalesWorkflowTemplateNameWithIds>(`${this.workflowTemplatesBaseUrl}/list/${workflowTemplateId}`).pipe(map((res) => res));
  }

  getWorkflowTaskOwners(workflowTemplateId: number): Observable<Array<Profile>> {
    return this.httpClient.get<Array<Profile>>(`${this.workflowTemplatesBaseUrl}/task_owner/${workflowTemplateId}`).pipe(map((res) => res));
  }

  getWorkflowContactOwners(workflowTemplateId: number): Observable<Array<Profile>> {
    return this.httpClient.get<Array<Profile>>(`${this.workflowTemplatesBaseUrl}/contact_owner/${workflowTemplateId}`).pipe(map((res) => res));
  }

  getWorkflowTemplateStatistics(workflowTemplateId: number): Observable<SalesWorkflowTemplateStatistics> {
    return this.httpClient.get<SalesWorkflowTemplateStatistics>(`${this.workflowTemplatesBaseUrl}/${workflowTemplateId}/statistics`).pipe(map((res) => res));
  }

  getWorkflowTemplateOrgAndUserStatistics(workflowTemplateId: number): Observable<OrgAndUserLevelStatistics> {
    return this.httpClient
      .get<OrgAndUserLevelStatistics>(`${this.workflowTemplatesBaseUrl}/${workflowTemplateId}/org_and_user_statistics`)
      .pipe(map((res) => res));
  }

  getWorkflowTemplateInsights(workflowTemplateId: number): Observable<SalesWorkflowDetailInsights> {
    return this.httpClient.get<SalesWorkflowDetailInsights>(`${this.workflowTemplatesBaseUrl}/${workflowTemplateId}/insights`);
  }

  getWorkflowTemplateInsightsWithActiveSteps(workflowTemplateId: number): Observable<SalesWorkflowDetailInsightsWithActiveSteps> {
    return this.httpClient.get<SalesWorkflowDetailInsightsWithActiveSteps>(`${this.workflowTemplatesBaseUrl}/${workflowTemplateId}/insights_with_active_steps`);
  }

  createWorkflowTemplate(workflowTemplateToCreate: SalesWorkflowTemplateCreate): Observable<SalesWorkflowTemplate> {
    return this.httpClient.post<SalesWorkflowTemplate>(this.workflowTemplatesBaseUrl, workflowTemplateToCreate).pipe(map((res) => res));
  }

  updateWorkflowTemplate(workflowTemplateToUpdate: SalesWorkflowTemplateUpdate): Observable<SalesWorkflowTemplate> {
    return this.httpClient
      .put<SalesWorkflowTemplate>(`${this.workflowTemplatesBaseUrl}/${workflowTemplateToUpdate.id}`, workflowTemplateToUpdate)
      .pipe(map((res) => res));
  }

  deleteWorkflowTemplate(workflowTemplateId: number): Observable<any> {
    return this.httpClient.delete<any>(`${this.workflowTemplatesBaseUrl}/${workflowTemplateId}`).pipe(map((res) => res));
  }

  enableDisableWorkflowStepABTestingTemplate(workflowTemplateId: number, reqBody: SalesWorkflowStepABTestingTemplatToggle): Observable<any> {
    return this.httpClient.put<any>(`${this.workflowTemplatesBaseUrl}/${workflowTemplateId}/ABTestingTemplate/enable_disable`, reqBody);
  }

  copyWorkflowTemplate(resource: SalesWorkflowTemplateCopy): Observable<any> {
    return this.httpClient.post<any>(`${this.workflowTemplatesBaseUrl}/${resource.id}/copy`, resource).pipe(map((res) => res));
  }

  getWorkflowSteps(workflowTemplateId: number): Observable<SalesWorkflowTemplateStep[]> {
    return this.httpClient.get<SalesWorkflowTemplateStep[]>(`${this.workflowTemplatesBaseUrl}/${workflowTemplateId}/steps`).pipe(map((res) => res));
  }

  getWorkflowStepsWithStartDate(workflowTemplateId: number, startDate: string): Observable<SalesWorkflowTemplateStep[]> {
    return this.httpClient
      .get<SalesWorkflowTemplateStep[]>(`${this.workflowTemplatesBaseUrl}/${workflowTemplateId}/steps?StartDate=${startDate}`)
      .pipe(map((res) => res));
  }

  getTasksbyStepId(stepId: number): Observable<StepTask[]> {
    if (this.isSalesflowVisibilityFlag) {
      return this.httpClient.get<any>(`${this.taskListBaseUrlV3}/withcontact/${stepId}?PageSize=2000`).pipe(map((res) => res.tasks.map(this.mapStepTasks)));
    } else {
      return this.httpClient.get<any>(`${this.taskListBaseUrl}/withcontact/${stepId}?PageSize=2000`).pipe(map((res) => res.tasks.map(this.mapStepTasks)));
    }
  }

  getAllContactTasks(
    workflowTemplateId: number,
    retrieveTotalCount = false,
    taskFilters?: { [filterType: number]: number[] },
    pageSize?: number,
    pageNumber?: number,
    isGetActiveCount = false,
    isGetAllStepsCount = false,
    searchTerm = '',
  ): Observable<ContactAllTasksResponse> {
    let params: any = {
      pageSize: String(pageSize),
      pageNumber: String(pageNumber),
      contactTaskFilters: JSON.stringify(taskFilters),
      retrieveTotalCount: String(retrieveTotalCount),
      isGetActiveCount: String(isGetActiveCount),
      isGetAllStepsCount: String(isGetAllStepsCount),
    };

    if (this.isSearchBarFlagEnabled && this.isSalesflowVisibilityFlag) {
      params = { ...params, searchTerm };
      return this.httpClient.get<ContactAllTasksResponse>(`${this.workflowTemplatesBaseUrlV2}/${workflowTemplateId}/contact/steps_with_search`, { params });
    } else if (this.isSalesflowVisibilityFlag) {
      return this.httpClient.get<ContactAllTasksResponse>(`${this.workflowTemplatesBaseUrlV2}/${workflowTemplateId}/contact/steps`, { params });
    } else {
      return this.httpClient.get<ContactAllTasksResponse>(`${this.workflowTemplatesBaseUrl}/${workflowTemplateId}/contact/steps`, { params });
    }
  }

  getAllTasksByStepId(stepId: number): Observable<StepTask[]> {
    return this.httpClient
      .get<any>(`${this.taskListBaseUrl}/withcontact/${stepId}?PageSize=2000&IsFetchAll=true`)
      .pipe(map((res) => res.tasks.map(this.mapStepTasks)));
  }

  createWorkflowStep(workflowTemplateId: number, stepToCreate: SalesWorkflowTemplateStepCreate): Observable<SalesWorkflowTemplateStep> {
    return this.httpClient
      .post<SalesWorkflowTemplateStep>(`${this.workflowTemplatesBaseUrl}/${workflowTemplateId}/steps`, stepToCreate)
      .pipe(map((res) => res));
  }

  updateWorkflowStep(stepToUpdate: SalesWorkflowTemplateStepUpdate): Observable<SalesWorkflowTemplateStep> {
    return this.httpClient
      .put<SalesWorkflowTemplateStep>(`${this.workflowTemplatesBaseUrl}/${stepToUpdate.workflowTemplateId}/steps/${stepToUpdate.id}`, stepToUpdate)
      .pipe(map((res) => res));
  }

  /**
   * @param workflowStepsToUpdate
   */
  updateWorkflowTemplateSteps(workflowStepsToUpdate: SalesWorkflowTemplateStepsUpdate): Observable<SalesWorkflowTemplateStep[]> {
    return this.httpClient.put<SalesWorkflowTemplateStep[]>(
      `${this.workflowTemplatesBaseUrl}/${workflowStepsToUpdate.workflowTemplateId}/steps`,
      workflowStepsToUpdate,
    );
  }

  deleteWorkflowStep(workflowTemplateId: number, stepId: number): Observable<any> {
    return this.httpClient.delete<any>(`${this.workflowTemplatesBaseUrl}/${workflowTemplateId}/steps/${stepId}`).pipe(
      // Response from endpoint is "null" so let's respond with the stepId in order to be able to work with it
      map((res) => stepId),
    );
  }

  getWaitingRoomByTemplateId(workflowTemplateId: number): Observable<WaitingRoomContactsResponse> {
    return this.httpClient
      .get<WaitingRoomContactsResponse>(`${this.workflowsBaseUrl}/waitingroom?WorkflowTemplateId=${workflowTemplateId}`)
      .pipe(map((res) => res));
  }

  getScheduledByTemplateId(workflowTemplateId: number): Observable<WaitingRoomBatchResponse> {
    return this.httpClient.get<WaitingRoomBatchResponse>(`${this.workflowsBaseUrl}/scheduled?WorkflowTemplateId=${workflowTemplateId}`).pipe(map((res) => res));
  }

  getScheduledContactsPage(workflowTemplateId: number, rpp: number, page: number): Observable<ScheduledContactsResponse> {
    return this.httpClient
      .get<ScheduledContactsResponse>(`${this.workflowsV2BaseUrl}/scheduled/${workflowTemplateId}?PageSize=${rpp}&Page=${page}`)
      .pipe(map((res) => res));
  }

  updateContactsBulkStatus(status: BulkUpdateWorkflowsStatusRequest): Observable<any> {
    return this.httpClient.post<any>(`${this.workflowsBaseUrl}/bulk/status`, status).pipe(map((res) => res));
  }

  updateContactStatus(status: UpdateContactStatusRequest): Observable<any> {
    return this.httpClient.post<any>(`${this.taskListBaseUrl}/bulkupdate`, status).pipe(map((res) => res));
  }

  updateContactStatusV2(status: UpdateContactStatusV2Request): Observable<any> {
    return this.httpClient.put<any>(`${this.taskUpdateBaseUrl}` + status.salesWorkflowId, { Status: status.status }).pipe(map((res) => res));
  }

  deleteWaitingRoomContacts(salesWorkflowWaitingRoomIds: number[]): Observable<any> {
    return this.httpClient
      .post<any>(`${this.workflowsBaseUrl}/waitingroom/delete`, { SalesWorkflowWaitingRoomIds: salesWorkflowWaitingRoomIds })
      .pipe(map((res) => salesWorkflowWaitingRoomIds));
  }

  deleteScheduledContacts(salesWorkflowWaitingRoomIds: number[]): Observable<any> {
    return this.httpClient
      .post<any>(`${this.workflowsBaseUrl}/scheduled/delete`, { SalesWorkflowWaitingRoomIds: salesWorkflowWaitingRoomIds })
      .pipe(map((res) => salesWorkflowWaitingRoomIds));
  }

  sendNowAutomaticEmails(emails: SalesWorkflowSendNowRequest): Observable<SendNowScheduledItemBulkResponse> {
    return this.httpClient.post<SendNowScheduledItemBulkResponse>(`${environment.backend_url}/v1/scheduled_items/sendnow`, emails).pipe((res) => {
      return res;
    });
  }

  getSchedualedByBatchId(batchRequest: any): Observable<any> {
    return this.httpClient
      .get<any>(`${this.workflowsBaseUrl}/scheduled/batches?BatchId=${batchRequest.batchId}&WorkflowTemplateId=${batchRequest.WorkflowTemplateId}`)
      .pipe(map((res) => res));
  }

  getContactIdsByTemplateStatus(templateId: number, page?: number, rpp?: number): Observable<any> {
    const pagination = page && rpp ? `&Page=${page}&PageSize=${rpp}` : '';
    return this.httpClient
      .get<any>(
        `${this.workflowsBaseUrl}/contact_ids_bytemplateid_by_status?EnteredByUser=true&StatusList=Failed&StatusList=FailedSoftBounce&StatusList=FailedUnknown&StatusList=Blocklisted&StatusList=Stopped&StatusList=Replied&StatusList=FinishedAllSteps&StatusList=Unsubscribed&StatusList=ExitByCondition&WorkflowTemplateId=${templateId}${pagination}`,
      )
      .pipe(map((res) => res));
  }

  getContactsByExitedIds(req: ContactListRequest): Observable<any> {
    return this.httpClient.post<any>(`${this.contactsBaseUrl}`, req).pipe(map((res) => res.contacts));
  }

  startScheduledContacts(workflowTemplateId: number, selectedBatches: { batchId: number; salesWorkflowWaitingRoomIds: number[] }[]) {
    const observablesArray = [];
    selectedBatches.forEach((batch) => {
      observablesArray.push(
        this.httpClient
          .post<any>(`${this.workflowsBaseUrl}/scheduled/batches/contacts`, {
            WorkflowTemplateId: Number(workflowTemplateId),
            BatchId: Number(batch.batchId),
            SalesWorkflowWaitingRoomIds: batch.salesWorkflowWaitingRoomIds,
          })
          .subscribe(),
      );
    });
    return observablesArray;
  }

  bulkDeleteWorkflowTemplate(resourceIds: Array<number>) {
    return this.httpClient.post<any>(`${this.workflowTemplatesBaseUrl}/bulkdelete`, { resourceIds }).pipe(map((res) => res));
  }

  getWorkflowTemplatesInsights(): Observable<SalesWorkflowTemplateInsights[]> {
    return this.httpClient.get<SalesWorkflowTemplateInsights[]>(`${this.workflowTemplatesBaseUrl}/insights`);
  }

  public getInsightsByTemplateIds(templateIds: number[]) {
    return this.httpClient.post<SalesWorkflowTemplateInsights[]>(`${this.workflowTemplatesBaseUrl}/insights_by_ids`, { templateIds }).pipe(
      map((salesflowInsights: SalesWorkflowTemplateInsights[]) => {
        return templateIds.map((id) => salesflowInsights.find((insight) => insight.id === id) ?? DEFAULT_SALESFLOW_TEMPLATE_INSIGHTS(id));
      }),
    );
  }

  ordinal_suffix_of(i) {
    const j = i % 10;
    const k = i % 100;
    if (j === 1 && k !== 11) {
      return i + 'st';
    }
    if (j === 2 && k !== 12) {
      return i + 'nd';
    }
    if (j === 3 && k !== 13) {
      return i + 'rd';
    }
    return i + 'th';
  }

  convertExitConditions(formValues: any): ExitConditions {
    const conditions: ExitConditions = {
      email: {
        isProspectReply: formValues.onProspectReply,
        isHyperlinkClick: formValues.onHyperlinkClick,
      },
      call: {
        result: {
          isNoAnswer: formValues.onCallResultNoAnswer,
          isLeftMessage: formValues.onCallResultLeftMessage,
          isWrongNumber: formValues.onCallResultWrongNumber,
          isGatekeeperBlocked: formValues.onCallResultGatekeeperBlocked,
          isContactNoLongerThere: formValues.onCallResultContactNoLongerThere,
        },
        sentiment: {
          isBadTiming: formValues.onCallSentimentBadTiming,
          isNotInterested: formValues.onCallSentimentNotInterested,
          isRequestForCallback: formValues.onCallSentimentRequestForCallback,
          isMoreInfoRequested: formValues.onCallSentimentMoreInfoRequested,
          isNextStepBooked: formValues.onCallSentimentNextStepBooked,
          isNoFit: formValues.onCallSentimentNoFit,
        },
      },
    };

    return conditions;
  }

  getTemplateEmptyForm(formBuilder: FormBuilder) {
    return formBuilder.group({
      name: [''],
      subject: ['', Validators.required],
      content: ['', Validators.required],
      emailOpenTracking: new FormControl(false),
      templateTiles: new FormControl([]),
    });
  }

  /**
   * Maps server response to client's StepTask model
   * @param workflowRest - the response to map
   * @returns mapped StepTask
   */
  private mapStepTasks(task: StepTask): StepTask {
    if (task?.isOutOfOffice) {
      task.status = 'PausedOOO';
      return task;
    }
    return task;
  }

  private setupFeatureFlags(): void {
    this.subscriptions = [];
    this.salesflowVisibilityPhase1FeatureFlag$ = this.featureFlagService.observe<Flag.SALESFLOW_VISIBILITY_PHASE1>(Flag.SALESFLOW_VISIBILITY_PHASE1);
    this.subscriptions.push(this.salesflowVisibilityPhase1FeatureFlag$.subscribe((res) => (this.isSalesflowVisibilityFlag = res)));
    this.subscriptions.push(
      this.featureFlagService
        .observe<Flag.SEARCH_SALESFLOW_CONTACTS_TAB>(Flag.SEARCH_SALESFLOW_CONTACTS_TAB)
        .subscribe((isFlagEnabled: boolean) => (this.isSearchBarFlagEnabled = isFlagEnabled)),
    );
  }
}
