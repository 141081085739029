import { ApplicationState } from '@app/reducers';
import { createSelector } from '@ngrx/store';
import * as get from 'lodash.get';
import { LabelResourceType } from '@zi-core/enums/resource-type.enum';
import { LabelBucketDto, LabelItem } from '@zi-core/http-model/response/labels.response.model';
import { FilterSort, FilterSortOrder, Scope } from '@zi-core/enums/engage-filter.enum';

export interface LabelResourceTypeState {
  entities: { [id: string]: LabelItem };
  loaded: boolean;
  loading: boolean;
}

export const initialLabelResourceTypeState: LabelResourceTypeState = {
  entities: null,
  loaded: false,
  loading: false,
};

export interface LabelFilterState {
  search: string;
  showUnusedTagsOnly: boolean;
  sort?: {
    field: FilterSort;
    order: FilterSortOrder;
  };
}

export const initialLabelFilterState: LabelFilterState = {
  search: '',
  showUnusedTagsOnly: false,
  sort: {
    field: FilterSort.Name,
    order: FilterSortOrder.Ascending,
  },
};

export interface LabelsState {
  [LabelResourceType.Contact]: LabelResourceTypeState;
  [LabelResourceType.EmailTemplate]: LabelResourceTypeState;
  [LabelResourceType.SalesflowTemplate]: LabelResourceTypeState;
  filter: LabelFilterState;
}

export const initialLabelsState: LabelsState = {
  [LabelResourceType.Contact]: { ...initialLabelResourceTypeState },
  [LabelResourceType.EmailTemplate]: { ...initialLabelResourceTypeState },
  [LabelResourceType.SalesflowTemplate]: { ...initialLabelResourceTypeState },
  filter: { ...initialLabelFilterState },
};

/**
 * Returns the labels state.
 */
export const getLabelsState = (state: ApplicationState) => state.labels;

export const areLabelsLoading = (resourceType: LabelResourceType) =>
  createSelector(getLabelsState, (state: LabelsState) => {
    return get(state[resourceType], 'loading', null);
  });

/**
 * Returns true if labels have been initialize for specific LabelResourceType
 */
export const getLabelsByResourceType = (resourceType: LabelResourceType) =>
  createSelector(getLabelsState, (state: LabelsState) => {
    return get(state[resourceType], 'entities', null);
  });

export const getLabelsByIdAndResourceType = (resourceType: LabelResourceType, labelId: number) =>
  createSelector(getLabelsState, (state: LabelsState) => {
    return get(state, `[${resourceType}].entities[${labelId}]`, null);
  });

export const getLabelsByCriteria = (resourceType: LabelResourceType, scope: Scope, userId: number) =>
  createSelector(getLabelsState, (state: LabelsState) => {
    const entities = get(state[resourceType], 'entities', null);
    if (!!entities) {
      let labels: LabelBucketDto[] = Object.values(entities);
      if (scope !== Scope.Organization) {
        labels = labels.filter((label) => {
          return label.ownerId === userId;
        });
      }

      // search filter
      if (!!get(state, 'filter.search', null) && state.filter.search.trim() !== '') {
        labels = labels.filter((label) => {
          return label.title.toLowerCase().includes(state.filter.search.trim().toLowerCase());
        });
      }

      labels = labels.sort((labelA, labelB) => {
        const field = get(state, 'filter.sort.field', FilterSort.Name);
        const order = get(state, 'filter.sort.order', FilterSortOrder.Ascending);
        const compareFrom = order === FilterSortOrder.Ascending ? labelA : labelB;
        const compareTo = order === FilterSortOrder.Ascending ? labelB : labelA;
        if (field === FilterSort.Name) {
          return compareFrom.title.localeCompare(compareTo.title);
        } else {
          return compareFrom.modifiedAt > compareTo.modifiedAt ? 1 : compareFrom.modifiedAt < compareTo.modifiedAt ? -1 : 0;
        }
      });

      return labels;
    } else {
      return null;
    }
  });

export const getFilterState = createSelector(getLabelsState, (state) => state.filter);

export const getSearchString = createSelector(getFilterState, (filter) => filter.search);
