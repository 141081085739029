import { createAction, props } from '@ngrx/store';
import { CallLogFollowUpTask } from '@app/caller/interface/call-log-info';
import { PhoneTypesEnum } from '@app/caller/interface/phone-types-enum';

export const ResetCallLog = createAction('[Call Log] Reset Call Log');

export const SetResult = createAction('[Call Log] Set Result', props<{ result: string }>());

export const SetTime = createAction('[Call Log] Set Time', props<{ time: string }>());

export const SetDuration = createAction('[Call Log] Set Duration', props<{ duration: string }>());

// Each time user select phone number on call log modal set phone type and number
export const SetPhone = createAction('[Call Log] Set Phone', props<{ phone: string; phoneType: PhoneTypesEnum }>());

export const SetType = createAction('[Call Log] Set Type', props<{ logType: string }>());

export const SetDate = createAction('[Call Log] Set Date', props<{ date: string }>());

export const SetSentiment = createAction('[Call Log] Set Sentiment', props<{ sentiment: string }>());

export const SetSubject = createAction('[Call Log] Set Subject', props<{ subject: string }>());

export const SetComment = createAction('[Call Log] Set Comment', props<{ comment: string }>());

export const SetContactStatus = createAction('[Call Log] Set Contact Status', props<{ contactStatus: string }>());

export const SetContactStatusColor = createAction('[Call Log] set Contact Status Color', props<{ contactStatusColor: string }>());

export const SetFollowupTask = createAction('[Call Log] Set Followup Task', props<{ callLogFollowupTask: CallLogFollowUpTask }>());

export const SetManualLogCallInfo = createAction('[Manual Call Log] Manual Log Call Info', props<{ isDialogOpen: boolean; contactId: number }>());

export const ResetFollowupTask = createAction('[Call Log] Reset Followup Task');
