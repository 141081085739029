import { MergeVariableMap } from '@app/common/interface/merge-variable-map';

export interface RenderEmailResponse {
  html: string;
  simpleHtml: string;
  plainText: string;
  subject: string;
}

export enum HtmlChar {
  space = '&nbsp;',
}

export interface ParsingData {
  mapping: MergeVariableMap;
  mergeVariableValues: string[];
}

export const junk = ['\n', '<div>', '</div>'];

export class MergeVariableRegexPattern {
  public static readonly allMergeVariables = /{[^{}]*}/g;
  public static readonly customMergeVariablesWithBrackets = /{[^{}]*_c}/g;
  public static readonly customMergeVariablesTest = /{[^{}]*_c}/;
  public static readonly mergeVariablesInsideElements = /<[^<>{}]*{[^<>{}]*}[^<>{}]*>/g;
}
