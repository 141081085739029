import { Directive, Input, Renderer2, ElementRef, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[automationId]',
})
export class AutomationDirective implements OnChanges {
  @Input('automationId') qaAutomationId: string;
  constructor(public renderer: Renderer2, public hostElement: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.qaAutomationId && changes.qaAutomationId.currentValue)
      this.renderer.setAttribute(this.hostElement.nativeElement, 'automation-id', changes.qaAutomationId.currentValue);
  }
}
