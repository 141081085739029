import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';

@Injectable({
  providedIn: 'root',
})
export class DatadogService {
  constructor() {}

  public registerView(pageUrl: string) {
    const viewName = this.getDatadogViewName(pageUrl);
    datadogRum.startView({
      name: viewName,
      service: 'engage',
      version: '1.0.0', // keeping it same as in app bootstrap for consistency
    });
  }

  /**
   * Private fns
   */

  /**
   * Transformation
   * /app/contacts/v2/1119846694242617 -> /app/contacts/v2/?
   * /app/contacts/v2?query=eyJxIjp7ImFjdGl2ZV9hdCI6W10sIkV -> /app/contacts/v2
   *
   * @param pageUrl
   * @private
   */
  private getDatadogViewName(pageUrl: string): string {
    return (pageUrl || 'unknown').split('?')[0].replace(/\/(\d+)/g, '/?');
  }
}
