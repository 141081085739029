import { StatusPickListResourceType } from '@app/core/enums/resource-type.enum';
import * as _ from 'lodash';

export enum filterCategoryTypeEnum {
  CHECKBOX_SEARCH = 'checkbox_search',
  CHECKBOX_SEARCH_WITHOUT_COUNT = 'checkbox_search_without_count',
  RANGE = 'range',
  LOCATION_GROUP = 'location_group',
  OWNER_FILTER = 'owner_filter',
  CONTACT_OWNER = 'contact_owner',
  TEAM_ID = 'team_id',
  GENERIC_FILTER = 'generic_filter',
}

export enum GlobalFilterKey {
  COMPANIES = 'companies',
  NUMBER_OF_EMPLOYEES = 'numbers_of_employees',
  ANNUAL_REVENUES = 'annual_revenues',
  DEPARTMENTS = 'departments',
  INDUSTRIES = 'industries',
  LOCATION = 'location',
  JOB_TITLES = 'job_titles',
  LIST_IDS = 'list_ids',
  OWNER_ID = 'owner_id',
  CONTACT_STATUS = 'contact_statuses',
  TEAM_ID = 'team_id',
  GENERIC_FILTER = 'generic_filter',
  SALESFLOW = 'salesflow',
  TAGS = 'tag_ids',
}

export enum LocationGlobalFilterChildKey {
  COUNTRIES = 'countries',
  STATE_OR_PROVINCES = 'state_or_provinces',
  CITIES = 'cities',
}

export const revenueRanges = [
  { label: 'Min', value: 0 },
  { label: '5M', value: 5000000 },
  { label: '10M', value: 10000000 },
  { label: '25M', value: 25000000 },
  { label: '50M', value: 50000000 },
  { label: '100M', value: 100000000 },
];

export const globalFiltersConfig = {
  name: 'Global Filters',
  category: [
    {
      name: 'Company',
      key: GlobalFilterKey.COMPANIES,
      type: filterCategoryTypeEnum.CHECKBOX_SEARCH,
      showFlag: false,
      child_keys: [],
      dynamicSearch: true,
      dynamicSearchField: 'company',
    },
    {
      name: 'Number of Employees',
      key: GlobalFilterKey.NUMBER_OF_EMPLOYEES,
      outputKey: 'number_of_employees',
      type: filterCategoryTypeEnum.RANGE,
      showFlag: false,
      child_keys: [],
    },
    {
      name: 'Annual Revenue ($)',
      key: GlobalFilterKey.ANNUAL_REVENUES,
      outputKey: 'annual_revenue',
      type: filterCategoryTypeEnum.RANGE,
      showFlag: false,
      child_keys: [],
    },
    {
      name: 'Departments',
      key: GlobalFilterKey.DEPARTMENTS,
      type: filterCategoryTypeEnum.CHECKBOX_SEARCH,
      showFlag: false,
      child_keys: [],
    },
    {
      name: 'Industry',
      key: GlobalFilterKey.INDUSTRIES,
      type: filterCategoryTypeEnum.CHECKBOX_SEARCH,
      showFlag: false,
      child_keys: [],
    },
    {
      name: 'Location',
      key: GlobalFilterKey.LOCATION,
      type: filterCategoryTypeEnum.LOCATION_GROUP,
      showFlag: false,
      child_keys: [
        { name: 'Country', key: LocationGlobalFilterChildKey.COUNTRIES, showFlag: false },
        { name: 'State', key: LocationGlobalFilterChildKey.STATE_OR_PROVINCES, showFlag: false },
        { name: 'City', key: LocationGlobalFilterChildKey.CITIES, showFlag: false },
      ],
    },
    {
      name: 'Job Titles',
      key: GlobalFilterKey.JOB_TITLES,
      type: filterCategoryTypeEnum.CHECKBOX_SEARCH,
      showFlag: false,
      child_keys: [],
    },
    {
      name: 'Imports',
      key: GlobalFilterKey.LIST_IDS,
      type: filterCategoryTypeEnum.CHECKBOX_SEARCH,
      showFlag: false,
      child_keys: [],
    },
    {
      name: 'Contact Owner',
      key: GlobalFilterKey.OWNER_ID,
      // type: filterCategoryTypeEnum.CHECKBOX_SEARCH,
      type: filterCategoryTypeEnum.CONTACT_OWNER,
      placeholder: 'Search',
      showFlag: false,
      child_keys: [],
    },
    {
      name: 'Contact Status',
      key: GlobalFilterKey.CONTACT_STATUS,
      type: filterCategoryTypeEnum.CHECKBOX_SEARCH,
      entity: StatusPickListResourceType.Contact,
      showFlag: false,
      child_keys: [],
    },
    {
      name: 'Team Owner',
      key: GlobalFilterKey.TEAM_ID,
      type: filterCategoryTypeEnum.TEAM_ID,
      placeholder: 'Search',
      showFlag: false,
      child_keys: [],
    },
    {
      name: 'Generic Filter',
      key: GlobalFilterKey.GENERIC_FILTER,
      type: filterCategoryTypeEnum.GENERIC_FILTER,
      showFlag: false,
      child_keys: [],
    },
  ],
};

export const companyFiltersConfig = {
  name: 'Company Filters',
  category: [
    {
      name: 'Company',
      key: GlobalFilterKey.COMPANIES,
      type: filterCategoryTypeEnum.CHECKBOX_SEARCH,
      showFlag: false,
      child_keys: [],
      dynamicSearch: true,
      dynamicSearchField: 'company',
    },
    {
      name: 'Number of Employees',
      key: GlobalFilterKey.NUMBER_OF_EMPLOYEES,
      outputKey: 'number_of_employees',
      type: filterCategoryTypeEnum.RANGE,
      showFlag: false,
      child_keys: [],
    },
    {
      name: 'Annual Revenue ($)',
      key: GlobalFilterKey.ANNUAL_REVENUES,
      outputKey: 'annual_revenue',
      type: filterCategoryTypeEnum.RANGE,
      showFlag: false,
      child_keys: [],
    },
    {
      name: 'Departments',
      key: GlobalFilterKey.DEPARTMENTS,
      type: filterCategoryTypeEnum.CHECKBOX_SEARCH,
      showFlag: false,
      child_keys: [],
    },
    {
      name: 'Industry',
      key: GlobalFilterKey.INDUSTRIES,
      type: filterCategoryTypeEnum.CHECKBOX_SEARCH,
      showFlag: false,
      child_keys: [],
    },
  ],
};

export const contactFiltersConfig = {
  name: 'Contact Filters',
  category: [
    {
      name: 'Contact Owner',
      key: GlobalFilterKey.OWNER_ID,
      // type: filterCategoryTypeEnum.CHECKBOX_SEARCH,
      type: filterCategoryTypeEnum.CONTACT_OWNER,
      placeholder: 'Search',
      showFlag: false,
      child_keys: [],
    },
    {
      name: 'Contact Status',
      key: GlobalFilterKey.CONTACT_STATUS,
      type: filterCategoryTypeEnum.CHECKBOX_SEARCH,
      entity: StatusPickListResourceType.Contact,
      showFlag: false,
      child_keys: [],
    },
    {
      name: 'Team Owner',
      key: GlobalFilterKey.TEAM_ID,
      type: filterCategoryTypeEnum.TEAM_ID,
      placeholder: 'Search',
      showFlag: false,
      child_keys: [],
    },
    {
      name: 'Job Titles',
      key: GlobalFilterKey.JOB_TITLES,
      type: filterCategoryTypeEnum.CHECKBOX_SEARCH,
      showFlag: false,
      child_keys: [],
    },
    {
      name: 'Imports',
      key: GlobalFilterKey.LIST_IDS,
      type: filterCategoryTypeEnum.CHECKBOX_SEARCH,
      showFlag: false,
      child_keys: [],
    },
    {
      name: 'Generic Filter',
      key: GlobalFilterKey.GENERIC_FILTER,
      type: filterCategoryTypeEnum.GENERIC_FILTER,
      showFlag: false,
      child_keys: [],
    },
  ],
};

export const locationFiltersConfig = {
  name: 'Location Filters',
  category: [
    {
      name: 'Location',
      key: GlobalFilterKey.LOCATION,
      type: filterCategoryTypeEnum.LOCATION_GROUP,
      showFlag: false,
      child_keys: [
        { name: 'Country', key: LocationGlobalFilterChildKey.COUNTRIES, showFlag: false },
        { name: 'State', key: LocationGlobalFilterChildKey.STATE_OR_PROVINCES, showFlag: false },
        { name: 'City', key: LocationGlobalFilterChildKey.CITIES, showFlag: false },
      ],
    },
  ],
};

export const getKeyForRequest = (key) => {
  const cat = _.get(globalFiltersConfig, 'category').find((category) => _.get(category, 'key') === key);
  return _.get(cat, 'outputKey', key);
};

export const getActualKeyInCaseOutKey = (outputKey) => {
  const cat = _.get(globalFiltersConfig, 'category').find((category) => {
    if (_.get(category, 'outputKey') === outputKey) {
      return category;
    } else if (_.get(category, 'key') === outputKey) {
      return category;
    }
  });
  return _.get(cat, 'key');
};

export const globalFiltersConfigForCompanyAccount = {
  name: 'Global Filters',
  category: [
    {
      name: 'Number of Employees',
      key: GlobalFilterKey.NUMBER_OF_EMPLOYEES,
      outputKey: 'number_of_employees',
      type: filterCategoryTypeEnum.RANGE,
      showFlag: false,
      child_keys: [],
    },
    {
      name: 'Annual Revenue ($)',
      key: GlobalFilterKey.ANNUAL_REVENUES,
      outputKey: 'annual_revenue',
      type: filterCategoryTypeEnum.RANGE,
      showFlag: false,
      child_keys: [],
    },
    {
      name: 'Industry',
      key: GlobalFilterKey.INDUSTRIES,
      type: filterCategoryTypeEnum.CHECKBOX_SEARCH,
      showFlag: false,
      child_keys: [],
    },
    {
      name: 'Location',
      key: GlobalFilterKey.LOCATION,
      type: filterCategoryTypeEnum.LOCATION_GROUP,
      showFlag: false,
      child_keys: [{ name: 'City', key: LocationGlobalFilterChildKey.CITIES, showFlag: false }],
    },
  ],
};
