import { FlagSet } from '../interfaces/flag-set.interface';
import { Flag } from '../types/flag.enum';

export const DefaultFlagSet: FlagSet = {
  [Flag.ROLES_AND_PERMISSONS]: false,
  [Flag.REPORTING_CALL_ANALYTICS]: false,
  [Flag.SENDING_EMAILS_ON_BEHALF_OF]: false,
  [Flag.UMS_INTEGRATION]: false,
  [Flag.ACTIVE_IN_SALESFLOW]: false,
  [Flag.ACCOUNT_ORG_SYNC]: false,
  [Flag.NEVERBOUNCE_INTEGRATION_PHASE_1]: false,
  [Flag.CUSTOM_PROFILES]: false,
  [Flag.ACCOUNT_ORG_SYNC]: false,
  [Flag.ENABLE_SMS]: false,
  [Flag.API_MONITORING_PAGE]: false,
  [Flag.EMAIL_REPLIES_FROM_ENGAGE]: false,
  [Flag.IMPORT_ENABLE_ORGSYNC_RESOLUTION]: false,
  [Flag.EMAIL_REPLIES_FROM_ENGAGE]: false,
  [Flag.CRM_CONTACT_OWNER_MAPPING]: false,
  [Flag.SALESFLOW_VISIBILITY_PHASE1]: true,
  [Flag.USER_MAPPING_PAGE]: false,
  [Flag.SALESFLOW_AND_EMAIL_OBO_CONTACT_OWNER]: false,
  [Flag.BLOCKLISTING_OF_DOMAINS_AND_EMAILS_OUTBOUND]: false,
  [Flag.RECRUITER_OOTB_TEMPLATES]: false,
  [Flag.CONTACT_PROFILE_BACKEND_CLEANUP]: false,
  [Flag.SALESFLOW_BASED_CONTACT_FILTER]: false,
  [Flag.UNIFIED_VOICE_FEED]: false,
  [Flag.NEW_FILTERING_UX_DISCOVERY_AND_ENHANCEMENTS]: false,
  [Flag.SELECT_RANGE_CONTACTS]: true,
  [Flag.UNIE_SALESFORCE_AUTHENTICATION]: false,
  [Flag.DOZI_ADMIN_PORTAL_INTEGRATIONS_ENGAGE]: false,
  [Flag.ADMIN_INTEGRATIONS_HIDE]: false,
  [Flag.ENABLE_DIALER_SERVICE_REQUESTS]: false,
  [Flag.TASK_NOTIFICATION_TRIGGERED_INDIVIDUAL]: false,
  [Flag.SEARCH_SALESFLOW_CONTACTS_TAB]: false,
  [Flag.ORG_MANAGEMENT_SALESFLOW_QUOTA]: false,
  [Flag.SALESFLOW_STEP_AB_TEMPLATE_ORG_INSIGHTS]: false,
  [Flag.FROALA_USABILITY_IMPROVEMENTS]: false,
  [Flag.SALESFLOW_INSIGHTS_BY_IDS_FROM_INSIGHTS_TABLE]: false,
  [Flag.ENABLE_API_ENCODING_FOR_WAF]: false,
  [Flag.DASHBOARD_API_PERF_IMPROVEMENTS]: false,
  [Flag.PREVENT_XSS_EMAIL_TEMPLATES_DISPLAY]: false,
  [Flag.ENGAGE_IN_HOUSE_IFRAME_ANALYTICS]: false,
  [Flag.EMAIL_FROALA_FONT_CHANGES_ON_SPELL_CHECK]: false,
};
