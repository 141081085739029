
export class ConversationContact {
  application: string;
  source: string;
  contactMail: string;
  partOfSalesflow: boolean;
  salesflowStep: string;
  dialerType: string;
  phoneNumber: string;
  incomingCall: boolean;
  callId: string;
  contactId: string;
  zoomPersonId: string;

  public constructor(contact?: Partial<ConversationContact>) {
    Object.assign(this, contact);
  }
}

export class CalledContact extends ConversationContact {
  section: string;
  setFollowUpTask: boolean;
  isAutoDial: boolean;
  component: string;

  public constructor(contact?: Partial<CalledContact>) {
    super();
    Object.assign(this, contact);
  }
}

export class SelectedDialerTypeEvent {
  dialerType: string;

  public constructor(event?: Partial<SelectedDialerTypeEvent>) {
    Object.assign(this, event);
  }
}
