import { createAction, props } from '@ngrx/store';
import { SimpleTagObject } from '@zi-common/pages-component/add-tag-dialog/model/SimpleTagObject';
import { Template } from '@zi-core/data-model/template.model';
import { Tile } from '@zi-core/data-model/tile.model';
import { LabelResourceType } from '@app/core/enums/resource-type.enum';
import { BulkTagChanges } from '@zi-common/pages-component/add-remove-tag-dialog/model/bulk-tag-changes';
import { ContentFilterState } from '@zi-pages/content/ngrx/state/content-filter.state';

export const AddContentAction = createAction(
  '[Content] Add Content Entity',
  props<{ template: any; tiles?: Tile[]; analyticsObj?: any; tags: SimpleTagObject[]; resourceType?: LabelResourceType; createdAnalyticsObj?: any }>(),
);

export const AddContentSuccessAction = createAction('[Content] Add Content Entity Success', props<{ template: Template }>());

export const AddContentErrorAction = createAction('[Content] Add Content Entity Error', props<{ error: any }>());

export const AddOotbContentAction = createAction(
  '[Content] Add OOTB Content Entity',
  props<{ template: any; tiles?: Tile[]; analyticsObj?: any; tags: SimpleTagObject[]; resourceType?: LabelResourceType }>(),
);

export const AddOotbContentSuccessAction = createAction('[Content] Add OOTB Content Entity Success', props<{ template: Template }>());

export const LoadContentAction = createAction(
  '[Content] Load Content Entities',
  props<{ orgId: number; requestId: number; userId: number; page?: number; count?: number }>(),
);

export const LoadContentWithoutBodyAction = createAction('[Content] Load Content Entities v2', props<{ page?: number; count?: number }>());

export const LoadFilteredContentAction = createAction(
  '[Content] entity/filtered/load',
  props<{ requestId: number; contentFilterState: ContentFilterState; userId: number }>(),
);

export const LoadFilteredContentSuccessAction = createAction(
  '[Content] Load Filtered Content Entities Success',
  props<{ templateArray: Template[]; requestId?: number }>(),
);

export const LoadContentSuccessAction = createAction(
  '[Content] Load Content Entities Success',
  props<{ templateArray: Template[]; requestId?: number; userId?: number }>(),
);

export const UpdateContentAction = createAction('[Content] Update Content Entity', props<{ payload: Template; tiles?: Tile[]; analyticsObj?: any }>());

export const UpdateContentSuccessAction = createAction('[Content] Update Content Entity Success', props<{ template: Template }>());

export const UpdateContentErrorAction = createAction('[Content] Update Content Entity Error', props<{ error: string; errorCode?: string }>());

export const DeleteContentAction = createAction('[Content] Delete Content Entity', props<{ template: Template }>());

export const DeleteContentSuccessAction = createAction('[Content] Delete Content Entity Success', props<{ template: Template }>());

export const DeleteContentErrorAction = createAction('[Content] Delete Content Entity Error', props<{ error: string; errorCode?: string }>());

export const BulkDeleteContentAction = createAction('[Content] Bulk Delete Content Entities', props<{ ids: number[] }>());

export const BulkDeleteContentSuccessAction = createAction('[Content] Bulk Delete Content Entities Success', props<{ ids: number[] }>());

export const BulkDeleteContentErrorAction = createAction('[Content] Bulk Delete Content Entities Error');

// bulk tag contents
export const BulkTagContentsAction = createAction('[Content] entity/tag/v2', props<{ changes: BulkTagChanges; resourceType: string; pageSource: string }>());

export const BulkTagContentsSuccessAction = createAction('[Content] entity/tag/v2/success', props<{ bulkTagChanges: BulkTagChanges }>());

// bulk tag contents failure
export const BulkTagContentsFailureAction = createAction('[Content] entity/tag/failure', props<{ error: any; errorCode?: string }>());

export const SetEmailContent = createAction('[Content] entity/content/templateData', props<{ template: Template }>());
