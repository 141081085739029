import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Profile } from '@zi-core/data-model/role.model';
import { environment } from '@env/environment';
import { UserInProfilePermissions } from '../pages/profiles-and-permissions/profiles-and-permissions.config';
import { UnassignedProfileUser } from '@app/core/data-model/profile.model';

@Injectable({
  providedIn: 'root',
})
export class ProfilesService {
  constructor(private http: HttpClient) {}

  getProfileList(orgId): Observable<Profile[]> {
    return this.http.get<any>(`${environment.backend_url}/v1/role_permissions/allfororg?OrgId=${orgId}`);
  }

  getUserByProfile(orgId, roleId): Observable<UserInProfilePermissions[]> {
    return this.http.get<any>(`${environment.backend_url}/v1/role_permissions/allUsersByRole?OrgId=${orgId}&RoleId=${roleId}`);
  }

  addProfile(profile, orgId: number): Observable<Profile> {
    return this.http.post(`${environment.backend_url}/v1/role_permissions/create?OrgId=${orgId}`, profile);
  }

  editProfile(profile): Observable<Profile> {
    return this.http.post(`${environment.backend_url}/v1/role_permissions/update`, profile);
  }

  getUnAssignedUsers(orgId: number, roleId: number): Observable<UnassignedProfileUser[]> {
    return this.http.get<UnassignedProfileUser[]>(`${environment.backend_url}/v1/role_permissions/unassignedUsers?OrgId=${orgId}&RoleId=${roleId}`);
  }

  deleteProfile(roleId): Observable<Profile> {
    return this.http.delete(`${environment.backend_url}/v1/role_permissions/delete?RoleId=${roleId}`);
  }

  assignUserToProfile(userId, roleId): Observable<Profile> {
    return this.http.post(`${environment.backend_url}/v1/role_permissions/updateUserRole`, { userId: userId, roleId: roleId });
  }

  assignMultipleUsersToProfile(orgId: number, userIds: number[], profileId: number): Observable<Profile> {
    return this.http.post(`${environment.backend_url}/v1/role_permissions/updateMultipleUsersWithRole`, {
      userIds,
      orgId,
      roleId: profileId,
    });
  }

  assignUsersToProfile(data): Observable<Profile> {
    return this.http.post(`${environment.backend_url}/v1/role_permissions/addUsersToRole`, data);
  }
}
