import { Action, createReducer, on } from '@ngrx/store';
import {
  SetOnBoardingFailedAction,
  SetOnBoardingSuccessAction,
  UpdateCurrentStep,
  UpdateUnfinishedStepsAction,
  UpdateUnfinishedStepsSuccessAction,
} from '../action/on-boarding.action';
import { initialOnBoardingEntity, OnBoardingEntityState } from '../state/on-boarding.state';

const OnBoardingReducer = createReducer(
  initialOnBoardingEntity,

  on(UpdateCurrentStep, (state: OnBoardingEntityState, { currentStep }) => {
    return { ...state, currentStepIndex: currentStep };
  }),
  on(UpdateUnfinishedStepsAction, (state: OnBoardingEntityState) => {
    return { ...state };
  }),
  on(UpdateUnfinishedStepsSuccessAction, (state: OnBoardingEntityState, { currentStep, unfinishedSteps }) => {
    return { ...state, currentStepIndex: currentStep, unfinishedSteps };
  }),
  on(SetOnBoardingSuccessAction, (state: OnBoardingEntityState, { currentStep, unfinishedSteps }) => {
    return { ...state, currentStepIndex: currentStep, unfinishedSteps };
  }),

  on(SetOnBoardingFailedAction, (state: OnBoardingEntityState) => {
    return { ...state, currentStepIndex: undefined, unfinishedSteps: undefined };
  }),
);

export function OnBoardingEntityReducer(state: OnBoardingEntityState, action: Action): OnBoardingEntityState {
  return OnBoardingReducer(state, action);
}
