import { SortOption, SortSection } from '@app/common/model/dropdown/select.model';
import { ImportSortField } from '@zi-pages/imports-history-v2/models/imports.history.enum';

export enum EntityType {
  CONTACT = 'CONTACT',
  ACCOUNT = 'ACCOUNT',
  USER = 'user',
}

export enum ListSource {
  DOZI = 'DOZI',
  SALESFORCE = 'SALESFORCE',
  RECOMMENDATIONS = 'RECOMMENDATIONS',
  CSV = 'CSV',
  MOST_ENGAGED_PERSONA = 'MOST_ENGAGED_PERSONA',
  DYNAMICS = 'DYNAMICS',
  TOP_TWENTY_CHARACTERISTICS = 'TOP_TWENTY_CHARACTERISTICS',
}

export enum ImportType {
  ACCOUNT,
  CONTACT,
  USER,
}

export enum DuplicateSettings {
  SKIP = 'SKIP',
  UPDATE_WHEN_EMPTY = 'UPDATE_WHEN_EMPTY',
  OVERWRITE = 'OVERWRITE',
}

export enum SyncResponses {
  IN_PROGRESS = 'Import in Progress',
  FAILED = 'Import failed',
}

export enum SortDirection {
  ASC = 'Ascending',
  DESC = 'Descending',
}
export enum ZoomInternalListType {
  LIST = 'List',
  TAG = 'Tag',
  SAVED_SEARCH = 'Saved_Search',
}
export enum SalesforceListType {
  ALL = 'ALL',
  LIST = 'LIST',
  REPORT = 'REPORT',
}
export const ImportsSortOptions: SortSection[] = [
  {
    displayName: '',
    options: [
      new SortOption('Date', ImportSortField.DATE),
      new SortOption('Name', ImportSortField.NAME),
      new SortOption('Number of Contacts', ImportSortField.NUMBER_OF_CONTACTS),
      new SortOption('Import Type', ImportSortField.IMPORT_TYPE),
    ],
  },
];
export const SyncOptions = [
  { displayName: 'Don’t sync for new contacts', value: undefined },
  { displayName: 'Daily', value: 'Daily' },
  { displayName: 'Weekly', value: 'Weekly' },
  { displayName: 'Monthly', value: 'Monthly' },
];

export const SALES_FLOW_MANAGED_SOURCES = ['DOZI', 'SALESFORCE', 'DYNAMICS'];

export enum ImportDuplicateSettings {
  SKIP = 1,
  OVERWRITE = 2,
  UPDATE_WHEN_EMPTY = 4,
}
