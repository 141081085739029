import { FilterEntity } from '@app/core/enums/engage-filter.enum';
import { FilterDateRangeValuesEnum } from '@app/core/enums/date-aggregates.enum';
import { ContentSortField } from '@zi-pages/content/ngrx/state/content-filter.state';
import { SortOption, SortSection } from '@zi-common/model/dropdown/select.model';

export const CONTENT_GLOBAL_FILTER = {
  name: 'Filters',
  category: [
    {
      name: 'Date',
      key: 'date',
      other_key: 'date',
      type: 'date_group',
      showFlag: false,
      child_keys: [
        {
          name: 'field',
          key: 'date_field',
          showFlag: true,
          values: [
            {
              type: 1,
              entity: FilterEntity.MessageTemplate,
              category: 'ContactFields',
              displayName: 'Updated',
              key: 'date_field',
              value: 'modifiedAsTimestamp',
              hitCount: 0,
            },
            {
              type: 1,
              entity: FilterEntity.MessageTemplate,
              category: 'ContactFields',
              displayName: 'Created',
              key: 'date_field',
              value: 'createdAsTimestamp',
              hitCount: 0,
            },
          ],
        },
        {
          name: 'range',
          key: 'date_range',
          showFlag: true,
          values: [
            {
              type: 1,
              entity: FilterEntity.MessageTemplate,
              category: 'ContactFields',
              displayName: 'Last Week',
              key: 'date_range',
              value: FilterDateRangeValuesEnum.lastWeek,
              hitCount: 0,
            },
            {
              type: 1,
              entity: FilterEntity.MessageTemplate,
              category: 'ContactFields',
              displayName: 'Last Two Weeks',
              key: 'date_range',
              value: FilterDateRangeValuesEnum.lastTwoWeeks,
              hitCount: 0,
            },
            {
              type: 1,
              entity: FilterEntity.MessageTemplate,
              category: 'ContactFields',
              displayName: 'Current Month',
              key: 'date_range',
              value: FilterDateRangeValuesEnum.currentMonth,
              hitCount: 0,
            },
            {
              type: 1,
              entity: FilterEntity.MessageTemplate,
              category: 'ContactFields',
              displayName: 'Customize Date',
              key: 'date_range',
              value: FilterDateRangeValuesEnum.custom,
              hitCount: 0,
            },
          ],
        },
      ],
    },
    {
      name: 'Owner',
      key: 'owner',
      type: 'checkbox_search_without_count',
      showFlag: false,
      values: [],
    },
  ],
};

export const CONTENT_EMAIL_TEMPLATE_FILTER = {
  name: 'Email Templates',
  category: [
    {
      name: 'Date',
      key: 'date',
      other_key: 'date',
      type: 'date_group',
      showFlag: false,
      child_keys: [
        {
          name: 'field',
          key: 'date_field',
          showFlag: true,
          values: [
            {
              type: 1,
              entity: FilterEntity.MessageTemplate,
              category: 'ContactFields',
              displayName: 'Created',
              key: 'date_field',
              value: 'createdAsTimestamp',
              hitCount: 0,
            },
            {
              type: 1,
              entity: FilterEntity.MessageTemplate,
              category: 'ContactFields',
              displayName: 'Updated',
              key: 'date_field',
              value: 'modifiedAsTimestamp',
              hitCount: 0,
            },
          ],
        },
        {
          name: 'range',
          key: 'date_range',
          showFlag: true,
          values: [
            {
              type: 1,
              entity: FilterEntity.MessageTemplate,
              category: 'ContactFields',
              displayName: 'Any time',
              key: 'date_range',
              value: FilterDateRangeValuesEnum.any,
              hitCount: 0,
            },
            {
              type: 1,
              entity: FilterEntity.MessageTemplate,
              category: 'ContactFields',
              displayName: 'Last 7 days',
              key: 'date_range',
              value: FilterDateRangeValuesEnum.lastWeek,
              hitCount: 0,
            },
            {
              type: 1,
              entity: FilterEntity.MessageTemplate,
              category: 'ContactFields',
              displayName: 'Last 14 days',
              key: 'date_range',
              value: FilterDateRangeValuesEnum.lastTwoWeeks,
              hitCount: 0,
            },
            {
              type: 1,
              entity: FilterEntity.MessageTemplate,
              category: 'ContactFields',
              displayName: 'This month',
              key: 'date_range',
              value: FilterDateRangeValuesEnum.currentMonth,
              hitCount: 0,
            },
            {
              type: 1,
              entity: FilterEntity.MessageTemplate,
              category: 'ContactFields',
              displayName: 'This year',
              key: 'date_range',
              value: FilterDateRangeValuesEnum.currentYear,
              hitCount: 0,
            },
            {
              type: 1,
              entity: FilterEntity.MessageTemplate,
              category: 'ContactFields',
              displayName: 'Custom',
              key: 'date_range',
              value: FilterDateRangeValuesEnum.custom,
              hitCount: 0,
            },
          ],
        },
      ],
    },
    {
      name: 'Owner',
      key: 'owner',
      type: 'checkbox_search_without_count',
      showFlag: false,
      values: [],
    },
  ],
};

export const SORT_SECTIONS: SortSection[] = [
  {
    displayName: 'Rate',
    options: [
      new SortOption('Emails Sent', ContentSortField.SENT_COUNT),
      new SortOption('Opens', ContentSortField.OPEN_RATE),
      new SortOption('Link Clicks', ContentSortField.LINK_COUNT),
      new SortOption('Replies', ContentSortField.REPLY_RATE),
      new SortOption('Unsubscribes', ContentSortField.UNSUBSCRIBED_RATE),
    ],
  },
  { displayName: 'Amount of', options: [new SortOption('Tags', ContentSortField.TAGS), new SortOption('Salesflows', ContentSortField.SALESFLOW)] },
  { displayName: 'Date', options: [new SortOption('Update Date', ContentSortField.UPDATE_DATE), new SortOption('Create Date', ContentSortField.CREATE_DATE)] },
];

export const TEMPLATE_CREATED_ITEM_FILTER_KEY = 'template_created_by_filter';
export const TEAM_TEMPLATE_CREATED_ITEM_FILTER_KEY = 'team_template_created_by_filter';
