import { ENTERPRISE_FEATURE_GROUP } from './permissions';

export interface ZoomRole {
  name: string;
  permissions: string[];
}

export const EnterpriseRole: ZoomRole = {
  name: 'ENTERPRISE',
  permissions: [...ENTERPRISE_FEATURE_GROUP],
};

export const NoneRole: ZoomRole = {
  name: 'NONE',
  permissions: [],
};
