import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { ExtensionIframeMessagingService } from '@app/extension/service/extension-iframe-messaging.service';
import { getExtensionMode } from '@app/extensionMode.config';
import { LoginService } from '@app/login/services/login.service';
import { ApplicationState } from '@app/reducers';
import { Store } from '@ngrx/store';
import { MessageType } from '@zi-common/model/message/message-type';
import { Message } from '@zi-common/model/message/message.model';
import { NotyService } from '@zi-common/service/noty/noty.service';
import { UserLicensesResponseModel } from '@zi-core/data-model/dozi-integration/response/user-licenses.response.model';
import { LogoutAction, SetUserLicensesFailureAction, SetUserLicensesSuccessAction } from '@zi-core/ngrx/action/auth.action';
import { getValidAuthToken, getZoomCustomerSalesforceId } from '@zi-core/ngrx/state/auth.state';
import { DoziIntegrationService } from '@zi-core/service/dozi-integration.service';
import { NavigationService } from '@zi-core/service/navigation.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { fromEvent, Observable, of, Subscription } from 'rxjs';
import { catchError, filter, map, mergeMap, take, timeout, withLatestFrom } from 'rxjs/operators';
import { environment } from '@env/environment';
import { FeatureFlagService } from '@app/feature-flag/feature-flag.service';
import { Flag } from '@app/feature-flag/types/flag.enum';
import { UmsWaffleNavbarService } from '@app/common/service/ums-waffle-menu/ums-waffle-navbar.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  AuthTokenLocalStorageRemoved$: Subscription;

  readonly epochMilliSecondsMultiplier: number = 1000;
  isUmsIntegrationEnabled: boolean = false;

  constructor(
    private store: Store<ApplicationState>,
    private router: Router,
    private navigationService: NavigationService,
    private extensionIframeMessagingService: ExtensionIframeMessagingService,
    private loginService: LoginService,
    private notyService: NotyService,
    private doziIntegrationService: DoziIntegrationService,
    private featureFlagService: FeatureFlagService,
    private doziCookieService: UmsWaffleNavbarService,
  ) {}
  /**
   * Check if the user is authenticated
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    // if there is no open listening to 'authenticatedToken' local storage, create
    if (environment.enable_auth_token_local_storage_removed_listener && !this.AuthTokenLocalStorageRemoved$) {
      this.subscribeToRemoveTokenLocalStorage();
    }

    this.featureFlagService
      .observe<Flag.UMS_INTEGRATION>(Flag.UMS_INTEGRATION)
      .pipe(
        map((isUmsIntegrationEnabled) => {
          this.isUmsIntegrationEnabled = isUmsIntegrationEnabled;
        }),
      )
      .subscribe();

    return this.store.select(getValidAuthToken).pipe(
      take(1), // take only one to end the observable
      map((authToken) => {
        if (this.doziCookieService?.umsToken && !this.doziCookieService.umsToken?.ziPlatforms.includes('ENGAGE')) {
          this.navigationService.navigateToLogin(state.url);
          return false;
        } else if (authToken && moment(new Date(authToken.expiresAt)).isAfter(moment())) {
          if (getExtensionMode()) {
            this.extensionIframeMessagingService.sendLoggedInMessage();
          }
          return true;
        } else if (authToken && moment(new Date(authToken.expiresAt)).isSameOrBefore(moment()) && !this.isUmsIntegrationEnabled) {
          // Token expired, so force logout.
          this.notyService.postMessage(new Message(MessageType.ERROR, `Your session has expired. Please log in again.`));
          this.loginService.generalLogout(state.url);
          return false;
        } else if (
          this.doziCookieService.umsToken &&
          moment(new Date(this.doziCookieService.umsToken.exp * this.epochMilliSecondsMultiplier)).isSameOrBefore(moment()) &&
          this.isUmsIntegrationEnabled
        ) {
          this.loginService.generalLogout(state.url);
          this.navigationService.navigateToDoziLogin();
          return false;
        } else {
          this.navigationService.navigateToLogin(state.url);
          return false;
        }
      }),
      withLatestFrom(this.store.select(getZoomCustomerSalesforceId)),
      mergeMap(([canActivateRoute, zoomCustSalesforceId]) => {
        // user licenses do not need to be retrieved if auth token is not valid
        if (!canActivateRoute) {
          return of(canActivateRoute);
        }

        // Loads user license in store based on zoomCustSalesforceId
        if (zoomCustSalesforceId) {
          return this.doziIntegrationService.getUserLicenses({ zoomCustSalesforceId }).pipe(
            // timeout http call after 2 seconds to avoid showing users a blank screen
            timeout(2000),
            map((resp: UserLicensesResponseModel) => {
              this.store.dispatch(SetUserLicensesSuccessAction({ licenses: !_.isEmpty(resp) ? resp : null }));
              return canActivateRoute;
            }),
            catchError((err) => {
              this.store.dispatch(SetUserLicensesFailureAction(err));
              return of(canActivateRoute);
            }),
          );
        }
        this.store.dispatch(SetUserLicensesSuccessAction({ licenses: null }));
        return of(canActivateRoute);
      }),
    );
  }

  /**
   * Listen to changes of 'authenticatedToken' nested to 'auth' key in local storage
   * If the authenticatedToken removed means user is logged out
   * This event can come from other tabs
   * Check if new value of auth key local storage not contain a token
   * Select auth token from state and check if exist
   * If token exist in state and remove from local storage:
   *  Dispatch logout on current tab to clean authToken from state
   *  If extensionIframeMessagingService.targetOrigin exist - Post message to parent - to disconnect extension
   *  Navigate to login page
   * @private
   */
  private subscribeToRemoveTokenLocalStorage(): void {
    this.AuthTokenLocalStorageRemoved$ = fromEvent(window, 'storage')
      .pipe(
        filter((ev: StorageEvent) => ev.key === 'auth'),
        filter((ev: StorageEvent) => {
          const newVal = JSON.parse(ev.newValue);
          if (!newVal?.authenticatedToken?.token) {
            return true;
          }
        }),
        withLatestFrom(this.store.select(getValidAuthToken)),
        filter(([ev, authToken]) => !!authToken?.token),
        take(1),
      )
      .subscribe((ev) => {
        // Auth token removed from local storage, and user is logged out
        this.AuthTokenLocalStorageRemoved$.unsubscribe();
        this.AuthTokenLocalStorageRemoved$ = null;
        this.store.dispatch(LogoutAction({}));

        if (this.extensionIframeMessagingService.targetOrigin) {
          this.extensionIframeMessagingService.sendLoggedOutMessage();
        }
        this.router.navigate([`/`]);
      });
  }
}
