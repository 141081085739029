import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { SearchQueryRequest } from '@app/core/http-model/request/contact-search.request.model';
import { SearchResponse } from '@app/core/http-model/response/contact-search.response.model';
import { Observable } from 'rxjs';
import { SearchQueryAnalyticsModel } from '@zi-core/data-model/analytics/search-query-analytics.model';
import { ContactFilterState } from '@zi-pages/contact/ngrx/state/contact-filter.state';
import * as _ from 'lodash';
import { AnalyticsService } from '@zi-core/service/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class EntitySearchService {
  constructor(private httpClient: HttpClient, private analyticsService: AnalyticsService) {}

  querySearch(resource: SearchQueryRequest): Observable<SearchResponse> {
    return this.httpClient.post<SearchResponse>(`${environment.backend_url}/v1/search/query`, resource);
  }

  getAnalyticsEventProperties(searchResponse: SearchResponse, filterState: ContactFilterState): SearchQueryAnalyticsModel {
    const defaultEventProperties = new SearchQueryAnalyticsModel();

    const salesflows = _.get(filterState, 'query.salesflow[0]') ? [filterState.query.salesflow[0].name] : [];
    const tags = _.get(filterState, 'query.tags', []).map((filter) => filter.value);
    const salesflowsStatus = salesflows.length ? 'true' : 'false';

    const engagementParameters = this.analyticsService.getEngagementFilterAnalytics(filterState.query);
    const contactParameters = this.analyticsService.getContactsFilterAnalytics(filterState.query);

    const eventProperties = {
      ...defaultEventProperties,
      ...engagementParameters,
      ...contactParameters,
      object: 'Contact',
      amount: searchResponse.contacts.length,
      tags,
      salesflows,
      salesflowsStatus,
    };

    return eventProperties;
  }
}
