import { Component, OnInit, Inject, TemplateRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';

export enum SourceOfClose {
  'XIcon',
  'CancelButton',
}

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent implements OnInit {
  sourceOfClose = SourceOfClose;
  title: string;
  content: string;
  subContent: string;
  // Optional fields
  secondaryBoltonDisabled = false;
  primaryButtonText = 'Ok';
  secondaryButtonText = 'Cancel';
  returnValueFromXIcon = false;
  primaryButtonDisabled: boolean;
  isBold = false;
  templateRef: TemplateRef<any>;

  constructor(private dialogRef: MatDialogRef<ErrorDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.title = this.data.title;
    this.content = this.data.content;
    // Optional fields
    this.subContent = _.get(this.data, 'subContent');
    this.secondaryBoltonDisabled = this.data.secondaryBoltonDisabled || this.data.secondaryButtonDisabled || false;
    this.primaryButtonText = _.get(this.data, 'primaryButtonText', 'Ok');
    this.secondaryButtonText = _.get(this.data, 'secondaryButtonText', 'Cancel');
    this.returnValueFromXIcon = _.get(this.data, 'returnValueFromXIcon', false);
    this.primaryButtonDisabled = _.get(this.data, 'primaryButtonDisabled', false);
    this.isBold = _.get(this.data, 'isBold', false);
    this.templateRef = _.get(this.data, 'templateRef', null);
  }

  onCancel(source: SourceOfClose) {
    if (this.returnValueFromXIcon !== false && source === SourceOfClose.XIcon) {
      // Option to return different values for C icon and Cancel Button.
      this.dialogRef.close(this.returnValueFromXIcon);
    } else {
      this.dialogRef.close(false);
    }
  }

  onSend() {
    this.dialogRef.close(true);
  }
}
