export enum IdProviderType {
  None = 0,
  Gmail = 7,
  Microsoft = 8, // Microsoft Account
  Tellwise = 10,
  Salesforce = 21,
  Dynamics = 22, // Microsoft Dynamics CRM/Online
  DiscoverOrg = 23, // Username Password
  Office365 = 24, // Office 365 Mail, Calendar, Contacts
  ExchangeBasicAuth = 25, // Exchange On Premis
  Hubspot = 26,
  ZoomInfo = 27,
  Dozi = 28,
  UnieSalesforce = 29,
  UnieHubSpot = 30,
  UnieDynamics = 31,
}
