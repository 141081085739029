import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { datadogRum, RumEvent } from '@datadog/browser-rum';
import { RumEventDomainContext } from '@datadog/browser-rum-core/cjs/domainContext.types';
import _get from 'lodash-es/get';

// Enable dd rum only for staging and production
if (environment.enable_dd) {
  datadogRum.init({
    applicationId: '08967cb1-22a7-4d61-82db-2c4224a0df92',
    clientToken: 'pub3528c41c108214edbf0ec29f84b375a0',
    site: 'datadoghq.com',
    service: 'engage',
    env: environment.name,
    version: '1.0.0', // Specify a version number to identify the deployed version of your application in Datadog
    sampleRate: environment.name === 'staging' ? 100 : 30,
    trackInteractions: true,
    trackViewsManually: true,
    allowedTracingUrls: [
      // Sisense does not support dd headers
      /http(s?):\/\/.*\.zoominfo\.com\/(?!(import-service\/sisense))/,
      /http(s?):\/\/.*\.tellwise\.com/,
    ],
    excludedActivityUrls: [
      /\/rest\/event-heartbeat/,
      /amplitude/,
      /launchdarkly/,
      /\/rest\/event-stream/,
      /\.salesforceliveagent\.com\/chat\/rest\/Visitor\/Availability.json/,
    ],
    beforeSend: (rumEvent: RumEvent, rumDomainContext: RumEventDomainContext) => {
      if (
        rumEvent.type === 'resource' &&
        _get(event, 'target.status') === 403 &&
        _get(event, 'target.responseText', '').includes('Sorry, you have been blocked') &&
        _get(event, 'target.responseText', '').includes('Cloudflare')
      ) {
        // tracking cloudflare 403 errors
        rumEvent.context = {
          ...rumEvent.context,
          cloudflare403: true,
          payloadEncoded: btoa(_get(event, 'target.__zone_symbol__xhrTask.data.args[0]', '')),
        };
      }
    },
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => {
    // eslint-disable-next-line no-console
    console.error(err);
  });
