import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'possessiveName',
})
export class PossessiveNamePipe implements PipeTransform {
  transform(value: string): string {
    if (!value || value.endsWith("'s")) {
      return value;
    } else if (value.endsWith("'")) {
      return `${value}s`;
    } else if (value.endsWith('s')) {
      return `${value}'`;
    }
    return `${value}'s`;
  }
}
