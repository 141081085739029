import { PreloadingStrategy, Route } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

/**
 * Application custom pre-loading strategy.
 * Only pre-load modules with 'preload' variable in route data set to true. Forex: 'contacts'.
 * The remaining modules will be lazy-loaded on demand.
 */
@Injectable()
export class AppCustomPreloadingStrategy implements PreloadingStrategy {
  constructor() {}

  preload(route: Route, preload: () => Observable<any>): Observable<any> {
    return route?.data?.preload ? preload() : of(null);
  }
}
