import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AttachmentChip } from '@zi-common/interface/attachment-chip';

@Component({
  selector: 'app-attachment-chip',
  templateUrl: './attachment-chip.component.html',
  styleUrls: ['./attachment-chip.component.scss'],
})
export class AttachmentChipComponent implements OnInit {
  @Input() attachInfo: AttachmentChip;
  @Output() clicked = new EventEmitter<AttachmentChip>();
  @Input() isPreviewMode = false;

  constructor() {}

  ngOnInit() {}

  onClick() {
    this.clicked.emit(this.attachInfo);
  }
}
