export class VerifiedPhoneNumberForCalls {
  application: string;
  source: string;
  hasExtension: boolean;
  selectedSaveOption: string;

  public constructor(phone?: Partial<VerifiedPhoneNumberForCalls>) {
    Object.assign(this, phone);
  }
}
