import { DialerSelection } from '@app/core/enums/dialer.enum';
import { TwilioCallerId } from '@app/core/http-model/response/phone-verifier.response.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { defaultLoadingState } from '@zi-core/ngrx/config/ngrx-loading-states.config';

export class DialerConfigState {
  dialerSelection: DialerSelection | null;
  voip: TwilioCallerId | null;
  bridge: TwilioCallerId | null;
  loaded: boolean;
  loading: boolean;
  error: any;
}

export const initialDialerConfigState: DialerConfigState = {
  dialerSelection: null,
  voip: null,
  bridge: null,
  ...defaultLoadingState,
};

export const DialerConfigFeatureSelector = createFeatureSelector<DialerConfigState>('dialerConfig');

export const getDialerVoip = createSelector(DialerConfigFeatureSelector, (state) => state.voip);

export const getDialerBridge = createSelector(DialerConfigFeatureSelector, (state) => state.bridge);
