<div *ngIf="messages$ | async as messages">
  <div class="zi-noty" *ngIf="messages.length > 0">
    <div class="zi-noty-parent" *ngFor="let message of messages">
      <div class="zi-noty-message" [ngClass]="message.styleClass">
        <div class="zi-noty-message-container">
          <img class="zi-noty-message-container-main-icon" *ngIf="message.imgSrc" [src]="message.imgSrc" alt="error" />
          <div class="zi-noty-message-container-main-content" [innerHTML]="message.content"></div>
          <i class="icon-x zi-noty-message-container-close" (click)="deleteMessage(message.id)"></i>
        </div>
      </div>
    </div>
  </div>
</div>
