<app-dialog-template dialogTitle="Private Email Template" hasCloseIcon="true" (close)="onClose()">
  <app-dialog-content>
    <div class="salesflow-template-container">
      <div class="notification row">
        <p>One or more steps in your salesflow uses a private template. If you continue, those templates will be changed to shared templates.</p>
      </div>
      <div class="question row">
        <p>Are you sure you want to continue?</p>
      </div>
    </div>
  </app-dialog-content>

  <app-dialog-footer>
    <app-primary-btn class="align-button" [text]="'Yes'" (clickButton)="updateTemplates()" [automationId]="'Yes'"></app-primary-btn>
    <app-secondary-btn [text]="'Cancel'" (clickButton)="onClose()"></app-secondary-btn>
  </app-dialog-footer>
</app-dialog-template>
