import { Injectable } from '@angular/core';

@Injectable()
export class OwnerColorService {
  colors = ['#670F5E', '#A01792', '#CF46C1', '#980E40', '#FF5F72', '#0041C0', '#428AFF'];

  constructor() {}

  getColorByOwnerId(id: number) {
    const colorIndex = (id || 0) % this.colors.length;
    return this.colors[colorIndex];
  }
}
