import { Inject, Injectable } from '@angular/core';
import { TwilioClientToken } from '@zi-core/http-model/response/authenticated-token.response.model';
import { Observable, Subscription, timer } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApplicationState } from '@app/reducers';
import { getTwilioClientToken } from '@zi-core/ngrx/state/auth.state';
import { map, mergeMap, take } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { LoadTwilioClientTokenAction } from '@zi-core/ngrx/action/auth.action';
import { VoipDeviceService } from '@app/caller/service/voip-device.service';
import { environment } from '@env/environment';
import { LoggerServiceToken } from '@zi-core/config/logger-service.config';
import { ILoggerService } from '@zi-core/interface/logger.service.interface';

@Injectable()
export class TwilioTokenRefreshService {
  private twilioClientToken$: Observable<TwilioClientToken>;
  private refreshTokenSubscription: Subscription = new Subscription();
  private deviceDisconnectListenerSubscription: Subscription = new Subscription();
  private tokenNotificationListenerSubscription: Subscription = new Subscription();

  private isStarted = false;

  constructor(
    private store: Store<ApplicationState>,
    private voipDeviceService: VoipDeviceService,
    @Inject(LoggerServiceToken) private loggerService: ILoggerService,
  ) {
    this.twilioClientToken$ = this.store.select(getTwilioClientToken);
  }

  start() {
    if (this.isStarted) {
      this.stop();
    }
    this.isStarted = true;
    this.setRefreshTokenTimer();
    this.setupResetTokenNotiListener();
  }

  stop() {
    this.isStarted = false;
    this.stopTimerSubs();
    this.tokenNotificationListenerSubscription.unsubscribe();
  }

  private stopTimerSubs() {
    this.refreshTokenSubscription.unsubscribe();
    this.deviceDisconnectListenerSubscription.unsubscribe();
  }

  private setRefreshTokenTimer() {
    const maxTimeoutInMs = 15 * 60 * 1000; // 15 Minutes
    const refreshToken = timer(0, maxTimeoutInMs);

    this.refreshTokenSubscription = refreshToken.subscribe(() => {
      // If another timer cycle has fired, just remove what's waiting for reconnect.
      this.deviceDisconnectListenerSubscription.unsubscribe();
      this.twilioClientToken$
        .pipe(
          take(1),
          map((data: TwilioClientToken) => {
            let jwtToken;
            let expiresAt;
            let timeDiff;
            const dataToken = _.get(data, 'token');
            if (dataToken) {
              jwtToken = this.parseJwt(data.token);

              // Exp comes back in seconds. Need to convert it to ms.
              expiresAt = moment(_.get(jwtToken, 'exp') * 1000);
              timeDiff = expiresAt.diff(moment.now());
              // TODO (5/21/2021) Remove when done with QA.
              if (!environment.production) {
                this.loggerService.log('Token Refresh timer :: twilio token expiration in %s minutes ', timeDiff / 1000 / 60);
              }
            }
            if (!dataToken || timeDiff < maxTimeoutInMs) {
              // Load new twilio client token action if device is not currently in use.
              if (this.voipDeviceService.isDeviceInUse()) {
                this.setupWaitForDisconnect();
              } else if (!data.loading) {
                // TODO (5/21/2021) Remove when done with QA.
                if (!environment.production) {
                  this.loggerService.log('Timer :: Sending refresh for token');
                }
                this.store.dispatch(LoadTwilioClientTokenAction());
              } else if (!environment.production) {
                this.loggerService.log('Timer :: token is already loading, not launching load twilio client token');
              }
            }
          }),
        )
        .subscribe();
    });
  }

  private setupWaitForDisconnect() {
    // Wait until disconnect, then check again.
    this.deviceDisconnectListenerSubscription = this.voipDeviceService
      .getAllCallEventsEndedNotifier()
      .pipe(mergeMap(() => this.store.select(getTwilioClientToken).pipe(take(1))))
      .subscribe((twilioClientToken: TwilioClientToken) => {
        if (!this.voipDeviceService.isDeviceInUse()) {
          if (!twilioClientToken.loading) {
            // TODO (5/21/2021) Remove when done with QA.
            if (!environment.production) {
              this.loggerService.log('Disconnect listener :: sending action to load twilio client');
            }
            this.store.dispatch(LoadTwilioClientTokenAction());
          } else if (!environment.production) {
            // TODO (5/21/2021) Remove when done with QA.
            this.loggerService.log('Disconnect listener :: twilio client token already loading');
          }
          this.deviceDisconnectListenerSubscription.unsubscribe();
        } else if (!environment.production) {
          // TODO (5/21/2021) Remove when done with QA.
          this.loggerService.log('Disconnect listener :: device is in use');
        }
      });
  }

  private setupResetTokenNotiListener() {
    // Unsubscribe to make sure we don't set up multiple.
    this.tokenNotificationListenerSubscription.unsubscribe();

    this.tokenNotificationListenerSubscription = this.voipDeviceService
      .getResetTokenNotifier()
      .pipe(
        mergeMap(() => {
          return this.store.select(getTwilioClientToken).pipe(take(1));
        }),
      )
      .subscribe((twilioClientToken: TwilioClientToken) => {
        // End any of the timer subs. This will now be the main reset thread.
        this.stopTimerSubs();

        if (!this.voipDeviceService.isDeviceInUse()) {
          // Make sure the token isn't already loading.
          if (!twilioClientToken.loading) {
            // TODO (5/21/2021) Remove when done with QA.
            if (!environment.production) {
              this.loggerService.log('Reset Token Listener :: sending action to load twilio client');
            }
            this.store.dispatch(LoadTwilioClientTokenAction());
          } else if (!environment.production) {
            // TODO (5/21/2021) Remove when done with QA.
            this.loggerService.log('Reset Token Listener :: twilio client token already loading');
          }
        } else {
          // TODO (5/21/2021) Remove when done with QA.
          if (!environment.production) {
            this.loggerService.log('Reset Token Listener :: Setting up disconnect listener :: ');
          }
          this.setupWaitForDisconnect();
        }
      });
  }

  private parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }
}
