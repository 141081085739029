import { Injectable } from '@angular/core';
import { Message } from '@app/common/model/message/message.model';
import { MultisortOption } from '@app/core/data-model/multisort-option.model';
import { MessageType } from '@zi-common/model/message/message-type';
import { NotyService } from '@zi-common/service/noty/noty.service';
import { UserConfigurationKey } from '@zi-core/enums/user-configuration.enum';
import { UserConfiguration } from '@zi-core/http-model/response/user-configuration.response.model';
import { UserConfigurationService } from '@zi-core/service/user-configuration.service';
import { EngageActionTypeV2 } from '@zi-pages/engage-mode-v2/engage-mode-v2.config';
import { EngageModeAutoDialEntity } from '@zi-pages/engage-mode-v2/model/engage-mode-autodial-entity.model';
import { EngageModeCallLogEntity } from '@zi-pages/engage-mode-v2/model/engage-mode-call-log-entity.model';
import { EngageModeEntity } from '@zi-pages/engage-mode-v2/model/engage-mode-entity.model';
import * as _ from 'lodash';
import { empty, Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EngageModeStorageV2Service {
  engageModeEntitiesKey = 'engageModeEntities';
  engageModeCurrentEntityIndexKey = 'engageModeCurrEntityIndex';
  engageModeActionTypeKey = 'engageModeActionType';
  engageModeKey = 'engageMode';
  engageModeAutoDialConfigKey = 'engageModeAutoDialConfig';
  engageModeAutoDialTimerConfigKey = 'engageModeAutoDialTimer';
  engageModeCallLogEntitiesKey = 'engageModeCallLogEntities';
  engageModeExitWithoutWarningKey = 'engageModeExitWithoutWarning';
  engageModeDisplayContactDataKey = 'engageModeDisplayContactData';
  engageModeMultiSortKey = 'engageModeMultiSort';

  constructor(private userConfigurationService: UserConfigurationService, private notyService: NotyService) {}

  storeEngageModeEntitiesInSessionStorage(entities: Array<EngageModeEntity>) {
    sessionStorage.setItem(this.engageModeEntitiesKey, JSON.stringify(entities));
  }

  loadEngageModeEntitiesFromSessionStorage(): Array<EngageModeEntity> {
    return [...(JSON.parse(sessionStorage.getItem(this.engageModeEntitiesKey)) || [])];
  }

  storeEngageModeMultiSort(multisort: MultisortOption[]) {
    sessionStorage.setItem(this.engageModeMultiSortKey, JSON.stringify(multisort));
  }

  loadEngageModeMultiSort(): MultisortOption[] {
    return [...JSON.parse(sessionStorage.getItem(this.engageModeMultiSortKey))];
  }

  storeEngageModeCurrIndexInSessionStorage(currentEntityIndex: number) {
    sessionStorage.setItem(this.engageModeCurrentEntityIndexKey, currentEntityIndex.toString());
  }

  loadEngageModeCurrIndexFromSessionStorage(): number {
    return Number(sessionStorage.getItem(this.engageModeCurrentEntityIndexKey));
  }

  storeEngageModeActionTypeInSessionStorage(actionType: EngageActionTypeV2) {
    sessionStorage.setItem(this.engageModeActionTypeKey, actionType);
  }

  loadEngageModeActionTypeFromSessionStorage(): EngageActionTypeV2 {
    return sessionStorage.getItem(this.engageModeActionTypeKey) as EngageActionTypeV2;
  }

  storeIsEngageModeInSessionStorage(isEngageMode: boolean) {
    sessionStorage.setItem(this.engageModeKey, String(isEngageMode));
  }

  loadIsEngageModeFromSessionStorage(): string {
    return sessionStorage.getItem(this.engageModeKey);
  }

  storeEngageModeAutoDialConfigInSessionStorage(autoDialEntity: EngageModeAutoDialEntity) {
    sessionStorage.setItem(this.engageModeAutoDialConfigKey, JSON.stringify(autoDialEntity));
  }

  loadEngageModeAutoDialConfigFromSessionStorage(): EngageModeAutoDialEntity {
    return JSON.parse(sessionStorage.getItem(this.engageModeAutoDialConfigKey));
  }

  storeEngageModeAutoDialTimerConfigInSessionStorage(autoDialTimer: number) {
    sessionStorage.setItem(this.engageModeAutoDialTimerConfigKey, autoDialTimer.toString());
  }

  storeEngageModeCallLogEntitiesInSessionStorage(entities: Array<EngageModeCallLogEntity>) {
    sessionStorage.setItem(this.engageModeCallLogEntitiesKey, JSON.stringify(entities));
  }

  loadEngageModeCallLogEntitiesFromSessionStorage(): Array<EngageModeCallLogEntity> {
    return [...JSON.parse(sessionStorage.getItem(this.engageModeCallLogEntitiesKey))];
  }

  loadEngageModeAutoDialTimerConfigFromSessionStorage(): number {
    return Number(sessionStorage.getItem(this.engageModeAutoDialTimerConfigKey));
  }

  storeEngageModeExitWithoutWarningInSessionStorage(exitWithoutWarning: boolean) {
    sessionStorage.setItem(this.engageModeExitWithoutWarningKey, exitWithoutWarning.toString());
  }

  loadEngageModeExitWithoutWarningFromSessionStorage(): string {
    return sessionStorage.getItem(this.engageModeExitWithoutWarningKey);
  }

  storeEngageModeDisplayContactDataDisplayInSessionStorage(displayContactData: boolean) {
    sessionStorage.setItem(this.engageModeDisplayContactDataKey, String(displayContactData));
  }

  loadEngageModeDisplayContactDataFromSessionStorage(): string {
    return sessionStorage.getItem(this.engageModeDisplayContactDataKey);
  }

  clearEngageModeDataInSessionStorage() {
    sessionStorage.removeItem(this.engageModeEntitiesKey);
    sessionStorage.removeItem(this.engageModeCurrentEntityIndexKey);
    sessionStorage.removeItem(this.engageModeActionTypeKey);
    sessionStorage.removeItem(this.engageModeKey);
    sessionStorage.removeItem(this.engageModeExitWithoutWarningKey);
    sessionStorage.removeItem(this.engageModeDisplayContactDataKey);
  }

  clearEngageModeDataAutoDialInSessionStorage() {
    sessionStorage.removeItem(this.engageModeAutoDialConfigKey);
    sessionStorage.removeItem(this.engageModeAutoDialTimerConfigKey);
  }

  clearEngageModeCallLogDataInSessionStorage() {
    sessionStorage.removeItem(this.engageModeCallLogEntitiesKey);
  }

  storeEngageModeExitWarningConfig(displayFlag: boolean): Observable<UserConfiguration> {
    // the user may select do not ask again when displaying the engage mode exit dialog & we need to store this in the backend user config
    return this.userConfigurationService.upsertUserConfiguration(UserConfigurationKey.DISPLAY_ENGAGE_MODE_WARNING, { hideWarning: displayFlag }).pipe(
      map((res: UserConfiguration) => res),
      catchError(() => {
        this.notyService.postMessage(new Message(MessageType.ERROR, 'Error saving engage mode configuration. Please try again later.'));
        return empty();
      }),
    );
  }

  loadEngageModeExitWarningConfig(): Observable<boolean> {
    // retrieves whether to display warning when exiting engage mode
    return this.userConfigurationService.getUserConfigByKey(UserConfigurationKey.DISPLAY_ENGAGE_MODE_WARNING).pipe(
      take(1),
      map((engageModeConfig) => {
        const displayFlag: boolean = _.get(engageModeConfig, 'value.hideWarning', false);
        return displayFlag;
      }),
      catchError(() => of(false)),
    );
  }
}
