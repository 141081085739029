import { Component, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { AccountCompanyPlaceholderDomain } from '@app/pages/company-accounts/company-account.config';
import { environment } from '@env/environment';
import { UrlService } from '@zi-core/service/url.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-company-account-image',
  templateUrl: './company-account-image.component.html',
  styleUrls: ['./company-account-image.component.scss'],
})
export class CompanyAccountImageComponent implements OnChanges {
  @Input() companyAccount;
  @Input() tooltipText = '';
  @Input() defaultImage = AccountCompanyPlaceholderDomain;

  public companyImageSrc: string;

  constructor(private urlService: UrlService) {}

  ngOnChanges(changes: SimpleChanges) {
    const compAccount: SimpleChange = _.get(changes, 'companyAccount');
    if (compAccount && compAccount.currentValue !== compAccount.previousValue) {
      this.getAccountPhotoByZoomCompanyId();
    }
  }

  getAccountPhotoByZoomCompanyId() {
    // To construct the image url from zoomCompanyId, both zoomCompanyId and companyWebsite are needed
    // In ZI, company website is used for image storing, example https://res.cloudinary.com/zoominfo-com/image/upload/aws.amazon.com
    if (!_.get(this.companyAccount, 'zoomCompanyId') || !_.get(this.companyAccount, 'companyWebsite')) {
      this.updateCompanyImageToDefault();
    } else {
      this.updateCompanyImageSrc();
    }
  }

  private updateCompanyImageSrc() {
    try {
      // get domain from company website for retrieving company image
      const hostName = this.urlService.getUrlDomain(_.get(this.companyAccount, 'companyWebsite', ''));
      this.companyImageSrc = `${environment.companyPictureLink}/${hostName}`;
    } catch (err) {
      this.updateCompanyImageToDefault();
    }
  }

  updateCompanyImageToDefault() {
    this.companyImageSrc = this.defaultImage;
  }
}
