import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  CreateProfileAction,
  CreateProfileFailureAction,
  CreateProfileSuccessAction,
  LoadProfilesAction,
  LoadProfilesSuccessAction,
  LoadProfilesFailureAction,
  EditProfileAction,
  EditProfileSuccessAction,
  EditProfileSuccessFailureAction,
  DeleteProfileAction,
  DeleteProfileActionSucessAction,
  moveUserAction,
  moveUserActionSucessAction,
  AssignUsersToProfileAction,
  AssignUsersToProfileSuccessAction,
  AssignUsersToProfileFailureAction,
  MoveUsersAction,
  MoveUsersActionSuccessAction,
} from '@app/pages/admin/ngrx/action/profiles.action';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Profile } from '@zi-core/data-model/role.model';
import { ProfilesService } from '@app/pages/admin/service/profiles.service';
import { Message } from '@zi-common/model/message/message.model';
import { MessageType } from '@zi-common/model/message/message-type';
import { of } from 'rxjs';
import { NotyService } from '@zi-common/service/noty/noty.service';
import { get } from 'lodash';
import { OrgMemberService } from '@app/common/service/org-member/org-member.service';

@Injectable()
export class ProfilesEffect {
  constructor(
    private action$: Actions,
    private notyService: NotyService,
    private profilesService: ProfilesService,
    private orgMemberService: OrgMemberService,
  ) {}

  // Load all roles
  loadAllProfiles$ = createEffect(() =>
    this.action$.pipe(
      ofType(LoadProfilesAction),
      mergeMap((action) => {
        return this.profilesService.getProfileList(action.orgId).pipe(
          map((profiles: Array<Profile>) => {
            return LoadProfilesSuccessAction({ profiles });
          }),
          catchError((error: any) => {
            return of(LoadProfilesFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  createProfile$ = createEffect(() =>
    this.action$.pipe(
      ofType(CreateProfileAction),
      mergeMap((action) => {
        return this.profilesService.addProfile(action.payload, action.orgId).pipe(
          map((profile) => {
            const message = new Message(MessageType.SUCCESS, `${profile?.name} Profile was created successfully`);
            this.notyService.postMessage(message);
            return CreateProfileSuccessAction({ payload: profile });
          }),
          catchError((error: any) => {
            return of(CreateProfileFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  assignUsersToProfile$ = createEffect(() =>
    this.action$.pipe(
      ofType(AssignUsersToProfileAction),
      mergeMap((action) => {
        return this.profilesService
          .assignUsersToProfile({
            userIds: action?.userIds,
            roleId: action?.profileId,
            orgId: action?.orgId,
          })
          .pipe(
            map((profile) => {
              const message = new Message(MessageType.SUCCESS, `Assigned ${action?.userIds?.length || 0} user(s) added to profile`);
              this.notyService.postMessage(message);
              action?.userIds?.forEach((userId) => {
                this.orgMemberService.updateMemberRole({
                  userId,
                  roleId: action?.profileId,
                });
              });

              return AssignUsersToProfileSuccessAction({ payload: action?.users, profileId: action?.profileId });
            }),
            catchError((error: any) => {
              return of(AssignUsersToProfileFailureAction({ error }));
            }),
          );
      }),
    ),
  );

  editProfile$ = createEffect(() =>
    this.action$.pipe(
      ofType(EditProfileAction),
      mergeMap((action) => {
        return this.profilesService.editProfile(action?.payload).pipe(
          map((profile) => {
            if (!get(action, 'payload.editName')) {
              const message = new Message(MessageType.SUCCESS, `Your changes to "${get(profile, 'name')}" permissions were saved successfully`);
              this.notyService.postMessage(message);
            }
            return EditProfileSuccessAction({ payload: profile });
          }),
          catchError((error: any) => {
            return of(EditProfileSuccessFailureAction({ error }));
          }),
        );
      }),
    ),
  );

  deleteProfile$ = createEffect(() =>
    this.action$.pipe(
      ofType(DeleteProfileAction),
      mergeMap((action) => {
        return this.profilesService.deleteProfile(action?.profile?.id).pipe(
          map((profile) => {
            const message = new Message(MessageType.SUCCESS, `${action?.profile?.name} has been successfully deleted`);
            this.notyService.postMessage(message);
            return DeleteProfileActionSucessAction({ newProfile: action.profile });
          }),
        );
      }),
    ),
  );

  moveUser$ = createEffect(() =>
    this.action$.pipe(
      ofType(moveUserAction),
      mergeMap((action) => {
        return this.profilesService.assignUserToProfile(action?.userId, action?.newRole).pipe(
          map((profile) => {
            this.orgMemberService.updateMemberRole({
              roleId: action?.newRole,
              userId: action?.userId,
            });
            this.notyService.postMessage(new Message(MessageType.SUCCESS, `User Profile changed successfully to "${action?.newProfile}"`));
            return moveUserActionSucessAction({ oldRole: action.oldProfile, newRole: action.newRole });
          }),
        );
      }),
    ),
  );

  moveUsers$ = createEffect(() =>
    this.action$.pipe(
      ofType(MoveUsersAction),
      mergeMap((action) => {
        return this.profilesService.assignMultipleUsersToProfile(action?.orgId, action?.userIds, action?.newProfileId).pipe(
          map((profile) => {
            action?.userIds?.forEach((userId) => {
              this.orgMemberService.updateMemberRole({
                userId,
                roleId: action?.newProfileId,
              });
            });
            this.notyService.postMessage(
              new Message(
                MessageType.SUCCESS,
                `${action?.userIds?.length > 1 ? `${action?.userIds?.length} ` : ''}user${
                  action?.userIds?.length > 1 ? '(s)' : ''
                } Profile changed successfully to "${action?.newProfileName}"`,
              ),
            );
            return MoveUsersActionSuccessAction({ oldProfileName: action.oldProfileName, newProfileId: action.newProfileId, count: action?.userIds?.length });
          }),
        );
      }),
    ),
  );
}
