export enum VoicemailDropRecordModeEnum {
  ADD_NEW_DROP,
  ENTER_NAME_START_RECORDING,
  INITIALIZING,
  RECORDING,
  ADD_NEW_DROP_DISABLED,
  ENTER_NAME_START_RECORDING_DISABLED,
}

export const VOICEMAIL_DROP_ACTIVE_VALUES = [VoicemailDropRecordModeEnum.INITIALIZING, VoicemailDropRecordModeEnum.RECORDING];

export enum VoicemailGreetRecordModeEnum {
  START_RECORDING,
  INITIALIZING,
  RECORDING,
  DISABLED,
}

export const VOICEMAIL_GREET_ACTIVE_VALUES = [VoicemailGreetRecordModeEnum.INITIALIZING, VoicemailGreetRecordModeEnum.RECORDING];

export interface VoicemailDropRecordState {
  mode: VoicemailDropRecordModeEnum;
  duration: string;
  errorMsg: string;
  newTitle: string;
  disabled_reason: string;
}

export interface VoicemailGreetRecordState {
  mode: VoicemailGreetRecordModeEnum;
  duration: string;
  disabled_reason: string;
}

export const initialVoicemailDropRecordState: VoicemailDropRecordState = {
  mode: VoicemailDropRecordModeEnum.ADD_NEW_DROP,
  duration: '',
  errorMsg: '',
  newTitle: '',
  disabled_reason: '',
};

export const initialVoicemailGreetRecordState: VoicemailGreetRecordState = {
  mode: VoicemailGreetRecordModeEnum.START_RECORDING,
  duration: '',
  disabled_reason: '',
};
