import { FilterQueryNode } from '@zi-core/data-model/filter-node.model';
import { Scope } from '@zi-core/enums/engage-filter.enum';
import * as _ from 'lodash';

export enum ContentSortOrder {
  ASC,
  DESC,
}

export enum ContentSortField {
  SENT_COUNT = 'stats.sentCount',
  LINK_COUNT = 'stats.externalLinkClickedRate',
  OPEN_RATE = 'stats.openedRate',
  REPLY_RATE = 'stats.replyRate',
  UNSUBSCRIBED_RATE = 'stats.unsubscribedRate',
  TAGS = 'template.tags.length',
  SALESFLOW = 'template.salesWorkFlowTemplates.length',
  UPDATE_DATE = 'template.modifiedAsTimestamp',
  CREATE_DATE = 'template.createdAsTimestamp',
}

export enum ContentDateFilter {
  UPDATE_DATE = 'modifiedAsTimestamp',
  CREATE_DATE = 'createdAsTimestamp',
}

export class ContentFilterState {
  query: {
    [filterName: string]: FilterQueryNode[];
  };
  sort: {
    field: ContentSortField;
    order: ContentSortOrder;
  };
  // in memory filter
  memFilter: {
    [filterName: string]: FilterQueryNode[];
  };
  search: string;
  page: number;
  scope?: Scope;
  loadFromUrl?: boolean;

  // get the total number of filter value in the query
  static getFilterCount(filterState: ContentFilterState): number {
    return _.map(filterState.query, (filters: FilterQueryNode[]) => filters.length).reduce((total, current) => total + current, 0);
  }

  static getMemFilterChips(filterState: ContentFilterState): FilterQueryNode[] {
    let output = [];
    const memFilter = filterState.memFilter;
    if (memFilter.date_range && memFilter.date_field) {
      const fqn = memFilter.date_range[0];
      fqn.displayName = fqn.displayName; // + ' '+ memFilter['date_field'][0];
      output.push(fqn);
    }
    output = output.concat(_.flatMap(_.filter(memFilter, (value, filter) => filter !== 'date_field' && filter !== 'date_range')));
    return output;
  }
}

const createDateFilterNode = {
  type: 1,
  entity: 'MessageTemplate',
  category: 'ContactFields',
  key: 'date_field',
  value: 'createdAsTimestamp',
  displayName: 'Created',
};

// initial state of filter
export const initialContentFilterState: ContentFilterState = {
  query: {},
  search: '',
  sort: {
    field: ContentSortField.CREATE_DATE,
    order: ContentSortOrder.DESC,
  },
  memFilter: {
    date_field: [createDateFilterNode],
    /* dateRange: {
      min: 0,
      // today + 1 day
      max: new Date().getTime() +  3600 * 1000 * 24
    }*/
  },
  page: 1,
  scope: Scope.Organization,
  loadFromUrl: false,
};
