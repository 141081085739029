import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { extIsLoggedInSentSelector, extSizeSelector } from '@app/extension/ngrx/state/extension.state';
import { ExtensionMessages } from '@app/extension/constants/extension-messages.enum';
import {
  ExtGoToNinetyPctAction,
  ExtGoToTwentyPctAction,
  ExtLoadContactsAction,
  ExtLoggedInMsgSentAction,
  ExtLoggedOutMsgSentAction,
  ExtMinimizeAction,
  ExtReceiveNewParsedContactIdsInfoAction,
  ExtRemoveContactsAction,
  ExtStartParsingPageAction,
  ExtUpdateContactsAction,
} from '@app/extension/ngrx/action/extension.action';
import { take, tap, withLatestFrom } from 'rxjs/operators';
import { ApplicationState } from '@app/reducers';
import { UrlService } from '@zi-core/service/url.service';
import { LoadConfigurationCRM, LoadConnectedServices, LogoutAction } from '@app/core/ngrx/action/auth.action';
import { getProfile, getValidAuthToken } from '@zi-core/ngrx/state/auth.state';
import { GmailContactLoaded, GmailPreviewLoaded, GmailSalesflowContactsLoaded } from '@app/gmail-plugin/ngrx/action/gmail-plugin.action';
import { GmailIframeActions } from '@app/extension/constants/gmail-iframe-action';
import { Router } from '@angular/router';
import { ExtensionSource } from '@app/extension/constants/extension-source.enum';

@Injectable({
  providedIn: 'root',
})
export class ExtensionIframeMessagingService {
  // Getter of parent of iframe - used for targetOrigin on postMessage to parent
  get targetOrigin(): string {
    // '' is invalid target origin, hence '*'
    return document.location.ancestorOrigins[0] || '*';
  }

  constructor(private _appStore: Store<ApplicationState>, private router: Router, private urlService: UrlService) {
    window.addEventListener('message', this.receiveMessage.bind(this), false);
  }

  private receiveMessage(evt) {
    if (_.get(evt, 'data.target.app') === 'ENGAGE') {
      switch (evt.data.name) {
        case ExtensionMessages.TO_NINETY_PCT:
          this._appStore.dispatch(ExtGoToNinetyPctAction({ notExpandIFrame: true }));
          break;
        case ExtensionMessages.TO_TWENTY_PCT:
          this._appStore
            .select(extSizeSelector)
            .pipe(
              take(1),
              tap((size) => {
                this._appStore.dispatch(ExtGoToTwentyPctAction({ sizeComingFrom: size }));
              }),
            )
            .subscribe();
          break;
        case ExtensionMessages.MINIMIZE:
          this._appStore.dispatch(ExtMinimizeAction());
          break;
        case ExtensionMessages.START_PARSING_PAGE:
          this._appStore.dispatch(ExtStartParsingPageAction());
          break;
        case ExtensionMessages.SEND_PARSED_INFO:
          const decodedInfo = this.urlService.decodeFromBase64(evt.data.parsedInfo);
          const source = _.get(decodedInfo, 'source');
          this._appStore.dispatch(ExtReceiveNewParsedContactIdsInfoAction({ encodedParsedContactIdsInfo: evt.data.parsedInfo, source }));
          this._appStore.dispatch(LoadConfigurationCRM());
          this._appStore.dispatch(LoadConnectedServices());
          break;
        case ExtensionMessages.LOGOUT:
          this._appStore.dispatch(LogoutAction({}));
          break;
        case ExtensionMessages.LOAD_CONTACT:
          this._appStore.dispatch(ExtLoadContactsAction({ contact: evt.data.contacts, source: ExtensionSource.GMAIL }));
          break;
        case ExtensionMessages.UPDATE_CONTACT:
          this._appStore.dispatch(ExtUpdateContactsAction({ contact: evt.data.contacts, source: ExtensionSource.GMAIL }));
          break;
        case ExtensionMessages.REMOVE_CONTACT:
          this._appStore.dispatch(ExtRemoveContactsAction({ contacts: evt.data.contacts, source: ExtensionSource.GMAIL }));
          break;
      }
    } else if (_.get(evt, 'data.parentData.target.app') === 'ENGAGE_GMAIL_PLUGIN') {
      switch (evt.data.parentData.name) {
        case GmailIframeActions.Reset:
          this.router.navigate(['/gmailplugin']);
          break;
        case GmailIframeActions.TurnOff:
          this.router.navigate(['/gmailplugin/turnOff']);
          break;
        case GmailIframeActions.Preview:
          const previewData = _.get(evt, 'data.parentData.previewData');
          this._appStore.dispatch(GmailPreviewLoaded({ previewData }));
          this.router.navigate(['/gmailplugin/preview']);
          break;
        case GmailIframeActions.SendContact:
          this._appStore.dispatch(GmailContactLoaded({ contact: _.get(evt, 'data.parentData.content') }));
          this.router.navigate(['/gmailplugin/contactCard'], {
            queryParams: { mode: _.get(evt, 'data.parentData.mode'), recipientType: _.get(evt, 'data.parentData.recipientType') },
          });
          break;
        case GmailIframeActions.TemplateRoute:
          this.router.navigate(['/gmailplugin/templateModal'], { queryParams: { id: _.get(evt, 'data.parentData.id') } });
          break;
        case GmailIframeActions.MergeVariablesRoute:
          this.router.navigate(['/gmailplugin/variableModal'], { queryParams: { id: _.get(evt, 'data.parentData.id') } });
          break;
        case GmailIframeActions.SendLaterRoute:
          const isGroup = _.get(evt, 'data.parentData.isGroup');
          this.router.navigate(['/gmailplugin/emailScheduler'], { queryParams: { isGroup: isGroup ? 'true' : 'false', id: _.get(evt, 'data.parentData.id') } });
          break;
        case GmailIframeActions.Task:
          this.router.navigate(['/gmailplugin/followTask'], {
            queryParams: {
              id: _.get(evt, 'data.parentData.id'),
              ..._.get(evt, 'data.parentData.task'),
            },
          });
          break;
        case GmailIframeActions.Salesflow:
          this._appStore.dispatch(GmailSalesflowContactsLoaded({ contacts: _.get(evt, 'data.parentData.contacts') }));
          this.router.navigate(['/gmailplugin/followSalesflow'], {
            queryParams: {
              id: _.get(evt, 'data.parentData.id'),
              ..._.get(evt, 'data.parentData.salesflow'),
            },
          });
          break;
        case GmailIframeActions.ContactCreate:
          this.router.navigate(['/gmailplugin/contactCreate'], { queryParams: { ..._.get(evt, 'data.parentData.query') } });
          break;
        case GmailIframeActions.Dialog:
          this.router.navigate(['/gmailplugin/dialog'], { queryParams: { ..._.get(evt, 'data.parentData.query') } });
          break;
      }
    }
  }

  public sendLoggedInMessage() {
    const extLoggedIn$ = this._appStore.select(extIsLoggedInSentSelector);
    const authToken$ = this._appStore.select(getValidAuthToken);
    const profile$ = this._appStore.select(getProfile);

    extLoggedIn$.pipe(take(1), withLatestFrom(authToken$, profile$)).subscribe(([isLoggedInSent, authToken, profile]) => {
      if (!isLoggedInSent && authToken !== null) {
        this.sendMessageWithToken(ExtensionMessages.IS_LOGGED_IN, authToken, profile);
        this._appStore.dispatch(ExtLoggedInMsgSentAction());
      }
    });
  }

  public sendLoggedOutMessage() {
    this._appStore.dispatch(ExtLoggedOutMsgSentAction());
    this.sendMessage(ExtensionMessages.LOGGED_OUT);
  }

  public sendMessage(name) {
    window.parent.postMessage({ name, target: 'ENGAGE_EXTENSION' }, this.targetOrigin);
  }
  public sendMessageWithToken(name: ExtensionMessages, authToken, profile) {
    window.parent.postMessage({ name, authToken, profile, target: 'ENGAGE_EXTENSION' }, this.targetOrigin);
  }
}
