import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DoziIntegrationService } from '@app/core/service/dozi-integration.service';
import { TrialStatus } from '@app/core/data-model/dozi-integration/enums/trial-status.model';
import { Store } from '@ngrx/store';
import { ApplicationState } from '@app/reducers';
import { getAuthState } from '@app/core/ngrx/state/auth.state';
import { take, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { environment } from '@env/environment';
import { ContactUsRequest } from '@app/core/data-model/dozi-integration/dozi-contact-us-request.model';
import { DoziFeature } from '@app/core/data-model/dozi-integration/enums/dozi-feature.model';
import { ContactUsType } from '@app/core/data-model/dozi-integration/enums/contact-us-type.model';
import { DoziContactUsDialogComponent } from '../dozi-contact-us-dialog/dozi-contact-us-dialog.component';

@Component({
  selector: 'app-dozi-account-dialog',
  templateUrl: './dozi-account-dialog.component.html',
  styleUrls: ['./dozi-account-dialog.component.scss'],
})
export class DoziAccountDialogComponent implements OnInit {
  readonly alreadyHaveAccessSrc = environment.icons_url + `/ebr2022_zoominfo_login.png`;
  readonly errorSrc = environment.icons_url + `/ebr2022_2x_email_and_calls.png`;
  readonly cantCreateError = `${environment.icons_url}/ebr2022_no_user.png`;
  readonly successProvisionedSrc = environment.icons_url + `/ebr2022_upcoming_emails.png`;
  readonly contactUsUrl = environment.zoominfo_app;
  readonly videoUrl = `https://play.vidyard.com/7be6hxuQVYDn13uiEekjpD.html?`;

  isLoading = false;
  trialStatus = TrialStatus;
  userTrialType: TrialStatus = null;
  email: string;
  fullName: string;

  constructor(
    private appStore: Store<ApplicationState>,
    private dialogRef: MatDialogRef<DoziAccountDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data,
    private doziService: DoziIntegrationService,
  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.appStore
      .select(getAuthState)
      .pipe(
        take(1),
        map(
          (res) => {
            this.email = _.get(res, 'profile.email');
            this.fullName = _.get(res, 'profile.name');
            this.isLoading = false;
          },
          (error) => {
            this.invokeErrorInTrial();
          },
        ),
      )
      .subscribe();
  }

  onCloseDialog(status?: TrialStatus) {
    switch (status) {
      default:
        // TODO: add other statuses
        this.dialogRef.close(status);
    }
  }

  onRegisterTrial() {
    this.isLoading = true;
    this.doziService.checkTrialStatus().subscribe(
      (res) => {
        if (_.has(res, 'status')) {
          this.userTrialType = res.status;
          this.updateDialogSizeFromResponse();
          this.isLoading = false;
        } else {
          this.invokeErrorInTrial();
        }
      },
      (error) => {
        this.isLoading = false;
        this.invokeErrorInTrial();
      },
    );
  }

  onContinueToLogin() {
    this.onCloseDialog(this.userTrialType);
  }

  onContactUs() {
    switch (this.userTrialType) {
      case TrialStatus.NOT_WHITELISTED:
        this.sendCTARequest(DoziFeature.contactUs, ContactUsType.UPGRADE_SUBSCRIPTION);
        break;
      case TrialStatus.TRIALED_IN_PAST:
        this.sendCTARequest(DoziFeature.contactUs, ContactUsType.RENEW_SUBSCRIPTION);
        break;
      case TrialStatus.TRIAL_PROVISION_ERROR:
      default:
        // TODO: Send CTA click event for now as a catch all
        this.sendCTARequest(DoziFeature.none, ContactUsType.NONE);
        break;
    }
  }

  sendCTARequest(doziFeature: DoziFeature, contactType: ContactUsType) {
    this.isLoading = true;

    const req: ContactUsRequest = {
      source: this.doziService.getContactUsSource(doziFeature),
      name: this.fullName,
      type: contactType,
    };

    this.doziService.sendContactUs(req).subscribe(
      (res) => {
        this.openContactUs();
        this.isLoading = false;
      },
      (error) => {
        this.openContactUs();
        this.isLoading = false;
      },
    );
  }

  private invokeErrorInTrial() {
    this.userTrialType = TrialStatus.TRIAL_PROVISION_ERROR;
    this.sendCTARequest(DoziFeature.none, ContactUsType.NONE);
    this.updateDialogSizeFromResponse();
    this.isLoading = false;
  }

  private updateDialogSizeFromResponse() {
    this.dialogRef.updateSize('434px', '381px');
  }

  openContactUs() {
    this.dialog
      .open(DoziContactUsDialogComponent, {
        width: '434px',
        height: '381px',
        data: {},
      })
      .afterClosed()
      .subscribe((x) => {
        this.onCloseDialog(this.userTrialType);
      });
  }
}
