import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

/**
 * Make sure to use (clickButton) and not (click)!!!
 */
@Component({
  selector: 'app-secondary-btn',
  templateUrl: './secondary-btn.component.html',
  styleUrls: ['./secondary-btn.component.scss'],
})
export class SecondaryBtnComponent implements OnInit {
  @Input() text = '';
  @Input() isRightButtonEnabled: boolean = false;
  @Input() disabled = false;
  @Input() iconSrc;
  @Input() buttonType: 'button' | 'submit' | 'reset' = 'button'; // Set a type of button

  /**
   * Always use this output, never (click)
   */
  @Output() clickButton = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onClick(event) {
    this.clickButton.emit(event);
  }
}
