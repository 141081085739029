import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PhoneTypesEnum } from '@app/caller/interface/phone-types-enum';
import { SubscriptionSetting } from '@app/core/http-model/request/subscription-setting-request.model';
import { Contact, ContactIds, ReplaceContactFields } from '@app/core/data-model/contact.model';
import { ContactPhoneStatus } from '@app/core/enums/contact-phone-status.enum';
import { environment } from '@env/environment';
import { HotProspect } from '@zi-common/model/hot-prospect/hot-prospect.model';
import { UtilitiesService } from '@zi-common/service/utilities-service/utilities-service';
import { FilterQueryNode } from '@zi-core/data-model/filter-node.model';
import { ContactSource } from '@zi-core/enums/contact-source.enum';
import { ContactDto, ContactsDtoResponse, ContactsResponse, ImportContactResponse } from '@zi-core/http-model/response/contacts.response.model';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  DateValues,
  excludeAllFiltersMap,
  HEADER_MAP_ARRAY,
  HEADER_MAP_VALUE,
  PHONE_CALL_RESULT_MAP,
  PHONE_CALL_SENTIMENT_MAP,
} from './contact-internal-data.config';
import { NotyService } from '@app/common/service/noty/noty.service';
import { Message } from '@app/common/model/message/message.model';
import { MessageType } from '@app/common/model/message/message-type';
import { AddContactsToSalesWorkflowResponse } from '@zi-core/http-model/response/sales-workflow.response';

/**
 *  Contact DataService for CRUD operation on entity
 */
@Injectable()
export class ContactInternalDataService {
  constructor(private httpClient: HttpClient, private utilitiesService: UtilitiesService, private notyService: NotyService) {}

  loadContacts(queryParams: string): Observable<Contact[]> {
    return this.httpClient.get<ContactsResponse>(`${environment.backend_url}/v1/contacts/recent?${queryParams}`).pipe(
      map((res) => {
        return res.contacts;
      }),
    );
  }

  loadContactsByIds(contactIds: Array<number>, pageSize: number = null, pageNumber: number = null, withCrmInfo = true): Observable<Array<ContactDto>> {
    return this.httpClient.post<ContactsDtoResponse>(`${environment.backend_url}/v1/contacts`, { contactIds, withCrmInfo, pageSize, pageNumber }).pipe(
      map((res: ContactsDtoResponse) => {
        return res.contacts;
      }),
    );
  }
  /**
   * Get contacts by ids with labels.
   * The server returns labels only when paging parameters are passed
   * @param contactIds - list of contact ids to return
   * @param pageSize - number of items for page
   * @param pageNumber - the number of the page to return
   * @returns list of Contacts
   */
  loadContactsWithLabelsByIds(contactIds: Array<number>, pageSize: number = null, pageNumber: number = null): Observable<Array<ContactDto>> {
    if (!pageSize || !pageNumber) {
      pageSize = contactIds.length;
      pageNumber = 1;
    }

    return this.loadContactsByIds(contactIds, pageSize, pageNumber);
  }

  updateContact(contact: Contact): Observable<Contact> {
    return this.httpClient.post<ContactsResponse>(`${environment.backend_url}/v1/contacts/${contact.id}`, contact).pipe(
      map((res: { contacts: Array<Contact> }) => {
        return res.contacts[0];
      }),
    );
  }

  updateContactStatus(status: string, contactId: number) {
    return this.httpClient.post<ContactsResponse>(`${environment.backend_url}/v1/contacts/${contactId}/status`, { status }).pipe(
      map((res: { contacts: Array<Contact> }) => {
        return res.contacts[0];
      }),
    );
  }

  /**
   * call to enpoint to update a specific phone status field on contacts table for specific contact
   * Based on contactId, phone type (home, hq, mobile etc...) and new status
   * @param phoneType - The type of phone field whose status should change
   * @param contactPhoneNewStatus - The new status
   * @param contactId - Contact id
   * @returns Observable<ContactsResponse> of response with contact updated
   */
  updateContactPhoneStatus(phoneType: PhoneTypesEnum, contactPhoneNewStatus: ContactPhoneStatus, contactId: number): Observable<ContactsResponse> {
    return this.httpClient.put<ContactsResponse>(`${environment.backend_url}/v1/contacts/${contactId}/phoneStatus`, {
      PhoneType: phoneType,
      ContactPhoneNewStatus: contactPhoneNewStatus,
    });
  }

  updateContactStatusBulk(status: string, contactIds: Array<number>) {
    return this.httpClient.post<ContactsResponse>(`${environment.backend_url}/v1/contacts/list/status`, { contactIds, status }).pipe(
      map((res: { contacts: Array<Contact> }) => {
        return res.contacts;
      }),
    );
  }

  updateContactOwnerBulk(newOwnerId: number, contactIds: Array<number>) {
    return this.httpClient.post<ContactsResponse>(`${environment.backend_url}/v1/contacts/list/owner`, { contactIds, newOwnerId }).pipe(
      map((res: { contacts: Array<Contact> }) => {
        return res.contacts;
      }),
    );
  }

  createOrUpdateContact(contact: Contact): Observable<Contact> {
    return this.httpClient
      .post<ImportContactResponse>(`${environment.backend_url}/v2/contacts/update`, { contacts: [contact], source: ContactSource.Other })
      .pipe(
        map((res) => {
          return res.result?.contacts[0];
        }),
      );
  }

  getContactById(contactId: number, skipCrmResolution = false): Observable<Contact> {
    return this.httpClient.get<ContactsResponse>(`${environment.backend_url}/v1/contacts/${contactId}?skipCrmResolution=${skipCrmResolution}`).pipe(
      map((res: { contacts: Array<Contact> }) => {
        if (res && res.contacts) {
          return res.contacts[0];
        }
      }),
    );
  }

  unsubscribeContact(contact: Contact): Observable<any> {
    return this.httpClient.post<any>(`${environment.backend_url}/v1/invitations/unsubscribe`, { contact }).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  bulkUnsubscribeContacts(contactIds: number[], subscriptionSetting: SubscriptionSetting): Observable<Contact[]> {
    return this.httpClient.post<Contact[]>(`${environment.backend_url}/v1/contacts/list/subscriptionSetting`, { contactIds, subscriptionSetting });
  }

  deleteContact(contactIds: Array<number>): Observable<any> {
    return this.httpClient.post<object>(`${environment.backend_url}/v1/contacts/delete`, { contactIds });
  }

  bulkTagContacts(resourceIds: Array<number>, labelId: string, resourceType: string) {
    return this.httpClient.post(`${environment.backend_url}/v1/labels/set`, { resourceIds, labelId, resourceType }).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  // load filter results
  loadFilteredResults(tags: string[]): Observable<Array<Contact>> {
    return this.httpClient
      .post(`${environment.backend_url}/v1/labels/query`, { labelIds: tags, resourceType: 'Contact' })
      .pipe(map((res: { contacts: Array<Contact> }) => res.contacts));
  }

  bulkAddContactsToWorkflow(
    contactIds: Array<number>,
    workflowTemplateId: number,
    sendAt = null,
    senderId = null,
    isOwnerAccordingToData?: boolean,
  ): Observable<AddContactsToSalesWorkflowResponse> {
    return this.httpClient
      .post(`${environment.backend_url}/v1/workflows/contacts`, {
        ContactIds: contactIds,
        WorkflowTemplateId: workflowTemplateId,
        StartAt: sendAt,
        SenderId: senderId,
        IsOwnerAccordingToData: isOwnerAccordingToData,
      })
      .pipe(
        map((res: AddContactsToSalesWorkflowResponse) => {
          return res;
        }),
      );
  }

  bulkCreateContacts(contacts: Array<Contact>, labelIds: Array<number>, source: ContactSource): Observable<Array<Contact>> {
    return this.httpClient.post<ImportContactResponse>(`${environment.backend_url}/v2/contacts/update`, { contacts, labelIds, source }).pipe(
      map((res) => {
        return res.result?.contacts;
      }),
    );
  }

  downloadFile(res: object[], fileName?: string, mapping?: string[], additionalHeaders?: string[]) {
    this.notyService.postMessage(new Message(MessageType.INFO, `CSV file download in progress`));

    const autoMapping: string[] = mapping ? mapping : HEADER_MAP_ARRAY;
    const header: string[] = Object.keys(autoMapping);
    const csv: string[] = this.convertToCsvFile(res, header, autoMapping);

    this.executeDownload(csv, header, fileName, additionalHeaders);
  }

  private executeDownload(csv: string[], header: string[], fileName?: string, additionalHeaders?: string[]) {
    const escapeHeaders = header.map((head) => {
      return this.escapeCsvValue(head);
    });
    csv.unshift(escapeHeaders.join(','));

    // Add additional headers if provided
    if (additionalHeaders) {
      csv.unshift(additionalHeaders.join(','));
    }

    const newlineSeparatedCsv = csv.join('\r\n');
    const fileToDownload = new Blob([newlineSeparatedCsv], { type: 'text/csv;charset=UTF-8' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(fileToDownload);
    link.download = fileName ? `${fileName}.csv` : `Contacts${csv.length - 1}.csv`;
    link.click();
  }

  private convertToCsvFile(res: object[], header: string[], mapping?: string[]): string[] {
    return res.map((row) =>
      header
        .map((fieldName: string) => {
          let translatedText: string;
          const fieldNameValue = mapping[fieldName];
          const currentValue = row[fieldNameValue];

          switch (fieldNameValue) {
            case mapping['Last Call Sentiment']:
              translatedText = PHONE_CALL_SENTIMENT_MAP[currentValue];
              break;

            case mapping['Last Call Result']:
              translatedText = PHONE_CALL_RESULT_MAP[currentValue];
              break;

            default:
              break;
          }

          if (translatedText) {
            return translatedText;
          }

          let displayValue: string = this.convertToDisplayValue(currentValue, fieldNameValue);
          displayValue = this.escapeCsvValue(displayValue);
          return JSON.stringify(displayValue);
        })
        .join(','),
    );
  }

  private convertToDisplayValue(value: any, fieldNameValue: HEADER_MAP_VALUE) {
    if (value === 0) {
      return '0';
    }

    if (!value) {
      return '';
    }

    let newValue = value;

    if (DateValues.has(fieldNameValue)) {
      const parsedDateValue: number = Date.parse(value);

      if (parsedDateValue) {
        const dateObject = new Date(parsedDateValue);
        newValue = this.convertDateToDisplayValue(dateObject);
      }
    }

    return newValue || '';
  }

  /**
   * Converts a data object to this format: YYYY-MM-DD HH:mm
   */
  private convertDateToDisplayValue(dateObject: Date) {
    return moment(dateObject).format('YYYY-MM-DD HH:mm');
  }

  /**
   * Escape the following characters when exporting to CSV: =, +, -, @
   * Identify those characters by the following rules:
   * The equal sign anywhere
   * Plus and minus signs at the beginning of the string
   * To escape it we should use double backslash "\\" prefix
   * @param value - string should to escape
   * @return string - an escaped string
   * @private
   */
  private escapeCsvValue(value: string): string {
    // Retain zeros
    if (value === '0') {
      return '0';
    }
    // Make sure a value was supplied.
    if (!value) {
      return '';
    }

    // Make sure the supplied value is of type string.
    if (typeof value !== 'string') {
      return value;
    }

    let escapedVal = value.replace(/=/g, '\\=');
    const regex = new RegExp(/^[@,+,-]/);
    escapedVal = escapedVal.replace(regex, `\\${escapedVal[0]}`);

    return escapedVal;
  }

  hotProspects(): Observable<HotProspect[]> {
    return this.httpClient.get(`${environment.backend_url}/v1/contacts/hot_prospects`).pipe(map((res: { contacts: HotProspect[] }) => res.contacts));
  }

  replaceContacts(zoomId: number, filterType?) {
    return this.httpClient.get(`${environment.engage_backend_url}/data/person/replace/${zoomId}${filterType ? '?req=' + filterType : ''}`).pipe(
      map((replaceContacts: Array<ReplaceContactFields>) => {
        return replaceContacts;
      }),
    );
  }

  importContacts(arrayOfContactIds: ContactIds) {
    return this.httpClient.post(`${environment.engage_backend_url}/data/person/export`, arrayOfContactIds).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  convertFilterQueryToArray(filterQuery: { [filterName: string]: FilterQueryNode[] }): FilterQueryNode[] {
    return _.map(filterQuery, (filter: FilterQueryNode[], filterKey: string) => {
      if (!filter) {
        return;
      }
      return filter.map((dv: FilterQueryNode) => ({
        keyPrefix: dv?.keyPrefix,
        key: dv.key,
        value: dv.displayName === 'All' && dv.value === 'All' && dv.isNegation === true ? this.getValueForExcludeAllFilter(dv.key) : dv.value,
        category: dv.category,
        categoryKey: dv.categoryKey,
        type: 1,
        entity: dv.entity,
        displayName: dv.displayName,
        isNegation: dv.isNegation,
      }));
    }).reduce((allFilters, filterArr) => allFilters.concat(filterArr), []);
  }

  adjustFilterDataForEngagementFilters(filtersAsArray: FilterQueryNode[]) {
    if (!filtersAsArray) {
      return null;
    }

    return this.utilitiesService
      .adjustDataForEngagementFilters(filtersAsArray)
      .filter(
        (criteria, index, self) =>
          index ===
          self.findIndex((t) => t.displayName === criteria.displayName && t.value === criteria.value && t.name === criteria.name && t.key === criteria.key),
      );
  }

  newlyUnsubscribedOrResubscribed(setting, newValue, oldValue, unsubscribed, resubscribed) {
    if (newValue && !oldValue) {
      unsubscribed.push(setting);
    } else if (!newValue && oldValue) {
      resubscribed.push(setting);
    }
  }

  getValueForExcludeAllFilter(filterKey) {
    return excludeAllFiltersMap[filterKey];
  }
}
