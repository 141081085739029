import { createAction, props } from '@ngrx/store';
import { Profile } from '@zi-core/data-model/role.model';
import { UserInProfilePermissions } from '../../pages/profiles-and-permissions/profiles-and-permissions.config';

export const LoadProfilesAction = createAction('[Profiles] profiles/load', props<{ orgId: number }>());

// load profiles success
export const LoadProfilesSuccessAction = createAction('[Profiles] profiles/load/success', props<{ profiles: Array<Profile> }>());

// load profiles failure
export const LoadProfilesFailureAction = createAction('[Profiles] profiles/load/failure', props<{ error: string }>());

export const AssignUsersToProfileAction = createAction(
  '[profiles] assign users to profiles',
  props<{ userIds: number[]; orgId: number; profileId: number; users: UserInProfilePermissions[] }>(),
);

export const AssignUsersToProfileSuccessAction = createAction(
  '[profiles] assign users to profiles/success',
  props<{ payload: UserInProfilePermissions[]; profileId: number }>(),
);

export const AssignUsersToProfileFailureAction = createAction('[profiles] assign users to profiles/failure', props<{ error: string }>());

export const CreateProfileAction = createAction('[profiles] profiles/add', props<{ payload: Profile; orgId: number }>());

export const CreateProfileSuccessAction = createAction('[profiles] profiles/add/success', props<{ payload: Profile }>());

export const CreateProfileFailureAction = createAction('[profiles] profiles/add/failure', props<{ error: any }>());

export const EditProfileAction = createAction('[profiles] profiles/edit', props<{ payload: Partial<Profile> }>());

export const EditProfileSuccessAction = createAction('[profiles] profiles/edit/success', props<{ payload: Partial<Profile> }>());

export const SelectProfileAction = createAction('[profiles] select/profile', props<{ payload: Profile }>());

export const EditProfileSuccessFailureAction = createAction('[profiles] profiles/edit/failure', props<{ error: any }>());
export const DeleteProfileAction = createAction('[profiles] profile/delete', props<{ profile: Profile }>());

export const DeleteProfileActionSucessAction = createAction('[profiles] profile/delete/sucess', props<{ newProfile: Profile }>());
export const DeleteProfileActionFailure = createAction('[profiles] profiles/delete/failure');

export const moveUserAction = createAction('[profiles] profile/move', props<{ userId: number; oldProfile: string; newRole: number; newProfile: string }>());

export const moveUserActionSucessAction = createAction('[profiles] profile/move/sucess', props<{ oldRole: string; newRole: number }>());

export const moveUserActionActionFailure = createAction('[profiles] profiles/move/failure', props<{ error: any }>());

export const MoveUsersAction = createAction(
  '[profiles] profile/multiple/move',
  props<{ userIds: number[]; oldProfileName: string; newProfileId: number; newProfileName: string; orgId: number }>(),
);

export const MoveUsersActionSuccessAction = createAction(
  '[profiles] profile/multiple/move/sucess',
  props<{ oldProfileName: string; newProfileId: number; count: number }>(),
);

export const MoveUsersActionFailureAction = createAction('[profiles] profiles/multiple/move/failure', props<{ error: any }>());
