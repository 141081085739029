import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class EngageSupportService {
  private openChatWidget$ = new Subject<boolean>();
  private isShowContactForm$ = new Subject<boolean>();
  private isDefaultSpecificFeature$ = new Subject<boolean>();

  //* SETTERS
  public setOpenChatWidget(value: boolean) {
    this.openChatWidget$.next(value);
  }

  public setIsShowContactForm(value: boolean) {
    this.isShowContactForm$.next(value);
  }

  public setIsDefaultSpecificFeature(value: boolean) {
    this.isDefaultSpecificFeature$.next(value);
  }

  //* GETTERS
  public getOpenChatWidget$(): Observable<boolean> {
    return this.openChatWidget$.asObservable().pipe(
      map((value) => {
        return value;
      }),
    );
  }

  public getIsShowContactForm$(): Observable<boolean> {
    return this.isShowContactForm$.asObservable().pipe(
      map((value) => {
        return value;
      }),
    );
  }

  public getIsDefaultSpecificFeature$(): Observable<boolean> {
    return this.isDefaultSpecificFeature$.asObservable().pipe(
      map((value) => {
        return value;
      }),
    );
  }
}
