import { EventEmitter, Inject, Injectable, OnDestroy } from '@angular/core';
import { MessageType } from '@app/common/model/message/message-type';
import { Message } from '@app/common/model/message/message.model';
import { NotyService } from '@app/common/service/noty/noty.service';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Connection } from 'twilio-client';
import { VoipDeviceService } from './voip-device.service';
import {
  initialVoicemailDropRecordState,
  initialVoicemailGreetRecordState,
  VOICEMAIL_DROP_ACTIVE_VALUES,
  VOICEMAIL_GREET_ACTIVE_VALUES,
  VoicemailDropRecordModeEnum,
  VoicemailDropRecordState,
  VoicemailGreetRecordModeEnum,
  VoicemailGreetRecordState,
} from '@app/pages/account/components/unified-voicemail/dialer-voicemail-v2.model';
import { distinctUntilChanged } from 'rxjs/operators';
import { DialerUtilService } from '@app/caller/service/dialer-util.service';
import { DialerSelection, VoicemailType } from '@zi-core/enums/dialer.enum';
import {
  DISABLE_VOICEMAIL_DROP_MSG_ACTIVE_GREET,
  DISABLE_VOICEMAIL_GREET_MSG_ACTIVE_DROP,
  VOICEMAIL_PRE_RECORD_TIMEOUT,
} from '@zi-pages/account/account.config';
import { PhoneVerifierService } from '@zi-core/service/phone-verifier.service';
import { AnalyticsService } from '@zi-core/service/analytics.service';
import Timeout = NodeJS.Timeout;
import { LoggerServiceToken } from '@zi-core/config/logger-service.config';
import { ILoggerService } from '@zi-core/interface/logger.service.interface';

@Injectable({
  providedIn: 'root',
})
export class VoipVoicemailService implements OnDestroy {
  private incomingConnection: Connection;
  private incomingVoicemailEnded$ = new EventEmitter<void>();
  private subscriptions: Subscription[] = [];
  private FINISH_VOICEMAIL_RECORDING = '9';
  private CANCEL_VOICEMAIL_RECORDING = '3';
  private timerRef: Timeout;
  private timeoutRef: Timeout;

  private voicemailDropRecordState$ = new BehaviorSubject<VoicemailDropRecordState>({ ...initialVoicemailDropRecordState });
  private voicemailGreetRecordState$ = new BehaviorSubject<VoicemailGreetRecordState>({ ...initialVoicemailGreetRecordState });

  constructor(
    private voipDeviceService: VoipDeviceService,
    private notyService: NotyService,
    private dialerUtilService: DialerUtilService,
    private phoneVerifierService: PhoneVerifierService,
    private analyticsService: AnalyticsService,
    @Inject(LoggerServiceToken) private loggerService: ILoggerService,
  ) {
    this.subscriptions.push(
      this.voipDeviceService.getDeviceIncomingVoicemailNotifier().subscribe((incomingConnection: Connection) => {
        this.incomingConnection = incomingConnection;
        this.initConnectionEventHandlers();
        this.acceptIncomingVoicemailCall();
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.cleanTimers();
  }

  public finishIncomingVoicemailCall(): void {
    _.invoke(this.incomingConnection, 'sendDigits', this.FINISH_VOICEMAIL_RECORDING);
    const isGreeting = this.getVoicemailGreetRecordState().mode === VoicemailGreetRecordModeEnum.RECORDING;
    this.analyticsService.sendAddedVoicemailRecordingEvent({
      voicemailType: isGreeting ? VoicemailType.GREETING : VoicemailType.DROP,
      recordingName: _.get(this.getVoicemailDropRecordState(), 'newTitle', ''),
    });
    this.resetNewVoicemailState();
  }

  public cancelIncomingVoicemailCall(): void {
    if (
      this.getVoicemailDropRecordState().mode === VoicemailDropRecordModeEnum.RECORDING ||
      this.getVoicemailGreetRecordState().mode === VoicemailGreetRecordModeEnum.RECORDING
    ) {
      _.invoke(this.incomingConnection, 'sendDigits', this.CANCEL_VOICEMAIL_RECORDING);
    } else {
      _.invoke(this.incomingConnection, 'disconnect');
    }
  }

  public getIncomingVoicemailEnded(): Observable<void> {
    return this.incomingVoicemailEnded$.asObservable();
  }

  public getIncomingConnection(): Connection {
    return this.incomingConnection;
  }

  public getVoicemailDropRecordState$(): Observable<VoicemailDropRecordState> {
    return this.voicemailDropRecordState$.pipe(
      distinctUntilChanged((prev, curr) => {
        return _.isEqual(prev, curr);
      }),
    );
  }

  public getVoicemailGreetRecordState$(): Observable<VoicemailGreetRecordState> {
    return this.voicemailGreetRecordState$.pipe(
      distinctUntilChanged((prev, curr) => {
        return _.isEqual(prev, curr);
      }),
    );
  }

  public getVoicemailDropRecordState(): VoicemailDropRecordState {
    return { ...this.voicemailDropRecordState$.value };
  }

  public getVoicemailGreetRecordState(): VoicemailGreetRecordState {
    return { ...this.voicemailGreetRecordState$.value };
  }

  public setVoicemailDropRecordState(voicemailDropRecordState: VoicemailDropRecordState): void {
    this.voicemailDropRecordState$.next(voicemailDropRecordState);
  }

  public setVoicemailGreetRecordState(voicemailGreetRecordState: VoicemailGreetRecordState): void {
    this.voicemailGreetRecordState$.next(voicemailGreetRecordState);
  }

  public isVoicemailRecActive(): boolean {
    return (
      VOICEMAIL_GREET_ACTIVE_VALUES.includes(_.get(this.voicemailGreetRecordState$, 'value.mode', 0)) ||
      VOICEMAIL_DROP_ACTIVE_VALUES.includes(_.get(this.voicemailDropRecordState$, 'value.mode', 0))
    );
  }

  public initVoicemailDropRecordState(): void {
    this.setVoicemailDropRecordState({ ...initialVoicemailDropRecordState });
  }

  public initVoicemailGreetRecordState(): void {
    this.setVoicemailGreetRecordState({ ...initialVoicemailGreetRecordState });
  }

  public resetNewVoicemailState(): void {
    this.initVoicemailDropRecordState();
    this.initVoicemailGreetRecordState();
    this.cleanTimers();
  }

  public startTimer(isGreeting: boolean): void {
    const startTime = Date.now();
    // Run once to cover 00:00
    this.handleTimerTick(startTime, isGreeting);
    // Make sure only one ever exists.
    this.stopTimer();
    this.timerRef = setInterval(() => {
      this.handleTimerTick(startTime, isGreeting);
    }, 1000);
  }

  public cleanTimers(): void {
    this.stopTimer();
    this.cleanTimeout();
  }

  public recordVoicemail(userId: number, isGreeting: boolean): void {
    this.phoneVerifierService.record(String(userId), this.getVoicemailDropRecordState().newTitle, DialerSelection.VOIP, isGreeting).subscribe(() => {
      this.changeVoicemailState(VoicemailDropRecordModeEnum.INITIALIZING, VoicemailGreetRecordModeEnum.INITIALIZING, true, isGreeting);
      // Make sure one is ever in existence
      this.cleanTimeout();
      this.timeoutRef = setTimeout(() => {
        this.changeVoicemailState(VoicemailDropRecordModeEnum.RECORDING, VoicemailGreetRecordModeEnum.RECORDING, true, isGreeting);
        this.startTimer(isGreeting);
      }, VOICEMAIL_PRE_RECORD_TIMEOUT);
    });
  }

  public cancelVoiceDropLink(): void {
    this.initVoicemailDropRecordState();
    // if voicemail greet recording is in progress
    if (this.getVoicemailGreetRecordState().mode !== VoicemailGreetRecordModeEnum.START_RECORDING) {
      this.disableVoicemailDropRecordState(this.getVoicemailDropRecordState(), DISABLE_VOICEMAIL_DROP_MSG_ACTIVE_GREET);
    }
  }

  public resetNewVoicemailDropInputState(): void {
    const voicemailDropState = this.getVoicemailDropRecordState();
    voicemailDropState.newTitle = '';
    voicemailDropState.errorMsg = '';
    this.setVoicemailDropRecordState(voicemailDropState);
  }

  public disableVoicemailRecordState(disabledReason: string): void {
    this.disableVoicemailDropRecordState(this.getVoicemailDropRecordState(), disabledReason);
    this.disableVoicemailGreetRecordState(this.getVoicemailGreetRecordState(), disabledReason);
  }

  public disableVoicemailDropRecordState(voicemailDropState: VoicemailDropRecordState, disabledReason?: string): VoicemailDropRecordState {
    this.conditionalVoiceDropStateChange(voicemailDropState, VoicemailDropRecordModeEnum.ADD_NEW_DROP, VoicemailDropRecordModeEnum.ADD_NEW_DROP_DISABLED);
    this.conditionalVoiceDropStateChange(
      voicemailDropState,
      VoicemailDropRecordModeEnum.ENTER_NAME_START_RECORDING,
      VoicemailDropRecordModeEnum.ENTER_NAME_START_RECORDING_DISABLED,
    );
    voicemailDropState.disabled_reason = disabledReason;
    this.setVoicemailDropRecordState(voicemailDropState);
    return voicemailDropState;
  }

  public enableVoicemailRecordState(): void {
    this.enableVoicemailDropRecordState();
    this.enableVoicemailGreetRecordState();
  }

  public cancelVoicemailRecord(): void {
    this.loggerService.log('cancel voicemail record called :: resetting voicemail state');
    const voicemailGreetState = this.getVoicemailGreetRecordState();
    const voicemailDropState = this.getVoicemailDropRecordState();
    const greetModePreCancel = voicemailGreetState.mode;
    const dropModePreCancel = voicemailDropState.mode;
    this.cancelIncomingVoicemailCall();
    this.resetNewVoicemailState();
    if (dropModePreCancel === VoicemailDropRecordModeEnum.RECORDING || greetModePreCancel === VoicemailGreetRecordModeEnum.RECORDING) {
      const voicemailGreetRecordState = this.getVoicemailGreetRecordState();
      const voicemailDropRecordState = this.getVoicemailDropRecordState();
      voicemailGreetRecordState.mode = VoicemailGreetRecordModeEnum.DISABLED;
      voicemailDropRecordState.mode = VoicemailDropRecordModeEnum.ADD_NEW_DROP_DISABLED;
      this.setVoicemailGreetRecordState(voicemailGreetRecordState);
      this.setVoicemailDropRecordState(voicemailDropRecordState);
    }
  }

  private acceptIncomingVoicemailCall(): void {
    _.invoke(this.incomingConnection, 'accept');
  }

  private initConnectionEventHandlers(): void {
    this.incomingConnection.on('disconnect', () => {
      this.incomingConnection = null;
      this.incomingVoicemailEnded$.emit();
    });

    this.incomingConnection.on('error', (error) => {
      this.notyService.postMessage(new Message(MessageType.ERROR, 'Error recording Voicemail. Please try again.'));
    });

    this.incomingConnection.on('warning', (warningName, warningData) => {
      // Gets triggered for silent voicemails - low byte received. No action needed as of now
    });
  }

  private handleTimerTick(startTime: number, isGreeting: boolean) {
    const counter = Date.now() - startTime;
    if (isGreeting) {
      const voicemailGreetState = this.getVoicemailGreetRecordState();
      voicemailGreetState.duration = this.dialerUtilService.getDuration(counter);
      this.setVoicemailGreetRecordState(voicemailGreetState);
    } else {
      const voicemailDropState = this.getVoicemailDropRecordState();
      voicemailDropState.duration = this.dialerUtilService.getDuration(counter);
      this.setVoicemailDropRecordState(voicemailDropState);
    }
    // max recording time = 5 min
    if (new Date(counter).getUTCMinutes() >= 5) {
      this.finishIncomingVoicemailCall();
    }
  }

  private changeVoicemailState(
    newDropState: VoicemailDropRecordModeEnum,
    newGreetState: VoicemailGreetRecordModeEnum,
    disableOtherState: boolean,
    isGreeting?: boolean,
  ): void {
    const voicemailGreetRecordState = this.getVoicemailGreetRecordState();
    const voicemailDropRecordState = this.getVoicemailDropRecordState();
    if (isGreeting) {
      voicemailGreetRecordState.mode = newGreetState;
      if (disableOtherState) {
        this.disableVoicemailDropRecordState(voicemailDropRecordState, DISABLE_VOICEMAIL_DROP_MSG_ACTIVE_GREET);
      }
      this.setVoicemailGreetRecordState(voicemailGreetRecordState);
    } else {
      voicemailDropRecordState.mode = newDropState;
      if (disableOtherState) {
        this.disableVoicemailGreetRecordState(voicemailGreetRecordState, DISABLE_VOICEMAIL_GREET_MSG_ACTIVE_DROP);
      }
      this.setVoicemailDropRecordState(voicemailDropRecordState);
    }
  }

  private disableVoicemailGreetRecordState(voicemailGreetingState: VoicemailGreetRecordState, disabledReason: string): void {
    voicemailGreetingState.mode = VoicemailGreetRecordModeEnum.DISABLED;
    voicemailGreetingState.disabled_reason = disabledReason;
    this.setVoicemailGreetRecordState(voicemailGreetingState);
  }

  public enableVoicemailDropRecordState(): void {
    const voicemailDropState = this.getVoicemailDropRecordState();
    this.conditionalVoiceDropStateChange(voicemailDropState, VoicemailDropRecordModeEnum.ADD_NEW_DROP_DISABLED, VoicemailDropRecordModeEnum.ADD_NEW_DROP);
    this.conditionalVoiceDropStateChange(
      voicemailDropState,
      VoicemailDropRecordModeEnum.ENTER_NAME_START_RECORDING_DISABLED,
      VoicemailDropRecordModeEnum.ENTER_NAME_START_RECORDING,
    );
    voicemailDropState.disabled_reason = '';
    this.setVoicemailDropRecordState(voicemailDropState);
  }

  private enableVoicemailGreetRecordState(): void {
    const voicemailGreetState = this.getVoicemailGreetRecordState();
    voicemailGreetState.mode = VoicemailGreetRecordModeEnum.START_RECORDING;
    voicemailGreetState.disabled_reason = '';
    this.setVoicemailGreetRecordState(voicemailGreetState);
  }

  private conditionalVoiceDropStateChange(
    voicemailDropState: VoicemailDropRecordState,
    oldState: VoicemailDropRecordModeEnum,
    newState: VoicemailDropRecordModeEnum,
  ): void {
    if (voicemailDropState.mode === oldState) {
      voicemailDropState.mode = newState;
    }
  }

  private stopTimer(): void {
    if (this.timerRef) {
      clearInterval(this.timerRef);
    }
  }

  private cleanTimeout(): void {
    if (this.timeoutRef) {
      clearTimeout(this.timeoutRef);
    }
  }
}
