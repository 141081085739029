import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-outlook-extension',
  templateUrl: './outlook-extension.component.html',
  styleUrls: ['./outlook-extension.component.scss'],
})
export class OutlookExtensionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
