export enum ExcludeFilterTitles {
  'Opened Email Once' = 'Opened Email Once',
  'Opened Multiple Times' = 'Opened Multiple Times',
}

export enum ExcludeFilterTitlesForFailedEmails {
  'Soft Bounce' = 'Soft Bounce',
  'Hard Bounce' = 'Hard Bounce',
}

export enum EmailFilterTitles {
  AllEmails = 'All Opened Emails',
  OpenedEmailOnce = 'Opened Email Once',
  OpenedMultipleTimes = 'Opened Multiple Times',
}
export enum FailedDeliveryFilterTitles {
  AllFailedDelivery = 'All Failed Delivery',
  SoftBounce = 'Soft Bounce',
  HardBounce = 'Hard Bounce',
}

export enum RadioOptions {
  'All Opened Emails' = 'All Opened Emails',
  'Opened Email Once' = 'Opened Email Once',
  'Opened Multiple Times' = 'Opened Multiple Times',
}

export enum FailedDeliveryRadioOptions {
  'All Failed Delivery' = 'All Failed Delivery',
  'Soft Bounce' = 'Soft Bounce',
  'Hard Bounce' = 'Hard Bounce',
}

export enum NonDateFields {
  'do_not_call' = 'do_not_call',
  'statuses' = 'statuses',
}
