import { Action, createReducer, on } from '@ngrx/store';
import { EngageFilterState, EntityFilterNodeState, initialEngageFilterState } from '../state/engage-filter.state';
import { FilterCategory, FilterEntity } from '@app/core/enums/engage-filter.enum';
import {
  LoadEngageFilterNodesAction,
  LoadEngageFilterNodesFailureAction,
  LoadEngageFilterNodesSuccessAction,
  LoadQuickFiltersFailureAction,
  LoadQuickFiltersSuccessAction,
  UpdateQuickFiltersFailureAction,
  UpdateQuickFiltersSuccessAction,
} from '../action/engage-filter.action';
import { UserConfigurationKey } from '@app/core/enums/user-configuration.enum';
import { failureLoadedState, loadingState, successLoadedState } from '@zi-core/ngrx/config/ngrx-loading-states.config';

function getEngageFilterStateKey(selectedEntity: FilterEntity): string | null {
  if (selectedEntity === FilterEntity.Contact) {
    return 'contact';
  } else if (selectedEntity === FilterEntity.Task) {
    return 'task';
  } else if (selectedEntity === FilterEntity.MessageTemplate) {
    return 'template';
  } else if (selectedEntity === FilterEntity.Message) {
    return 'message';
  } else if (selectedEntity === FilterEntity.CompanyAccount) {
    return 'companyAccount';
  }
  return null;
}

function getNewFilterNodeState(
  state: EngageFilterState,
  selectedCategory: FilterCategory,
  type: string,
  entityState: EntityFilterNodeState,
): EngageFilterState {
  if (
    selectedCategory === FilterCategory.ContactFields ||
    selectedCategory === FilterCategory.CompanyAccountFields ||
    selectedCategory === FilterCategory.MessageFields
  ) {
    return { ...state, [type]: { ...state[type], fields: entityState } };
  } else if (selectedCategory === FilterCategory.Tags) {
    return { ...state, [type]: { ...state[type], tags: entityState } };
  } else if (selectedCategory === FilterCategory.Salesflow) {
    return { ...state, [type]: { ...state[type], salesflow: entityState } };
  }
  return { ...state };
}

const engageFilterReducer = createReducer(
  initialEngageFilterState,

  on(LoadEngageFilterNodesAction, (state: EngageFilterState, { selectedEntity, selectedCategory, selectedScope }) => {
    const entityState: EntityFilterNodeState = { filters: undefined, ...loadingState };
    const filterStateKey = getEngageFilterStateKey(selectedEntity);
    if (filterStateKey) {
      return getNewFilterNodeState(state, selectedCategory, filterStateKey, entityState);
    }
    return { ...state };
  }),

  on(LoadEngageFilterNodesSuccessAction, (state: EngageFilterState, { filterNodes, selectedEntity, selectedCategory, selectedScope }) => {
    const entityState: EntityFilterNodeState = { filters: filterNodes, ...successLoadedState };
    const filterStateKey = getEngageFilterStateKey(selectedEntity);
    if (filterStateKey) {
      return getNewFilterNodeState(state, selectedCategory, filterStateKey, entityState);
    }
    return { ...state };
  }),

  on(LoadEngageFilterNodesFailureAction, (state: EngageFilterState, { selectedEntity, selectedCategory, error }) => {
    const entityState: EntityFilterNodeState = { filters: undefined, ...failureLoadedState, error };
    const filterStateKey = getEngageFilterStateKey(selectedEntity);
    if (filterStateKey) {
      return getNewFilterNodeState(state, selectedCategory, filterStateKey, entityState);
    }
    return { ...state };
  }),

  on(LoadQuickFiltersSuccessAction, (state: EngageFilterState, { quickFilters, selectedEntity, selectedCategory }) => {
    if (selectedEntity === FilterEntity.Contact && selectedCategory === FilterCategory.QuickFilters) {
      return { ...state, contact: { ...state.contact, quickFilters: { ...state.contact.quickFilters, qFilters: quickFilters, ...successLoadedState } } };
    } else if (selectedEntity === FilterEntity.Task && selectedCategory === FilterCategory.QuickFilters) {
      return { ...state, task: { ...state.task, quickFilters: { ...state.task.quickFilters, qFilters: quickFilters, ...successLoadedState } } };
    }
    return state;
  }),

  on(LoadQuickFiltersFailureAction, (state: EngageFilterState) => {
    return { ...state };
  }),

  on(UpdateQuickFiltersSuccessAction, (state: EngageFilterState, { key, quickFilters }) => {
    if (key === UserConfigurationKey.QUICK_FILTERS) {
      return { ...state, contact: { ...state.contact, quickFilters: { ...state.contact.quickFilters, qFilters: quickFilters, ...successLoadedState } } };
    } else if (key === UserConfigurationKey.TASK_QUICK_FILTERS) {
      return { ...state, task: { ...state.task, quickFilters: { ...state.task.quickFilters, qFilters: quickFilters, ...successLoadedState } } };
    }
    return state;
  }),

  on(UpdateQuickFiltersFailureAction, (state: EngageFilterState) => {
    return { ...state };
  }),
);

export function EngageFilterReducer(state: EngageFilterState, action: Action): EngageFilterState {
  return engageFilterReducer(state, action);
}
