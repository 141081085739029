<mat-checkbox
  class="eg-checkbox"
  [indeterminate]="indeterminate"
  [checked]="checked"
  [disabled]="disabled"
  [disableRipple]="true"
  [ngClass]="{'disabled': disabled, 'isAll': isAll}"
  (change)="onChecked($event)"
  (click)="onClick($event)"
>
  <ng-content></ng-content>
</mat-checkbox>
