import { defaultLoadingState } from '@zi-core/ngrx/config/ngrx-loading-states.config';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AdminState } from '../../admin.state';
import { extractDomainFromEmail } from '@zi-pages/admin/pages/org-management/email-quotas/email-blocklisting/email-blocklisting.helper';
import { BlocklistSubscriptionSettings } from '@zi-core/data-model/blocklist.model';
import { Contact } from '@zi-core/data-model/contact.model';
import { isNil } from 'lodash';

export class BlocklistState {
  emails: string[];
  domains: string[];
  subscribeCalls: boolean;
  subscribeSms: boolean;
  loaded: boolean;
  loading: boolean;
}

export const initialBlocklistState: BlocklistState = {
  emails: [],
  domains: [],
  subscribeCalls: false,
  subscribeSms: false,
  ...defaultLoadingState,
};

// Selectors
export const adminFeatureSelector = createFeatureSelector<AdminState>('admin');
export const blocklistSelector = createSelector(adminFeatureSelector, (state: AdminState) => state?.blocklist);
export const blocklistDomainStateSelector = createSelector(blocklistSelector, (state: BlocklistState) => [...state?.domains].sort());
export const blocklistEmailStateSelector = createSelector(blocklistSelector, (state: BlocklistState) => [...state?.emails].sort());

export const blocklistDomainAndEmailStateSelector = createSelector(blocklistSelector, (state: BlocklistState) => [...state?.domains, ...state?.emails]);

export const blocklistSubscribeSMSSelector = createSelector(blocklistSelector, (state: BlocklistState) => state?.subscribeSms);

export const blocklistSubscribeCallsSelector = createSelector(blocklistSelector, (state: BlocklistState) => state?.subscribeCalls);

export const blocklistSubscriptionSettingsSelector = createSelector(
  blocklistSelector,
  (state: BlocklistState) =>
    ({
      subscribeCalls: state?.subscribeCalls,
      subscribeSms: state?.subscribeSms,
    } as BlocklistSubscriptionSettings),
);

const isEmailBlocklisted = (blocklist: BlocklistState, email: string) => {
  if (isNil(blocklist) || isNil(email)) {
    return false;
  }
  const domain = extractDomainFromEmail(email);

  const domainBlocklisted = blocklist.domains?.some((blocklistDomain) => blocklistDomain?.toLowerCase() === domain?.toLowerCase());

  const emailBlocklisted = blocklist.emails?.some((blocklistEmail) => blocklistEmail?.toLowerCase() === email?.toLowerCase());

  return domainBlocklisted || emailBlocklisted;
};

export const blocklistSettingsSelector = (email: string) => {
  return createSelector(blocklistSelector, (blocklist: BlocklistState) => {
    const blockEmail = isEmailBlocklisted(blocklist, email);
    const { subscribeCalls, subscribeSms } = blocklist;

    return {
      blockEmail,
      blockCall: blockEmail && subscribeCalls,
      blockSMS: blockEmail && subscribeSms,
    };
  });
};

export const blocklistSettingsBlockEmailSelector = (email: string) => {
  return createSelector(blocklistSelector, (blocklist: BlocklistState) => {
    return isEmailBlocklisted(blocklist, email);
  });
};

export const blocklistSettingsBlockCallSelector = (email: string) => {
  return createSelector(blocklistSelector, (blocklist: BlocklistState) => {
    const { subscribeCalls } = blocklist;
    const blockEmail = isEmailBlocklisted(blocklist, email);

    return blockEmail && subscribeCalls;
  });
};

export const filterBlockListedContacts = (contacts: Contact[]) => {
  return createSelector(blocklistSelector, (blocklist: BlocklistState) => {
    const nonBlockListedContacts = contacts.filter((x) => !isEmailBlocklisted(blocklist, x.email));

    return nonBlockListedContacts;
  });
};

/**
 * Sets blockEmail property to true if ALL emails are part of blocklist
 */
export const blocklistSettingsAllEmailsAreBlocklistedSelector = (emails: string[]) => {
  return createSelector(blocklistSelector, (blocklist: BlocklistState) => {
    if (emails === null || emails.length === 0 || isNil(blocklist)) {
      return {};
    }

    let blockEmail = true;
    const { subscribeCalls, subscribeSms } = blocklist;

    emails.every((email) => {
      if (isEmailBlocklisted(blocklist, email) === false) {
        blockEmail = false;
        return false;
      }

      return true;
    });

    return {
      blockEmail,
      blockCall: blockEmail && subscribeCalls,
      blockSMS: blockEmail && subscribeSms,
    };
  });
};
