import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { map, switchMap } from 'rxjs/operators';
import { RetrieveConversationResponse } from '@zi-pages/sms/model/retrieve-conversation-response';
import { Observable } from 'rxjs';
import { RetrieveConversationToDtoPipe } from '@zi-pages/sms/pipe/retrieve-conversation-to-dto.pipe';
import { SmsConversationDto } from '@zi-pages/sms/model/sms-conversation.dto';
import { SmsSendMessageResponse } from '@zi-pages/sms/model/sms-send-message-response';
import { RetrieveUserConversationsToDtoPipe } from '@zi-pages/sms/pipe/retrieve-user-conversations-to-dto.pipe';
import { RetrieveUserConversationsResponse } from '@zi-pages/sms/model/retrieve-user-conversations-response';
import { SmsUserConversationsDto } from '@zi-pages/sms/model/sms-user-conversations.dto';
import { ApplicationState } from '@app/reducers';
import { UpdateIsReadForConversationAction, UpdateTotalUnreadSMSAction } from '@zi-pages/sms/ngrx/action/sms-message.action';
import { Store } from '@ngrx/store';
import { smsMessageLoadedConvoContactSelector, totalUnreadSMSStateSelector } from '../ngrx/state/sms-message.state';
import { CustomerProfileDto } from '@app/pages/admin/pages/org-management/sms-registration/sms-registration-form.dto';
import { isSmsEnabledSelector } from '@zi-core/ngrx/state/auth.state';
import { DialerConfigState, getDialerVoip } from '@zi-pages/account/ngrx/state/dialer-config.state';
import { parsePhoneNumber } from 'libphonenumber-js';
import { FeatureFlagService } from '@app/feature-flag/feature-flag.service';
import { Flag } from '@app/feature-flag/types/flag.enum';

@Injectable()
export class SmsService {
  isSmsEnabled$: Observable<boolean>;
  isSmsRegistered$: Observable<boolean>;
  isSmsVisible$: Observable<boolean>;

  constructor(
    private _httpClient: HttpClient,
    private _store: Store<ApplicationState>,
    private retrieveConversationToDtoPipe: RetrieveConversationToDtoPipe,
    private retrieveUserConversationsToDtoPipe: RetrieveUserConversationsToDtoPipe,
    private dialerConfigStore: Store<DialerConfigState>,
    private featureFlagService: FeatureFlagService,
  ) {
    this.isSmsVisible$ = this.featureFlagService.observe<Flag.ENABLE_SMS>(Flag.ENABLE_SMS);
    this.isSmsRegistered$ = this.isSmsVisible$.pipe(
      switchMap((isSmsVisible) => {
        return this._store.select(isSmsEnabledSelector).pipe(map((isSmsRegistered) => isSmsRegistered && isSmsVisible));
      }),
    );
    this.isSmsEnabled$ = this.isSmsRegistered$.pipe(
      switchMap((isSmsRegistered) => {
        return this.dialerConfigStore.select(getDialerVoip).pipe(
          map((dialerConfig) => {
            return isSmsRegistered && this.checkNumUS(dialerConfig?.phone);
          }),
        );
      }),
    );
  }

  public checkNumUS(userPhoneNumber: string) {
    return !!userPhoneNumber ? parsePhoneNumber(userPhoneNumber).country === 'US' : false;
  }

  public getIsSmsEnabled(): Observable<boolean> {
    return this.isSmsEnabled$;
  }

  public getIsSmsRegistered(): Observable<boolean> {
    return this.isSmsRegistered$;
  }

  public getIsSmsVisible(): Observable<boolean> {
    return this.isSmsVisible$;
  }

  public sendMessage(toNumber: string, messageBody: string, trackingId?: string, contactId?: number) {
    return this._httpClient.post(`${environment.backend_url}/v1/messenger/send`, { toNumber, messageBody, trackingId, contactId }).pipe(
      map((resp: SmsSendMessageResponse) => {
        resp.data.isRead = true;
        return resp;
      }),
    );
  }

  public deleteMessage(trackingId: string) {
    return this._httpClient.post(`${environment.sms_messenging_service_url}/v1/delete`, { trackingId });
  }

  public retrieveConversationsForUser(pageNum: number, pageSize: number): Observable<SmsUserConversationsDto> {
    return this._httpClient.get<RetrieveUserConversationsResponse>(`${environment.backend_url}/v1/messages?pageSize=${pageSize}&pageNum=${pageNum}`).pipe(
      map((resp: RetrieveUserConversationsResponse) => {
        return this.retrieveUserConversationsToDtoPipe.transform(resp);
      }),
    );
  }

  public retrieveConversationForContactByMobile(mobileNum: string, pageNum: number, pageSize: number): Observable<SmsConversationDto> {
    return this._httpClient
      .get<RetrieveConversationResponse>(`${environment.sms_messenging_service_url}/v1/conversation/${mobileNum}?pageSize=${pageSize}&pageNum=${pageNum}`)
      .pipe(
        map((resp: RetrieveConversationResponse) => {
          return this.retrieveConversationToDtoPipe.transform(resp);
        }),
      );
  }

  public searchContactsThroughSms(data: string): Observable<SmsUserConversationsDto> {
    return this._httpClient.post<RetrieveUserConversationsResponse>(`${environment.backend_url}/v1/messages/search`, { search: data }).pipe(
      map((resp: RetrieveUserConversationsResponse) => {
        return this.retrieveUserConversationsToDtoPipe.transform(resp);
      }),
    );
  }

  public updateTotalUnreadMessages(mobilePhone: any) {
    let totalUnreadSMSPresent = 0;
    this._store
      .select(totalUnreadSMSStateSelector)
      .subscribe((currentUnReadSMSPresent) => {
        totalUnreadSMSPresent = currentUnReadSMSPresent + 1;
      })
      .unsubscribe();

    this._store.dispatch(UpdateTotalUnreadSMSAction({ totalUnreadSMSPresent: totalUnreadSMSPresent }));
    let selectedConvoContactPhone = '';
    this._store
      .select(smsMessageLoadedConvoContactSelector)
      .subscribe((selectedConvoContact) => {
        selectedConvoContactPhone = selectedConvoContact?.mobilePhone;
      })
      .unsubscribe();

    if (selectedConvoContactPhone !== '' && mobilePhone === selectedConvoContactPhone) {
      this._store.dispatch(UpdateIsReadForConversationAction({ mobilePhone: mobilePhone }));
    }
  }

  public getTotalUnreadMessages(): Observable<number> {
    const unreadCount = this._httpClient.get<any>(`${environment.sms_messenging_service_url}/v1/unread`).pipe(
      map((resp) => {
        return resp.unreadMessages;
      }),
    );
    return unreadCount;
  }

  public updateIsReadForContact(contactPhone: string): Observable<number> {
    const readSMSCount = this._httpClient.post<any>(`${environment.sms_messenging_service_url}/v1/setIsRead`, { contactPhone }).pipe(
      map((res) => {
        return res.readMessages;
      }),
    );
    return readSMSCount;
  }

  public registerForSMSService(customerProfile: CustomerProfileDto): Observable<string> {
    return this._httpClient.post<any>(`${environment.backend_url}/v1/messages/service`, { customerProfile }).pipe(
      map((res) => {
        return res.sid;
      }),
    );
  }

  public getSMSServiceRegisterationStatus(): Observable<string> {
    return this._httpClient.post<any>(`${environment.backend_url}/v1/messages/service/status`, {}).pipe(
      map((res) => {
        return res.registrationDate;
      }),
    );
  }
}
