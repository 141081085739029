import { Injectable } from '@angular/core';
import * as jsonAreaCodes from '../constant/npa-area-codes.json';
import { stateAbbreviationMap } from '@app/caller/constant/state-abbreviation-map';

/**
 * NPA: The area code.
 * Location: The state/county/territory being represented
 */
export interface NPAAreaCodes {
  NPA: string;
  Location: string;
}

export interface NPAAreaCodesByStateList {
  [state: string]: string[];
}

/**
 * This is a service that retrieves Area Codes from npa-area-codes.json and provides accessor
 * methods for accessing data.
 *
 * At some point in the future, a customer might require the addition of new area codes. This
 * is the process for doing so:
 *
 * 1) Navigate to https://nationalnanpa.com/enas/geoAreaCodeNumberReport.do
 * 2) Click the "Download Report to Excel file". Note the location of file download. It must be
 * downloaded as a XLSX file.
 * 3) Navigate to a XLSX->JSON converter (can be found through google,
 * such as https://www.aconvert.com/document/xlsx-to-json/).
 * 4) Take the JSON file produced and replace the current npa-area-codes.json file.
 * 5) Commit the new file to git and push.
 *
 */
@Injectable()
export class AreaCodesService {
  private readonly onlyUsStatesAreaCodes: NPAAreaCodes[] = [];
  private readonly onlyUsStatesAreaCodesStrList: string[] = [];
  private readonly areaCodes: NPAAreaCodes[];
  private readonly areaCodesStrList: string[] = [];

  constructor() {
    // @ts-ignore
    this.areaCodes = jsonAreaCodes.default;
    this.areaCodes.forEach((areaCodeObj) => {
      const areaCodeStr = this.makeAreaCodeStr(areaCodeObj);
      if (areaCodeObj.Location && areaCodeObj.Location.length === 2) {
        this.onlyUsStatesAreaCodes.push(areaCodeObj);
        this.onlyUsStatesAreaCodesStrList.push(areaCodeStr);
      }

      this.areaCodesStrList.push(areaCodeStr);
    });
  }

  /**
   * Get location from area code
   * @param npa
   * @private
   */
  public getLocationFromAreaCode(npa: string): string {
    return this.areaCodes.find((areaCode: NPAAreaCodes) => {
      return areaCode?.NPA === npa;
    })?.Location;
  }

  public getOnlyUsStatesAreaCodes(): NPAAreaCodes[] {
    return this.onlyUsStatesAreaCodes;
  }

  public getOnlyUsStatesAreaCodesStrList(): string[] {
    return this.onlyUsStatesAreaCodesStrList;
  }

  public getAreaCodes(): NPAAreaCodes[] {
    return this.areaCodes;
  }

  public getAreaCodesStrList(): string[] {
    return this.areaCodesStrList;
  }

  public getAreaCodesByStateList(): NPAAreaCodesByStateList {
    const areaCodesByStateList = {};
    this.getOnlyUsStatesAreaCodes().forEach((npaAreaCode) => {
      const stateName = stateAbbreviationMap[npaAreaCode.Location];
      // If state name does not exist in abbreviation map, it is not supported.
      if (!!stateName) {
        if (!areaCodesByStateList[stateName]) {
          areaCodesByStateList[stateName] = [npaAreaCode.NPA];
        } else {
          areaCodesByStateList[stateName].push(npaAreaCode.NPA);
        }
      }
    });
    return areaCodesByStateList;
  }

  private makeAreaCodeStr(areaCodeObj: { NPA: string; Location: string }): string {
    return `${areaCodeObj.NPA} (${areaCodeObj.Location})`;
  }

  public getUsAreaCodeAndStrMappingList() {
    return this.getOnlyUsStatesAreaCodes().map((areaCodeObj) => {
      return { areaCode: areaCodeObj.NPA, areaCodeStr: this.makeAreaCodeStr(areaCodeObj) };
    });
  }

  private isNumeric(value): boolean {
    return /^-?\d+$/.test(value);
  }
}
