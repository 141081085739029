import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { validationMessages } from '@zi-common/model/forms/validation-messages';

@Component({
  selector: 'app-validation-errors',
  templateUrl: './validation-errors.component.html',
  styleUrls: ['./validation-errors.component.scss'],
})
export class ValidationErrorsComponent {
  /**
   * The form validation errors.
   */
  @Input() errors: ValidationErrors;

  /**
   * The set of validation messages to dispaly.
   */
  validationMessages = validationMessages;

  constructor() {}
}
