export enum AnalyticsOutcome {
  SUCCESS = 'Success',
  ERROR = 'Error',
}

export enum AnalyticsApplication {
  Engage = 'Engage',
  EngageExtension = 'EngageExtension',
}

/**
 * Analytics object being searched
 */
export enum AnalyticsSearchType {
  ACCOUNT = 'Account',
  CONTACT = 'Contact',
}

/**
 * Filter tab from which filter was selected
 */
export enum AnalyticsFilterTab {
  ACCOUNTS = 'Accounts',
  CONTACTS = 'Contacts',
}

export enum AnalyticsSectionType {
  ActionBar = 'ActionBar',
  ContactActions = 'ContactActions',
  SideNav = 'SideNav',
  EngagePanel = 'EngagePanel',
  EngageContactCard = 'EngageContactCard',
  HelpMenu = 'Help',
  SupportCenter = 'SupportCenter',
  ActionBarAddToSalesflowBtn = 'ActionBarAddToSalesflowButton',
}

export enum AnalyticsFilterNodeState {
  FILTER_ADDED = 'Added',
  FILTER_REMOVED = 'Removed',
}

export enum AnalyticsTogglePanel {
  SHOW_PANEL = 'Show',
  HIDE_PANEL = 'Hide',
}

export enum AnalyticsAccountTabSource {
  ACTIVITIES = 'Activities',
  REPS = 'Reps',
  SALES_FLOWS = 'Salesflows',
  NOTES = 'Notes',
}

export enum AnalyticsCreateUpdateEntityAction {
  CREATED = 'Created',
  UPDATED = 'Updated',
}

export enum AnalyticsAccountCreateUpdateCTA {
  ACCOUNT_CARD_ACTION_MENU = 'AccountCardActionMenu',
  NEW = 'New',
}
