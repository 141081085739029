import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyDetails } from '@app/common/interface/company-details';
import { environment } from '@env/environment';
import { TouchData } from '@zi-common/interface/last-touch';
import { DateManagementService } from '@zi-common/service/date-management/date-management.service';
import { CompanyAccount } from '@zi-core/data-model/account.model';
import { CompanyAccountContact } from '@zi-core/data-model/company-account-contact.model';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  constructor(private httpClient: HttpClient, private dateManagementService: DateManagementService) {}

  getDetails(companyId: number): Observable<CompanyDetails> {
    return this.httpClient.get<CompanyDetails>(`${environment.engage_backend_url}/data/company/${companyId}`);
  }

  getLastDateOfEngagement(companyAccount: CompanyAccount): TouchData {
    const lastEmailedAt = _.get(companyAccount, 'lastEmailedAt');
    const lastCalledAt = _.get(companyAccount, 'lastCalledAt');

    if (!lastEmailedAt && !lastCalledAt) {
      return { lastDate: null, touchType: '' };
    }

    let lastTouchDate: moment.Moment;
    let touchType: string;
    const lastEmailedAtMoment = this.dateManagementService.strDateToMoment(lastEmailedAt);
    const lastCalledAtMoment = this.dateManagementService.strDateToMoment(lastCalledAt);

    if (lastEmailedAt && lastCalledAt) {
      lastTouchDate = lastEmailedAtMoment.isAfter(lastCalledAtMoment) ? lastEmailedAtMoment : lastCalledAtMoment;
      touchType = lastEmailedAtMoment.isAfter(lastCalledAtMoment) ? 'Last Touch (by Email)' : 'Last Touch (by Call)';
    } else if (lastEmailedAt || lastCalledAt) {
      lastTouchDate = lastEmailedAt ? lastEmailedAtMoment : lastCalledAtMoment;
      touchType = lastEmailedAt ? 'Last Touch (by Email)' : 'Last Touch (by Call)';
    }

    return { lastDate: lastTouchDate, touchType };
  }

  addCompanyAccountContactMemberships(accountId: number, contactIds: Array<number>): Observable<Array<CompanyAccountContact>> {
    return this.httpClient.post<Array<CompanyAccountContact>>(`${environment.backend_url}/v1/company_accounts/contacts`, { accountId, contactIds });
  }

  getCompanyAccountMembershipsByContactIds(contactIds: Array<number>): Observable<Array<CompanyAccountContact>> {
    return this.httpClient.get<Array<CompanyAccountContact>>(`${environment.backend_url}/v1/company_accounts/contacts?contactIds=${contactIds}`);
  }
}
