import { ApplicationState } from '@app/reducers';
import { createSelector } from '@ngrx/store';

export class SfLiveAgentState {
  isInitialized: boolean;
  isLoading: boolean;
  isLoaded: boolean;
  error: {
    hasError: boolean;
    error: any;
  };
}

export const initialSfLiveAgentState = {
  isInitialized: false,
  isLoading: false,
  isLoaded: false,
  error: {
    hasError: false,
    error: null,
  },
};

export const getSfLiveAgentState = (state: ApplicationState) => state.sfLiveAgentStatus;

export const isSfLiveAgentInitialized = createSelector(getSfLiveAgentState, (state: SfLiveAgentState) => state.isInitialized);

export const isSfLiveAgentLoading = createSelector(getSfLiveAgentState, (state: SfLiveAgentState) => state.isLoading);
