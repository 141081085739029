import { ExtensionTab } from '@app/extension/model/extension-tab.model';
import { ExtensionSize } from '@app/extension/constants/extension-size.enum';
import { createSelector } from '@ngrx/store';
import { ExtensionParsedInfoState, initialExtensionParsedInfoState } from '@app/extension/ngrx/state/extension-parsed-info.state';
import { ApplicationState } from '@app/reducers';
import * as _ from 'lodash';

export interface ExtensionState {
  loading: boolean;
  isLoggedInSent: boolean;
  size: ExtensionSize;
  selectedExtTab: ExtensionTab;
  parsedContactInfoState: ExtensionParsedInfoState;
}

export const initialExtensionState = {
  loading: false,
  isLoggedInSent: false,
  size: ExtensionSize.TWENTY_PCT,
  selectedExtTab: undefined,
  parsedContactInfoState: initialExtensionParsedInfoState,
};

export const extensionState = (state: ApplicationState) => state.extension;

export const extSelector = createSelector(extensionState, (state: ExtensionState) => state);

export const extSizeSelector = createSelector(extensionState, (state: ExtensionState) => state.size);

export const extSelectedTabSelector = createSelector(extensionState, (state: ExtensionState) => state.selectedExtTab);

export const extIsLoggedInSentSelector = createSelector(extensionState, (state: ExtensionState) => state.isLoggedInSent);

export const extParsedContactInfoSelector = createSelector(extensionState, (state: ExtensionState) => state.parsedContactInfoState);

export const extParsedContactsSelector = createSelector(extParsedContactInfoSelector, (state: ExtensionParsedInfoState) => state.parsedContacts);

export const extParsedSourceInfoSelector = createSelector(extParsedContactInfoSelector, (state: ExtensionParsedInfoState) => state.source);

// selected contacts
export const contactsSelectedExtension = createSelector(extParsedContactInfoSelector, (state: ExtensionParsedInfoState) => {
  const parsedContacts = _.get(state, 'parsedContacts', []);
  return parsedContacts.filter((elt) => state.parsedSelectedIds.includes(elt.externalId || elt.zoomId));
});

export const contactsSelectedIdsExtension = createSelector(extParsedContactInfoSelector, (state: ExtensionParsedInfoState) => {
  const parsedContacts = _.get(state, 'parsedContacts', []);
  return parsedContacts.filter((elt) => state.parsedSelectedIds.includes(elt.externalId || elt.zoomId)).map((elt) => elt.id);
});

export const SelectedIdsStatusExtension = (contactId) =>
  createSelector(extParsedContactInfoSelector, (state: ExtensionParsedInfoState) => {
    const selectedContacts = _.get(state, 'parsedSelectedIds', []);
    return selectedContacts.some((elt) => elt === contactId);
  });

export const extContactIndexSelector = createSelector(extParsedContactInfoSelector, (state: ExtensionParsedInfoState) => state.currentIndex);

export const extContactSelectorByIndex = (index) =>
  createSelector(extParsedContactInfoSelector, (state: ExtensionParsedInfoState) => state.parsedContacts[index]);

export const extContactSelectorByCurrentIndex = createSelector(extParsedContactInfoSelector, (state: ExtensionParsedInfoState) => {
  if (state.parsedContacts) {
    return state.parsedContacts[state.currentIndex - 1];
  }
});
