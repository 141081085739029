export enum SystemName {
  email = 'email',
  firstName = 'firstName',
  lastName = 'lastName',
  company = 'company',
  zoomCompanyId = 'zoomCompanyId',
  phone = 'phone',
  owner = 'owner',
  salutation = 'salutation',
  jobTitle = 'jobTitle',
  department = 'department',
  doNotEmail = 'doNotEmail',
  doNotCall = 'doNotCall',
  description = 'description',
  industry = 'industry',
  annualRevenue = 'annualRevenue',
  numberOfEmployees = 'numberOfEmployees',
  companyPageUrl = 'companyPageUrl',
  companyGoogleUrl = 'companyGoogleUrl',
  companyFacebookUrl = 'companyFacebookUrl',
  companyLinkedInUrl = 'companyLinkedInUrl',
  companyTwitterUrl = 'companyTwitterUrl',
  street = 'street',
  city = 'city',
  state = 'state',
  zip = 'zip',
  country = 'country',
  otherPhone = 'otherPhone',
  mobilePhone = 'mobilePhone',
  homePhone = 'homePhone',
  assistantPhone = 'assistantPhone',
  hqPhone = 'hqPhone',
  facebookUrl = 'facebookUrl',
  googleUrl = 'googleUrl',
  linkedInUrl = 'linkedInUrl',
  twitterUrl = 'twitterUrl',
  unsubscribed = 'unsubscribed',
  leadStatus = 'leadStatus',
  tags = 'tags',
}

export const SYSTEM_NAME_TO_ENGAGE_FIELD_NAME = {
  [SystemName.email]: 'Email',
  [SystemName.firstName]: 'First Name',
  [SystemName.lastName]: 'Last Name',
  [SystemName.company]: 'Company',
  [SystemName.zoomCompanyId]: 'ZoomInfo Company ID',
  [SystemName.phone]: 'Direct Phone',
  [SystemName.owner]: 'Contact Owner',
  [SystemName.salutation]: 'Salutation',
  [SystemName.tags]: 'Tags',
  [SystemName.jobTitle]: 'Job Title',
  [SystemName.department]: 'Department',
  [SystemName.doNotEmail]: 'Do Not Email',
  [SystemName.doNotCall]: 'Do Not Call',
  [SystemName.description]: 'Description',
  [SystemName.industry]: 'Industry',
  [SystemName.annualRevenue]: 'Annual Revenue',
  [SystemName.numberOfEmployees]: 'Number Of Employees',
  [SystemName.companyPageUrl]: 'Company Website',
  [SystemName.companyGoogleUrl]: 'Company Google',
  [SystemName.companyFacebookUrl]: 'Company Facebook',
  [SystemName.companyLinkedInUrl]: 'Company LinkedIn',
  [SystemName.companyTwitterUrl]: 'Company Twitter',
  [SystemName.street]: 'Street',
  [SystemName.city]: 'City',
  [SystemName.state]: 'Province/State',
  [SystemName.zip]: 'Postal/Zip',
  [SystemName.country]: 'Country',
  [SystemName.otherPhone]: 'Other Phone',
  [SystemName.mobilePhone]: 'Mobile Phone',
  [SystemName.homePhone]: 'Home Phone',
  [SystemName.assistantPhone]: 'Assistant Phone',
  [SystemName.hqPhone]: 'Headquarter Phone',
  [SystemName.facebookUrl]: 'Facebook URL',
  [SystemName.googleUrl]: 'Google URL',
  [SystemName.linkedInUrl]: 'LinkedIn URL',
  [SystemName.twitterUrl]: 'Twitter',
};

export const ERROR_SYSTEM_NAME_TO_ENGAGE_FIELD_NAME = {
  failedReason: 'Failed Reason',
  zoomId: 'Zoom ID',
  zoomCompanyId: 'Zoom Company ID',
  ...SYSTEM_NAME_TO_ENGAGE_FIELD_NAME,
};

export const USER_HEADERS_CSV = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email Address',
  isAdmin: 'Is Admin',
  accountId: 'Account Id',
  sfAccountId: 'Salesforce Account Id',
};

const AutoMapping = {
  owner: SystemName.owner,
  'contact owner': SystemName.owner,
  first: SystemName.firstName,
  'first name': SystemName.firstName,
  firstname: SystemName.firstName,
  'employee first name': SystemName.firstName,
  'recommended contact first name': SystemName.firstName,
  last: SystemName.lastName,
  'last name': SystemName.lastName,
  lastname: SystemName.lastName,
  'recommended contact last name': SystemName.lastName,
  'employee last name': SystemName.lastName,
  salutation: SystemName.salutation,
  'company phone': SystemName.phone, // WorkPhone - Netprospex
  'employer telephone': SystemName.phone, // WorkPhone
  'employer phone': SystemName.phone, // WorkPhone
  employerphone: SystemName.phone, // WorkPhone
  'business telephone': SystemName.phone, // WorkPhone
  'business phone': SystemName.phone, // WorkPhone
  phone: SystemName.phone, // WorkPhone
  telephone: SystemName.phone, // WorkPhone
  tel: SystemName.phone, // WorkPhone
  'tel number': SystemName.phone, // WorkPhone
  'telephone number': SystemName.phone, // WorkPhone
  'phone number': SystemName.phone, // WorkPhone
  'direct phone number': SystemName.phone,
  'direct phone': SystemName.phone,
  'tel ': SystemName.phone, // WorkPhone
  'telephone ': SystemName.phone, // WorkPhone
  'phone ': SystemName.phone, // WorkPhone
  'employee direct phone': SystemName.phone, // WorkPhone
  'recommended contact phone direct': SystemName.phone,
  // 'Ext': 'Phone1', //WorkPhone
  //                'Extension': 'Phone1', //WorkPhone
  //                'Ext.': 'Phone1', //WorkPhone

  'phone 1': SystemName.otherPhone, // HomePhone - Netprospex
  cell: SystemName.mobilePhone, // CellPhone
  mobile: SystemName.mobilePhone, // CellPhone
  'phone 2': SystemName.mobilePhone, // CellPhone - Netprospex
  'mobile phone': SystemName.mobilePhone, // CellPhone
  'employee mobile phone': SystemName.mobilePhone, // CellPhone
  'mobile phone number': SystemName.mobilePhone, // CellPhone
  homephone: SystemName.homePhone,
  'home phone': SystemName.homePhone,
  'other phone': SystemName.otherPhone,
  assistantphone: SystemName.assistantPhone,
  'assistant phone': SystemName.assistantPhone,
  hqphone: SystemName.hqPhone,
  'company hq phone': SystemName.hqPhone,
  'company phone number': SystemName.hqPhone,

  email: SystemName.email, // WorkEmail
  'work email': SystemName.email, // WorkEmail
  'email address': SystemName.email, // WorkEmail
  emailaddress: SystemName.email, // WorkEmail
  emails: SystemName.email, // WorkEmail - Inside view
  'employee work email': SystemName.email, // WorkEmail
  'recommended contact email': SystemName.email,

  company: SystemName.company,
  'company name': SystemName.company,
  companyname: SystemName.company,
  'employer name': SystemName.company,
  employername: SystemName.company,
  accountname: SystemName.company, // data.com
  'account name': SystemName.company, // inside view
  'company  account': SystemName.company,
  'common organization name': SystemName.company, // Netprospex

  'zoominfo company id': SystemName.zoomCompanyId,
  'zoom company id': SystemName.zoomCompanyId,

  industry: SystemName.industry,
  'primary industry': SystemName.industry,
  'company primary industry': SystemName.industry,
  'industry label': SystemName.industry,
  dept: SystemName.department,
  department: SystemName.department,
  'employee departments': SystemName.department,
  'job function': SystemName.department,
  revenue: SystemName.annualRevenue,
  'annual revenue': SystemName.annualRevenue,
  'revenue in 000s': SystemName.annualRevenue,
  'company revenue': SystemName.annualRevenue,
  website: SystemName.companyPageUrl,
  'company website': SystemName.companyPageUrl,
  'website url': SystemName.companyPageUrl,
  ' of employees': SystemName.numberOfEmployees,
  employees: SystemName.numberOfEmployees,
  'employee count': SystemName.numberOfEmployees,
  'number of employees': SystemName.numberOfEmployees,
  'no of employees': SystemName.numberOfEmployees,

  title: SystemName.jobTitle,
  'job title': SystemName.jobTitle,
  jobtitle: SystemName.jobTitle,
  'job description': SystemName.jobTitle,
  'professional title': SystemName.jobTitle,
  professionaltitle: SystemName.jobTitle,
  'employee title': SystemName.jobTitle,
  'recommended contact title': SystemName.jobTitle,

  mailingstreet: SystemName.street, // data.com
  'company address': SystemName.street, // salesloft
  'mailing street': SystemName.street,
  street: SystemName.street,
  address: SystemName.street,
  'company street address': SystemName.street,
  'hq address 1': SystemName.street,
  'street 1': SystemName.street,
  'street address': SystemName.street,
  'address line 1': SystemName.street,
  //                'Address Line 2': 'Street',

  mailingstatecode: SystemName.state, // data.com
  'company state': SystemName.state, // salesloft
  'mailing state_province': SystemName.state,
  state: SystemName.state,
  province: SystemName.state,
  'hq state': SystemName.state,
  stateprovince: SystemName.state,
  stateregion: SystemName.state,

  mailingcity: SystemName.city, // data.com
  'company city': SystemName.city, // salesloft
  'mailing city': SystemName.city,
  city: SystemName.city,
  'hq city': SystemName.city,

  mailingpostalcode: SystemName.zip,
  'zip code': SystemName.zip,
  'company postal code': SystemName.zip,
  'mailing zippostal code': SystemName.zip,
  zip: SystemName.zip,
  'postal code': SystemName.zip,
  'company zip code': SystemName.zip,
  'hq postal code': SystemName.zip,
  'company zippostal code': SystemName.zip,
  'zippostal code': SystemName.zip,

  mailingcountry: SystemName.country,
  'company country': SystemName.country,
  'mailing country': SystemName.country,
  country: SystemName.country,
  'hq country': SystemName.country,
  countryregion: SystemName.country,

  'account description': SystemName.description, // inside view
  description: SystemName.description,
  notes: SystemName.description,
  note: SystemName.description,
  'company description': SystemName.description,
  'linkedin profile': SystemName.linkedInUrl, // LinkedInUrl - Netprospex
  'linked public profile url': SystemName.linkedInUrl, // LinkedInUrl - Salesloft
  'employee linkedin url': SystemName.linkedInUrl, // LinkedInUrl - DiscoverOrg
  'twitter profile': SystemName.twitterUrl, // TwitterUrl - Netprospex
  'twitter handle': SystemName.twitterUrl, // TwitterUrl - DiscoverOrg
  'linkedin company url': SystemName.companyLinkedInUrl, // Company LinkedInUrl - Salesloft,
  'company linkedin url': SystemName.companyLinkedInUrl, // Company LinkedInUrl - DiscoverOrg,
  'linkedIn company profile url': SystemName.companyLinkedInUrl,
  'linkedin url': SystemName.companyLinkedInUrl, // Company LinkedInUrl - Salesforce,
  'google url': SystemName.googleUrl,
  'facebook url': SystemName.facebookUrl,

  donotcall: SystemName.doNotCall,
  'do not call': SystemName.doNotCall,
  donotemail: SystemName.doNotEmail,
  'do not email': SystemName.doNotEmail,

  'headquarter phone': SystemName.hqPhone,
  'headquarters phone': SystemName.hqPhone,
  tags: SystemName.tags,
};

export function autoMapField(fieldName: string) {
  return fieldName
    ? AutoMapping[
        fieldName
          .replace(/[^a-zA-Z0-9 ]/g, '')
          .toLowerCase()
          .trim()
      ]
    : null;
}

export const PhoneTypes: any[] = [
  SystemName.phone,
  SystemName.otherPhone,
  SystemName.assistantPhone,
  SystemName.homePhone,
  SystemName.mobilePhone,
  SystemName.hqPhone,
];

export const NumericFields: any[] = [SystemName.annualRevenue, SystemName.numberOfEmployees];
