import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AttachmentChip } from '@zi-common/interface/attachment-chip';
import * as _ from 'lodash';
import { FileDownloaderService } from '@zi-common/service/file-downloader-service/file-downloader.service';
import { Attachments } from '@zi-core/http-model/request/invitations.request.model';

@Component({
  selector: 'app-attachments-container',
  templateUrl: './attachments-container.component.html',
  styleUrls: ['./attachments-container.component.scss'],
})
export class AttachmentsContainerComponent implements OnInit, OnChanges {
  @Input() attachments: Attachments[] = [];
  @Input() hasBorder = true;
  @Input() isPreviewMode = false;
  @Input() adjustAttachmentAlignment = false;
  attachmentsChips: AttachmentChip[] = [];
  constructor(protected fileDownloaderService: FileDownloaderService) {}

  ngOnInit() {
    this.attachmentsChips = this.attachments.map((attach: any) => ({ displayName: attach.fileName, value: attach }));
  }

  onClickAttachment(attachmentChip: AttachmentChip) {
    const attachment: Attachments = attachmentChip.value;
    const url: string = _.get(attachment, 'url');
    const fileName = _.get(attachment, 'fileName');
    this.fileDownloaderService.downloadFileFromServer(url, fileName);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.attachmentsChips = this.attachments.map((attach: Attachments) => ({ displayName: attach.fileName, value: attach }));
  }
}
