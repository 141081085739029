import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Task } from '@zi-core/data-model/task.model';
import { Contact } from '@app/core/data-model/contact.model';
import { ContactTaskDashboardStat } from '@zi-common/interface/contactTaskDashboardStat';
import { SearchByFilterLeafsRequest } from '@app/core/http-model/request/engage-filter.request.model';
import { Invitation } from '@app/core/data-model/invitation.model';

/**
 *  Contact DataService for CRUD operation on entity
 */
@Injectable()
export class TaskInternalDataService {
  constructor(private httpClient: HttpClient) {}

  // Dates in 'YYYY-MM-DD' format
  dashboardTasksByTime(fromDate: string, toDate: string): Observable<ContactTaskDashboardStat> {
    const params = { from: fromDate, to: toDate };
    return this.httpClient.post<ContactTaskDashboardStat>(`${environment.backend_url}/v2/contacttasks/dashboard_stats`, params);
  }

  createNewTask(userId: number, contacts: Contact[], task: any): Observable<Task[]> {
    const body = {
      Contacts: contacts,
      ...task,
    };
    return this.httpClient.post<any>(`${environment.backend_url}/v2/contacttasks/${userId}/add`, body).pipe(
      map((res: { tasks: Task[] }) => {
        return res.tasks || [];
      }),
    );
  }

  editTask(userId: number, task: Task): Observable<object[]> {
    return this.httpClient.post<any>(`${environment.backend_url}/v2/contacttasks/${userId}/update`, task).pipe(
      map((res: { tasks: Array<Task> }) => {
        return res.tasks || [];
      }),
    );
  }

  updateTasksStatus(tasksIds: Array<number>, status: string): Observable<Task[]> {
    const params = { ContactTaskIds: tasksIds, Status: status };
    return this.httpClient.post<any>(`${environment.backend_url}/v2/contacttasks/bulkupdate`, params).pipe(
      map((res: { tasks: Array<Task> }) => {
        return res.tasks || [];
      }),
    );
  }

  getFilterResults<T>(resource: SearchByFilterLeafsRequest): Observable<T> {
    return this.httpClient.post<T>(`${environment.backend_url}/v1/filters`, resource).pipe(map((res) => res));
  }

  loadEmailFromPreviousTaskInSalesflow(taskId: number): Observable<Invitation> {
    return this.httpClient.get<Invitation>(`${environment.backend_url}/v2/contacttasks/previous_email/${taskId}`).pipe(map((res) => res));
  }

  /**
   * Maps server response to client's Task model
   * @param task - the response to map
   * @returns mapped Task
   */
  public mapTask(task: Task): Task {
    if (task?.isOutOfOffice) {
      task.status = 'PausedOOO';
      return task;
    }
    return task;
  }
}
