<div class="attach-container" [ngClass]="{'has-grid': !adjustAttachmentAlignment}">
  <div *ngIf="hasBorder" class="header" [automationId]="'attachments-header'">Attachments</div>
  <div class="labels-container" [automationId]="'attachments-container'" [ngClass]="{'has-border': hasBorder, 'no-padding-left': adjustAttachmentAlignment}">
    <ng-container *ngFor="let attachment of attachmentsChips">
      <app-attachment-chip
        class="chip"
        [attachInfo]="attachment"
        (clicked)="onClickAttachment($event)"
        [automationId]="'attachment-chip'"
        [isPreviewMode]="isPreviewMode"
      ></app-attachment-chip>
    </ng-container>
  </div>
</div>
