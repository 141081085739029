<div class="img-container">
  <img
    [src]="companyImageSrc"
    [matTooltip]="tooltipText"
    matTooltipPosition="above"
    matTooltipClass="engage-tooltip"
    [matTooltipDisabled]="!tooltipText"
    (error)="updateCompanyImageToDefault()"
  />
</div>
