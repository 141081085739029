import { SelectComponentObject } from '@app/common/model/dropdown/select.model';
import { FilterSort } from '@app/core/enums/engage-filter.enum';
import { Tab } from '@zi-common/component/tabs/tabs.config';

export interface OptionInterface {
  displayName: string;
  value: any;
  checked?: boolean;
  isEnabled?: boolean;
}

export enum EngageActionTypeV2 {
  Email = 'Email',
  Phone = 'Phone',
  EmailReply = 'Reply',
}

export const EngageActionTypeV2Content = {
  [EngageActionTypeV2.Email]: 'email',
  [EngageActionTypeV2.Phone]: 'call',
  [EngageActionTypeV2.EmailReply]: 'email',
};

export enum AutoDialPhoneTypes {
  Direct = 'Direct',
  MobilePhone = 'Mobile Phone',
  HomePhone = 'Home Phone',
  Other = 'Other',
  AssistantPhone = 'Assistant Phone',
  HQPhone = 'HQ Phone',
}

export enum AutoDialPhoneTypesToPhoneTypeEnum {
  'Direct' = '1',
  'Mobile Phone' = '2',
  'Other' = '3',
  'HQ Phone' = '5',
  'Home Phone' = '6',
  'Assistant Phone' = '4',
}

export enum AutoDialNoAnswer {
  TryAll = 'Try all the selected numbers available',
  Skip = 'Skip to next contact',
}

export const DefaultEngageActionType = EngageActionTypeV2.Email;

export const EngageActionOptions: OptionInterface[] = [
  { displayName: 'Email', value: EngageActionTypeV2.Email },
  { displayName: 'Call', value: EngageActionTypeV2.Phone },
];

export const AutoDialIntervalOptions: OptionInterface[] = [
  { displayName: '0 Seconds', isEnabled: true, value: 0 },
  { displayName: '5 Seconds', isEnabled: true, value: 5 },
  { displayName: '10 Seconds', isEnabled: true, value: 10 },
  { displayName: '15 Seconds', isEnabled: true, value: 15 },
  { displayName: '20 Seconds', isEnabled: true, value: 20 },
  { displayName: '30 Seconds', isEnabled: true, value: 30 },
];

export const AutoDialPhoneTypeOptions: OptionInterface[] = [
  { displayName: 'Direct', value: AutoDialPhoneTypes.Direct, checked: false, isEnabled: true },
  { displayName: 'Mobile', value: AutoDialPhoneTypes.MobilePhone, checked: false, isEnabled: true },
  { displayName: 'Home', value: AutoDialPhoneTypes.HomePhone, checked: false, isEnabled: true },
  { displayName: 'Other', value: AutoDialPhoneTypes.Other, checked: false, isEnabled: true },
  { displayName: 'Assistant', value: AutoDialPhoneTypes.AssistantPhone, checked: false, isEnabled: true },
  { displayName: 'HQ', value: AutoDialPhoneTypes.HQPhone, checked: false, isEnabled: true },
];

export const EngageModeTabs: Array<Tab> = [
  {
    displayName: 'Email',
    value: EngageActionTypeV2.Email,
    isDisabled: false,
    icon: 'icon-email',
  },
  {
    displayName: 'Call',
    value: EngageActionTypeV2.Phone,
    isDisabled: false,
    icon: 'icon-call',
  },
];

export const MULTISORT_OPTIONS = [
  new SelectComponentObject('Account', true, false, '', FilterSort.Company),
  new SelectComponentObject('Local Time', true, false, '', FilterSort.LocalTime),
  new SelectComponentObject('Contact Name', true, false, '', FilterSort.Name),
];

export enum EngageModeEntityStateCategory {
  Awaiting = 'Awaiting',
  Done = 'Completed',
  Skipped = 'Skipped',
}

export const CATEGORIES_OPTIONS = [
  new SelectComponentObject('Awaiting', true, false, '', EngageModeEntityStateCategory.Awaiting),
  new SelectComponentObject('Completed', true, false, '', EngageModeEntityStateCategory.Done),
  new SelectComponentObject('Skipped', true, false, '', EngageModeEntityStateCategory.Skipped),
];
