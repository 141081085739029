import { InjectionToken } from '@angular/core';
import { ILoggerService } from '../interface/logger.service.interface';
import { BrowserLoggerService } from '@zi-core/service/logger/browser-logger.service';

export const LoggerServiceToken = new InjectionToken<ILoggerService>('Logger service token');

export const LoggerServiceProviderToken = {
  provide: LoggerServiceToken,
  useFactory: () => new BrowserLoggerService(),
  deps: [],
};
