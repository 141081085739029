import { Task } from '@app/core/data-model/task.model';
import { createAction, props } from '@ngrx/store';

// select one
export const SelectOneAction = createAction('[Task Selector] Select one', props<{ task: Task }>());

// select multiple
export const SelectMultipleAction = createAction('[Task Selector] Select multiple', props<{ tasks: Array<Task> }>());

export const UpdateSelectedTasks = createAction('[Task Selector] Update Selected Tasks', props<{ tasks: Array<Task> }>());
// unselect one
export const UnSelectOneAction = createAction('[Task Selector] UnSelect one', props<{ task: Task }>());

// unselect multiple
export const UnSelectMultipleAction = createAction('[Task Selector] UnSelect multiple', props<{ tasks: Array<Task> }>());

// clear all selection
export const ClearSelectionAction = createAction('[Task Selector] Clear selection');

// clear all selection
export const SelectAllAction = createAction('[Task Selector] Select All');

// clear all success selection
export const SelectAllSuccessAction = createAction('[Task Selector] Select All Success', props<{ tasks: Task[] }>());
