import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { FilterCategory, FilterEntity, Scope } from '../enums/engage-filter.enum';
import { FilterNode } from '../data-model/filter-node.model';
import { SearchByFilterLeafsRequest } from '../http-model/request/engage-filter.request.model';
import { excludeAllFiltersMap } from '@zi-pages/contact/service/contact-internal-data.config';

@Injectable({
  providedIn: 'root',
})
export class EngageFilterService {
  // mock url
  private filterBaseUrl = `${environment.backend_url}/v1/filters`;

  constructor(private httpClient: HttpClient) {}

  // public methods
  getNodes(entity: FilterEntity, category: FilterCategory, scope: Scope, parentId?: number): Observable<any[]> {
    const filterByParentId = parentId ? `?parentId=${parentId}` : '';
    return this.httpClient.get<FilterNode[]>(`${this.filterBaseUrl}/${entity}/${category}/${scope}${filterByParentId}`).pipe(map((res) => res));
  }

  getFilterResults<T>(resource: SearchByFilterLeafsRequest): Observable<T> {
    return this.httpClient.post<T>(`${this.filterBaseUrl}`, resource).pipe(map((res) => res));
  }

  getFilteredNodes(entity: FilterEntity, category: FilterCategory, scope: Scope, field: string, search: string, parentId?: number): Observable<FilterNode[]> {
    const filterByParentId = parentId ? `&parentId=${parentId}` : '';
    return this.httpClient.get<FilterNode[]>(`${this.filterBaseUrl}/${entity}/${category}/${scope}?fieldName=${field}&fieldValue=${search}${filterByParentId}`);
  }

  getValueForExcludeAllFilter(filterKey) {
    return excludeAllFiltersMap[filterKey];
  }
}
