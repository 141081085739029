import { ApplicationState } from '@app/reducers';
import { createSelector } from '@ngrx/store';
import { AuthenticatedToken, RulesEngineToken, TwilioClientToken } from '@zi-core/http-model/response/authenticated-token.response.model';
import { Profile } from '@zi-core/data-model/profile.model';
import * as _ from 'lodash';
import { IntegrationNamesMap } from '@zi-core/enums/integration-names-map.enum';
import { ZoomUser } from '@app/core/data-model/zoom-user.model';
import { ConfigurationCRM } from '@app/core/http-model/response/crm.model';
import { WebsiteDomain } from '@app/core/data-model/dozi-integration/dozi-website-domain.response.model';
import { LoginRememberMe } from '@zi-core/interface/login-remember-me';
import { Organization, Quota } from '@app/core/http-model/response/organization.model';
import { defaultLoadingState } from '@zi-core/ngrx/config/ngrx-loading-states.config';
import { EngageVersionEnum } from '@zi-core/enums/engage-version.enum';
import { UserLicensesModel } from '@zi-core/interface/user-licenses';
import { UserOrganization } from '@zi-core/data-model/user-organization.model';
import { TimeHelperService } from '@zi-common/service/time-helper.service';
import { getExtensionMode } from '@app/extensionMode.config';

export interface AuthenticatedTokenState {
  authenticatedToken: AuthenticatedToken;
  rulesEngineToken: RulesEngineToken;
  twilioClientToken: TwilioClientToken;
  isOrgAdmin: boolean;
  rememberMeData: LoginRememberMe;
  profile: Profile;
  zoomUser: ZoomUser;
  zoomConnected: boolean;
  connectedServices: IntegrationNamesMap[];
  configurationCRM: ConfigurationCRM;
  multiFactorAuthJwt: string;
  websiteDomains: Array<WebsiteDomain>;
  userLicenses: UserLicensesModel;
  websiteDomainsLoaded: boolean;
  websiteDomainsLoading: boolean;
  isDemoOrg: boolean;
  org: Organization;
  teamId?: number;
}

export const initialAuthenticatedTokenState: AuthenticatedTokenState = {
  authenticatedToken: null,
  rulesEngineToken: null,
  twilioClientToken: {
    token: undefined,
    ...defaultLoadingState,
  },
  isOrgAdmin: false,
  rememberMeData: null,
  profile: null,
  zoomUser: null,
  zoomConnected: false,
  connectedServices: null,
  configurationCRM: null,
  multiFactorAuthJwt: null,
  websiteDomains: [],
  userLicenses: {
    licenses: null,
    ...defaultLoadingState,
  },
  websiteDomainsLoaded: false,
  websiteDomainsLoading: false,
  isDemoOrg: false,
  org: null,
};

/**
 * Returns the auth state.
 */
export const getAuthState = (state: ApplicationState) => state.auth;

/**
 * Returns true if the user is authenticated
 */
export const isAuthenticated = createSelector(getAuthState, (state: AuthenticatedTokenState) => state.authenticatedToken !== null);

/**
 * Returns true if voip is enabled
 */
export const isVoipEnabledSelector = createSelector(getAuthState, (state: AuthenticatedTokenState) =>
  state.authenticatedToken ? state.authenticatedToken.voipDialerEnabled : false,
);

/**
 * Returns true if SMS is enabled
 */
export const isSmsEnabledSelector = createSelector(getAuthState, (state: AuthenticatedTokenState) =>
  // Will always be false if extension.
  state.authenticatedToken ? state.authenticatedToken.textMessagingEnabled && !getExtensionMode() : false,
);

/**
 * Returns the auth token if it exists in the store
 */
export const getValidAuthToken = createSelector(getAuthState, (state: AuthenticatedTokenState) => state.authenticatedToken);

/**
 * Returns partial jwt used for MFA
 */
export const getMultiFactorAuthJwt = createSelector(getAuthState, (state: AuthenticatedTokenState) => state.multiFactorAuthJwt);

/**
 * Returns the rules engine token if it exists in the store
 */
export const getValidRulesToken = createSelector(getAuthState, (state: AuthenticatedTokenState) => state.rulesEngineToken);

/**
 * Returns the SignIn Error if it exists in the store
 */
export const getRememberMeStatus = createSelector(getAuthState, (state: AuthenticatedTokenState) => state.rememberMeData);

/**
 * Returns the user license object
 */
export const getUserLicenses = createSelector(getAuthState, (state: AuthenticatedTokenState) => _.get(state, 'userLicenses'));

export const salesflowOrTalentflow = createSelector(getUserLicenses, (licenseState: UserLicensesModel) =>
  _.get(licenseState, 'licenses.recruiter') ? 'Talentflow' : 'Salesflow',
);

/**
 * Returns if the user is connected to Zoominfo or not
 */
export const isConnectedToZoominfo = createSelector(getAuthState, (state: AuthenticatedTokenState) => state.zoomConnected);

/**
 * Returns zoom customer salesforce id
 */
export const getZoomCustomerSalesforceId = createSelector(getValidAuthToken, (tokenState: AuthenticatedToken) => _.get(tokenState, 'zoomCustSalesforceId'));

/**
 * Returns if the authenticated user is an admin (lower level or super admin)
 */
export const isUserAdmin = createSelector(getAuthState, (state: AuthenticatedTokenState) => {
  return state.isOrgAdmin;
});

/**
 * Returns if the user is connected to specific service
 */
export const isConnectedToService = (service: IntegrationNamesMap) =>
  createSelector(getAuthState, (state: AuthenticatedTokenState) => (_.get(state, 'connectedServices', []) || []).includes(service));

/**
 * Returns if the user is connected to any list of services
 */
export const isConnectedToServices = (services: string[]) =>
  createSelector(getAuthState, (state: AuthenticatedTokenState) => (_.get(state, 'connectedServices', []) || []).some((service) => services.includes(service)));

/**
 * Returns connected services
 */
export const connectedServicesList = createSelector(getAuthState, (state: AuthenticatedTokenState) => state.connectedServices || []);

/**
 * Gets a map of all available crm know to engage and its connection status with org
 */
export const getAllCrmConnectionStatusMap = createSelector(getAuthState, (state: AuthenticatedTokenState) => {
  const connectedServices: IntegrationNamesMap[] = state?.connectedServices || [];
  return {
    [IntegrationNamesMap.Salesforce]: connectedServices.includes(IntegrationNamesMap.Salesforce),
    [IntegrationNamesMap.UnieSalesforce]: connectedServices.includes(IntegrationNamesMap.UnieSalesforce),
    [IntegrationNamesMap.Hubspot]: connectedServices.includes(IntegrationNamesMap.Hubspot),
    [IntegrationNamesMap.Dynamics]: connectedServices.includes(IntegrationNamesMap.Dynamics),
  };
});

/**
 * Returns Salesforce CRM admin settings for sync
 */
export const getConfigurationCRM = createSelector(getAuthState, (state: AuthenticatedTokenState) => state.configurationCRM);

/**
 * Returns websiteDomains based on ZI logged in user
 */
export const getWebsiteDomains = createSelector(getAuthState, (state: AuthenticatedTokenState) => state.websiteDomains || []);

/**
 * Returns websiteDomainIds based on ZI logged in user
 */
export const getWebsiteDomainIds = createSelector(getAuthState, (state: AuthenticatedTokenState) => state.websiteDomains.map(({ _id }) => _id) || []);

export const getWebsiteDomainsLoaded = createSelector(getAuthState, (state: AuthenticatedTokenState) => state.websiteDomainsLoaded);

/**
 * Returns the profile
 */
export const getProfile = createSelector(getAuthState, (state: AuthenticatedTokenState) => state.profile);

export const getOrganization = createSelector(getAuthState, (state: AuthenticatedTokenState) => _.get(state, 'authenticatedToken.organizations[0]'));

export const getUserPrivilege = createSelector(getOrganization, (org: UserOrganization) => _.get(org, 'privileges'));

export const getOrganizationId = createSelector(getAuthState, (state: AuthenticatedTokenState) => _.get(state, 'authenticatedToken.orgId'));

export const getTimeZone = createSelector(getAuthState, (state: AuthenticatedTokenState) =>
  _.get(state.profile, 'attributes.timeZoneId', 'America/Los_Angeles'),
);

export const getTimeZoneCode = createSelector(getAuthState, (state: AuthenticatedTokenState) => {
  const time = _.get(state.profile, 'attributes.timeZoneId', 'America/Los_Angeles');
  return TimeHelperService.getTimeZone(time);
});

export const getOrg = createSelector(getAuthState, (state: AuthenticatedTokenState) => state.org);

export const getOrgQuota = createSelector(getOrg, (state: Organization) => {
  return _.get(state, 'quota');
});

export const getWaitingRoomLimit = createSelector(getOrgQuota, (state: Quota) => {
  return _.get(state, 'salesWorkflowWaitingRoomLimit');
});

export const getInProgressLimit = createSelector(getOrgQuota, (state: Quota) => {
  return _.get(state, 'salesWorkflowInProgressLimit');
});

export const getOrgCrmConnected = createSelector(getAuthState, (state: AuthenticatedTokenState) => _.get(state, 'org.crmIdProviderType'));

export const getTimeZoneTooltipText = createSelector(getAuthState, (state: AuthenticatedTokenState) => {
  const timezone = _.get(state.profile, 'attributes.timeZoneId', 'America/Los_Angeles');
  return timezone.replace(/[_-]/g, ' ');
});

export const getZoomRoles = createSelector(getAuthState, (state: AuthenticatedTokenState) => {
  const zoomRoles = _.get(state, 'zoomUser.zoom_role', null);
  return zoomRoles;
});

export const getZoomConnected = createSelector(getAuthState, (state: AuthenticatedTokenState) => {
  return state.zoomConnected;
});

/**
 * Get current userId
 */
export const getCurrentUserId = createSelector(getProfile, (state: Profile) => state?.userId);

/**
 * Returns if the authenticated user is in demo org
 */
export const isDemoOrg = createSelector(getAuthState, (state: AuthenticatedTokenState) => {
  const orgId = _.get(state.authenticatedToken, 'orgId');
  return (
    orgId === 916558068605066 || // production demo org
    orgId === 807934485163480 || // staging demo org
    orgId === 822681382164131 || // automation org
    orgId === 927520740180027
  ); // automation production
});

/**
 * Returns if the authenticated user is in stage & prod demo org
 */
export const isPlatformSwitcherDemoOrg = createSelector(getAuthState, (state: AuthenticatedTokenState) => {
  const orgId = _.get(state.authenticatedToken, 'orgId');
  return orgId === 916558068605066; // production demo org
});

export const getTwilioClientToken = createSelector(getAuthState, (state: AuthenticatedTokenState) => {
  return state.twilioClientToken;
});

export const isUserLicensesLoaded = createSelector(getUserLicenses, (licensesState: UserLicensesModel) => _.get(licensesState, 'loaded'));

/**
 * Get exclusive user role.
 * Default role is Sales
 */
export const getExclusiveUserRole = createSelector(getUserLicenses, (licensesState: UserLicensesModel) => {
  if (!!_.get(licensesState, 'licenses.recruiter')) {
    return EngageVersionEnum.RECRUITER;
  }
  return EngageVersionEnum.SALES;
});

export const getUserPermissionsFromAuthToken = createSelector(getValidAuthToken, (state) => state?.permissions);
