import { Pipe, PipeTransform } from '@angular/core';
import { EngageVersionStringTransformService } from '@zi-common/service/engage-version-string-transform/engage-version-string-transform.service';
import { EngageVersionEnum } from '@zi-core/enums/engage-version.enum';

/**
 * Pipe to modify input to an app component.
 * For simple text transformation use directive 'appStringTransform'.
 *
 * Example usage:
 * <i class="icon-info1 info-icon"
 *    [matTooltip]="'Allow starting Salesflows on behalf of others' | stringTransform : (stringTransformService.userRole$ | async) : '-'"
 * ></div>
 */
@Pipe({
  name: 'stringTransform',
})
export class EngageVersionStringTransformPipe implements PipeTransform {
  constructor(private transformService: EngageVersionStringTransformService) {}

  transform(text: string, userRole: EngageVersionEnum, loadingText: string = ''): any {
    return userRole ? this.transformService.getTransformedText(userRole, text) : loadingText;
  }
}
