import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable()
export class SeoService {
  constructor(private titleService: Title, private metaService: Meta) {}

  updateTitle(title: string) {
    this.titleService.setTitle(title);
  }

  getTitle() {
    return this.titleService.getTitle();
  }

  updateMetaDescription(metaDesc: string) {
    this.metaService.updateTag({ name: 'description', content: metaDesc });
  }

  getMetaDescription() {
    return this.metaService.getTag("name='description'");
  }

  removeMetaDescription() {
    this.metaService.removeTag("name='description'");
  }
}
