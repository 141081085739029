import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ApplicationState } from '@app/reducers';
import { getMultiFactorAuthJwt } from '@zi-core/ngrx/state/auth.state';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MultiFactorAuthJwtGuard implements CanActivate {
  constructor(private store: Store<ApplicationState>) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    return this.store.select(getMultiFactorAuthJwt).pipe(
      take(1),
      map((multiFactorAuthJwt) => {
        return multiFactorAuthJwt ? true : false;
      }),
    );
  }
}
