<app-dialog-template [dialogTitle]="title" (close)="onCancel()">
  <app-dialog-content>
    <div class="content">
      <div *ngIf="subHeader" class="sub-header">{{subHeader}}</div>
      <div *ngFor="let option of checkBoxOptions" class="option">
        <app-checkbox
          class="checkbox"
          *ngIf="option?.displayText !== 'SMS'"
          [disabled]="option?.disabled"
          (clicked)="onOptionToggle(option)"
          [checked]="option.checked"
          [automationId]="'checkbox-'+option?.displayText?.toLowerCase()"
        ></app-checkbox>
        <app-checkbox
          class="checkbox"
          *ngIf="option?.displayText === 'SMS' && (isSmsRegistered$ | async)"
          [matTooltip]="smsDisabledTooltip"
          matTooltipClass="engage-tooltip"
          [matTooltipPosition]="'above'"
          [disabled]="option?.disabled || contact?.optOutSms"
          (clicked)="onOptionToggle(option)"
          [checked]="option.checked"
          [automationId]="'checkbox-'+option?.displayText?.toLowerCase()"
        ></app-checkbox>
        <div *ngIf="option?.displayText !== 'SMS' || (isSmsRegistered$ | async)">{{option.displayText}}</div>
      </div>
    </div>
  </app-dialog-content>

  <app-dialog-footer>
    <app-primary-btn class="ok-button" [text]="primaryButtonText" [disabled]="!isSaveButtonEnabled" (clickButton)="onSend()"> </app-primary-btn>
    <app-secondary-btn [text]="secondaryButtonText" (clickButton)="onCancel()"> </app-secondary-btn>
  </app-dialog-footer>
</app-dialog-template>
