export enum SystemName {
  email = 'email',
  firstName = 'firstName',
  lastName = 'lastName',
  phone = 'phone',
  salutation = 'salutation',
  company = 'company',
  jobTitle = 'Job Title',
  department = 'department',
  doNotEmail = 'doNotEmail',
  doNotCall = 'doNotCall',
  doNotSms = 'doNotSms',
  optOutSms = 'optOutSms',
  description = 'description',
  industry = 'industry',
  annualRevenue = 'annualRevenue',
  numberOfEmployees = 'numberOfEmployees',
  companyPageUrl = 'companyPageUrl',
  companyGoogleUrl = 'companyGoogleUrl',
  companyFacebookUrl = 'companyFacebookUrl',
  companyLinkedInUrl = 'companyLinkedInUrl',
  companyTwitterUrl = 'companyTwitterUrl',
  street = 'street',
  city = 'city',
  state = 'state',
  zip = 'zip',
  country = 'country',
  otherPhone = 'otherPhone',
  mobilePhone = 'mobilePhone',
  homePhone = 'homePhone',
  assistantPhone = 'assistantPhone',
  hQPhone = 'hQPhone',
  facebookUrl = 'facebookUrl',
  googleUrl = 'googleUrl',
  linkedInUrl = 'linkedInUrl',
  twitterUrl = 'twitterUrl',
  unsubscribed = 'unsubscribed',
  leadStatus = 'leadStatus',
  personalEmail = 'personalEmail',
  status = 'status',
}

export const SYSTEM_NAME_TO_ENGAGE_FIELD_NAME = {
  [SystemName.email]: 'Email',
  [SystemName.firstName]: 'First Name',
  [SystemName.lastName]: 'Last Name',
  [SystemName.phone]: 'Direct Phone',
  [SystemName.salutation]: 'Salutation',
  [SystemName.company]: 'Company',
  [SystemName.jobTitle]: 'Job Title',
  [SystemName.department]: 'Department',
  [SystemName.doNotEmail]: 'Do Not Email',
  [SystemName.doNotCall]: 'Do Not Call',
  [SystemName.doNotSms]: 'Do Not SMS',
  [SystemName.description]: 'Description',
  [SystemName.industry]: 'Industry',
  [SystemName.annualRevenue]: 'Annual Revenue',
  [SystemName.numberOfEmployees]: 'Number Of Employees',
  [SystemName.companyPageUrl]: 'Company Website',
  [SystemName.companyGoogleUrl]: 'Company Google',
  [SystemName.companyFacebookUrl]: 'Company Facebook',
  [SystemName.companyLinkedInUrl]: 'Company LinkedIn',
  [SystemName.companyTwitterUrl]: 'Company Twitter',
  [SystemName.street]: 'Street',
  [SystemName.city]: 'City',
  [SystemName.state]: 'Province/State',
  [SystemName.zip]: 'Postal/Zip',
  [SystemName.country]: 'Country',
  [SystemName.otherPhone]: 'Other Phone',
  [SystemName.mobilePhone]: 'Mobile Phone',
  [SystemName.homePhone]: 'Home Phone',
  [SystemName.assistantPhone]: 'Assistant Phone',
  [SystemName.hQPhone]: 'Headquarter Phone',
  [SystemName.facebookUrl]: 'Facebook URL',
  [SystemName.googleUrl]: 'Google URL',
  [SystemName.linkedInUrl]: 'LinkedIn URL',
  [SystemName.twitterUrl]: 'Twitter',
  [SystemName.personalEmail]: 'Personal Email',
  [SystemName.status]: 'Status',
};

const AutoMapping = {
  first: SystemName.firstName,
  'first name': SystemName.firstName,
  firstname: SystemName.firstName,
  first_name: SystemName.firstName,
  'employee first name': SystemName.firstName,
  last: SystemName.lastName,
  'last name': SystemName.lastName,
  lastname: SystemName.lastName,
  last_name: SystemName.lastName,
  'employee last name': SystemName.lastName,
  salutation: SystemName.salutation,
  'company phone': SystemName.phone, // WorkPhone - Netprospex
  'employer telephone': SystemName.phone, // WorkPhone
  'employer phone': SystemName.phone, // WorkPhone
  employer_phone: SystemName.phone, // WorkPhone
  'business telephone': SystemName.phone, // WorkPhone
  'business phone': SystemName.phone, // WorkPhone
  phone: SystemName.phone, // WorkPhone
  telephone: SystemName.phone, // WorkPhone
  tel: SystemName.phone, // WorkPhone
  'tel.': SystemName.phone, // WorkPhone
  'tel number': SystemName.phone, // WorkPhone
  'tel. number': SystemName.phone, // WorkPhone
  'telephone number': SystemName.phone, // WorkPhone
  'phone number': SystemName.phone, // WorkPhone
  'direct phone number': SystemName.phone,
  'direct phone': SystemName.phone,
  'tel #': SystemName.phone, // WorkPhone
  'tel. #': SystemName.phone, // WorkPhone
  'telephone #': SystemName.phone, // WorkPhone
  'phone #': SystemName.phone, // WorkPhone
  'employee direct phone': SystemName.phone, // WorkPhone
  // 'Ext': 'Phone1', //WorkPhone
  //                'Extension': 'Phone1', //WorkPhone
  //                'Ext.': 'Phone1', //WorkPhone

  'phone 1': SystemName.otherPhone, // HomePhone - Netprospex
  cell: SystemName.mobilePhone, // CellPhone
  mobile: SystemName.mobilePhone, // CellPhone
  'phone 2': SystemName.mobilePhone, // CellPhone - Netprospex
  'mobile phone': SystemName.mobilePhone, // CellPhone
  'employee mobile phone': SystemName.mobilePhone, // CellPhone
  'mobile phone number': SystemName.mobilePhone, // CellPhone
  homephone: SystemName.homePhone,
  'other phone': SystemName.homePhone, // Salesforce
  assistantphone: SystemName.assistantPhone,
  hqphone: SystemName.hQPhone,
  'company hq phone': SystemName.hQPhone,
  'company phone number': SystemName.hQPhone,

  email: SystemName.email, // WorkEmail
  'work email': SystemName.email, // WorkEmail
  e_mail: SystemName.email, // WorkEmail
  'email address': SystemName.email, // WorkEmail
  email_address: SystemName.email, // WorkEmail
  emailaddress: SystemName.email, // WorkEmail
  'e_mail address': SystemName.email, // WorkEmail
  email_s_: SystemName.email, // WorkEmail - Inside view
  'employee work email': SystemName.email, // WorkEmail

  'employee personal email': SystemName.personalEmail,
  'secondary email': SystemName.personalEmail,
  'supplemental email': SystemName.personalEmail,

  company: SystemName.company,
  'company name': SystemName.company,
  companyname: SystemName.company,
  'employer name': SystemName.company,
  employer_name: SystemName.company,
  accountname: SystemName.company, // data.com
  'account name': SystemName.company, // inside view
  'company _ account': SystemName.company,
  'common organization name': SystemName.company, // Netprospex
  industry: SystemName.industry,
  'primary industry': SystemName.industry,
  'company primary industry': SystemName.industry,
  'industry label': SystemName.industry,
  dept: SystemName.department,
  department: SystemName.department,
  'employee departments': SystemName.department,
  'job function': SystemName.department,
  revenue: SystemName.annualRevenue,
  'annual revenue': SystemName.annualRevenue,
  'revenue _in 000s_': SystemName.annualRevenue,
  'company revenue': SystemName.annualRevenue,
  website: SystemName.companyPageUrl,
  'company website': SystemName.companyPageUrl,
  'website url': SystemName.companyPageUrl,
  '# of employees': SystemName.numberOfEmployees,
  employees: SystemName.numberOfEmployees,
  'employee count': SystemName.numberOfEmployees,
  'number of employees': SystemName.numberOfEmployees,
  'no_ of employees': SystemName.numberOfEmployees,

  title: SystemName.jobTitle,
  'job title': SystemName.jobTitle,
  jobtitle: SystemName.jobTitle,
  'job description': SystemName.jobTitle,
  'professional title': SystemName.jobTitle,
  professional_title: SystemName.jobTitle,
  'employee title': SystemName.jobTitle,

  mailingstreet: SystemName.street, // data.com
  'company address': SystemName.street, // salesloft
  'mailing street': SystemName.street,
  street: SystemName.street,
  address: SystemName.street,
  'company street address': SystemName.street,
  'hq address 1': SystemName.street,
  'street 1': SystemName.street,
  'street address': SystemName.street,
  'address line 1': SystemName.street,
  //                'Address Line 2': 'Street',

  mailingstatecode: SystemName.state, // data.com
  'company state': SystemName.state, // salesloft
  'mailing state_province': SystemName.state,
  state: SystemName.state,
  province: SystemName.state,
  'hq state': SystemName.state,
  state_province: SystemName.state,
  state_region: SystemName.state,

  mailingcity: SystemName.city, // data.com
  'company city': SystemName.city, // salesloft
  'mailing city': SystemName.city,
  city: SystemName.city,
  'hq city': SystemName.city,

  mailingpostalcode: SystemName.zip,
  'zip code': SystemName.zip,
  'company postal code': SystemName.zip,
  'mailing zip_postal code': SystemName.zip,
  zip: SystemName.zip,
  'postal code': SystemName.zip,
  'company zip code': SystemName.zip,
  'hq postal code': SystemName.zip,
  'company zip_postal code': SystemName.zip,
  'zip_postal code': SystemName.zip,

  mailingcountry: SystemName.country,
  'company country': SystemName.country,
  'mailing country': SystemName.country,
  country: SystemName.country,
  'hq country': SystemName.country,
  country_region: SystemName.country,

  'account description': SystemName.description, // inside view
  description: SystemName.description,
  notes: SystemName.description,
  note: SystemName.description,
  'company description': SystemName.description,
  'linkedin profile': SystemName.linkedInUrl, // LinkedInUrl - Netprospex
  'linked public profile url': SystemName.linkedInUrl, // LinkedInUrl - Salesloft
  'employee linkedin url': SystemName.linkedInUrl, // LinkedInUrl - DiscoverOrg
  'twitter profile': SystemName.twitterUrl, // TwitterUrl - Netprospex
  'twitter handle': SystemName.twitterUrl, // TwitterUrl - DiscoverOrg
  'linkedin company url': SystemName.companyLinkedInUrl, // Company LinkedInUrl - Salesloft,
  'company linkedin url': SystemName.companyLinkedInUrl, // Company LinkedInUrl - DiscoverOrg,
  'linkedin url': SystemName.companyLinkedInUrl, // Company LinkedInUrl - Salesforce,

  donotcall: SystemName.doNotCall,
  'do not call': SystemName.doNotCall,
  donotemail: SystemName.doNotEmail,
  'do not email': SystemName.doNotEmail,
  donotsms: SystemName.doNotSms,
  'do not sms': SystemName.doNotSms,

  status: SystemName.status,
};

export function autoMapField(fieldName: string) {
  return fieldName ? AutoMapping[fieldName.toLowerCase().trim()] : null;
}
