import { Action, createReducer, on } from '@ngrx/store';
import {
  loadImportContactLists,
  loadImportContactListsFailure,
  loadImportContactListsSuccess,
} from '@zi-pages/contact/ngrx/action/import-contact-list.actions';
import { ImportListsState, InitialContactListImportEmptyState } from '@zi-pages/contact/ngrx/state/import-contact-list.state';

export const importContactListFeatureKey = 'importContactList';

const importContactListReducer = createReducer(
  { ...InitialContactListImportEmptyState },
  on(loadImportContactLists, (state: ImportListsState) => {
    return {
      ...state,
      imports: [],
    };
  }),

  on(loadImportContactListsSuccess, (state: ImportListsState, { imports }) => {
    return {
      ...state,
      imports,
    };
  }),

  on(loadImportContactListsFailure, (state: ImportListsState, { error }) => {
    return {
      ...state,
      imports: [],
      error,
    };
  }),
);

export function contactListImportReducer(state: ImportListsState, action: Action): ImportListsState {
  return importContactListReducer(state, action);
}
