/**
 * NOTE: DO NOT COPY DESIGN/PATTERN.
 *
 * This is a global mutator/accessor for isExtensionMode. This was
 * necessary for Grow Location Strategy to know if the app is being
 * accessed through an extension or through normal means, since the constructor
 * cannot be modified due to the fact that location strategy is initialized
 * in SetupRouter using the new keyword and does not allow for the addition
 * of more arguments.
 */
import { Subject } from 'rxjs';

let isExtensionMode = false;
let isGmailExtensionMode = false;
// For usage in areas that might be initialized before it's set.
export const getExtensionMode$ = new Subject<boolean>();
export const getExtensionMode = (): boolean => {
  return isExtensionMode;
};

export const isGmailExtension = (): boolean => {
  return isGmailExtensionMode;
};

export const turnOnGmailExtensionMode = () => {
  isGmailExtensionMode = true;
};

// Once extension mode is turned on, it should not be turned off.
export const turnOnExtensionMode = () => {
  isExtensionMode = true;
  getExtensionMode$.next(isExtensionMode);
};
