import { createSelector } from '@ngrx/store';
import { ApplicationState } from '@app/reducers';
import { PreviewData } from '@app/gmail-plugin/ngrx/state/preview-data';
import { Contact } from '@zi-core/data-model/contact.model';

export interface GmailPluginState {
  gmailContactCard: any;
  previewData: PreviewData;
  salesflowContacts: Contact[];
}

export const initialGmailPluginState = {
  gmailContactCard: null,
  previewData: null,
  salesflowContacts: null,
};

export const gmailPluginState = (state: ApplicationState) => state.gmailPlugin;
export const gmailContactSelector = createSelector(gmailPluginState, (state: GmailPluginState) => state.gmailContactCard);
export const gmailPreviewDataSelector = createSelector(gmailPluginState, (state: GmailPluginState) => state.previewData);
export const gmailSalesflowContactDataSelector = createSelector(gmailPluginState, (state: GmailPluginState) => state.salesflowContacts);
