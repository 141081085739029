import { Injectable } from '@angular/core';
import {
  filterCategoryTypeEnum,
  GlobalFilterKey,
  LocationGlobalFilterChildKey,
} from '@zi-common/pages-component/global-filters-panel/global-filters-panel.config';
import { FilterEntity, FilterSort, FilterSortOrder, Scope } from '@zi-core/enums/engage-filter.enum';
import { CompanyAccountActionNames } from '@zi-core/enums/company-account-action-names.enum';
import { CompanyAccountDetailsTab } from '@zi-core/enums/company-account.enum';
import { AnalyticsAccountTabSource } from '@zi-core/enums/analytics.enum';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsConfigService {
  constructor() {}

  /**
   * Map all global filter keys including child keys to a specific GlobalFilterKey for analytics
   */
  public readonly analyticsAllGlobalFilterKeyMap = {
    [GlobalFilterKey.NUMBER_OF_EMPLOYEES]: GlobalFilterKey.NUMBER_OF_EMPLOYEES,
    [GlobalFilterKey.ANNUAL_REVENUES]: GlobalFilterKey.ANNUAL_REVENUES,
    [GlobalFilterKey.INDUSTRIES]: GlobalFilterKey.INDUSTRIES,
    [GlobalFilterKey.COMPANIES]: GlobalFilterKey.COMPANIES,
    [GlobalFilterKey.DEPARTMENTS]: GlobalFilterKey.DEPARTMENTS,
    [GlobalFilterKey.SALESFLOW]: GlobalFilterKey.SALESFLOW,
    [GlobalFilterKey.JOB_TITLES]: GlobalFilterKey.JOB_TITLES,
    [GlobalFilterKey.LIST_IDS]: GlobalFilterKey.LIST_IDS,
    [GlobalFilterKey.OWNER_ID]: GlobalFilterKey.OWNER_ID,
    [GlobalFilterKey.TEAM_ID]: GlobalFilterKey.TEAM_ID,
    [GlobalFilterKey.CONTACT_STATUS]: GlobalFilterKey.CONTACT_STATUS,
    [GlobalFilterKey.LOCATION]: GlobalFilterKey.LOCATION,
    [LocationGlobalFilterChildKey.COUNTRIES]: GlobalFilterKey.LOCATION,
    [LocationGlobalFilterChildKey.STATE_OR_PROVINCES]: GlobalFilterKey.LOCATION,
    [LocationGlobalFilterChildKey.CITIES]: GlobalFilterKey.LOCATION,
  };

  public readonly analyticsFilterTitle = {
    [GlobalFilterKey.NUMBER_OF_EMPLOYEES]: 'NumberOfEmployees',
    [GlobalFilterKey.ANNUAL_REVENUES]: 'AnnualRevenue',
    [GlobalFilterKey.INDUSTRIES]: 'Industry',
    [GlobalFilterKey.COMPANIES]: 'Company',
    [GlobalFilterKey.DEPARTMENTS]: 'Department',
    [GlobalFilterKey.JOB_TITLES]: 'Job Title',
    [GlobalFilterKey.CONTACT_STATUS]: 'Contact Status',
    [GlobalFilterKey.OWNER_ID]: 'Contact Owner',
    [GlobalFilterKey.TEAM_ID]: 'Team Owner',
    [GlobalFilterKey.LIST_IDS]: 'Imports',
    [GlobalFilterKey.LOCATION]: 'Location',
  };

  public readonly analyticsFilterCategoryType = {
    [filterCategoryTypeEnum.CHECKBOX_SEARCH]: 'Checkbox',
    [filterCategoryTypeEnum.CHECKBOX_SEARCH_WITHOUT_COUNT]: 'Checkbox',
    [filterCategoryTypeEnum.LOCATION_GROUP]: 'Checkbox',
    [filterCategoryTypeEnum.CONTACT_OWNER]: 'Checkbox',
    [filterCategoryTypeEnum.TEAM_ID]: 'Checkbox',
    [filterCategoryTypeEnum.RANGE]: 'Range',
  };

  public readonly analyticsChildFilterCategoryMap = {
    [LocationGlobalFilterChildKey.COUNTRIES]: 'Country',
    [LocationGlobalFilterChildKey.STATE_OR_PROVINCES]: 'State_OR_Province',
    [LocationGlobalFilterChildKey.CITIES]: 'City',
  };

  public readonly analyticsCompanyAccountScopeMap = {
    [Scope.Organization]: 'AllAccounts',
    [Scope.Owner]: 'MyAccounts',
  };

  public readonly analyticsCompanyAccountActionNamesMap = {
    [CompanyAccountActionNames.ASSOCIATE_CONTACTS]: 'AssociateContacts',
    [CompanyAccountActionNames.EDIT_ACCOUNT]: 'EditAccount',
    [CompanyAccountActionNames.TWITTER]: 'Twitter',
    [CompanyAccountActionNames.WEBSITE]: 'Website',
  };

  public readonly analyticsEngageFilterSortOrderMap = {
    [FilterSortOrder.Ascending]: 'Ascending',
    [FilterSortOrder.Descending]: 'Descending',
  };

  public readonly analyticsAccountDetailsTabMap = {
    [CompanyAccountDetailsTab.ACTIVITIES]: AnalyticsAccountTabSource.ACTIVITIES,
    [CompanyAccountDetailsTab.REPS]: AnalyticsAccountTabSource.REPS,
    [CompanyAccountDetailsTab.SALESFLOWS]: AnalyticsAccountTabSource.SALES_FLOWS,
    [CompanyAccountDetailsTab.NOTES]: AnalyticsAccountTabSource.NOTES,
  };

  public getAnalyticsEngageFilterSortCategory(filterSort: FilterSort, entity: FilterEntity) {
    switch (filterSort) {
      case FilterSort.LastTouched:
        return 'LastTouched';
      case FilterSort.NumberOfContacts:
        return 'NumberOfContacts';
      case FilterSort.Name:
        return entity === FilterEntity.CompanyAccount ? 'AccountName' : '';
      case FilterSort.LastAdded:
        return 'LastAdded';
      case FilterSort.LastUpdated:
        return 'LastUpdated';
    }
  }
}
