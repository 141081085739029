import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  ExtAddBulkContactAction,
  ExtAddBulkContactSuccessAction,
  ExtAddContactAction,
  ExtAddContactFailureAction,
  ExtAddContactSuccessAction,
  ExtGetParsedContactInfoAction,
  ExtGoToNinetyPctAction,
  ExtGoToFiftyPctAction,
  ExtGoToTwentyPctAction,
  ExtLoadingEndAction,
  ExtLoadingStartAction,
  ExtReceiveNewParsedContactIdsInfoAction,
  ExtSetParsedContactInfoSuccessAction,
  ExtGoToTwentyPctSuccessAction,
  ExtNoOpAction,
} from '@app/extension/ngrx/action/extension.action';
import { catchError, flatMap, map, mergeMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { ExtensionIframeMessagingService } from '@app/extension/service/extension-iframe-messaging.service';
import { ExtensionMessages } from '@app/extension/constants/extension-messages.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { ExtensionRedirectionService } from '@app/extension/service/extension-redirection.service';
import { ExtensionMatcherService } from '@app/extension/service/extension-matcher.service';
import { Contact } from '@zi-core/data-model/contact.model';
import { ApplicationState } from '@app/reducers';
import { UrlService } from '@zi-core/service/url.service';
import { from, of } from 'rxjs';
import { ExtensionSize } from '@app/extension/constants/extension-size.enum';
import { getValidAuthToken, isVoipEnabledSelector } from '@zi-core/ngrx/state/auth.state';
import { EngageModeV2Service } from '@zi-pages/engage-mode-v2/service/engage-mode-v2.service';
import { EngageModeStorageV2Service } from '@zi-pages/engage-mode-v2/service/engage-mode-storage-v2.service';
import { EmailModeService } from '@app/pages/email-v2/services/email-mode.service';
import { BridgeDialerService } from '@app/caller/service/bridge-dialer.service';
import { DialerSelectionSelector } from '@zi-pages/account/ngrx/selector/dialer-config.selector';
import { DialerUtilService } from '@app/caller/service/dialer-util.service';
import { VoipDialerService } from '@app/caller/service/voip-dialer.service';
import { NavigationService } from '@zi-core/service/navigation.service';

@Injectable()
export class ExtensionEffects {
  constructor(
    private action$: Actions,
    private extensionIframeMessagingService: ExtensionIframeMessagingService,
    private extensionRedirectionService: ExtensionRedirectionService,
    private extensionMatcherService: ExtensionMatcherService,
    private _appStore: Store<ApplicationState>,
    private _activatedRoute: ActivatedRoute,
    private urlService: UrlService,
    private emailService: EmailModeService,
    private bridgeDialerService: BridgeDialerService,
    private engageModeV2Service: EngageModeV2Service,
    private engageModeStorageV2Service: EngageModeStorageV2Service,
    private dialerUtilService: DialerUtilService,
    private voipDialerService: VoipDialerService,
    private navigationService: NavigationService,
    private _router: Router,
    private _matDialog: MatDialog,
  ) {}

  toTwentyPct$ = createEffect(() =>
    this.action$.pipe(
      ofType(ExtGoToTwentyPctAction),
      withLatestFrom(this._appStore.select(getValidAuthToken), this._appStore.select(DialerSelectionSelector), this._appStore.select(isVoipEnabledSelector)),
      mergeMap(([action, authToken, dialerSelection, isVoipEnabled]) => {
        return this.engageModeV2Service.getEngageModeIsInEngageCall().pipe(
          take(1),
          map((isInEngageCall) => {
            return { action, authToken, dialerSelection, isVoipEnabled, isInEngageCall };
          }),
        );
      }),
      mergeMap(({ action, authToken, dialerSelection, isVoipEnabled, isInEngageCall }) => {
        const isVoip = this.dialerUtilService.checkIsVoip(dialerSelection, isVoipEnabled);
        const serviceToUse = isVoip ? this.voipDialerService : this.bridgeDialerService;
        if (
          action.sizeComingFrom !== ExtensionSize.TWENTY_PCT &&
          action.sizeComingFrom !== ExtensionSize.MINIMIZED &&
          !(this.engageModeV2Service.getEngageModeState() && (serviceToUse.checkIfSesionActive() || isInEngageCall))
        ) {
          if (authToken) {
            this.extensionIframeMessagingService.sendMessage(ExtensionMessages.START_LOADING);
            this._matDialog.closeAll();
          }
          if (this.engageModeV2Service.getEngageModeState()) {
            this.engageModeStorageV2Service.storeEngageModeExitWithoutWarningInSessionStorage(true);
          }
          this.emailService.clearAllEmailTemplates();
          this.bridgeDialerService.setOpenVerifyPanel(false);
          this.extensionIframeMessagingService.sendMessage(ExtensionMessages.TO_TWENTY_PCT);
          return from(this._router.navigate(['/engageext'], {})).pipe(() => {
            return of(ExtGoToTwentyPctSuccessAction());
          });
        } else if (action.sizeComingFrom === ExtensionSize.MINIMIZED) {
          this.extensionIframeMessagingService.sendMessage(ExtensionMessages.FINISH_LOADING);
          this.extensionIframeMessagingService.sendMessage(ExtensionMessages.TO_TWENTY_PCT);
          return of(ExtGoToTwentyPctSuccessAction());
        } else if (!(this.engageModeV2Service.getEngageModeState() && (serviceToUse.checkIfSesionActive() || isInEngageCall))) {
          this.extensionIframeMessagingService.sendMessage(ExtensionMessages.FINISH_LOADING);
          return of(ExtGoToTwentyPctSuccessAction());
        } else if (this.engageModeV2Service.getEngageModeState() && (serviceToUse.checkIfSesionActive() || isInEngageCall)) {
          this.navigationService.displayCallInProgressErrorDialog('exiting engage mode');
        }
        return of(ExtNoOpAction());
      }),
    ),
  );

  toFiftyPct$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(ExtGoToFiftyPctAction),
        tap((action) => {
          // Button action will not get you here, so do not need to worry about iframe triggering it.
          this.extensionIframeMessagingService.sendMessage(ExtensionMessages.START_LOADING);
          this.extensionIframeMessagingService.sendMessage(ExtensionMessages.TO_FIFTY_PCT);
          this._router.navigate([action.redirectUrl], { queryParams: action.queryParams });
        }),
      ),
    { dispatch: false },
  );

  toNinetyPct$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(ExtGoToNinetyPctAction),
        map((action) => {
          if (this._router.url.startsWith('/engageext')) {
            this.extensionIframeMessagingService.sendMessage(ExtensionMessages.START_LOADING);

            if (!action.notExpandIFrame) {
              this.extensionIframeMessagingService.sendMessage(ExtensionMessages.TO_NINETY_PCT);
            }

            if (!action.redirectUrl) {
              // TODO: Make this actually do something.
              const redirectParams = this.extensionRedirectionService.getRedirectParams('', {});
              this._router.navigate([redirectParams.url], { queryParams: redirectParams.queryParams });
            } else {
              this._router.navigate([action.redirectUrl], { queryParams: action.queryParams });
            }
          } else {
            this.extensionIframeMessagingService.sendMessage(ExtensionMessages.FINISH_LOADING);
          }
        }),
      ),
    { dispatch: false },
  );

  extLoadingStart$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(ExtLoadingStartAction),
        tap(() => {
          this.extensionIframeMessagingService.sendMessage(ExtensionMessages.START_LOADING);
        }),
      ),
    { dispatch: false },
  );

  extLoadingEnd$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(ExtLoadingEndAction),
        tap(() => {
          this.extensionIframeMessagingService.sendMessage(ExtensionMessages.FINISH_LOADING);
        }),
      ),
    { dispatch: false },
  );

  extReceiveNewParsedContactInfo$ = createEffect(() =>
    this.action$.pipe(
      ofType(ExtReceiveNewParsedContactIdsInfoAction),
      flatMap((action) => {
        return this.extensionMatcherService.getEntitiesByIds(action.encodedParsedContactIdsInfo);
      }),
      map((contacts: Contact[]) => {
        return ExtSetParsedContactInfoSuccessAction({ parsedContacts: contacts });
      }),
    ),
  );

  extGetParsedContactInfo$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(ExtGetParsedContactInfoAction),
        tap(() => {
          this.extensionIframeMessagingService.sendMessage(ExtensionMessages.GET_PARSED_INFO);
        }),
      ),
    { dispatch: false },
  );

  // Create contact
  createOrUpdateContact$ = createEffect(() =>
    this.action$.pipe(
      ofType(ExtAddContactAction),
      mergeMap((action) =>
        this.extensionMatcherService.createOrUpdateExtensionContact(action.payload).pipe(
          map((contact) => ExtAddContactSuccessAction({ payload: contact })),
          catchError((error: any) => of(ExtAddContactFailureAction({ error }))),
        ),
      ),
    ),
  );

  // Create contact Bulk
  createOrUpdateContactBulk$ = createEffect(() => {
    return this.action$.pipe(
      ofType(ExtAddBulkContactAction),
      mergeMap((action) =>
        this.extensionMatcherService.createOrUpdateExtensionBulkContact(action.payload).pipe(
          map((contacts) => ExtAddBulkContactSuccessAction({ payload: contacts })),
          catchError((error: any) => of(ExtAddContactFailureAction({ error }))),
        ),
      ),
    );
  });
}
