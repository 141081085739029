import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractFieldComponent } from '@zi-common/model/forms/abstract-field';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent extends AbstractFieldComponent<boolean> {
  @Input() checked = false;
  @Input() disabled = false;
  @Input() stopPropagationOnClick = false;
  @Input() isAll = false;
  @Output() clickCheckbox = new EventEmitter<MatCheckboxChange>();
  @Output() clicked = new EventEmitter<MouseEvent>();
  @Input() indeterminate = false;

  constructor() {
    super();
  }

  onClick(event: MouseEvent) {
    if (!this.disabled) {
      if (this.stopPropagationOnClick) {
        event.stopPropagation();
      }
      this.clicked.emit(event);
    }
  }

  onChecked($event: MatCheckboxChange) {
    this.clickCheckbox.emit($event);
    this.value = $event.checked;
  }

  public writeValue(value: any): void {
    this.value = value;
    if (this.changeDetector) {
      this.changeDetector.detectChanges();
    }
  }
}
