import { compact } from 'lodash';

export type BlockListEntries = Readonly<{
  domains: Array<string>;
  emails: Array<string>;
}>;

export const extractBlockListFromText = (input): BlockListEntries => {
  const rows: Array<string> = compact(input.split(/\n|\s|,/));
  return extractDomainsAndEmailsFromRows(rows);
};

export const extractDomainsAndEmailsFromRows = (rows: Array<string>): BlockListEntries => {
  const emails: Array<string> = rows.map((_) => _?.trim()).filter((entry) => isEmail(entry));
  const domains: Array<string> = rows.map((_) => _?.trim()).filter((entry) => !isEmail(entry));
  return {
    emails,
    domains,
  };
};

export const extractDomainFromEmail = (email: string) => {
  const domain = email?.split('@').pop();
  return isValidDomain(domain) ? domain : null;
};

const isEmail = (entry) => entry.includes('@');

const domainRegex = new RegExp(/^([A-Za-z0-9._%+-])+[A-Za-z]{2,}$/);
export const isValidDomain = (domain: string) => domain?.match(domainRegex);
