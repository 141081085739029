import { Inject, Injectable } from '@angular/core';
import { EventManager } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

/**
 * This is an event manager plugin that enables registering javascript events during the capture phase of event dispatch.
 * Usual event handlers occur during the target phase (on the element the even occurred) and bubbling phase (each parent
 * element in the DOM tree will also be triggered for the event unless {@code Event#stopPropagation} is called)
 *
 * Capture phase events are useful in the case where you want to handle the event on an parent element regardless of whether
 * the target stops propagation of the event in the bubbling phase
 *
 * See https://www.w3.org/TR/DOM-Level-3-Events/#event-flow for more information on the DOM Event flow
 *
 * To use this plugin, it must be provided in the app module, and this plugin will pickup all events with ".capture" appended
 * to the event name
 *
 * @NgModule({
 * ...,
 * providers: [
 * ...,
 * {
 *   provide: EVENT_MANAGER_PLUGINS,
 *   useClass: EventCapturePlugin,
 *   multi: true
 * },
 * ...
 * })
 * export class AppModule
 *
 * It can be used in templates:
 *    <my-component (click.capture)="captureEventHandler($event)"></my-component>
 *
 * As a host listener
 *    @HostListener('click.capture', ['$event'])
 *    captureEventHandler(event) {...}
 *
 * Or it can be restered using the public renderer api
 * @see Renderer2#listen
 * @see Renderer2#listenGlobal
 */
@Injectable()
export class EventCapturePlugin {
  manager: EventManager;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  supports(eventName: string): boolean {
    const eventParts = (eventName && eventName.toLowerCase().split('.')) || [];
    return eventParts.length > 1 && eventParts[eventParts.length - 1] === 'capture';
  }

  addEventListener(element: HTMLElement, eventName: string, handler: Function): Function {
    const event = eventName.split('.')[0];
    this.manager.getZone().runOutsideAngular(() => {
      element.addEventListener(event, handler as any, true);
    });

    return () => this.removeEventListener(element, event, handler);
  }

  addGlobalEventListener(element: string, eventName: string, handler: Function): Function {
    let nativeElement: HTMLElement;
    switch (element.toLowerCase()) {
      case 'document':
        nativeElement = this.document.documentElement;
        break;
      case 'body':
        nativeElement = this.document.body;
        break;
      default:
        nativeElement = <HTMLElement>this.document.querySelector(element);
    }

    if (!nativeElement) {
      throw new Error(`Cannot bind event for selector ${element}. No elements found!`);
    }

    return this.addEventListener(nativeElement, eventName, handler);
  }

  removeEventListener(element: HTMLElement, eventName: string, handler: Function): void {
    element.removeEventListener(eventName, handler as any, true);
  }
}
