import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class DateManagementService {
  constructor() {}

  /**
   * The display text for the "just now" time representation.
   */
  public static get JUST_NOW_TEXT(): string {
    return 'Just now';
  }

  // This function purpose is to avoid deprecation warning of moment (because of unknown format)
  strDateToMoment(dateStr: string = null) {
    if (dateStr) {
      const dateInMilliseconds = new Date(dateStr).getTime();
      return moment(dateInMilliseconds);
    }
    return moment();
  }

  floorToDay(dateParam = null) {
    // check if Date of String
    const date = dateParam == null ? new Date() : typeof dateParam !== 'string' ? dateParam : new Date(dateParam);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    return new Date(year, month, day).toISOString();
  }

  /**
   * Update dateFormat with diff in days with no months from current date.
   * @param pastMomentDate - past moment date
   * @param dateFormat - Date format to update
   */
  private setDaysDiffFromCurrentDateWithNoMonths(pastMomentDate, dateFormat) {
    const now = moment();
    const months = now.diff(pastMomentDate, 'months');
    const maxDate = moment(now).subtract(months, 'M');
    const diff = maxDate.diff(pastMomentDate, 'days');
    if (diff !== 0) {
      dateFormat.short = `${dateFormat.short} ${diff}d`;
      dateFormat.long = `${dateFormat.long} ${this.singleOrPlural(diff, 'day')}`;
    }
  }

  /**
   * Update dateFormat with diff in years from current date.
   * @param pastMomentDate - past moment date
   * @param dateFormat - Date format to update
   */
  setYearsDiffInDateFormat(pastMomentDate: moment.Moment, dateFormat: { short: string; long: string }) {
    const now = moment();
    const years = now.diff(pastMomentDate, 'years');
    const months = now.subtract(years, 'years').diff(pastMomentDate, 'months');
    if (years > 0) {
      dateFormat.short = `${years}yr`;
      dateFormat.long = this.singleOrPlural(years, 'year');
    }
    if (months > 0) {
      dateFormat.short = `${dateFormat.short} ${months}mo`;
      dateFormat.long = `${dateFormat.long} ${this.singleOrPlural(months, 'month')}`;
    }
  }

  /**
   * Update dateFormat with diff in months from current date.
   * @param pastMomentDate - past moment date
   * @param dateFormat - Date format to update
   */
  private setMonthsDiffInDateFormat(pastMomentDate, dateFormat) {
    const now = moment();
    const months = now.diff(pastMomentDate, 'months');

    if (months !== 0) {
      dateFormat.short = `${months}mo`;
      dateFormat.long = this.singleOrPlural(months, 'month');
    }
  }

  /**
   * Update dateFormat for dates within a month.
   * @param pastMomentDate - past moment date
   * @param dateFormat - Date format to update
   */
  private setDateFormatForDateWithinMonth(pastMomentDate, dateFormat) {
    const now = moment();
    const minutes = now.diff(pastMomentDate, 'minutes');
    const hours = now.diff(pastMomentDate, 'hours');
    const days = now.diff(pastMomentDate, 'days');

    if (days > 0) {
      dateFormat.short = `${days}d`;
      dateFormat.long = this.singleOrPlural(days, 'day');
    } else if (hours > 0) {
      dateFormat.short = `${hours}h`;
      dateFormat.long = this.singleOrPlural(hours, 'hour');
    } else if (minutes > 0) {
      dateFormat.short = `${minutes}m`;
      dateFormat.long = this.singleOrPlural(minutes, 'minute');
    } else {
      dateFormat.short = DateManagementService.JUST_NOW_TEXT;
      dateFormat.long = DateManagementService.JUST_NOW_TEXT;
    }
  }

  public getDifferenceFromNow(pastMomentDate) {
    const dateFormat = { short: '', long: '' };
    if (pastMomentDate) {
      const now = moment();
      const months = now.diff(pastMomentDate, 'months');
      const years = now.diff(pastMomentDate, 'years');
      if (years > 0) {
        this.setYearsDiffInDateFormat(pastMomentDate, dateFormat);
      } else if (months > 0) {
        this.setMonthsDiffInDateFormat(pastMomentDate, dateFormat);
        this.setDaysDiffFromCurrentDateWithNoMonths(pastMomentDate, dateFormat);
      } else {
        this.setDateFormatForDateWithinMonth(pastMomentDate, dateFormat);
      }
    }
    return dateFormat;
  }

  private singleOrPlural(value: number, unitText: string) {
    return value + ' ' + unitText + (value > 1 ? 's' : '');
  }

  // Return array of week days with same hour as 'date'
  getArrayOfWeekDaysWithSpecificTime(date: moment.Moment): moment.Moment[] {
    const dateDayOfWeek = date.isoWeekday() - 1;
    const dayToAdd = date.subtract(dateDayOfWeek, 'day'); // Start from Monday
    const weekDays = [];
    //
    for (let i = 1; i <= 7; i++) {
      if (dayToAdd.isSameOrAfter(moment(), 'day')) {
        weekDays.push(moment(dayToAdd));
      }
      dayToAdd.add(1, 'day');
    }
    return weekDays;
  }

  /**
   * Gets the difference in days from the current date. Difference from will always yield a positive value.
   * @param inputMomentDate - A moment date.
   * @param inclusiveOfInputDate - Set to true to include input date.
   */
  public getDaysDifferenceFromSpecifiedMomentToNow(inputMomentDate: moment.Moment, inclusiveOfInputDate: boolean = false): number {
    if (inputMomentDate) {
      const now = moment().startOf('day');
      const days = Math.abs(now.diff(inputMomentDate.startOf('day'), 'days'));
      return inclusiveOfInputDate ? days + 1 : days;
    }
    return 0;
  }

  /**
   * Gets the absolute value of the difference in days from two given dates.
   * @param firstMomentDate - First moment date to use.
   * @param secondMomentDate - Second moment date to use.
   * @param inclusiveOfEndDate - Set to true to include the end date.
   */
  public getAbsoluteNumberOfDaysBetweenDates(firstMomentDate: moment.Moment, secondMomentDate: moment.Moment, inclusiveOfEndDate: boolean = false): number {
    const itHasInvalidParameters = !firstMomentDate || !secondMomentDate;

    if (itHasInvalidParameters) {
      return 0;
    }

    const startOfFirstDate = firstMomentDate.startOf('day');
    const startOfSecondDate = secondMomentDate.startOf('day');
    const differenceInDays = startOfFirstDate.diff(startOfSecondDate, 'days');
    const absoluteDays = Math.abs(differenceInDays);

    return inclusiveOfEndDate ? absoluteDays + 1 : absoluteDays;
  }

  /**
   * Fn used to find duration in seconds
   * @param startTime
   * @private
   */
  public getDurationFromNowInSecs(startTime: moment.Moment): number {
    if (startTime) {
      const endTime = moment(new Date());
      const duration = moment.duration(endTime.diff(startTime));
      return duration.asSeconds();
    }
    return 0;
  }

  /**
   * Set time to minutesFromNow from current time.
   * Used by add to salesflow time picker(10 mins), and add to followup salesflow time picker(10 mins).
   */
  initDeliveryDateTime(timezone: string, minutesFromNow: number) {
    const timeZoneDate = new Date().toLocaleString('en-US', { timeZone: timezone });
    const pickerDateTime = new Date(timeZoneDate);
    pickerDateTime.setSeconds(0);
    const tenMinutesAddition = moment(pickerDateTime.getTime()).add(minutesFromNow, 'm');
    pickerDateTime.setHours(tenMinutesAddition.hours());
    pickerDateTime.setMinutes(tenMinutesAddition.minutes());

    return pickerDateTime;
  }

  validateTime(timezone: string, deliveryTime?) {
    let errorFlag = false;
    let errorText = null;

    const timeZoneDate = new Date().toLocaleString('en-US', { timeZone: timezone });
    const today = new Date(timeZoneDate);
    const deliveryDate = new Date(deliveryTime);
    const time: any = (deliveryDate.getTime() - today.getTime()) / 60000;

    if (Math.ceil(time) < 0) {
      errorFlag = true;
      errorText = 'Start time for this Salesflow should be set after current time.';
    }
    return { errorFlag: errorFlag, errorText: errorText };
  }
}
