<app-dialog-template [dialogTitle]="title" (close)="onCancel(sourceOfClose.XIcon)" [automationId]="'app-dialog-template'">
  <app-dialog-content [automationId]="'app-dialog-content'">
    <p class="pre-line"><span class="content" [innerHTML]="content" [ngClass]="{'bold-section': isBold}"></span></p>
    <ng-container *ngTemplateOutlet="templateRef"></ng-container>
    <ng-container *ngIf="subContent">
      <div class="sub-pre-line">{{subContent}}</div>
    </ng-container>
  </app-dialog-content>

  <app-dialog-footer>
    <app-primary-btn [disabled]="primaryButtonDisabled" [text]="primaryButtonText" (clickButton)="onSend()" [automationId]="primaryButtonText">
    </app-primary-btn>
    <app-secondary-btn
      class="cancel-button"
      *ngIf="!secondaryBoltonDisabled"
      [text]="secondaryButtonText"
      (clickButton)="onCancel(sourceOfClose.CancelButton)"
      [automationId]="'btn-cancel'"
    >
    </app-secondary-btn>
  </app-dialog-footer>
</app-dialog-template>
