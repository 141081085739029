import { Contact } from '@zi-core/data-model/contact.model';
import { ExtensionSource } from '@app/extension/constants/extension-source.enum';

export interface ExtensionParsedInfoState {
  isLoading: boolean;
  isLoaded: boolean;
  loadedFromPage: string;
  source: ExtensionSource;
  parsedContacts: Contact[];
  // should be any as it stores different Ids based on parsing platform
  parsedSelectedIds: any[];
  parsedContactsError: any;
  currentIndex: number;
}

export const initialExtensionParsedInfoState = {
  isLoading: true,
  isLoaded: false,
  loadedFromPage: null,
  source: null,
  parsedContacts: undefined,
  parsedSelectedIds: undefined,
  parsedContactsError: null,
  currentIndex: 1,
};
