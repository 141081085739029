<app-dialog-template class="dozi-features-header" hasCloseIcon="true" (close)="onClose()">
  <app-dialog-content class="dozi-trial-content">
    <div *ngIf="!isLoading; else loaded">
      <div class="box-container centered">
        <div class="video-player">
          <iframe
            class="vidyard_iframe"
            [src]="videoUrl | safeValue: 'resourceUrl'"
            width="449"
            height="301"
            scrolling="no"
            frameborder="0"
            allowtransparency="true"
            allowfullscreen
          >
          </iframe>
        </div>
        <div>
          <div class="content-wrapper">
            <div class="title">{{title}}</div>
            <div *ngFor="let content of contents">
              <div class="content">
                <p>{{content}}</p>
              </div>
            </div>
            <div class="btn">
              <app-primary-btn [text]="'Contact Us'" (clickButton)="onContactUs(doziFeatureToShow)"> </app-primary-btn>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #loaded>
      <app-loader class="dozi-trial-loader"></app-loader>
    </ng-template>
  </app-dialog-content>
</app-dialog-template>
