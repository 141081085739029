import { Action, createReducer, on } from '@ngrx/store';
import { ExtensionState, initialExtensionState } from '@app/extension/ngrx/state/extension.state';
import {
  ExtLoggedInMsgSentAction,
  ExtLoadingEndAction,
  ExtLoadingStartAction,
  ExtReceiveNewParsedContactIdsInfoAction,
  ExtSetParsedContactInfoFailureAction,
  ExtSetParsedContactInfoSuccessAction,
  ExtUpdateSelectedTabAction,
  ExtGetParsedContactInfoAction,
  ExtAddContactSuccessAction,
  ExtSelectContactId,
  ExtClearContactId,
  ExtClearAllContactId,
  ExtStartParsingPageAction,
  ExtGoToNinetyPctAction,
  ExtGoToFiftyPctAction,
  ExtMinimizeAction,
  ExtSelectContactAllId,
  ExtLoggedOutMsgSentAction,
  ExtAddBulkContactSuccessAction,
  ExtSetProfilePageContactAction,
  ExtGoToTwentyPctSuccessAction,
  ExtLoadContactsAction,
  ExtUpdateContactsAction,
  ExtRemoveContactsAction,
  ExtParsingLoadedAction,
  ExtUpdateCurrentIndex,
} from '@app/extension/ngrx/action/extension.action';
import { initialExtensionParsedInfoState } from '@app/extension/ngrx/state/extension-parsed-info.state';
import { ExtensionSize } from '@app/extension/constants/extension-size.enum';
import * as _ from 'lodash';

const extensionReducer = createReducer(
  initialExtensionState,
  on(ExtUpdateSelectedTabAction, (state: ExtensionState, selectedExtTab) => {
    return { ...state, selectedExtTab };
  }),
  on(ExtLoadingStartAction, (state: ExtensionState) => {
    return { ...state, loading: true };
  }),
  on(ExtLoadingEndAction, (state: ExtensionState) => {
    return { ...state, loading: false };
  }),
  on(ExtMinimizeAction, (state: ExtensionState) => {
    return { ...state, size: ExtensionSize.MINIMIZED };
  }),
  on(ExtGoToTwentyPctSuccessAction, (state: ExtensionState) => {
    return { ...state, size: ExtensionSize.TWENTY_PCT };
  }),
  on(ExtGoToNinetyPctAction, (state: ExtensionState) => {
    return { ...state, size: ExtensionSize.NINETY_PCT };
  }),
  on(ExtGoToFiftyPctAction, (state: ExtensionState) => {
    return { ...state, size: ExtensionSize.FIFTY_PCT };
  }),
  on(ExtParsingLoadedAction, (state: ExtensionState) => {
    return { ...state, parsedContactInfoState: { ...state.parsedContactInfoState, isLoading: false, isLoaded: true } };
  }),
  on(ExtLoadContactsAction, (state: ExtensionState, { contact, source }) => {
    const newState = { ...state };
    const updatedContacts = newState.parsedContactInfoState.parsedContacts || [];
    const uniqueContacts = contact.filter((val) => {
      return updatedContacts.findIndex((c) => c.email === val.email) === -1;
    });
    newState.parsedContactInfoState = {
      parsedContacts: [...updatedContacts, ...uniqueContacts],
      parsedSelectedIds: [],
      isLoading: false,
      isLoaded: true,
      loadedFromPage: null,
      source,
      parsedContactsError: null,
      currentIndex: 1,
    };
    return newState;
  }),
  on(ExtUpdateContactsAction, (state: ExtensionState, { contact, source }) => {
    const newState = { ...state };
    const updatedContacts = newState.parsedContactInfoState.parsedContacts.map((c) => {
      if (c.email === contact.email) {
        c = contact;
      }
      return c;
    });
    newState.parsedContactInfoState = {
      parsedContacts: updatedContacts,
      parsedSelectedIds: [],
      isLoading: false,
      isLoaded: true,
      loadedFromPage: null,
      source,
      parsedContactsError: null,
      currentIndex: newState.parsedContactInfoState.currentIndex,
    };
    return newState;
  }),
  on(ExtRemoveContactsAction, (state: ExtensionState, { contacts, source }) => {
    const newState = { ...state };
    const idArray = contacts?.map((contact) => contact.email);
    const updatedContacts = newState?.parsedContactInfoState?.parsedContacts?.filter((c) => {
      return idArray.indexOf(c.email) === -1;
    });
    newState.parsedContactInfoState = {
      parsedContacts: updatedContacts,
      parsedSelectedIds: [],
      isLoading: false,
      isLoaded: true,
      loadedFromPage: null,
      source,
      parsedContactsError: null,
      currentIndex:
        updatedContacts?.length > 0 && updatedContacts?.length < newState.parsedContactInfoState.currentIndex
          ? updatedContacts.length - 1
          : newState.parsedContactInfoState.currentIndex,
    };
    return newState;
  }),
  on(ExtReceiveNewParsedContactIdsInfoAction, (state: ExtensionState, { encodedParsedContactIdsInfo, source }) => {
    const newState = { ...state };
    newState.parsedContactInfoState = {
      parsedContacts: undefined,
      parsedSelectedIds: [],
      isLoading: true,
      isLoaded: false,
      loadedFromPage: null,
      source,
      parsedContactsError: null,
      currentIndex: newState.parsedContactInfoState.currentIndex,
    };
    return newState;
  }),
  on(ExtSetParsedContactInfoSuccessAction, (state: ExtensionState, { parsedContacts }) => {
    const newState = { ...state };
    newState.parsedContactInfoState = {
      parsedContacts,
      parsedSelectedIds: _.get(state, 'parsedContactInfoState.parsedSelectedIds', []),
      isLoading: false,
      isLoaded: true,
      loadedFromPage: null,
      source: state.parsedContactInfoState.source,
      parsedContactsError: null,
      currentIndex: newState.parsedContactInfoState.currentIndex,
    };
    return newState;
  }),
  on(ExtSetParsedContactInfoFailureAction, (state: ExtensionState, { error }) => {
    const newState = { ...state };
    newState.parsedContactInfoState = {
      parsedContacts: undefined,
      parsedSelectedIds: [],
      isLoading: false,
      isLoaded: false,
      loadedFromPage: null,
      source: state.parsedContactInfoState.source,
      parsedContactsError: error,
      currentIndex: newState.parsedContactInfoState.currentIndex,
    };
    return newState;
  }),
  on(ExtSetProfilePageContactAction, (state: ExtensionState, { parsedContacts, page }) => {
    const newState = { ...state };
    newState.parsedContactInfoState = {
      parsedContacts,
      parsedSelectedIds: [],
      isLoading: false,
      isLoaded: true,
      loadedFromPage: page,
      source: state.parsedContactInfoState.source,
      parsedContactsError: null,
      currentIndex: newState.parsedContactInfoState.currentIndex,
    };
    return newState;
  }),
  on(ExtLoggedInMsgSentAction, (state: ExtensionState) => {
    return { ...state, isLoggedInSent: true };
  }),
  on(ExtLoggedOutMsgSentAction, (state: ExtensionState) => {
    return { ...state, isLoggedInSent: false };
  }),
  on(ExtStartParsingPageAction, (state: ExtensionState) => {
    const newState = { ...state };
    newState.parsedContactInfoState = initialExtensionParsedInfoState;
    return newState;
  }),
  on(ExtGetParsedContactInfoAction, (state: ExtensionState) => {
    const newState = { ...state };
    newState.parsedContactInfoState = initialExtensionParsedInfoState;
    return newState;
  }),
  on(ExtAddContactSuccessAction, (state: ExtensionState, { payload: updatedContact }) => {
    let newState = { ...state };
    if (!updatedContact) {
      return newState;
    }
    if (newState.parsedContactInfoState.parsedContacts) {
      const newParsedContacts = newState.parsedContactInfoState.parsedContacts.slice();
      let idxOfContact;
      if (updatedContact.externalId) {
        idxOfContact = _.findIndex(newParsedContacts, { externalId: updatedContact.externalId });
      } else {
        idxOfContact = _.findIndex(newParsedContacts, { zoomId: updatedContact.zoomId });
      }
      newParsedContacts[idxOfContact] = { ...newParsedContacts[idxOfContact], ...updatedContact };
      newState = { ...newState, parsedContactInfoState: { ...newState.parsedContactInfoState, parsedContacts: newParsedContacts } };
    }
    return newState;
  }),
  on(ExtAddBulkContactSuccessAction, (state: ExtensionState, { payload: updatedContacts }) => {
    let newState = { ...state };
    const newParsedContacts = newState.parsedContactInfoState.parsedContacts.slice();
    if (!updatedContacts || _.isEmpty(updatedContacts)) {
      return newState;
    }
    updatedContacts.forEach((elt) => {
      let idxOfContact;
      if (elt.externalId) {
        idxOfContact = _.findIndex(newParsedContacts, { externalId: elt.externalId });
      } else {
        idxOfContact = _.findIndex(newParsedContacts, { zoomId: elt.zoomId });
      }
      newParsedContacts[idxOfContact] = { ...newParsedContacts[idxOfContact], ...elt };
    });
    newState = { ...newState, parsedContactInfoState: { ...newState.parsedContactInfoState, parsedContacts: newParsedContacts } };
    return newState;
  }),
  on(ExtSelectContactId, (state: ExtensionState, { contactId: idList }) => {
    const currentList = state.parsedContactInfoState.parsedSelectedIds;
    const index = currentList.findIndex((val) => val === idList);
    if (index < 0) {
      return { ...state, parsedContactInfoState: { ...state.parsedContactInfoState, parsedSelectedIds: [...currentList, idList] } };
    } else {
      return state;
    }
  }),
  on(ExtUpdateCurrentIndex, (state: ExtensionState, { index }) => {
    const newState = { ...state, parsedContactInfoState: { ...state.parsedContactInfoState, currentIndex: index } };
    return newState;
  }),
  on(ExtSelectContactAllId, (state: ExtensionState, { contactsIds: idList }) => {
    return { ...state, parsedContactInfoState: { ...state.parsedContactInfoState, parsedSelectedIds: idList } };
  }),
  on(ExtClearContactId, (state: ExtensionState, { contactId: idList }) => {
    const currentList = state.parsedContactInfoState.parsedSelectedIds;
    return { ...state, parsedContactInfoState: { ...state.parsedContactInfoState, parsedSelectedIds: currentList.filter((ele) => ele !== idList) } };
  }),
  on(ExtClearAllContactId, (state: ExtensionState) => {
    return { ...state, parsedContactInfoState: { ...state.parsedContactInfoState, parsedSelectedIds: [] } };
  }),
);

export function ExtensionReducer(state: ExtensionState, action: Action): ExtensionState {
  return extensionReducer(state, action);
}
