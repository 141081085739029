import { EventEmitter, Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ApplicationState } from '@app/reducers';
import { getTwilioClientToken, getUserPermissionsFromAuthToken } from '@zi-core/ngrx/state/auth.state';
import { catchError, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject, forkJoin, from, Observable, of, Subscription } from 'rxjs';
import { environment } from '@env/environment';
import { AuthenticatedToken, TwilioClientToken } from '@zi-core/http-model/response/authenticated-token.response.model';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { NotyService } from '@zi-common/service/noty/noty.service';
import { LoadTwilioClientTokenAction } from '@zi-core/ngrx/action/auth.action';
import { MessageType } from '@zi-common/model/message/message-type';
import { Message } from '@zi-common/model/message/message.model';
import { Connection, Device } from 'twilio-client';
import { getDialerConfigState } from '@zi-pages/account/ngrx/selector/dialer-config.selector';
import { DialerConfigState } from '@zi-pages/account/ngrx/state/dialer-config.state';
import { DialerSelection } from '@zi-core/enums/dialer.enum';
import { PhoneVerifierService } from '@zi-core/service/phone-verifier.service';
import { TwilioErrorCodes } from '@app/caller/constant/twilio-error-codes';
import { DialerUtilService } from '@app/caller/service/dialer-util.service';
import { getExtensionMode } from '@app/extensionMode.config';
import {
  AUDIO_DEVICE_DEFAULT_ID,
  AUDIO_OUTPUT,
  AUIDO_INPUT,
  VOIP_AUDIO_INPUT_SESSION_STORAGE_KEY,
  VOIP_AUDIO_OUTPUT_SESSION_STORAGE_KEY,
} from '@zi-pages/account/components/audio-device-settings/audio-device-settings.config';
import { AudioDeviceService } from '@zi-core/service/audio-device.service';
import { CookieService } from 'ngx-cookie-service';
import { FeatureFlagService } from '@app/feature-flag/feature-flag.service';
import Options = Device.Options;
import { DialerErrorDetails, DialerErrorTypes } from '../constant/dialer-errors.enum';
import { PermissionEnum } from '@app/pages/admin/pages/profiles-and-permissions/profiles-and-permissions.config';
import { AnalyticsApplication } from '@app/core/enums/analytics.enum';
import { DialerAnalyticsService } from '@app/caller/service/dialer-analytics.service';
import { LoggerServiceToken } from '@zi-core/config/logger-service.config';
import { ILoggerService } from '@zi-core/interface/logger.service.interface';

@Injectable({
  providedIn: 'root',
})
export class VoipDeviceService {
  private twilioDevice: Device;
  private isDeviceReady$ = new BehaviorSubject<boolean>(false);
  private subscriptions: Subscription[] = [];
  private tokenResetTriggered = false;
  private isDeviceInitLoading = false;
  private connectRetryApplied = false;
  private application = AnalyticsApplication.Engage;

  private deviceIncomingCallNotifier$ = new EventEmitter<Connection>();
  private deviceInitNotifier$ = new EventEmitter<boolean>();
  // Should only be used for token management.
  private allCallEventsEndedNotifier$ = new EventEmitter<void>();
  // Used to trigger refresh of token.
  private resetTokenNotifier$ = new EventEmitter<void>();
  private deviceIncomingVoicemailNotifier$ = new EventEmitter<Connection>();

  constructor(
    private _appStore: Store<ApplicationState>,
    private _dialerConfigStore: Store<DialerConfigState>,
    private _httpClient: HttpClient,
    private phoneVerifierService: PhoneVerifierService,
    private dialerUtilService: DialerUtilService,
    private audioDeviceService: AudioDeviceService,
    private notyService: NotyService,
    private cookieService: CookieService,
    protected featureFlagService: FeatureFlagService,
    private dialerAnalyticsService: DialerAnalyticsService,
    @Inject(LoggerServiceToken) private loggerService: ILoggerService,
  ) {}

  /**
   * Initializes a Twilio Device through use of the Twilio Client JWT Token.
   */
  public initDevice(): void {
    this.tokenResetTriggered = false;
    this.isDeviceReady$.next(false);
    // If the token is refreshed, we have to reinit device, so cleanup.
    if (this.twilioDevice || this.subscriptions.length > 0) {
      this.cleanUpDevice();
    }
    const getTwilioClientTokenSub = this._appStore
      .select(getTwilioClientToken)
      .pipe(
        filter((tokenObj: TwilioClientToken) => {
          return tokenObj.loaded && !tokenObj.loading && !_.isEmpty(tokenObj.token);
        }),
        take(1),
        map((tokenObj: TwilioClientToken) => {
          this.twilioDevice = new Device();
          const options: Options = {
            allowIncomingWhileBusy: true,
            appName: 'ZI Engage',
            closeProtection: true,
            debug: environment.debugLoggingVoip,
            enableRingingState: true,
          };
          this.twilioDevice.setup(tokenObj.token, options);
          // Silence the outgoing ringtone when someone picks up due to customer complaint.
          if (this.twilioDevice && this.twilioDevice.sounds) {
            this.twilioDevice.sounds.outgoing(false);
          }
          return this.twilioDevice;
        }),
      )
      .subscribe(
        (device: Device) => {
          this.initDeviceHandlers(device);
          this.deviceInitNotifier$.emit(true);
        },
        (err) => {
          this.isDeviceInitLoading = false;
          this.deviceInitNotifier$.emit(false);
          this.notyService.postMessage(new Message(MessageType.ERROR, 'Error initializing VOIP device. Please reload and try again.'));
        },
      );
    this.subscriptions.push(getTwilioClientTokenSub);
    this.application = getExtensionMode() ? AnalyticsApplication.EngageExtension : AnalyticsApplication.Engage;
  }

  /**
   * Retrieves a JWT token from Twilio for using the Twilio Client.
   */
  public getTwilioClientToken(): Observable<{ token: string }> {
    return this._httpClient.get(`${this.dialerUtilService.dialerDomain}/v1/dial/token`) as Observable<{ token: string }>;
  }

  /**
   * Informs if a device has an active connection or not.
   */
  public isDeviceInUse() {
    return this.twilioDevice && !!this.twilioDevice.activeConnection();
  }

  /**
   * returns audio input device id stored in local storage
   */
  getAudioInputDevice(): Observable<string> {
    const audioDeviceId = localStorage.getItem(VOIP_AUDIO_INPUT_SESSION_STORAGE_KEY);
    if (!audioDeviceId) {
      this.setAudioInputDevice(AUDIO_DEVICE_DEFAULT_ID);
    }
    return audioDeviceId ? of(audioDeviceId) : of(AUDIO_DEVICE_DEFAULT_ID);
  }

  /**
   * sets audio input device id in local storage
   */
  setAudioInputDevice(value) {
    from(navigator.mediaDevices.enumerateDevices())
      .pipe(take(1))
      .subscribe((devices) => {
        const deviceInfo = devices.find((device) => device.deviceId === value && device.kind === AUIDO_INPUT);
        const deviceLabel = deviceInfo ? deviceInfo.label : AUDIO_DEVICE_DEFAULT_ID;
        this.cookieService.set(VOIP_AUDIO_INPUT_SESSION_STORAGE_KEY, deviceLabel, { path: '/', domain: '.zoominfo.com', secure: true, sameSite: 'None' });
      });
    localStorage.setItem(VOIP_AUDIO_INPUT_SESSION_STORAGE_KEY, value);
  }

  /**
   * returns audio input device id stored in local storage
   */
  getAudioOutputDevice(): Observable<string> {
    const audioDeviceId = localStorage.getItem(VOIP_AUDIO_OUTPUT_SESSION_STORAGE_KEY);
    if (!audioDeviceId) {
      this.setAudioOutputDevice(AUDIO_DEVICE_DEFAULT_ID);
    }
    return audioDeviceId ? of(audioDeviceId) : of(AUDIO_DEVICE_DEFAULT_ID);
  }

  /**
   * sets audio output device id in local storage
   */
  setAudioOutputDevice(value) {
    from(navigator.mediaDevices.enumerateDevices())
      .pipe(take(1))
      .subscribe((devices) => {
        const deviceInfo = devices.find((device) => device.deviceId === value && device.kind === AUDIO_OUTPUT);
        const deviceLabel = deviceInfo ? deviceInfo.label : AUDIO_DEVICE_DEFAULT_ID;
        this.cookieService.set(VOIP_AUDIO_OUTPUT_SESSION_STORAGE_KEY, deviceLabel, { path: '/', domain: '.zoominfo.com', secure: true, sameSite: 'None' });
      });
    localStorage.setItem(VOIP_AUDIO_OUTPUT_SESSION_STORAGE_KEY, value);
  }

  /**
   * Clean up and "destroy" a Twilio Device object.
   */
  public cleanUpDevice(): void {
    this.isDeviceReady$.next(false);
    if (this.twilioDevice) {
      this.twilioDevice.destroy();
      this.twilioDevice = null;
    }
    this.subscriptions.forEach((subs) => {
      subs.unsubscribe();
    });
    this.subscriptions = [];
  }

  /**
   * Returns if the twilio client has indicated the twilio device is ready.
   */
  public isDeviceReady(): boolean {
    return this.isDeviceReady$.value;
  }

  /**
   * Returns an observable indicating if the twilio device is ready or not.
   */
  public getIsDeviceReady$(): Observable<boolean> {
    return this.isDeviceReady$.asObservable();
  }

  /**
   * Returns an observable that indicates when there is an incoming call.
   */
  public getDeviceIncomingCallsNotifier(): Observable<Connection> {
    return this.deviceIncomingCallNotifier$.asObservable();
  }

  /**
   * Returns an observable that indicates when all call events have ended.
   */
  public getAllCallEventsEndedNotifier(): Observable<void> {
    return this.allCallEventsEndedNotifier$.asObservable();
  }

  /**
   * Returns an observable that communicates when the twilio token should be reset.
   */
  public getResetTokenNotifier(): Observable<void> {
    return this.resetTokenNotifier$.asObservable();
  }

  /**
   * Notifies that all call events have ended.
   */
  public notifyAllCallEventsEnded(): void {
    this.allCallEventsEndedNotifier$.emit();
  }

  /**
   * Returns am observable that indicates when there is an incoming call for the voicemail record.
   */
  public getDeviceIncomingVoicemailNotifier(): Observable<Connection> {
    return this.deviceIncomingVoicemailNotifier$.asObservable();
  }

  /**
   * Uses the Twilio Device object to connect to the Twilio client to make an outgoing phone call.
   *
   * @param phoneNumber - The number being dialed.
   * @param contactId - The contact ID of the contact being called.
   * @param userId - The user ID of the user making the call.
   */
  public connectToTwilio(phoneNumber: string, contactId: string, userId: string, taskId?: string): Observable<Connection> {
    if (this.twilioDevice) {
      this.connectRetryApplied = false;
      const args: {
        phoneNumber: string;
        contactId: string;
        userId: string;
        application: string;
        taskId?: string;
      } = {
        phoneNumber,
        contactId,
        userId,
        application: this.application,
      };

      if (taskId) {
        args.taskId = taskId;
      }

      return of(this.twilioDevice.connect(args, null, null));
    } else if (!this.connectRetryApplied) {
      // Connect is called without init a twilio device? Retry init once.
      this._appStore.dispatch(LoadTwilioClientTokenAction());
      this.connectRetryApplied = true;
      return this.deviceInitNotifier$.pipe(
        take(1),
        mergeMap((isInit) => {
          if (isInit) {
            return this.connectToTwilio(phoneNumber, contactId, userId, taskId).pipe(take(1));
          } else {
            this.connectRetryApplied = false;
            this.dialerAnalyticsService.sendDialerErrorAnalyticsEvent(DialerErrorDetails.DEVICE_INITIALIZATION_ERROR);
            this.notyService.postMessage(new Message(MessageType.ERROR, 'Error performing VOIP action. Please reload and try again'));
            return of(null);
          }
        }),
      );
    } else {
      this.connectRetryApplied = false;
      this.notyService.postMessage(new Message(MessageType.ERROR, 'Error performing VOIP action. Please reload and try again'));
      return of(null);
    }
  }

  /**
   * get/update latest audio settings from local storage and set the twilio with the devices
   */
  public getCurrentAudioInfo$(): Observable<{ inputDeviceId: string; outputDeviceId: string }> {
    return this.getIsDeviceReady$().pipe(
      filter((isReady) => isReady),
      switchMap(() => {
        return forkJoin([this.getAudioInputDevice().pipe(take(1)), this.getAudioOutputDevice().pipe(take(1))]);
      }),
      map((twilioDevices) => {
        const inputDeviceId = twilioDevices[0];
        const outputDeviceId = twilioDevices[1];
        this.setAudioOptions(inputDeviceId, outputDeviceId);
        return {
          inputDeviceId,
          outputDeviceId,
        };
      }),
    );
  }

  /**
   * Checks to see if the auth token of the user indicates that the org has VOIP access
   * and that the device has not been initialized already. If so, and the user is currently using
   * VOIP, will start the process of retrieving a Twilio Client JWT Token.
   *
   * @param authToken - The authentication token of the user.
   */
  public checkAndInitToken(authToken: AuthenticatedToken): void {
    if (authToken && authToken.voipDialerEnabled && !this.isDeviceReady() && !this.isDeviceInitLoading) {
      this.isDeviceInitLoading = true;
      this._dialerConfigStore
        .select(getDialerConfigState)
        .pipe(
          filter((dialogConfigState) => {
            return !dialogConfigState.loading && dialogConfigState.loaded;
          }),
          take(1),
          mergeMap((dialogConfigState) => {
            const { dialerSelection } = dialogConfigState;
            if (this.dialerUtilService.checkIsVoip(dialerSelection, authToken.voipDialerEnabled)) {
              return this.phoneVerifierService.getCallerIds(authToken.userId, [DialerSelection.VOIP]);
            }

            return of({});
          }),
        )
        .subscribe((callerId) => {
          if (!_.isEmpty(callerId)) {
            this._appStore.dispatch(LoadTwilioClientTokenAction());
          } else {
            this.isDeviceInitLoading = false;
          }
        });
    }
  }

  /**
   * update input and output device ids in local storage
   * @param inputId
   * @param outputId
   */
  public setAudioOptions(inputId: string, outputId: string): void {
    if (this.audioDeviceService.checkIfInputDeviceIsValid(inputId)) {
      this.setAudioInputDevice(inputId);
    }
    if (this.audioDeviceService.checkIfOutputDeviceIsValid(outputId)) {
      this.setAudioOutputDevice(outputId);
    }
  }

  /**
   * initialize twilio input and output devices from local storage
   */
  public initializeDevices(): Observable<[void, void]> {
    const obsA$ = this.getAudioInputDevice().pipe(
      take(1),
      mergeMap((inputId) => {
        return from(this.twilioDevice.audio.setInputDevice(inputId)).pipe(take(1));
      }),
      catchError((err) => {
        return of(null);
      }),
    );
    const obsB$ = this.getAudioOutputDevice().pipe(
      take(1),
      mergeMap((outputId) => {
        return from(this.twilioDevice.audio.speakerDevices.set(outputId));
      }),
      catchError((err) => {
        return of(null);
      }),
    );
    return forkJoin([obsA$, obsB$]);
  }

  /**
   * unset twilio input device
   */
  public unsetAudioInput() {
    this.twilioDevice.audio.unsetInputDevice();
  }

  /**
   * Initializes the event handlers for the Twilio device. This will be used
   * to react to events from the Twilio Client.
   *
   * @param device - The twilio device object.
   */
  private initDeviceHandlers(device: Device): void {
    /**
     * This is triggered when an incoming connection is cancelled by the caller
     * before it is accepted by the Twilio Client device.
     *
     * A Twilio Connection object is received as an argument to the Handler function,
     * but it represents an inactive connection.
     */
    // TODO: Clean up if not necessary
    device.on('cancel', (connection: Connection) => {});

    /**
     * This is triggered when a connection is opened, whether initiated
     * using .connect() or via an accepted incoming connection.
     *
     * A Twilio Connection object representing the connection is received as an
     * argument to the Handler function.
     */
    // TODO: Clean up if not necessary
    device.on('connect', (connection: Connection) => {
      // Currently unused due to focusing on Connection.
    });

    /**
     * This is triggered any time a connection is closed.
     *
     * A Twilio Connection object representing the connection that was just
     * closed is received as an argument to the Handler function.
     */
    // TODO: (5/17/2021) Clean up if not necessary
    device.on('disconnect', (connection: Connection) => {});

    /**
     * Emitted when any device error occurs. These may be errors in the request,
     * the token, connection errors, or other application errors.
     *
     * The handler function receives an error object as an argument.
     * The error object may include the following properties:
     * - message: A string describing the error.
     * - code: A numeric error code described in the Twilio Client error code reference.
     * - connection: A reference to the Twilio.Connection object that was active when the error occurred.
     * - twilioError: When applicable, errors emitted now contain this twilioError field,
     *                providing more information about the error. This twilioError
     *                represents the new TwilioError format that will become the default Error
     *                format in the next major release of this SDK.
     */
    device.on('error', (error) => {
      this.handleTwilioErrors(error);
    });

    /**
     * This is triggered whenever an incoming connection from an outbound REST call
     * or a TwiML <Dial> to this device is made (basically handling incoming calls).
     *
     * The handler function receives a Twilio.Connection object as an argument.
     * This connection will be in state pending until you call .accept() on it.
     */
    device.on('incoming', (connection: Connection) => {
      _.invoke(connection.customParameters, 'get', 'IsVoiceRecording') === 'True'
        ? this.deviceIncomingVoicemailNotifier$.emit(connection)
        : this.deviceIncomingCallNotifier$.emit(connection);
    });

    /**
     * This is triggered when the connection to Twilio drops or the device's token is
     * invalid/expired. In either of these scenarios, the device cannot receive incoming
     * connections or make outgoing connections.
     *
     * If the token expires during an active connection the offline event will be fired,
     * but the connection will not be terminated. In this situation Twilio.Device.setup()
     * will need to be called again with a valid token before attempting or receiving the
     * next connection.
     *
     * The handler function receives the Device object as its sole argument.
     */
    device.on('offline', (deviceObj: Device) => {
      // TODO: (5/5/2021) Leaving this log for QA / Debugging purposes. Will remove closer to
      // release.
      this.loggerService.log('call offline :: ', deviceObj);
    });

    /**
     * This is initially triggered when all operations in .setup() have completed and
     * the device is ready and online. It will be triggered again if the device goes offline
     * and comes back online (i.e. the connection drops and returns).
     *
     * The handler function receives the Twilio.Device object as its sole argument.
     */
    device.on('ready', (deviceObj: Device) => {
      this.audioDeviceService
        .getInputDevices$()
        .pipe(take(1))
        .subscribe((inputDevices) => {
          if (inputDevices.length === 1 && inputDevices[0].deviceId === '') {
            this.isDeviceReady$.next(false);
          } else {
            this.isDeviceReady$.next(true);
          }
        });
      this.isDeviceInitLoading = false;
    });
  }

  /**
   * Used to reset the Twilio Client JWT Token.
   */
  private resetToken(): void {
    // TODO: (5/5/2021) Remove log prior to release. Keeping it for testing/debug purposes.
    this.loggerService.log('Twilio Token Reset triggered');
    this.tokenResetTriggered = true;
    this.resetTokenNotifier$.emit();
  }

  private handleTwilioErrors(error): void {
    this.loggerService.error('Error with Twilio Device :: ', error);
    this.isDeviceInitLoading = false;
    const tokenErrCodes = [TwilioErrorCodes.INVALID_JWT_TOKEN.code, TwilioErrorCodes.JWT_TOKEN_EXPIRED.code];
    const twilioErrorTokenCodes = [TwilioErrorCodes.INVALID_ACCESS_TOKEN.code, TwilioErrorCodes.TOKEN_EXPIRED_OR_EXP_DATE_INVALID.code];
    // For refreshing the Twilio Client Token
    if ((tokenErrCodes.includes(_.get(error, 'code')) || twilioErrorTokenCodes.includes(_.get(error, 'twilioError.code'))) && !this.tokenResetTriggered) {
      this.resetToken();
    } else {
      const errCode = _.get(error, 'code') || _.get(error, 'twilioError.code');
      const errType = _.get(error, 'twilioError.description') || DialerErrorTypes.TWILIO_ERROR;
      const findErr = TwilioErrorCodes.findByErrorCode(errCode);

      let additionalErrMsg = '';
      if (findErr?.code === TwilioErrorCodes.BLOCKED_DUE_TO_LOW_TRUST_SCORE.code) {
        this._appStore
          .select(getUserPermissionsFromAuthToken)
          .pipe(take(1))
          .subscribe((permissions) => {
            const adminAccessPermission = permissions?.find((permission) => permission?.permissionId === PermissionEnum.ADMIN_ACCESS);
            if (!!adminAccessPermission) {
              additionalErrMsg = ` Admins register <a href="/#/app/user-name/admin/v2/dialer/trusted-calling">HERE</a>`;
            } else {
              additionalErrMsg = ' Contact your admin for more info.';
            }
          });
      }
      const errMsg = (findErr?.text ? findErr.text : DialerErrorDetails.DEFAULT_VOIP_ERROR) + `${additionalErrMsg}`;
      const notyText = errMsg + `[Error code: ${errCode}]`;
      this.dialerAnalyticsService.sendDialerErrorAnalyticsEvent(errMsg, errType, errCode.toString());
      this.notyService.postMessage(new Message(MessageType.ERROR, notyText));
    }
  }
}
