import { HashLocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { getExtensionMode } from './extensionMode.config';

/**
 * Inherits from HashLocationStrategy. Used to prevent adding route
 * navigation to the history state of the browser when accessing the
 * app through ReachOut. This was the alternative to having to change
 * all router.navigate calls to a service with these conditional checks
 * as well as providing the need to enforce use of that service as opposed
 * being able to use router.navigate.
 */
@Injectable()
export class EngageLocationStrategy extends HashLocationStrategy {
  pushState(state: any, title: string, path: string, queryParams: string) {
    if (!getExtensionMode()) {
      super.pushState(state, title, path, queryParams);
    }
  }

  replaceState(state: any, title: string, path: string, queryParams: string) {
    if (!getExtensionMode()) {
      super.replaceState(state, title, path, queryParams);
    }
  }
}
