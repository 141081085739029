import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ExtendSalesWorkflowTemplateRest, ExtendSalesWorkflowTemplateStep } from '@zi-core/http-model/response/sales-workflow-template.response.model';
import { CompanyAccountTeamMember } from '@zi-core/data-model/company-account-team-member';
import { CompanyAccount } from '@zi-core/data-model/account.model';
import { environment } from '@env/environment';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CompanyAccountGetActivitiesRequestModel } from '@zi-core/http-model/request/company-account.request.model';
import { CrmEntityType } from '@app/core/enums/crm-entity-type.enum';
import { CustomFieldsByOrg } from '@app/core/http-model/response/crm.model';
import { ActivitiesUiFilterKeys } from '@zi-core/enums/company-account.enum';
import { SpacesResponse } from '@zi-core/http-model/response/spaces.response.model';
import { CompanyAccountSummaryResponse } from '@zi-core/http-model/response/company-accounts.response.model';
import { CompanyAccountReps } from '@zi-core/data-model/company-account-reps.model';
import { Task } from '@zi-core/data-model/task.model';
import { TasksRequestModel } from '@zi-core/http-model/request/tasks.request.model';
import { WebsitesResponse } from '@app/core/http-model/response/website-domain-response.model';
import { CompanyAccountsStatisticsResponse } from '@zi-core/http-model/response/company-accounts-statistics.response.model';
import { SearchByFilterLeafsRequest } from '@zi-core/http-model/request/engage-filter.request.model';
import * as moment from 'moment';

@Injectable()
export class CompanyAccountDetailInternalDataService {
  constructor(private httpClient: HttpClient) {}

  /**
   * Delete CompanyAccounts and this endpoint returns <int>
   * which is count of company accounts deleted
   *
   * @param companyAccountIds - list of company accounts to delete
   * @return Observable of int
   */
  deleteCompanyAccounts(companyAccountIds: number[]): Observable<number> {
    return this.httpClient.delete<number>(`${environment.backend_url}/v1/company_accounts?DeleteContacts=true`, { body: { Ids: companyAccountIds } });
  }

  createCompanyAccounts(companyAccount: CompanyAccount): Observable<CompanyAccount> {
    return this.httpClient.post<CompanyAccount>(`${environment.backend_url}/v1/company_accounts`, { companyAccount });
  }

  updateCompanyAccount(companyAccount: CompanyAccount): Observable<CompanyAccount> {
    return this.httpClient.put<CompanyAccount>(`${environment.backend_url}/v1/company_accounts`, { companyAccount });
  }

  loadCustomFieldsByOrganization(entityType: CrmEntityType): Observable<CustomFieldsByOrg> {
    return this.httpClient.get<CustomFieldsByOrg>(`${environment.backend_url}/v1/org/customfields?EntityType=Account`);
  }

  // TODO: change the service once actual response is defined
  deleteContactFromAccount(contactIds: Array<number>, accountId): Observable<any> {
    return this.httpClient.post<object>(`${environment.backend_url}/v1/contacts/delete`, { contactIds }).pipe(
      map((res) => {
        return res;
      }),
    );
  }

  loadCompanyAccount(accountId: number): Observable<CompanyAccount> {
    return this.httpClient.get<CompanyAccount>(`${environment.backend_url}/v1/company_accounts?Id=${accountId}`);
  }

  loadCompanyAccountWebsiteVisits(websiteDomainIds: string[], endDate?: string, startDate?: string): Observable<WebsitesResponse> {
    endDate = endDate || new Date(Date.now()).toLocaleDateString();
    endDate = moment(endDate).format('YYYY-MM-DD');
    const defaultStartDate = moment().subtract('24', 'hours').format('YYYY-MM-DD');
    startDate = startDate || defaultStartDate;
    const websiteIds = websiteDomainIds.map((id) => `&websiteIds=${id}`).join('');
    return this.httpClient.get<WebsitesResponse>(
      `${environment.engage_backend_url}/websights/zoominfo-websights/visitors?endDate=${endDate}&startDate=${startDate}${websiteIds}`,
    );
  }

  /**
   * TODO cleanup
   * @param accountId - account id
   */
  loadCompanyAccountHealthScore(accountId: number): Observable<number> {
    return of(99);
  }

  loadCompanyAccountSalesflows(companyAccountId: number): Observable<ExtendSalesWorkflowTemplateRest[]> {
    return this.httpClient.get<ExtendSalesWorkflowTemplateRest[]>(`${environment.backend_url}/v2/workflow_templates?CompanyAccountId=${companyAccountId}`);
  }

  loadCompanyAccountSalesflowSteps(salesflowId: number): Observable<ExtendSalesWorkflowTemplateStep[]> {
    return this.httpClient.get<ExtendSalesWorkflowTemplateStep[]>(`${environment.backend_url}/v1/workflow_templates/${salesflowId}/steps`);
  }

  loadCompanyAccountsSummary(resource: SearchByFilterLeafsRequest): Observable<CompanyAccountsStatisticsResponse> {
    return this.httpClient.post<CompanyAccountsStatisticsResponse>(`${environment.backend_url}/v1/filters`, resource).pipe(map((res) => res));
  }

  /**
   * Load company account activities summary
   * URL: /v1/company_accounts/{id}/eventSummary
   *
   * @param companyAccountId - Id of account
   */
  public loadCompanyAccountActivitiesSummary(companyAccountId: number): Observable<CompanyAccountSummaryResponse> {
    return this.httpClient.get<CompanyAccountSummaryResponse>(
      `${environment.backend_url}/v1/company_accounts/${companyAccountId}/eventSummary?Id=${companyAccountId}`,
    );
  }

  /**
   * Fetch company account history activities
   * @param payload - CompanyAccountGetActivitiesRequestModel model
   */
  public loadCompanyAccountActivities(payload: CompanyAccountGetActivitiesRequestModel): Observable<SpacesResponse> {
    const params = new HttpParams()
      .set('CompanyAccountId', String(payload.id))
      .set('Type', JSON.stringify(payload.filters[ActivitiesUiFilterKeys.activityTypes]))
      .set('PageSize', String(payload.pageSize));
    // TODO .set('PaginationCookie', payload.paginationCookie);

    return this.httpClient.get<SpacesResponse>(`${environment.backend_url}/v1/company_accounts/${payload.id}/activities`, { params });
  }

  /**
   * Loads company account tasks in sorted order - Descending
   * @param request - TasksRequestModel
   */
  public loadCompanyAccountTasks(request: TasksRequestModel): Observable<Array<Task>> {
    const params = new HttpParams().set('CompanyAccountId', String(request.resourceId)).set('PageSize', String(request.pageSize));
    return this.httpClient.get<{ tasks: Task[] }>(`${environment.backend_url}/v1/company_accounts/${request.resourceId}/tasks`, { params }).pipe(
      map((res: { tasks: Task[] }) => {
        return res.tasks || [];
      }),
    );
  }

  /**
   * TODO cleanup
   * @param accountId - accountId
   */
  loadCompanyAccountTeamMembers(accountId: number): Observable<Array<CompanyAccountTeamMember>> {
    return of([
      {
        profile: {
          userId: 1,
          email: 'test@test.com',
          isEmailVerified: 1,
          phone: '555-5555',
          isPhoneVerified: 1,
          lastLoginAt: '2020-01-01',
          loginCount: 3,
          passwordReset: 0,
          createdAt: '2020-01-01',
          modifiedAt: '2020-01-01',
          name: 'Testteam Membername',
          company: 'test company',
          attributes: {
            signature: '',
            timeZoneId: 'America/Los_Angeles',
            cultureName: 'en-US',
            title: 'Customer Outbound',
          },
        },
        contactIds: [],
        emails: 1,
        calls: 2,
        salesflows: 3,
        lastContacted: '2020-01-01',
        demosComplete: 4,
      },
    ]);
  }

  loadCompanyAccountRepsStats(companyAccountId: number): Observable<Array<CompanyAccountReps>> {
    return this.httpClient.get<Array<CompanyAccountReps>>(`${environment.backend_url}/v1/company_accounts/${companyAccountId}/reps`);
  }

  updateCompanyAccountsStatus(status: string, companyAccountIds: Array<number>): Observable<Array<CompanyAccount>> {
    return this.httpClient.post<Array<CompanyAccount>>(`${environment.backend_url}/v1/company_accounts/status`, { companyAccountIds, status });
  }
}
