import { Inject, Injectable, Optional } from '@angular/core';
import { environment } from '@env/environment';
import * as Amplitude from 'amplitude-js';
import { AuthenticatedToken } from '@zi-core/http-model/response/authenticated-token.response.model';
import * as _ from 'lodash';
import { select, Store } from '@ngrx/store';
import { AuthenticatedTokenState, getAuthState } from '@zi-core/ngrx/state/auth.state';
import { distinctUntilChanged, filter, take } from 'rxjs/operators';
import { ApplicationState } from '@app/reducers';
import { getExtensionMode } from '@app/extensionMode.config';
import { AnalyticsApplication } from '@zi-core/enums/analytics.enum';
import * as ZoominfoAnalytic from '@zoominfo/zoominfo-analytic';
import { Observable } from 'rxjs';
import { AnalyticsEvent } from '@zi-core/enums/analytics-event.enum';
import { UrlService } from '@zi-core/service/url.service';
import { ILoggerService } from '@zi-core/interface/logger.service.interface';
import { LoggerServiceToken } from '@zi-core/config/logger-service.config';

@Injectable({ providedIn: 'root' })
export class AnalyticsBaseService {
  protected authState$: Observable<AuthenticatedTokenState>;

  constructor(
    protected urlService: UrlService,
    protected store: Store<ApplicationState>,
    @Optional() @Inject(LoggerServiceToken) protected loggerService: ILoggerService,
  ) {
    Amplitude.getInstance().init(environment.amplitude_api_key);
    this.authState$ = this.store.pipe(
      select(getAuthState),
      distinctUntilChanged(_.isEqual),
      filter((state: AuthenticatedTokenState) => !!state && !!state.authenticatedToken && !!state.profile),
    );
    this.initialize();
  }

  /**
   * Use to send amplitude events
   * @param eventName - string/ enum
   * @param eventProperties - Properties for amplitude event
   * @param source - Page source url
   * @param bypassUserIdCheck - Set to true to bypass user id check before emitting events
   */
  public send(eventName: string, eventProperties: any, source: string = '', bypassUserIdCheck: boolean = false) {
    const application = getExtensionMode() ? AnalyticsApplication.EngageExtension : AnalyticsApplication.Engage;
    const propsSource = source || this.getCurrentPageSourceName();
    const props = source !== 'noSource' ? { ...eventProperties, application, source: propsSource } : { ...eventProperties, application };
    try {
      const event = ZoominfoAnalytic.events.make(eventName, props);
      const instance = Amplitude.getInstance();
      // Ensure user id is present before emitting amplitude events
      if (bypassUserIdCheck || instance.options?.userId) {
        instance.logEvent(event.name, event.properties);
      } else {
        this.setUserPropertiesAndEmitEvent(event.name, event.properties);
      }
    } catch (error: any) {
      this.failedToSendEvent(error, application, propsSource, eventName, props);
    }
  }

  /**
   * Send error event
   * @param failedEvent - failed event name
   * @param description - reason for failure
   * @param source - page from where event was emitted
   * @param errorCode - optional error code
   * @param responsibility - optional 'System' || 'UserError'
   * @param bypassUserIdCheck - Set to true to bypass user id check before emitting events
   */
  public sendErrorEvent(failedEvent: string, description: string, source?: string, errorCode?: number, responsibility?: string, bypassUserIdCheck?: boolean) {
    const errorEventData = {
      Action: failedEvent,
      Description: description,
      ErrorCode: errorCode || null,
      ErrorResponsibility: responsibility || 'System',
    };
    this.send(AnalyticsEvent.ERROR_OCCURRED_IN_EVENT, errorEventData, source, bypassUserIdCheck);
  }

  public sendApplicationReloadRequest(reason: string, source?: string) {
    this.send(AnalyticsEvent.APPLICATION_RELOAD_REQUEST, { reason }, source);
  }

  /**
   * Private/Protected functions
   */

  private setUserPropertiesAndEmitEvent(eventName, eventProperties) {
    this.store.pipe(select(getAuthState), take(1)).subscribe((state: AuthenticatedTokenState) => {
      if (!!state && !!state.authenticatedToken && !!state.profile) {
        this.setProperties(state.authenticatedToken, state.profile);
        Amplitude.getInstance().logEvent(eventName, eventProperties);
      } else {
        this.sendErrorEvent('EmptyUserPropsInEngageWhileSendingEvent', eventName, null, null, null, true);
      }
    });
  }

  /**
   * Get current page source name
   * TODO ZE-9607 to correct page source compute logic
   * @protected
   */
  protected getCurrentPageSourceName(): any {
    return this.urlService.getRouteForAnalytics();
  }

  /**
   * Emit failed amplitude event on event emission failure
   * @param error - Error object
   * @param failedEventApplication - Application source
   * @param failedEventSource - Page source
   * @param failedEventName - Event failed to send
   * @param failedEventProperties - properties object
   * @protected
   */
  protected failedToSendEvent(error: any, failedEventApplication: string, failedEventSource: string, failedEventName: string, failedEventProperties: {}) {
    try {
      const errorProps = {
        application: failedEventApplication,
        failedEvent: failedEventName,
        errorDetails: error.toString(),
        eventProperties: failedEventProperties,
      };
      this.send(AnalyticsEvent.FAILED_TO_SEND_EVENT, errorProps, failedEventSource);
    } catch (err: any) {
      this.loggerService.error(err);
    }
  }

  protected setProperties(token: AuthenticatedToken, profile) {
    const { orgId } = token;
    const organization = (token.organizations || []).find((org) => org.orgId === orgId);
    Amplitude.getInstance().setUserId(token.userId);
    Amplitude.getInstance().setGroup('orgId', orgId.toString());
    // initialize user
    Amplitude.getInstance().setUserProperties({
      Email: _.get(profile, 'email'),
      login_count: _.get(profile, 'loginCount'),
      CreationDate: _.get(profile, 'createdAt'),
      Name: _.get(profile, 'name'),
      CompanyName: _.get(organization, 'title'),
      SalesforceAccountID: _.get(token, 'zoomCustSalesforceId', ''),
    });
  }

  /**
   * Initialize Amplitude instance properties
   * @private
   */
  private initialize() {
    this.authState$.pipe(take(1)).subscribe((authToken: AuthenticatedTokenState) => {
      this.setProperties(authToken.authenticatedToken, authToken.profile);
    });
  }
}
