// On Boarding
import { createAction, props } from '@ngrx/store';

export const UpdateCurrentStep = createAction('[OnBoarding] Update current step', props<{ currentStep: number }>());

export const UpdateUnfinishedStepsAction = createAction('[OnBoarding] Update unfinished steps', props<{ currentStep: number; unfinishedSteps: string[] }>());

export const UpdateUnfinishedStepsSuccessAction = createAction(
  '[OnBoarding] Update unfinished steps success',
  props<{ currentStep: number; unfinishedSteps: string[] }>(),
);

export const UpdateUnfinishedStepsFailedAction = createAction('[OnBoarding] Update unfinished steps failed');

export const SetOnBoardingAction = createAction('[OnBoarding] Set on boarding');

export const SetOnBoardingSuccessAction = createAction('[OnBoarding] Set on boardings success', props<{ currentStep: number; unfinishedSteps: string[] }>());

export const SetOnBoardingFailedAction = createAction('[OnBoarding] Set on boardings failed');
