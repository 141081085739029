import { Injectable } from '@angular/core';
import { SafeValuePipe } from '@app/common/pipe/safe-value/safe-value.pipe';
import { IntegrationNamesMap } from '@app/core/enums/integration-names-map.enum';
import { CompanyAccountDetailInternalDataService } from '@app/pages/company-accounts/service/company-account-detail.service';
import { ApplicationState } from '@app/reducers';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import { CompanyAccount } from '@zi-core/data-model/account.model';
import { isConnectedToService } from '@zi-core/ngrx/state/auth.state';
import { IntegrationService } from '@zi-core/service/integration.service';
import { NavigationService } from '@zi-core/service/navigation.service';
import { UrlService } from '@zi-core/service/url.service';
import * as _ from 'lodash';
import { empty, Observable } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class CompanyAccountsIntegrationService {
  DEFAULT_TWITTER_URL = 'https://twitter.com/';
  DEFAULT_LINKEDIN_URL = 'https://www.linkedin.com/';
  REG_EXP = new RegExp('!/^https?:///i');

  constructor(
    private integrationService: IntegrationService,
    private navigationService: NavigationService,
    private store: Store<ApplicationState>,
    private companyAccountDetailInternalDataService: CompanyAccountDetailInternalDataService,
    private urlService: UrlService,
    private safeValue: SafeValuePipe,
  ) {}

  openCRMRecord(account: CompanyAccount, crmName: IntegrationNamesMap): Observable<any> {
    return this.store.pipe(
      select(isConnectedToService(crmName)),
      take(1),
      switchMap((isConnectedToCRM) => {
        if (isConnectedToCRM) {
          return this.companyAccountDetailInternalDataService.loadCompanyAccount(account.id);
        } else {
          this.notConnectedToCRMDialog(crmName);
          return empty();
        }
      }),
      tap((accountData) => {
        const crmInfo = _.get(accountData, 'crmInfo', null);
        if (!!crmInfo) {
          const searchString = _.get(crmInfo, 'entityUrl', '');
          window.open(searchString, '_blank');
        }
      }),
    );
  }

  notConnectedToCRMDialog(crmName) {
    this.integrationService.notConnectedToCRMDialog(crmName).subscribe((toSettings) => {
      if (toSettings) {
        this.navigationService.navigateToSettings();
      }
    });
  }

  openZoominfoCompany(account: CompanyAccount) {
    const zoominfoCompanyId = _.get(account, 'zoomCompanyId');
    const url = zoominfoCompanyId ? `${environment.zoominfo_app}/#/apps/profile/company/${zoominfoCompanyId}` : environment.zoominfo_app_search;
    window.open(url, '_blank');
  }

  openLinkedInCompany(account: CompanyAccount) {
    let companyLinkedInUrl = _.get(account, 'linkedInUrl', null);
    if (companyLinkedInUrl) {
      // Convert to safe value.
      companyLinkedInUrl = this.safeValue.transform(companyLinkedInUrl, 'url');
      if (this.REG_EXP.test(companyLinkedInUrl)) {
        companyLinkedInUrl = 'https://' + companyLinkedInUrl;
      }

      window.open(companyLinkedInUrl || this.DEFAULT_LINKEDIN_URL, '_blank');
    } else {
      const companyName = _.get(account, 'name', '');
      const searchString = `https://www.linkedin.com/search/results/all/?keywords=${companyName}`;
      window.open(searchString, '_blank');
    }
  }

  openTwitterCompany(account: CompanyAccount) {
    let companyTwitterUrl = _.get(account, 'twitterUrl');

    if (companyTwitterUrl) {
      // Convert to safe value.
      companyTwitterUrl = this.safeValue.transform(companyTwitterUrl, 'url');
    }
    window.open(companyTwitterUrl || this.DEFAULT_TWITTER_URL, '_blank');
  }

  openCompanyWebsite(account: CompanyAccount) {
    let searchString = _.get(account, 'companyWebsite');

    if (searchString) {
      // Convert to safe value.
      searchString = this.safeValue.transform(searchString, 'url');
    }
    const domain: string = this.urlService.getUrlDomain(searchString);
    const urlToUse = `https://www.${domain}`;
    window.open(urlToUse, '_blank');
  }
}
