import { Injectable } from '@angular/core';
import { PhoneTypesEnum } from '@app/caller/interface/phone-types-enum';
import { PhoneNumberInfo } from '@app/caller/interface/phone-number-info';
import { DotsOption } from '@zi-common/interface/dots-option';
import { Contact } from '@zi-core/data-model/contact.model';
import * as _ from 'lodash';

export interface PhoneOptions {
  displayName: string;
  value: PhoneNumberInfo;
  iconPrefixClass?: string;
  parentOption?: string;
}

interface PhoneAction {
  displayName: string;
  children?: DotsOption[];
}

@Injectable({
  providedIn: 'root',
})
export class CallNumberService {
  public getPhoneOptions(contact): PhoneOptions[] {
    const contactToUse = { ...contact };
    let dropdownOptions = [];
    if (!contact) {
      return [];
    }
    if (contactToUse.phone) {
      dropdownOptions.push({
        displayName: contactToUse.phone + ' (Direct)',
        value: {
          number: contactToUse.phone,
          type: PhoneTypesEnum.direct,
          typeLabel: '(Direct)',
          status: contactToUse.phoneStatus,
        },
      });
    }

    if (contactToUse.mobilePhone) {
      dropdownOptions.push({
        displayName: contactToUse.mobilePhone + ' (Mobile)',
        value: {
          number: contactToUse.mobilePhone,
          type: PhoneTypesEnum.mobilePhone,
          typeLabel: '(Mobile)',
          status: contactToUse.mobilePhoneStatus,
        },
      });
    }

    if (contactToUse.homePhone) {
      dropdownOptions.push({
        displayName: contactToUse.homePhone + ' (Home)',
        value: {
          number: contactToUse.homePhone,
          type: PhoneTypesEnum.homePhone,
          typeLabel: '(Home)',
          status: contactToUse.homePhoneStatus,
        },
      });
    }

    if (contactToUse.otherPhone) {
      dropdownOptions.push({
        displayName: contactToUse.otherPhone + ' (Other)',
        value: {
          number: contactToUse.otherPhone,
          type: PhoneTypesEnum.other,
          typeLabel: '(Other)',
          status: contactToUse.otherPhoneStatus,
        },
      });
    }

    if (contactToUse.hqPhone) {
      dropdownOptions.push({
        displayName: contactToUse.hqPhone + ' (HQ)',
        value: {
          number: contactToUse.hqPhone,
          type: PhoneTypesEnum.hqPhone,
          typeLabel: '(HQ)',
          status: contactToUse.hqPhoneStatus,
        },
      });
    }

    if (contactToUse.assistantPhone) {
      dropdownOptions.push({
        displayName: contactToUse.assistantPhone + ' (Assistant)',
        value: {
          number: contactToUse.assistantPhone,
          type: PhoneTypesEnum.assistantPhone,
          typeLabel: '(Assistant)',
          status: contactToUse.assistantPhoneStatus,
        },
      });
    }

    dropdownOptions = dropdownOptions.map((dropdownOption) => {
      return { ...dropdownOption, iconPrefixClass: 'icon-call' };
    });

    return dropdownOptions;
  }

  public getCallActionForThreeDots(actionName: string, contact: Contact): PhoneAction {
    let phoneNumberOptions = this.getPhoneOptions(contact);
    const phoneAction: PhoneAction = { displayName: actionName };
    if (phoneNumberOptions.length > 1 && !contact.doNotCall) {
      phoneNumberOptions = phoneNumberOptions.map((phoneNumberOption) => {
        return { ...phoneNumberOption, parentOption: actionName };
      });
      phoneAction.children = phoneNumberOptions;
    }

    return phoneAction;
  }

  public getCallActionForPhoneNumThreeDots(actionName: string, phoneNum: string): PhoneOptions {
    return { displayName: actionName, value: { number: phoneNum, type: PhoneTypesEnum.direct } };
  }

  public isPhoneAvailable(contact: Contact): boolean {
    return !!(
      _.get(contact, 'phone') ||
      _.get(contact, 'mobilePhone') ||
      _.get(contact, 'otherPhone') ||
      _.get(contact, 'homePhone') ||
      _.get(contact, 'hqPhone') ||
      _.get(contact, 'assistantPhone')
    );
  }
}
