export enum ExtensionNavigationPageNames {
  ACCOUNT_SETTINGS,
  INTEGRATION,
  ENGAGE,
  BULK_EMAIL,
  ACCOUNT,
  ADMIN,
  CONTACT_PROFILE,
  IMPORTS_PAGE,
  ANALYTICS,
}

export const EXTENSION_NAVIGATION_URLS = {
  [ExtensionNavigationPageNames.ACCOUNT_SETTINGS]: ['/apps/account-settings/my-account'],
  [ExtensionNavigationPageNames.INTEGRATION]: ['app/user-name/my-account/v2/settings'],
  [ExtensionNavigationPageNames.ACCOUNT]: ['app/user-name/my-account/v2/profile'],
  [ExtensionNavigationPageNames.ENGAGE]: ['/app/engage'],
  [ExtensionNavigationPageNames.BULK_EMAIL]: ['engageext/bulk-email'],
  [ExtensionNavigationPageNames.CONTACT_PROFILE]: ['app/contacts'],
  [ExtensionNavigationPageNames.IMPORTS_PAGE]: ['/app/imports/import-page'],
  [ExtensionNavigationPageNames.ANALYTICS]: ['/app/analytics/v2/call-logs'],
};

export const ExtensionContactUrl = 'engageext/ext-cont/details';
export const ExtensionProfileUrl = 'engageext/ext-cont/profile';
export const BasePath = 'engageext';
export const ContactBasePath = 'ext-cont';

export enum ExtensionNavigationGotoStr {
  ACCOUNT = 'account',
}
