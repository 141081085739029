import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, finalize, skip, switchMap, takeWhile, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, of, Subscription, timer } from 'rxjs';
import * as moment from 'moment';

import { ApplicationState } from '@app/reducers';
import { AuthenticatedToken } from '../http-model/response/authenticated-token.response.model';
import { RefreshTokenAction, RefreshTokenFailureAction, RefreshTokenSuccessAction } from '../ngrx/action/auth.action';
import _get from 'lodash-es/get';
import { UtilitiesService } from '@zi-common/service/utilities-service/utilities-service';
import { Actions, ofType } from '@ngrx/effects';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LoggerServiceToken } from '@zi-core/config/logger-service.config';
import { ILoggerService } from '@zi-core/interface/logger.service.interface';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class TokenRefreshService {
  private authenticatedToken$: Observable<AuthenticatedToken>;
  private refreshTokenSubscription: Subscription = new Subscription();
  private setUserToInactiveSubscription: Subscription = new Subscription();
  private isEngageTokenRefreshInProgress = false;
  private engageTokenRefreshFailureCount = 0;
  private MAX_ENGAGE_TOKEN_REFRESH_FAILURES_ALLOWED = 5;

  private userIsActive = new BehaviorSubject(false);

  constructor(
    private store: Store<ApplicationState>,
    private utilitiesService: UtilitiesService,
    private actions$: Actions,
    @Inject(LoggerServiceToken) private loggerService: ILoggerService,
  ) {
    this.authenticatedToken$ = this.store.select((state) => state.auth.authenticatedToken);
    this.listenForTokenRefreshComplete();
  }

  start() {
    this.userIsActive.next(true);
    this.setRefreshTokenTimer();
    this.setUserToInactiveAfterTimeFrame();
  }

  stop() {
    this.userIsActive.next(false);
    this.refreshTokenSubscription.unsubscribe();
    this.setUserToInactiveSubscription.unsubscribe();
  }

  setUserToActive() {
    this.userIsActive.next(true);
    this.setUserToInactiveSubscription.unsubscribe();
    this.setUserToInactiveAfterTimeFrame();
  }

  private listenForTokenRefreshComplete() {
    this.actions$.pipe(untilDestroyed(this), ofType(RefreshTokenSuccessAction, RefreshTokenFailureAction)).subscribe((action) => {
      this.isEngageTokenRefreshInProgress = false;
      if (action.type === RefreshTokenSuccessAction.type) {
        this.engageTokenRefreshFailureCount = 0;
      } else {
        this.engageTokenRefreshFailureCount++;
      }
    });
  }

  private setRefreshTokenTimer() {
    const oneMinuteInMilliseconds = 60 * 1000;
    const fiveMinutesInMilliseconds = 5 * 60 * 1000;

    // Listen for active http requests going on from the user
    const userBecomesActiveObservable = this.userIsActive.asObservable().pipe(
      skip(1),
      filter((value) => !!value),
    );

    this.refreshTokenSubscription = userBecomesActiveObservable
      .pipe(
        // Take while there are no constant failures for token refresh
        takeWhile(() => this.engageTokenRefreshFailureCount < this.MAX_ENGAGE_TOKEN_REFRESH_FAILURES_ALLOWED),
        finalize(() => {
          if (this.engageTokenRefreshFailureCount >= this.MAX_ENGAGE_TOKEN_REFRESH_FAILURES_ALLOWED) {
            this.loggerService.error('Failed to refresh engage token. Max retries reached');
          }
        }),
        switchMap((isUserActive) => combineLatest([of(isUserActive), this.authenticatedToken$])),
        tap(([isUserActive, data]) => {
          const isZiAccessTokenValid = this.utilitiesService.isZiAccessTokenValid();

          const engageTokenExpiresAt = moment(_get(data, 'expiresAt'));
          const ziAccessTokenExpiresAt = this.utilitiesService.fetchZiAccessTokenExpiry();

          /*
          The engagetoken expiry is always set to ziaccesstoken expiry in sharpy backend on signin request. If there is
           a mismatch in the two values it implies that the ziaccesstoken was refreshed after engagetoken generation.
          * */
          const IsZiAccessTokenRefreshed = ziAccessTokenExpiresAt?.diff(engageTokenExpiresAt) > oneMinuteInMilliseconds;

          /*
          Check for the following:
          1. Valid ziaccesstoken
          2. Logged-in user is active
          3. If there is a refresh on zi access token
          4. If token refresh is not already in progress
          If the above conditions are met, refresh the expired tellwise token.
          Sharpy backend will reject requests with expired ziaccesstoken and expired tellwise token.
           */
          if (isZiAccessTokenValid && IsZiAccessTokenRefreshed && isUserActive && !this.isEngageTokenRefreshInProgress) {
            this.isEngageTokenRefreshInProgress = true;
            this.store.dispatch(RefreshTokenAction());
          }
        }),
      )
      .subscribe();
  }

  private setUserToInactiveAfterTimeFrame() {
    const fortyMinutesInMilliseconds = 2400000;
    const setInactive = timer(fortyMinutesInMilliseconds, fortyMinutesInMilliseconds);

    this.setUserToInactiveSubscription = setInactive.subscribe(() => {
      this.userIsActive.next(false);
    });
  }
}
