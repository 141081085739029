import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SettingService {
  recordsPerPage = 25;
  maxTaskRecords = 500;
  maxBulkActionRecords = 5000;
  maxEngageRecords = 500;
  maxAddSalesFlowRecords = 2000;
  maxContactIdsInCloudSearchQuery = 100;
  constructor() {}
}
