import { createFeatureSelector, createSelector } from '@ngrx/store';

export class OnBoardingSignatureEntityState {
  emailSignature: string;
  loaded: boolean;
  loading: boolean;
  error?: any;
}

export const initialOnBoardingSignatureEntity: OnBoardingSignatureEntityState = {
  emailSignature: null,
  loaded: false,
  loading: false,
};

export const onBoardingSignatureFeatureSelector = createFeatureSelector<OnBoardingSignatureEntityState>('onBoardingSignature');

export const getSignatureSelector = createSelector(onBoardingSignatureFeatureSelector, (state) => (state.loaded ? state.emailSignature : null));

export const loadingSignatureSelector = createSelector(onBoardingSignatureFeatureSelector, (state) => state.loading);

export const loadedSignatureSelector = createSelector(onBoardingSignatureFeatureSelector, (state) => state.loaded);
