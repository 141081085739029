import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { VoicemailResponse } from '@zi-core/http-model/response/dialer.response.model';
import { environment } from '@env/environment';
import { distinctUntilChanged, flatMap, map, take } from 'rxjs/operators';
import { connectedServicesList, getUserPermissionsFromAuthToken } from '@zi-core/ngrx/state/auth.state';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { ApplicationState } from '@app/reducers';
import { Contact } from '@zi-core/data-model/contact.model';
import { PhoneTypesEnum } from '@app/caller/interface/phone-types-enum';
import * as _ from 'lodash';
import { DialerSelection } from '@zi-core/enums/dialer.enum';
import { RecordingConsentResponse } from '@app/caller/models/recording-consent-response.model';
import { FilterCategory, FilterEntity, ScopeNumber } from '@app/core/enums/engage-filter.enum';
import { SearchByFilterLeafsRequest } from '@app/core/http-model/request/engage-filter.request.model';
import { parsePhoneNumber, CountryCode } from 'libphonenumber-js';
import { PermissionEnum } from '@app/pages/admin/pages/profiles-and-permissions/profiles-and-permissions.config';
import { CallLogSubjectOption } from '@app/caller/v1/call-log-subject-option';
import { FeatureFlagService } from '@app/feature-flag/feature-flag.service';
import { Flag } from '@app/feature-flag/types/flag.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RegulatoryComplianceBundleResponse } from '../models/regulatory-compliance-bundle-response.model';

@UntilDestroy()
@Injectable()
export class DialerUtilService {
  startTimer: number;
  idleTimer: number;
  bridgeIdleTimerRef;
  countdownStarted = false;
  private noDialerSelectionPanelOpen$: EventEmitter<boolean> = new EventEmitter();

  public dialerDomain: string;

  constructor(private httpClient: HttpClient, private _appStore: Store<ApplicationState>, protected featureFlagService: FeatureFlagService) {
    this.getDialerDomain$().subscribe((domain: string) => {
      this.dialerDomain = domain;
    });
  }

  // Lifted straight from DialerService
  // TODO: Delete from either here or dialer.service.ts when decision is made what to do with it.
  public getVoicemails$(userId, orgId): Observable<VoicemailResponse[]> {
    return this.httpClient.get<VoicemailResponse[]>(`${environment.backend_url}/v1/account/${userId}/voicemail?OrgId=${orgId}`);
  }

  // TODO: Delete from dialer.service.ts when ready to transition.
  public getCallLogSubjectOptions$(): Observable<{ displayName: string; value: string }[]> {
    return this._appStore.select(connectedServicesList).pipe(
      take(1),
      flatMap((servicesRes: string[]) => {
        const isConnectedToSf = servicesRes.includes('salesforce');
        let subjectSelectedOptions: { displayName: string; value: string }[] = [];
        if (isConnectedToSf) {
          return this.getConfigurationSubjectOptions$().pipe(
            map((res: CallLogSubjectOption[]) => {
              subjectSelectedOptions = res.map((subjectOption) => {
                return { displayName: subjectOption.friendlyName, value: subjectOption.value };
              });

              return subjectSelectedOptions;
            }),
          );
        } else {
          return of(subjectSelectedOptions);
        }
      }),
    );
  }

  public pickFirstNumberToUse(contactInfo: Contact): { number: string; type: PhoneTypesEnum } {
    let phoneInfoToUse: { number: string; type: PhoneTypesEnum };
    if (!_.isEmpty(contactInfo.phone)) {
      phoneInfoToUse = { number: contactInfo.phone, type: PhoneTypesEnum.direct };
    } else if (!_.isEmpty(contactInfo.mobilePhone)) {
      phoneInfoToUse = { number: contactInfo.mobilePhone, type: PhoneTypesEnum.mobilePhone };
    } else if (!_.isEmpty(contactInfo.homePhone)) {
      phoneInfoToUse = { number: contactInfo.homePhone, type: PhoneTypesEnum.homePhone };
    } else if (!_.isEmpty(contactInfo.otherPhone)) {
      phoneInfoToUse = { number: contactInfo.otherPhone, type: PhoneTypesEnum.other };
    } else if (!_.isEmpty(contactInfo.hqPhone)) {
      phoneInfoToUse = { number: contactInfo.hqPhone, type: PhoneTypesEnum.hqPhone };
    } else if (!_.isEmpty(contactInfo.assistantPhone)) {
      phoneInfoToUse = { number: contactInfo.assistantPhone, type: PhoneTypesEnum.other };
    }
    return phoneInfoToUse;
  }

  public retrievePhoneNumber(phone: string): string {
    // remove any extension from the string if it exists (example: convert '+1(123)-456-7890 ext 123' to '+1(123)-456-7890')
    // covers extensions in formats like 'ext 123', '(ext 123)', ',123#', etc,
    // removes the dot from phone numbers ex: converts 781.560.3456 to 7815603456
    // then normalize the phone number to remove all characters except digits and leading plus
    const phoneHasLeadingPlus = !!phone && phone.length > 0 && phone.charAt(0) === '+';
    let formattedPhone = (phone || '').replace(new RegExp('([^0-9-+.()\\s]+.*$)', ''), '').trim().replace(/\D/g, '');
    if (phoneHasLeadingPlus) {
      formattedPhone = '+' + formattedPhone;
    }
    return formattedPhone;
  }

  public retrievePhoneNumberFor2PartyCheck(phone: string): string {
    // Remove everything but the + and the digits for international numbers
    return (phone || '').replace(/[^0-9+]+/g, '').trim();
  }

  public formatPhoneNumberToE164(phoneNumber: string, countryCode: CountryCode = 'US'): string {
    return parsePhoneNumber(phoneNumber, countryCode)?.number?.toString() ?? '';
  }

  public formatPhoneNumber(phoneNumber: string, excludeAreaCode: boolean = false): string {
    if (!phoneNumber) {
      return '';
    }
    const parsedPhoneNumber = parsePhoneNumber(phoneNumber);

    let formattedPhoneNumber = '';
    if (parsedPhoneNumber.countryCallingCode.toString() === '1') {
      formattedPhoneNumber = parsedPhoneNumber.formatNational();
    } else {
      formattedPhoneNumber = parsedPhoneNumber.formatInternational();
    }

    if (parsedPhoneNumber.country === 'US' && excludeAreaCode) {
      formattedPhoneNumber = formattedPhoneNumber.substr(formattedPhoneNumber.length - 8);
    }
    return formattedPhoneNumber;
  }

  public getPhoneNumbersByAreaCode(areaCode?: string, countryCode?: string) {
    let conjunction = '';
    countryCode = !!countryCode ? `CountryCode=${countryCode}` : '';
    areaCode = !!areaCode ? `AreaCode=${areaCode}` : '';
    if (!!countryCode && !!areaCode) {
      conjunction = '&';
    }

    return this.httpClient.get(`${environment.backend_url}/v1/org/available_phone_numbers?${countryCode}${conjunction}${areaCode}`).pipe(map((res) => res));
  }

  public provisionPhoneNumber(phoneNumber: string, addressSid?: string) {
    if (addressSid) {
      return this.httpClient.post(`${environment.backend_url}/v1/org/dialer_account/caller_ids`, { phoneNumber, addressSid }).pipe(map((res) => res));
    }
    return this.httpClient.post(`${environment.backend_url}/v1/org/dialer_account/caller_ids`, { phoneNumber }).pipe(map((res) => res));
  }

  public addressValidation(name, street, city, region, postalCode, country) {
    return this.httpClient
      .post(`${environment.backend_url}/v1/org/dialer_address`, { name, street, city, region, postalCode, country })
      .pipe(map((res) => res));
  }

  public getNoDialerSelectionPanelOpenNotifier() {
    return this.noDialerSelectionPanelOpen$.asObservable();
  }

  public emitOpenNoDialerSelectionPanel(toOpen: boolean) {
    return this.noDialerSelectionPanelOpen$.emit(toOpen);
  }

  public checkIsVoip(dialerSelection: DialerSelection, isVoipEnabled: boolean): boolean {
    return isVoipEnabled && (_.isEmpty(dialerSelection) || dialerSelection === DialerSelection.VOIP);
  }

  public checkIsBridge(dialerSelection: DialerSelection, isVoipEnabled: boolean): boolean {
    // Existing non dialer orgs that do not have voip enabled. This is for backwards compatibility for existing users
    const isExistingNonDialerOrgs: boolean = (dialerSelection === DialerSelection.NONE || !dialerSelection) && !isVoipEnabled;
    return dialerSelection === DialerSelection.BRIDGE || isExistingNonDialerOrgs;
  }

  public getContactsByPhoneNumberRequest(phoneNumber: string): SearchByFilterLeafsRequest {
    let userScope;
    this._appStore
      .select(getUserPermissionsFromAuthToken)
      .pipe(take(1))
      .subscribe((permissions) => {
        const viewPermission = permissions.find((permission) => permission?.permissionId === PermissionEnum.CONTACT_MANAGEMENT_VIEW_CONTACTS);
        userScope = viewPermission?.scopeId === ScopeNumber.Organization ? 'Organization' : viewPermission?.scopeId === ScopeNumber.Team ? 'Team' : 'Owner';
      });
    return {
      chips: [
        {
          key: 'phones',
          value: phoneNumber,
          type: 1,
          category: FilterCategory.ContactFields,
          entity: FilterEntity.Contact,
        },
      ],
      scope: userScope,
      entity: FilterEntity.Contact,
      pageNumber: 0,
      pageSize: 25,
      search: '',
    };
  }

  // TODO: Delete from either here or dialer.service.ts when decision is made what to do with it.
  private getConfigurationSubjectOptions$(): Observable<CallLogSubjectOption[]> {
    return this.httpClient
      .get(`${this.dialerDomain}/v1/dial/configuration`)
      .pipe(map((res: { callSubjectList: CallLogSubjectOption[] }) => res.callSubjectList));
  }
  public getDuration(counter) {
    const date = new Date(counter);
    const mm = date.getUTCMinutes();
    const ss = date.getSeconds();

    let duration = '';
    mm < 10 ? (duration += '0' + mm) : (duration += mm);
    ss < 10 ? (duration += ' : 0' + ss) : (duration += ' : ' + ss);
    return duration;
  }

  // Check if recording consent is required
  getPhoneCallRecordingConsentRequired(phone: string): Observable<RecordingConsentResponse> {
    return this.httpClient.get<RecordingConsentResponse>(`${this.dialerDomain}/v1/dial/require_recording_consent?phone=${encodeURIComponent(phone)}`);
  }

  getStartTimer() {
    this.startTimer = this.startTimer ?? Date.now();
    return this.startTimer;
  }

  clearStartTimer() {
    this.startTimer = null;
  }

  getIdleTimer() {
    if (this.idleTimer && !this.countdownStarted) {
      this.startCountdown();
    }
    this.idleTimer = this.idleTimer ?? 5 * 60;
    return this.idleTimer;
  }

  clearIdleTimer() {
    this.countdownStarted = false;
    this.idleTimer = null;
    clearInterval(this.bridgeIdleTimerRef);
  }

  startCountdown() {
    this.countdownStarted = true;
    this.bridgeIdleTimerRef = setInterval(() => {
      this.idleTimer--;
    }, 1000);
  }

  public getDialerDomain$(): Observable<string> {
    return this.featureFlagService.observe<Flag.ENABLE_DIALER_SERVICE_REQUESTS>(Flag.ENABLE_DIALER_SERVICE_REQUESTS).pipe(
      untilDestroyed(this),
      distinctUntilChanged(_.isEqual),
      map((isDialerServiceRoutingEnabled: boolean) => (isDialerServiceRoutingEnabled ? environment.dialer_url : environment.backend_url)),
    );
  }

  public getRegulatoryComplianceBundles(countryIsoCode: string): Observable<RegulatoryComplianceBundleResponse[]> | null {
    if (!countryIsoCode) {
      return null;
    }
    const countryCode = `countryCode=${countryIsoCode}`;

    return this.httpClient.get<RegulatoryComplianceBundleResponse[]>(`${environment.backend_url}/v2/RegulatoryCompliance/Bundles?${countryCode}`);
  }
}
