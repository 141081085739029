export class ReplacedContact {
  application: string;
  source: string;
  replacedContactID: string;
  newContactIDs: string[] = [];

  public constructor(contact?: Partial<ReplacedContact>) {
    Object.assign(this, contact);
  }
}

export class ClickedLearnMoreLock {
  application: string;
  source: string;
  feature: string;

  public constructor(feature?: Partial<ClickedLearnMoreLock>) {
    Object.assign(this, feature);
  }
}

export class ClickedContactUsLock extends ClickedLearnMoreLock {}
