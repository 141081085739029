import { Injectable } from '@angular/core';
import { PhoneCallSentiment } from '@zi-pages/analytics/Pages/call-logs/model/phone-call-sentiment.model';
import { EngageModeCallLogEntity } from '@zi-pages/engage-mode-v2/model/engage-mode-call-log-entity.model';
import { EngageModeStorageV2Service } from '@zi-pages/engage-mode-v2/service/engage-mode-storage-v2.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EngageModeCallLogV2Service {
  private engageModeCallLogEntities: BehaviorSubject<Array<EngageModeCallLogEntity>>;
  engageModeCallLogEntities$: Observable<Array<EngageModeCallLogEntity>>;

  constructor(private engageModeStorageService: EngageModeStorageV2Service) {}

  getCallLogEntities() {
    return this.engageModeCallLogEntities ? this.engageModeCallLogEntities.getValue() : null;
  }

  initializeSubscriptions() {
    this.engageModeCallLogEntities$ = this.engageModeCallLogEntities.asObservable();

    this.engageModeCallLogEntities$.pipe(distinctUntilChanged()).subscribe((entities) => {
      this.engageModeStorageService.storeEngageModeCallLogEntitiesInSessionStorage(entities);
    });
  }

  initializeEngageModeCallLog() {
    this.engageModeCallLogEntities = new BehaviorSubject([]);
    this.initializeSubscriptions();
  }

  initializeEngageModeCallLogFromSessionStorage() {
    const entities: Array<EngageModeCallLogEntity> = this.engageModeStorageService.loadEngageModeCallLogEntitiesFromSessionStorage();
    this.engageModeCallLogEntities = new BehaviorSubject(entities);
    this.initializeSubscriptions();
  }

  setCallLogForEntity(contactId: number, callSentiment: PhoneCallSentiment) {
    const entities = [...this.engageModeCallLogEntities.getValue()];
    const existingEntity = entities.find((entity) => entity.contactId === contactId);
    if (existingEntity) {
      existingEntity.callSentiment = callSentiment;
    } else {
      entities.push({ contactId, callSentiment });
    }
    this.engageModeCallLogEntities.next(entities);
  }

  getCallLogForEntity(contactId: number): EngageModeCallLogEntity {
    const entities = [...this.engageModeCallLogEntities.getValue()];
    return entities.find((entity) => entity.contactId === contactId);
  }

  removeCallLogForEntity(contactId: number) {
    const entities = [...this.engageModeCallLogEntities.getValue()];
    const existingEntityIndex = entities.findIndex((entity) => entity.contactId === contactId);
    if (existingEntityIndex !== -1) {
      entities.splice(existingEntityIndex, 1);
    }
    this.engageModeCallLogEntities.next(entities);
  }

  unsubscribeOnExit() {
    this.engageModeCallLogEntities.complete();
    this.engageModeStorageService.clearEngageModeCallLogDataInSessionStorage();
  }
}
